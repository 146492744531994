/* eslint-disable no-nested-ternary */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';

import { Container, Row, Col } from 'components/grid';
import CarerOnboardingStatusNotification from 'components/carers/CarerOnboardingStatusNotification';
import ButtonLink from 'components/buttons/ButtonLink';
import { SCJobEmail } from 'components/global/ContactInfo';
import Module, {
  ModuleHeader,
  ModulePanel,
} from 'components/global/layout/Module';
import { SmileyFaceIcon, ClockIcon } from 'components/global/icons';
import { TickIcon } from 'components/global/icons/small';

import moment from 'moment';
import styled from 'styled-components';

const StyledStatus = styled.h3`
  color: ${props => props.theme.colors.changeGreen};
  font-weight: bold;
  @media (min-width: 768px) {
    margin-top: 20px;
  }
`;

const MobileModule = styled(Module)`
  display: ${props => (props.mobilePanel ? 'flex' : 'block')};
  padding: 0;

  @media (min-width: 768px) {
    display: flex;
  }
`;

const MobileModulePanel = styled(ModulePanel)`
  border-right: 1px solid ${props => props.theme.colors.uiGrey};
  border-bottom: none;

  ${props =>
    props.center &&
    'display: grid; justify-content: center; align-content: center;'};
`;

const MobilePanelContent = styled.div`
  @media (max-width: 767px) {
    > * {
      display: inline-block;
    }

    > *:first-child {
      width: 20%;
    }

    > *:last-child {
      width: 80%;
    }
  }
`;

class CarerOnboarding extends Component {
  render() {
    const { carer, inductionSlot, profileComplete } = this.props;

    const fountainState = carer.profile_details.fountain_state;

    if (
      ['failedtrustidcheck', 'unresponsive', 'onhold', 'rejected'].includes(
        fountainState,
      )
    ) {
      return <CarerOnboardingStatusNotification carer={carer} />;
    }

    // Before induction
    const referencesSubmitted =
      carer.references_submitted && carer.references_submitted >= 4;
    const isBeforeInductionComplete = profileComplete && referencesSubmitted;

    // Induction
    const inductionTime =
      inductionSlot && inductionSlot.time && moment(inductionSlot.time);
    const isBookInductionComplete = inductionSlot && inductionSlot.attended;

    // After induction
    const backgroundChecksComplete = fountainState === 'approved';
    const referencesCheckedHI = (
      carer.profile_details.hi_compliance_fields || {}
    ).references;
    const isAfterInductionComplete =
      backgroundChecksComplete && referencesCheckedHI;

    return (
      <section>
        <Module>
          <ModuleHeader>Hi {carer.firstname}</ModuleHeader>
          <Container>
            <Row>
              <Col>
                Before you can find work through the platform, we need to learn
                a bit more about you and complete some compliance checks. Please
                follow the instructions below so you can start applying for
                live-in jobs with Home Instead franchised offices as soon as
                possible!
              </Col>
            </Row>
          </Container>
        </Module>

        <MobileModule
          tab="blue"
          id="induction-before"
          panelled
          mobilePanel={isBeforeInductionComplete}
        >
          {isBeforeInductionComplete ? (
            <CompletedModule
              text="
                You've added your profile info and submitted your references.
                Please ensure your work history is complete and up to date.
              "
            />
          ) : (
            [
              <ModulePanel key="header" size={1}>
                <ModuleHeader>Before Interview</ModuleHeader>
              </ModulePanel>,
              <TaskPanel
                key="profile-task"
                id="profile-task"
                title="Complete your profile"
                complete={profileComplete}
                incompleteJsx={[
                  <p key="desc">
                    You must complete your profile to progress. This will be
                    shown to potential clients to help you win work.
                  </p>,
                  <br />,
                  <div key="add-profile-info">
                    <ButtonLink to="/carers/profile/">
                      Add profile info
                    </ButtonLink>
                  </div>,
                ]}
                completeJsx={
                  <p key="profile-complete-desc">Profile completed!</p>
                }
              />,
              <TaskPanel
                key="references-task"
                id="references-task"
                title="Submit your Work History and References"
                complete={referencesSubmitted}
                incompleteJsx={[
                  <p key="desc">
                    Please provide us with your work history first
                  </p>,
                  <p key="link">
                    <Link to="/work-history/summary/">Manage work history</Link>
                  </p>,
                  <br />,
                  <p key="desc">Then please give us your references</p>,
                  <p key="link">
                    <Link to="/references/summary/">Manage references</Link>
                  </p>,
                ]}
                completeJsx={[
                  <p key="desc">
                    You&#39;ve successfully submitted your reference details!
                    <br />
                    <br />
                    <Link to="/references/summary/">View references</Link>
                  </p>,
                ]}
              />,
            ]
          )}
        </MobileModule>

        <MobileModule
          tab="purple"
          id="induction-book"
          panelled
          mobilePanel={isBookInductionComplete}
        >
          {isBookInductionComplete ? (
            <CompletedModule text="You’ve attended your interview." />
          ) : inductionSlot ? (
            [
              <ModulePanel key="description" size={2}>
                <ModuleHeader>Interview: Booked</ModuleHeader>
                <p style={{ textAlign: 'left' }}>
                  We’re looking forward to meeting you!
                </p>
                <p style={{ textAlign: 'left' }}>
                  Please keep your appointment. If there is an emergency
                  preventing your attendance then please contact us at{' '}
                  <SCJobEmail />.
                </p>
              </ModulePanel>,

              <ModulePanel key="induction-time">
                <div>
                  <MobilePanelContent>
                    <ClockIcon />
                    {inductionTime ? (
                      <div>
                        <StyledStatus>
                          {inductionTime.format('dddd, Do MMM')}
                        </StyledStatus>
                        <span>
                          {inductionTime.format('h:mma')} -{' '}
                          {inductionTime
                            .clone()
                            .add(3, 'hours')
                            .format('h:mma')}
                        </span>
                      </div>
                    ) : (
                      <p>
                        Error while retrieving time of booking. Please contact
                        us for your booking time.
                      </p>
                    )}
                  </MobilePanelContent>
                </div>
              </ModulePanel>,
            ]
          ) : (
            [
              <ModulePanel key="description" size={2}>
                <ModuleHeader>Interview: Book Now</ModuleHeader>
                <p style={{ textAlign: 'left' }}>
                  We meet or do video interviews with every carer. We&#39;ll get
                  to know you, get you set up on our system, and answer any
                  questions you have.
                </p>
              </ModulePanel>,

              <ModulePanel key="book-action">
                {isBeforeInductionComplete ? (
                  <div>
                    <StyledStatus>Check your email</StyledStatus>
                    <span>We’ve sent you a link to book your interview.</span>
                  </div>
                ) : (
                  <div>
                    <StyledStatus>Complete Steps Above</StyledStatus>
                    <span>Please submit your work history and references.</span>
                  </div>
                )}
              </ModulePanel>,
            ]
          )}
        </MobileModule>

        <MobileModule
          tab="yellow"
          id="induction-after"
          mobilePanel={isAfterInductionComplete}
          panelled
        >
          {isAfterInductionComplete ? (
            <CompletedModule text="Your background checks and reference checks are complete" />
          ) : (
            [
              <ModulePanel key="header" size={1}>
                <ModuleHeader>After Interview</ModuleHeader>
              </ModulePanel>,
              <TaskPanel
                title="Background checks"
                id="background-checks"
                key="background-checks"
                className="disabled"
                complete={backgroundChecksComplete}
                incompleteJsx={
                  <p>
                    Submit your background checks and we will tell you when they
                    are complete
                  </p>
                }
                completeJsx={
                  <p key="background-checks-complete">
                    Your background checks are complete!
                  </p>
                }
              />,
              <TaskPanel
                title="Reference checks"
                className="disabled"
                id="reference-checks"
                key="reference-checks"
                complete={referencesCheckedHI}
                incompleteJsx={
                  <p>We require more employment references to work with HomeInstead</p>
                }
                completeJsx={
                  <p key="references-checs-complete">
                    Your references are checked!
                  </p>
                }
              />,
            ]
          )}
        </MobileModule>
      </section>
    );
  }
}

CarerOnboarding.propTypes = {
  carer: PropTypes.shape(),
  inductionSlot: PropTypes.shape(),
  profileComplete: PropTypes.bool.isRequired,
};

CarerOnboarding.defaultProps = {
  carer: null,
  inductionSlot: null,
};

const CompletedModule = ({ text }) => [
  <MobileModulePanel key="description" size={2}>
    <div style={{ textAlign: 'left' }}>{text}</div>
  </MobileModulePanel>,
  <MobileModulePanel key="tick-icon" id="tick-icon" center>
    <TickIcon />
  </MobileModulePanel>,
];

CompletedModule.propTypes = {
  text: PropTypes.string.isRequired,
};

const CompletedPanelContent = styled(MobilePanelContent)`
  @media (max-width: 767px) {
    margin-top: 8px;

    > * {
      vertical-align: middle;
    }

    > *:first-child {
      padding-right: 20px;
    }
  }
`;

const IncompletePanelContent = styled.div`
  @media (max-width: 767px) {
    > p {
      text-align: left;
    }
  }
`;

const TaskPanel = ({
  title,
  completeJsx,
  className,
  id,
  incompleteJsx,
  complete,
}) => (
  <ModulePanel id={id} className={complete ? 'complete' : className} size={1}>
    <h3>{title}</h3>
    {complete ? (
      <CompletedPanelContent>
        <SmileyFaceIcon key="smiley-face-icon" />
        {completeJsx}
      </CompletedPanelContent>
    ) : (
      <IncompletePanelContent>{incompleteJsx}</IncompletePanelContent>
    )}
  </ModulePanel>
);

TaskPanel.propTypes = {
  className: PropTypes.string,
  complete: PropTypes.bool,
  completeJsx: PropTypes.node,
  id: PropTypes.string,
  incompleteJsx: PropTypes.node,
  title: PropTypes.string.isRequired,
};

TaskPanel.defaultProps = {
  className: '',
  complete: false,
  completeJsx: null,
  id: null,
  incompleteJsx: null,
  size: 1,
};

export default CarerOnboarding;
