import React from 'react';
import styled from 'styled-components';
import { arrayOf, func, shape, string } from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import Button from 'components/buttons/Button';
import ButtonGroup from 'components/buttons/ButtonGroup';
import ButtonLink from 'components/buttons/ButtonLink';
import ButtonWithReason from 'components/buttons/ButtonWithReason';
import CarerImage from 'components/carers/CarerImage';
import Definition from 'components/global/informationdisplay/Definition';
import RatesBreakdown from 'components/bids/RatesBreakdown';
import ShiftDetails from 'components/shifts/ShiftDetails';
import StandardList from 'components/global/layout/StandardList';
import StatusIndicator from 'components/global/StatusIndicator';
import StyledSubheader from 'components/global/StyledSubheader';

import { SC_NUMBER } from 'components/global/ContactInfo';
import { trackEventCall } from 'components/helpers/trackingHelpers';

function BidsList(props) {
  const {
    carers,
    outstandingBids,
    rejectionReasons,
    rejectBid,
    schedule,
    shiftTypes,
    updateBid,
  } = props;

  return (
    <StandardList name="Bids" default>
      {outstandingBids.map(bid => (
        <BidRow
          key={bid.id}
          bid={bid}
          carer={carers[bid.carer_id]}
          shiftTypes={shiftTypes}
          updateBid={updateBid}
          rejectBid={rejectBid}
          rejectionReasons={rejectionReasons}
          schedule={schedule}
        />
      ))}
    </StandardList>
  );
}

const BidRowCarer = styled.div`
  background: ${props => props.theme.colors.lightGrey};
  vertical-align: top;
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;

  .image {
    margin: 10px auto 20px;
    display: block;
  }

  @media (min-width: 768px) {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0;
    padding: 20px;

    > a,
    > button {
      margin: 10px 0;
    }
    > div {
      text-align: left;
    }
  }
`;

const BidRowDetails = styled.span`
  display: block;
  padding: 0 20px;

  .status {
    float: right;
  }

  @media (min-width: 768px) {
    flex-basis: 0;
    flex-grow: 2.2;
    flex-shrink: 2.2;
    padding: 20px;
  }
`;

const FlexRow = styled.li`
  padding: 0 !important;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const BidRow = ({
  bid,
  carer,
  shiftTypes,
  updateBid,
  rejectBid,
  rejectionReasons,
  schedule,
}) => {
  if (!bid || !carer) {
    return null;
  }

  const viewCandidateProfileClicked = (carer, bid) => {
    trackEventCall('view candidate profile clicked', {
      carerID: carer.id,
      carerName: bid.carer_display_name,
      bidID: bid.id,
    });
  };

  const parsePhoneNumber = parsePhoneNumberFromString(carer.phone_number);
  const phoneCountry = parsePhoneNumber.country;
  const carerPhoneNumber =
    parsePhoneNumber && phoneCountry === 'GB'
      ? parsePhoneNumber.formatNational()
      : carer.phone_number;

  return (
    <FlexRow>
      <BidRowCarer>
        <CarerImage className="image" carer={carer} />
        <ButtonLink
          onClick={() => viewCandidateProfileClicked(carer, bid)}
          className="alt"
          to={`/carers/${carer.slug}/`}
        >
          View Profile
        </ButtonLink>
        <Button
          disabled={bid.details_requested}
          onClick={() => updateBid(bid.id, { details_requested: true })}
        >
          Contact carer
        </Button>
        {bid.details_requested && (
          <Definition label="Contact details">
            <p>
              {carerPhoneNumber ? (
                <>
                  Contact this carer on{' '}
                  <a href={`tel:${carerPhoneNumber}`}>{carerPhoneNumber}</a>.{' '}
                </>
              ) : (
                <>
                  Contact us on <a href={`tel:${SC_NUMBER}`}>{SC_NUMBER}</a>.{' '}
                </>
              )}
            </p>
          </Definition>
        )}
      </BidRowCarer>
      <BidRowDetails>
        <StatusIndicator status={bid.state} />
        <StyledSubheader>{bid.carer_display_name}</StyledSubheader>
        <Definition label="Shifts Available">
          <ShiftDetails
            schedule={schedule}
            appliedShifts={bid.available_schedule_shifts}
          />
        </Definition>
        {bid.description && (
          <Definition label={`Message from ${carer.firstname}`}>
            {bid.description}
          </Definition>
        )}
        {bid.admin_description && (
          <Definition label="Message from Home Instead Live-in Care">
            {bid.admin_description}
          </Definition>
        )}
        <Definition label="Rates">
          <RatesBreakdown
            shiftTypes={shiftTypes}
            rates={bid.rates}
            isCustomer
          />
        </Definition>
        <ButtonGroup>
          <Button
            disabled={bid.state === 'accepted'}
            onClick={() => updateBid(bid.id, { state: 'accepted' })}
          >
            Book carer
          </Button>
          {bid.state !== 'accepted' && (
            <ButtonWithReason
              className="danger"
              action={reason => rejectBid(bid.id, reason)}
              reasons={rejectionReasons}
              otherReasoning="Please tell us why to improve your future recommendations."
              cta="Remove from Shortlist"
              key="remove"
            />
          )}
        </ButtonGroup>
        {bid.state === 'accepted' &&
          'Great! We’ll confirm final schedule details and get back to you very shortly.'}
      </BidRowDetails>
    </FlexRow>
  );
};

BidRow.propTypes = {
  bid: shape(),
  carer: shape(),
  shiftTypes: arrayOf(string).isRequired,
  updateBid: func.isRequired,
  rejectBid: func.isRequired,
  rejectionReasons: arrayOf(shape()).isRequired,
  schedule: shape().isRequired,
};

BidRow.defaultProps = {
  bid: null,
  carer: null,
};

export default BidsList;
