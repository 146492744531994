import Module, { ModuleHeader } from 'components/global/layout/Module';
import StripeButton from 'components/payments/StripeButton';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { Container, Col, Row } from 'components/grid';

class StripeConnect extends Component {
  render() {
    const details = {
      response_type: 'code',
      client_id: window.global.STRIPE_CLIENT_ID,
      scope: 'read_write',
      stripe_user: {
        first_name: this.props.carer.firstname,
        last_name: this.props.carer.lastname,
        email: this.props.carer.email,
        physical_product: false,
        product_category: 'professional_services',
        product_description: 'Homecare services',
        business_name: `${this.props.carer.firstname} ${
          this.props.carer.lastname
        }`,
        business_type: 'sole_prop',
        street_address: this.props.carer.firstname.address_1,
        city: this.props.carer.firstname.address_2,
        state: this.props.carer.address_3,
        country: 'GB',
        url: `https://supercarers.com/carers/profile/${this.props.carer.slug}/`,
      },
    };

    const query = qs.stringify(details);
    const stripeUrl = `https://connect.stripe.com/oauth/authorize?${query}`;

    return (
      <Module>
        <ModuleHeader>Connect with Stripe</ModuleHeader>
        <Container>
          <Row>
            <Col>
              You will be paid through our billing platform Stripe. Click on the
              button below to enter your bank account details.
            </Col>
          </Row>
          <Row>
            <Col>You cannot be paid until this has been done.</Col>
          </Row>
          <Row>
            <Col>
              <StripeButton className="large" href={stripeUrl}>
                <span>Connect with Stripe</span>
              </StripeButton>
            </Col>
          </Row>
        </Container>
      </Module>
    );
  }
}

StripeConnect.propTypes = {
  carer: PropTypes.shape().isRequired,
};

export default StripeConnect;
