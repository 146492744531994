// $FlowFixMe
import React from 'react';
// $FlowFixMe
import { Field } from 'redux-form';
import styled from 'styled-components';

// $FlowFixMe
import { trim } from 'normalizers';
import { requiredEmail, validEmail } from 'validators';

import FormRow from 'components/forms/FormRow';
import { Container, Col, Row } from 'components/grid';
import StandardInput from 'components/forms/StandardInput';
import Button from 'components/buttons/Button';
import StyledLinkLogo from 'components/global/StyledLinkLogo';
import ValidationError from 'components/forms/ValidationError';

const ValidationErrorContainer = styled(ValidationError)`
  position: relative;
  top: -50px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.small}px) {
    position: absolute;
    top: -86px;
  }
`;

type Props = {
  handleSubmit: () => void,
  pristine: () => void,
  error: ?string,
};

function AccountRecoverPasswordForm({ handleSubmit, pristine, error }: Props) {
  return (
    <>
      {error && <ValidationErrorContainer>{error}</ValidationErrorContainer>}
      <form onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col xs={12} md={11} offset={{ md: 1 }}>
              <StyledLinkLogo />
            </Col>
          </Row>
          <FormRow>
            <Col xs={12} md={11} offset={{ md: 1 }}>
              <Field
                name="email"
                label="Email"
                component={StandardInput}
                type="text"
                validate={[requiredEmail, validEmail]}
                normalize={trim}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col xs={12} md={11} offset={{ md: 1 }}>
              <Button disabled={pristine} type="submit" fullWidth>
                Send Email
              </Button>
            </Col>
          </FormRow>
        </Container>
      </form>
    </>
  );
}

export default AccountRecoverPasswordForm;
