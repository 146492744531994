// @flow
import React from 'react';
import styled from 'styled-components';
// $FlowFixMe
import { Field } from 'redux-form';

import AdminJobOffersResult from 'components/jobOffers/AdminJobOffersResult';
import CollapsibleSection from 'components/global/dynamic/CollapsibleSection';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import LoadingSubmitButton from 'components/forms/LoadingSubmitButton';
import Paginator from 'components/global/Paginator';
import StandardInput from 'components/forms/StandardInput';
import StandardMultiSelect from 'components/forms/StandardMultiSelect';
import {
  TrueFalseSelect,
} from 'components/forms/ConvenienceSelects';
import Module, {
  ModuleHeader,
  ModuleMeta,
} from 'components/global/layout/Module';
import { Container, Row, Col } from 'components/grid';

import { taxonomyToMultiselect } from 'components/helpers/taxonomyHelpers';

import type {
  JobOfferType,
  LocationType,
  SearchType,
  TaxonomiesClassificationType,
} from 'shared/types';
import type { SearchJobOffersParams } from '_actions/jobOfferActions';

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

type Props = {|
  jobOffers: Array<JobOfferType>,
  location: LocationType,
  search: SearchType,
  searchJobOffers: (SearchJobOffersParams, number | string) => Object,
  stateCounts: {
    [string]: number,
  },
  taxonomiesByClassification: TaxonomiesClassificationType,
|};

const AdminJobOffersSearch = ({
  jobOffers,
  location,
  search,
  searchJobOffers,
  taxonomiesByClassification,
}: Props) => {
  return (
    <div>
      <Form
        name="jobOfferSearch"
        fields={[
          'account_manager',
          'state',
          'customer_name',
          'caree_name',
          'location',
          'care_type',
          'carer_gender',
          'incentive_customer',
          'incentive_supercarers',
          'pets',
          'priority',
          'needs_license',
          'needs_car',
        ]}
        onSubmit={searchJobOffers}
        component={({ handleSubmit, reset }) => (
          <form onSubmit={handleSubmit}>
            <Module>
              <Container>
                <Row>
                  <Col xs={12} md={4}>
                    <StyledHeader>Find Job Offers</StyledHeader>
                  </Col>
                  <Col xs={12} md={8}>
                    <ModuleMeta>
                      <div role="button" onClick={reset}>
                        Reset filters
                      </div>
                    </ModuleMeta>
                  </Col>
                </Row>
                <FormRow>
                  <Col xs={12} md={4}>
                    <Field
                      component={StandardInput}
                      label="Reference"
                      name="reference"
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Field
                      component={StandardInput}
                      label="Outcode"
                      name="outcode"
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Field
                      component={StandardInput}
                      label="Location"
                      name="location"
                    />
                  </Col>
                </FormRow>
                <FormRow>
                  <Col xs={12} md={12}>
                    <Field
                      label="Care recipient name"
                      component={StandardInput}
                      name="caree_name"
                    />
                  </Col>
                </FormRow>
              </Container>
              <CollapsibleSection
                subheader="Show more filters"
                id="extra-filters"
                maxHeight="800px"
                openByDefault={false}
              >
                <Container>
                  <FormRow>
                    <Col xs={12} md={3}>
                      <Field
                        label="Gender"
                        component={StandardMultiSelect}
                        name="carer_gender"
                        options={taxonomyToMultiselect(
                          taxonomiesByClassification.gender_preference,
                        )}
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <Field
                        label="Pets"
                        component={StandardMultiSelect}
                        name="pets"
                        options={taxonomyToMultiselect(
                          taxonomiesByClassification.pets,
                        )}
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <Field
                        component={TrueFalseSelect}
                        label="Driver"
                        name="needs_license"
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <Field
                        label="Car"
                        component={TrueFalseSelect}
                        name="needs_car"
                      />
                    </Col>
                  </FormRow>
                </Container>
              </CollapsibleSection>
              <Container>
                <FormRow>
                  <Col xs={12} md={4}>
                    <LoadingSubmitButton
                      label="Search"
                      loadingState="jobOffers"
                      loadingType="jobOffers"
                      formName="jobOfferSearch"
                    />
                  </Col>
                </FormRow>
              </Container>
            </Module>
          </form>
        )}
      />
      <Module>
        <ModuleHeader>Job Offer Results</ModuleHeader>
        <AdminJobOffersResult
          jobOffers={jobOffers}
          taxonomiesByClassification={taxonomiesByClassification}
        />
        {search.pagination && (
          <Paginator
            currentPage={search.pagination.page}
            totalPages={search.pagination.pages}
            location={location}
          />
        )}
      </Module>
    </div>
  );
};

export default AdminJobOffersSearch;
