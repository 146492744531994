// @flow
import * as React from 'react';
import Link from 'react-router-redux-dom-link';

import FlexTable from 'components/global/layout/FlexTable';
import FlexTableRow from 'components/global/layout/FlexTableRow';
import FlexTableItem from 'components/global/layout/FlexTableItem';
import { EditIcon } from 'components/global/icons';
import StyledCheckbox from 'components/forms/StyledCheckbox';

import type {
  FranchiseType,
  TaxonomiesClassificationType,
} from 'shared/types';

type Props = {
  displayArchived: boolean,
  franchise: FranchiseType,
  franchiseUsers: Array,
  isAdmin: boolean,
  taxonomiesByClassification: TaxonomiesClassificationType,
  updateLeadContact: (value: string) => Object,
};


const FranchiseUsersTable = ({
  displayArchived,
  franchise,
  franchiseUsers,
  isAdmin,
  taxonomiesByClassification,
  updateLeadContact,
}: Props) => (
  <FlexTable moduleStyling>
    <FlexTableRow>
      <FlexTableItem xs={2} md={2}>Name</FlexTableItem>
      <FlexTableItem xs={0} md={2}>Email</FlexTableItem>
      <FlexTableItem xs={0} md={1}>Lead Contact</FlexTableItem>
      <FlexTableItem xs={1} md={1}>Edit</FlexTableItem>
    </FlexTableRow>
    {franchiseUsers.map((user, index) => {
      if (!displayArchived && user.archived) {
        return null;
      }
      return (
        <FlexTableRow key={`franchise-user-${index}`}>
          <FlexTableItem xs={2} md={2}>
            {user.name}
          </FlexTableItem>
          <FlexTableItem xs={0} md={2}>
            {user.archived ? 'ARCHIVED' : user.email}
          </FlexTableItem>
          <FlexTableItem xs={0} md={1}>
            <StyledCheckbox
              checked={user.id === franchise.lead_contact_id}
              name={user.id}
              onChange={() => updateLeadContact(user.id)}
              value={user.id}
            />
          </FlexTableItem>
          <FlexTableItem xs={1} md={1}>
            {isAdmin ?
              <Link to={`/admin/users/${user.id}/`}>
                <EditIcon />
              </Link>
              :
              "Contact Team"
            }
          </FlexTableItem>
        </FlexTableRow>
      );
    })}
  </FlexTable>
);

export default FranchiseUsersTable;
