// @flow
import React from 'react';
import styled from 'styled-components';

import InputWrapper from 'components/forms/InputWrapper';
import StyledTitleWithLabel from 'components/forms/StyledTitleWithLabel';
import StyledTextarea from 'components/forms/StyledTextarea';
import WysiwygTextarea from 'components/forms/WysiwygTextarea';

import type { MetaType } from 'shared/types';

const WordCount = styled.div`
  color: ${props => props.theme.colors.midGrey};
  text-align: right;
`;

const CustomStyledTextarea = styled(StyledTextarea)`
  font-family: inherit;
  &.isChanged {
    border-color: ${props => props.theme.colors.changeGreen};
    border-width: 2px;
  }
`;

type Props = {
  changes: {
    kind: string,
    lhs: string,
    rhs: string,
  },
  children: React$Element<any>,
  extraPadding: boolean,
  greyTitle: boolean,
  help: ?string,
  input: HTMLInputElement,
  label: string,
  meta: MetaType,
  placeholder?: string,
  required: boolean | string,
  type: string,
  charCountLimit: string,
  wordCountLimit: string,
  title: ?string,
  wysiwyg: boolean,
  otherProps: {
    minimum: ?number,
  },
};

const StandardTextarea = ({
  changes,
  children,
  extraPadding,
  greyTitle,
  help,
  input,
  label,
  meta,
  type,
  placeholder,
  charCountLimit,
  wordCountLimit,
  title,
  wysiwyg,
  ...otherProps
}: Props) => {
  const { touched, error } = meta;
  const inputValue = input.value.trim();
  const wordCount = inputValue === '' ? 0 : inputValue.split(' ').length;
  const charCount = inputValue === '' ? 0 : input.value.length;
  const wysiwygOptions = {
    toolbar: {
      buttons: ['bold', 'italic', 'underline'],
    },
    placeholder: {
      text: placeholder,
    },
  };

  const textareaOptions = {
    className: `${touched && error ? 'error' : ''} ${
      changes.kind ? 'isChanged' : ''
    }`,
    id: `${input.name}`,
    options: wysiwyg && wysiwygOptions,
    placeholder: placeholder,
    text: wysiwyg && input.value,
  };

  return (
    <InputWrapper {...{ meta }}>
      <StyledTitleWithLabel
        title={title}
        label={label}
        help={help}
        error={touched && error}
        extraPadding={extraPadding}
        greyTitle={greyTitle}
      />
      {wysiwyg ? (
        <WysiwygTextarea {...{ ...textareaOptions, ...input }}>
          {children}
        </WysiwygTextarea>
      ) : (
        <CustomStyledTextarea {...{ ...textareaOptions, ...input }}>
          {children}
        </CustomStyledTextarea>
      )}

      {charCountLimit && (
        <WordCount>{`${charCount}/${charCountLimit}`}</WordCount>
      )}
      {wordCountLimit && (
        <WordCount>{`${wordCount}/${wordCountLimit}`}</WordCount>
      )}
    </InputWrapper>
  );
};

StandardTextarea.defaultProps = {
  changes: {},
  children: null,
  help: null,
  label: null,
  type: 'text',
  minimum: null,
  title: '',
};

export default StandardTextarea;
