import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import NeedResults from 'components/admin/NeedResults';
import NeedSearchFilters from 'components/admin/NeedSearchFilters';
import Module, {
  ModuleHeader,
  ModuleHero,
} from 'components/global/layout/Module';

class AdminNeedSearch extends Component {
  render() {
    let results;
    const { needs, location, search, taxonomiesByClassification } = this.props;

    if (!search) {
      results = <LoadingPlaceholder />;
    } else if (search.searchOrder && search.searchOrder.length) {
      results = (
        <NeedResults
          needs={needs}
          pagination={search.pagination}
          location={location}
          taxonomiesByClassification={taxonomiesByClassification}
        />
      );
    } else {
      results = <ModuleHero>No needs found</ModuleHero>;
    }

    return (
      <div>
        <NeedSearchFilters
          filters={this.props.filters}
          location={this.props.location}
          updateFilters={this.props.updateFilters}
        />
        <Module>
          <ModuleHeader>Needs</ModuleHeader>
          {results}
        </Module>
      </div>
    );
  }
}

AdminNeedSearch.propTypes = {
  filters: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  needs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  search: PropTypes.shape(),
  taxonomiesByClassification: PropTypes.shape().isRequired,
  updateFilters: PropTypes.func.isRequired,
};

AdminNeedSearch.defaultProps = {
  search: {},
};

export default AdminNeedSearch;
