import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Currency from 'components/modules/Currency';

class TimesheetTotalCarer extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    timesheetTotal: PropTypes.shape({
      application_total: PropTypes.number.isRequired,
      application_total_gross: PropTypes.number.isRequired,
      application_total_net: PropTypes.number.isRequired,
      carer_total: PropTypes.number.isRequired,
      carer_total_gross: PropTypes.number.isRequired,
      carer_total_net: PropTypes.number.isRequired,
      customer_total: PropTypes.number.isRequired,
      customer_total_net: PropTypes.number.isRequired,
      insurance_payment: PropTypes.number.isRequired,
      livein_days: PropTypes.number.isRequired,
      minutes_total: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <tbody>
        <tr className="total">
          <td rowSpan="1">
            <strong>
              {this.props.isAdmin ? 'Carer ' : ''}Payment Received
            </strong>
          </td>
          <td />
          <td />
          <td className="number">
            <strong>
              <Currency value={this.props.timesheetTotal.carer_total_net} />
            </strong>
          </td>
        </tr>
      </tbody>
    );
  }
}

export default TimesheetTotalCarer;
