// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCarerById } from '_actions/carerActions';
import { searchRefunds } from '_actions/refundActions';
import { getTimesheetTotalById } from '_actions/timesheetActions';
import CareNeedActions from 'actions/CareNeedActions';
import TimesheetActions from 'actions/TimesheetActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import stateHelper from 'components/helpers/stateHelper';
import TimesheetDetailsConfirm from 'components/timesheets/TimesheetDetailsConfirm';
import { isAdmin } from 'shared/selectors/accountSelectors';

import { getCarerforTimesheetByID } from 'shared/selectors/carerSelectors';
import { getNeedforTimesheetByID } from 'shared/selectors/needSelectors';

import { getRefundsForTimesheetByID } from 'shared/selectors/refundSelectors';
import { getTimesheetByID } from 'shared/selectors/timesheetSelectors';

import { getTimesheetTotalByID } from 'shared/selectors/totalSelectors';

import type {
  CarerType,
  MatchType,
  RefundType,
  TimesheetType,
  TotalType,
} from 'shared/types';

type Props = {
  carer: CarerType,
  timesheet: TimesheetType,
  total: TotalType,
  refunds: Array<RefundType>,
  match: MatchType,
  isAdmin: boolean,
  searchRefunds: ({ charge_id: string }) => Object,
  getCarerById: string => Object,
  getTimesheetTotalById: string => Object,
};

function TimesheetDetailsConfirmContainer({
  carer,
  isAdmin,
  match,
  refunds = [],
  timesheet,
  total,
  getCarerById,
  getTimesheetTotalById,
  searchRefunds,
}: Props) {
  useEffect(() => {
    const timesheetId = match.params.timesheetID;
    TimesheetActions.getTimesheetById(timesheetId).then(body => {
      CareNeedActions.getNeedById(body.data.need_id);
      if (isAdmin) {
        getCarerById(body.data.carer_id);
      }
      if (!body.data.totals) {
        getTimesheetTotalById(timesheetId);
      }
      if (body.data.charge_id) {
        searchRefunds({ charge_id: body.data.charge_id });
      }
    });
  }, [match.params.timesheetID]);

  const totals = timesheet && (timesheet.totals || total);

  if (!timesheet || !totals) {
    return <LoadingPlaceholder />;
  }

  let stripeEnabled = true;
  if (carer) {
    stripeEnabled = carer.has_stripe_account && carer.stripe_account_enabled;
  }

  return (
    <TimesheetDetailsConfirm
      state={stateHelper.timesheetDisplayState(timesheet)}
      timesheet={timesheet}
      totals={totals}
      refunds={refunds}
      isAdmin={isAdmin}
      carer={carer}
      empty={
        timesheet.shifts && timesheet.shifts.length === 0 && timesheet.expenses
          ? timesheet.expenses.length === 0
          : false
      }
      stripeEnabled={stripeEnabled}
    />
  );
}

const mapStateToProps = (state, props) => ({
  carer: getCarerforTimesheetByID(state, props.match.params.timesheetID),
  isAdmin: isAdmin(state),
  need: getNeedforTimesheetByID(state, props.match.params.timesheetID),
  refunds: getRefundsForTimesheetByID(state, props.match.params.timesheetID),
  timesheet: getTimesheetByID(state, props.match.params.timesheetID),
  total: getTimesheetTotalByID(state, props.match.params.timesheetID),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCarerById,
      getTimesheetTotalById,
      searchRefunds,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimesheetDetailsConfirmContainer);
