// @flow
import ApiConstants from 'shared/constants/ApiConstants';
import ScheduleConstants from 'shared/constants/ScheduleConstants';
import { ExtractReturn } from 'shared/types/ExtractReturn';
import type { APIAction } from 'shared/types';

// $FlowFixMe
export function createSchedule(data, tag) {
  return {
    endpoint: '/schedule/',
    method: 'post',
    onError: { type: ScheduleConstants.SCHEDULE_CREATE_FAILURE },
    onStart: { type: ScheduleConstants.SCHEDULE_CREATE },
    onSuccess: { type: ScheduleConstants.SCHEDULE_CREATE_SUCCESS, tag },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

// $FlowFixMe
export function modifySchedule(scheduleId, data) {
  return {
    endpoint: `/schedule/${scheduleId}/`,
    method: 'put',
    onError: { type: ScheduleConstants.SCHEDULE_MODIFY_FAILURE },
    onStart: { type: ScheduleConstants.SCHEDULE_MODIFY },
    onSuccess: {
      type: ScheduleConstants.SCHEDULE_MODIFY_SUCCESS,
      scheduleID: scheduleId,
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

// $FlowFixMe
export function deleteSchedule(scheduleId) {
  return {
    endpoint: `/schedule/${scheduleId}/`,
    method: 'delete',
    onError: { type: ScheduleConstants.SCHEDULE_DELETE_FAILURE },
    onStart: { type: ScheduleConstants.SCHEDULE_DELETE },
    onSuccess: {
      type: ScheduleConstants.SCHEDULE_DELETE_SUCCESS,
      scheduleID: scheduleId,
      toast: {
        type: 'success',
        public: false,
        title: 'Schedule deleted',
      },
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function archiveSchedule(
  scheduleId: string,
  archive: boolean,
): APIAction {
  return {
    endpoint: `/schedule/${scheduleId}/archive/`,
    payload: { archive },
    method: 'put',
    onError: { type: ScheduleConstants.SCHEDULE_ARCHIVE_FAILURE },
    onStart: { type: ScheduleConstants.SCHEDULE_ARCHIVE },
    onSuccess: {
      type: ScheduleConstants.SCHEDULE_ARCHIVE_SUCCESS,
      scheduleID: scheduleId,
      toast: {
        type: 'success',
        public: false,
        title: 'Schedule archived',
      },
    },
    type: ApiConstants.API_REQUEST,
  };
}

// $FlowFixMe
export function getScheduleById(scheduleId) {
  return {
    endpoint: `/schedule/${scheduleId}/`,
    method: 'get',
    onError: { type: ScheduleConstants.SCHEDULE_GET_BY_ID_FAILURE },
    onStart: { type: ScheduleConstants.SCHEDULE_GET_BY_ID },
    onSuccess: { type: ScheduleConstants.SCHEDULE_GET_BY_ID_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

// $FlowFixMe
export function getSchedulesByParams(params) {
  return {
    endpoint: '/schedule/find/',
    method: 'get',
    onError: { type: ScheduleConstants.SCHEDULE_GET_BY_PARAMS_FAILURE },
    onStart: { type: ScheduleConstants.SCHEDULE_GET_BY_PARAMS },
    onSuccess: { type: ScheduleConstants.SCHEDULE_GET_BY_PARAMS_SUCCESS },
    payload: params,
    type: ApiConstants.API_REQUEST,
  };
}

// $FlowFixMe
export function fetchAllSchedulesForUser(userID) {
  return {
    type: ScheduleConstants.SCHEDULE_GET_ALL_FOR_USER,
    payload: {
      userID,
    },
  };
}

// $FlowFixMe
export function getSchedulesByNeedID(needID) {
  return {
    endpoint: '/schedule/find/',
    method: 'get',
    onError: { type: ScheduleConstants.SCHEDULE_GET_BY_PARAMS_FAILURE },
    onStart: { type: ScheduleConstants.SCHEDULE_GET_BY_PARAMS },
    onSuccess: { type: ScheduleConstants.SCHEDULE_GET_BY_PARAMS_SUCCESS },
    payload: { care_need_id: needID },
    type: ApiConstants.API_REQUEST,
  };
}

// $FlowFixMe
export function createScheduleShift(payload, tag) {
  return {
    endpoint: '/schedule_shift/',
    method: 'post',
    onError: { type: ScheduleConstants.SCHEDULE_SHIFT_CREATE_FAILURE, tag },
    onStart: { type: ScheduleConstants.SCHEDULE_SHIFT_CREATE },
    onSuccess: { type: ScheduleConstants.SCHEDULE_SHIFT_CREATE_SUCCESS, tag },
    payload,
    type: ApiConstants.API_REQUEST,
  };
}

// $FlowFixMe
export function updateScheduleShift(id, payload, tag) {
  return {
    endpoint: `/schedule_shift/${id}/`,
    method: 'put',
    onError: { type: ScheduleConstants.SCHEDULE_SHIFT_UPDATE_FAILURE, tag },
    onStart: { type: ScheduleConstants.SCHEDULE_SHIFT_UPDATE },
    onSuccess: {
      type: ScheduleConstants.SCHEDULE_SHIFT_UPDATE_SUCCESS,
      scheduleShiftID: id,
      tag,
    },
    payload,
    type: ApiConstants.API_REQUEST,
  };
}

// $FlowFixMe
export function deleteScheduleShift(id) {
  return {
    endpoint: `/schedule_shift/${id}/`,
    method: 'delete',
    onStart: { type: ScheduleConstants.SCHEDULE_SHIFT_DELETE },
    onSuccess: {
      type: ScheduleConstants.SCHEDULE_SHIFT_DELETE_SUCCESS,
      scheduleShiftID: id,
    },
    onError: { type: ScheduleConstants.SCHEDULE_SHIFT_DELETE_FAILURE },
    type: ApiConstants.API_REQUEST,
  };
}

// $FlowFixMe
export function updateSchedule(schedule, needID, scheduleId) {
  return {
    type: ScheduleConstants.UPDATE_SCHEDULE_REQUEST,
    schedule,
    needID,
    scheduleId,
  };
}

// $FlowFixMe
export function updateShifts(shifts, scheduleId) {
  return {
    type: ScheduleConstants.UPDATE_SHIFTS_REQUEST,
    shifts,
    scheduleId,
  };
}

// $FlowFixMe
export function createScheduleWithShifts(schedule, scheduleTimes) {
  return {
    type: ScheduleConstants.CREATE_SCHEDULE_WITH_SHIFTS,
    schedule,
    scheduleTimes,
  };
}

// $FlowFixMe
export function getScheduleShiftsByParams(params) {
  return {
    endpoint: '/schedule_shift/find/',
    method: 'get',
    onError: { type: ScheduleConstants.SCHEDULE_SHIFT_GET_BY_PARAMS_FAILURE },
    onStart: { type: ScheduleConstants.SCHEDULE_SHIFT_GET_BY_PARAMS },
    onSuccess: { type: ScheduleConstants.SCHEDULE_SHIFT_GET_BY_PARAMS_SUCCESS },
    payload: params,
    type: ApiConstants.API_REQUEST,
  };
}

// $FlowFixMe
export function getSchedulesWithScheduleShiftsByScheduleIDs(scheduleIDs) {
  return {
    type: ScheduleConstants.GET_SCHEDULES_WITH_SCHEDULE_SHIFTS_BY_SCHEDULE_IDS,
    scheduleIDs,
  };
}

export function getSchedulesWithScheduleShiftsByNeedID(
  needID: string,
  searchParams: { archived: boolean },
) {
  return {
    type: ScheduleConstants.GET_SCHEDULE_WITH_SCHEDULE_SHIFTS_BY_NEED_ID,
    needID,
    searchParams,
  };
}

export type SchedulesActions = ExtractReturn<
  typeof getSchedulesWithScheduleShiftsByNeedID,
>;
