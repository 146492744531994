import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';

class Form extends Component {
  /* Wrapper component for hooking a <form> into redux forms
   *
   * To use you must pass a component with the following signature
   *
   * ({ handleSubmit }) => <form onSubmit={handleSubmit} />
   *
   * All props supported by redux forms are passed into the component
   *
   * If you want to display the current values of some of the fields, specify them in selectFields.
   * They will then be available in your component in the currentValues prop.
   *
   */
  static propTypes = {
    component: PropTypes.func.isRequired,
    destroyOnUnmount: PropTypes.bool,
    enableReinitialize: PropTypes.bool,
    fields: PropTypes.arrayOf(PropTypes.string),
    selectFields: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    values: PropTypes.shape(),
    otherProps: PropTypes.shape(),
    validate: PropTypes.func,
  };

  static defaultProps = {
    destroyOnUnmount: true,
    enableReinitialize: true,
    fields: [],
    onSubmit: null,
    selectFields: [],
    values: {},
    otherProps: {},
    validate: null,
  };

  constructor(props) {
    super(props);
    // ReduxForm must be created in the constructor otherwises a new ReduxForm is created
    // whenever initialValues are changed

    // connect the component to the redux form reducer
    const ReduxForm = reduxForm({
      asyncValidate: props.asyncValidate,
      asyncBlurFields: props.asyncBlurFields,
      shouldAsyncValidate: props.shouldAsyncValidate,
      destroyOnUnmount: props.destroyOnUnmount !== false,
      form: props.name,
      validate: props.validate,
      onSubmit: props.onSubmit,
    })(props.component);

    // set up a selector for each field into the currentValues prop
    const selector = formValueSelector(props.name);
    this.ReduxForm = connect(state => {
      const syncErrors = getFormSyncErrors(props.name)(state);
      if (props.selectFields.length > 0) {
        let currentValues;
        if (props.selectFields.length === 1) {
          const selectField = props.selectFields[0];
          currentValues = {
            [selectField]: selector(state, selectField),
          };
        } else {
          currentValues = selector(state, ...props.selectFields);
        }
        return { currentValues, syncErrors };
      }
      return { syncErrors };
    })(ReduxForm);
  }

  render() {
    // filter out only the values that we are interested in
    let initialValues = {};
    if (this.props.values) {
      initialValues = this.props.fields.reduce((accumulator, field) => {
        // eslint-disable-next-line no-param-reassign
        accumulator[field] = this.props.values[field];
        return accumulator;
      }, {});
    }

    const enableReinitialize = this.props.enableReinitialize !== false;

    return (
      <this.ReduxForm
        enableReinitialize={enableReinitialize}
        initialValues={initialValues}
        {...this.props.otherProps}
      />
    );
  }
}

export default Form;
