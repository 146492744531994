import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Paginator from 'components/global/Paginator';
import TeamResultRow from 'components/admin/TeamResultRow';

class TeamResults extends Component {
  render() {
    return (
      <div>
        <table className="teams">
          <thead>
            <tr>
              <th>Customer</th>
              <th>State</th>
              <th>State Age</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {this.props.teams.map(team => (
              <TeamResultRow key={team.id} id={team.id} team={team} />
            ))}
          </tbody>
        </table>
        <Paginator
          currentPage={this.props.search.pagination.page}
          totalPages={this.props.search.pagination.pages}
          location={this.props.location}
        />
      </div>
    );
  }
}

TeamResults.propTypes = {
  location: PropTypes.shape().isRequired,
  search: PropTypes.shape(),
  teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

TeamResults.defaultProps = {
  search: null,
};

export default TeamResults;
