import { createSelector } from 'reselect';
import _ from 'lodash';

import { getUser, isCarer } from 'shared/selectors/accountSelectors';
import { getChargeByID } from 'shared/selectors/chargeSelectors';
import { getTimesheetByID } from 'shared/selectors/timesheetSelectors';

export const getCarers = state => state.carers;
export const getCarerSearch = state => state.search.carers;
export const getCarerProfileSearch = state => state.search.carerProfiles;
export const getCarerProfiles = state => state.carerProfiles;
export const getCarerByID = (state, idOrSlug) =>
  state.carers[idOrSlug] ||
  Object.values(state.carers).find(carer => carer.slug === idOrSlug);

export const getLiveProfileByCarerID = (state, id) =>
  state.carerProfiles.live[id];

export const getDraftProfileByCarerID = (state, id) =>
  state.carerProfiles.draft[id];

export const getUserDraftProfile = createSelector(
  [getUser, getCarerProfiles],
  (user, carerProfiles) => carerProfiles.draft[user.id],
);

export const selectCarerConflictsByID = createSelector(
  [getCarerByID],
  carer => (carer ? carer.conflicts : null),
);

export const selectCarerBidConflictsByID = createSelector(
  [selectCarerConflictsByID],
  conflicts => {
    if (!conflicts || !conflicts.conflicting_bids) {
      return [];
    }

    return _.flatten(Object.values(conflicts.conflicting_bids));
  },
);

export const getCarerByChargeID = createSelector(
  [getCarers, getChargeByID],
  (carers, charge) => {
    if (!carers || !charge) {
      return null;
    }
    return carers[charge.carer_id];
  },
);

export const getUserAsCarer = createSelector(
  [getCarers, getUser],
  (carers, user) => {
    if (!carers || !user) {
      return null;
    }
    return carers[user.id];
  },
);

export const getCarerResults = createSelector(
  [getCarers, getCarerSearch],
  (carers, search) => {
    if (!search || !search.searchOrder) {
      return [];
    }
    const results = [];
    search.searchOrder.forEach(id => {
      const match = carers[id];
      if (match) {
        results.push(match);
      }
    });
    return results;
  },
);

export const getCarerforTimesheetByID = createSelector(
  [getCarers, getTimesheetByID],
  (carers, timesheet) => {
    if (!carers || !timesheet) {
      return null;
    }
    return Object.values(carers).find(carer => carer.id === timesheet.carer_id);
  },
);

export const selectCarerInductionSlot = createSelector(
  [getUserAsCarer],
  carer => (carer ? carer.induction_slot : null),
);

function isProfileComplete(profileDetails) {
  if (!profileDetails) {
    return false;
  }

  const emptyBio =
    profileDetails.profile_data.bio || profileDetails.profile_data.bio === '';
  return !!emptyBio;
}

export const getCarerProfileCompleted = createSelector(
  [getUserDraftProfile, getUserAsCarer],
  (draftProfile, carer) => {
    if (isProfileComplete(carer && carer.profile_details)) {
      return true;
    }

    if (isProfileComplete(draftProfile)) {
      return true;
    }

    return false;
  },
);

export const isRejectedCarer = createSelector(
  [getUser, getCarers, isCarer],
  (user, carers, isCarer) => {
    if (!isCarer || !user || !carers || !Object.entries(carers).length) {
      return null;
    }
    const profileDetails = carers[user.id].profile_details;
    if (!profileDetails) return null;

    return profileDetails && profileDetails.state === 'rejected';
  },
);
