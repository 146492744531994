// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  addNoteForObject,
  getNotesForObject,
  updateNote,
  type GetNotesForObjectType,
} from '_actions/noteActions';
import { selectNotesByObjectID } from 'shared/selectors/noteSelectors';

import Notes from 'components/notes/Notes';

import type { NoteType } from 'shared/types';

type Props = {|
  category?: string,
  excludeCategories?: Array<string>,
  notes: NoteType,
  noteType: string,
  objectID: string,
  placeholder?: string,
  addNoteForObject: (string, string, string, ?string, string) => void,
  getNotesForObject: GetNotesForObjectType => void,
  updateNote: (string, string) => void,
|};

const NotesContainer = (props: Props) => {

  const {
    category,
    excludeCategories,
    notes,
    noteType,
    objectID,
    placeholder,
    updateNote,
  } = props;

  useEffect(() => {
    const payload: GetNotesForObjectType = {
      object_id: props.objectID,
    };
    if (category) {
      payload.category = category;
    }
    if (noteType) {
      payload.object_type = noteType;
    }
    props.getNotesForObject(payload);
  }, []);

  const addNote = (values: { message: string, category?: string }) => {
    props.addNoteForObject(
      noteType,
      objectID,
      values.message,
      category || values.category,
      'notes',
    );
  };

  let displayNotes = notes;
  if (!!excludeCategories) {
    displayNotes = notes.filter(note => !excludeCategories.includes(note.category));
  }

  return (
    <Notes
      addNote={addNote}
      notes={displayNotes}
      category={category}
      updateNote={updateNote}
      placeholder={placeholder}
    />
  );
};

NotesContainer.defaultProps = {
  category: null,
  excludeCategories: [],
  objectID: null,
  noteType: 'User',
  note: null,
};

const mapStateToProps = (state, props) => ({
  notes: selectNotesByObjectID(state, props.objectID, props.category),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addNoteForObject,
      getNotesForObject,
      updateNote,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotesContainer);
