import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';

import Module, {
  ModuleHeader,
  ModuleMeta,
} from 'components/global/layout/Module';

import StatusIndicator from 'components/global/StatusIndicator';
import PersonalDetailsForm from 'components/account/PersonalDetailsForm';
import CarerImage from 'components/carers/CarerImage';
import ButtonLink from 'components/buttons/ButtonLink';
import FileUploadButton from 'components/inputs/FileUploadButton';
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import ZohoButton from 'components/global/ZohoButton';
import ProfilePDFLink from 'components/carers/profile/ProfilePDFLink';

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

const StyledImagePicker = styled.div`
  margin-bottom: 20px;

  > span,
  > a {
    vertical-align: middle;
    display: inline-block;

    & + span {
      margin: 0 20px;
    }
  }
`;

class AdminUserPersonal extends Component {
  static propTypes = {
    user: PropTypes.shape().isRequired,
    updateAccount: PropTypes.func.isRequired,
    uploadProfileImage: PropTypes.func.isRequired,
  };

  render() {
    const { taxonomiesByClassification, updateAccount, user } = this.props;

    const isCarer = user.roles.includes('Carer');

    return (
      <Module>

        <Container>

          <Row>
            <Col xs={12} md={6}>
              <StyledHeader>Personal Details</StyledHeader>
            </Col>
            <Col xs={12} md={6}>
              <ModuleMeta>
                <StatusIndicator status={user.roles.join(', ')} />
                {user.care_needs &&
                  user.care_needs.map(need => (
                    <Link to={`/admin/needs/${need.id}/`}>
                      View need for {need.name}
                    </Link>
                  ))}
              </ModuleMeta>
            </Col>
          </Row>

          {user.zoho_id && (
            <Row>
              <Col xs={12} md={6}>
                <ZohoButton id={user.zoho_id} user />
              </Col>
            </Row>
          )}

        </Container>

        {isCarer && (
          <Container>

            <Row>

              <Col xs={12} md={6}>

                <StyledImagePicker>

                  <CarerImage carer={user} />

                  <FileUploadButton onChange={this.props.uploadProfileImage}>
                    Choose New Image
                  </FileUploadButton>

                  <ButtonLink
                    to={`/admin/carers/profile/${user.id}/`}
                    className="alt"
                  >
                    Edit Profile
                  </ButtonLink>

                  <Link
                    to={`/carers/${user.slug}`}
                    style={{ display: 'block', margin: '10px 5px 20px' }}
                  >
                    View Profile
                  </Link>

                </StyledImagePicker>

              </Col>

              <Col xs={12} md={6}>
                <ModuleMeta>
                  <ProfilePDFLink carerID={user.id} />
                </ModuleMeta>
              </Col>

            </Row>

          </Container>
        )}

        <PersonalDetailsForm
          isCarer={isCarer}
          taxonomiesByClassification={taxonomiesByClassification}
          user={user}
          updateAccount={updateAccount}
        />
      </Module>
    );
  }
}

export default AdminUserPersonal;
