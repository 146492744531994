import keyMirror from 'keymirror';

const JobOfferConstants = keyMirror({
  JOB_OFFER_GET_BY_ID: null,
  JOB_OFFER_GET_BY_ID_SUCCESS: null,
  JOB_OFFER_GET_BY_ID_FAILURE: null,

  JOB_OFFER_GET_BY_ID_FOR_MULTIPLE: null,
  JOB_OFFER_GET_BY_ID_FOR_MULTIPLE_SUCCESS: null,
  JOB_OFFER_GET_BY_ID_FOR_MULTIPLE_FAILURE: null,

  JOB_OFFER_GET_BY_REFERENCE: null,
  JOB_OFFER_GET_BY_REFERENCE_SUCCESS: null,
  JOB_OFFER_GET_BY_REFERENCE_FAILURE: null,

  JOB_OFFER_GET_BY_REFERENCE_FOR_CARER: null,
  JOB_OFFER_GET_BY_REFERENCE_FOR_CARER_SUCCESS: null,
  JOB_OFFER_GET_BY_REFERENCE_FOR_CARER_FAILURE: null,

  JOB_OFFER_GET_BID_SUMMARY: null,
  JOB_OFFER_GET_BID_SUMMARY_SUCCESS: null,
  JOB_OFFER_GET_BID_SUMMARY_FAILURE: null,

  JOB_OFFER_GET_MATCH_TOTAL: null,
  JOB_OFFER_GET_MATCH_TOTAL_SUCCESS: null,
  JOB_OFFER_GET_MATCH_TOTAL_FAILURE: null,

  JOB_OFFER_CREATE: null,
  JOB_OFFER_CREATE_SUCCESS: null,
  JOB_OFFER_CREATE_FAILURE: null,

  JOB_OFFER_UPDATE: null,
  JOB_OFFER_UPDATE_SUCCESS: null,
  JOB_OFFER_UPDATE_FAILURE: null,

  JOB_OFFER_NOTIFY_OF_BIDS: null,
  JOB_OFFER_NOTIFY_OF_BIDS_SUCCESS: null,
  JOB_OFFER_NOTIFY_OF_BIDS_FAILURE: null,

  JOB_OFFER_SEARCH: null,
  JOB_OFFER_SEARCH_SUCCESS: null,
  JOB_OFFER_SEARCH_FAILURE: null,

  JOB_OFFER_GET_STATUS: null,
  JOB_OFFER_GET_STATUS_SUCCESS: null,
  JOB_OFFER_GET_STATUS_FAILURE: null,
});

export default JobOfferConstants;
