import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Row, Col } from 'components/grid';
import Module from 'components/global/layout/Module';
import styled from 'styled-components';

const CustomModule = styled(Module)`
  overflow: hidden;
`;

class Impersonation extends Component {
  render() {
    const { role, name, email, firstName, stopImpersonatingUser } = this.props;

    return (
      <CustomModule>
        <Container>
          <Row>
            <Col xs={12}>
              You are impersonating the {role} {name} ({email}).
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div role="button" onClick={stopImpersonatingUser}>
                Stop impersonating and become {firstName} again.
              </div>
            </Col>
          </Row>
        </Container>
      </CustomModule>
    );
  }
}

Impersonation.propTypes = {
  role: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  stopImpersonatingUser: PropTypes.func.isRequired,
};

export default Impersonation;
