import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { getSetupIntent } from '_actions/paymentActions';
import { selectSetupIntent } from 'shared/selectors/accountSelectors';
import AddStripeCardContainer from 'containers/payments/AddStripeCardContainer';

import Button from 'components/buttons/Button';

const StyledCardFormWrapper = styled.div`
  text-align: left;
`;

const AddPaymentMethod = ({
  getSetupIntent,
  openPaymentForm,
  setupIntent,
  user,
}) => {
  const [showPaymentForm, toggleFormDisplay] = useState(false);

  const addPaymentMethod = () => {
    getSetupIntent(user.id);
    toggleFormDisplay(true);
    openPaymentForm && openPaymentForm(true);
  };

  return (
    <div>
      {showPaymentForm && setupIntent ? (
        <StyledCardFormWrapper>
          <AddStripeCardContainer setupIntentSecret={setupIntent} />
        </StyledCardFormWrapper>
      ) : (
        <Button onClick={addPaymentMethod}>Add Payment Method</Button>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    setupIntent: selectSetupIntent(state),
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSetupIntent,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPaymentMethod);
