// @flow
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { addTestimonial, updateTestimonial } from '_actions/testimonialActions';
import CarerTestimonialsForm from 'components/carers/testimonials/CarerTestimonialsForm';
import { selectTestimonialByID } from 'shared/selectors/testimonialSelectors';
import Module from 'components/global/layout/Module';
import type {
  DefaultTestimonialType,
  TestimonialType,
  TaxonomiesClassificationType,
  MatchType,
} from 'shared/types';

type Props = {
  taxonomiesByClassification: TaxonomiesClassificationType,
  actions: {
    addTestimonial: Function,
    updateTestimonial: Function,
  },
  testimonial: TestimonialType,
  match: MatchType,
};

export class CarerTestimonialsFormContainer extends Component<Props> {
  handleSubmit = (data: Object, edit: boolean) => {
    const { user_id } = data;
    const redirect = `/admin/users/${user_id}/#testimonials`;
    if (edit) {
      delete data.user_id;
      return this.props.actions.updateTestimonial(
        this.props.testimonial.id,
        data,
        redirect,
      );
    }
    return this.props.actions.addTestimonial(data, redirect);
  };

  render() {
    const { testimonial, taxonomiesByClassification, match } = this.props;

    const defaultValues: DefaultTestimonialType = { featured: false };

    return (
      <section>
        <Module>
          <CarerTestimonialsForm
            onSubmit={this.handleSubmit}
            initialValues={testimonial || defaultValues}
            taxonomiesByClassification={taxonomiesByClassification}
            user_id={match.params.userID}
          />
        </Module>
      </section>
    );
  }
}

const mapStateToProps = (state, props) => ({
  taxonomiesByClassification: getTaxonomiesByClassifications(state, [
    'testimonial_relationship',
  ]),
  testimonial: selectTestimonialByID(
    state,
    props.match && props.match.params.testimonialID,
  ),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addTestimonial,
      updateTestimonial,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerTestimonialsFormContainer);
