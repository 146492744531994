import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateReference, getReferenceByID } from '_actions/referencesActions';
import { getWorkHistoryByCarerID } from '_actions/workHistoryActions';
import { selectReferenceByID } from 'shared/selectors/referencesSelectors';
import { selectWorkHistoryByCarerID } from 'shared/selectors/workHistorySelectors';

import ReferenceEdit from 'components/references/ReferenceEdit';

class ReferenceEditContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      updateReference: PropTypes.func.isRequired,
      getReferenceByID: PropTypes.func.isRequired,
      getWorkHistoryByCarerID: PropTypes.func.isRequired,
    }).isRequired,
    carerID: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool,
    reference: PropTypes.shape().isRequired,
    referenceID: PropTypes.string.isRequired,
    summaryURL: PropTypes.string.isRequired,
    workHistory: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    isAdmin: false,
    reference: null,
    workHistory: null,
  };

  UNSAFE_componentWillMount() {
    this.props.actions.getReferenceByID(this.props.referenceID);
    this.props.actions.getWorkHistoryByCarerID(this.props.carerID);
  }

  updateReference = data => {

    const updatedData = { ...data };

    if (updatedData.phone_number === '') {
      delete updatedData.phone_number;
    }

    if (updatedData.work_history_instance_id === '') {
      delete updatedData.work_history_instance_id;
    }

    this.props.actions.updateReference(
      this.props.referenceID,
      updatedData,
      this.props.summaryURL,
    );
  };

  render() {
    const { reference, workHistory } = this.props;

    const initialValues = { ...reference };

    return (
      <ReferenceEdit
        carerID={this.props.carerID}
        isAdmin={this.props.isAdmin}
        onSubmit={this.updateReference}
        initialValues={initialValues}
        ready={!!reference && !!workHistory}
        workHistory={workHistory}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    reference: selectReferenceByID(state, props.referenceID),
    workHistory: selectWorkHistoryByCarerID(state, props.carerID),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateReference,
      getReferenceByID,
      getWorkHistoryByCarerID,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferenceEditContainer);
