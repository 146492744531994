// @flow
import ScheduleConstants from 'shared/constants/ScheduleConstants';
import { addObjects, updateObject } from 'shared/reducers/reducerHelpers';
import type { ScheduleShiftsType } from 'shared/types';

type SchedulesState = {
  +[string]: ScheduleShiftsType,
};

function schedules(
  state: SchedulesState = {},
  action: { type: string, data: Object, scheduleID: string },
) {
  switch (action.type) {
    case ScheduleConstants.SCHEDULE_CREATE_FAILURE:
    case ScheduleConstants.SCHEDULE_MODIFY_FAILURE:
    case ScheduleConstants.SCHEDULE_DELETE_FAILURE:
    case ScheduleConstants.SCHEDULE_GET_BY_ID_FAILURE:
    case ScheduleConstants.SCHEDULE_GET_BY_PARAMS_FAILURE:
    case ScheduleConstants.SCHEDULE_SHIFT_GET_BY_PARAMS_FAILURE:
      return state;

    case ScheduleConstants.SCHEDULE_MODIFY_SUCCESS:
    case ScheduleConstants.SCHEDULE_CREATE_SUCCESS:
    case ScheduleConstants.SCHEDULE_GET_BY_ID_SUCCESS:
    case ScheduleConstants.SCHEDULE_ARCHIVE_SUCCESS:
      return updateObject(state, { [action.data.id]: action.data });

    case ScheduleConstants.SCHEDULE_GET_BY_PARAMS_SUCCESS:
      return addObjects(state, action);

    case ScheduleConstants.SCHEDULE_DELETE_SUCCESS: {
      const updatedState = { ...state };
      delete updatedState[action.scheduleID];
      return updatedState;
    }

    default:
      return state;
  }
}

export default schedules;
