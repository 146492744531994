// @flow
import ApiConstants from 'shared/constants/ApiConstants';
// $FlowFixMe
import TestimonialConstants from 'shared/constants/TestimonialConstants';

export function addTestimonial(payload: Object, redirect: string) {
  // We do not use a reducer for adding testimonials as we redirect to the summary page which
  // retrieves the testimonials themselves
  return {
    endpoint: '/testimonial/',
    method: 'post',
    onError: { type: TestimonialConstants.TESTIMONIAL_ADD_FAILURE },
    onStart: { type: TestimonialConstants.TESTIMONIAL_ADD },
    onSuccess: {
      type: TestimonialConstants.TESTIMONIAL_ADD_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Testimonial added',
      },
    },
    payload,
    redirect,
    type: ApiConstants.API_REQUEST,
  };
}

export function updateTestimonial(
  testimonialID: string,
  data: Object,
  redirect: ?string = null,
) {
  // We do not use a reducer for updating testimonial as we redirect to the summary page which
  // retrieves the testimonials themselves
  return {
    endpoint: `/testimonial/${testimonialID}/`,
    method: 'put',
    onError: { type: TestimonialConstants.TESTIMONIAL_UPDATE_FAILURE },
    onStart: { type: TestimonialConstants.TESTIMONIAL_UPDATE },
    onSuccess: {
      type: TestimonialConstants.TESTIMONIAL_UPDATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Testimonial updated',
      },
    },
    payload: data,
    redirect,
    type: ApiConstants.API_REQUEST,
  };
}

export function deleteTestimonial(testimonialID: string, userID: string) {
  return {
    endpoint: `/testimonial/${testimonialID}/`,
    method: 'delete',
    onError: { type: TestimonialConstants.TESTIMONIAL_DELETE_FAILURE },
    onStart: { type: TestimonialConstants.TESTIMONIAL_DELETE },
    onSuccess: {
      type: TestimonialConstants.TESTIMONIAL_DELETE_SUCCESS,
      payload: { testimonialID, userID },
      toast: {
        type: 'success',
        public: false,
        title: 'Testimonial deleted',
      },
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function getTestimonialsByCarerID(carerID: string) {
  return {
    endpoint: '/testimonial/find/',
    method: 'get',
    onError: {
      type: TestimonialConstants.TESTIMONIALS_GET_FOR_CARER_BY_ID_FAILURE,
    },
    onStart: { type: TestimonialConstants.TESTIMONIALS_GET_FOR_CARER_BY_ID },
    onSuccess: {
      carer_id: carerID,
      type: TestimonialConstants.TESTIMONIALS_GET_FOR_CARER_BY_ID_SUCCESS,
    },
    payload: {
      user_id: carerID,
      order_by_parameter: 'start_date',
    },
    type: ApiConstants.API_REQUEST,
  };
}
