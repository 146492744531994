// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCarerConflictsWithBids } from '_actions/carerActions';
import { selectBidsByIDs } from 'shared/selectors/bidSelectors';
import { selectJobOffersByBids } from 'shared/selectors/jobOfferSelectors';

import AdminUserConflicts from 'components/users/AdminUserConflicts';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import type { BidType, JobOfferType, UserType } from 'shared/types';

type Props = {
  bids: Array<BidType>,
  bidConflicts: Array<string>,
  conflictingBids: { [string]: Array<string> },
  user: UserType,
  jobOffers: Array<JobOfferType>,
};

export class BidConflictsContainer extends Component<Props> {
  render() {
    const { bids, bidConflicts, conflictingBids, jobOffers } = this.props;

    const fetchedBidsForConflicts = bids && bidConflicts.length === bids.length;
    const fetchedJobsForConflicts =
      jobOffers && bidConflicts.length === jobOffers.length;

    if (
      !bids ||
      !fetchedBidsForConflicts ||
      !jobOffers ||
      !fetchedJobsForConflicts
    ) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminUserConflicts
        conflictingBids={conflictingBids}
        bids={bids}
        jobOffers={jobOffers}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const bids = selectBidsByIDs(state, props.bidConflicts);
  const jobOffers = selectJobOffersByBids(state, bids);
  return {
    bids,
    jobOffers,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCarerConflictsWithBids,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BidConflictsContainer);
