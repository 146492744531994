import { createSelector } from 'reselect';

export const selectTestimonialsByCarerID = (state, carerID) => {
  return state.testimonials[carerID] ? state.testimonials[carerID] : null;
};

export const selectFeaturedTestimonialsByCarerID = createSelector(
  selectTestimonialsByCarerID,
  testimonials => {
    if (!testimonials) {
      return null;
    }

    return testimonials.filter(testimonial => testimonial.featured);
  },
);

export const selectTestimonialByID = (state, testimonialID) => {
  if (!testimonialID) {
    return null;
  }
  let testimonial = null;
  Object.keys(state.testimonials).forEach(carerID => {
    const carerTestimonials = state.testimonials[carerID];
    testimonial =
      carerTestimonials.find(testimonial => testimonial.id === testimonialID) ||
      null;
  });
  return testimonial;
};
