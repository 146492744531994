// @flow
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLink = styled.a`
  display: inline-block;

  @media (min-width: ${props => props.theme.screen.medium}px) {
    position: absolute;
    bottom: 8px;
  }

  > span {
    display: inline-block;
    text-decoration: underline;
  }
`;

const PREFIX = `https://www.smartrecruiters.com/app`;

type Props = {
  id: string,
  resource: string,
  user: boolean,
};

const SmartRecruiterButton = ({ id, resource }: Props) => {
  let href;
  if (resource === 'job') {
    href = `${PREFIX}/jobs/details/${id}/`;
  } else if (resource === 'carer') {
    href = `${PREFIX}/people/candidates/${id}`;
  } else {
    return null;
  }
  return (
    <StyledLink href={href} target="_blank">
      <span>View on Smart Recruiter</span>
    </StyledLink>
  );
};

SmartRecruiterButton.propTypes = {
  id: PropTypes.string.isRequired,
  resource: PropTypes.string,
};

SmartRecruiterButton.defaultProps = {
  resource: null,
};

export default SmartRecruiterButton;
