import PropTypes from 'prop-types';
import React from 'react';

const LinkIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 10"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="LinkIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M1.9,5 C1.9,3.29 3.29,1.9 5,1.9 L9,1.9 L9,0 L5,0 C2.24,0 0,2.24 0,5 C0,7.76 2.24,10 5,10 L9,10 L9,8.1 L5,8.1 C3.29,8.1 1.9,6.71 1.9,5 Z M6,6 L14,6 L14,4 L6,4 L6,6 Z M15,0 L11,0 L11,1.9 L15,1.9 C16.71,1.9 18.1,3.29 18.1,5 C18.1,6.71 16.71,8.1 15,8.1 L11,8.1 L11,10 L15,10 C17.76,10 20,7.76 20,5 C20,2.24 17.76,0 15,0 Z"
          id="Shape"
          fill="#1c7589"
          fillRule="nonzero"
        />
      </g>
    </svg>
  </div>
);

LinkIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

LinkIcon.defaultProps = {
  className: '',
  height: '20px',
  width: '10px',
};

export default LinkIcon;
