import ApiConstants from 'shared/constants/ApiConstants';
import CarerConstants from 'shared/constants/CarerConstants';

export function createCarer(data) {
  return {
    endpoint: '/carer/',
    method: 'post',
    onError: { type: CarerConstants.CARER_CREATE_FAILURE },
    onStart: { type: CarerConstants.CARER_CREATE },
    onSuccess: {
      type: CarerConstants.CARER_CREATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title:
          'Carer created. Please wait 10 minutes before they appear in search.',
      },
    },
    payload: data,
    redirect: response => `/admin/carers/${response.data.user.id}/`,
    type: ApiConstants.API_REQUEST,
  };
}

export function searchCarers(inputData) {
  // The backend does not allow empty or undefined state to be passed (API 3.20)
  const data = { ...inputData };
  if (!data.state) {
    delete data.state;
  }
  data.objects = true;

  return {
    endpoint: '/search/carer/',
    method: 'get',
    onError: { type: CarerConstants.CARER_SEARCH_FAILURE },
    onStart: { type: CarerConstants.CARER_SEARCH },
    onSuccess: { type: CarerConstants.CARER_SEARCH_SUCCESS },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function searchMoreCarers(data) {
  return {
    endpoint: '/search/carer/',
    method: 'get',
    onError: { type: CarerConstants.CARER_SEARCH_MORE_FAILURE },
    onStart: { type: CarerConstants.CARER_SEARCH_MORE },
    onSuccess: { type: CarerConstants.CARER_SEARCH_MORE_SUCCESS },
    payload: { objects: true, ...data },
    type: ApiConstants.API_REQUEST,
  };
}

export function getCarerById(carerId) {
  return {
    endpoint: `/carer/${carerId}/`,
    method: 'get',
    onError: { type: CarerConstants.CARER_GET_BY_ID_FAILURE },
    onStart: { type: CarerConstants.CARER_GET_BY_ID },
    onSuccess: { type: CarerConstants.CARER_GET_BY_ID_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function updateCarer(carerId, data) {
  return {
    endpoint: `/user/${carerId}/`,
    method: 'put',
    onError: { type: CarerConstants.CARER_UPDATE_FAILURE },
    onStart: { type: CarerConstants.CARER_UPDATE },
    onSuccess: {
      type: CarerConstants.CARER_UPDATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Carer updated.',
      },
    },
    payload: data,
    redirect: response => `/admin/carers/${response.data.id}/`,
    type: ApiConstants.API_REQUEST,
  };
}

export function getCarerProfileByCarerId(carerID, params) {
  return {
    endpoint: `/profile/carer/${carerID}/`,
    method: 'get',
    onError: { type: CarerConstants.CARER_GET_PROFILE_FAILURE },
    onStart: { type: CarerConstants.CARER_GET_PROFILE },
    onSuccess: { type: CarerConstants.CARER_GET_PROFILE_SUCCESS },
    payload: params,
    type: ApiConstants.API_REQUEST,
  };
}

export function getCarerInductionSlot(carerID) {
  return {
    endpoint: `/profile/carer/${carerID}/induction-slot/`,
    method: 'get',
    onError: { type: CarerConstants.CARER_GET_INDUCTION_SLOT_FAILURE },
    onStart: { type: CarerConstants.CARER_GET_INDUCTION_SLOT },
    onSuccess: {
      carerID,
      type: CarerConstants.CARER_GET_INDUCTION_SLOT_SUCCESS,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function getCarerConflicts(carerID) {
  return {
    endpoint: `/carer/${carerID}/conflict/`,
    method: 'get',
    onError: { type: CarerConstants.CARER_GET_CONFLICTS_FAILURE },
    onStart: { type: CarerConstants.CARER_GET_CONFLICTS },
    onSuccess: {
      carerID,
      type: CarerConstants.CARER_GET_CONFLICTS_SUCCESS,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function getCarerConflictsWithBids(carerID) {
  return {
    type: CarerConstants.CARER_GET_CONFLICTS_WITH_BIDS,
    payload: { carerID: carerID },
  };
}

export function searchProfiles(params) {
  return {
    endpoint: '/profile/carer/',
    method: 'get',
    onError: { type: CarerConstants.CARER_PROFILE_SEARCH_FAILURE },
    onStart: { type: CarerConstants.CARER_PROFILE_SEARCH },
    onSuccess: { type: CarerConstants.CARER_PROFILE_SEARCH_SUCCESS },
    payload: params,
    type: ApiConstants.API_REQUEST,
  };
}

function maskProfile(data) {
  const maskedData = { ...data };
  if (maskedData.dbs_check) {
    delete maskedData.dbs_check.expiry_date;
    delete maskedData.dbs_check.expired;
  }
  if (maskedData.right_to_work) {
    delete maskedData.right_to_work.expired;
    delete maskedData.right_to_work.response;
  }
  return maskedData;
}

export function updateCarerProfile(
  carerID,
  data,
  notify = true,
  isAdmin = false,
  approveChanges,
) {
  const endpoint = `/profile/carer/${carerID}/?both=true${
    approveChanges === false ? `&approve_changes=${approveChanges}` : ''
  }`;
  const action = {
    endpoint,
    method: 'put',
    onError: { type: CarerConstants.CARER_UPDATE_PROFILE_FAILURE },
    onStart: { type: CarerConstants.CARER_UPDATE_PROFILE },
    onSuccess: { type: CarerConstants.CARER_UPDATE_PROFILE_SUCCESS },
    payload: maskProfile(data),
    type: ApiConstants.API_REQUEST,
  };
  if (notify) {
    const title = `Profile updated.${isAdmin === false
      ? 'Changes needing review have been sent to the Home Instead Live-in Care team.'
      : ''
    }`;
    action.onSuccess.toast = {
      type: 'success',
      public: false,
      title: title,
    };
  }
  return action;
}

export function adminUpdateCarerProfile(data, approve = false) {
  const action = {
    payload: data,
    type: CarerConstants.ADMIN_CARER_UPDATE_PROFILE,
    approve,
  };
  return action;
}

export function partialUpdateCarerProfile(data) {
  const action = {
    payload: data,
    type: CarerConstants.CARER_PARTIAL_UPDATE_PROFILE,
  };
  return action;
}

export function messageJob(carerIds, message) {
  const data = {
    carerIds,
    message,
  };
  return {
    endpoint: '/notification/carer/message/',
    method: 'post',
    onError: { type: CarerConstants.CARER_MESSAGE_JOB_FAILURE },
    onStart: { type: CarerConstants.CARER_MESSAGE_JOB },
    onSuccess: {
      type: CarerConstants.CARER_MESSAGE_JOB_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Carers emailed.',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function inviteApplicant(data) {
  return {
    endpoint: '/carer/notify/applicant/invitation/',
    method: 'post',
    onError: {
      type: CarerConstants.CARER_APPLICANT_INVITE_FAILURE
    },
    onStart: { type: CarerConstants.CARER_APPLICANT_INVITE },
    onSuccess: {
      type: CarerConstants.CARER_APPLICANT_INVITE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Applicant emailed.',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function disconnectStripe(userId) {
  return {
    endpoint: `/payment/provider/stripe/disconnected/${userId}/`,
    method: 'post',
    onError: { type: CarerConstants.CARER_DISCONNECT_STRIPE_FAILURE },
    onStart: { type: CarerConstants.CARER_DISCONNECT_STRIPE },
    onSuccess: {
      type: CarerConstants.CARER_DISCONNECT_STRIPE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Stripe Disconnected.',
      },
    },
    payload: {},
    type: ApiConstants.API_REQUEST,
  };
}

export function queryCarerProfile(userId, message) {
  return {
    endpoint: `/notification/carer/profile/query/notify/${userId}/`,
    method: 'post',
    onError: { type: CarerConstants.CARER_PROFILE_QUERY_FAILURE },
    onStart: { type: CarerConstants.CARER_PROFILE_QUERY },
    onSuccess: {
      type: CarerConstants.CARER_PROFILE_QUERY_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Profile queried.',
      },
    },
    payload: { message },
    type: ApiConstants.API_REQUEST,
  };
}

export function uploadProfileImage(carerID, carerProfileID, formData) {
  return {
    endpoint: `/profile/carer/${carerProfileID}/avatar/`,
    method: 'put',
    onError: {
      type: CarerConstants.CARER_UPDATE_PROFILE_IMAGE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'File upload must be one of .jpg, .jpeg or .png',
      },
    },
    onStart: { type: CarerConstants.CARER_UPDATE_PROFILE_IMAGE },
    onSuccess: {
      type: CarerConstants.CARER_UPDATE_PROFILE_IMAGE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Image uploaded and processing. Refresh to view.',
      },
    },
    payload: formData,
    type: ApiConstants.API_REQUEST,
  };
}
