import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Currency from 'components/modules/Currency';

class TimesheetTotalExpenses extends Component {
  static propTypes = {
    expenses: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        expense_type: PropTypes.string,
        miles: PropTypes.number,
      }),
    ).isRequired,
  };

  render() {
    return (
      <tbody>
        {this.props.expenses.map((expense, index) => {
          const expenseType =
            expense.expense_type === 'mileage' ? 'Mileage' : 'Expense';
          const rowClass =
            index < this.props.expenses.length - 1 ? 'multiple' : '';
          return (
            <tr key={index} className={rowClass}>
              <td rowSpan="1">
                <strong>{expenseType}</strong>
              </td>
              <td>{expense.description}</td>
              <td className="number">
                {expense.expense_type === 'mileage' && (
                  <span>{expense.miles} miles</span>
                )}
              </td>
              <td className="number">
                <strong>
                  <Currency value={expense.value} />
                </strong>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export default TimesheetTotalExpenses;
