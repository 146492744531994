/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import { Container, Col, Row } from 'components/grid';
import Module, {
  ModuleHeader,
  ModuleHero,
  ModuleText,
} from 'components/global/layout/Module';
import Button from 'components/buttons/Button';
import StatusIndicator from 'components/global/StatusIndicator';
import { DeleteIcon, EditIcon } from 'components/global/icons';
import StyledTickList from 'components/global/informationdisplay/StyledTickList';
import StandardTable from 'components/global/layout/StandardTable';
import ConfirmationDialog from 'components/global/ConfirmationDialog';
import stateHelper from 'components/helpers/stateHelper';
import { renderTaxon } from 'components/helpers/taxonomyHelpers';

const DeleteLink = styled.a`
  color: ${props => props.theme.colors.midGrey};
`;

const Meta = styled.div`
  color: ${props => props.theme.colors.midGrey};
`;

const ReferenceField = styled.span`
  font-weight: 600;
`;

class CarerReferenceSummary extends Component {
  static propTypes = {
    deleteReference: PropTypes.func.isRequired,
    getTaxonomiesByClassifications: PropTypes.shape().isRequired,
    isSubmitted: PropTypes.bool.isRequired,
    numRequiredReferences: PropTypes.number.isRequired,
    references: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    referencesCompliant: PropTypes.bool.isRequired,
    submitReferences: PropTypes.func.isRequired,
  };

  state = {
    deletingReference: null,
    referencesCompliant: false,
  };

  confirmDeletion = referenceID => {
    this.setState({
      deletingReference: referenceID,
    });
  };

  render() {
    const {
      isSubmitted,
      numRequiredReferences,
      references,
      referencesCompliant,
      submitReferences,
      taxonomiesByClassification,
    } = this.props;

    return (
      <div>
        <Module>
          <ModuleHeader>
            As part of the recruitment process Home Instead require a minimum of
            four references.
          </ModuleHeader>
          <Container>
            <Row>
              <Col xs={12} md={10}>
                <StyledTickList>
                  <li>
                    <strong>Two professional references</strong>: One of these
                    must be your present or most recent employer. References
                    need to be from a<strong> manager</strong> or{' '}
                    <strong>HR</strong>.
                  </li>
                  <li>
                    <strong>Two personal references</strong>: This can be a
                    co-worker, friend or someone else in your community that
                    knows you well. This
                    <strong> must not be a relative</strong>.
                  </li>
                </StyledTickList>
              </Col>
            </Row>
          </Container>

          {!referencesCompliant && (
            <ModuleText>
              Please note applications cannot be progressed unless details of
              four referees are provided. Any offer of employment from a Home
              Instead franchise is conditional upon them being completely
              satisfied with the information provided as a result of the
              reference check.
            </ModuleText>
          )}

          <ModuleText>
            We will not send the request for references until we have spoken to
            you and received permission following an initial interview. By
            providing these reference details, you authorise us to contact
            referees to validate past employment and professional activities
            following your initial interview.
          </ModuleText>

          {referencesCompliant && (
            <ModuleText>
              <strong>
                Congratulations. Your references are marked as compliant.
              </strong>
            </ModuleText>
          )}
        </Module>

        {isSubmitted && (
          <Module>
            <Container>
              <Row>
                <Col>
                  Your references have been submitted. If you need to change
                  your references, please contact us.
                </Col>
              </Row>
            </Container>
          </Module>
        )}

        <Module className="carerReferenceSummary">
          <ModuleHeader>References Summary</ModuleHeader>
          {!references.length && (
            <ModuleHero>You have not entered any references yet.</ModuleHero>
          )}
          {!!references.length && (
            <StandardTable className="carer-references">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Referee</th>
                  <th>Contact Details</th>
                  <th>Status</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {references.map(reference => (
                  <tr key={reference.id}>
                    <td>
                      <ReferenceField>
                        {reference.professional_reference
                          ? 'Professional'
                          : 'Personal'}
                      </ReferenceField>
                    </td>

                    <td>
                      <div>
                        {reference.firstname} {reference.lastname}
                      </div>
                      <Meta>
                        {reference.professional_reference
                          ? renderTaxon(
                              taxonomiesByClassification['reference_roles'],
                              reference.reference_role,
                            )
                          : renderTaxon(
                              taxonomiesByClassification[
                                'reference_relationships'
                              ],
                              reference.reference_relationship,
                            )}
                      </Meta>
                    </td>

                    <td>
                      {reference.email}
                      <Meta>{reference.phone_number}</Meta>
                    </td>

                    <td>
                      <StatusIndicator
                        status={
                          stateHelper.REFERENCE_DISPLAY_STATES[reference.state]
                        }
                      />
                    </td>

                    <td>
                      {reference.state === 'new' ? (
                        <Link to={`/references/${reference.id}/`}>
                          <EditIcon />
                        </Link>
                      ) : (
                        '-'
                      )}
                    </td>

                    <td>
                      {reference.state === 'new' ? (
                        <DeleteLink
                          onClick={() => this.confirmDeletion(reference.id)}
                          role="button"
                        >
                          <DeleteIcon />
                        </DeleteLink>
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </StandardTable>
          )}

          <Container>
            <Row>
              <Col>
                <Link to="/references/add/">Add a reference</Link>
                <br />
                <br />
              </Col>
            </Row>
          </Container>

          {references.some(reference => reference.state === 'new') && (
            <Container>
              <ModuleText>
                Please submit any new references by clicking below.
              </ModuleText>
              {!!references.length && (
                <Row>
                  <Col xs={6} md={3}>
                    <Button
                      disabled={references.length < numRequiredReferences}
                      onClick={submitReferences}
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col xs={18} md={9}>
                    {references.length < numRequiredReferences && (
                      <Meta>
                        {`
                          You need to have added at least ${numRequiredReferences}
                          references before you can Submit.
                        `}
                      </Meta>
                    )}
                    <Meta>
                      You will not be able to edit your submitted references
                      without contacting us.
                    </Meta>
                  </Col>
                </Row>
              )}
            </Container>
          )}

          {this.state.deletingReference && (
            <ConfirmationDialog
              title="Delete this reference?"
              message="Caution. This cannot be undone."
              confirmation="Yes, delete it"
              onConfirm={() => {
                this.props.deleteReference(this.state.deletingReference);
                this.setState({ deletingReference: null });
              }}
              onCancel={() => {
                this.setState({ deletingReference: null });
              }}
              danger
            />
          )}
        </Module>
      </div>
    );
  }
}

export default CarerReferenceSummary;
