import keyMirror from 'keymirror';

export default keyMirror({
  SHIFT_CREATE: null,
  SHIFT_CREATE_SUCCESS: null,
  SHIFT_CREATE_FAILURE: null,

  SHIFT_MODIFY: null,
  SHIFT_MODIFY_SUCCESS: null,
  SHIFT_MODIFY_FAILURE: null,

  SHIFT_DELETE: null,
  SHIFT_DELETE_SUCCESS: null,
  SHIFT_DELETE_FAILURE: null,

  SHIFT_DELETE_MULTIPLE: null,
  SHIFT_DELETE_MULTIPLE_SUCCESS: null,
  SHIFT_DELETE_MULTIPLE_FAILURE: null,

  SHIFT_GET_BY_ID: null,
  SHIFT_GET_BY_ID_SUCCESS: null,
  SHIFT_GET_BY_ID_FAILURE: null,

  SHIFT_GET_BY_PARAMS: null,
  SHIFT_GET_BY_PARAMS_SUCCESS: null,
  SHIFT_GET_BY_PARAMS_FAILURE: null,

  SHIFT_GET_ALL_FOR_NEED_ID: null,
  SHIFT_GET_ALL_FOR_NEED_ID_SUCCESS: null,
  SHIFT_GET_ALL_FOR_NEED_ID_FAILURE: null,

  SHIFT_GET_ALL_FOR_CARER_USER_ID: null,
  SHIFT_GET_ALL_FOR_CARER_USER_ID_SUCCESS: null,
  SHIFT_GET_ALL_FOR_CARER_USER_ID_FAILURE: null,

  SHIFT_GET_ALL_FOR_CUSTOMER_USER_ID: null,
  SHIFT_GET_ALL_FOR_CUSTOMER_USER_ID_SUCCESS: null,
  SHIFT_GET_ALL_FOR_CUSTOMER_USER_ID_FAILURE: null,

  SHIFT_GET_MORE_FOR_CUSTOMER_USER_ID: null,
  SHIFT_GET_MORE_FOR_CUSTOMER_USER_ID_SUCCESS: null,
  SHIFT_GET_MORE_FOR_CUSTOMER_USER_ID_FAILURE: null,
});
