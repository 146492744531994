import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Field } from 'redux-form';
import Link from 'react-router-redux-dom-link';

import Module, {
  ModuleHeader,
  ModuleMeta,
} from 'components/global/layout/Module';
import { Container, Col, Row } from 'react-grid-system';
import Button from 'components/buttons/Button';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import StandardInput from 'components/forms/StandardInput';
import StandardDateInput from 'components/forms/StandardDateInput';
import StandardSelect from 'components/forms/StandardSelect';
import StandardMultiSelect from 'components/forms/StandardMultiSelect';
import FountainButton from 'components/global/FountainButton';
import SmartRecruiterButton from 'components/global/SmartRecruiterButton';
import StatusIndicator from 'components/global/StatusIndicator';
import Definition from 'components/global/informationdisplay/Definition';
import { SectionHeader } from 'components/global/layout/Module';
import { taxonomyToMultiselect } from 'components/helpers/taxonomyHelpers';
import styled from 'styled-components';
import { orderBy } from 'lodash';

import { validDate, validGUID } from 'validators';

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

class AdminUserOnboarding extends Component {
  static propTypes = {
    data: PropTypes.shape().isRequired,
    taxonomiesByClassification: PropTypes.shape().isRequired,
    updateCarerProfile: PropTypes.func.isRequired,
  };

  render() {
    const { data, updateCarerProfile, taxonomiesByClassification } = this.props;
    return (
      <Module>
        <Container>
          <Row>
            <Col xs={18} md={9}>
              <StyledHeader>Onboarding Information</StyledHeader>
            </Col>
            <Col xs={6} md={3}>
              <ModuleMeta>
                <Link to={`/admin/carers/profile/${data.user_id}/`}>
                  Edit Profile
                </Link>
              </ModuleMeta>
            </Col>
          </Row>
        </Container>
        <Form
          name="userOnboarding"
          fields={[
            'ats_rejection_reason',
            'care_experience_types',
            'care_team_state',
            'citizenship',
            'country_of_origin',
            'fountain_funnel',
            'fountain_id',
            'insurance_expiry_date',
            'ninum',
            'rejection_reasons',
            'smart_recruiter_id',
            'source_data',
            'state',
            'work_preference',
            'work_state',
          ]}
          selectFields={['state', 'smart_recruiter_id', 'fountain_id']}
          values={data}
          onSubmit={updateCarerProfile}
          component={({ currentValues, handleSubmit, pristine }) => (
            <form onSubmit={handleSubmit}>
              <Container>

                <Fragment>
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardSelect}
                        label="Platform Status"
                        name="state"
                      >
                        {taxonomiesByClassification.carer_profile_states.map(
                          taxon => (
                            <option key={taxon.taxon_id} value={taxon.taxon_id}>
                              {taxon.value}
                            </option>
                          ),
                        )}
                      </Field>
                    </Col>
                    {currentValues.state === 'rejected' && (
                      <Col xs={12} md={6}>
                        <Field
                          component={StandardMultiSelect}
                          label="Rejection Reasons"
                          name="rejection_reasons"
                          options={taxonomyToMultiselect(
                            taxonomiesByClassification.rejection_reasons,
                          )}
                        />
                      </Col>
                    )}
                  </FormRow>
                  {data.ats_rejection_reasons &&
                    data.ats_rejection_reasons.length > 0 && (
                      <FormRow>
                        <Col>
                          <Definition label="Rejected in Fountain">
                            {data.ats_rejection_reasons.join(', ')}
                          </Definition>
                        </Col>
                      </FormRow>
                    )}
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardMultiSelect}
                        label="Work Preference"
                        name="work_preference"
                        options={taxonomyToMultiselect(
                          taxonomiesByClassification.carer_profile_work_preferences,
                        )}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardSelect}
                        label="Work Availability"
                        name="work_state"
                      >
                        {taxonomiesByClassification.work_state.map(taxon => (
                          <option key={taxon.taxon_id} value={taxon.taxon_id}>
                            {taxon.value}
                          </option>
                        ))}
                      </Field>
                    </Col>
                  </FormRow>
                </Fragment>

                <Fragment>
                  <SectionHeader>Fountain</SectionHeader>
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardSelect}
                        label="Current Fountain Funnel"
                        name="fountain_funnel"
                        type="text"
                        disabled
                      >
                        <option>Not set</option>
                        {taxonomiesByClassification.fountain_funnel.map(taxon => (
                          <option key={taxon.taxon_id} value={taxon.taxon_id}>
                            {taxon.value}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col xs={6} md={3}>
                      {currentValues.fountain_id && (
                        <FountainButton
                          id={currentValues.fountain_id}
                          resource="carer"
                        />
                      )}
                    </Col>
                    <Col xs={6} md={3}>
                      <ModuleMeta>
                        <StatusIndicator
                          status={data.fountain_state || 'Unknown Fountain Stage'}
                        />
                      </ModuleMeta>
                    </Col>
                  </FormRow>
                </Fragment>

                <Fragment>

                  <SectionHeader>Home Instead</SectionHeader>

                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardInput}
                        label="Smart Recruiter ID"
                        name="smart_recruiter_id"
                        type="text"
                        validate={[validGUID]}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      {currentValues.smart_recruiter_id && (
                        <SmartRecruiterButton
                          id={currentValues.smart_recruiter_id}
                          resource="carer"
                        />
                      )}
                    </Col>
                  </FormRow>

                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardSelect}
                        label="Care Team State Availability"
                        name="care_team_state"
                        type="text"
                        disabled
                      >
                        <option>Not set</option>
                        {taxonomiesByClassification.carer_profile_care_team_states.map(taxon => (
                          <option key={taxon.taxon_id} value={taxon.taxon_id}>
                            {taxon.value}
                          </option>
                        ))}
                      </Field>
                    </Col>
                  </FormRow>

                </Fragment>

                <Fragment>
                  <SectionHeader>Hidden Profile Fields</SectionHeader>

                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardSelect}
                        label="Source"
                        name="source_data.source"
                      >
                        <option>Please select</option>
                        {taxonomiesByClassification.source.map(taxon => (
                          <option key={taxon.taxon_id} value={taxon.taxon_id}>
                            {taxon.value}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardInput}
                        label="Source (Other)"
                        name="source_data.source_other"
                        type="text"
                      />
                    </Col>
                  </FormRow>

                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardSelect}
                        label="Citizenship"
                        name="citizenship"
                      >
                        <option>Please select</option>
                        <option key="gbr" value="gbr">
                          United Kingdom
                        </option>
                        {orderBy(
                          taxonomiesByClassification.organization_country,
                          ['title'],
                          ['asc'],
                        )
                          .filter(taxon => taxon.value !== 'gbr')
                          .map(taxon => (
                            <option key={taxon.taxon_id} value={taxon.taxon_id}>
                              {taxon.value}
                            </option>
                          ))}
                      </Field>
                    </Col>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardSelect}
                        label="Country of Origin"
                        name="country_of_origin"
                      >
                        <option>Please select</option>
                        <option key="gbr" value="gbr">
                          United Kingdom
                        </option>
                        {orderBy(
                          taxonomiesByClassification.organization_country,
                          ['title'],
                          ['asc'],
                        )
                          .filter(taxon => taxon.value !== 'gbr')
                          .map(taxon => (
                            <option key={taxon.taxon_id} value={taxon.taxon_id}>
                              {taxon.value}
                            </option>
                          ))}
                      </Field>
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardMultiSelect}
                        label="Previous Care Experience"
                        name="care_experience_types"
                        options={taxonomyToMultiselect(
                          taxonomiesByClassification.care_experience_types,
                        )}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardInput}
                        label="National Insurance Number"
                        name="ninum"
                      />
                    </Col>
                  </FormRow>

                  <FormRow>
                    <Col xs={12} sm={6}>
                      <Field
                        component={StandardDateInput}
                        from={new Date().getFullYear() - 10}
                        name="insurance_expiry_date"
                        label="Insurance expiry date"
                        validate={[validDate]}
                      />
                    </Col>
                  </FormRow>

                </Fragment>

                <Row>
                  <Col xs={12} md={6}>
                    <Button disabled={pristine}>Save Changes</Button>
                  </Col>
                </Row>
              </Container>
            </form>
          )}
        />
      </Module>
    );
  }
}

export default AdminUserOnboarding;
