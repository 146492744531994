import NoteConstants from 'shared/constants/NoteConstants';
import { takeEvery, put } from 'redux-saga/effects';
import { reset } from 'redux-form';

export function* clearForm(action) {
  yield put(reset(action.formName));
}

export function* clearFormListener() {
  yield takeEvery(NoteConstants.NOTE_ADD_FOR_OBJECT_SUCCESS, clearForm);
}
