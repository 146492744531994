import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CarerScheduleContainer from 'containers/carers/CarerScheduleContainer';
import CustomerScheduleContainer from 'containers/customers/CustomerScheduleContainer';
import { isCarer, isCustomer } from 'shared/selectors/accountSelectors';

class UserSchedulesContainer extends Component {
  static propTypes = {
    isCarer: PropTypes.bool.isRequired,
    isCustomer: PropTypes.bool.isRequired,
  };

  render() {
    if (this.props.isCarer) {
      return <CarerScheduleContainer />;
    }

    if (this.props.isCustomer) {
      return <CustomerScheduleContainer />;
    }

    return <span />;
  }
}

function mapStateToProps(state) {
  return {
    isCarer: isCarer(state),
    isCustomer: isCustomer(state),
  };
}

export default connect(mapStateToProps)(UserSchedulesContainer);
