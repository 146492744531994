// @flow
import React from 'react';
import styled, { type ReactComponentStyled } from 'styled-components';

import Module from 'components/global/layout/Module';
import SummaryCustomerModule from 'components/admin/needs/SummaryCustomerModule';
import SummaryRecipientModule from 'components/admin/needs/SummaryRecipientModule';
import SummaryNeedModule from 'components/admin/needs/SummaryNeedModule';

import { Container, Col, Row } from 'components/grid';

import type {
  NeedType,
  SchedulesType,
  TaxonomiesClassificationType,
  UserType,
} from 'shared/types';
import type { ModuleProps } from 'components/global/layout/Module';

const StyledModule: ReactComponentStyled<ModuleProps> = styled(Module)`
  padding: 20px 0 0;
`;

const StyledRow = styled(Row)`
  @media (min-width: ${({ theme }) => theme.screen.medium}px) {
    &:first-of-type {
      border-bottom: solid rgba(0, 0, 0, 0.1) 1px;
    }
  }
`;

const StyledCol = styled(Col)`
  @media (min-width: ${props => props.theme.screen.medium}px) {
    &:last-of-type {
      padding-left: 25px;
    }
  }
`;

const TopRowCol = styled(StyledCol)`
  position: relative;
`;

const BottomRowCol = styled(StyledCol)`
  @media (min-width: ${props => props.theme.screen.medium}px) {
    &:first-of-type {
      border-right: solid rgba(0, 0, 0, 0.1) 1px;
    }
  }
`;

type Props = {
  customer: UserType,
  need: NeedType,
  schedules: SchedulesType,
  taxonomiesByClassification: TaxonomiesClassificationType,
};

const AdminNeed = ({
  need,
  customer,
  taxonomiesByClassification,
}: Props) => {
  return (
    <StyledModule>
      <Container outerGutter={0} colGutter={0}>
        <StyledRow
          style={{ paddingRight: 20, paddingLeft: 20, paddingBottom: 15 }}
        >
          <TopRowCol xs={12} md={12} id="recipient-summary">
            <SummaryRecipientModule customer={customer} need={need} />
          </TopRowCol>
        </StyledRow>
        <StyledRow style={{ paddingRight: 20, paddingLeft: 20 }}>
          <BottomRowCol xs={12} md={5} id="customer-summary">
            <SummaryCustomerModule
              customer={customer}
              need={need}
              taxonomiesByClassification={taxonomiesByClassification}
            />
          </BottomRowCol>
          <BottomRowCol xs={12} md={7} id="needs-summary">
            <SummaryNeedModule
              need={need}
              taxonomiesByClassification={taxonomiesByClassification}
            />
          </BottomRowCol>
        </StyledRow>
      </Container>
    </StyledModule>
  );
};

export default AdminNeed;
