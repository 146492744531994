import React from 'react';
import styled from 'styled-components';
import Currency from 'components/modules/Currency';
import { calculateCustomerRate } from 'components/helpers/currency';

import { SHIFT_DESCRIPTIONS } from 'components/rates/CarerRatesTable';

const Rate = styled.span`
  display: block;
`;

const Frequency = styled.span`
  color: ${props => props.theme.colors.midGrey};
`;

const Commission = styled.div`
  color: ${props => props.theme.colors.midGrey};
  font-style: italic;
  margin-bottom: 5px;
`;

const RatesBreakdown = ({ shiftTypes, rates, admin, isCustomer = false }) =>
  shiftTypes.map(shiftType => {
    const shiftTypeCustomerRate = shiftType =>
      calculateCustomerRate(rates[shiftType], rates[`${shiftType}_commission`]);
    const frequency = ['livein', 'half_livein'].includes(shiftType)
      ? 'per day'
      : 'per hour';
    let carerRate;
    let customerRate;
    if (shiftType === 'half_livein') {
      customerRate = shiftTypeCustomerRate('livein') / 2.0;
      carerRate = rates['livein'] / 2.0;
    } else {
      customerRate = shiftTypeCustomerRate(shiftType);
      carerRate = rates[shiftType];
    }
    const commission = customerRate - carerRate;

    return (
      <Rate key={shiftType}>
        <Currency value={isCustomer ? customerRate : carerRate} />{' '}
        <Frequency>{frequency}</Frequency> -{' '}
        {`${SHIFT_DESCRIPTIONS[shiftType].name}`}
        {admin && (
          <Commission>
            <Currency value={customerRate} /> total -{' '}
            <Currency value={commission} /> commission
          </Commission>
        )}
        {!admin &&
          SHIFT_DESCRIPTIONS[shiftType].description &&
          ` - ${SHIFT_DESCRIPTIONS[shiftType].description}`}
      </Rate>
    );
  });

export default RatesBreakdown;
