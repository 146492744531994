// @flow
import {
  TaxonomySelect,
  TrueFalseSelect,
} from 'components/forms/ConvenienceSelects';
import StandardCheckbox from 'components/forms/StandardCheckbox';
import StandardCheckboxWithChildren from 'components/forms/StandardCheckboxWithChildren';
import StandardDateInput from 'components/forms/StandardDateInput';
import StandardInput from 'components/forms/StandardInput';
import StandardMultiSelect from 'components/forms/StandardMultiSelect';
import StandardSelect from 'components/forms/StandardSelect';
import StandardTextArea from 'components/forms/StandardTextarea';
import StandardTextSpan from 'components/forms/StandardTextSpan';

const formComponentMap = {
  StandardCheckbox,
  StandardCheckboxWithChildren,
  StandardDateInput,
  StandardInput,
  StandardMultiSelect,
  StandardSelect,
  StandardTextArea,
  StandardTextSpan,
  TaxonomySelect,
  TrueFalseSelect,
};

const FormComponents = (componentString: string) => {
  if (!formComponentMap[componentString]) {
    throw new Error(
      `No matching form component found for - ${componentString}`,
    );
  }
  return formComponentMap[componentString];
};

export default FormComponents;
