import keyMirror from 'keymirror';

export default keyMirror({
  SCHEDULE_CREATE: null,
  SCHEDULE_CREATE_SUCCESS: null,
  SCHEDULE_CREATE_FAILURE: null,

  SCHEDULE_MODIFY: null,
  SCHEDULE_MODIFY_SUCCESS: null,
  SCHEDULE_MODIFY_FAILURE: null,

  SCHEDULE_DELETE: null,
  SCHEDULE_DELETE_SUCCESS: null,
  SCHEDULE_DELETE_FAILURE: null,

  SCHEDULE_ARCHIVE: null,
  SCHEDULE_ARCHIVE_SUCCESS: null,
  SCHEDULE_ARCHIVE_FAILURE: null,

  SCHEDULE_GET_BY_ID: null,
  SCHEDULE_GET_BY_ID_SUCCESS: null,
  SCHEDULE_GET_BY_ID_FAILURE: null,

  SCHEDULE_GET_BY_PARAMS: null,
  SCHEDULE_GET_BY_PARAMS_SUCCESS: null,
  SCHEDULE_GET_BY_PARAMS_FAILURE: null,

  SCHEDULE_GET_ALL_FOR_USER: null,
  SCHEDULE_GET_ALL_FOR_USER_SUCCESS: null,
  SCHEDULE_GET_ALL_FOR_USER_FAILURE: null,

  SCHEDULE_SHIFT_CREATE: null,
  SCHEDULE_SHIFT_CREATE_SUCCESS: null,
  SCHEDULE_SHIFT_CREATE_FAILURE: null,

  SCHEDULE_SHIFT_UPDATE: null,
  SCHEDULE_SHIFT_UPDATE_SUCCESS: null,
  SCHEDULE_SHIFT_UPDATE_FAILURE: null,

  SCHEDULE_SHIFT_DELETE: null,
  SCHEDULE_SHIFT_DELETE_SUCCESS: null,
  SCHEDULE_SHIFT_DELETE_FAILURE: null,

  SCHEDULE_SHIFT_GET_BY_PARAMS: null,
  SCHEDULE_SHIFT_GET_BY_PARAMS_SUCCESS: null,
  SCHEDULE_SHIFT_GET_BY_PARAMS_FAILURE: null,

  GET_SCHEDULE_WITH_SCHEDULE_SHIFTS_BY_NEED_ID: null,
  GET_SCHEDULE_WITH_SCHEDULE_SHIFTS_BY_NEED_ID_SUCCESS: null,
  GET_SCHEDULE_WITH_SCHEDULE_SHIFTS_BY_NEED_ID_FAILURE: null,

  GET_SCHEDULES_WITH_SCHEDULE_SHIFTS_BY_SCHEDULE_IDS: null,
  GET_SCHEDULES_WITH_SCHEDULE_SHIFTS_BY_SCHEDULE_IDS_SUCCESS: null,
  GET_SCHEDULES_WITH_SCHEDULE_SHIFTS_BY_SCHEDULE_IDS_FAILURE: null,

  UPDATE_SCHEDULE_WITH_SHIFTS: null,
  UPDATE_SCHEDULE_WITH_SHIFTS_SUCCESS: null,
  UPDATE_SCHEDULE_WITH_SHIFTS_FAILURE: null,

  UPDATE_SCHEDULE_REQUEST: null,
  UPDATE_SCHEDULE_SUCCESS: null,
  UPDATE_SCHEDULE_FAILURE: null,

  UPDATE_SHIFTS_REQUEST: null,
  UPDATE_SHIFTS_SUCCESS: null,
  UPDATE_SHIFTS_FAILURE: null,

  CREATE_SCHEDULES_MULTI_WITH_SHIFTS: null,
  CREATE_SCHEDULES_MULTI_WITH_SHIFTS_SUCCESS: null,
  CREATE_SCHEDULES_MULTI_WITH_SHIFTS_FAILURE: null,

  CREATE_SCHEDULE_WITH_SHIFTS: null,
  CREATE_SCHEDULE_WITH_SHIFTS_SUCCESS: null,
  CREATE_SCHEDULE_WITH_SHIFTS_FAILURE: null,
});
