import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const SimpleText = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: ${props => props.theme.colors.buttonBlue};
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;

  ${props =>
    !props.removeUnderline &&
    css`
      text-decoration: underline;
    `}

  &:focus {
    outline: 0;
  }
`;

class SimpleTextButton extends Component {
  onClick = () => {
    this.props.onClick();
  };

  render() {
    const { children, className, removeUnderline } = this.props;
    return (
      <SimpleText
        removeUnderline={removeUnderline}
        onClick={this.onClick}
        className={className}
      >
        {children}
      </SimpleText>
    );
  }
}

export default SimpleTextButton;
