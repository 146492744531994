// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AdminUserCompliance from 'components/users/AdminUserCompliance';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import { updateCarerProfile } from '_actions/carerActions';

import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

import type {
  CarerProfileType,
  TaxonomiesClassificationType,
} from 'shared/types';

type Props = {
  profile: CarerProfileType,
  taxonomiesByClassification: TaxonomiesClassificationType,
  updateCarerProfile: Function,
};

export class AdminUserComplianceContainer extends Component<Props> {

  updateCarerProfile = data => {
    this.props.updateCarerProfile(this.props.profile.user_id, data, true, true);
  };

  render() {

    const {
      profile,
      taxonomiesByClassification,
    } = this.props;

    if (!taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminUserCompliance
        profile={profile}
        taxonomiesByClassification={taxonomiesByClassification}
        updateCarerProfile={this.updateCarerProfile}
      />
    );
  }

}

const mapStateToProps = (state, props) => ({
  taxonomiesByClassification: getTaxonomiesByClassifications(state, [
    'compliance_fields',
    'franchise_country',
    'right_to_work',
  ]),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    	updateCarerProfile,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserComplianceContainer);