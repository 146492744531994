import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-gb';
import { arrayOf, func, shape, string } from 'prop-types';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import { TableCarerImage } from 'components/carers/CarerImage';
import Modal from 'components/modules/Modal';
import { ShiftTypeIcons } from 'components/global/icons/IconLabelMappings';
import { Container, Row, Col } from 'components/grid';
import { BorderedHeader } from 'components/global/layout/Module';

import { getNeedsforUserByID } from 'shared/selectors/needSelectors';
import { getNeedIDForShift } from 'shared/selectors/shiftsSelectors';

// borrowing this line height from the carer image so the text is vertically centred
const StyledRow = styled(Row)`
  line-height: 50px;
`;

class CustomerShiftInformationContainer extends Component {
  static propTypes = {
    closePortal: func.isRequired,
    selectedShift: shape().isRequired,
    needID: string.isRequired,
    needs: arrayOf(shape()).isRequired,
  };

  render() {
    const { closePortal, needID, needs, selectedShift } = this.props;

    const need = needs.find(need => need.id === needID);

    return (
      <Modal closePortal={closePortal} small key="shift-details-modal">
        <BorderedHeader key="modal-header">
          <Row>
            <Col>
              {`${need.firstname}’s care - ${moment(selectedShift.start).format(
                'ddd D MMM YYYY',
              )}`}
            </Col>
          </Row>
        </BorderedHeader>
        <Container key="shift-details-container">
          <StyledRow>
            <Col xs={12} md={3} style={{ fontWeight: 'bold' }}>
              Time:
            </Col>
            <Col md={9}>
              {`${moment(selectedShift.start).format('HH:mm')} - ${moment(
                selectedShift.end,
              ).format('HH:mm')}`}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={3} style={{ fontWeight: 'bold' }}>
              Shift Type:
            </Col>
            <Col md={9}>
              <ShiftTypeIcons shiftType={selectedShift.type} />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={3} style={{ fontWeight: 'bold' }}>
              Address:
            </Col>
            <Col md={9}>
              {`${need.address1} ${need.address2} ${need.address3} ${
                need.postcode
              }`}
            </Col>
          </StyledRow>
          {selectedShift.carer && (
            <StyledRow xs={12}>
              <Col md={3} style={{ fontWeight: 'bold' }}>
                Carer:
              </Col>
              <Col md={3}>
                <Link to={`/carers/${selectedShift.carer.slug}/`}>
                  {selectedShift.carer_name}
                </Link>
              </Col>
              <Col md={4}>
                <a href={`tel:${selectedShift.carer.phone_number}`}>
                  {selectedShift.carer.phone_number}
                </a>
              </Col>
              <Col md={1}>
                <TableCarerImage
                  className="image"
                  carer={selectedShift.carer}
                />
              </Col>
            </StyledRow>
          )}
          {!selectedShift.carer && (
            <StyledRow>
              <Col style={{ fontWeight: 'bold' }} md={6}>
                Carer not yet assigned
              </Col>
            </StyledRow>
          )}
        </Container>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const needs = props.selectedShift
    ? getNeedsforUserByID(state, props.selectedShift.customerID)
    : null;
  return {
    needs,
    needID: getNeedIDForShift(state, props.selectedShift),
  };
};

export default connect(mapStateToProps)(CustomerShiftInformationContainer);
