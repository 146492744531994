// Ensures that an admin_filters object exists - as it can return null if not updated before
// Ensures that condition_experience if it returns null uses the need to prepopulate the admin_filters
// As we want condition_experience used in the Job Search automatically
export const normaliseAdminFilters = data => {
  const adminFiltersExist = data.admin_filters;
  const normalisedData = {
    ...data,
    admin_filters: {
      services: adminFiltersExist && data.admin_filters.services,
      condition_experience:
        adminFiltersExist && data.admin_filters.condition_experience,
      training_courses:
        adminFiltersExist && data.admin_filters.training_courses,
      languages: adminFiltersExist && data.admin_filters.languages,
      qualifications: adminFiltersExist && data.admin_filters.qualifications,
    },
  };
  return normalisedData;
};
