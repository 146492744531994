// @flow
import React, { useState } from 'react';

import BasicFlexItem from 'components/global/layout/BasicFlexItem';
import BasicFlexContainer from 'components/global/layout/BasicFlexContainer';
import InputPicker from 'components/global/form/inputs/InputPicker';
import Button from 'components/buttons/Button';

import DiscountPropType from 'proptypes/DiscountPropType';

type Props = {
  discounts: Array<DiscountPropType>,
  buttonClassName?: string,
  buttonText: string,
  onSubmit: DiscountPropType => void,
};

const AdminDiscountTable = ({
  discounts,
  buttonClassName,
  buttonText,
  onSubmit,
}: Props) => {
  const [discountUpdates, addDiscountUpdate] = useState({});

  const updateDiscounts = (value, discount, field) => {
    const existingDiscount = discountUpdates[discount.id] || discount;
    addDiscountUpdate({
      ...discountUpdates,
      [discount.id]: { ...existingDiscount, [field]: value },
    });
  };

  return (
    <BasicFlexContainer direction="column" margin="20px">
      <BasicFlexContainer>
        <BasicFlexItem basis="15%">Code</BasicFlexItem>
        <BasicFlexItem basis="10%">Percent</BasicFlexItem>
        <BasicFlexItem basis="12%">Weeks Valid</BasicFlexItem>
        <BasicFlexItem basis="12%">Amount Valid</BasicFlexItem>
        <BasicFlexItem basis="16%">Start</BasicFlexItem>
        <BasicFlexItem basis="16%">End</BasicFlexItem>
        <BasicFlexItem basis="8%">Void</BasicFlexItem>
        <BasicFlexItem basis="11%" />
      </BasicFlexContainer>
      {discounts.map(discount => (
        <BasicFlexContainer align="center" key={discount.id} margin="10px 0">
          <BasicFlexItem basis="15%">
            <InputPicker
              height="50px"
              inputType="BasicInput"
              name={`${discount.id}-slug`}
              value={
                discountUpdates[discount.id]
                  ? discountUpdates[discount.id].slug
                  : discount.slug
              }
              onChange={value => updateDiscounts(value, discount, 'slug')}
            />
          </BasicFlexItem>
          <BasicFlexItem basis="10%">
            <InputPicker
              height="50px"
              inputType="PercentageInput"
              name={`${discount.id}-percent`}
              value={
                discountUpdates[discount.id]
                  ? discountUpdates[discount.id].percent
                  : discount.percent
              }
              onChange={value => updateDiscounts(value, discount, 'percent')}
              width="50px"
            />
          </BasicFlexItem>
          <BasicFlexItem basis="12%">
            <InputPicker
              height="50px"
              name={`${discount.id}-weeks_valid`}
              inputType="BasicInput"
              type="number"
              value={
                discountUpdates[discount.id]
                  ? discountUpdates[discount.id].weeks_valid
                  : discount.weeks_valid
              }
              onChange={value =>
                updateDiscounts(value, discount, 'weeks_valid')
              }
              step="1"
              width="50px"
            />
          </BasicFlexItem>
          <BasicFlexItem basis="12%">
            <InputPicker
              height="50px"
              name={`${discount.id}-amount_valid`}
              inputType="CurrencyInput"
              placeholder="Optional"
              type="number"
              value={
                (discountUpdates[discount.id]
                  ? discountUpdates[discount.id].amount_valid
                  : discount.amount_valid) || undefined
              }
              onChange={value =>
                updateDiscounts(value, discount, 'amount_valid')
              }
            />
          </BasicFlexItem>
          <BasicFlexItem basis="16%">
            <InputPicker
              border="none"
              height="50px"
              inputType="DateInput"
              name={`${discount.id}-start_date`}
              value={
                discountUpdates[discount.id]
                  ? discountUpdates[discount.id].start_date
                  : discount.start_date
              }
              onChange={date => updateDiscounts(date, discount, 'start_date')}
            />
          </BasicFlexItem>
          <BasicFlexItem basis="16%">
            <InputPicker
              border="none"
              height="50px"
              inputType="DateInput"
              name={`${discount.id}-end_date`}
              value={
                discountUpdates[discount.id]
                  ? discountUpdates[discount.id].end_date
                  : discount.end_date
              }
              onChange={date => updateDiscounts(date, discount, 'end_date')}
            />
          </BasicFlexItem>
          <BasicFlexItem basis="8%">
            <InputPicker
              border="none"
              height="50px"
              name={`${discount.id}-void`}
              inputType="BasicCheckbox"
              inputWidth="unset"
              value={
                discountUpdates[discount.id]
                  ? discountUpdates[discount.id].void
                  : discount.void
              }
              onChange={value => updateDiscounts(value, discount, 'void')}
            />
          </BasicFlexItem>
          <BasicFlexItem basis="11%">
            <Button
              className={buttonClassName}
              onClick={() =>
                discountUpdates[discount.id]
                  ? onSubmit(discountUpdates[discount.id])
                  : null
              }
            >
              {buttonText}
            </Button>
          </BasicFlexItem>
        </BasicFlexContainer>
      ))}
    </BasicFlexContainer>
  );
};

export default AdminDiscountTable;
