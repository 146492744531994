import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addReference } from '_actions/referencesActions';
import { getUser } from 'shared/selectors/accountSelectors';

import ReferenceAddContainer from 'containers/references/ReferenceAddContainer';

class CarerReferenceAddContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      addReference: PropTypes.func.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
  };

  handleSubmit = (data, addMore) => {

    this.props.actions.addReference(
      { ...data, user_id: this.props.user.id },
      addMore ? null : '/references/summary/'
    );

    if (addMore) {
      window.scrollTo(0, 0);
    }
  };

  render() {

    return (
      <ReferenceAddContainer
        carerID={this.props.user.id}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: getUser(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addReference,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerReferenceAddContainer);
