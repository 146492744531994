import PropTypes from 'prop-types';
import React from 'react';

const LogoutIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 29"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="logout-icon"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path d="M12.5037714,25.6413689 L5.2,25.6413689 C3.21516,25.6413689 1.60472,24.1278181 1.60472,22.2745062 L1.60472,5.7900409 C1.60472,3.93130285 3.22095429,2.4231782 5.2,2.4231782 L12.6227771,2.4231782 C13.0684914,2.4231782 13.42484,2.0891911 13.42484,1.67179418 C13.42484,1.25439726 13.0684914,0.920410156 12.6227771,0.920410156 L5.2,0.920410156 C2.32952571,0.920410156 0,3.10763959 0,5.7900409 L0,22.2745062 C0,24.9626119 2.33561714,27.1441369 5.2,27.1441369 L12.5037714,27.1441369 C12.9494857,27.1441369 13.3061314,26.8101498 13.3061314,26.3927529 C13.3061314,25.975356 12.9433943,25.6413689 12.5037714,25.6413689 Z" />
        <path d="M25.7205371,13.4565133 L20.6218629,8.31710575 C20.3066686,7.99979089 19.8015257,7.99979089 19.4866286,8.31710575 C19.1715086,8.6347201 19.1715086,9.14393632 19.4866286,9.46132606 L23.2186686,13.2231319 L6.94133143,13.2231319 C6.49561714,13.2231319 6.13904571,13.5823012 6.13904571,14.0315435 C6.13904571,14.4807859 6.49561714,14.8401798 6.94133143,14.8401798 L23.2186686,14.8401798 L19.4866286,18.6020605 C19.1715086,18.9193754 19.1715086,19.4285916 19.4866286,19.7459064 C19.6409943,19.9017935 19.8490686,19.9854275 20.0513486,19.9854275 C20.2533314,19.9854275 20.4613314,19.9076337 20.6157714,19.7459064 L25.7147429,14.6064989 C26.0356571,14.2833439 26.0356571,13.767988 25.7205371,13.4565133 Z" />
      </g>
    </svg>
  </div>
);

LogoutIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

LogoutIcon.defaultProps = {
  className: '',
  height: '29px',
  width: '27px',
};

export default LogoutIcon;
