import CareTeamConstants from 'shared/constants/CareTeamConstants';
import {
  addObject,
  addObjects,
  updateObject,
  setLoadingState,
  addObjectSetLoadingState,
} from 'shared/reducers/reducerHelpers';

const initalState = {
  loading: {
    careTeam: false,
    careTeamWithCarers: false,
  },
};

function updateTeam(state, action) {
  return addObject(state, action);
}

function updateTeams(state, action) {
  return addObjects(state, action);
}

function updateTeamMemberState(state, action) {
  const team = Object.assign({}, state[action.data.care_team_id]);
  let updatedMember;
  const updatedMembers = {};
  // Remove updated member from member status and move them to correct location as new response
  Object.keys(team.members).forEach(status => {
    updatedMembers[status] = team.members[status].filter(member => {
      const isMember = member.carer_id === action.data.carer_id;
      if (isMember) {
        updatedMember = action.data;
      }
      return !isMember;
    });
  });
  team.members = updatedMembers;
  team.members[action.data.state.state].push(updatedMember);
  return updateObject(state, { [action.data.care_team_id]: team });
}

function careTeams(state = initalState, action) {
  switch (action.type) {
    case CareTeamConstants.CARETEAM_GET_BY_NEED:
      return setLoadingState(state, 'careTeam', true);
    case CareTeamConstants.CARETEAM_GET_BY_NEED_SUCCESS:
      return addObjectSetLoadingState(state, action, 'careTeam', false);
    case CareTeamConstants.CARETEAM_GET_BY_NEED_FAILURE:
      return setLoadingState(state, 'careTeam', false);
    case CareTeamConstants.CARETEAM_GET_BY_NEED_WITH_CARERS:
      return setLoadingState(state, 'careTeamWithCarers', true);
    case CareTeamConstants.CARETEAM_GET_BY_NEED_WITH_CARERS_SUCCESS:
    case CareTeamConstants.CARETEAM_GET_BY_NEED_WITH_CARERS_FAILURE:
      return setLoadingState(state, 'careTeamWithCarers', false);
    case CareTeamConstants.CARETEAM_CREATE_SUCCESS:
    case CareTeamConstants.CARETEAM_UPDATE_SUCCESS:
    case CareTeamConstants.CARETEAM_GET_TEAMS_BY_OWNER_SUCCESS:
      return updateTeam(state, action);

    case CareTeamConstants.CARETEAM_GET_TEAMS_BY_CARER_SUCCESS:
      return updateTeams(state, action);

    case CareTeamConstants.CARETEAM_SEARCH_SUCCESS:
      return addObjects(state, action);

    case CareTeamConstants.UPDATE_TEAM_MEMBER_STATE_SUCCESS:
      return updateTeamMemberState(state, action);

    case CareTeamConstants.SEND_CARER_CONFIRMATION_SUCCESS:
      return updateObject(state, { [action.data.id]: action.data });

    default:
      return state;
  }
}

export default careTeams;
