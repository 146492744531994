import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  reduxForm,
  getFormValues,
  formValueSelector,
  getFormSyncErrors,
} from 'redux-form';
import deepDiff from 'deep-diff';
import { differentiateArrayOfObjects } from 'components/helpers/arrayHelpers';
import _ from 'lodash';

import {
  adminUpdateCarerProfile,
  getCarerById,
  getCarerProfileByCarerId,
  partialUpdateCarerProfile,
  queryCarerProfile,
  updateCarerProfile,
} from '_actions/carerActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import CarerEditProfile from 'components/carers/profile/CarerEditProfile';
import {
  getUser,
  isAdmin,
  isRecruiter,
} from 'shared/selectors/accountSelectors';

import {
  getCarerByID,
  getLiveProfileByCarerID,
  getDraftProfileByCarerID,
} from 'shared/selectors/carerSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

const formName = 'editCarerProfile';
const ReduxCarerEditProfile = reduxForm({
  enableReinitialize: true,
  form: formName,
  touchOnChange: true,
})(CarerEditProfile);

class CarerEditProfileContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      adminUpdateCarerProfile: PropTypes.func.isRequired,
      getCarerById: PropTypes.func.isRequired,
      getCarerProfileByCarerId: PropTypes.func.isRequired,
      partialUpdateCarerProfile: PropTypes.func.isRequired,
      queryCarerProfile: PropTypes.func.isRequired,
      updateCarerProfile: PropTypes.func.isRequired,
    }).isRequired,
    isStaff: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape().isRequired,
    }).isRequired,
    taxonomiesByClassification: PropTypes.shape(),
    user: PropTypes.shape(),
    countryPreference: PropTypes.arrayOf(PropTypes.string),
    dbsNumber: PropTypes.string,
    draftProfile: PropTypes.shape(),
    liveProfile: PropTypes.shape(),
    rtwCertification: PropTypes.bool,
    rtwExpires: PropTypes.bool,
    values: PropTypes.shape(),
  };

  static defaultProps = {
    carer: null,
    countryPreference: [],
    dbsNumber: '',
    draftProfile: null,
    liveProfile: null,
    rtwCertification: false,
    rtwExpires: false,
    taxonomiesByClassification: {},
    user: null,
    values: {},
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'car_categories',
      'care_experience_types',
      'care_experience_years',
      'care_type',
      'condition_experience',
      'condition',
      'equipment',
      'franchise_country',
      'hobbies',
      'languages',
      'language_level',
      'likes_animals',
      'profile_needs_approval',
      'qualifications',
      'right_to_work',
      'services',
      'training_courses',
    ];
  }

  constructor(props) {
    super(props);

    this.state = {
      profile: null,
    };

    this.carerID = props.match.params.carerID || this.props.user.id;
  }

  componentDidMount() {
    this.props.actions.getCarerById(this.carerID);

    this.props.actions.getCarerProfileByCarerId(this.carerID, {
      live_status: 'draft',
    });

    this.props.actions.getCarerProfileByCarerId(this.carerID, {
      live_status: 'live',
    });
  }

  onSubmit = data => {
    const newData = { ...data };

    if (newData.dbs_check) {
      delete newData.dbs_check.state;
    }
    if (newData.right_to_work && !newData.right_to_work.expiry_date) {
      delete newData.right_to_work.expiry_date;
    }

    if (this.props.isStaff) {
      this.props.actions.adminUpdateCarerProfile({
        carerID: this.carerID,
        data: newData,
      });
    } else {
      this.props.actions.updateCarerProfile(
        this.carerID,
        newData,
        true,
        this.props.isStaff,
      );
    }
  };

  queryProfile = message =>
    this.props.actions.queryCarerProfile(this.carerID, message);

  sectionApprove = fields => {
    const { values } = this.props;
    const data = {};
    fields.forEach(field => {
      if (
        typeof values[field] === 'string' ||
        typeof values[field] === 'boolean' ||
        Array.isArray(values[field])
      ) {
        data[field] = values[field];
      } else {
        data[field] = { ...values[field] };
      }
      return true;
    });

    if (data.dbs_check) {
      delete data.dbs_check.state;
    }
    if (!data.training_courses) {
      delete data.training_courses;
    }
    this.props.actions.adminUpdateCarerProfile(
      { carerID: this.carerID, data },
      true,
    );
  };

  sectionReject = fields => {
    const { liveProfile } = this.props;
    const data = {};
    fields.forEach(field => {
      if (
        typeof liveProfile.profile_data[field] === 'string' ||
        typeof liveProfile.profile_data[field] === 'boolean' ||
        Array.isArray(liveProfile.profile_data[field])
      ) {
        data[field] = liveProfile.profile_data[field];
      } else {
        data[field] = { ...liveProfile.profile_data[field] };
      }
      return true;
    });

    if (data.dbs_check) {
      delete data.dbs_check.state;
    }
    this.props.actions.adminUpdateCarerProfile({ carerID: this.carerID, data });
  };

  itemApprove = (fields, picks) => {
    // for each live profile version of the fieldDiff
    const { liveProfile, values } = this.props;
    const liveData = {};
    const draftData = {};
    fields.forEach(field => {
      // use the live version as a base
      const liveField = this.copyField(liveProfile.profile_data[field]);
      // update live using the current form values with picks to filter
      liveData[field] = this.updateField(
        liveField,
        values[field],
        picks[field],
      );
      // new draft is just whatever the form is set to now
      draftData[field] = this.copyField(values[field]);
      return true;
    });

    if (liveData.dbs_check) {
      delete liveData.dbs_check.state;
      delete draftData.dbs_check.state;
    }
    if (liveData.right_to_work && !liveData.right_to_work.expiry_date) {
      delete liveData.right_to_work.expiry_date;
    }
    if (draftData.right_to_work && !draftData.right_to_work.expiry_date) {
      delete draftData.right_to_work.expiry_date;
    }
    this.props.actions.partialUpdateCarerProfile({
      carerID: this.carerID,
      liveData,
      draftData,
    });
  };

  copyField = field => {
    if (!field || typeof field === 'string' || typeof field === 'boolean') {
      return field;
    } else if (Array.isArray(field)) {
      return [...field];
    }
    return { ...field };
  };

  updateField = (previous, next = {}, allowedAdditions = []) => {
    let updated;
    let safePrevious = previous;
    if (!previous) {
      // Might previously have been null, so we need to handle that
      safePrevious = Array.isArray(next) ? [] : {};
    }
    if (Array.isArray(safePrevious)) {
      if (next.length && typeof next[0] === 'string') {
        updated = next.filter(
          item =>
            safePrevious.includes(item) || allowedAdditions.includes(item),
        );
      } else {
        updated = next.filter(
          item => _.find(safePrevious, item) || _.find(allowedAdditions, item),
        );
      }
    } else {
      const expected = {};
      if (next) {
        Object.keys(next).forEach(key => {
          expected[key] = safePrevious[key];
          return true;
        });
      }
      updated = {
        ...expected,
        ...allowedAdditions,
      };
    }
    return updated;
  };

  render() {
    const {
      carer,
      countryPreference,
      draftProfile,
      liveProfile,
      rtwCertification,
      rtwExpires,
      taxonomiesByClassification,
      dbsNumber,
      isStaff,
      syncErrors,
    } = this.props;

    if (!liveProfile) {
      return <LoadingPlaceholder />;
    }

    const profile = draftProfile || liveProfile;
    const hiComplianceFields = liveProfile && (liveProfile.hi_compliance_fields || {});
    const diff = {};

    // TODO: Maybe don't do this each render?
    if (isStaff && draftProfile && liveProfile) {
      Object.keys(draftProfile.profile_data).forEach(key => {

        const rhs = draftProfile.profile_data[key] || [];
        let lhs = liveProfile.profile_data[key] || [];

        // Array diffing is handled DIFFerently
        if (Array.isArray(lhs) && Array.isArray(rhs)) {

          if (!lhs.length && !rhs.length) {
            return;
          }

          if (typeof rhs[0] === 'string') {
            // If it's an array of strings we can easily distinguish items unique to both sides
            diff[key] = {
              added: rhs.filter(item => !lhs.includes(item)),
              removed: lhs.filter(item => !rhs.includes(item)),
            };
          } else {
            // Otherwise it's an array of objects, we need to check each property for equality
            diff[key] = {
              added: differentiateArrayOfObjects(rhs, lhs),
              removed: differentiateArrayOfObjects(lhs, rhs),
            };
          }
        } else {

          if (
            typeof(rhs) === 'object' &&
            !Array.isArray(rhs) &&
            Array.isArray(lhs)
          ) {
            lhs = {};
          }

          console.log(lhs);
          console.log(rhs);

          const fieldDiff = deepDiff(lhs, rhs) || [];
          console.log(fieldDiff);

          fieldDiff.forEach(change => {
            const path = change.path ? `${key}.${change.path.join('.')}` : key;
            if (change.lhs !== null || change.rhs !== null) {
              diff[path] = change;
            }
          });
        }
      });
    }

    if (!carer || !profile || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    const data = profile.profile_data || {};
    const dbsState = ((data.dbs_check || {}).state) || 'notchecked';
    const dbsDOB = ((data.dbs_check || {}).dob) || '';
    const dbsDate = ((data.dbs_check || {}).last_checked_date) || null;
    const initialValues = {
      bio: data.bio,
      car: data.car,
      car_insurance: data.car_insurance,
      care_experience_years: data.care_experience_years,
      care_type: data.care_type,
      condition_experience: data.condition_experience,
      country_preference: profile.country_preference,
      dbs_check: { ...data.dbs_check },
      driving_license: data.driving_license,
      equipment: data.equipment,
      hobbies: data.hobbies,
      languages: data.languages,
      likes_animals: data.likes_animals,
      ni_access_ni: data.ni_access_ni,
      ni_social_care: data.ni_social_care,
      other_hobbies: data.other_hobbies,
      qualifications: data.qualifications || [],
      right_to_work: { ...data.right_to_work },
      scotland_pvg: data.scotland_pvg,
      scotland_social_services: data.scotland_social_services,
      services: data.services,
      smoker: data.smoker,
      training_courses: data.training_courses || [],
      wales_social_care: data.wales_social_care,
      work_postcode: data.work_postcode,
    }

    return (
      <ReduxCarerEditProfile
        carer={carer}
        countryPreference={countryPreference}
        dbsDate={dbsDate}
        dbsDOB={dbsDOB}
        dbsNumber={dbsNumber}
        dbsState={dbsState}
        diff={diff}
        hiComplianceFields={hiComplianceFields}
        initialValues={initialValues}
        isStaff={isStaff}
        itemApprove={this.itemApprove}
        onSubmit={this.onSubmit}
        profile={profile}
        queryProfile={this.queryProfile}
        rtwCertification={rtwCertification}
        rtwExpires={rtwExpires}
        sectionApprove={this.sectionApprove}
        sectionReject={this.sectionReject}
        submitErrors={syncErrors}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );

  }
}

const mapStateToProps = (state, props) => {
  const carerID = props.match.params.carerID || getUser(state).id;
  const values = getFormValues(formName)(state);
  const formSelector = formValueSelector(formName);
  const dbsNumber = formSelector(state, 'dbs_check.certificate_id');
  const countryPreference = formSelector(state, 'country_preference');
  const syncErrors = getFormSyncErrors(formName)(state);

  const rtw = formSelector(state, 'right_to_work.validation');
  const rtwEU = rtw && ['eupassportornationalidentitycard'].includes(rtw);
  const rtwEUExpires = rtwEU && !formSelector(state, 'right_to_work.settled_status_confirmed');
  const rtwOtherExpires = rtw && [
    'biometricresidencepermit',
    'visainavalidpassportpassportmuststillbeindate',
  ].includes(rtw);

  return {
    carer: getCarerByID(state, carerID),
    countryPreference: countryPreference,
    dbsNumber,
    rtwCertification: rtwEU,
    rtwExpires: rtwOtherExpires || rtwEUExpires,
    draftProfile: getDraftProfileByCarerID(state, carerID),
    liveProfile: getLiveProfileByCarerID(state, carerID),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      CarerEditProfileContainer.TAXONOMY_CLASSIFICATIONS,
    ),
    user: getUser(state),
    isStaff: isAdmin(state) || isRecruiter(state),
    values,
    syncErrors,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        adminUpdateCarerProfile,
        getCarerById,
        getCarerProfileByCarerId,
        partialUpdateCarerProfile,
        queryCarerProfile,
        updateCarerProfile,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerEditProfileContainer);
