import Module, { ModuleHeader } from 'components/global/layout/Module';
import { StyledButton } from 'components/buttons/Button';

import React from 'react';
import { Container, Col, Row } from 'components/grid';

const Buttonlink = StyledButton.withComponent('a');

function StripeIssueBanner() {
  return (
    <Module className="danger">
      <ModuleHeader>URGENT: You cannot be paid</ModuleHeader>
      <Container>
        <Row>
          <Col>
            <p>
              You need to provide proof of identity to Stripe, our payment
              provider.
            </p>
            <p>We’ve sent you an email with instructions on how to fix this.</p>
            <p>Click the button below to log in to your Stripe account.</p>
            <Buttonlink href="https://dashboard.stripe.com/" className="danger">
              Log into Stripe
            </Buttonlink>
          </Col>
        </Row>
      </Container>
    </Module>
  );
}

export default StripeIssueBanner;
