/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

const colStyle = (colWidth, colGutter, isFirst = false, isLast = false) => ({
  boxSizing: 'border-box',
  flexBasis: `${(100 / 12) * colWidth}%`,
  maxWidth: `${(100 / 12) * colWidth}%`,
  minWidth: 0,
  paddingRight: `${isLast || !colGutter ? undefined : colGutter / 2}px`,
  paddingLeft: `${isFirst || !colGutter ? undefined : colGutter / 2}px`,
});

const ColContainer = ({
  first,
  last,
  width,
  offsetWidth,
  style = {},
  children,
  id,
  className,
  colGutter,
  ...otherProps
}) => {
  const offsetCoeff = 12 / (offsetWidth + width);
  const actualOffsetWidth = offsetCoeff * offsetWidth;
  return offsetWidth ? (
    <div
      style={{
        display: 'flex',
        flexBasis: `${(100 / 12) * (width + offsetWidth)}%`,
      }}
    >
      <div style={colStyle(actualOffsetWidth, colGutter, first, false)} />
      <div
        style={Object.assign(
          colStyle(12 - actualOffsetWidth, colGutter, false, last),
          style,
        )}
        className={className}
        id={id}
      >
        {children}
      </div>
    </div>
  ) : (
    <div
      style={Object.assign(colStyle(width, colGutter, first, last), style)}
      className={className}
      id={id}
    >
      {children}
    </div>
  );
};

// @flow
type Props = {
  style?: object,
  className?: string,
  children: object,
  offsetWidth: object,
  width: number,
  _colGutter: number,
  first: boolean,
  last: boolean,
  offset: Offset,
};
type Offset = {
  xs: number,
  sm: number,
  md: number,
  lg: number,
  xl: number,
};

export default class Col extends React.Component {
  static propTypes = {
    style: PropTypes.shape(),
    className: PropTypes.string,
    offsetWidth: PropTypes.number,
    width: PropTypes.number,
    _colGutter: PropTypes.number,
    first: PropTypes.bool,
    last: PropTypes.bool,
    offset: PropTypes.shape({
      xs: PropTypes.number,
      sm: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
      xl: PropTypes.number,
    }),
  };

  render() {
    const {
      style,
      className,
      children,
      offsetWidth,
      width,
      _colGutter,
      first,
      last,
      id,
    } = this.props;

    const colProps = {
      id,
      first,
      last,
      colGutter: _colGutter,
      style,
      className,
      width: Math.min(width, 12),
      offsetWidth: Math.min(offsetWidth, 12),
    };
    return <ColContainer {...colProps}>{children}</ColContainer>;
  }
}
