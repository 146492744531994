export const hasRole = (user, ...roles) => {
  let has = false;
  if (user) {
    Array.from(roles).forEach(role => {
      if (user.roles && user.roles.includes(role)) {
        has = true;
      }
    });
  }
  return has;
};

export const isStaff = user =>
  hasRole(user, 'Admin', 'Super', 'Agent', 'Recruiter');


export const isFranchiseMember = user =>
  hasRole(user, 'Franchisee');