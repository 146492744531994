// @flow
import React from 'react';
import styled from 'styled-components';
import Currency from 'components/modules/Currency';

import DiscountPropType from 'proptypes/DiscountPropType';

type Props = {
  discount: DiscountPropType,
  isAdmin: boolean,
};

const DiscountContainer = styled.div`
  padding: 0 20px 20px;
  font-style: italic;
`;

const TimesheetTotalDiscount = ({ discount, isAdmin }: Props) => {
  const discountPercentage = `${(discount.percent * 100).toFixed(0)}%`;
  return (
    <DiscountContainer>
      <div>
        Includes{' '}
        <strong>
          <Currency value={discount.total} />{' '}
          {isAdmin ? `(${discountPercentage})` : null}
        </strong>{' '}
        discount from code <strong>{discount.slug}</strong>.
      </div>
    </DiscountContainer>
  );
};

export default TimesheetTotalDiscount;
