import styled from 'styled-components';

const StyledLabel = styled.label`
  color: ${props => props.theme.colors.midGrey};
  margin-bottom: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;

  > em {
    color: ${props => props.theme.colors.midGrey};
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
`;

export default StyledLabel;
