import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledPaymentStatus = styled.div`
  margin-top: 5px;
  @media (min-width: 768px) {
    margin: 0;
  }
`;

function PaymentStatus({ transferredAt }) {
  if (transferredAt) {
    const transferred = moment(transferredAt);
    const date = transferred.calendar(null, {
      sameDay: '[today]',
      nextDay: '[tomorrow]',
      nextWeek: '[on] dddd',
      lastDay: '[yesterday]',
      lastWeek: '[last] dddd',
      sameElse: '[on] dddd, MMMM Do',
    });
    let message;
    if (transferred.isAfter(moment())) {
      message = `Money should be received ${date}.`;
    } else {
      message = `Money should have been received ${date}.`;
    }

    return <StyledPaymentStatus>{message}</StyledPaymentStatus>;
  }
}

PaymentStatus.propTypes = {
  transferredAt: PropTypes.string.isRequired,
};

export default PaymentStatus;
