import React from 'react';
import styled from 'styled-components';

import { Container, Col, Row } from 'components/grid';
import { ThickUnderline } from 'components/global/StyledUnderlines';

const Title = styled.div`
  font-size: 32px;
  color: #ffffff;

  @media (min-width: ${props => props.theme.screen.small}px) {
    font-size: 28px;
  }
`;

const Message = styled.p`
  color: #ffffff;
  margin: 20px 0;
  font-size: 18px;
  overflow-wrap: break-word;
  word-wrap: break-word;

  @media (min-width: ${props => props.theme.screen.small}px) {
    font-size: 16px;
  }
`;

const Rules = styled.div`
  color: #ffffff;
  font-size: 18px;

  @media (min-width: ${props => props.theme.screen.small}px) {
    font-size: 16px;
  }

  ul > li {
    list-style-type: disc;
    list-style-position: inside;
  }
`;

const RULES = [
  '8+ characters',
  'Upper and lower case',
  'Incl. numbers & symbols',
  'Avoid common words',
];

function AccountLoginMessageBoard({
  title,
  message,
  secondaryMessage,
  button,
  rules = false,
}) {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <Title>{title}</Title>
          <ThickUnderline themeColour={'hiCream'} />
        </Col>
        <Col md={12}>
          <Message>{message}</Message>
          {!!rules && (
            <Rules>
              <div>Please set a strong password. Suggested rules:</div>
              <br/>
              <ul>
                {RULES.map(rule => (
                  <li>{rule}</li>
                ))}
              </ul>
            </Rules>
          )}
          <Message>{secondaryMessage}</Message>
        </Col>
      </Row>
    </Container>
  );
}

export default AccountLoginMessageBoard;
