import TaxonomyConstants from 'shared/constants/TaxonomyConstants';

const initalState = {
  taxonomies: null,
};

function taxonomies(state = initalState, action, returnDefaultState = true) {
  switch (action.type) {
    case TaxonomyConstants.TAXONOMY_GET_ALL_TAXONOMIES_SUCCESS:
      return { ...state, taxonomies: action.data };

    default:
      return returnDefaultState ? state : false;
  }
}

export default taxonomies;
