// @flow
import React from 'react';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import CarerImage from 'components/carers/CarerImage';
import SimpleTextButton from 'components/buttons/SimpleTextButton';
import { LinkIcon } from 'components/global/icons/small';
import { ModuleHeader } from 'components/global/layout/Module';

import type { CarerType } from 'shared/types';

const StyledLinkIcon = styled(LinkIcon)`
  display: inline-block;
  color: ${props => props.theme.colors.statusBlue};
  margin-right: 6px;
  position: relative;
  top: 4px;
`;

const StyledModuleHeader = styled(ModuleHeader)`
  margin: 10px 0 0;
`;

const StyledSimpleButton = styled(SimpleTextButton)`
  width: 100%;
`;

const StyledCarerProfileHeader = styled.div`
  margin-bottom: -50px;
  position: relative;
  top: -50px;

  @media (min-width: 768px) {
    border-bottom: 1px solid ${props => props.theme.colors.uiGrey};
    margin-bottom: 0;
    position: static;
  }
`;

type Props = {|
  adminView: boolean,
  carer: CarerType,
  shareableDialogToggle: () => void,
|};

const ProfileHeader = ({ adminView, carer, shareableDialogToggle }: Props) => (
  <StyledCarerProfileHeader>
    <CarerImage carer={carer} width={140} height={140} />
    <StyledModuleHeader centered>
      {adminView ? (
        <Link to={`/admin/users/${carer.id}/`}>{carer.display_name}</Link>
      ) : (
        carer.display_name
      )}
    </StyledModuleHeader>
    <StyledSimpleButton onClick={shareableDialogToggle} removeUnderline>
      <StyledLinkIcon width="20" />
      Get a shareable link
    </StyledSimpleButton>
  </StyledCarerProfileHeader>
);

export default ProfileHeader;
