import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import StyledLabel from 'components/forms/StyledLabel';
import { renderTaxon } from 'components/helpers/taxonomyHelpers';
import { upperCaseFirstChar } from 'components/helpers/stringHelpers';

const StyledChildrenGroup = styled.div`
  background: ${props => props.theme.colors.lightGrey};
  border-left: 2px solid ${props => props.theme.colors.darkBlue};
  margin: 10px 0 0 10px;
  padding: 10px;
`;

const StyledChildren = styled.div`
  margin: 10px 0;
  input {
    margin-right: 10px;
  }
`;

export default class CheckboxChildren extends PureComponent {
  static propTypes = {
    children: PropTypes.object.isRequired,
    parent: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.object.isRequired,
    selected: PropTypes.array.isRequired,
  };

  render() {
    const {
      children,
      parent,
      onChange,
      taxonomiesByClassification,
      selected,
    } = this.props;

    return (
      <StyledChildrenGroup>
        <StyledLabel>
          {upperCaseFirstChar(Object.keys(children)[0])}
        </StyledLabel>
        {children[Object.keys(children)[0]].map(child => (
          <StyledChildren key={child}>
            <label htmlFor={`${parent}_${child}`}>
              <input
                id={`${parent}_${child}`}
                value={`${parent}_${child}`}
                type="checkbox"
                onChange={onChange}
                onFocus={e => e.stopPropagation()}
                onBlur={e => e.stopPropagation()}
                checked={selected.includes(`${parent}_${child}`)}
              />
              {renderTaxon(
                taxonomiesByClassification[Object.keys(children)[0]],
                child,
              )}
            </label>
          </StyledChildren>
        ))}
      </StyledChildrenGroup>
    );
  }
}
