const rp = require('request-promise');

class MetaClient {
  static getCurrentEtag() {
    // check for update
    return rp({
      uri: window.location.origin,
      method: 'HEAD',
    }).then(
      response =>
        // return the etag of the document
        response.etag,
    );
  }
}

module.exports = MetaClient;
