// @flow
import React from 'react';
import styled, { type Interpolation } from 'styled-components';

import { BulletedList } from 'components/global/informationdisplay/DisplayLists';

import type {
  CarerType,
  ProfileDataType,
  TaxonomiesClassificationType,
} from 'shared/types';

const StyledLists = styled.div`
  > ul {
    margin-bottom: 10px;
  }
`;

type Props = {|
  carer: CarerType,
  listStyling: Array<Interpolation>,
  taxonomiesByClassification: TaxonomiesClassificationType,
|};

const ProfileOverview = ({
  carer,
  listStyling,
  taxonomiesByClassification,
}: Props) => {
  const data: ProfileDataType = carer.profile_details.profile_data;

  if (!data.condition_experience && !data.services && !data.equipment) {
    return null;
  }

  return (
    <StyledLists>
      {data.condition_experience && (
        <BulletedList
          list={data.condition_experience
            .filter(
              conditionExp => conditionExp.experience !== 'lessthan1month',
            )
            .map(conditionExp => conditionExp.condition)}
          taxonomies={taxonomiesByClassification['condition']}
          moduleLabel="I have experience with…"
          listStyling={listStyling}
        />
      )}
      <BulletedList
        list={data.services}
        taxonomies={taxonomiesByClassification['services']}
        moduleLabel="I can help with…"
        listStyling={listStyling}
      />
      <BulletedList
        list={data.equipment}
        taxonomies={taxonomiesByClassification['equipment']}
        moduleLabel=" I have used…"
        listStyling={listStyling}
      />
    </StyledLists>
  );
};

export default ProfileOverview;
