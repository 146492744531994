// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';

// $FlowFixMe - Needs a fix for styled components
const StyledFlexTableRow = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  box-shadow: 0 1px 0 #c8e8f9;
  justify-content: center;

  ${props =>
    props.header &&
    css`
      font-weight: bold;
      color: ${props => props.theme.colors.midGrey};
    `}
`;

type Props = {
  children: React.Node,
  className?: string,
  header?: boolean,
};

const FlexTableRow = ({ children, className, header }: Props) => (
  <StyledFlexTableRow className={className} header={header}>
    {children}
  </StyledFlexTableRow>
);

export default FlexTableRow;
