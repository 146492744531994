// @flow
import React from 'react';

import { SCDefaultEmail } from 'components/global/ContactInfo';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import { Container, Row, Col } from 'components/grid';

import composedLoginWrapper from 'containers/login/composedLoginWrapper';

export const ErrorMessage = () => (
  <Module>
    <ModuleHeader>Something went wrong</ModuleHeader>
    <Container>
      <Row>
        <Col>
          Our team has been notified and will investigate the issue as soon as
          possible. In the meantime please try refreshing the page and trying
          again. If those does not resolve the problem for you please email us
          at <SCDefaultEmail/>.
        </Col>
      </Row>
    </Container>
  </Module>
);

export default composedLoginWrapper(ErrorMessage);
