import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import daysMap from 'components/helpers/daysMap';
import niceShiftTimes from 'components/helpers/niceShiftTimes';
import StyledBoolean from 'components/global/StyledBoolean';

const WeeklyShifts = styled.div`
  font-weight: bold;
`;

const ShiftDetail = styled.div`
  &:last-child {
    margin-bottom: 5px;
  }
  .day {
    display: inline-block;
    min-width: 100px;
  }
  .time {
    display: inline-block;
  }
  &.unavailable {
    color: ${props => props.theme.colors.midGrey};
  }
`;

class ShiftDetails extends Component {
  static propTypes = {
    schedule: PropTypes.shape().isRequired,
    appliedShifts: PropTypes.arrayOf(PropTypes.shape()),
    bidID: PropTypes.string,
  };

  static defaultProps = {
    appliedShift: [],
    bidID: '',
  };

  formatWeekNumber(weekNumber) {
    return `Week ${parseInt(weekNumber.split('_')[1], 10) + 1}`;
  }

  render() {
    const { bidID, schedule, appliedShifts } = this.props;
    if (!schedule || !schedule.shifts) {
      return false;
    }

    if (!(appliedShifts || []).length) {
      return (
        <p>
          No shifts have been applied for. Please contact us for more
          information.
        </p>
      );
    }

    const weeksDetails = Object.entries(schedule.shifts).map(
      ([week, shifts]) => {
        const details = [];
        Object.entries(daysMap).forEach(([shortDay, day]) => {
          shifts[shortDay] &&
            shifts[shortDay].forEach((shift, index) => {
              let availableShift;
              availableShift = appliedShifts.find(
                appliedShift => shift.id === appliedShift.id,
              );
              details.push(
                <ShiftDetail
                  className={availableShift ? 'available' : 'unavailable'}
                  key={shift.id}
                >
                  <span className="day">{day}</span>
                  <span className="time">{niceShiftTimes(shift)}</span>
                  <StyledBoolean value={!!availableShift} />
                </ShiftDetail>,
              );
            });
        });

        return (
          <Fragment>
            <WeeklyShifts key={`${bidID}${schedule.id}${week}`}>
              {this.formatWeekNumber(week)}
            </WeeklyShifts>
            {details}
          </Fragment>
        );
      },
    );

    return weeksDetails;
  }
}

export default ShiftDetails;
