import AccountConstants from 'shared/constants/AccountConstants';

const initalState = {};

function errors(state = initalState, action) {
  switch (action.type) {
    case AccountConstants.MAGIC_TOKEN_LOGIN_FAILURE:
      return { ...state, magicTokenError: true };

    default:
      return state;
  }
}

export default errors;
