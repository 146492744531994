import CarerConstants from 'shared/constants/CarerConstants';
import { updateObject } from 'shared/reducers/reducerHelpers';

const initalState = {
  live: {},
  draft: {},
};

function carerProfiles(state = initalState, action) {
  switch (action.type) {
    case CarerConstants.CARER_GET_PROFILE_SUCCESS: {
      let updateProfiles = updateObject(state);
      const liveStatus = action.data.live_status;
      updateProfiles[liveStatus][action.data.user_id] = action.data;
      return updateProfiles;
    }

    case CarerConstants.CARER_UPDATE_PROFILE_SUCCESS:
    case CarerConstants.CARER_PARTIAL_UPDATE_PROFILE_SUCCESS: {
      let updateProfiles = updateObject(state);
      let userID;

      if (action.data.live) {
        userID = action.data.live.user_id;
        updateProfiles.live[userID] = action.data.live;
      }

      if (action.data.draft) {
        updateProfiles.draft[userID] = action.data.draft;
      } else {
        delete updateProfiles.draft[userID];
      }

      return updateProfiles;
    }

    case CarerConstants.CLEAR_DRAFT_PROFILE: {
      let updateProfiles = updateObject(state);
      if (updateProfiles.draft[action.payload.carerID]) {
        delete updateProfiles.draft[action.payload.carerID];
      }
      return updateProfiles;
    }

    default:
      return state;
  }
}

export default carerProfiles;
