import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';

class TeamSearchFilters extends Component {
  render() {
    const filters = this.props.filters;

    return (
      <div className="module">
        <div className="old-row">
          <div className="old-col col-50">
            <label htmlFor="state">Team State</label>
            <select
              name="state"
              value={filters.state || 'all'}
              onChange={this.props.updateFilters}
            >
              <option value="all">All</option>
              <option value="new">New</option>
              <option value="needs_approval">Needs Approval</option>
              <option value="approved">Approved</option>
            </select>
          </div>
        </div>
        <div className="old-row">
          <div className="old-col col-50">
            <Link to={this.props.location.pathname}>Reset Filters</Link>
          </div>
        </div>
      </div>
    );
  }
}

TeamSearchFilters.propTypes = {
  location: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  updateFilters: PropTypes.func.isRequired,
};

export default TeamSearchFilters;
