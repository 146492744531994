import React from 'react';
import { AsteriskIcon } from 'components/global/icons/small';

const RequiredTitle = (title, complete) => (
  <>
    {title} <AsteriskIcon complete={complete} />
  </>
);

export default RequiredTitle;
