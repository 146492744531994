import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const FormError = ({ className, children }) => (
  <span className={`error ${className}`}>{children}</span>
);

FormError.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FormError.defaultProps = { className: '' };

const ValidationError = styled(FormError)`
  background-color: ${props => props.theme.colors.warningRed};
  color: white;
  display: block;
  font-weight: 600;
  margin-top: 10px;
  padding: 25px 0;
  text-align: center;

  @media (min-width: 768px) {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    box-shadow: 0 1px 4px 0 ${props => props.theme.colors.shadowGrey};
  }
`;

export default ValidationError;
