import AdminCreateRefundForm from 'components/admin/AdminCreateRefundForm';
import Input from 'components/forms/Input';

import StyledLabel from 'components/forms/StyledLabel';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import Button from 'components/buttons/Button';
import Notes from 'components/modules/Notes';
import PaymentButton from 'components/payments/PaymentButton';
import TransactionTable from 'components/refunds/TransactionTable';
import AdminChargeFormContainer from 'containers/admin/AdminChargeFormContainer';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Col, Row } from 'components/grid';

class AdminEditCharge extends Component {
  static propTypes = {
    carers: PropTypes.arrayOf(PropTypes.shape()),
    charge: PropTypes.shape({
      state: PropTypes.string.isRequired,
      void: PropTypes.bool.isRequired,
      stripe_id: PropTypes.string,
      id: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      notes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          from_user_id: PropTypes.string.isRequired,
          created_at: PropTypes.string.isRequired,
          message: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    consumer: PropTypes.shape().isRequired,
    paymentMethods: PropTypes.arrayOf(PropTypes.shape()),
    refunds: PropTypes.arrayOf(PropTypes.shape()),
    createPaymentMethod: PropTypes.func.isRequired,
    payCharge: PropTypes.func.isRequired,
    updateCharge: PropTypes.func.isRequired,
    createRefund: PropTypes.func.isRequired,
    voidCharge: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.object.isRequired,
  };

  static defaultProps = {
    carers: null,
    paymentMethods: [],
    notified: null,
    refunds: [],
    charge: {
      notes: [],
    },
  };

  state = {
    note: null,
  };

  payCharge = () => this.props.payCharge({ payment_type: 'stripe' });

  markChargeAsPaid = () =>
    this.props.payCharge({
      payment_type: 'manual',
      note: this.state.note,
    });

  render() {
    return (
      <div>
        <AdminChargeFormContainer
          onSubmit={this.props.updateCharge}
          edit
          charge={this.props.charge}
          consumer={this.props.consumer}
          carers={this.props.carers}
          voidCharge={this.props.voidCharge}
        />
        {this.props.charge.state !== 'paid' && this.props.charge.void !== true && (
          <Module>
            <ModuleHeader>Pay Charge</ModuleHeader>
            <Container>
              <Row>
                <Col xs={12} md={8}>
                  <StyledLabel>Pay using Stripe</StyledLabel>
                </Col>
                <Col xs={12} md={4}>
                  <PaymentButton
                    paymentMethods={this.props.paymentMethods}
                    user={this.props.consumer}
                    createPaymentMethod={this.props.createPaymentMethod}
                    pay={this.payCharge}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={8}>
                  <StyledLabel>Mark as paid</StyledLabel>
                  <Input
                    name="description"
                    placeholder="Payment method and reference"
                    onChange={(name, value) => this.setState({ note: value })}
                    value={this.state.note}
                  />
                </Col>
                <Col xs={12} md={4}>
                  <StyledLabel>&nbsp;</StyledLabel>
                  <br />
                  <Button className="alt" onClick={this.markChargeAsPaid}>
                    Mark As Paid
                  </Button>
                </Col>
              </Row>
            </Container>
          </Module>
        )}
        {this.props.charge.notes > 0 && (
          <Module>
            <ModuleHeader>Notes</ModuleHeader>
            <Notes notes={Object.values(this.props.charge.notes)} />
          </Module>
        )}

        {this.props.charge.state === 'paid' && (
          <div>
            {this.props.charge.fulfilment_type !== 'manual' && (
              <AdminCreateRefundForm
                charge={this.props.charge}
                createRefund={this.props.createRefund}
                taxonomiesByClassification={
                  this.props.taxonomiesByClassification
                }
              />
            )}
            <TransactionTable
              charge={this.props.charge}
              refunds={this.props.refunds}
            />
          </div>
        )}
      </div>
    );
  }
}

export default AdminEditCharge;
