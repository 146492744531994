import ApiConstants from 'shared/constants/ApiConstants';
import CareTeamConstants from 'shared/constants/CareTeamConstants';

export function createCareTeam(data) {
  return {
    endpoint: '/care/team/',
    method: 'post',
    onError: {
      type: CareTeamConstants.CARETEAM_CREATE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to save care team',
        dataSent: data,
      },
    },
    onStart: { type: CareTeamConstants.CARETEAM_CREATE },
    onSuccess: {
      type: CareTeamConstants.CARETEAM_CREATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Care Team Saved',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function updateCareTeam(teamId, data) {
  return {
    endpoint: `/care/team/${teamId}/`,
    method: 'put',
    onError: {
      type: CareTeamConstants.CARETEAM_UPDATE_FAILURE,
    },
    onStart: { type: CareTeamConstants.CARETEAM_UPDATE },
    onSuccess: {
      type: CareTeamConstants.CARETEAM_UPDATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Care team updated successfully',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function searchCareTeams(data) {
  return {
    endpoint: '/care/team/find/',
    method: 'get',
    onError: { type: CareTeamConstants.CARETEAM_SEARCH_FAILURE },
    onStart: { type: CareTeamConstants.CARETEAM_SEARCH },
    onSuccess: { type: CareTeamConstants.CARETEAM_SEARCH_SUCCESS },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function getCareTeamByNeed(needId) {
  return {
    endpoint: `/care/team/by/need/${needId}/`,
    method: 'get',
    onError: { type: CareTeamConstants.CARETEAM_GET_BY_NEED_FAILURE },
    onStart: { type: CareTeamConstants.CARETEAM_GET_BY_NEED },
    onSuccess: { type: CareTeamConstants.CARETEAM_GET_BY_NEED_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function getCareTeamByNeedWithCarers(needId) {
  return {
    type: CareTeamConstants.CARETEAM_GET_BY_NEED_WITH_CARERS,
    payload: { needId },
  };
}

export function getCareTeamsByCarerId(carerId) {
  return {
    endpoint: `/care/team/by/carer/${carerId}/`,
    method: 'get',
    onError: { type: CareTeamConstants.CARETEAM_GET_TEAMS_BY_CARER_FAILURE },
    onStart: { type: CareTeamConstants.CARETEAM_GET_TEAMS_BY_CARER },
    onSuccess: { type: CareTeamConstants.CARETEAM_GET_TEAMS_BY_CARER_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function getCareTeamsByCustomerId(customerId) {
  return {
    endpoint: `/care/team/by/owner/${customerId}/`,
    method: 'get',
    onError: { type: CareTeamConstants.CARETEAM_GET_TEAMS_BY_OWNER_FAILURE },
    onStart: { type: CareTeamConstants.CARETEAM_GET_TEAMS_BY_OWNER },
    onSuccess: { type: CareTeamConstants.CARETEAM_GET_TEAMS_BY_OWNER_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function notifyCustomerOfCareTeam(needId) {
  return {
    endpoint: `/notification/care/team/email/${needId}/`,
    method: 'put',
    onError: {
      type: CareTeamConstants.NOTIFY_EMAIL_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to send email',
      },
    },
    onStart: { type: CareTeamConstants.NOTIFY_EMAIL },
    onSuccess: {
      type: CareTeamConstants.NOTIFY_EMAIL_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Customer email has been sent',
      },
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function updateCareTeamMemberState(teamId, carerId, data) {
  return {
    endpoint: `/care/team/${teamId}/carer/${carerId}/`,
    method: 'put',
    onError: {
      type: CareTeamConstants.UPDATE_TEAM_MEMBER_STATE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to edit care team member. Please contact Home Instead Live-in Care.',
        dataSent: data,
      },
    },
    onStart: { type: CareTeamConstants.UPDATE_TEAM_MEMBER_STATE },
    onSuccess: { type: CareTeamConstants.UPDATE_TEAM_MEMBER_STATE_SUCCESS },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function createTeamAndUpdateMember(data) {
  return {
    type: CareTeamConstants.CARETEAM_CREATE_AND_UPDATE_MEMBER,
    data, // {needId, team, memberData}
  };
}

export function updateTeamAndMember(data) {
  return {
    type: CareTeamConstants.CARETEAM_AND_MEMBER_UPDATE,
    data, // {teamId, carerId, memberData}
  };
}

export function removeTeamMember(data) {
  return {
    type: CareTeamConstants.CARETEAM_REMOVE_MEMBER,
    data, // {teamID, carerID, jobOfferID, team}
  };
}

export function sendConfirmationEmail(teamId, carerId) {
  return {
    endpoint: `/care/team/${teamId}/confirm/${carerId}/`,
    method: 'put',
    onSuccess: {
      type: CareTeamConstants.SEND_CARER_CONFIRMATION_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Confirmation email sent',
      },
    },
    type: CareTeamConstants.SEND_CARER_CONFIRMATION,
  };
}
