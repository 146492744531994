import { updateBid } from '_actions/bidActions';
import { searchJobOffers } from '_actions/jobOfferActions';
import { fetchAllSchedulesForUser } from '_actions/scheduleActions';
import CustomerBids from 'components/bids/CustomerBids';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectBidsByJobOfferID } from 'shared/selectors/bidSelectors';
import { selectAllJobOffersforUser } from 'shared/selectors/jobOfferSelectors';
import { getUser } from 'shared/selectors/accountSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

class CustomerBidsContainer extends Component {
  static propTypes = {
    fetchAllSchedulesForUser: PropTypes.func.isRequired,
    jobOffers: PropTypes.arrayOf(PropTypes.shape()),
    schedules: PropTypes.arrayOf(PropTypes.shape()),
    searchJobOffers: PropTypes.func.isRequired,
    updateBid: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    jobOffers: null,
    schedules: null,
  };

  componentDidMount() {
    this.props.searchJobOffers({ user_id: this.props.user.id, state: 'open' });
    this.props.fetchAllSchedulesForUser(this.props.user.id);
  }

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['bid_customer_rejection'];
  }

  render() {
    const { activeBids, bids, jobOffers, schedules } = this.props;

    if (!jobOffers || !jobOffers.length || !schedules.length) {
      return null;
    }

    return (
      <CustomerBids
        activeBids={activeBids}
        bids={bids}
        jobOffers={jobOffers}
        schedules={schedules}
        updateBid={this.props.updateBid}
      />
    );
  }
}

const mapStateToProps = state => {
  const user = getUser(state);
  const userID = user && user.id;
  const jobOffers = selectAllJobOffersforUser(state, userID);
  const activeBids =
    jobOffers &&
    jobOffers.some(
      jobOffer => !!selectBidsByJobOfferID(state, jobOffer.id).length,
    );

  return {
    activeBids,
    jobOffers,
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      CustomerBidsContainer.TAXONOMY_CLASSIFICATIONS,
    ),
    user,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAllSchedulesForUser,
      searchJobOffers,
      updateBid,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerBidsContainer);
