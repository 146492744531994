// @flow
import React from 'react';

import TabbedComponents from 'components/global/layout/TabbedComponents';
import CarerJobOffersContainer from 'containers/jobOffers/CarerJobOffersContainer';

import type { CarerProfileType, HistoryType, LocationType } from 'shared/types';

type Props = {
  history: HistoryType,
  location: LocationType,
  profile: CarerProfileType,
  userID: string,
};

const CarerJobOffersOverview = ({
  history,
  location,
  profile,
  userID,
}: Props) => {
  return (
    <section>
      <TabbedComponents>
        <CarerJobOffersContainer
          name="New"
          title="New jobs"
          location={location}
          history={history}
          profile={profile}
          tab="new"
          userID={userID}
          default
        />
        <CarerJobOffersContainer
          name="Your jobs"
          title="Your jobs"
          location={location}
          history={history}
          profile={profile}
          tab="your-jobs"
          userID={userID}
        />
      </TabbedComponents>
    </section>
  );
};

export default CarerJobOffersOverview;
