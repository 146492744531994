// @flow
import React from 'react';
import { connect } from 'react-redux';
// $FlowFixMe
import { submit } from 'redux-form';

import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';

import Button from 'components/buttons/Button';

type Props = {
  dispatch: (() => void) => void,
  formName: string,
  loadingState: boolean,
  loadingType: string,
  label: string,
  storeLoadingState: boolean,
};

export const LoadingSubmitButton = ({
  dispatch,
  formName,
  label,
  storeLoadingState,
}: Props) => {
  return (
    <Button
      type="submit"
      loading={storeLoadingState}
      onClick={() => dispatch(submit(formName))}
    >
      {label}
    </Button>
  );
};

const mapStateToProps = (state, props) => ({
  storeLoadingState: loadingSelectorFactory(
    props.loadingState,
    props.loadingType,
  )(state),
});

export default connect(mapStateToProps)(LoadingSubmitButton);
