import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { searchJobOffers, updateJobOffer } from '_actions/jobOfferActions';

import { selectJobOffersforNeedByID } from 'shared/selectors/jobOfferSelectors';
import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';

import { ModuleHero } from 'components/global/layout/Module';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AdminNeedJobOffers from 'components/admin/needs/AdminNeedJobOffers';

class AdminNeedJobOffersContainer extends Component {
  componentDidMount() {
    this.props.searchJobOffers({ need_id: this.props.id });
  }

  render() {
    const {
      updateJobOffer,
      need,
      jobOffers,
      schedules,
      jobOffersLoadingState,
    } = this.props;

    if (jobOffersLoadingState) {
      return <LoadingPlaceholder />;
    }

    if (jobOffers && need && schedules && updateJobOffer) {
      return (
        <AdminNeedJobOffers
          updateJobOffer={updateJobOffer}
          need={need}
          jobOffers={jobOffers}
          schedules={schedules}
        />
      );
    }

    if (!jobOffers && !jobOffersLoadingState) {
      return <ModuleHero>No job offers</ModuleHero>;
    } else {
      return <LoadingPlaceholder />;
    }
  }
}

const mapStateToProps = (state, props) => ({
  jobOffers: selectJobOffersforNeedByID(state, props.id),
  jobOffersLoadingState: loadingSelectorFactory('jobOffers', 'jobOffers')(
    state,
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateJobOffer,
      searchJobOffers,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminNeedJobOffersContainer);
