// @flow
import React from 'react';

import Module from 'components/global/layout/Module';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import FormTemplate from 'components/forms/FormTemplate';
import { Container, Col } from 'components/grid';
import Button from 'components/buttons/Button';
import ButtonGroup from 'components/buttons/ButtonGroup';

import referenceSendFormFieldsConfig from 'components/references/AdminUserReferenceSendFormFields';


type Props = {
  initialValues: Object,
  onSubmit: () => void,
};

const AdminUserReferenceSendForm = ({
  initialValues = {},
  onSubmit,
}: Props) => {
  return (
    <Form
      name="AdminUserReferenceSendForm"
      shouldAsyncValidate={syncValidationPasses => {
        return syncValidationPasses;
      }}
      destroyOnUnmount
      fields={['email']}
      values={initialValues}
      onSubmit={onSubmit}
      selectFields={['email']}
      component={({ currentValues, initialValues, handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit} id="admin-reference-send-form">
          <Module>
            <FormTemplate
              currentValues={currentValues}
              fieldsConfig={referenceSendFormFieldsConfig}
            />
            <Container>
              <FormRow>
                <Col>
                  <ButtonGroup>
                    <Button disabled={pristine} type="submit">
                      Send Reference
                    </Button>
                  </ButtonGroup>
                </Col>
              </FormRow>
            </Container>
          </Module>
        </form>
      )}
    />
  );
};

export default AdminUserReferenceSendForm;