// empty schedule view
export const emptyScheduleTitle =
  'We’re working on creating a schedule for care.';
export const scheduleAdviceLink = {
  link:
    'https://supercarers.desk.com/customer/en/portal/topics/' +
    '1079865-identifying-care-needs/articles',
  text: 'Take a look at our advice',
};

// empty care team view
export const emptyCareTeamTitle =
  'We’ll suggest carers based on your requirements.';
export const careTeamFaqLink = {
  link:
    'https://supercarers.desk.com/customer/en/portal/topics/' +
    '1080145-arranging-care/articles',
  text: 'See our FAQs here',
};

// empty invoices title
export const emptyInvoicesTitle =
  'Invoice History';
export const emptyInvoicesSummary =
  'Previous customers of introductory care service can view historical invoices here.'

// completed care details title
export const completedCareDetailsTitle =
  'Thank you for completing your care details.';

// empty home page title - no need
export const emptyHomePageTitle =
  "Sorry, we can't find any existing care details!";

export const verificationMessages = {
  careTeam: {
    title: 'Your carers are waiting for you',
    summary:
      'Please go back to the homepage and verify your email address to view your carers',
  },
  careDetails: {
    title: 'View your needs and all the details regarding your care',
    summary:
      'Please go back to the homepage and verify your email address to access this information',
  },
  schedule: {
    title: 'Check the times and dates of your care with our care schedule',
    summary:
      'Please go back to the homepage and verify your email address to view your schedule',
  },
  invoice: {
    title: 'Manage your payments and invoices',
    summary:
      'Please go back to the homepage and verify your email address to access your payment details',
  },
};
