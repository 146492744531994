import { createSelector } from 'reselect';

import { getChargeByID } from 'shared/selectors/chargeSelectors';
import { getFranchiseByID } from 'shared/selectors/franchiseSelectors';
import { getInvoiceByID } from 'shared/selectors/invoiceSelectors';
import {
  getNeedByID,
  getNeedforTimesheetByID,
  selectNeedsForShifts,
} from 'shared/selectors/needSelectors';

export const getUsers = state => state.users;
export const selectUserByID = (state, id) => state.users[id];
export const getUserSearch = state => state.search.users;

export const getUserResults = createSelector(
  [getUsers, getUserSearch],
  (users, search) => {
    if (!search || !search.searchOrder) {
      return [];
    }
    const results = [];
    search.searchOrder.forEach(id => {
      if (users[id]) {
        results.push(users[id]);
      }
    });
    return results;
  },
);

export const getUserByChargeID = createSelector(
  [getUsers, getChargeByID],
  (users, charge) => {
    if (!users || !charge) {
      return null;
    }
    return users[charge.consumer_id];
  },
);

export const getUserForInvoiceByID = createSelector(
  [getUsers, getInvoiceByID],
  (users, invoice) => {
    if (!users || !invoice) {
      return null;
    }
    return users[invoice.user_id];
  },
);

export const getUserForTimesheetByID = createSelector(
  [getUsers, getNeedforTimesheetByID],
  (users, need) => {
    if (!users || !need) {
      return null;
    }
    return users[need.user_id];
  },
);

export const getUserForNeedByID = createSelector(
  [getUsers, getNeedByID],
  (users, need) => {
    if (!users || !need) {
      return null;
    }
    return users[need.user_id];
  },
);

export const selectCustomersForShifts = createSelector(
  [getUsers, selectNeedsForShifts],
  (users, needs) => {
    if (!users) {
      return null;
    }
    let usersForShifts = {};
    Object.values(needs).forEach(need => {
      if (need) {
        usersForShifts[need.user_id] = users[need.user_id];
      }
    });
    return usersForShifts;
  },
);

export const getUsersForFranchise = createSelector(
  [getUsers, getFranchiseByID],
  (users, franchise) => {
    if (!users || !franchise) {
      return null;
    }
    const franchiseUsers = [];
    franchise.users.forEach(userID => {
      const user = users[userID];
      if (user) {
        franchiseUsers.push(user);
      }
    });
    return franchiseUsers;
  },
);
