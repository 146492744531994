/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminNeedsFormContainer from 'containers/admin/needs/AdminNeedsFormContainer';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import {
  getNeedByIdWithUser,
  updateNeedAndCustomer,
  setNeedArchived,
} from '_actions/needActions';

import { getNeedByID } from 'shared/selectors/needSelectors';
import { getUserForNeedByID } from 'shared/selectors/userSelectors';

class AdminNeedsEditFormContainer extends React.Component {
  static propTypes = {
    getNeedByIdWithUser: PropTypes.func.isRequired,
    updateNeedAndCustomer: PropTypes.func.isRequired,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    startDateAccuracy: PropTypes.string,
    customer: PropTypes.shape(),
    need: PropTypes.shape(),
    match: PropTypes.shape({
      params: PropTypes.shape({
        needID: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    setNeedArchived: PropTypes.func.isRequired,
  };

  static defaultProps = {
    customer: null,
    need: null,
    startDate: null,
    endDate: null,
    startDateAccuracy: null,
  };

  archiveNeed = () => {
    const confirmation = confirm('Are you sure you want to archive this need?');
    if (confirmation) {
      this.props.setNeedArchived(this.props.match.params.needID, true);
    }
  };

  onUpdate = ({ customerData, needData }) => {
    const { customer, need } = this.props;

    this.props.updateNeedAndCustomer({
      customerData: { id: customer.id, ...customerData },
      needData: { id: need.id, ...needData },
    });
  };

  unarchiveNeed = () => {
    this.props.setNeedArchived(this.props.match.params.needID, false);
  };

  render() {
    const {
      customer,
      need,
      start_date_accuracy,
      schedule_description,
    } = this.props;

    if (!customer || !need) {
      return <LoadingPlaceholder />;
    }

    const initialValues = {
      account_manager: need.account_manager,
      franchise_id: need.franchise_id,
      lead_source: need.lead_source,
      partner_source: need.partner_source,
      contact_email: customer.email,
      contact_firstname: customer.firstname,
      contact_lastname: customer.lastname,
      contact_phone_number: customer.phone_number,
      age: need.age,
      admin_notes: need.admin_notes,
      carer_gender: need.carer_gender,
      firstname: need.firstname,
      postcode: need.postcode,
      story: need.story,
      pets: need.pets || 'none',
      start_date_accuracy,
      start_date_unknown: start_date_accuracy !== 'exact',
      schedule_description,
      schedule_start_date: need.start_date,
      schedule_end_date: need.end_date,
      needs_car: need.needs_car,
      needs_license: need.needs_license,
      smart_recruiter_id: need.smart_recruiter_id,
    };

    return (
      <AdminNeedsFormContainer
        archiveNeed={this.archiveNeed}
        initialValues={initialValues}
        onUpdate={this.onUpdate}
        needID={need.id}
        editMode
        unarchiveNeed={this.unarchiveNeed}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const need = getNeedByID(state, props.match.params.needID);
  return {
    customer: getUserForNeedByID(state, props.match.params.needID),
    need,
    schedule_description: need && need.schedule_description,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getNeedByIdWithUser,
      setNeedArchived,
      updateNeedAndCustomer,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminNeedsEditFormContainer);
