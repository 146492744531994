// @flow
import * as React from 'react';
import Link from 'react-router-redux-dom-link';

import FlexTable from 'components/global/layout/FlexTable';
import FlexTableRow from 'components/global/layout/FlexTableRow';
import FlexTableItem from 'components/global/layout/FlexTableItem';
import { DeleteIcon, EditIcon, PlusIcon } from 'components/global/icons';

import { renderTaxon } from 'components/helpers/taxonomyHelpers';
import type {
  CarerType,
  TaxonomiesClassificationType,
  TestimonialType,
} from 'shared/types';

type Props = {
  carer: CarerType,
  confirmDeletion: (testimonialID: string) => void,
  DeleteLink: React.ComponentType<*>,
  StyledButtonLink: React.ComponentType<*>,
  getDatesDisplay: (testimonial: TestimonialType) => string,
  taxonomiesByClassification: TaxonomiesClassificationType,
  testimonials: Array<TestimonialType>,
};

const AdminUserTestimonialsTable = ({
  carer,
  confirmDeletion,
  DeleteLink,
  StyledButtonLink,
  getDatesDisplay,
  taxonomiesByClassification,
  testimonials,
}: Props) => (
  <FlexTable moduleStyling>
    <FlexTableRow>
      <FlexTableItem xs={2}>Edit/Delete</FlexTableItem>
      <FlexTableItem xs={3}>Reviewer Name</FlexTableItem>
      <FlexTableItem xs={2}>Date</FlexTableItem>
      <FlexTableItem xs={0} md={3}>
        Relationship
      </FlexTableItem>
      <FlexTableItem xs={0} md={2}>
        Featured
      </FlexTableItem>
    </FlexTableRow>
    {testimonials.map((testimonial, index) => (
      <FlexTableRow key={`testimonial-${index}`}>
        <FlexTableItem xs={2}>
          <Link to={`/admin/users/${carer.id}/${testimonial.id}/`}>
            <EditIcon />
          </Link>
          <DeleteLink
            onClick={() => confirmDeletion(testimonial.id)}
            role="button"
          >
            <DeleteIcon width="35px" height="35px" />
          </DeleteLink>
        </FlexTableItem>
        <FlexTableItem xs={3}>{`${testimonial.firstname} ${
          testimonial.lastname ? testimonial.lastname : ''
        }`}</FlexTableItem>
        <FlexTableItem xs={2}>{getDatesDisplay(testimonial)}</FlexTableItem>
        <FlexTableItem xs={0} md={3}>
          {renderTaxon(
            taxonomiesByClassification.testimonial_relationship,
            testimonial.relationship,
          )}
        </FlexTableItem>
        <FlexTableItem xs={0} md={2}>
          {testimonial.featured ? 'Yes' : 'No'}
        </FlexTableItem>
      </FlexTableRow>
    ))}
    <FlexTableRow>
      <StyledButtonLink to={`/admin/users/${carer.id}/addTestimonial/`}>
        <PlusIcon />
        Testimonial
      </StyledButtonLink>
    </FlexTableRow>
  </FlexTable>
);

export default AdminUserTestimonialsTable;
