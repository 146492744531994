// @flow
import React, { Component } from 'react';

import Module, { ModuleHeader } from 'components/global/layout/Module';
import SummaryState from 'components/modules/SummaryState';
import SummaryTitle from 'components/modules/SummaryTitle';
import styled from 'styled-components';

const StyledRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
`;

export type SummariesType = Array<{
  title?: string,
  subtitle?: any,
  states: Array<{
    name: string,
    count: number,
    link?: string,
  }>,
}>;
type Props = {
  title?: string,
  removeModuleStyling?: boolean,
  summaries: SummariesType,
};

class Summary extends Component<Props> {
  render() {
    const { title, summaries, removeModuleStyling } = this.props;

    const summaryStates = (
      <div style={{ padding: '0 20px' }}>
        {summaries.length > 0 &&
          summaries.map((summary, index) => (
            <StyledRow key={summary.title || index}>
              {summary.title && (
                <SummaryTitle
                  title={summary.title}
                  subtitle={summary.subtitle}
                />
              )}
              {summary.states.map(state => (
                <SummaryState
                  key={state.name}
                  name={state.name}
                  count={state.count}
                  link={state.link}
                />
              ))}
            </StyledRow>
          ))}
      </div>
    );

    if (removeModuleStyling) {
      return summaryStates;
    }

    return (
      <Module>
        {title && <ModuleHeader>{title}</ModuleHeader>}
        {summaryStates}
      </Module>
    );
  }
}

export default Summary;
