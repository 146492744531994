// @flow
/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import FlexTable from 'components/global/layout/FlexTable';
import FlexTableRow from 'components/global/layout/FlexTableRow';
import ButtonLink from 'components/buttons/ButtonLink';
import { PlusIcon } from 'components/global/icons';

import AdminUserTestimonialsTable from 'components/users/AdminUserTestimonialsTable';

import { ModuleHero } from 'components/global/layout/Module';
import ConfirmationDialog from 'components/global/ConfirmationDialog';
import type {
  CarerType,
  TestimonialType,
  TaxonomiesClassificationType,
} from 'shared/types';

type Props = {
  carer: CarerType,
  testimonials: Array<TestimonialType>,
  deleteTestimonial: Function,
  taxonomiesByClassification: TaxonomiesClassificationType,
};

type State = {
  deletingTestimonial: ?string,
};

// $FlowFixMe - Needs a fix for styled components
const DeleteLink = styled.a`
  padding-left: 20px;
  position: relative;
`;

// $FlowFixMe - Needs a fix for styled components
const StyledButtonLink = styled(ButtonLink)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

class AdminUserTestimonials extends Component<Props, State> {
  state = {
    deletingTestimonial: null,
  };

  confirmDeletion = (testimonialID: string) => {
    this.setState({
      deletingTestimonial: testimonialID,
    });
  };

  deleteTestimonial = (testimonialID: string, userID: string) => {
    const confirmation = confirm(
      'Are you sure you want to delete this testimonial?',
    );
    if (confirmation) {
      this.props.deleteTestimonial(testimonialID, userID);
    }
  };

  getDatesDisplay = (testimonial: TestimonialType): string => {
    const startDate = testimonial.start_date
      ? moment(testimonial.start_date).format('MM/YYYY')
      : '';
    const endDate = testimonial.end_date
      ? moment(testimonial.end_date).format('MM/YYYY')
      : '';

    if (endDate) {
      return `${startDate} - ${endDate}`;
    }

    return `${startDate}`;
  };

  render() {
    const { carer, testimonials, taxonomiesByClassification } = this.props;

    if (testimonials.length === 0) {
      return (
        <FlexTable moduleStyling>
          <ModuleHero>There are no testimonials for this carer</ModuleHero>
          <FlexTableRow>
            <StyledButtonLink to={`/admin/users/${carer.id}/addTestimonial/`}>
              <PlusIcon />
              Testimonial
            </StyledButtonLink>
          </FlexTableRow>
        </FlexTable>
      );
    }
    return (
      <section>
        <AdminUserTestimonialsTable
          carer={carer}
          confirmDeletion={this.confirmDeletion}
          DeleteLink={DeleteLink}
          StyledButtonLink={StyledButtonLink}
          getDatesDisplay={this.getDatesDisplay}
          taxonomiesByClassification={taxonomiesByClassification}
          testimonials={testimonials}
        />
        {this.state.deletingTestimonial && (
          <ConfirmationDialog
            title="Delete this testimonial?"
            message="Caution. This cannot be undone."
            confirmation="Yes, delete it"
            onConfirm={() => {
              this.props.deleteTestimonial(
                this.state.deletingTestimonial,
                carer.id,
              );
              this.setState({ deletingTestimonial: null });
            }}
            onCancel={() => {
              this.setState({ deletingTestimonial: null });
            }}
            danger
          />
        )}
      </section>
    );
  }
}

export default AdminUserTestimonials;
