import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  color: ${props => props.theme.colors.darkBlue};
  font-family: ${props => props.theme.typography.fontStack};
  font-size: ${props => props.theme.typography.defaultFontSize};
  line-height: ${props => props.theme.typography.defaultLineHeight};
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: ${props => props.theme.zIndexes.modal};
  ${''} > div {
    background: white;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 ${props => props.theme.colors.shadowGrey};
    max-height: 80%;
    min-height: 20%;
    min-width: 600px;
    overflow: auto;
    padding: 20px 0;
    position: absolute;
    left: ${props => (props.small ? '30%' : '10%')};
    top: 10%;
    width: ${props => (props.small ? '40%' : '80%')};
    @media (max-width: 768px) {
      min-width: 0;
      left: 2%;
      top: 2%;
      width: 96%;
      max-height: 100vh;
      bottom: 2%;
    }
    h2 {
      display: block;
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 20px;
    }
    h3 {
      display: block;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 10px;
    }
    strong {
      font-weight: bold;
    }
    > button.close {
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      border: none;
      position: absolute;
      right: 5px;
      top: 5px;
      text-indent: -9999em;
      outline: 0;

      &:after {
        -webkit-appearance: none;
        appearance: none;
        content: '×';
        color: ${props => props.theme.colors.midGrey};
        cursor: pointer;
        display: block;
        font-size: 30px;
        line-height: 25px;
        position: absolute;
        right: 0;
        top: 0;
        height: 30px;
        text-align: center;
        text-indent: 0;
        width: 30px;
      }
    }
  }
`;

// Can't be stateless functional component because of react-portal
class Modal extends Component {
  componentDidMount() {
    window.document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    window.document.body.style.overflow = 'auto';
  }

  render() {
    const { children, closePortal, id, small } = this.props;

    return (
      <ModalWrapper small={small} id={id}>
        <div>
          {children.length
            ? children.map(child => React.cloneElement(child))
            : React.cloneElement(children)}
          <button className="close" onClick={closePortal}>
            Close
          </button>
        </div>
      </ModalWrapper>
    );
  }
}

Modal.propTypes = {
  closePortal: PropTypes.func,
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
};

Modal.defaultProps = {
  closePortal: () => {},
  small: false,
};

export default Modal;
