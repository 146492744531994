//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getJobOfferStatus,
  searchJobOffers,
  type SearchJobOffersParams,
} from '_actions/jobOfferActions';
import {
  getJobOfferResults,
  getJobOfferSearch,
} from 'shared/selectors/jobOfferSelectors';
import { getSummariesByCategory } from 'shared/selectors/summarySelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

import AdminJobOffersSearch from 'components/jobOffers/AdminJobOffersSearch';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import type {
  JobOfferType,
  LocationType,
  SearchType,
  TaxonomiesClassificationType,
} from 'shared/types';

type Props = {|
  getJobOfferStatus: () => void,
  jobOffers: Array<JobOfferType>,
  location: LocationType,
  search: SearchType,
  searchJobOffers: (SearchJobOffersParams, number | string) => Object,
  stateCounts: {
    [string]: number,
  },
  taxonomiesByClassification: TaxonomiesClassificationType,
|};

type State = {
  searchParams: Object,
};

export class AdminJobOffersContainer extends Component<Props, State> {
  static defaultProps = {
    jobOffers: null,
    search: null,
    stateCounts: null,
    taxonomiesByClassification: null,
  };

  state = {
    searchParams: {},
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'account_manager',
      'gender_preference',
      'pets',
      'shift_type',
    ];
  }

  componentDidMount() {
    this.search();
    this.props.getJobOfferStatus();
  }

  componentDidUpdate(prevProps: Props) {
    const page = this.props.location.query.page;
    if (page && page !== prevProps.location.query.page) {
      this.search();
    }
  }

  search = (searchParams?: SearchJobOffersParams) => {
    const page = searchParams ? 1 : this.props.location.query.page;

    this.props.searchJobOffers(searchParams || this.state.searchParams, page);

    if (searchParams) {
      this.setState({ searchParams });
    }
  };

  render() {
    const {
      search,
      jobOffers,
      location,
      stateCounts,
      taxonomiesByClassification,
    } = this.props;

    if (!search || !jobOffers || !stateCounts || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminJobOffersSearch
        jobOffers={jobOffers}
        location={location}
        search={search}
        searchJobOffers={this.search}
        stateCounts={stateCounts}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

const mapStateToProps = state => ({
  jobOffers: getJobOfferResults(state),
  search: getJobOfferSearch(state),
  stateCounts: getSummariesByCategory(state, { category: 'jobOffers' }),
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    AdminJobOffersContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchJobOffers,
      getJobOfferStatus,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminJobOffersContainer);
