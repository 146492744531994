import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-redux-dom-link';

import Definition from 'components/global/informationdisplay/Definition';
import { ModuleSubheader } from 'components/global/layout/Module';

export default class SummaryCustomerModule extends React.Component {
  static propTypes = {
    customer: PropTypes.shape().isRequired,
    need: PropTypes.shape().isRequired,
    taxonomiesByClassification: PropTypes.shape().isRequired,
  };

  render() {
    const { customer, need } = this.props;
    return (
      <>
        <ModuleSubheader noMargin>User</ModuleSubheader>
        {!!need.franchise_id &&
          <Definition label="Franchise">
            <Link to={`/admin/franchises/${need.franchise_id}/`}>
              {need.franchise_name}
            </Link>
          </Definition>
        }
        <Definition label="Contact">
          <Link to={`/admin/users/${customer.id}/`}>
            {customer.firstname} {customer.lastname}
          </Link>
        </Definition>
        <Definition label="Email">{customer.email}</Definition>
      </>
    );
  }
}
