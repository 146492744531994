import { toastr } from 'react-redux-toastr';
import * as Sentry from '@sentry/browser';

import AccountConstants from 'shared/constants/AccountConstants';
import PaymentConstants from 'shared/constants/PaymentConstants';
import { setLoadingState, updateObject } from 'shared/reducers/reducerHelpers';

const initalState = {
  user: null,
  impersonator: null,
  features: {},
  redirect_to: null,
  loading: {
    login: true,
    logout: false,
  },
};

function features(state = {}, action) {
  let updatedFeatures;

  switch (action.type) {
    case AccountConstants.TOGGLE_FEATURE:
      updatedFeatures = { ...state };
      updatedFeatures[action.name] = action.active;
      return updatedFeatures;

    default:
      return state;
  }
}

function login(state = {}, data) {
  if (data && JSON.stringify(data)) {
    try {
      localStorage.setItem('user', JSON.stringify(data));
    } catch (error) {
      toastr.error('Unable to persist user.');
    }
    return { ...state, user: data, impersonator: null };
  }

  Sentry.captureEvent({
    message: 'Attempted to set user as null',
    level: 'warning',
  });

  return state;
}

function accounts(state = initalState, action, returnDefaultState = true) {
  let data;
  let updatedUser;

  switch (action.type) {
    case AccountConstants.GET_LOCAL_STORAGE_LOGIN_DATA:
      return setLoadingState(state, 'login', true);

    case AccountConstants.GET_LOCAL_STORAGE_LOGIN_DATA_SUCCESS:
      return setLoadingState(state, 'login', false);

    case AccountConstants.GET_LOCAL_STORAGE_USER_DATA_SUCCESS:
    case AccountConstants.GET_LOCAL_STORAGE_STAFF_USER_DATA_SUCCESS:
      return {
        ...state,
        user: action.data,
      };

    case AccountConstants.GET_LOCAL_STORAGE_IMPERSONATOR_DATA_SUCCESS:
      return {
        ...state,
        impersonator: action.data,
      };

    case AccountConstants.SET_REDIRECT:
      return {
        ...state,
        redirect_to: action.data,
      };

    case AccountConstants.LOGIN:
      return { ...state };

    case AccountConstants.LOGIN_SUCCESS:
    case AccountConstants.STAFF_LOGIN_SUCCESS:
      return login(state, action.data);

    case AccountConstants.LOGOUT:
      return setLoadingState(state, 'logout', true);

    case AccountConstants.LOGOUT_FAILURE:
      return setLoadingState(state, 'logout', false);

    case AccountConstants.LOGOUT_SUCCESS:
      const newLogoutState = setLoadingState(state, 'logout', false);
      try {
        localStorage.removeItem('user');
        localStorage.removeItem('impersonator');
      } catch (error) {
        toastr.error('Unable to clear user details.');
      }
      return { ...newLogoutState, user: null, impersonator: null };

    case AccountConstants.ACCOUNT_CREATE:
      return state;

    case AccountConstants.ACCOUNT_CREATE_SUCCESS:
      return state;

    case AccountConstants.ACCOUNT_CREATE_FAILURE:
      return state;

    case AccountConstants.ACCOUNT_UPDATE:
      return state;

    case AccountConstants.ACCOUNT_UPDATE_SUCCESS:
      if (state.user.id === action.data.id) {
        data = action.data;
        // We don't want to overwrite user details like access token
        updatedUser = updateObject(state.user, data);
        localStorage.setItem('user', JSON.stringify(updatedUser));
        return { ...state, user: updatedUser };
      }
      return state;

    case AccountConstants.ACCOUNT_UPDATE_FAILURE:
      return state;

    case AccountConstants.ACCOUNT_VERIFY:
      return state;

    case AccountConstants.ACCOUNT_VERIFY_SUCCESS:
      return login(state, action.data);

    case AccountConstants.ACCOUNT_VERIFY_FAILURE:
      return state;

    case AccountConstants.ACCOUNT_CONFIRM_TERMS:
      return state;

    case AccountConstants.ACCOUNT_CONFIRM_TERMS_SUCCESS:
      data = action.data;
      updatedUser = updateObject(state.user, data);
      try {
        localStorage.setItem('user', JSON.stringify(updatedUser));
      } catch (error) {
        toastr.error('Unable to update persisted user.');
      }
      return { ...state, user: updatedUser };

    case AccountConstants.ACCOUNT_CONFIRM_TERMS_FAILURE:
      return state;

    case AccountConstants.ACCOUNT_RECOVER:
      return state;

    case AccountConstants.ACCOUNT_RECOVER_SUCCESS:
      return state;

    case AccountConstants.ACCOUNT_RECOVER_FAILURE:
      return state;

    case AccountConstants.CHANGE_PASSWORD:
      return state;

    case AccountConstants.CHANGE_PASSWORD_SUCCESS:
      return state;

    case AccountConstants.CHANGE_PASSWORD_FAILURE:
      return state;

    case AccountConstants.RESET_PASSWORD:
      return state;

    case AccountConstants.RESET_PASSWORD_SUCCESS:
      return state;

    case AccountConstants.RESET_PASSWORD_FAILURE:
      return state;

    case AccountConstants.IMPERSONATE:
      return setLoadingState(state, 'impersonate', true);

    case AccountConstants.IMPERSONATE_SUCCESS:
      if (state.impersonator) {
        return setLoadingState(state, 'impersonate', false);
      }
      try {
        localStorage.setItem('impersonator', JSON.stringify(state.user));
        localStorage.setItem('user', JSON.stringify(action.data));
      } catch (error) {
        toastr.error('Unable to persist impersonation.');
      }
      return {
        ...setLoadingState(state, 'impersonate', false),
        user: action.data,
        impersonator: state.user,
      };

    case AccountConstants.IMPERSONATE_FAILURE:
      return state;

    case AccountConstants.IMPERSONATE_STOP:
      try {
        localStorage.removeItem('impersonator');
        localStorage.setItem('user', JSON.stringify(state.impersonator));
      } catch (error) {
        toastr.error('Unable to clear impersonation.');
      }
      return { ...state, user: state.impersonator, impersonator: null };

    case AccountConstants.ACCOUNT_REFRESH_SUCCESS:
      data = action.data;
      updatedUser = updateObject(state.user, data);
      try {
        localStorage.setItem('user', JSON.stringify(updatedUser));
      } catch (error) {
        toastr.error('Unable to update persisted user.');
      }
      return { ...state, user: updatedUser };

    case AccountConstants.ACCOUNT_REFRESH_FAILURE:
      return state;

    case AccountConstants.TOGGLE_FEATURE:
      return { ...state, features: features(state.features, action) };

    case PaymentConstants.PAYMENTS_GET_SETUP_INTENT_SUCCESS:
      updatedUser = updateObject(state.user, {
        client_secret: action.data.client_secret,
      });
      return { ...state, user: updatedUser };

    case PaymentConstants.PAYMENTS_ADD_PAYMENT_SUCCESS:
      updatedUser = updateObject(state.user, {
        card_setup_complete: action.status,
      });
      return { ...state, user: updatedUser };

    default:
      return returnDefaultState ? state : false;
  }
}

export default accounts;
