import React from 'react';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import Logo from 'components/global/Logo';

const StyledLink = styled(Link)`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const StyledLinkLogo = ({ height = '80px' }) => (
  <StyledLink to="/">
    <Logo color={'#2E404F'} height={height} width={'180px'} />
  </StyledLink>
);

export default StyledLinkLogo;
