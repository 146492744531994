import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Paginator from 'components/global/Paginator';
import InvoiceResultRow from 'components/admin/InvoiceResultRow';

class InvoiceResults extends Component {
  render() {
    return (
      <div>
        <table className="invoices">
          <thead>
            <tr>
              <th>ID</th>
              <th>Start Date</th>
              <th>State</th>
              <th>State Age</th>
              <th>Customer</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {this.props.invoices.map(invoice => (
              <InvoiceResultRow
                key={invoice.id}
                id={invoice.id}
                invoice={invoice}
              />
            ))}
          </tbody>
        </table>
        <Paginator
          currentPage={this.props.pagination.page}
          totalPages={this.props.pagination.pages}
          location={this.props.location}
        />
      </div>
    );
    /*return (
      <div>
        <table className="invoices">
          <thead>
            <tr>
              <th>ID</th>
              <th>Start Date</th>
              <th>State</th>
              <th>State Age</th>
              <th>Customer</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {this.props.invoices.map(invoice =>
              <InvoiceResultRow key={invoice.id} id={invoice.id} invoice={invoice} />,
            )}
          </tbody>
        </table>
        <Paginator
          currentPage={this.props.pagination.page}
          totalPages={this.props.pagination.pages}
          location={this.props.location}
        />
      </div>
    );*/
  }
}

InvoiceResults.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pagination: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export default InvoiceResults;
