// import PropTypes from 'prop-types';
import React, { Component } from 'react';

function TestError(message) {
  this.name = 'TestError';
  this.message = message || '';
}
TestError.prototype = Error.prototype;

class ErrorTest extends Component {
  state = {};

  render() {
    if (this.state.shouldError) {
      throw new TestError('This error was manually thrown by an Admin user');
    }
    return (
      <div>
        <button
          onClick={() => {
            this.setState({ shouldError: true });
          }}
        >
          Throw React Error
        </button>
      </div>
    );
  }
}

export default ErrorTest;
