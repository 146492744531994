import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AdminFranchisesOverview from 'components/admin/franchise/AdminFranchisesOverview';

class AdminFranchisesOverviewContainer extends Component {
  render() {
    return <AdminFranchisesOverview location={this.props.location} />;
  }
}

AdminFranchisesOverviewContainer.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default AdminFranchisesOverviewContainer;
