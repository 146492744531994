// @flow
import React from 'react';
import { connect } from 'react-redux';

import JobOfferRow from 'components/jobOffers/JobOfferRow';

import { selectScheduleWithShiftsByScheduleID } from 'shared/selectors/scheduleShiftsSelectors';

import type { BidType, JobOfferType, ScheduleType } from 'shared/types';

type Props = {
  bid: BidType,
  jobOffer: JobOfferType,
  schedule: ScheduleType,
  newJob: boolean,
};

const JobOfferRowContainer = ({ bid, jobOffer, schedule, newJob }: Props) => (
  <JobOfferRow
    bid={bid}
    jobOffer={jobOffer}
    newJob={newJob}
    schedule={schedule}
  />
);

const mapStateToProps = (state, props) => ({
  schedule: selectScheduleWithShiftsByScheduleID(
    state,
    props.jobOffer.schedule_id,
  ),
});

export default connect(mapStateToProps)(JobOfferRowContainer);
