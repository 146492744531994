import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Col, Row } from 'components/grid';

import styled from 'styled-components';
import List from 'components/global/informationdisplay/List';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import Button from 'components/buttons/Button';
import TimesheetShift from 'components/timesheets/TimesheetShift';

const StyledDuration = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

class TimesheetDay extends Component {
  static propTypes = {
    date: PropTypes.string.isRequired,
    shifts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    updateShiftType: PropTypes.func.isRequired,
    updateShiftDuration: PropTypes.func.isRequired,
    updateShiftExceptionalTotal: PropTypes.func.isRequired,
    updateShiftExceptionalReason: PropTypes.func.isRequired,
    removeShift: PropTypes.func.isRequired,
    addShift: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.object.isRequired,
  };

  render() {
    const includesLiveIn = this.props.shifts.some(shift =>
      ['half_livein', 'livein'].includes(shift.shift_type),
    );

    const lastShift = this.props.shifts[this.props.shifts.length - 1];
    const lastShiftAfterMidnight =
      lastShift && !lastShift.end.isSame(this.props.date, 'day');

    const duration = this.props.shifts.reduce(
      (accumulator, shift) => accumulator + shift.duration,
      0,
    );

    return (
      <Module>
        <ModuleHeader>
          {moment(this.props.date).format('dddd, MMMM Do')}
        </ModuleHeader>
        <Container>
          {this.props.shifts && (
            <List>
              {this.props.shifts.map((shift, index) => (
                <TimesheetShift
                  key={index}
                  date={this.props.date}
                  index={index}
                  shift={shift}
                  previousShift={this.props.shifts[index - 1]}
                  updateShiftType={this.props.updateShiftType}
                  updateShiftDuration={this.props.updateShiftDuration}
                  updateShiftExceptionalTotal={
                    this.props.updateShiftExceptionalTotal
                  }
                  updateShiftExceptionalReason={
                    this.props.updateShiftExceptionalReason
                  }
                  removeShift={this.props.removeShift}
                  taxonomiesByClassification={
                    this.props.taxonomiesByClassification
                  }
                />
              ))}
            </List>
          )}
          {!includesLiveIn && (
            <Row>
              <Col>
                <StyledDuration>
                  This means you worked{' '}
                  <strong>
                    {Math.floor(duration / 60)} hours{' '}
                    {duration % 60 > 0 && ` and ${duration % 60} minutes`}
                  </strong>{' '}
                  for this period.
                </StyledDuration>
              </Col>
            </Row>
          )}
          {!includesLiveIn && !lastShiftAfterMidnight && (
            <Row>
              <Col>
                <Button onClick={this.props.addShift}>Add a Shift</Button>
              </Col>
            </Row>
          )}
        </Container>
      </Module>
    );
  }
}

export default TimesheetDay;
