import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { updateJobOffer } from '_actions/jobOfferActions';
import { getRateLimits } from '_actions/rateActions';
import { selectRateLimits } from 'shared/selectors/ratesSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import JobSummary from 'components/admin/needs/jobs/JobSummary';
import JobSummaryFormEdit from 'components/admin/needs/jobs/JobSummaryFormEdit';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

const TAXONOMY_CLASSIFICATIONS = [
  'bid_carer_rejection',
  'care_type',
  'condition',
  'condition_experience',
  'equipment',
  'gender_preference',
  'hobbies',
  'languages',
  'likes_animals',
  'pets',
  'qualifications',
  'services',
  'shift_type',
  'symptoms',
  'smoker_preference',
  'training_courses',
];

const JobSummaryContainer = ({
  jobOffer,
  bid,
  getRateLimits,
  schedule,
  needID,
  taxonomiesByClassification,
  updateJobOffer,
  preview,
  closePortal,
  rateLimits,
}) => {
  const [editing, toggleEditingState] = useState(false);

  useEffect(() => {
    if (!rateLimits) {
      getRateLimits();
    }
  }, []);

  const updateJobOfferAndClose = (id, payload) => {
    updateJobOffer(id, payload);
    closePortal && closePortal();
  };

  if (!rateLimits) {
    return <LoadingPlaceholder />;
  }

  return !editing ? (
    <JobSummary
      bid={bid}
      closePortal={closePortal}
      jobOffer={jobOffer}
      needID={needID}
      preview={preview}
      schedule={schedule}
      taxonomiesByClassification={taxonomiesByClassification}
      toggleEditingState={toggleEditingState}
      updateJobOffer={updateJobOfferAndClose}
    />
  ) : (
    <JobSummaryFormEdit
      bid={bid}
      editing={editing}
      jobOffer={jobOffer}
      preview={preview}
      rateLimits={rateLimits}
      schedule={schedule}
      taxonomiesByClassification={taxonomiesByClassification}
      toggleEditingState={toggleEditingState}
      updateJobOffer={updateJobOfferAndClose}
    />
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateJobOffer,
      getRateLimits,
    },
    dispatch,
  );

const mapStateToProps = (state, props) => ({
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    TAXONOMY_CLASSIFICATIONS,
  ),
  needID: props.match.params.needID,
  rateLimits: selectRateLimits(state),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(JobSummaryContainer),
);
