import React, { Component } from 'react';
import { connect } from 'react-redux';
import PrivacyPolicy from 'components/site/PrivacyPolicy';

class PrivacyPolicyContainer extends Component {
  render() {
    return (
      <PrivacyPolicy />
    );
  }
}

export default connect()(PrivacyPolicyContainer);
