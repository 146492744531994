import WorkHistoryConstants from 'shared/constants/WorkHistoryConstants';

const initalState = {};

function workHistory(state = initalState, action) {
  switch (action.type) {
    case WorkHistoryConstants.WORK_HISTORY_GET_BY_ID_SUCCESS: {
      const wh = action.data;
      const newState = { ...state };
      const userID = action.data.user_id;
      if (newState[userID]) {
        // Remove any old instances of the work history
        newState[userID].splice(
          newState[userID].findIndex(existingWH => existingWH.id === wh.id),
          1,
        );
        newState[wh.user_id] = [...newState[wh.user_id]];
        newState[wh.user_id].push(wh);
      } else {
        newState[wh.user_id] = [wh];
      }
      return newState;
    }

    case WorkHistoryConstants.WORK_HISTORY_SEARCH_SUCCESS:
    case WorkHistoryConstants.WORK_HISTORY_GET_FOR_CARER_BY_ID_SUCCESS: {
      const newState = {};
      if (action.carer_id) {
        newState[action.carer_id] = [];
      }
      action.data.results.forEach(workHistoryInstance => {
        const userId = workHistoryInstance.user_id;
        if (!newState[userId]) {
          newState[userId] = [];
        }
        newState[userId].push(workHistoryInstance);
      });
      return { ...state, ...newState };
    }

    case WorkHistoryConstants.WORK_HISTORY_UPDATE_SUCCESS: {
      const newState = { ...state };
      const wh = action.data;
      const userID = action.data.user_id;
      if (newState[userID]) {
        // Remove any old instances of the work history
        newState[userID].splice(
          newState[userID].findIndex(existingWH => existingWH.id === wh.id),
          1,
        );
        newState[wh.user_id] = [...newState[wh.user_id]];
        newState[wh.user_id].push(wh);
      } else {
        newState[wh.user_id] = [wh];
      }
      return newState;
    }

    case WorkHistoryConstants.WORK_HISTORY_DELETE_SUCCESS: {
      const newState = { ...state };
      const userID = action.payload.userID;
      newState[userID] = (newState[userID] || []).filter(
        workHistoryInstance => workHistoryInstance.id !== action.payload.workHistoryInstanceID,
      );
      return newState;
    }

    default:
      return state;
  }
}

export default workHistory;
