import styled from 'styled-components';

const StyledInput = styled.input`
  background-color: ${props => props.theme.colors.lightestGrey};
  border: 1px solid;
  border-color: ${props => props.theme.colors.uiGrey};
  border-radius: 6px;
  color: ${props => props.theme.colors.formText};
  font-family: ${props => props.theme.typography.fontStack};
  font-size: 18px;
  padding: 15px 10px;
  text-align: ${({ align }) => align};
  min-width: 120px;
  width: ${props => (props.help ? '60%' : '100%')};

  ${props => props.prefix && 'padding-left: 20px;'} ${props =>
    props.suffix && 'padding-right: 20px;'} &.error {
    border-color: ${props => props.theme.colors.warningRed};
  }

  &:focus {
    background-color: white;
  }

  &[disabled],
  &[readonly] {
    background-color: ${props => props.theme.colors.uiGrey};
  }
`;

export default StyledInput;
