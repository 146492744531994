import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import CustomerApprovedMembers from 'components/careNeeds/CustomerApprovedMembers';

function teamSummarys({
  needs,
  teams,
  taxonomiesByClassification,
  carers,
  schedules,
  updateCareTeamMemberState,
}) {
  const allTeams = [];
  needs.forEach(need => {
    let schedulesForNeed;
    const needTeam = teams.find(team => team.need_id === need.id);
    if (needTeam) {
      const approvedCarers = needTeam.members.approved.map(
        member => carers[member.carer_id],
      );
      if (schedules) {
        schedulesForNeed = schedules.filter(
          schedule => schedule.care_need_id === need.id,
        );
      }
      allTeams.push(
        <CustomerApprovedMembers
          key={needTeam.id}
          team={needTeam}
          schedules={schedulesForNeed}
          carers={approvedCarers}
          taxonomiesByClassification={taxonomiesByClassification}
          reasons={taxonomiesByClassification.care_team_member_customer_fired}
          fireCareTeamMember={updateCareTeamMemberState}
        />,
      );
    }
  });
  return allTeams;
}

class CustomerTeams extends Component {
  render() {
    return teamSummarys(this.props);
  }
}

export default CustomerTeams;
