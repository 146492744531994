import RatesConstants from 'shared/constants/RatesConstants';
import { updateObject } from 'shared/reducers/reducerHelpers';

const initalState = {
  defaultRates: null,
  rateLimits: null,
  carerRates: {},
  careNeedRates: {},
  FIELDS: {
    settings: ['holiday_multiplier', 'exceptional_commission'],
    shiftTypes: [
      'short_duty',
      'long_duty',
      'working_night',
      'sleeping_night',
      'livein',
    ],
  },
};

function rates(state = initalState, action, returnDefaultState = true) {
  let updatedRates;
  switch (action.type) {
    case RatesConstants.RATES_GET_DEFAULT:
      return state;

    case RatesConstants.RATES_GET_DEFAULT_SUCCESS:
      return { ...state, defaultRates: action.data };

    case RatesConstants.RATES_GET_DEFAULT_FAILURE:
      return state;

    case RatesConstants.RATES_SET_DEFAULT:
      return state;

    case RatesConstants.RATES_SET_DEFAULT_SUCCESS:
      return { ...state, defaultRates: action.data };

    case RatesConstants.RATES_SET_DEFAULT_FAILURE:
      return state;

    case RatesConstants.RATES_GET_LIMITS_SUCCESS:
      return {
        ...state,
        rateLimits: action.data.results.reduce((accumulator, limit) => {
          accumulator[limit.name] = limit;
          return accumulator;
        }, {}),
      };

    case RatesConstants.RATES_GET_CARER:
      return state;

    case RatesConstants.RATES_GET_CARER_SUCCESS:
      updatedRates = updateObject(state.carerRates, {
        [action.id]: action.data,
      });
      return { ...state, carerRates: updatedRates };

    case RatesConstants.RATES_GET_CARER_FAILURE:
      return state;

    case RatesConstants.RATES_SET_CARER:
      return state;

    case RatesConstants.RATES_SET_CARER_SUCCESS:
      updatedRates = updateObject(state.carerRates, {
        [action.id]: action.data,
      });
      return { ...state, carerRates: updatedRates };

    case RatesConstants.RATES_SET_CARER_FAILURE:
      return state;

    case RatesConstants.RATES_GET_CARE_NEED:
    case RatesConstants.RATES_SET_CARE_NEED:
    case RatesConstants.RATES_GET_CARE_NEED_CARER:
    case RatesConstants.RATES_SET_CARE_NEED_CARER:
    case RatesConstants.RATES_GET_CARE_NEED_CARER_FAILURE:
    case RatesConstants.RATES_SET_CARE_NEED_CARER_FAILURE:
      return state;

    case RatesConstants.RATES_GET_CARE_NEED_SUCCESS:
    case RatesConstants.RATES_SET_CARE_NEED_SUCCESS:
    case RatesConstants.RATES_RESET_CARE_NEED_SUCCESS:
      updatedRates = updateObject(state.careNeedRates, {
        [action.otherProps.needId]: action.data,
      });
      return { ...state, careNeedRates: updatedRates };

    case RatesConstants.RATES_GET_CARE_NEED_CARER_SUCCESS:
    case RatesConstants.RATES_SET_CARE_NEED_CARER_SUCCESS:
      updatedRates = updateObject(state.careNeedRates, {
        [action.id]: action.data,
      });
      return { ...state, careNeedRates: updatedRates };

    default:
      return returnDefaultState ? state : false;
  }
}

export default rates;
