import PropTypes from 'prop-types';
import React from 'react';

const HomeIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 26"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="home-icon"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path d="M21.1248669,24.3752708 L16.2498669,24.3752708 C15.9508669,24.3752708 15.7082002,24.1326042 15.7082002,23.8336042 L15.7082002,16.7919375 L10.2915336,16.7919375 L10.2915336,23.8336042 C10.2915336,24.1326042 10.0488669,24.3752708 9.74986691,24.3752708 L4.87486691,24.3752708 C3.97895024,24.3752708 3.24986691,23.6461875 3.24986691,22.7502708 L3.24986691,13.0002708 C3.24986691,12.7012708 3.49253358,12.4586042 3.79153358,12.4586042 C4.09053358,12.4586042 4.33320024,12.7012708 4.33320024,13.0002708 L4.33320024,22.7502708 C4.33320024,23.0487292 4.57640858,23.2919375 4.87486691,23.2919375 L9.20820024,23.2919375 L9.20820024,16.2502708 C9.20820024,15.9512708 9.45086691,15.7086042 9.74986691,15.7086042 L16.2498669,15.7086042 C16.5488669,15.7086042 16.7915336,15.9512708 16.7915336,16.2502708 L16.7915336,23.2919375 L21.1248669,23.2919375 C21.4233252,23.2919375 21.6665336,23.0487292 21.6665336,22.7502708 L21.6665336,13.0002708 C21.6665336,12.7012708 21.9092002,12.4586042 22.2082002,12.4586042 C22.5072002,12.4586042 22.7498669,12.7012708 22.7498669,13.0002708 L22.7498669,22.7502708 C22.7498669,23.6461875 22.0207836,24.3752708 21.1248669,24.3752708" />
        <path d="M25.4582002,13.0002708 C25.3233252,13.0002708 25.1884502,12.9504375 25.0839086,12.8502292 L12.9998669,1.29160417 L0.915825243,12.8502292 C0.699700243,13.0560625 0.356825243,13.0490208 0.149908576,12.8328958 C-0.0564664236,12.6167708 -0.0488830903,12.2738958 0.16724191,12.0669792 L12.6255752,0.1503125 C12.8346586,-0.0501041667 13.1650752,-0.0501041667 13.3741586,0.1503125 L25.8324919,12.0669792 C26.0486169,12.2738958 26.0562002,12.6167708 25.8498252,12.8328958 C25.7431169,12.9439375 25.6006586,13.0002708 25.4582002,13.0002708" />
        <path d="M21.1248669,5.4169375 C20.8258669,5.4169375 20.5832002,5.17427083 20.5832002,4.87527083 L20.5832002,1.62527083 L17.3332002,1.62527083 C17.0342002,1.62527083 16.7915336,1.38260417 16.7915336,1.08360417 C16.7915336,0.784604167 17.0342002,0.5419375 17.3332002,0.5419375 L21.1248669,0.5419375 C21.4238669,0.5419375 21.6665336,0.784604167 21.6665336,1.08360417 L21.6665336,4.87527083 C21.6665336,5.17427083 21.4238669,5.4169375 21.1248669,5.4169375" />
      </g>
    </svg>
  </div>
);

HomeIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

HomeIcon.defaultProps = {
  className: '',
  height: '26px',
  width: '26px',
};

export default HomeIcon;
