import keyMirror from 'keymirror';

const ChargeConstants = keyMirror({
  CHARGE_CREATE: null,
  CHARGE_CREATE_SUCCESS: null,
  CHARGE_CREATE_FAILURE: null,

  CHARGE_CREATE_NOTIFY_EMAIL: null,
  CHARGE_CREATE_NOTIFY_EMAIL_SUCCESS: null,
  CHARGE_CREATE_NOTIFY_EMAIL_FAILURE: null,

  CHARGE_GET_BY_ID: null,
  CHARGE_GET_BY_ID_SUCCESS: null,
  CHARGE_GET_BY_ID_FAILURE: null,

  CHARGE_UPDATE: null,
  CHARGE_UPDATE_SUCCESS: null,
  CHARGE_UPDATE_FAILURE: null,

  CHARGE_PAY: null,
  CHARGE_PAY_SUCCESS: null,
  CHARGE_PAY_FAILURE: null,

  CHARGE_SEARCH: null,
  CHARGE_SEARCH_SUCCESS: null,
  CHARGE_SEARCH_FAILURE: null,

  CHARGE_SEARCH_WITH_USERS: null,
});

export default ChargeConstants;
