import ApiConstants from 'shared/constants/ApiConstants';
import InvoiceConstants from 'shared/constants/InvoiceConstants';

export function getInvoiceSummary(isoweek, cumulative) {
  return {
    endpoint: `/invoice/summary/${isoweek}/`,
    method: 'get',
    onError: { type: InvoiceConstants.INVOICE_SUMMARY_FAILURE },
    onStart: { type: InvoiceConstants.INVOICE_SUMMARY },
    onSuccess: { type: InvoiceConstants.INVOICE_SUMMARY_SUCCESS, isoweek },
    payload: cumulative ? { cumulative } : {},
    type: ApiConstants.API_REQUEST,
  };
}

export function payInvoice(invoiceId) {
  return {
    endpoint: `/invoice/${invoiceId}/pay/`,
    method: 'post',
    onError: {
      type: InvoiceConstants.INVOICE_PAY_FAILURE,
    },
    onStart: { type: InvoiceConstants.INVOICE_PAY },
    onSuccess: { type: InvoiceConstants.INVOICE_PAY_SUCCESS },
    payload: {},
    type: ApiConstants.API_REQUEST,
  };
}

export function setInvoiceVoid(invoiceId, setVoid) {
  return {
    endpoint: `/invoice/${invoiceId}/`,
    method: 'put',
    onError: { type: InvoiceConstants.INVOICE_VOID_FAILURE },
    onStart: { type: InvoiceConstants.INVOICE_VOID },
    onSuccess: { type: InvoiceConstants.INVOICE_VOID_SUCCESS },
    payload: { void: setVoid },
    type: ApiConstants.API_REQUEST,
  };
}

export function searchInvoices(params, page) {
  const query = Object.assign(
    { page: page || 1, order_by_parameter: 'iso_week' },
    params,
  );
  return {
    endpoint: '/invoice/find/',
    method: 'get',
    onError: { type: InvoiceConstants.INVOICE_SEARCH_FAILURE },
    onStart: { type: InvoiceConstants.INVOICE_SEARCH },
    onSuccess: { type: InvoiceConstants.INVOICE_SEARCH_SUCCESS },
    payload: query,
    type: ApiConstants.API_REQUEST,
  };
}

export function populateInvoiceWithId(invoiceId) {
  return {
    type: InvoiceConstants.POPULATE_INVOICE,
    invoiceId,
  };
}

export function getInvoiceWithId(invoiceId) {
  return {
    endpoint: `/invoice/${invoiceId}/`,
    method: 'get',
    onError: { type: InvoiceConstants.INVOICE_GET_BY_ID_FAILURE },
    onStart: { type: InvoiceConstants.INVOICE_GET_BY_ID },
    onSuccess: { type: InvoiceConstants.INVOICE_GET_BY_ID_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}
