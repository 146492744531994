import React, { Component } from 'react';
import { Container, Col, Row } from 'components/grid';

import Module, { ModuleHeader } from 'components/global/layout/Module';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
    h3 {
        font-weight: bold;
        font-size: 20px;
        margin: 24px 0 10px;
    }
    h4 {
        font-size: 18px;
        font-weight: bold;
        margin: 22px 0 10px;
    }
    h5 {
        font-weight: bold;
        margin: 20px 0 10px;
    }
    
    ul {
        list-style-type: disc;
        margin-bottom: 16px;
    }
    
    li {
        margin: 6px 0 6px 24px;
    }
    
    ul ul {
        list-style-type: circle;
    }
    
    li li {
        margin-left: 48px;
    }
    
    p {
        margin: 10px 0;
    }
`;

class TermsAndConditions extends Component {

  render() {
    return (
      <section>
        <Module>
          <ModuleHeader>Terms and Conditions</ModuleHeader>
          <StyledContainer>
            <Row>
              <Col>
                <h3>WEBSITE AND PLATFORM TERMS OF USE</h3>

                <p>Home Instead Limited (“us,” “we,” “our,” or “the Company”) is a company registered in England and
                  Wales under company number 12580451, with its registered office at Unit 2 Walnut Tree Business Centre,
                  Walnut Tree Farm, Northwich Road, Lower Stretton, Warrington, Cheshire, England, WA4 4PG and with VAT
                  number 348398747.</p>

                <p>We operate the website at, https://supercarers.com (“Website”) under our trading name SuperCarers
                  (“SuperCarers”). We also provide an online platform for care professionals registered before June 2023
                  and franchisees accessible online via liveincareapp.homeinstead.co.uk (“Platform”).</p>

                <p>These Terms and Conditions (“Terms”) govern your use of the Website and the Platform (“Services”).
                  Please read these Terms carefully before using the Services.</p>

                <p>Your access to and use of the Website and or Platform is conditional on your acceptance and
                  compliance with these Terms. These Terms apply to all visitors, users, and others who access or use
                  the Website and or the Platform. By accessing the Website and or the Platform, you agree to be bound
                  by these Terms. If you disagree with any part of these Terms, please do not use the Website and or the
                  Platform.</p>

                <h4>THERE ARE OTHER TERMS THAT MAY APPLY TO YOU, SUCH AS:</h4>

                <h5>Appendix 1 for Vitality Members</h5>
                <h5>Appendix 2 for Care Professionals</h5>
                <h5>Appendix 3 for Franchisees</h5>
                <p>When certain words and phrases are used in these Terms, they have specific meanings (these are known
                  as “defined terms”). You can identify these defined terms because they start with capital letters
                  (even if they are not at the start of a sentence). Where a defined term is used, it has the meaning
                  given to it in the section of these Terms where it was defined (you can find these meanings by looking
                  at the sentence where the defined term is included in brackets and speech marks).</p>

                <p>Please note:</p>

                <ul>
                  <li>if you are acting for purposes relating to your trade, business, craft or profession, then you are
                    acting in the course of a business (“Business User”).
                  </li>
                  <li>If you are acting on behalf of your employer or another business when you access and use the
                    Website and or the Platform, you represent and warrant that:
                  </li>
                  <li>you have full legal authority to bind your employer or that business; and</li>
                  <li>you agree to these Terms and the attached Appendix (if applicable) on behalf of the business that
                    you represent.
                  </li>
                  <li>Please note we only use your personal information in accordance with our privacy policy (available
                    here).
                  </li>
                  <li>We amend these terms from time to time. Every time you wish to use our site, please check these
                    terms to ensure you understand the terms that apply at that time.
                  </li>
                  <li>We recommend that you print a copy of these Terms for your future reference.</li>
                  <li>We are a provider of technology platform services only. We do not provide care nor are we
                    responsible for managing, directing or overseeing the provision of care provided by care
                    professionals nor the conduct of the care professionals or the franchisees (including, but not
                    limited to, pay disputes or care professional conduct).
                  </li>
                  <li>We provide this Service free of charge.</li>
                </ul>

                <h3>1. The Website</h3>
                <p>We do not guarantee that the Website, or any of its content, will always be available or there will
                  be uninterrupted access. Access to the Website is permitted on a temporary basis. We may suspend,
                  withdraw, discontinue or change all or any part of the Website without notice and without any
                  liability to you. We will not be liable to you if for any reason the Website is unavailable at any
                  time or for any period. We may update the Website and/or change the content on it at any time, without
                  any notice.</p>
                <p>You are responsible for making all arrangements necessary for you to have access to the Website. You
                  are also responsible for ensuring that all persons who access the Website through your internet
                  connection are aware of these Terms and that they comply with them.</p>

                <p>We may periodically upload content onto the Website. This might include articles with advice,
                  guidance and any information we believe will be of use or interest to you. The Website and the content
                  on it are provided for general information purposes only and we are not responsible for its accuracy.
                  It is meant as a guide only and not advice on which you should rely.</p>

                <p>The Website and any promotional materials provided by us might include links to websites owned,
                  operated, controlled and/or provided by third parties (“Third-Party Content”). Your use of any
                  Third-Party Content may be subject to the relevant Third Party’s terms and conditions and it is your
                  responsibility to make yourself aware of those terms and conditions. We will not necessarily see or be
                  aware of enquiries that you submit to any third parties who provide Third Party Content.</p>

                <p>We have no control over or proprietary interest in any Third-Party Content. We do not give any
                  promises about the quality, security, accuracy or any other aspect of any Third-Party Content.</p>

                <h3>2. General</h3>
                <p>2.1. You agree not to:</p>

                <p>2.1.1. use the Website and or the Platform in any way that breaches these Terms or any applicable
                  local, national or international law or regulation;</p>

                <p>2.1.2. copy, or otherwise reproduce or re-sell any part of the Website and or the Platform unless
                  expressly permitted to do so in these Terms;</p>

                <p>2.1.3. do any act or thing that might damage, disrupt or otherwise interfere with the operation of
                  the Website and or the Platform or any equipment, network or software used in operating the Website
                  and or the Platform;</p>

                <p>2.1.4. damage or bring our reputation in dispute;</p>

                <p>2.1.5. use the Website and or the Platform for the purposes of harming or attempting to harm minors
                  in any way or to solicit personal information from anyone under 18 years of age or solicit passwords
                  or personally identifying information for commercial or unlawful purposes;</p>

                <p>2.1.6. use the Website and or the Platform for the purpose of harming or attempting to harm
                  vulnerable adults or any other user in any way or to solicit personal information from them or solicit
                  passwords or personally identifying information for commercial or unlawful purposes;</p>

                <p>2.1.7. use the Website and or the Platform to harvest or collect email addresses or other contact
                  information of other users by electronic means for the purposes of sending unsolicited or unauthorised
                  advertising or promotional material or any other form of similar solicitation (“Spam”);</p>

                <p>2.1.8. transmit, or procure the sending of Spam; or</p>

                <p>2.1.9. use automated scripts or any other automated systems or software to extract or collect data
                  from or otherwise interact with the Website and or the Platform. Doing so for commercial purposes,
                  commonly known as ‘scraping’ or ‘screen scraping’ is strictly prohibited.</p>

                <h3>3. User Generated Content</h3>
                <p>3.1. If it is the case that you supply/upload any content to the Website and or the Platform,
                  including but not limited to pictures, text, sound recordings or diagrams (“User Generated Content”)
                  must comply with the following rules:</p>

                <p>3.1.1. it must not be obscene, abusive, threatening, offensive or racist and it must not promote or
                  propose hatred or physical harm against anyone;</p>

                <p>3.1.2. it must not harass or bully another person;</p>

                <p>3.1.3. It must not cause annoyance, inconvenience or needless anxiety, or be likely to harass, upset,
                  embarrass, alarm or annoy any other person;</p>

                <p>3.1.4. it must not advocate, promote or assist any unlawful act such as (by way of example only)
                  copyright infringement or computer misuse;</p>

                <p>3.1.5. it must be true and honest so far as you know;</p>

                <p>3.1.6. it must not be defamatory of anyone;</p>

                <p>3.1.7. it must not promote sexually explicit material, or discrimination based on race, sex,
                  religion, nationality, disability, sexual orientation, age or other characteristic;</p>

                <p>3.1.8. it must not use the material or content or infringe the rights or privacy of anyone else; for
                  example you should not use images of well-known characters, footage or music (unless it is your
                  own);</p>

                <p>3.1.9. you will not impersonate any person, misrepresent your identity or affiliation with any
                  person, or give the impression that the User Generated Content emanates from us (if this is not the
                  case);</p>

                <p>3.1.10. it must not contain someone else’s personal details or confidential information relating to
                  other people without their express consent; and</p>

                <p>3.1.11. it must not promote or condone terrorism, violence or illegal behaviour.</p>

                <p>3.2. We reserve the right to refuse to accept or refuse or cease to use any User Generated Content
                  supplied by any person that we think contravenes these rules.</p>

                <p>3.3. In addition, we may from time to time provide interactive services on the Website that shall
                  enable you to upload User Generated Content, including, without limitation:</p>

                <p>3.3.1. comment facilities;</p>

                <p>3.3.2. chat rooms; and/or</p>

                <p>3.3.3. bulletin boards,</p>

                <p>3.3.4. (together “Interactive Services”).</p>

                <p>3.4. Where we provide an Interactive Service, we will use reasonable endeavours to provide
                  information to you about the kind of service offered and if it is moderated. However, we are under no
                  obligation to oversee, monitor or moderate any Interactive Service we provide.</p>

                <h3>4. Viruses</h3>
                <p>4.1. We do not guarantee that the Website and or the Platform will be totally secure or free from
                  bugs or viruses. You are responsible for configuring your information technology, computer programmes
                  and platform to access the Website and we recommend that you use your own virus protection
                  software.</p>

                <p>4.2. You must not misuse the Website by knowingly introducing viruses, trojans, worms, logic bombs or
                  other material which is malicious or technologically harmful. You must not attempt to gain
                  unauthorised access to the Website, the server on which the Website and or the Platform is stored or
                  any server, computer or database connected to the Website and or the Platform. You must not attack the
                  Website and or the Platform via a denial-of-service attack or a distributed denial-of service attack.
                  By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990.
                  We will report any such breach to the relevant law enforcement authorities and we will co-operate with
                  those authorities by disclosing your identity to them. In the event of such a breach, your right to
                  use the Website and or the Platform will cease immediately and you will be liable for all direct and
                  indirect damages and costs which we will have incurred in restoring the Services.</p>

                <h3>5. Intellectual property</h3>
                <p>5.1. We are the owner or licensee of all intellectual property rights on the Website and the Platform
                  and its content, the SuperCarers and Home Instead names and marks. Those works are protected by
                  intellectual property laws and treaties around the world. All such rights are reserved.</p>

                <p>5.2. You are not granted any right to use, and may not use, any of our intellectual property rights
                  other than as set out in these Terms.</p>

                <p>5.3. No part of the Website and the Platform, including, without limitation, the text, designs,
                  graphics, photographs and images contained in it, may be copied, reproduced, republished, uploaded,
                  re-posted, modified, transmitted or distributed or otherwise used in any way for any non-personal,
                  public or commercial purpose without our prior written consent.</p>

                <p>5.4. Any communications or materials you send to us through the Website and the Platform by
                  electronic mail or other means will be treated as non-proprietary and non-confidential (other than
                  communications in respect of your order if you use the Website to buy products from us). We are free
                  to publish, display, post, distribute and otherwise use any ideas, suggestions, concepts, designs,
                  know-how and other information contained in such communications or material for any purpose,
                  including, but not limited to, developing, manufacturing, advertising and marketing us and our
                  products.</p>

                <h3>6. Copyright</h3>
                <p>6.1. All material on the Website, the Platform and in paper form are protected by copyright. You may
                  view pages of the Website and the Platform on a screen and may print or download extracts for your own
                  personal use. You agree to destroy all information obtained from the Website or the Platform after
                  your use, save where required to be retained by law.</p>

                <p>6.2. You may supply a copy of any such extract to any third party provided that:</p>

                <p>6.2.1. the extract is for their own personal use;</p>

                <p>6.2.2. the extract is not supplied as part of or included in another work, website, platform or
                  publication;</p>

                <p>6.2.3. the extract is not supplied either directly or indirectly in return for commercial gain;
                  and</p>

                <p>6.2.4. the third party is made aware that the source of the copy is Our Service and that these terms
                  and conditions apply equally to them.</p>

                <p>6.3. Content on the Website, Platform, provided by letter, article, newsletter, pamphlet, leaflet,
                  booklet, flier or other marketing or promotional content may not be reproduced, transmitted to, or
                  stored on any other website or any other form of electronic medium or used by any other company,
                  without our express written consent.</p>

                <h3>7. Cookies:</h3>
                <p>7.1. Certain parts of Our Service use “cookies” to keep track of your visit and to help you navigate
                  between sections. A cookie is a small data file that certain websites store on your computer’s
                  hard-drive when you visit such websites. More information is contained in our Privacy Policy.</p>

                <h3>8. Our liability</h3>
                <p>8.1. Whether you are a consumer or a Business User:</p>

                <p>8.2. We do not exclude or limit in any way our liability to you where it would be unlawful to do so.
                  This includes liability for death or personal injury caused by our negligence or the negligence of our
                  employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</p>

                <p>8.3. If you are a Business User:</p>

                <p>8.3.1. We exclude all implied conditions, warranties, representations or other terms that may apply
                  to our site or any content on it.</p>

                <p>8.3.2 We will not be liable to you for any loss or damage, whether in contract, tort (including
                  negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in
                  connection with:</p>

                <p>8.3.3. use of, or inability to use, our site; or</p>

                <p>8.3.4. use of or reliance on any content displayed on our site.</p>

                <p>8.4. In particular, we will not be liable for:</p>

                <p>8.4.1. loss of profits, sales, business, or revenue;</p>

                <p>8.4.2. business interruption;</p>

                <p>8.4.3. loss of anticipated savings;</p>

                <p>8.4.4. loss of business opportunity, goodwill or reputation; or</p>

                <p>8.4.5. any indirect or consequential loss or damage.</p>

                <p>8.5 If you are a consumer user:</p>

                <p>8.5.1. note that we only provide our site for domestic and private use. You agree not to use our site
                  for any commercial or business purposes, and we have no liability to you for any loss of profit, loss
                  of business, business interruption, or loss of business opportunity.</p>

                <p>8.5.2. Or in any other case, our liability to you will be limited to £100.</p>

                <h3>9. Suspension and termination</h3>
                <p>9.1. If you breach any of these Terms, we may immediately do any or all of the following (without
                  limitation):</p>

                <p>9.1.1. issue a warning to you;</p>

                <p>9.1.2. temporarily or permanently remove any User Generated Content uploaded by you to the Website or
                  Platform;</p>

                <p>9.1.3. temporarily or permanently withdraw your right to use the Website or Platform;</p>

                <p>9.1.4. issue legal proceedings against you for reimbursement of all costs resulting from the breach
                  (including, but not limited to, reasonable administrative and legal costs);</p>

                <p>9.1.5. take further legal action against you; and/or</p>

                <p>9.1.6. disclose such information to law enforcement authorities as we reasonably feel is necessary to
                  do so.</p>

                <h3>10. Use of your data</h3>
                <p>10.1. We will not, except as set out in these Terms, disclose confidential or sensitive information
                  about you to third parties, except those outlined below, without your consent. Please note that your
                  data will be shared with Home Instead Limited, any of the Home Instead franchised offices and selected
                  third parties who operate the Website or the Platform for the purpose of providing the Services,
                  monitoring use of the Services, improving the Services, creating new products and services and to use
                  anonymised and aggregated versions of such data or information (which shall not contain any personal
                  data, and, unless mutually agreed between the parties, shall not make any reference to any
                  individuals) for research purposes and in marketing and/or advertising materials relating to the
                  Services.</p>

                <p>10.2. You shall own all rights in your data and shall have sole responsibility for the legality,
                  reliability, integrity and accuracy of your data.</p>

                <p>10.3. You are responsible for any use of your account using your username and/or password, for
                  keeping your username and password confidential and for logging out of your account at the end of each
                  session. You agree to notify us immediately if you become aware of or suspect any unauthorised use of
                  your account, username and/or password. If you forget your username and/or password, you may email us
                  at our email address set out above and we will email your username and password to the email address
                  specified in your account. You are responsible for any actions taken by a person who uses our Service
                  using your username and password.</p>

                <p>10.4. You will provide accurate registration data and other information and keep the information you
                  provide on the Platform up to date at all times including, in particular, your profile information and
                  your availability to provide care.</p>

                <h3>11. Disputes and Complaints</h3>
                <p>11.1. Any complaints, queries and other feedback regarding the Services should be directed to us
                  using the contact details below (see Contacting Us).</p>

                <p>11.2. We are not obliged to have any involvement in any dispute between users of the Services, except
                  as required by law.</p>

                <p>11.3. Specifically, Franchisees have their own complaint procedure. We have no oversight of this, and
                  we will not get involved in any disputes that may arise regarding (but not limited to) payment to the
                  care professionals, payment from the clients, level of care provided by the Care Professionals or any
                  other issue between the Care Professionals, franchisees and their clients.</p>

                <p>11.4. If a concern or complaint relating to a care professional, franchisee or third party service
                  provider is raised with us, it is at our absolute discretion what action, if any, we decide to
                  take.</p>

                <p>11.5. We reserve the right to notify an appropriate authority or regulator to protect the interests
                  of clients and you accept that this overrides any confidentiality obligations, whether express or
                  implied, we have to you.</p>

                <h3>12. Availability of Our Services</h3>
                <p>12.1. We make no warranty to you that our Service or any of the tools we offer will be available to
                  you at all times, will continue uninterrupted, error-free or will remain unchanged and accept no
                  liability for any loss or damages arising as a result.</p>

                <h3>13. Other important information</h3>
                <p>13.1. Each of the clauses of these Terms operates separately. If any court or relevant authority
                  decides that any of them are unlawful or unenforceable, the remaining clauses will remain in full
                  force and effect.</p>

                <p>13.2. If we do not enforce our rights against you, or if we delay in doing so, that will not mean
                  that we have waived our rights against you and will not mean that you do not have to comply with those
                  obligations. If we do waive a default by you, we will only do so in writing, and that will not mean
                  that we will automatically waive any later default by you.</p>

                <h3>14. Governing law and jurisdiction</h3>
                <p>14.1. These Terms are governed by the laws of England and Wales. This means that your access to and
                  use of the Services, and any dispute or claim arising out of or in connection therewith (including
                  non-contractual disputes or claims), will be governed by English law.</p>

                <p>14.2. You can bring proceedings in respect of these Terms in the English courts. If you are a
                  consumer and you live in Scotland you can bring legal proceedings in respect of these Terms in either
                  the Scottish or the English courts. If you are a consumer and you live in Northern Ireland you can
                  bring legal proceedings in respect of these Terms in either the Northern Irish or the English courts.
                  We shall bring any dispute which may arise under these Terms to the competent court of your country of
                  habitual residence if this is within the UK, or otherwise the competent court of England.</p>

                <h3>15. Entire Agreement</h3>
                <p>15.1 These Terms and any document expressly referred to in them constitute the whole agreement
                  between us and supersede any previous arrangement, understanding or agreement between us.</p>

                <h3>16. Additional Terms</h3>
                <p>16.1 Additional Terms attached as Appendices will apply, in addition to the Terms, to the users of
                  the Services, depending on your role:</p>

                <p>16.1.1 Appendix 1 will be applicable to all Vitality Members.</p>

                <p>16.1.2 Appendix 2 will be applicable to all Care Professionals.</p>

                <p>16.1.3 Appendix 3 will be applicable to all Franchisee Offices.</p>

                <h3>17. Contacting us</h3>
                <p>
                  Should you have any reasons for a complaint, we will endeavour to resolve the issue and avoid any
                  re-occurrence in the future. You can always contact us by using the following details:
                </p>
                <ul>
                  <li>Email: hello@liveincare.homeinstead.co.uk</li>
                  <li>
                    Address: Unit 2 Walnut Tree Farm, Northwich Road, Lower Stretton, Warrington, England, WA4 4PG.
                  </li>
                  <li>Phone: 01925 730 273</li>
                </ul>

                <h3>Appendix 1 - Vitality Members</h3>

                <p>1. Care support line</p>

                <p>1.1 We operate a care support line (“Care Support Line”) which may be used for the following
                  purposes: 1.1.1 to discuss your care needs and provide you with general advice and support; and/or
                  1.1.2 to complete a consultation with one of our representatives (a “Care Consultation”), which might
                  help you think in more detail about what care services you may require.</p>

                <p>2. Users of the Care Support Line understand that:</p>

                <p>2.1 we provide general advice and support only. We do not provide care ourselves, nor do we provide
                  specific instructions or directions over the management and/or facilitation of care by care
                  professionals;</p>

                <p>2.2 if we suggest a care professional to you, we cannot confirm their suitability, professional
                  qualification or competence to provide the services and you engage them at your own risk;</p>

                <p>2.3 a Care Consultation is intended only to gather information about the care you may require, but it
                  is not intended to deliver, or in any way assist with the creation of, a care plan for you;</p>

                <p>2.4 we do not provide medical or clinical advice, nor do we specify what care should be delivered as
                  we do not have requisite detailed information to make such determinations; and</p>

                <p>3. we do not provide legal or regulated financial advice, although we may suggest you engage with
                  experts for such purposes.</p>

                <p>4. We make no warranty to you that the Care Support Line or any of the tools we offer will be
                  available to you at all times, will continue uninterrupted, error-free or will remain unchanged and
                  accept no liability for any loss or damages arising as a result.</p>

                <p>5. The Care Support Line can be withdrawn without any notice.</p>

                <h3>Appendix 2 – Care Professionals</h3>
                <p>1. These terms are specifically applicable to care professionals using the Platform.</p>

                <p>2. The Platform is designed to allow you to view and apply for jobs posted by the franchisees.</p>

                <p>3. The Platform seeks to do this by allowing you to view a job description and schedule provided by a
                  Franchisee (please note we do not validate or warranty the accuracy of the information provided by the
                  Franchisee). You then have the option to apply and complete the corresponding application, which will
                  be considered by the relevant Franchisee.</p>

                <p>4. If the Franchisee Office is interested in your application, they will make direct contact with you
                  either via the Platform, your email or phone number. By being registered on the platform you accept
                  that your contact details will be shared with the relevant Franchisee.</p>

                <p>5. Your account and password</p>

                <p>5.1 You will need to register an account with us on the Platform in order to access the Services
                  (“Account”). In order to register for an Account, you must be aged 18 or over and resident in the UK.
                  If you register an Account, you will be asked to provide certain information (such as your email
                  address) and to create a password, as part of our security procedures. You must treat the password as
                  confidential and you must not disclose it to any third party.</p>

                <p>5.2. Once you register an Account, you will be a “Registered User”.</p>

                <p>5.3. You agree that:</p>

                <p>5.3.1 all the information that you provide to us in connection with your Account is complete and
                  accurate;</p>

                <p>5.3.2 you are the person whose details you have provided;</p>

                <p>5.3.3 you will amend your profile immediately if there are any changes to the information you have
                  provided to us;</p>

                <p>5.3.4 we have the right to share the information with franchisees via the Platform to facilitate the
                  introduction of job opportunities.</p>

                <p>6. We have the right to disable any Accounts and/or passwords, at any time, if in our reasonable
                  opinion you have failed to comply with any of the provisions of these Terms.</p>

                <p>7. If you know or suspect that anyone other than you knows your Account login details, you must
                  immediately notify us using the details in clause 17.</p>

                <p>8. You are responsible for any unauthorised use of your Account login details.</p>

                <p>9. You acknowledge that we do not check or verify any details submitted by Franchisees about jobs
                  posted on the Platform for which you apply and that we cannot guarantee that the information provided,
                  or description of care required are correct. It is your responsibility to verify this information with
                  the relevant franchisee before commencing a care assignment.</p>

                <p>10. Franchisees are all independently owned and operated and each franchisee is an independent
                  employer in its own right. We are only providing a platform to connect you with franchisees. If you
                  respond to a care job listing (sometimes known as an assignment) which you wish to take on, you may if
                  successful in your application, enter into an employment contract directly with the relevant
                  franchisee at your own risk.</p>

                <p>11. Responding to a care job listing is not a guarantee of an offer of employment and you are not in
                  receipt of an offer until you have received a contract from the relevant franchisee. As each
                  franchisee is an independent employer in its own right, it will conduct its own compliance checks
                  before or after making an offer of employment or the Franchisee may request other information from you
                  as required by the relevant healthcare regulator operating in the region of the UK in which you will
                  be working.</p>

                <p>12. Training:</p>

                <p>12.1 We do not provide any type of training. All training is optional and is your responsibility. Any
                  training offered by the franchisees will need to be arranged with them directly, we will not reimburse
                  you for any training you decide to undergo in agreement with a franchisee or of your own volition,
                  whether via our “MyLearningCloud” system or otherwise.</p>

                <p>13. Parties:</p>

                <p>13.1 Nothing contained in these Terms shall render the relationship between you and us as that of an
                  employee, worker, agent or partner and you shall not hold yourself out as such. You acknowledge that
                  any work provided by you is provided on the basis of a direct engagement by the relevant franchisee,
                  who is independent from us and independently operated. If, notwithstanding this provision, you become
                  an employee, worker, agent or partner of ours by function of law or are otherwise deemed to be so by
                  the relevant authorities then you shall pay or reimburse any tax or national insurance payments due
                  from us to the relevant authorities and shall indemnify us for any loss suffered or cost incurred as a
                  result.</p>

                <p>13.2 We are not obliged to display any care job listings to you and you are not obliged to apply for
                  any car assignment offered.</p>

                <p>13.3 We provide no guarantee that any opportunities to be employed or provide care will be available
                  to You through the Website or the Platform.</p>

                <p>13.4 You acknowledge that the nature of temporary work means that there may be periods when no
                  suitable work is available and we make no guarantees that the platform will provide you with work.</p>

                <p>13.5 You will provide accurate registration data and other information and keep the information you
                  provide on the platform up to date at all times including, in particular, your profile information and
                  your availability to provide care. We accept no responsibility that the information held about you on
                  the Platform is accurate or up to date.</p>

                <p>13.6 You will complete any additional checks that a franchisee may require if you are offered a care
                  assignment.</p>

                <h3>Appendix 3 – Franchisee</h3>
                <p>1. The terms apply specifically to franchisees (and their employees) who use the Platform to
                  advertise jobs and source applicants for those jobs.</p>

                <p>2. The Platform is designed to allow you to advertise jobs listings, which will be visible to all
                  registered care professionals.</p>

                <p>3. Process</p>

                <p>3.1 In order to use the Platform;</p>

                <p>3.1.1 You will create a new job need on the Platform which describes a relevant live-in care
                  assignment for one of your clients.</p>

                <p>3.1.2 Once the need is created, you can preview this and post it to the care professional registered
                  users to view and respond to.</p>

                <p>3.2 Jobs Listings / Assignments:</p>

                <p>3.2.1 The job listing should include an accurate job description and which enables the Care
                  Professional to make an informed choice if the job is appropriate for them to apply to.</p>

                <p>3.2.2 It should also include accurate care times including schedule so the applicant is able to
                  determine it meets their availability to fulfil.</p>

                <p>3.2.3 Once a care professional has responded to a job, You are then responsible in managing the
                  communication between yourself and the care professional, via the means they provide, which may be the
                  email address or phone number.</p>

                <p>3.2.4 It is your responsibility to negotiate the key employment terms which might include but not be
                  limited to: rate of pay, any enhanced rates for Bank holidays etc. and food allowance and much
                  more.</p>

                <p>3.2.5 It is your responsibility to provide all necessary training to the care professionals.</p>

                <p>3.2.6 You will employ the care professionals directly at your own risk and we will not be a party to
                  any discussion or agreement you have with the care professionals.</p>

                <p>4. Your Obligations</p>

                <p>4.1 It is your responsibility to ensure all the correct pre-employment checks have been completed as
                  required by law or by any regulatory body.</p>

                <p>4.2 You accept that you will carry-out all background checks on the care professionals as required by
                  law and your regulator, including but not limited to;</p>

                <p>4.2.1 enhanced criminal record checks relevant for your country,</p>

                <p>4.2.2 identity checks,</p>

                <p>4.2.3 right to work</p>

                <p>4.2.4 documents check (including but not limited to; passport, driving licence and visa)</p>

                <p>4.2.5 adverse history check,</p>

                <p>4.2.6 employment history checks and reference checks, both professional and personal.</p>

                <p>4.3 You will pay the agreed sum to the Care Professionals for their service, deducting all relevant
                  taxes as required by law. You will pay for any training as required by law.</p>

                <p>4.4 You confirm and accept we will no longer be providing you with compliance checks for the Care
                  Professionals. All checks carried out in the past, and any other information stored on the Platform
                  including as part of a care professional’s “Home instead Passport”, is provided on an “as is”, “for
                  information only” basis and we give no assurance that these checks and information will be accurate
                  and up to date. The information on the Platform including the Care Professional profile is now the
                  sole responsibility of the care professionals themselves to update, which is why it is important that
                  you understand your own responsibility to verify any information provided and carry out all the
                  required checks before making an offer of employment.</p>
              </Col>
            </Row>
          </StyledContainer>
        </Module>
      </section>
    );
  }
}

export default TermsAndConditions;
