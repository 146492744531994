// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getBidSummary, getMatchTotal } from '_actions/jobOfferActions';
import {
  selectBidSummary,
  selectMatchTotal,
} from 'shared/selectors/jobOfferSelectors';

import BidsSummary from 'components/jobOffers/BidsSummary';

import { checkIfDefined } from 'components/helpers/functionHelpers';

import type { APIAction, JobOfferType } from 'shared/types';

type Props = {
  adminFilters: {},
  bidSummary: { [string]: number },
  getBidSummary: string => APIAction,
  getMatchTotal: string => APIAction,
  jobOffer: JobOfferType,
  matchTotal: number,
  totalBids: number,
};

export function BidsSummaryContainer({
  bidSummary,
  getBidSummary,
  getMatchTotal,
  jobOffer,
  matchTotal,
}: Props) {
  useEffect(() => {
    getBidSummary(jobOffer.id);
    getMatchTotal(jobOffer.id);
  }, [jobOffer.id, jobOffer.adminFilters]);

  if (!checkIfDefined(matchTotal) || !bidSummary) {
    return null;
  }

  const openJob = !['pending', 'draft'].includes(jobOffer.state);
  const totalBids = jobOffer.bids && jobOffer.bids.length;

  return (
    <BidsSummary
      bidSummary={bidSummary}
      matchTotal={matchTotal}
      totalBids={totalBids}
      openJob={openJob}
    />
  );
}

const mapStateToProps = (state, props) => ({
  bidSummary: selectBidSummary(state, props.jobOffer.id),
  matchTotal: selectMatchTotal(state, props.jobOffer.id),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBidSummary,
      getMatchTotal,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BidsSummaryContainer);
