//@flow
import React, { type Node } from 'react';
import styled from 'styled-components';

import ReduxToastrMessage from 'components/global/ReduxToastrMessage';
import { Container, Row, Col } from 'components/grid';

import Background from 'components/images/login_background.png';

const StyledContainer = styled(Container)`
  display: flex;
  color: ${props => props.theme.colors.formText};
  font-family: ${props => props.theme.typography.fontStack};
  font-size: ${props => props.theme.typography.defaultFontSize};
  line-height: ${props => props.theme.typography.defaultLineHeight};
  min-height: 100vh;

  @media (min-width: ${props => props.theme.screen.small}px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const ColBackground = styled(Col)`
  position: relative;
  z-index: 1;
`;

const StyledColContainer = styled(Container)`
  flex-grow: 1;
`;

type Props = {
  children?: Node,
};

function LoginWrapper({ children }: Props) {
  return (
    <StyledContainer outerGutter={0}>
      <Row>
        <Col md={12}>
          <ReduxToastrMessage />
        </Col>
        <StyledColContainer outerGutter={{ xs: 0, sm: 0, md: 200, lg: 400 }}>
          <ColBackground background={Background} md={12}>
            {children}
          </ColBackground>
        </StyledColContainer>
      </Row>
    </StyledContainer>
  );
}

export default LoginWrapper;
