// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TabbedComponents from 'components/global/layout/TabbedComponents';

import AdminFranchiseInfoContainer from 'containers/admin/franchise/AdminFranchiseInfoContainer';
import AdminFranchiseUsersContainer from 'containers/admin/franchise/AdminFranchiseUsersContainer';
import NotesContainer from 'containers/notes/NotesContainer';

import type { MatchType } from 'shared/types';


type Props = {
  match: MatchType,
};

export class AdminFranchiseSummaryContainer extends Component<Props> {

  static defaultProps = {};

  render () {
    return (
      <TabbedComponents>
        <AdminFranchiseInfoContainer
          name="Info"
          default
          franchiseID={this.props.match.params.franchiseID}
        />
        <AdminFranchiseUsersContainer
          name="Users"
          franchiseID={this.props.match.params.franchiseID}
        />
        <NotesContainer
          name="Notes"
          excludeCategories={['updatehistory']}
          objectID={this.props.match.params.franchiseID}
          noteType="Franchise"
          placeholder="Keep up to date record of your communications with Franchises"
        />
      </TabbedComponents>
    );
  }
}

const mapStateToProps = (state, props) => ({
  match: props.match,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminFranchiseSummaryContainer);
