// @flow
import { getCarerById, getCarerProfileByCarerId } from '_actions/carerActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import CarerProfile from 'components/carers/profile/CarerProfile';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { getUser, isAdmin } from 'shared/selectors/accountSelectors';
import { getCarerByID } from 'shared/selectors/carerSelectors';

import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

import type {
  CarerType,
  TaxonomiesClassificationType,
  UserType,
} from 'shared/types';

type Props = {
  carer: CarerType,
  getCarerById: string => void,
  getCarerProfileByCarerId: (string, Object) => void,
  match: {
    params: {
      carerID: string,
    },
  },
  taxonomiesByClassification: TaxonomiesClassificationType,
  user: UserType,
  isAdmin: boolean,
};

export class CarerProfileContainer extends Component<Props> {
  static defaultProps = {
    carer: null,
    user: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'care_type',
      'condition_experience',
      'care_experience_years',
      'condition',
      'equipment',
      'hobbies',
      'language_level',
      'languages',
      'likes_animals',
      'qualifications',
      'recent_uk_care',
      'services',
      'training_courses',
    ];
  }

  componentDidMount() {
    this.props.getCarerById(this.props.match.params.carerID);
  }

  componentDidUpdate() {
    if (this.props.carer && !this.props.carer.profile_details) {
      this.props.getCarerProfileByCarerId(this.props.carer.id, {
        approved: true,
      });
    }
  }

  render() {
    const { carer, isAdmin, user, taxonomiesByClassification } = this.props;

    if (
      !carer ||
      !this.props.carer.profile_details ||
      !taxonomiesByClassification
    ) {
      return <LoadingPlaceholder />;
    }

    return (
      <CarerProfile
        carer={carer}
        taxonomiesByClassification={taxonomiesByClassification}
        adminView={isAdmin}
        currentUserId={user && user.id}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  carer: getCarerByID(state, props.match.params.carerID),
  isAdmin: isAdmin(state),
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    CarerProfileContainer.TAXONOMY_CLASSIFICATIONS,
  ),
  user: getUser(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCarerById,
      getCarerProfileByCarerId,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerProfileContainer);
