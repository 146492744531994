import { createSelector } from 'reselect';
import { selectSchedulesByNeedID } from 'shared/selectors/scheduleSelectors';
import { selectSchedulesForShifts } from 'shared/selectors/scheduleSelectors';

export const selectShifts = state => {
  return state.shifts;
};
export const selectShiftByID = (state, id) => state.shifts[id];
export const selectShiftsByUserID = (state, userID) => {
  return Object.values(state.shifts).filter(shift => shift.user_id === userID);
};

export const selectShiftsByScheduleID = (state, scheduleID) => {
  if (!state.shifts) {
    return null;
  }

  return Object.values(state.shifts).filter(
    shift => shift.schedule_id === scheduleID,
  );
};

export const selectShiftsByCustomerID = (state, customerID) => {
  const needs = Object.values(state.needs).filter(
    need => need.user_id === customerID,
  );

  if (!needs || !needs[0]) {
    return null;
  }

  return needs.map(need => selectShiftsByNeedID(state, need.id)).flatten();
};

export const selectShiftsByNeedID = createSelector(
  [selectSchedulesByNeedID, selectShifts],
  (schedules, shifts) => {
    if (!schedules || !shifts) {
      return null;
    }

    const shiftsForID = schedules.reduce(
      (accum, schedule) =>
        accum.concat(
          Object.values(shifts).filter(
            shift => shift.schedule_id === schedule.id,
          ),
        ),
      [],
    );
    return shiftsForID;
  },
);

export const selectAmendsByScheduleID = (state, scheduleID) => {
  return selectShiftsByScheduleID(state, scheduleID).filter(
    shift => shift.amended === true,
  );
};

export const getNeedIDForShift = (state, shift) => {
  const schedules = Object.values(selectSchedulesForShifts(state, [shift]));

  if (!schedules || !schedules[0]) {
    return null;
  }

  return schedules[0].care_need_id;
};
