import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getJobOfferByID } from '_actions/jobOfferActions';
import {
  selectJobOfferByID,
  selectJobOfferLoadingState,
} from 'shared/selectors/jobOfferSelectors';
import { getScheduleById } from '_actions/scheduleActions';
import { selectScheduleWithShiftsByScheduleID } from 'shared/selectors/scheduleShiftsSelectors';

import Module, { ModuleHero } from 'components/global/layout/Module';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import JobSummaryContainer from 'containers/admin/needs/jobs/JobSummaryContainer';

function JobOfferDetailsContainer({
  schedule = null,
  jobOffer = null,
  jobOfferID,
  jobOffersLoading,
  getJobOfferByID,
  getScheduleById,
}) {
  useEffect(() => {
    getJobOfferByID(jobOfferID);
  }, []);

  useEffect(() => {
    if (jobOffer && !schedule) {
      getScheduleById(jobOffer.schedule_id);
    }
  }, [jobOffer]);

  if (jobOffersLoading) {
    return <LoadingPlaceholder />;
  }

  if (!schedule || !jobOffer) {
    return <ModuleHero>Invalid job ID</ModuleHero>;
  }

  return (
    <Module noPadding>
      <JobSummaryContainer jobOffer={jobOffer} schedule={schedule} editStatus />
    </Module>
  );
}

const mapStateToProps = (state, props) => {
  const jobOfferID = props.location.query.jobID;
  const jobOffer = selectJobOfferByID(state, jobOfferID);
  const schedule =
    jobOffer &&
    selectScheduleWithShiftsByScheduleID(state, jobOffer.schedule_id);

  return {
    jobOfferID,
    jobOffersLoading: selectJobOfferLoadingState(state),
    jobOffer,
    schedule,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getJobOfferByID,
      getScheduleById,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobOfferDetailsContainer);
