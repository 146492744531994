import PropTypes, { oneOfType, arrayOf } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CheckboxChildren from 'components/forms/CheckboxWithChildren/CheckboxChildren';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

const StyledCheckbox = styled.div`
  margin: 10px 0 0 0;
  > input {
    margin-right: 10px;
  }
`;

const StyledContainer = styled.div`
  &.error {
    border: 1px solid #d86663;
    border-radius: 5px;
  }
`;
class CheckboxWithChildren extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.currentSelected,
      newValue: JSON.parse(JSON.stringify([...this.props.value])),
    };
  }

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.shape().isRequired,
    currentSelected: PropTypes.array.isRequired,
    value: oneOfType([arrayOf(PropTypes.string), PropTypes.string]),
    className: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    customPlaceholder: null,
    customOnChange: null,
    onBlur: () => {},
    onFocus: () => {},
    name: '',
    value: [],
  };

  onChange = e => {
    e.persist();
    e.stopPropagation();
    const { selected } = this.state;
    if (e.target.checked) {
      selected.push(e.target.value);
      this.addTaxonomyToValue(e.target.value);
    } else {
      const newSelected = selected.filter(value => {
        return value.indexOf(e.target.value) !== 0;
      });
      this.setState({ selected: newSelected });
      this.removeTaxonomyFromValue(e.target.value);
    }
  };

  addTaxonomyToValue = taxonomy => {
    const { newValue } = this.state;
    const { childrenTaxonomyClassifications, onChange } = this.props;
    const taxonomyIds = taxonomy.split('_');
    if (taxonomyIds.length === 1) {
      const newObj = {
        taxon_id: taxonomyIds[0],
        children: {},
      };
      newValue.push(newObj);
    } else if (taxonomyIds.length === 2) {
      const parentIndex = newValue.findIndex(
        obj => obj.taxon_id === taxonomyIds[0],
      );
      if (!newValue[parentIndex].children[childrenTaxonomyClassifications[0]]) {
        newValue[parentIndex].children[childrenTaxonomyClassifications[0]] = [];
      }
      newValue[parentIndex].children[childrenTaxonomyClassifications[0]].push(
        taxonomyIds[1],
      );
    }
    return onChange(newValue);
  };

  removeTaxonomyFromValue = taxonomy => {
    const { newValue } = this.state;
    const { childrenTaxonomyClassifications, onChange } = this.props;
    const taxonomyIds = taxonomy.split('_');
    if (taxonomyIds.length === 1) {
      const taxonomyIndex = newValue.findIndex(
        obj => obj.taxon_id === taxonomyIds[0],
      );
      newValue.splice(taxonomyIndex, 1);
    } else if (taxonomyIds.length === 2) {
      const parentIndex = newValue.findIndex(
        obj => obj.taxon_id === taxonomyIds[0],
      );
      const childIndex = newValue[parentIndex].children[
        childrenTaxonomyClassifications[0]
      ].indexOf(taxonomyIds[1]);
      newValue[parentIndex].children[childrenTaxonomyClassifications[0]].splice(
        childIndex,
        1,
      );
    }
    return onChange(newValue);
  };

  render() {
    const { taxonomiesByClassification, className, name, options } = this.props;

    const { selected } = this.state;

    return (
      <StyledContainer
        className={`${className}`}
        id={`dropdown-${name}`}
        name={name}
      >
        {options.map(item => (
          <StyledCheckbox>
            <label htmlFor={`${item.taxon_id}`} key={item.taxon_id}>
              <input
                id={`${item.taxon_id}`}
                value={item.taxon_id}
                type="checkbox"
                onChange={this.onChange}
                onFocus={e => e.stopPropagation()}
                onBlur={e => e.stopPropagation()}
                checked={selected.includes(item.taxon_id)}
              />
              {item.value}
              {selected.includes(item.taxon_id) &&
                Object.keys(item.children).length !== 0 && (
                  <CheckboxChildren
                    children={item.children}
                    parent={item.taxon_id}
                    onChange={this.onChange}
                    taxonomiesByClassification={taxonomiesByClassification}
                    selected={selected}
                  />
                )}
            </label>
          </StyledCheckbox>
        ))}
      </StyledContainer>
    );
  }
}

const mapStateToProps = (state, props) => ({
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    props.childrenTaxonomyClassifications,
  ),
});

export default connect(mapStateToProps)(CheckboxWithChildren);
