import React from 'react';

const BasicCheckbox = ({ id, name, value, onChange }) => (
  <input
    checked={value}
    id={id}
    name={name}
    onChange={e => onChange(e.target.checked)}
    type="checkbox"
  />
);

export default BasicCheckbox;
