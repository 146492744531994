import { createSelector } from 'reselect';
import { getUser } from 'shared/selectors/accountSelectors';
import { selectBidResultsBySearchName } from 'shared/selectors/bidSelectors';
import { getNeeds } from 'shared/selectors/needSelectors';
import { selectScheduleWithShiftsByScheduleID } from 'shared/selectors/scheduleShiftsSelectors';

export const getJobOffers = state => state.jobOffers;
export const getJobOfferSearch = state => state.search.jobOffers;
export const selectJobOfferByID = (state, id) => state.jobOffers[id];
export const selectJobOfferLoadingState = state =>
  state.jobOffers.loading.jobOffers;
export const selectJobOfferByReference = (state, reference) =>
  Object.values(state.jobOffers).find(
    jobOffer => jobOffer.reference === reference,
  );

export const selectJobOffersByBids = (state, bids) => {
  if (!bids) {
    return null;
  }

  return bids
    .map(bid => selectJobOfferByID(state, bid.job_offer_id))
    .filter(jobOffer => jobOffer);
};

export const selectJobOffersforNeedByID = (state, needID) => {
  const jobOffers = getJobOffers(state);
  if (!jobOffers || !needID) {
    return null;
  }
  const found = Object.values(jobOffers).filter(
    jobOffer => jobOffer.need_id === needID,
  );
  if (found.length) {
    return found;
  }
  return null;
};

//TODO do we still need this? is there ever going to be just one job offer?
export const getJobOfferforUserByID = (state, userID) => {
  const jobOffers = getJobOffers(state);
  if (!jobOffers || !userID) {
    return null;
  }
  const found = Object.values(jobOffers).filter(
    jobOffer => jobOffer.user_id === userID,
  );
  if (found.length) {
    return found[0];
  }
  return null;
};

export const selectAllJobOffersforUser = (state, userID) => {
  const jobOffers = getJobOffers(state);
  if (!jobOffers || !userID) {
    return null;
  }

  return Object.values(jobOffers).filter(
    jobOffer => jobOffer.user_id === userID,
  );
};

export const selectJobOffersWithBidResultsForUser = createSelector(
  [getJobOffers, selectBidResultsBySearchName, getUser],
  (jobOffers, bids, user) => {
    if (!jobOffers || !bids || !user) {
      return null;
    }
    const offers = [];
    bids.forEach(bid => {
      if (jobOffers[bid.job_offer_id] && bid.carer_id === user.id) {
        offers.push({ jobOffer: jobOffers[bid.job_offer_id], bid });
      }
    });

    return offers;
  },
);

export const selectNeedforJobOfferByID = createSelector(
  [getNeeds, selectJobOfferByID],
  (needs, jobOffer) => {
    if (!needs || !jobOffer) {
      return null;
    }
    return needs[jobOffer.need_id];
  },
);

export const selectScheduleForJobOfferByID = (state, jobOfferID) => {
  const jobOffer = selectJobOfferByID(state, jobOfferID);
  if (!jobOffer) {
    return null;
  }
  return selectScheduleWithShiftsByScheduleID(state, jobOffer.schedule_id);
};

export const getJobOfferResults = createSelector(
  [getJobOffers, getJobOfferSearch],
  (jobOffers, search) => {
    if (!search || !search.searchOrder) {
      return [];
    }
    const results = [];
    search.searchOrder.forEach(id => {
      const match = jobOffers[id];
      if (match) {
        results.push(match);
      }
    });
    return results;
  },
);

export const selectJobOffersByScheduleId = (state, scheduleID) => {
  const jobOffers = Object.values(getJobOffers(state)).filter(
    obj => obj.schedule_id === scheduleID,
  );

  if (!jobOffers) return [];

  return jobOffers;
};

export const selectMatchTotal = createSelector(
  [selectJobOfferByID],
  jobOffer => {
    if (!jobOffer) {
      return null;
    }

    return jobOffer.matchTotal;
  },
);

export const selectBidSummary = createSelector(
  [selectJobOfferByID],
  jobOffer => {
    if (!jobOffer) {
      return null;
    }

    return jobOffer.bidSummary;
  },
);
