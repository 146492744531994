// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';

import Button from 'components/buttons/Button';

const SubscriptText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.uiDarkGrey};
  text-align: center;
  max-width: 240px;
`;

type Props = {
  children?: Node,
  className: string,
  subscript: string,
};

function ButtonWithSubscript(props: Props) {
  return (
    <div className={props.className}>
      <Button {...props}>{props.children}</Button>
      <SubscriptText>{props.subscript}</SubscriptText>
    </div>
  );
}

export default ButtonWithSubscript;
