import ApiConstants from 'shared/constants/ApiConstants';
import HolidayConstants from 'shared/constants/HolidayConstants';

export function getHolidays() {
  return {
    endpoint: '/settings/holiday/',
    method: 'get',
    onError: {
      type: HolidayConstants.HOLIDAY_GET_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to get holiday',
      },
    },
    onStart: { type: HolidayConstants.HOLIDAY_GET },
    onSuccess: { type: HolidayConstants.HOLIDAY_GET_SUCCESS },
    type: ApiConstants.API_REQUEST,
    payload: {
      order_by_parameter: 'date',
    },
  };
}

export function createHoliday(holiday) {
  return {
    endpoint: '/settings/holiday/',
    method: 'post',
    onError: {
      type: HolidayConstants.HOLIDAY_CREATE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to create holiday',
      },
    },
    onStart: { type: HolidayConstants.HOLIDAY_CREATE },
    onSuccess: { type: HolidayConstants.HOLIDAY_CREATE_SUCCESS },
    payload: holiday,
    type: ApiConstants.API_REQUEST,
  };
}

export function updateHoliday(date, name) {
  return {
    endpoint: `/settings/holiday/${date}`,
    method: 'put',
    onError: {
      type: HolidayConstants.HOLIDAY_UPDATE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to update holiday',
      },
    },
    onStart: { type: HolidayConstants.HOLIDAY_UPDATE },
    onSuccess: {
      type: HolidayConstants.HOLIDAY_UPDATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Holiday updated',
      },
    },
    payload: { name },
    type: ApiConstants.API_REQUEST,
  };
}

export function deleteHoliday(date) {
  return {
    endpoint: `/settings/holiday/${date}`,
    method: 'delete',
    onError: {
      type: HolidayConstants.HOLIDAY_DELETE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to delete holiday',
      },
    },
    onStart: { type: HolidayConstants.HOLIDAY_DELETE },
    onSuccess: { type: HolidayConstants.HOLIDAY_DELETE_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}
