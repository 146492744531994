import keyMirror from 'keymirror';

export default keyMirror({
  REFERENCE_GET_BY_ID: null,
  REFERENCE_GET_BY_ID_SUCCESS: null,
  REFERENCE_GET_BY_ID_FAILURE: null,

  REFERENCES_GET_FOR_CARER_BY_ID: null,
  REFERENCES_GET_FOR_CARER_BY_ID_SUCCESS: null,
  REFERENCES_GET_FOR_CARER_BY_ID_FAILURE: null,

  REFERENCES_SEARCH: null,
  REFERENCES_SEARCH_SUCCESS: null,
  REFERENCES_SEARCH_FAILURE: null,

  REFERENCE_ADD: null,
  REFERENCE_ADD_SUCCESS: null,
  REFERENCE_ADD_FAILURE: null,

  REFERENCE_UPDATE: null,
  REFERENCE_UPDATE_SUCCESS: null,
  REFERENCE_UPDATE_FAILURE: null,

  REFERENCE_DELETE: null,
  REFERENCE_DELETE_SUCCESS: null,
  REFERENCE_DELETE_FAILURE: null,

  REFERENCE_SEND: null,
  REFERENCE_SEND_SUCCESS: null,
  REFERENCE_SEND_FAILURE: null,

  REFERENCES_SUBMIT: null,
  REFERENCES_SUBMIT_SUCCESS: null,
  REFERENCES_SUBMIT_FAILURE: null,
});
