import {
  all,
  put,
  call,
  take,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { runParallel } from 'sagas/sagaHelpers';
import { shortWeekdays } from 'shared/helpers/scheduleHelpers';
import { getNeedsByUserId } from '_actions/needActions';
import {
  getSchedulesWithScheduleShiftsByNeedID as getSchedulesWithScheduleShiftsByNeedIDAction,
  getScheduleShiftsByParams,
  getSchedulesByParams,
  modifySchedule,
  createSchedule,
  createScheduleShift,
  updateScheduleShift,
  deleteScheduleShift,
  getScheduleById,
} from '_actions/scheduleActions';
import { getShiftsByParams, deleteShift } from '_actions/shiftActions';
import CareNeedConstants from 'shared/constants/CareNeedConstants';
import ShiftConstants from 'shared/constants/ShiftConstants';
import ScheduleConstants from 'shared/constants/ScheduleConstants';
import JobOfferConstants from 'shared/constants/JobOfferConstants';
import { selectShiftsByUserID } from 'shared/selectors/shiftsSelectors';
import { getNeedByID } from '_actions/needActions';
import { actions as toastrActions } from 'react-redux-toastr';
import uuidv4 from 'uuid/v4';

function* getSchedulesWithScheduleShiftsByNeedID({ needID, searchParams }) {
  yield put(getSchedulesByParams({ care_need_id: needID, ...searchParams }));

  const getSchedulesResult = yield take([
    ScheduleConstants.SCHEDULE_GET_BY_PARAMS_SUCCESS,
    ScheduleConstants.SCHEDULE_GET_BY_PARAMS_FAILURE,
  ]);
  if (
    getSchedulesResult.type === ScheduleConstants.SCHEDULE_GET_BY_PARAMS_SUCCESS
  ) {
    //once this comes back we need to go and get all the shifts for each schedule
    const schedules = getSchedulesResult.data.results;
    const tasksPending = new Set();
    yield all(
      schedules.map(schedule => {
        tasksPending.add(schedule.id);
        return put(
          getScheduleShiftsByParams({
            schedule_id: schedule.id,
            per_page: 1000,
          }),
        );
      }),
    );

    while (tasksPending.size) {
      const getShiftsResult = yield take([
        ScheduleConstants.SCHEDULE_SHIFT_GET_BY_PARAMS_SUCCESS,
        ScheduleConstants.SCHEDULE_SHIFT_GET_BY_PARAMS_FAILURE,
      ]);

      if (
        getShiftsResult.type ===
        ScheduleConstants.SCHEDULE_SHIFT_GET_BY_PARAMS_SUCCESS
      ) {
        tasksPending.delete(getShiftsResult.scheduleID);
      } else {
        return yield put({
          type:
            ScheduleConstants.GET_SCHEDULE_WITH_SCHEDULE_SHIFTS_BY_NEED_ID_FAILURE,
        });
      }
    }
  } else {
    return yield put({
      type:
        ScheduleConstants.GET_SCHEDULE_WITH_SCHEDULE_SHIFTS_BY_NEED_ID_FAILURE,
    });
  }

  return yield put({
    type:
      ScheduleConstants.GET_SCHEDULE_WITH_SCHEDULE_SHIFTS_BY_NEED_ID_SUCCESS,
  });
}

export function* getSchedulesWithScheduleShiftsByNeedIDListener() {
  yield takeLatest(
    ScheduleConstants.GET_SCHEDULE_WITH_SCHEDULE_SHIFTS_BY_NEED_ID,
    getSchedulesWithScheduleShiftsByNeedID,
  );
}

function* getSchedulesWithScheduleShiftsByScheduleIDs({ scheduleIDs }) {
  yield all(scheduleIDs.map(scheduleID => put(getScheduleById(scheduleID))));

  const [...getScheduleResults] = yield all(
    Array(scheduleIDs.length).fill(
      take([
        ScheduleConstants.SCHEDULE_GET_BY_ID_SUCCESS,
        ScheduleConstants.SCHEDULE_GET_BY_ID_FAILURE,
      ]),
    ),
  );

  getScheduleResults.filter(
    result => result.type === ScheduleConstants.SCHEDULE_GET_BY_ID_SUCCESS,
  );

  yield all(
    getScheduleResults.map(result =>
      put(
        getScheduleShiftsByParams({
          schedule_id: result.data.id,
          per_page: 1000,
        }),
      ),
    ),
  );

  yield all(
    Array(getScheduleResults.length).fill(
      take([
        ScheduleConstants.SCHEDULE_SHIFT_GET_BY_PARAMS_SUCCESS,
        ScheduleConstants.SCHEDULE_SHIFT_GET_BY_PARAMS_FAILURE,
      ]),
    ),
  );

  return yield put({
    type:
      ScheduleConstants.GET_SCHEDULES_WITH_SCHEDULE_SHIFTS_BY_SCHEDULE_IDS_SUCCESS,
  });
}

export function* getSchedulesWithScheduleShiftsByScheduleIDsListener() {
  yield takeEvery(
    ScheduleConstants.GET_SCHEDULES_WITH_SCHEDULE_SHIFTS_BY_SCHEDULE_IDS,
    getSchedulesWithScheduleShiftsByScheduleIDs,
  );
}

function* createScheduleWithShifts({ schedule, scheduleTimes }) {
  yield put(
    createSchedule({
      ...schedule,
      deletedShifts: undefined,
    }),
  );

  const scheduleResult = yield take([
    ScheduleConstants.SCHEDULE_CREATE_SUCCESS,
    ScheduleConstants.SCHEDULE_CREATE_FAILURE,
  ]);
  if (scheduleResult.type === ScheduleConstants.SCHEDULE_CREATE_FAILURE) {
    return yield put({
      type: ScheduleConstants.CREATE_SCHEDULE_WITH_SHIFTS_FAILURE,
    });
  }
  const scheduleID = scheduleResult.data.id;
  const tasks = [];
  const tasksPending = [];
  scheduleTimes &&
    Object.entries(scheduleTimes).forEach(([day, daySelection]) => {
      daySelection.forEach((shift, index) => {
        const tag = `${scheduleID}-${day}-${index}`;
        tasksPending.push(tag);
        tasks.push(
          put(
            createScheduleShift(
              {
                schedule_id: scheduleID,
                start: shift.start,
                duration: shift.duration,
                iso_weekday: shortWeekdays.indexOf(day) + 1,
                week_number: 0,
                type: shift.type === 'day' ? 'short_duty' : shift.type,
                user_id: shift.user_id ? shift.user_id : undefined,
              },
              tag,
            ),
          ),
        );
      });
    });

  yield all(tasks);

  while (tasksPending.size) {
    const result = take([
      ScheduleConstants.SCHEDULE_SHIFT_CREATE_SUCCESS,
      ScheduleConstants.SCHEDULE_SHIFT_CREATE_FAILURE,
    ]);
    if (result.type === ScheduleConstants.SCHEDULE_SHIFT_CREATE_FAILURE) {
      return yield put({
        type: ScheduleConstants.CREATE_SCHEDULE_WITH_SHIFTS_FAILURE,
      });
    }
    tasksPending.delete(result.tag);
  }

  return yield put({
    type: ScheduleConstants.CREATE_SCHEDULE_WITH_SHIFTS_SUCCESS,
  });
}

export function* createScheduleWithShiftsListener() {
  yield takeLatest(
    ScheduleConstants.CREATE_SCHEDULE_WITH_SHIFTS,
    createScheduleWithShifts,
  );
}

export function* updateScheduleListener() {
  yield takeLatest(ScheduleConstants.UPDATE_SCHEDULE_REQUEST, updateSchedule);
}
function* updateSchedule({ schedule, scheduleId }) {
  try {
    yield put(
      modifySchedule(schedule.id, {
        ...schedule,
        start: schedule.start ? schedule.start : null,
        end: schedule.end ? schedule.end : null,
        schedule: undefined,
        care_need_id: undefined,
        id: undefined,
        deletedShifts: undefined,
      }),
    );
    const modifyScheduleResult = yield take([
      ScheduleConstants.SCHEDULE_MODIFY_SUCCESS,
      ScheduleConstants.SCHEDULE_MODIFY_FAILURE,
    ]);

    if (
      modifyScheduleResult.type === ScheduleConstants.SCHEDULE_MODIFY_FAILURE
    ) {
      throw ScheduleConstants.SCHEDULE_MODIFY_FAILURE;
    }

    yield put(
      toastrActions.add({
        type: 'success',
        public: false,
        title: 'Successfully updated schedule',
        options: {
          showCloseButton: true,
        },
      }),
    );
    return yield put({
      type: ScheduleConstants.UPDATE_SCHEDULE_SUCCESS,
    });
  } catch (e) {
    return yield put({
      type: ScheduleConstants.UPDATE_SCHEDULE_FAILURE,
    });
  }
}

export function* updateShiftsListener() {
  yield takeLatest(ScheduleConstants.UPDATE_SHIFTS_REQUEST, updateShifts);
}
function* updateShifts({ shifts }) {
  try {
    const tasks = [];
    const tasksPending = new Set();

    shifts.deletedShifts &&
      shifts.deletedShifts.forEach(shiftId => {
        if (shiftId) {
          tasks.push(put(deleteScheduleShift(shiftId)));
          tasksPending.add(shiftId);
        }
      });

    shifts.shifts &&
      Object.entries(shifts.shifts).forEach(([day, daySelection]) => {
        daySelection.forEach((shift, index) => {
          const tag = `${day}-${index}`;
          tasksPending.add(tag);
          if (shift.id) {
            tasks.push(
              put(
                updateScheduleShift(
                  shift.id,
                  {
                    ...shift,
                    id: undefined,
                    iso_weekday_name: undefined,
                    schedule_id: undefined,
                  },
                  tag,
                ),
              ),
            );
          } else {
            tasks.push(
              put(
                createScheduleShift(
                  {
                    schedule_id: shift.schedule_id,
                    start: shift.start,
                    duration: shift.duration,
                    iso_weekday: shortWeekdays.indexOf(day) + 1,
                    week_number: shift.week_number,
                    type: shift.type === 'day' ? 'short_duty' : shift.type,
                    user_id: shift.user_id ? shift.user_id : undefined,
                  },
                  tag,
                ),
              ),
            );
          }
        });
      });

    yield all(tasks);

    while (tasksPending.size) {
      const result = yield take([
        ScheduleConstants.SCHEDULE_SHIFT_UPDATE_SUCCESS,
        ScheduleConstants.SCHEDULE_SHIFT_UPDATE_FAILURE,
        ScheduleConstants.SCHEDULE_SHIFT_CREATE_SUCCESS,
        ScheduleConstants.SCHEDULE_SHIFT_CREATE_FAILURE,
        ScheduleConstants.SCHEDULE_SHIFT_DELETE_SUCCESS,
        ScheduleConstants.SCHEDULE_SHIFT_DELETE_FAILURE,
      ]);

      switch (result.type) {
        case ScheduleConstants.SCHEDULE_SHIFT_UPDATE_SUCCESS:
        case ScheduleConstants.SCHEDULE_SHIFT_CREATE_SUCCESS:
          tasksPending.delete(result.tag);
          break;

        case ScheduleConstants.SCHEDULE_SHIFT_DELETE_SUCCESS:
          tasksPending.delete(result.scheduleShiftID);
          break;

        default:
          return yield put({
            type: ScheduleConstants.UPDATE_SHIFTS_FAILURE,
          });
      }
    }

    yield put(
      toastrActions.add({
        type: 'success',
        public: false,
        title: 'Successfully updated schedule shifts',
        options: {
          showCloseButton: true,
        },
      }),
    );
    return yield put({
      type: ScheduleConstants.UPDATE_SHIFTS_SUCCESS,
    });
  } catch (e) {
    return yield put({
      type: ScheduleConstants.UPDATE_SHIFTS_FAILURE,
    });
  }
}

export function* fetchShiftsForCustomerUserID(action) {
  yield put(getNeedsByUserId(action.payload.userID));

  const result = yield take([
    CareNeedConstants.CARE_NEEDS_GET_BY_USER_SUCCESS,
    CareNeedConstants.CARE_NEEDS_GET_BY_USER_FAILURE,
  ]);
  if (result.type === CareNeedConstants.CARE_NEEDS_GET_BY_USER_SUCCESS) {
    yield all(
      result.data.map(need => {
        const payload = { needID: need.id };
        if (action.payload.starts_after) {
          payload['starts_after'] = action.payload.starts_after;
        }
        if (action.payload.starts_before) {
          payload['starts_before'] = action.payload.starts_before;
        }
        return fetchShiftsForNeedID({ payload });
      }),
    );
  } else {
    throw CareNeedConstants.CARE_NEEDS_GET_BY_USER_FAILURE;
  }
}

export function* fetchMoreShiftsForCustomerUserIDListener() {
  yield takeLatest(
    ShiftConstants.SHIFT_GET_MORE_FOR_CUSTOMER_USER_ID,
    function*(...args) {
      try {
        yield* fetchShiftsForCustomerUserID(...args);
        yield put({
          type: ShiftConstants.SHIFT_GET_MORE_FOR_CUSTOMER_USER_ID_SUCCESS,
        });
      } catch (error) {
        yield put({
          type: ShiftConstants.SHIFT_GET_MORE_FOR_CUSTOMER_USER_ID_FAILURE,
        });
      }
    },
  );
}

export function* fetchShiftsForCustomerUserIDListener() {
  yield takeLatest(ShiftConstants.SHIFT_GET_ALL_FOR_CUSTOMER_USER_ID, function*(
    ...args
  ) {
    try {
      yield* fetchShiftsForCustomerUserID(...args);
      yield put({
        type: ShiftConstants.SHIFT_GET_ALL_FOR_CUSTOMER_USER_ID_SUCCESS,
      });
    } catch (error) {
      yield put({
        type: ShiftConstants.SHIFT_GET_ALL_FOR_CUSTOMER_USER_ID_FAILURE,
      });
    }
  });
}

export function* fetchShiftsForNeedID(action) {
  try {
    // go off and get all the schedules for a particular needID
    yield put(getSchedulesByParams({ care_need_id: action.payload.needID }));

    const result = yield take([
      ScheduleConstants.SCHEDULE_GET_BY_PARAMS_SUCCESS,
      ScheduleConstants.SCHEDULE_GET_BY_PARAMS_FAILURE,
    ]);

    if (result.type !== ScheduleConstants.SCHEDULE_GET_BY_PARAMS_SUCCESS) {
      throw ScheduleConstants.SCHEDULE_GET_BY_ID_FAILURE;
    }

    //once this comes back we need to go and get all the shifts for each schedule
    const schedules = result.data.results;
    const results = yield all(
      schedules.map(schedule => {
        const params = { schedule_id: schedule.id };
        if (action.payload.starts_after) {
          params['starts_after'] = action.payload.starts_after;
        }
        if (action.payload.starts_before) {
          params['starts_before'] = action.payload.starts_before;
        }
        return call(getPagedShifts, params);
      }),
    );

    for (let i = 0; i < results.length; ++i) {
      if (results[i] !== true) {
        throw ShiftConstants.SHIFT_GET_ALL_FOR_NEED_ID_FAILURE;
      }
    }

    yield put({ type: ShiftConstants.SHIFT_GET_ALL_FOR_NEED_ID_SUCCESS });
  } catch (error) {
    yield put({
      type: ShiftConstants.SHIFT_GET_ALL_FOR_NEED_ID_FAILURE,
    });
  }
}

export function* fetchShiftsForNeedIDListener() {
  yield takeLatest(
    ShiftConstants.SHIFT_GET_ALL_FOR_NEED_ID,
    fetchShiftsForNeedID,
  );
}

export function* fetchShiftsForCarerUserID(action) {
  try {
    const { userID } = action.payload;

    const params = { user_id: userID };
    if (action.payload.starts_after) {
      params['starts_after'] = action.payload.starts_after;
    }
    if (action.payload.starts_before) {
      params['starts_before'] = action.payload.starts_before;
    }
    yield getPagedShifts(params);

    // Select all generated shifts for this user
    const shifts = yield select(selectShiftsByUserID, userID);
    // Get the schedules for these shifts
    const scheduleIDs = [...new Set(shifts.map(shift => shift.schedule_id))];
    yield all(
      scheduleIDs.map(scheduleID => {
        return put(getScheduleById(scheduleID));
      }),
    );

    let schedulesCollected = [];
    while (schedulesCollected.length < scheduleIDs.length) {
      const scheduleResults = yield take([
        ScheduleConstants.SCHEDULE_GET_BY_ID_SUCCESS,
        ScheduleConstants.SCHEDULE_GET_BY_ID_FAILURE,
      ]);

      if (
        scheduleResults.type === ScheduleConstants.SCHEDULE_GET_BY_ID_SUCCESS
      ) {
        schedulesCollected.push(scheduleResults.data);
      } else {
        schedulesCollected.push(null);
      }
    }

    yield all(
      schedulesCollected.map(schedule => {
        if (schedule) {
          return put(getNeedByID(schedule.care_need_id));
        }

        return null;
      }),
    );
    // Get the needs for these schedules
    let needsCollected = [];
    while (needsCollected.length < schedulesCollected.length) {
      const needResults = yield take([
        CareNeedConstants.CARE_NEED_GET_BY_ID_SUCCESS,
        CareNeedConstants.CARE_NEED_GET_BY_ID_FAILURE,
      ]);

      if (needResults.type === CareNeedConstants.CARE_NEED_GET_BY_ID_SUCCESS) {
        needsCollected.push(needResults.data);
      } else {
        needsCollected.push(null);
      }
    }

    yield put({
      type: ShiftConstants.SHIFT_GET_ALL_FOR_CARER_USER_ID_SUCCESS,
    });
  } catch (error) {}
}

export function* fetchShiftsForCarerUserIDListener() {
  yield takeLatest(
    ShiftConstants.SHIFT_GET_ALL_FOR_CARER_USER_ID,
    fetchShiftsForCarerUserID,
  );
}

export function* fetchSchedulesForJobOffers(action) {
  yield put(getSchedulesWithScheduleShiftsByNeedIDAction(action.data.need_id));
}

//TODO LP find out where else this is triggered and see if we can update
export function* fetchSchedulesForJobOffersListener() {
  yield takeEvery(
    [
      JobOfferConstants.JOB_OFFER_GET_BY_ID_SUCCESS,
      JobOfferConstants.JOB_OFFER_GET_BY_REFERENCE_SUCCESS,
    ],
    fetchSchedulesForJobOffers,
  );
}

export function* deleteMultipleShifts(action) {
  try {
    const { shiftIDs } = action.payload;
    let tasksPending = 0;
    yield all(
      shiftIDs.map(id => {
        tasksPending = tasksPending + 1;
        return put(deleteShift(id));
      }),
    );

    while (tasksPending > 0) {
      const getResult = yield take([
        ShiftConstants.SHIFT_DELETE_SUCCESS,
        ShiftConstants.SHIFT_DELETE_FAILURE,
      ]);

      if (getResult.type === ShiftConstants.SHIFT_DELETE_SUCCESS) {
        tasksPending = tasksPending - 1;
      } else {
        return yield put({
          toast: {
            type: 'error',
            public: false,
            title: 'Unable to delete all shifts.',
          },
          type: ShiftConstants.SHIFT_DELETE_MULTIPLE_FAILURE,
        });
      }
    }
  } catch (error) {
    yield put({
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to delete all shifts.',
      },
      type: ShiftConstants.SHIFT_DELETE_MULTIPLE_FAILURE,
    });
  }

  return yield put({
    toast: {
      type: 'success',
      public: false,
      title: 'Amended shifts deleted. You can now edit the schedule.',
    },
    type: ShiftConstants.SHIFT_DELETE_MULTIPLE_SUCCESS,
  });
}

export function* deleteMultipleShiftsListener() {
  yield takeLatest(ShiftConstants.SHIFT_DELETE_MULTIPLE, deleteMultipleShifts);
}

export function* getPagedShifts(params) {
  const uuid = uuidv4();
  let firstShiftResult;
  yield put(getShiftsByParams({ page: 1, ...params }, uuid));
  do {
    firstShiftResult = yield take([
      ShiftConstants.SHIFT_GET_BY_PARAMS_SUCCESS,
      ShiftConstants.SHIFT_GET_BY_PARAMS_FAILURE,
    ]);
  } while (firstShiftResult.tag !== uuid);

  if (firstShiftResult.type !== ShiftConstants.SHIFT_GET_BY_PARAMS_SUCCESS) {
    return false;
  }

  // Get the rest of the pages in parallel
  const getShiftsArgs = [];
  for (let i = 2; i <= firstShiftResult.data.pages; ++i) {
    getShiftsArgs.push({ page: i, ...params });
  }
  return yield call(
    runParallel,
    getShiftsByParams,
    getShiftsArgs,
    ShiftConstants.SHIFT_GET_BY_PARAMS_SUCCESS,
    ShiftConstants.SHIFT_GET_BY_PARAMS_FAILURE,
  );
}
