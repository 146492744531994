// @flow
import * as React from 'react';
import styled from 'styled-components';
import { getScreenClass, screenClassToInt } from 'components/grid/screen-utils';

// $FlowFixMe - Needs a fix for styled components
const StyledFlexItem = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  display: ${props => (props.cols === 0 ? 'none' : 'block')};
  width: ${props => `${props.cols ? (100 / 12) * props.cols : 100}%`};
  padding: 0.8em 1.2em;
  overflow: hidden;
  list-style: none;
  text-align: center;
  word-wrap: normal;
  // Important to prevent any h tags inside from blowing out the formatting
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin: 0;
  }
`;

type Props = {
  children?: React.Node,
  className?: string,
  xs?: ?number,
  sm?: ?number,
  md?: ?number,
  lg?: ?number,
  xl?: ?number,
};

type State = {
  screenClassInt: number,
};

export default class FlexTableItem extends React.Component<Props, State> {
  state = {
    screenClassInt: 0,
  };

  componentDidMount() {
    this.setScreenClass();
    window.addEventListener('resize', this.setScreenClass);
  }

  componetWillUnmount() {
    window.removeEventListener('resize', this.setScreenClass);
  }

  setScreenClass = () => {
    const screenClassInt = screenClassToInt[getScreenClass()];
    this.setState({ screenClassInt });
  };

  convertColsToWidth = (colSettings: Array<?number>) => {
    const cleanedColSettingsArray = colSettings.slice(
      this.state.screenClassInt,
      colSettings.length,
    );
    return cleanedColSettingsArray.find(colValue => !isNaN(parseInt(colValue)));
  };

  render() {
    const { xs, sm, md, lg, xl, children, className } = this.props;
    const cols = this.convertColsToWidth([xl, lg, md, sm, xs]);
    return (
      <StyledFlexItem className={className} cols={cols}>
        {children}
      </StyledFlexItem>
    );
  }
}
