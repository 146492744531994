import { isAdmin } from 'shared/selectors/accountSelectors';
import store from 'store';

const moment = require('moment');

const stateHelper = {
  TIMESHEET_DISPLAY_STATES: {
    new: 'pending',
    submitted: 'submitted',
    approval_needed: 'submitted',
    approved: 'submitted',
    queried: 'queried',
  },

  timesheetDisplayState(timesheet) {
    if (timesheet.charge_status !== 'unpaid') {
      if (timesheet.charge_transferred_at) {
        const transferred = moment(timesheet.charge_transferred_at);
        if (moment().isAfter(transferred)) {
          return 'paid';
        }
      }
      return 'processing_payment';
    }

    if (isAdmin(store.getState())) {
      return timesheet.state;
    }

    return this.TIMESHEET_DISPLAY_STATES[timesheet.state];
  },

  INVOICE_DISPLAY_STATES: {
    awaiting_timesheets: 'pending',
    awaiting_payment: 'unpaid',
    requires_action: 'requires_action',
    paid: 'paid',
    queried: 'queried',
    void: 'void',
  },

  invoiceDisplayState(invoice) {
    if (isAdmin(store.getState())) {
      return invoice.state;
    }

    return this.INVOICE_DISPLAY_STATES[invoice.state];
  },

  JOB_OFFER_DISPLAY_STATES: {
    open: 'open',
    closed: 'job_closed',
    on_hold: 'job_on_hold',
    fulfilled: 'fulfilled',
  },

  BID_DISPLAY_STATES: {
    new: 'awaiting_your_response',
    applied: 'awaiting_customer_response',
    carer_declined: 'you_declined_this_job',
    proposed: 'awaiting_customer_response',
    admin_rejected: 'your_bid_was_unsuccessful',
    accepted: 'awaiting_customer_response',
    customer_rejected: 'your_bid_was_unsuccessful',
    carer_withdrawn: 'your_bid_was_withdrawn',
    approved: 'your_bid_was_successful',
  },

  bidDisplayState(jobOffer, bid = null) {
    if (!bid) {
      return jobOffer.state;
    }
    if (jobOffer.state !== 'open' && bid.state !== 'approved') {
      return this.JOB_OFFER_DISPLAY_STATES[jobOffer.state];
    }

    return this.BID_DISPLAY_STATES[bid.state];
  },

  REFERENCE_DISPLAY_STATES: {
    new: 'new',
    submitted: 'submitted',
    invited: 'request_sent',
    responded: 'responded',
    non_responsive: 'non-responsive',
  },

  inState(state, { carer, customer, admin }) {
    return (
      (customer && customer.includes(state)) ||
      (carer && carer.includes(state)) ||
      (isAdmin(store.getState()) && admin && admin.includes(state))
    );
  },
};

export default stateHelper;
