import React from 'react';
import PropTypes from 'prop-types';

const CalendarIcon = ({ className, width, height }) => (
  <span className={`icon ${className}`}>
    <svg height={height} width={width} version="1.1" viewBox="0 0 44 45">
      <defs />
      <g id="Office" fill="none" stroke="none" strokeWidth="1">
        <g id="calendar-clock" fill="currentColor">
          <g>
            <path
              id="Fill-189"
              d="M32.5,43 C27.262,43 23,38.739 23,33.5 C23,28.262 27.262,24 32.5,24 C37.738,24 42,28.262 42,33.5 C42,38.739 37.738,43 32.5,43 Z M3,43 C2.449,43 2,42.552 2,42 L2,7 C2,6.449 2.449,6 3,6 L8,6 L8,7.896 C8,9.55 9.346,10.896 11,10.896 C12.654,10.896 14,9.55 14,7.896 L14,6 L24,6 L24,7.896 C24,9.55 25.346,10.896 27,10.896 C28.654,10.896 30,9.55 30,7.896 L30,6 L37,6 C37.551,6 38,6.449 38,7 L38,23.404 C36.365,22.51 34.491,22 32.5,22 C26.668,22 21.848,26.367 21.109,32 L18,32 C17.448,32 17,32.448 17,33 L17,37 C17,37.553 17.448,38 18,38 C18.552,38 19,37.553 19,37 L19,34 L21.025,34 C21.187,37.736 23.126,41.016 26.03,43 L3,43 Z M10,3 C10,2.449 10.449,2 11,2 C11.551,2 12,2.449 12,3 L12,7.896 C12,8.448 11.551,8.896 11,8.896 C10.449,8.896 10,8.448 10,7.896 L10,3 Z M26,3 C26,2.449 26.449,2 27,2 C27.551,2 28,2.449 28,3 L28,7.896 C28,8.448 27.551,8.896 27,8.896 C26.449,8.896 26,8.448 26,7.896 L26,3 Z M40,24.802 L40,7 C40,5.346 38.654,4 37,4 L30,4 L30,3 C30,1.346 28.654,0 27,0 C25.346,0 24,1.346 24,3 L24,4 L14,4 L14,3 C14,1.346 12.654,0 11,0 C9.346,0 8,1.346 8,3 L8,4 L3,4 C1.346,4 0,5.346 0,7 L0,42 C0,43.655 1.346,45 3,45 L31,45 C31.109,45 31.21,44.971 31.308,44.938 C31.7,44.979 32.098,45 32.5,45 C38.841,45 44,39.841 44,33.5 C44,30.025 42.444,26.912 40,24.802 Z"
            />
            <path
              id="Fill-190"
              d="M33,33.52 L33,29 C33,28.448 32.552,28 32,28 C31.448,28 31,28.448 31,29 L31,34 C31,34.304 31.138,34.591 31.375,34.782 L36.376,38.782 C36.56,38.929 36.78,39 37,39 C37.293,39 37.583,38.872 37.781,38.625 C38.126,38.194 38.056,37.565 37.624,37.219 L33,33.52"
            />
            <path
              id="Fill-191"
              d="M13,13 L9,13 C8.448,13 8,13.448 8,14 L8,18 C8,18.553 8.448,19 9,19 C9.552,19 10,18.553 10,18 L10,15 L13,15 C13.552,15 14,14.553 14,14 C14,13.448 13.552,13 13,13"
            />
            <path
              id="Fill-192"
              d="M18,19 C18.552,19 19,18.553 19,18 L19,15 L22,15 C22.552,15 23,14.553 23,14 C23,13.448 22.552,13 22,13 L18,13 C17.448,13 17,13.448 17,14 L17,18 C17,18.553 17.448,19 18,19"
            />
            <path
              id="Fill-193"
              d="M27,19 C27.552,19 28,18.553 28,18 L28,15 L31,15 C31.552,15 32,14.553 32,14 C32,13.448 31.552,13 31,13 L27,13 C26.448,13 26,13.448 26,14 L26,18 C26,18.553 26.448,19 27,19"
            />
            <path
              id="Fill-194"
              d="M13,22 L9,22 C8.448,22 8,22.448 8,23 L8,27 C8,27.553 8.448,28 9,28 C9.552,28 10,27.553 10,27 L10,24 L13,24 C13.552,24 14,23.553 14,23 C14,22.448 13.552,22 13,22"
            />
            <path
              id="Fill-195"
              d="M18,28 C18.552,28 19,27.553 19,27 L19,24 L22,24 C22.552,24 23,23.553 23,23 C23,22.448 22.552,22 22,22 L18,22 C17.448,22 17,22.448 17,23 L17,27 C17,27.553 17.448,28 18,28"
            />
            <path
              id="Fill-196"
              d="M13,32 L9,32 C8.448,32 8,32.448 8,33 L8,37 C8,37.553 8.448,38 9,38 C9.552,38 10,37.553 10,37 L10,34 L13,34 C13.552,34 14,33.553 14,33 C14,32.448 13.552,32 13,32"
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
);

CalendarIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

CalendarIcon.defaultProps = {
  className: '',
  height: '26px',
  width: '26px',
};

export default CalendarIcon;
