// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setPasswordAndConfirm } from '_actions/accountActions';
import SetConfirmPassword from 'components/account/SetConfirmPassword';

import type { APIAction } from 'shared/types';

type Props = {
  className: string,
  accountID: string,
  setPasswordAndConfirm: (string, string) => APIAction,
};

function SetConfirmPasswordContainer({
  accountID,
  setPasswordAndConfirm,
  className,
}: Props) {
  function setConfirmPassword(data: { password: string }) {
    return setPasswordAndConfirm(accountID, data.password);
  }

  return (
    <SetConfirmPassword
      setConfirmPassword={setConfirmPassword}
      className={className}
    />
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPasswordAndConfirm,
    },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(SetConfirmPasswordContainer);
