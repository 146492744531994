import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTimesheetSummary } from '_actions/timesheetActions';

import AdminTimesheetSearchContainer from 'containers/admin/AdminTimesheetSearchContainer';
import WeeklySummaryContainer from 'containers/modules/WeeklySummaryContainer';

class UnconnectedAdminTimesheetsOverview extends Component {
  render() {
    return (
      <div className="component-wrapper wide">
        <h1>Timesheets Overview</h1>
        <WeeklySummaryContainer
          category="timesheets"
          title="Timesheets Summary"
          states={['new', 'approval_needed', 'approved', 'queried']}
          filterLink="/admin/timesheets/"
          getSummary={this.props.getTimesheetSummary}
        />
        <AdminTimesheetSearchContainer location={this.props.location} />
      </div>
    );
  }
}

UnconnectedAdminTimesheetsOverview.propTypes = {
  location: PropTypes.shape().isRequired,
  getTimesheetSummary: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTimesheetSummary,
    },
    dispatch,
  );
}

const AdminTimesheetsOverview = connect(
  null,
  mapDispatchToProps,
)(UnconnectedAdminTimesheetsOverview);
export default AdminTimesheetsOverview;
export { UnconnectedAdminTimesheetsOverview };
