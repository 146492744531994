import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import AdminUserShifts from 'components/users/AdminUserShifts';
import { fetchShiftsForCarerUserID } from '_actions/shiftActions';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { selectShiftsByUserID } from 'shared/selectors/shiftsSelectors';
import { selectSchedulesForShifts } from 'shared/selectors/scheduleSelectors';
import { selectNeedsForShifts } from 'shared/selectors/needSelectors';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

class AdminUserShiftsContainer extends Component {
  static propTypes = {
    fetchShiftsForCarerUserID: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    shifts: PropTypes.arrayOf(PropTypes.object),
    shiftNeeds: PropTypes.object,
    shiftSchedules: PropTypes.object,
    taxonomiesByClassification: PropTypes.object,
  };

  static defaultProps = { teams: null, taxonomiesByClassification: null };

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['shift_type'];
  }

  constructor(props) {
    super(props);

    const now = moment();

    this.state = {
      initialTime: now,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const numShifts = Object.keys(props.shifts).length;
    const numSchedules = Object.keys(props.shiftSchedules).length;
    const numNeeds = Object.keys(props.shiftNeeds).length;

    if (numShifts === 0) {
      return { shifts: [] };
    }

    if (!numSchedules || !numNeeds || numSchedules < numNeeds) {
      return {};
    }

    const shiftsWithDetails = [];
    props.shifts.forEach(shift => {
      const schedule = props.shiftSchedules[shift.schedule_id];
      if (
        !props.shiftNeeds ||
        !schedule ||
        !props.shiftNeeds[schedule.care_need_id]
      ) {
        return false;
      }
      const need = props.shiftNeeds[schedule.care_need_id];
      const needIndex = Object.keys(props.shiftNeeds).indexOf(
        schedule.care_need_id,
      );
      shiftsWithDetails.push({
        ...shift,
        schedule,
        need,
        needIndex,
        shiftTypes: props.taxonomiesByClassification.shift_type,
      });
      return true;
    });

    return {
      shifts: shiftsWithDetails,
    };
  }

  fetchCarerShifts(date) {
    this.props.fetchShiftsForCarerUserID({
      userID: this.props.user.id,
      starts_after: date
        .clone()
        .startOf('month')
        .startOf('isoWeek')
        .format(),
      starts_before: date
        .clone()
        .endOf('month')
        .endOf('isoWeek')
        .format(),
    });
  }

  componentDidMount() {
    this.fetchCarerShifts(this.state.initialTime.clone());
  }

  onNavigate = date => {
    this.fetchCarerShifts(moment(date));
  };

  render() {
    const { taxonomiesByClassification } = this.props;

    if (this.state.shifts === null || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminUserShifts
        user={this.props.user}
        shifts={this.state.shifts}
        onNavigate={this.onNavigate}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const shifts = selectShiftsByUserID(state, props.user.id);
  const shiftSchedules = selectSchedulesForShifts(state, shifts);
  const shiftNeeds = selectNeedsForShifts(state, shifts);
  return {
    shiftNeeds,
    shiftSchedules,
    shifts,
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      AdminUserShiftsContainer.TAXONOMY_CLASSIFICATIONS,
    ),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchShiftsForCarerUserID,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserShiftsContainer);
