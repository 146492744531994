// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateAccount } from '_actions/accountActions';
import { uploadProfileImage } from '_actions/carerActions';

import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

import AdminUserPersonal from 'components/users/AdminUserPersonal';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import type {
  ProfileDataType,
  TaxonomiesClassificationType,
  UserType,
} from 'shared/types';

const TAXONOMY_CLASSIFICATIONS = ['care_for', 'user_title', 'gender'];

type Props = {
  profile: ProfileDataType,
  taxonomiesByClassification: TaxonomiesClassificationType,
  updateAccount: Object => Object,
  uploadProfileImage: (string, string, Object) => Object,
  user: UserType,
};

function AdminUserPersonalContainer({
  profile,
  taxonomiesByClassification,
  updateAccount,
  uploadProfileImage,
  user,
}: Props) {
  const updateUserAccount = data => {
    const id = user.id;
    updateAccount({ id, ...data });
  };

  const uploadProfileImageData = data => {
    if (data.target.files.length > 0) {
      const formData = new FormData();
      formData.append('profile_image', data.target.files[0]);
      uploadProfileImage(user.id, profile.id, formData);
    }
  };

  if (!taxonomiesByClassification) {
    return <LoadingPlaceholder />;
  }

  return (
    <AdminUserPersonal
      user={user}
      profile={profile}
      updateAccount={updateUserAccount}
      uploadProfileImage={uploadProfileImageData}
      taxonomiesByClassification={taxonomiesByClassification}
    />
  );
}

const mapStateToProps = (state, props) => ({
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    TAXONOMY_CLASSIFICATIONS,
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateAccount,
      uploadProfileImage,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserPersonalContainer);
