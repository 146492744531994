import ScheduleHoldConstants from 'shared/constants/ScheduleHoldConstants';
import moment from 'moment';

const initialState = {};

/*It is highly unlikely we'll ever need a hold without the schedule it's for
so we're storing against the schedule id.
A schedule hold will deactivate the shifts in that schedule for a set period of time.
Can be past, present, or future */

function addMultipleScheduleHolds(action) {
  return action.data.results.reduce((accumulator, result) => {
    return addScheduleHold(accumulator, result);
  }, {});
}

function addScheduleHold(state, hold) {
  if (!state[hold.schedule_id]) {
    state[hold.schedule_id] = [];
  }
  state[hold.schedule_id].push(hold);
  state[hold.schedule_id].sort((a, b) => {
    return moment(a.end).isBefore(moment(b.end)) ? -1 : 1;
  });

  return state;
}

function deleteScheduleHold(state, scheduleHold) {
  const newState = { ...state };
  const scheduleHolds = newState[scheduleHold.schedule_id];

  if (scheduleHolds) {
    scheduleHolds.splice(scheduleHolds.indexOf(scheduleHold), 1);
  }

  if (!scheduleHold.length) {
    delete newState[scheduleHold.schedule_id];
  }

  return newState;
}

function scheduleHold(state = initialState, action) {
  switch (action.type) {
    case ScheduleHoldConstants.SCHEDULE_HOLD_GET_BY_PARAMS_SUCCESS:
      return {
        ...state,
        ...addMultipleScheduleHolds(action),
      };
    case ScheduleHoldConstants.SCHEDULE_HOLD_DELETE_SUCCESS:
      return deleteScheduleHold(state, action.scheduleHold);
    case ScheduleHoldConstants.SCHEDULE_HOLD_CREATE_SUCCESS:
    case ScheduleHoldConstants.SCHEDULE_HOLD_MODIFY_SUCCESS:
    case ScheduleHoldConstants.SCHEDULE_HOLD_GET_BY_ID_SUCCESS:
      const newState = deleteScheduleHold(state, action.data);
      return addScheduleHold(newState, action.data);
    default:
      return state;
  }
}

export default scheduleHold;
