// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  createDiscountAndRefresh,
  searchDiscounts,
  updateDiscount,
} from '_actions/discountActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AdminDiscounts from 'components/admin/discounts/AdminDiscounts';
import DiscountPropType from 'proptypes/DiscountPropType';
import {
  getDiscountResults,
  getDiscountSearch,
} from 'shared/selectors/discountSelectors';

type Props = {
  createDiscountAndRefresh: (
    DiscountPropType,
    { void: ?boolean, page: number },
  ) => void,
  discounts: Array<DiscountPropType>,
  location: Object,
  search: Object,
  searchDiscounts: ({ void: ?boolean, page: number }) => void,
  updateDiscount: (string, DiscountPropType) => void,
};

const AdminDiscountsContainer = ({
  createDiscountAndRefresh,
  discounts,
  location,
  search,
  searchDiscounts,
  updateDiscount,
}: Props) => {
  useEffect(() => {
    if (location.query.page) {
      startSearch(location.query.page);
    } else {
      startSearch();
    }
  }, [location]);

  const startSearch = page => {
    searchDiscounts({
      void: null,
      page: page || location.query.page || 1,
    });
  };

  const startCreateDiscount = discount => {
    createDiscountAndRefresh(discount, {
      void: null,
      page: location.query.page || 1,
    });
  };

  const startUpdateDiscount = data => {
    const discountId = data.id;
    const copy = { ...data };
    copy.id = undefined;
    copy.users = undefined;
    copy.created_at = undefined;
    updateDiscount(discountId, copy);
  };

  if (!discounts || !search) {
    return <LoadingPlaceholder />;
  }

  return (
    <AdminDiscounts
      discounts={discounts}
      search={search}
      location={location}
      createDiscount={startCreateDiscount}
      updateDiscount={startUpdateDiscount}
    />
  );
};

const mapStateToProps = state => ({
  discounts: getDiscountResults(state),
  search: getDiscountSearch(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createDiscountAndRefresh,
      searchDiscounts,
      updateDiscount,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminDiscountsContainer);
