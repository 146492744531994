import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Paginator from 'components/global/Paginator';
import ChargeResultRow from 'components/admin/ChargeResultRow';

class ChargeResults extends Component {
  render() {
    return (
      <div className="old-row">
        <div className="module">
          <h2>Charges</h2>
          <table className="charges">
            <thead>
              <tr>
                <th>Created</th>
                <th>State</th>
                <th>Customer</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Payee</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {this.props.charges.map(charge => (
                <ChargeResultRow
                  key={charge.id}
                  charge={charge}
                  customer={charge.consumer_name || ''}
                  payee={charge.carer_name || 'SuperCarers'}
                />
              ))}
            </tbody>
          </table>
          <Paginator
            currentPage={this.props.search.pagination.page}
            totalPages={this.props.search.pagination.pages}
            location={this.props.location}
          />
        </div>
      </div>
    );
  }
}

ChargeResults.propTypes = {
  search: PropTypes.shape().isRequired,
  charges: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  location: PropTypes.shape().isRequired,
};

export default ChargeResults;
