import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Currency from 'components/modules/Currency';
import Hours from 'components/modules/Hours';

class ShiftRateCell extends Component {
  static propTypes = {
    shift: PropTypes.shape().isRequired,
    liveIn: PropTypes.bool.isRequired,
    rates: PropTypes.shape().isRequired,
    view: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  render() {
    let rate;
    const shiftType =
      this.props.shift.type === 'half_livein'
        ? 'livein'
        : this.props.shift.type;
    if (this.props.rates[shiftType]) {
      rate = (
        <span>
          @ <Currency value={this.props.rates[shiftType]} />x{' '}
          {this.props.shift.total.holiday_multiplier}
        </span>
      );
    } else {
      rate = 'EXCEPTIONAL';
    }

    return (
      <td data-heading="Info" className="number">
        {!this.props.liveIn && (
          <Hours minutes={this.props.shift.total.minutes_total} />
        )}
        {this.props.isAdmin &&
          this.props.liveIn &&
          `${this.props.shift.total.livein_days} day(s)`}
        {this.props.isAdmin && this.props.view !== 'customer' && (
          <div
            title="Rate and holiday multiplier."
            className="supplementary-info"
          >
            {rate}
          </div>
        )}
      </td>
    );
  }
}

export default ShiftRateCell;
