export const selectReferences = state => state.references;
export const selectReferencesSearch = state => state.search.references;
export const selectReferencesByCarerID = (state, carerID) =>
  state.references[carerID] ? state.references[carerID] : null;

export const selectReferenceByID = (state, referenceID) => {
  let ref;
  Object.keys(state.references).forEach(carerID => {
    if (!ref) {
      const carerReferences = state.references[carerID];
      const matches = carerReferences.filter(item => item.id === referenceID);
      if (matches.length) {
        ref = matches[0];
      }
    }
  });
  return ref;
};
