import Module, {
  ModuleHeader,
  ModuleMeta,
} from 'components/global/layout/Module';
import StandardTable from 'components/global/layout/StandardTable';
import Currency from 'components/modules/Currency';
import StripeLink from 'components/modules/stripe/StripeLink';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Row, Col } from 'components/grid';
import styled from 'styled-components';

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

class TransactionTable extends Component {
  static propTypes = {
    charge: PropTypes.shape().isRequired,
    refunds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  render() {
    const { charge } = this.props;
    const total = {
      customer_total: -charge.unit,
      application_total: charge.application_fee,
      carer_total: charge.unit - charge.application_fee,
    };
    this.props.refunds.forEach(refund => {
      ['customer_total', 'application_total', 'carer_total'].forEach(
        // eslint-disable-next-line no-return-assign
        party => (total[party] += refund.totals[party]),
      );
    });

    return (
      <Module>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <StyledHeader>Transactions</StyledHeader>
            </Col>
            {this.props.charge.fulfilment_type === 'stripe' && (
              <Col xs={12} md={6}>
                <ModuleMeta>
                  <StripeLink href={`payments/${charge.stripe_id}`} />
                </ModuleMeta>
              </Col>
            )}
          </Row>
        </Container>
        <StandardTable>
          <thead>
            <tr>
              <th />
              <th>Created</th>
              <th className="numeric">Customer</th>
              <th className="numeric">Carer</th>
              <th className="numeric">Commission</th>
            </tr>
          </thead>
          <tbody>
            <TransactionTableRow
              transaction="Charge"
              createdAt={charge.created_at}
              customer={-charge.unit}
              application={charge.application_fee}
              carer={charge.unit - charge.application_fee}
            />
            {this.props.refunds
              .sort((a, b) => moment(a.created_at) - moment(b.created_at))
              .map(refund => (
                <TransactionTableRow
                  key={refund.id}
                  transaction="Refund"
                  createdAt={refund.created_at}
                  customer={refund.totals.customer_total}
                  application={refund.totals.application_total}
                  carer={refund.totals.carer_total}
                />
              ))}
            <TransactionTableRow
              transaction="Total"
              customer={total.customer_total}
              application={total.application_total}
              carer={total.carer_total}
            />
          </tbody>
        </StandardTable>
      </Module>
    );
  }
}

const TransactionTableRow = ({
  transaction,
  createdAt,
  customer,
  application,
  carer,
}) => {
  const created = createdAt && moment(createdAt).format('DD/MM/YY HH:mm');
  return (
    <tr className={transaction === 'Total' && 'total'}>
      <td data-heading="Transaction">{transaction}</td>
      <td data-heading="Created">{created}</td>
      <td data-heading="Customer" className="number">
        <Currency value={customer} />
      </td>
      <td data-heading="Carer" className="number">
        <Currency value={carer} />
      </td>
      <td data-heading="Commission" className="number">
        <Currency value={application} />
      </td>
    </tr>
  );
};

TransactionTableRow.propTypes = {
  transaction: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  customer: PropTypes.number.isRequired,
  application: PropTypes.number.isRequired,
  carer: PropTypes.number.isRequired,
};

TransactionTableRow.defaultProps = {
  createdAt: null,
};

export default TransactionTable;
