// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';

import BlueDot from 'images/BlueDot.png';

const ItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 10px 5px;
`;

const Number = styled.div`
  background: ${props => props.showBackground && `url(${BlueDot}) no-repeat`};
  background-position: 0 -5px;
  background-size: contain;
  height: 40px;
  text-align: center;
  min-width: 40px;
  color: #4a4a4a;
  font-weight: 700;
`;

type Props = {
  children: Array<Node>,
  showBackground?: boolean,
};

const NumberedList = ({ children, showBackground = true }: Props) => {
  const noNulls = children.filter(child => child);
  return (
    <>
      {noNulls.map((listItem, index) => (
        <ItemWrapper key={index + 1}>
          <Number showBackground={showBackground}>{index + 1}</Number>
          {listItem}
        </ItemWrapper>
      ))}
    </>
  );
};

export default NumberedList;
