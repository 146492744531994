import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Row, Col } from 'components/grid';

import Module, { ModuleHeader } from 'components/global/layout/Module';
import QuestionnairePrompt from 'components/carers/questionnaireSections/QuestionnairePrompt';
import CarerHomeDashboard from 'components/carers/CarerHomeDashboard';

class CarerHome extends Component {
  render() {
    const { carer, profileComplete, user } = this.props;

    const profileQueried = carer.profile_details.approval_state === 'queried';

    return (
      <section>
        {!profileComplete && <QuestionnairePrompt />}

        {profileQueried && (
          <Module>
            <ModuleHeader>Profile Information Required</ModuleHeader>
            <Container>
              <Row>
                <Col xs={12} md={10}>
                  <p>
                    We require more information from you regarding your recent
                    profile changes. For more information please check your
                    email for a message with the subject line:{' '}
                    <strong>“A question about your profile update”</strong>.
                  </p>
                </Col>
              </Row>
            </Container>
          </Module>
        )}

        <Module>
          <ModuleHeader>Welcome</ModuleHeader>
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <CarerHomeDashboard user={user} />
              </Col>
            </Row>
          </Container>
        </Module>

      </section>
    );
  }
}

CarerHome.propTypes = {
  carer: PropTypes.shape(),
  profileComplete: PropTypes.bool.isRequired,
  user: PropTypes.shape().isRequired,
};

CarerHome.defaultProps = {
  carer: null,
  timesheets: null,
};

export default CarerHome;
