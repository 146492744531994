import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ButtonLink from 'components/buttons/ButtonLink';
import StatusIndicator from 'components/global/StatusIndicator';
import stateHelper from 'components/helpers/stateHelper';

class InvoiceTableRow extends Component {
  render() {
    const { invoice } = this.props;
    const weekCommencing = moment(invoice.start_date)
      .startOf('isoweek')
      .format('dddd MMMM Do, YYYY');
    const state = stateHelper.invoiceDisplayState(invoice);
    return (
      <tr>
        <td data-heading="Week">{weekCommencing}</td>
        <td data-heading="ID">{invoice.slug}</td>
        <td data-heading="Status">
          <StatusIndicator status={state} />
        </td>
        <td>
          <ButtonLink to={`/invoices/${invoice.id}/`}>View</ButtonLink>
        </td>
      </tr>
    );
  }
}

InvoiceTableRow.propTypes = {
  invoice: PropTypes.shape({
    start_date: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default InvoiceTableRow;
