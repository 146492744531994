import React from 'react';
import styled from 'styled-components';

const StyledAsteriskIcon = styled.div`
  font-size: 2.2rem;
  display: inline-block;
  color: #df6150;
  transition: all 1s;

  &.complete {
    color: green;
    visibility: hidden;
    opacity: 0;
    transition: all 1s;
  }
`;

const AsteriskIcon = ({ complete }) => (
  <StyledAsteriskIcon className={complete && 'complete'}>*</StyledAsteriskIcon>
);

export default AsteriskIcon;
