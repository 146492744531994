import PropTypes, { oneOfType, arrayOf } from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';

const StyledMultiselect = styled.div`
  background-color: ${props => props.theme.colors.lightestGrey};
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"><g transform="translate(8.131728, 2.131728) rotate(-45.000000) translate(-8.131728, -2.131728) translate(2.631728, -3.868272)" stroke="%234A4A4A" stroke-width="1.5"><path d="M0.75,0.6 L0.75,10.2"></path><path d="M10.35,10.2496938 L0.75,10.2496938"></path></g></g></svg>');
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 16px 100%;
  border: 1px solid;
  border-color: ${props => props.theme.colors.uiGrey};
  border-radius: 6px;
  color: ${props => props.theme.colors.formText};
  font-size: 18px;
  min-width: 120px;
  position: relative;
  user-select: none;
  width: 100%;

  > span.current-selection {
    cursor: pointer;
    display: block;
    line-height: 23px;
    padding: 15px 35px 15px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > div {
    display: none;
  }

  &.isOpen {
    > div {
      display: block;
    }
  }
`;

const StyledMultiselectPanel = styled.div`
  background-color: ${props => props.theme.colors.lightestGrey};
  border: 1px solid;
  border-color: ${props => props.theme.colors.uiGrey};
  border-radius: 6px;
  min-width: 100%;
  padding: 15px 10px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: ${props => props.theme.zIndexes.menu};
  max-height: 50vh;
  overflow-y: scroll;
  margin-bottom: 20px;

  > label {
    cursor: pointer;
    display: block;
    white-space: nowrap;
  }
`;

class MultiSelect extends Component {
  static propTypes = {
    customPlaceholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.shape().isRequired,
    value: oneOfType([arrayOf(PropTypes.string), PropTypes.string]),
    className: PropTypes.string,
    customOnChange: PropTypes.func,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    customPlaceholder: null,
    customOnChange: null,
    onBlur: () => {},
    onFocus: () => {},
    name: '',
    value: [],
  };

  state = {
    isOpen: false,
  };

  onChange = e => {
    if (this.props.customOnChange) {
      return this.props.customOnChange.call(this, e);
    }
    e.persist();
    e.stopPropagation();
    const { value, onChange } = this.props;
    const newValue = [...value];
    if (e.target.checked) {
      newValue.push(e.target.value);
    } else {
      newValue.splice(newValue.indexOf(e.target.value), 1);
    }

    return onChange(newValue);
  };

  handleClickOutside = evt => {
    const { value, onFocus } = this.props;
    onFocus(value);
    this.setState({ isOpen: false });
  };

  handleClick = e => {
    e.preventDefault();
    const { value, onFocus, onBlur } = this.props;
    if (this.state.isOpen) {
      onFocus(value);
      this.setState({ isOpen: false });
    } else {
      onBlur(value);
      this.setState({ isOpen: true });
    }
  };

  render() {
    const { customPlaceholder, value, className, name, options } = this.props;

    let selected;
    if (value.length === 0) {
      selected = 'Please select';
    } else {
      selected = value.map(chosen => options[chosen]).join(', ');
    }
    return (
      <StyledMultiselect
        className={`${className} ${this.state.isOpen ? 'isOpen' : ''}`}
        id={`select-${name}`}
        name={name}
      >
        <span
          role="button"
          className="current-selection"
          onClick={this.handleClick}
        >
          {customPlaceholder || selected}
        </span>
        <StyledMultiselectPanel>
          {Object.entries(options).map(([key, optionLabel]) => (
            <label htmlFor={`${name}_${key}`} key={key}>
              <input
                id={`${name}_${key}`}
                value={key}
                type="checkbox"
                onChange={this.onChange}
                onFocus={e => e.stopPropagation()}
                onBlur={e => e.stopPropagation()}
                checked={value.includes(key)}
              />
              {optionLabel}
            </label>
          ))}
        </StyledMultiselectPanel>
      </StyledMultiselect>
    );
  }
}

export default onClickOutside(MultiSelect);
