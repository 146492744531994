import { currencyFormat } from 'components/helpers/currency';
import { isValidNumber } from 'libphonenumber-js';
import toTitleCase from 'components/helpers/toTitleCase';
import moment from 'moment';
import SuperCarersClient from 'clients/SuperCarersClient';

export const required = value =>
  value ? undefined : 'This field is required.';

export const requiredBoolean = value =>
  value === true || value === false ? undefined : 'This field is required.';

export const requiredList = value =>
  value && value.length >= 1
    ? undefined
    : 'This field is required, please enter at least one response.';

export const requiredEmail = value =>
  value ? undefined : 'Please enter your email address.';

export const requiredPassword = value =>
  value ? undefined : 'Please enter your password.';

export const validEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(value)
    ? 'Please enter a valid email address.'
    : undefined;

export const validGUID = value =>
  value && /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{4}-[89ab][0-9a-f]{4}-[0-9a-f]{12}$/i.test(value)
    ? 'Please enter a valid identifier.'
    : undefined;

export const validDate = value => {
  if (!value) {
    return undefined;
  }

  const dateComponents = value.split('T')[0].split('-');

  if (dateComponents.some(date => isNaN(parseInt(date)))) {
    return 'Please select a valid date';
  }

  return undefined;
};

export const minimumShiftTime = (value, allValues) => {
  const shiftDuration = moment(value).diff(allValues.start, 'minutes');
  if (Math.abs(shiftDuration) < 15) {
    return "Shifts can't be shorter than 15 minutes";
  } else if (shiftDuration < 0) {
    return 'End date must be after start date';
  }

  return undefined;
};

export const matchesPassword = (value, allValues) =>
  value === allValues.password
    ? undefined
    : 'Please ensure your passwords match.';

export const bidsAvailabilityFormValidator = ({ availability }) => {
  const anyAvailable = Object.values(availability).some(weekAvailability =>
    []
      .concat(...Object.values(weekAvailability))
      .some(shift => shift.available),
  );
  return anyAvailable ? {} : { _error: 'Please select at least one shift.' };
};

export const positive = value =>
  (!value && value !== 0) || value < 0 ? 'Cannot be negative.' : undefined;

export const withinLimits = (
  minimum,
  maximum,
  format = value => value,
) => value => {
  if (value < minimum) {
    return `Must be at least ${format(minimum)}.`;
  }
  if (value > maximum) {
    return `Must be at most ${format(maximum)}.`;
  }
  return undefined;
};

export const rateLimitValidators = rateLimits =>
  Object.keys(rateLimits).reduce((accumulator, shiftType) => {
    const rateLimit = rateLimits[shiftType];
    // eslint-disable-next-line no-param-reassign
    accumulator[shiftType] = [
      required,
      withinLimits(rateLimit.minimum, rateLimit.maximum, currencyFormat),
    ];
    return accumulator;
  }, {});

export const englishRequired = languages => {
  const englishSet = (languages || []).some(
    chosen => chosen.language === 'eng',
  );
  return englishSet ? undefined : 'Please enter your English level.';
};

export const minimumWords = min => value => {
  const count = value.trim().split(' ').length;
  return count >= min ? undefined : `Please write at least ${min} words.`;
};

export const maxChars = max => value => {
  const count = value.trim().length;
  return count > max ? `Must be less than ${max} characters` : undefined;
};

export const conditionalValidator = (condition, validator) => {
  if (!condition) {
    return () => undefined;
  }
  return validator;
};

export const emailOrPhone = (value, allValues) =>
  allValues.email || allValues.phone_number
    ? undefined
    : 'You must enter an email or a phone number.';

export const asyncValidPostcode = async values => {
  if (!values.postcode || values['not-uk']) {
    return;
  }

  try {
    await SuperCarersClient.get(`/postcode/validate/${values.postcode}/`);
  } catch (e) {
    return {
      postcode: 'Please enter a valid postcode',
      _error: 'Please enter a valid postcode',
    };
  }
};

export const validPhoneNumber = value => {
  if (!value) {
    return undefined;
  }

  const invalidString =
    'Invalid phone number. Please include the country code (e.g. +44) if it is not a UK number.';
  let number = value;
  const firstChar = number.slice(0, 1);
  if (firstChar !== '+' && firstChar === '0' && !isValidNumber(number)) {
    number = `44${number.slice(1)}`;
  }

  if (firstChar !== '+') {
    number = `+${number}`;
  }

  return isValidNumber(number) ? undefined : invalidString;
};

export const requiredDaySchedule = value => {
  if (!value || !Object.keys(value).length) {
    return 'Please select at least one day.';
  }

  const errors = Object.entries(value).reduce((accum, [day, daySelection]) => {
    if (
      Array.isArray(daySelection) &&
      daySelection.some(shift => !shift.start || !shift.end)
    ) {
      return accum.concat(day);
    }

    return accum;
  }, []);

  return errors.length
    ? `Please make sure a start and end time has been selected for
    ${errors.map(day => toTitleCase(day)).join(', ')}`
    : undefined;
};

export const pastStartDate = value => {
  if (
    value &&
    moment(value.start).isSameOrBefore(moment().startOf('day')) &&
    !value.deleting
  ) {
    return 'Start date must not be in the past';
  }

  return undefined;
};
