import StandardInput from 'components/forms/StandardInput';
import PercentField from 'components/forms/PercentField';
import React from 'react';

import StandardTable from 'components/global/layout/StandardTable';
import { Field } from 'redux-form';

import { positive } from 'validators';

const AdminRatesSettings = () => (
  <StandardTable>
    <thead>
      <tr>
        <th>Setting</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Bank Holiday Multiplier</td>
        <td>
          <Field
            component={StandardInput}
            type="number"
            name="holiday_multiplier"
          />
        </td>
      </tr>
      <tr>
        <td>Exceptional Shift Commission</td>
        <td>
          <PercentField
            component={StandardInput}
            name="exceptional_commission"
            validate={[positive]}
          />
        </td>
      </tr>
    </tbody>
  </StandardTable>
);

export default AdminRatesSettings;
