import React, { Component } from 'react';
import Features from 'components/admin/Features';
import AppFooter from 'components/global/AppFooter';
import AppHeader from 'components/global/AppHeader';
import AppHelpers from 'components/global/AppHelpers';
import AppMain from 'components/global/AppMain';

import BreadcrumbsContainer from 'components/global/BreadcrumbsContainer';
import ImpersonationContainer from 'containers/admin/ImpersonationContainer';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'components/grid';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import styled from 'styled-components';

// "Global styles"
const StyledContainer = styled(Container)`
  background: ${props => props.theme.colors.lightGrey};
  color: ${props => props.theme.colors.formText};
  font-family: ${props => props.theme.typography.fontStack};
  font-size: ${props => props.theme.typography.defaultFontSize};
  line-height: ${props => props.theme.typography.defaultLineHeight};

  margin-left: 0;

  strong {
    font-weight: bold;
  }

  h5 {
    margin-bottom: 10px;
  }

  div[style] > p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  main > section > div + h2 {
    margin-left: 20px;
    margin-top: 20px;
    @media (min-width: 768px) {
      margin-left: inherit;
      margin-top: 40px;
    }
  }
`;

const BreadcrumbRow = styled(Row)`
  padding: 32px 0 !important;
  @media (min-width: 768px) {
    padding: 20px 0 !important;
  }
`;

export class App extends Component {
  render() {
    const {
      children,
      features,
      impersonating,
      impersonator,
      isAdmin,
      isAgent,
      location,
      user,
      taxonomies,
    } = this.props;

    if (!taxonomies || Object.keys(taxonomies).length === 0) {
      return <LoadingPlaceholder />;
    } else {
      return (
        <StyledContainer
          className="home"
          fluid
          gutteGrWidth={20}
          outerutter={0}
        >
          <Row>
            <Col sm={12} md={3} lg={2} style={{ minHeight: '26px' }}>
              <AppHeader user={user} isAgent={isAgent} location={location} />
            </Col>
            <Col sm={12} md={9} lg={10} offset={{ md: 0 }}>
              <Container outerGutter={{ xs: 0, md: 20 }} rowGutter={0}>
                <BreadcrumbRow>
                  <Col sm={12} md={6}>
                    <BreadcrumbsContainer />
                  </Col>
                  <Col sm={12} md={6}>
                    <AppHelpers user={user} impersonating={impersonating} />
                  </Col>
                </BreadcrumbRow>
                <Row>
                  <Col md={12}>
                    <AppMain>{children}</AppMain>
                  </Col>
                </Row>
              </Container>
              <AppFooter>
                {impersonating && !!user && impersonator && (
                  <ImpersonationContainer
                    user={user}
                    impersonator={impersonator}
                  />
                )}
                {Object.keys(features).length > 0 &&
                  (isAdmin || impersonating) && (
                    <Features features={features} />
                  )}
              </AppFooter>
            </Col>
          </Row>
        </StyledContainer>
      );
    }
  }
}

App.propTypes = {
  features: PropTypes.shape().isRequired,
  impersonator: PropTypes.shape(),
  impersonating: PropTypes.bool.isRequired,
  user: PropTypes.shape(),
  isAdmin: PropTypes.bool.isRequired,
  isAgent: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  location: PropTypes.shape().isRequired,
  taxonomies: PropTypes.shape(),
};

App.defaultProps = {
  user: null,
  impersonator: null,
  taxonomies: null,
};

export default App;
