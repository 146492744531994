// @flow
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getNeedsByUserId } from '_actions/needActions';
import { getSingleNeedForUserByID } from 'shared/selectors/needSelectors';

import { InternalLink } from 'components/global/primarynavigation/NavigationLink';

import { hasRole, isFranchiseMember } from 'shared/helpers/accountHelpers';

import type { NeedType, UserType } from 'shared/types';

type Props = {
  need: NeedType,
  path: string,
  user: UserType,
  getNeedsByUserId: string => void,
};
export class PrimaryNavigationLinks extends Component<Props> {
  componentDidMount() {
    if (hasRole(this.props.user, 'Customer')) {
      this.props.getNeedsByUserId(this.props.user.id);
    }
  }

  render() {
    const { path, user, need } = this.props;

    if (!user) {
      return null;
    }

    if (hasRole(user, 'Admin', 'Recruiter')) {
      return (
        <>
          <InternalLink currentPath={path} path="/admin/" label="Admin" />
          <InternalLink
            currentPath={path}
            path="/admin/carers/"
            label="Carer Search"
          />
        </>
      );
    }

    if (hasRole(user, 'Agent')) {
      return (
        <>
          <InternalLink
            currentPath={path}
            path="/admin/needs/new/"
            label="New Need"
          />
        </>
      );
    }

    if (hasRole(user, 'Carer')) {
      return (
        <>
          <InternalLink currentPath={path} path="/home/" label="Home" />
          <InternalLink
            currentPath={path}
            path={`/carers/${user.id}/`}
            label="Profile"
          />
          <InternalLink currentPath={path} path="/jobs/" label="Jobs" />
          <InternalLink
            currentPath={path}
            path="/work-history/"
            label="Work History"
          />
          <InternalLink
            currentPath={path}
            path="/references/"
            label="References"
          />
        </>
      );
    }

    if (hasRole(user, 'Customer') && need) {
      return (
        <>
          <InternalLink currentPath={path} path="/home/" label="Home" />
        </>
      );
    }

    if (isFranchiseMember(user)) {
      return (
        <>
          <InternalLink currentPath={path} path="/admin/needs/" label="Care Needs" />
        </>
      );
    }

    return null;
  }
}

function mapStateToProps(state, props) {
  return {
    need: getSingleNeedForUserByID(state, props.user.id),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getNeedsByUserId,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrimaryNavigationLinks);
