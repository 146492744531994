import keyMirror from 'keymirror';

export default keyMirror({
  CARER_CREATE: null,
  CARER_CREATE_SUCCESS: null,
  CARER_CREATE_FAILURE: null,

  CARER_UPDATE: null,
  CARER_UPDATE_SUCCESS: null,
  CARER_UPDATE_FAILURE: null,

  CARER_UPDATE_PROFILE: null,
  CARER_UPDATE_PROFILE_SUCCESS: null,
  CARER_UPDATE_PROFILE_FAILURE: null,

  CARER_PARTIAL_UPDATE_PROFILE: null,
  CARER_PARTIAL_UPDATE_PROFILE_SUCCESS: null,
  CARER_PARTIAL_UPDATE_PROFILE_FAILURE: null,

  ADMIN_CARER_UPDATE_PROFILE: null,
  ADMIN_CARER_UPDATE_PROFILE_SUCCESS: null,
  ADMIN_CARER_UPDATE_PROFILE_FAILURE: null,

  CARER_GET_MULTIPLE: null,
  CARER_GET_MULTIPLE_SUCCESS: null,
  CARER_GET_MULTIPLE_FAILURE: null,

  CARER_GET_PROFILE: null,
  CARER_GET_PROFILE_SUCCESS: null,
  CARER_GET_PROFILE_FAILURE: null,

  CARER_GET_BY_ID: null,
  CARER_GET_BY_ID_SUCCESS: null,
  CARER_GET_BY_ID_FAILURE: null,

  CARER_GET_INDUCTION_SLOT: null,
  CARER_GET_INDUCTION_SLOT_SUCCESS: null,
  CARER_GET_INDUCTION_SLOT_FAILURE: null,

  CARER_GET_CONFLICTS: null,
  CARER_GET_CONFLICTS_SUCCESS: null,
  CARER_GET_CONFLICTS_FAILURE: null,

  CARER_GET_CONFLICTS_WITH_BIDS: null,
  CARER_GET_CONFLICTS_WITH_BIDS_SUCCESS: null,
  CARER_GET_CONFLICTS_WITH_BIDS_FAILURE: null,

  CARER_FIND: null,
  CARER_FIND_SUCCESS: null,
  CARER_FIND_FAILURE: null,

  CARER_SEARCH: null,
  CARER_SEARCH_SUCCESS: null,
  CARER_SEARCH_FAILURE: null,

  CARER_SEARCH_MORE: null,
  CARER_SEARCH_MORE_SUCCESS: null,
  CARER_SEARCH_MORE_FAILURE: null,

  CARER_MESSAGE_JOB_SUCCESS: null,
  CARER_MESSAGE_JOB_FAILURE: null,
  CARER_MESSAGE_JOB: null,

  CARER_APPLICANT_INVITE_SUCCESS: null,
  CARER_APPLICANT_INVITE_FAILURE: null,
  CARER_APPLICANT_INVITE: null,

  CARER_DISCONNECT_STRIPE_SUCCESS: null,
  CARER_DISCONNECT_STRIPE_FAILURE: null,
  CARER_DISCONNECT_STRIPE: null,

  CARER_UPDATE_PROFILE_IMAGE_SUCCESS: null,
  CARER_UPDATE_PROFILE_IMAGE_FAILURE: null,
  CARER_UPDATE_PROFILE_IMAGE: null,

  CARER_PROFILE_SEARCH_SUCCESS: null,
  CARER_PROFILE_SEARCH_FAILURE: null,
  CARER_PROFILE_SEARCH: null,

  CARER_PROFILE_QUERY_SUCCESS: null,
  CARER_PROFILE_QUERY_FAILURE: null,
  CARER_PROFILE_QUERY: null,

  CARER_PROFILE_APPROVE_SUCCESS: null,
  CARER_PROFILE_APPROVE_FAILURE: null,
  CARER_PROFILE_APPROVE: null,

  CLEAR_DRAFT_PROFILE: null,
});
