import ScheduleConstants from 'shared/constants/ScheduleConstants';
import { updateObject } from 'shared/reducers/reducerHelpers';

const initalState = {};

function addScheduleShift(state, shift) {
  if (!state[shift.schedule_id]) {
    state[shift.schedule_id] = [];
  }
  state[shift.schedule_id].push(shift);

  return state;
}

function deleteScheduleShift(state, shiftID) {
  const newState = { ...state };
  Object.entries(newState).forEach(entry => {
    entry[1].forEach(shift => {
      if (shift.id === shiftID) {
        entry[1].splice(entry[1].indexOf(shift), 1);
      }
    });

    if (!entry[1].length) {
      delete newState[entry[0]];
    }
  });

  return newState;
}

function scheduleShifts(state = initalState, action) {
  switch (action.type) {
    case ScheduleConstants.SCHEDULE_SHIFT_GET_BY_PARAMS_SUCCESS:
      if (!action.data.results.length) return state;
      return updateObject(state, {
        [action.data.results[0].schedule_id]: action.data.results,
      });

    case ScheduleConstants.SCHEDULE_SHIFT_UPDATE_SUCCESS:
      const newState = deleteScheduleShift(state, action.data.id);
      return addScheduleShift(newState, action.data);

    case ScheduleConstants.SCHEDULE_SHIFT_CREATE_SUCCESS:
      return addScheduleShift({ ...state }, action.data);

    case ScheduleConstants.SCHEDULE_SHIFT_DELETE_SUCCESS:
      return deleteScheduleShift({ ...state }, action.scheduleShiftID);

    default:
      return state;
  }
}

export default scheduleShifts;
