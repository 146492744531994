import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';

class TimesheetSearchFilters extends Component {
  render() {
    const filters = this.props.filters;

    return (
      <div className="module">
        <div className="old-row">
          <div className="old-col col-25">
            <label htmlFor="state">Timesheet State</label>
            <select
              name="state"
              value={filters.state || 'all'}
              onChange={this.props.updateFilters}
            >
              <option value="all">All</option>
              <option value="new">New</option>
              <option value="approval_needed">Approval Needed</option>
              <option value="approved">Approved</option>
              <option value="queried">Queried</option>
            </select>
          </div>
          <div className="old-col col-25">
            <label htmlFor="begin">From Date</label>
            <input
              name="begin"
              type="date"
              placeholder="e.g. 2016-01-01"
              value={filters.begin || ''}
              onChange={this.props.updateFilters}
            />
          </div>
          <div className="old-col col-25">
            <label htmlFor="end">To Date</label>
            <input
              name="end"
              type="date"
              placeholder="e.g. 2016-01-01"
              value={filters.end || ''}
              onChange={this.props.updateFilters}
            />
          </div>
          <div className="old-col col-25">
            <label htmlFor="care_type">Care Type</label>
            <select
              name="care_type"
              value={filters.care_type || 'all'}
              onChange={this.props.updateFilters}
            >
              <option value="all">All</option>
              <option value="livein">Live-in</option>
              <option value="not_livein">Not Live-in</option>
            </select>
          </div>
        </div>
        <div className="old-row">
          <div className="old-col col-33">
            <label htmlFor="customer_name">Customer Name</label>
            <input
              name="customer_name"
              type="text"
              value={filters.customer_name || ''}
              onChange={this.props.updateFilters}
            />
          </div>
          <div className="old-col col-33">
            <label htmlFor="caree_name">Care Recipient Name</label>
            <input
              name="caree_name"
              type="text"
              value={filters.caree_name || ''}
              onChange={this.props.updateFilters}
            />
          </div>
          <div className="old-col col-33">
            <label htmlFor="carer_name">Carer Name</label>
            <input
              name="carer_name"
              type="text"
              value={filters.carer_name || ''}
              onChange={this.props.updateFilters}
            />
          </div>
        </div>
        <div className="old-row">
          <Link to={this.props.location.pathname}>Reset Filters</Link>
        </div>
      </div>
    );
  }
}

TimesheetSearchFilters.propTypes = {
  location: PropTypes.shape().isRequired,
  updateFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
};

export default TimesheetSearchFilters;
