import PropTypes from 'prop-types';
import React from 'react';

import InputWrapper from 'components/forms/InputWrapper';
import StyledLabel from 'components/forms/StyledLabel';
import StyledFieldTitle from 'components/forms/StyledFieldTitle';
import StyledDateInput from 'components/forms/StyledDateInput';
import styled from 'styled-components';

const StyledPicker = styled.label`
  border-left: 1px solid ${props => props.theme.colors.uiGrey};
  position: absolute;
  bottom: 0;
  right: -15%;
  top: 36px;
  padding: 5px;
  &:hover {
    cursor: pointer;
  }
  > input {
    position: relative;
    top: 9px;
  }
`;

const CustomStyledDateInput = styled(StyledDateInput)`
  & {
    select.changed {
      border: 1px solid ${props => props.theme.colors.changeGreen};
    }
  }
`;

export default class StandardDateInput extends React.Component {
  render() {
    const {
      changes,
      extraPadding,
      greyTitle,
      help,
      input,
      label,
      pick,
      title,
      meta,
      prefix,
      suffix,
      ...otherProps
    } = this.props;
    const { touched, error } = meta;
    const displayError = touched && error;

    return (
      <InputWrapper {...{ help, meta, prefix, suffix }}>
        {title && (
          <StyledFieldTitle
            error={displayError}
            htmlFor={`input-${input.name}`}
            extraPadding={extraPadding}
            greyTitle={greyTitle}
          >
            {title}
          </StyledFieldTitle>
        )}
        {label && (
          <StyledLabel
            htmlFor={`select-${input.name}`}
            error={!title && displayError}
          >
            {label}
          </StyledLabel>
        )}
        <CustomStyledDateInput
          className={`${touched && error ? 'error' : ''}
            ${changes.lhs !== changes.rhs ? 'changed' : ''}`}
          {...{ ...otherProps, ...input }}
        />
        {pick && changes.lhs !== changes.rhs && (
          <StyledPicker
            htmlFor={`${input.name}-picker-input`}
            key={`${input.name}-picker`}
          >
            <input
              id={`${input.name}-picker-input`}
              onChange={e => {
                e.persist();
                pick(input.value, e.target.checked);
              }}
              type="checkbox"
            />
          </StyledPicker>
        )}
      </InputWrapper>
    );
  }
}

StandardDateInput.propTypes = {
  changes: PropTypes.shape(),
  pick: PropTypes.func,
  help: PropTypes.node,
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  title: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  meta: PropTypes.shape().isRequired,
};

StandardDateInput.defaultProps = {
  changes: {},
  pick: null,
  help: null,
  label: null,
  title: null,
  prefix: null,
  suffix: null,
};
