import { createSelector } from 'reselect';
import AccountConstants from 'shared/constants/AccountConstants';

import {
  hasRole,
  isStaff as isStaffHelper,
} from 'shared/helpers/accountHelpers';

import store from 'store';

export const getAccountState = state => state.accounts;
export const getUser = state => state.accounts.user;
export const getImpersonator = state => state.accounts.impersonator;
export const getImpersonatorLoadingState = state =>
  state.accounts.loading.impersonate;
export const getStatus = state => state.accounts.status;
export const getError = state => state.accounts.error;
export const getRedirect = state => state.accounts.redirect_to;
export const selectSetupIntent = state => state.accounts.user.client_secret;

export const getFeatures = state => state.accounts.features;

export const isImpersonating = createSelector(
  getImpersonator,
  impersonator => {
    return !!impersonator;
  },
);

export const isLoggedIn = createSelector(
  getUser,
  user => {
    return !!user;
  },
);

export const setRedirect = path => {
  // Only one way to change store. It is dispatch action
  store.dispatch({
    data: path,
    type: AccountConstants.SET_REDIRECT,
  });
};

export const isUser = createSelector(
  getUser,
  user =>
    hasRole(user, 'User', 'Carer', 'Client', 'Customer', 'Franchisee', 'Admin', 'Super'),
);

export const isStaff = createSelector(
  getUser,
  user => isStaffHelper(user),
);

export const isAdmin = createSelector(
  getUser,
  user => hasRole(user, 'Admin', 'Super'),
);

export const isFranchiseMember = createSelector(
  getUser,
  user => hasRole(user, 'Franchisee'),
);

export const isAgent = createSelector(
  getUser,
  user => hasRole(user, 'Agent'),
);

export const isRecruiter = createSelector(
  getUser,
  user => hasRole(user, 'Recruiter'),
);

export const isCarer = createSelector(
  getUser,
  user => hasRole(user, 'Carer'),
);

export const isCustomer = createSelector(
  getUser,
  user => hasRole(user, 'Customer'),
);

export const isVerified = createSelector(
  isImpersonating,
  getUser,
  (impersonator, user) => !!impersonator || !!(user && !!user.confirmed_at),
);

export const hasConfirmedTerms = createSelector(
  isImpersonating,
  getUser,
  (impersonator, user) =>
    !!impersonator ||
    hasRole(user, 'Admin', 'Super', 'Agent') ||
    (!!user && user.confirmed_tos),
);
