import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import Button from 'components/buttons/Button';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import { Container, Col } from 'components/grid';
import StandardInput from 'components/forms/StandardInput';
import StyledLabel from 'components/forms/StyledLabel';
import { asyncValidPostcode, required } from 'validators';
import StandardCheckbox from 'components/forms/StandardCheckbox';
import StandardSelect from 'components/forms/StandardSelect';
import countryCodes from 'components/helpers/countryCodes';

const StyledCardDetails = styled.div`
  background-color: #fcfcfc;
  border: 1px solid;
  border-color: #d4e4e7;
  border-radius: 6px;
  padding: 15px 10px;
`;

const AddCardForm = ({ submitPaymentMethod }) => {
  const disableSubmission = currentValues => {
    const customerDetailsMissing = !formFields.every(
      value =>
        currentValues[value] || value === 'not-uk' || value === 'country',
    );
    return customerDetailsMissing;
  };

  const formFields = [
    'card-details',
    'name',
    'street',
    'city',
    'country',
    'not-uk',
    'postcode',
  ];

  return (
    <Form
      asyncValidate={asyncValidPostcode}
      shouldAsyncValidate={syncValidationPasses => {
        return syncValidationPasses;
      }}
      key="addStripeCard"
      name="addStripeCard"
      fields={formFields}
      selectFields={formFields}
      onSubmit={submitPaymentMethod}
      component={({ currentValues, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Container gutterWidth={10}>
            <FormRow>
              <Col xs={12}>
                <Field
                  component={StandardCheckbox}
                  name="not-uk"
                  id="not-uk"
                  type="text"
                  label="This is not a British card"
                  inlineLabel
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col xs={12} md={6}>
                <Field
                  component={StandardInput}
                  name="name"
                  id="name"
                  type="text"
                  label="Name"
                  validate={[required]}
                />
              </Col>
              <Col xs={12} md={6}>
                <Field
                  component={StandardInput}
                  name="street"
                  id="street"
                  type="text"
                  label="Street"
                  validate={[required]}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col xs={12} md={6}>
                <Field
                  component={StandardInput}
                  name="city"
                  id="city"
                  type="text"
                  label="City"
                  validate={[required]}
                />
              </Col>
              <Col xs={12} md={6}>
                <Field
                  component={StandardInput}
                  name="postcode"
                  id="postcode"
                  type="text"
                  label="Postcode"
                  validate={[required]}
                />
              </Col>
            </FormRow>
            {currentValues['not-uk'] && (
              <FormRow>
                <Col xs={12} md={6}>
                  <Field
                    component={StandardSelect}
                    name="country"
                    id="country"
                    label="Country"
                    validate={currentValues['not-uk'] && [required]}
                  >
                    <option value="">Select option</option>
                    {Object.entries(countryCodes).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </Field>
                </Col>
              </FormRow>
            )}
            <FormRow>
              <Col xs={12} lg={7}>
                <StyledLabel>Card Details</StyledLabel>
              </Col>
            </FormRow>
            <FormRow>
              <Col xs={12} lg={7}>
                <StyledCardDetails id="card-element" name="card-details" />
              </Col>
            </FormRow>
            <FormRow>
              <Col xs={12} lg={7}>
                <Button
                  disabled={disableSubmission(currentValues)}
                  type="submit"
                  id="card-button"
                >
                  Save Card
                </Button>
              </Col>
            </FormRow>
          </Container>
        </form>
      )}
    />
  );
};

export default AddCardForm;
