/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import React, { Component } from 'react';
import { arrayOf, func, object as obj } from 'prop-types';

import ButtonWithReason from 'components/buttons/ButtonWithReason';
import FormRow from 'components/forms/FormRow';
import { SC_NAME } from 'components/global/ContactInfo';
import Module, {
  ModuleHeader,
} from 'components/global/layout/Module';
import { Container, Col } from 'components/grid';

class CarerBid extends Component {
  static propTypes = {
    bid: obj.isRequired,
    jobOffer: obj.isRequired,
    rejectionReasons: arrayOf(obj).isRequired,
    updateBid: func.isRequired,
    schedule: obj.isRequired,
  };

  withdrawBid = (bidID, updateBid, reason) => {
    const confirmation = confirm(
      `Are you sure you want to withdraw your application? You will need to contact ${SC_NAME} if you wish to place a new application for this job.`,
    );
    if (confirmation) {
      updateBid(bidID, { state: 'carer_withdrawn', state_reason: reason });
    }
  };

  render() {
    const { bid, rejectionReasons, updateBid } = this.props;
    return (
      <Module>
        <ModuleHeader>Your Application</ModuleHeader>
        <Container>
          <FormRow>
            <Col xs={12} sm={12}>
              <strong>Your message:</strong>
              <p>{bid.description || <em>No message</em>}</p>
            </Col>
          </FormRow>
          {bid.state === 'applied' && (
            <FormRow>
              <Col xs={12} sm={4}>
                <ButtonWithReason
                  className="danger"
                  action={reason => this.withdrawBid(bid.id, updateBid, reason)}
                  reasons={rejectionReasons}
                  otherReasoning="Please tell us why to improve the jobs we send you."
                  cta="Withdraw Application"
                  key="withdraw"
                />
              </Col>
            </FormRow>
          )}
        </Container>
      </Module>
    );
  }
}

export default CarerBid;
