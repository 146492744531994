import React from 'react';
import styled from 'styled-components';
import { bool, object as obj } from 'prop-types';

import { ShiftTypeIcons } from 'components/global/icons/IconLabelMappings';
import { ModuleHeader } from 'components/global/layout/Module';
import { Visible } from 'components/grid';

const StyledShiftTypeHeadings = styled(ModuleHeader)`
  ${props => (props.inline ? 'display: flex;' : '')}
  justify-content: left;
  flex-wrap: wrap;
  margin-left: 12px;

  @media (min-width: 768px) {
    margin-left: 20px;
  }

  > span {
    @media (min-width: 768px) {
      margin-left: 18px;
    }
    margin-right: 20px;
  }
`;

const JobRef = styled.div`
  font-size: 15px;
  line-height: 32px;
  margin-bottom: 10px;
`;

const ShiftTypeHeadings = ({
  jobOffer,
  showRefOnMobile = false,
  className,
  inline,
}) => {
  return (
    <StyledShiftTypeHeadings className={className} inline={inline}>
      {showRefOnMobile && (
        <Visible xs sm>
          <JobRef>Job Ref: {jobOffer.reference}</JobRef>
        </Visible>
      )}
      {jobOffer.shift_types.map(shiftType => (
        <ShiftTypeIcons key={shiftType} shiftType={shiftType} />
      ))}
    </StyledShiftTypeHeadings>
  );
};

ShiftTypeHeadings.propTypes = {
  jobOffer: obj.isRequired,
  showRefOnMobile: bool,
};

ShiftTypeHeadings.defaultProps = {
  showRefOnMobile: false,
};

export default ShiftTypeHeadings;
