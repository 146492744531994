import ApiConstants from 'shared/constants/ApiConstants';
import ReferencesConstants from 'shared/constants/ReferencesConstants';

export function addReference(payload, redirect) {
  return {
    endpoint: '/reference/',
    method: 'post',
    onError: { type: ReferencesConstants.REFERENCE_ADD_FAILURE },
    onStart: { type: ReferencesConstants.REFERENCE_ADD },
    onSuccess: {
      type: ReferencesConstants.REFERENCE_ADD_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Reference added',
      },
    },
    payload,
    redirect,
    type: ApiConstants.API_REQUEST,
  };
}

export function updateReference(referenceID, data, redirect = null) {
  return {
    endpoint: `/reference/${referenceID}/`,
    method: 'put',
    onError: { type: ReferencesConstants.REFERENCE_UPDATE_FAILURE },
    onStart: { type: ReferencesConstants.REFERENCE_UPDATE },
    onSuccess: {
      type: ReferencesConstants.REFERENCE_UPDATE_SUCCESS,
    },
    payload: data,
    redirect,
    type: ApiConstants.API_REQUEST,
  };
}

export function deleteReference(referenceID, userID) {
  return {
    endpoint: `/reference/${referenceID}/`,
    method: 'delete',
    onError: { type: ReferencesConstants.REFERENCE_DELETE_FAILURE },
    onStart: { type: ReferencesConstants.REFERENCE_DELETE },
    onSuccess: {
      type: ReferencesConstants.REFERENCE_DELETE_SUCCESS,
      // Pass back payload to remove from store (no object returned by API)
      payload: { referenceID, userID },
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function getReferenceByID(referenceID) {
  return {
    endpoint: `/reference/${referenceID}/`,
    method: 'get',
    onError: {
      type: ReferencesConstants.REFERENCE_GET_BY_ID_FAILURE,
    },
    onStart: { type: ReferencesConstants.REFERENCE_GET_BY_ID },
    onSuccess: { type: ReferencesConstants.REFERENCE_GET_BY_ID_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function getReferencesByCarerID(carerID) {
  return {
    endpoint: '/reference/find/',
    method: 'get',
    onError: {
      type: ReferencesConstants.REFERENCES_GET_FOR_CARER_BY_ID_FAILURE,
    },
    onStart: { type: ReferencesConstants.REFERENCES_GET_FOR_CARER_BY_ID },
    onSuccess: {
      carer_id: carerID,
      type: ReferencesConstants.REFERENCES_GET_FOR_CARER_BY_ID_SUCCESS,
    },
    payload: {
      user_id: carerID,
      order_by_parameter: ['professional_reference', 'created_at',],
      order_by_direction: ['desc', 'asc',],
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function searchReferences(params, page) {
  const query = Object.assign({ page: page || 1 }, params);
  return {
    endpoint: '/reference/find/',
    method: 'get',
    onError: { type: ReferencesConstants.REFERENCES_SEARCH_FAILURE },
    onStart: { type: ReferencesConstants.REFERENCES_SEARCH },
    onSuccess: { type: ReferencesConstants.REFERENCES_SEARCH_SUCCESS },
    payload: query,
    type: ApiConstants.API_REQUEST,
  };
}

export function sendReference(data) {
  return {
    endpoint: '/reference/notify/supercarers/',
    method: 'post',
    onError: { type: ReferencesConstants.REFERENCE_SEND_FAILURE },
    onStart: { type: ReferencesConstants.REFERENCE_SEND },
    onSuccess: {
      type: ReferencesConstants.REFERENCE_SEND_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Reference sent.',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function submitReferences(references) {
  return {
    references,
    type: ReferencesConstants.REFERENCES_SUBMIT,
  };
}
