import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Module, { ModuleHeader } from 'components/global/layout/Module';

import AdminRatesSettings from 'components/rates/AdminRatesSettings';
import AdminRatesTable from 'components/rates/AdminRatesTable';
import { Container, Row, Col } from 'react-grid-system';
import Form from 'components/forms/Form';
import Button from 'components/buttons/Button';
import ButtonGroup from 'components/buttons/ButtonGroup';

class AdminUserRates extends Component {
  static propTypes = {
    fields: PropTypes.shape({
      settings: PropTypes.arrayOf(PropTypes.string).isRequired,
      shiftTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    rates: PropTypes.shape().isRequired,
    rateLimits: PropTypes.shape().isRequired,
    resetRates: PropTypes.func.isRequired,
    setCarerRates: PropTypes.func.isRequired,
  };
  render() {
    const shiftTypeFields = this.props.fields.shiftTypes.concat(
      this.props.fields.shiftTypes.map(shiftType => `${shiftType}_commission`),
    );
    return (
      <Module>
        <ModuleHeader>Carer Rates</ModuleHeader>
        <Form
          name="carerRates"
          fields={this.props.fields.settings.concat(shiftTypeFields)}
          selectFields={shiftTypeFields}
          onSubmit={this.props.setCarerRates}
          values={this.props.rates.rate}
          component={({ currentValues, handleSubmit, pristine }) => (
            <form onSubmit={handleSubmit}>
              <AdminRatesSettings />
              <AdminRatesTable
                currentValues={currentValues}
                rate={this.props.rates.rate}
                rateLimits={this.props.rateLimits}
                shiftTypes={this.props.fields.shiftTypes}
              />
              <Container>
                <Row>
                  <Col>
                    <ButtonGroup>
                      <Button disabled={pristine} type="submit">
                        Save Changes
                      </Button>
                      <Button
                        className="danger"
                        onClick={this.props.resetRates}
                      >
                        Reset To Default
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Container>
            </form>
          )}
        />
      </Module>
    );
  }
}

export default AdminUserRates;
