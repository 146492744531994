//@flow
import React from 'react';
import styled, { type ReactComponentStyled } from 'styled-components';

import AvatarImage from 'components/global/AvatarImage';

import type { CarerType } from 'shared/types';

type Props = {|
  carer: CarerType,
  className: string,
  height?: number,
  width?: number,
|};

function CarerImage({ carer, className, height, width }: Props) {
  const noCarerProfileImage: boolean =
    !carer || !carer.image || !carer.image.profile;

  const spanClassName: string = noCarerProfileImage
    ? `no-image ${className}`
    : `carer-image ${className}`;

  const imageSrc: ?string = noCarerProfileImage ? null : carer.image.profile;
  const imageAlt: ?string = !carer ? null : carer.display_name;

  return (
    <AvatarImage
      src={imageSrc}
      alt={imageAlt}
      spanClassName={spanClassName}
      height={height}
      width={width}
    />
  );
}

CarerImage.defaultProps = {
  className: '',
};

export default CarerImage;

export const TableCarerImage: ReactComponentStyled<Props> = styled(CarerImage)`
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;
