import { createSelector } from 'reselect';

import { getUser } from 'shared/selectors/accountSelectors';
import { getChargeByID } from 'shared/selectors/chargeSelectors';
import { getInvoiceByID } from 'shared/selectors/invoiceSelectors';

export const getPayments = state => state.payments;
export const getPaymentByID = (state, id) => state.payments[id];

export const getPaymentMethodsByCurrentUser = createSelector(
  [getPayments, getUser],
  (payments, user) => {
    if (!payments || !user) {
      return null;
    }
    return Object.values(payments).filter(
      payment => payment.user_id === user.id,
    );
  },
);

export const getPaymentMethodsByChargeID = createSelector(
  [getPayments, getChargeByID],
  (payments, charge) => {
    if (!payments || !charge) {
      return null;
    }
    return Object.values(payments).filter(
      payment => payment.user_id === charge.consumer_id,
    );
  },
);

export const getPaymentsByInvoiceID = createSelector(
  [getPayments, getInvoiceByID],
  (payments, invoice) => {
    if (!payments || !invoice) {
      return null;
    }
    return Object.values(payments).filter(
      payment => payment.user_id === invoice.user_id,
    );
  },
);
