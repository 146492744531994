import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AdminChargeSearchContainer from 'containers/admin/AdminChargeSearchContainer';

class AdminChargesOverview extends Component {
  render() {
    return (
      <div className="component-wrapper wide">
        <h1>Charges Overview</h1>
        <AdminChargeSearchContainer location={this.props.location} />
      </div>
    );
  }
}

AdminChargesOverview.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default AdminChargesOverview;
