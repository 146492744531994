// @flow
import React from 'react';
import { Container, Row, Col } from 'components/grid';
import styled from 'styled-components';

import { PortalWithState } from 'react-portal';
import Modal from 'components/modules/Modal';
import ButtonGroup from 'components/buttons/ButtonGroup';
import Button from 'components/buttons/Button';

const StyledDiv = styled.div`
  padding: 20px 0;
  text-align: center;

  > h3.title {
    font-size: 24px;
    margin-bottom: 5px;
  }

  > p.message {
    color: ${props => props.theme.colors.midGrey};
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  text-align: center;
`;

type Props = {
  confirmation: string,
  onCancel: () => void,
  onConfirm: () => void,
  title: string,
  message: string,
  danger: boolean,
};

function ConfirmationDialog({
  confirmation = 'Confirm',
  onCancel = () => {},
  onConfirm = () => {},
  title,
  message = 'Are you sure you want to do this?',
  danger = false,
}: Props) {
  const confirm = closePortal => {
    onConfirm();
    closePortal();
  };

  const cancel = closePortal => {
    onCancel();
    closePortal();
  };

  return (
    <PortalWithState
      closeOnOutsideClick
      closeOnEsc
      onClose={onCancel}
      defaultOpen
    >
      {({ closePortal, portal }) =>
        portal(
          <Modal closePortal={closePortal} key="confirmation-modal" small>
            <Container>
              <Row>
                <Col>
                  <StyledDiv>
                    {title && <h3 className="title">{title}</h3>}
                    <p className="message">{message}</p>
                  </StyledDiv>
                </Col>
              </Row>
              <Row>
                <Col>
                  <StyledButtonGroup>
                    <Button
                      type="button"
                      className="alt"
                      onClick={() => cancel(closePortal)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      className={danger ? 'danger' : ''}
                      onClick={() => confirm(closePortal)}
                    >
                      {confirmation}
                    </Button>
                  </StyledButtonGroup>
                </Col>
              </Row>
            </Container>
          </Modal>,
        )
      }
    </PortalWithState>
  );
}

export default ConfirmationDialog;
