import React from 'react';

const BasicInput = ({ id, name, value, onChange, placeholder, step, type }) => (
  <input
    id={id}
    name={name}
    value={value}
    onChange={e => onChange(e.target.value)}
    placeholder={placeholder}
    step={step}
    type={type}
  />
);

export default BasicInput;
