import keyMirror from 'keymirror';

export default keyMirror({
  FRANCHISE_GET_BY_ID: null,
  FRANCHISE_GET_BY_ID_SUCCESS: null,
  FRANCHISE_GET_BY_ID_FAILURE: null,

  FRANCHISE_GET_ALL: null,
  FRANCHISE_GET_ALL_SUCCESS: null,
  FRANCHISE_GET_ALL_FAILURE: null,

  FRANCHISE_SEARCH: null,
  FRANCHISE_SEARCH_SUCCESS: null,
  FRANCHISE_SEARCH_FAILURE: null,

  FRANCHISE_ADD: null,
  FRANCHISE_ADD_SUCCESS: null,
  FRANCHISE_ADD_FAILURE: null,

  FRANCHISE_UPDATE: null,
  FRANCHISE_UPDATE_SUCCESS: null,
  FRANCHISE_UPDATE_FAILURE: null,

  FRANCHISE_DELETE: null,
  FRANCHISE_DELETE_SUCCESS: null,
  FRANCHISE_DELETE_FAILURE: null,

  POPULATE_FRANCHISE: null,
  POPULATE_FRANCHISE_COMPLETE: null,
});