import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addReference } from '_actions/referencesActions';

import ReferenceAddContainer from 'containers/references/ReferenceAddContainer';

class AdminUserReferenceAddContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      addReference: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        userID: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  handleSubmit = (data, addMore) => {

    this.props.actions.addReference(
      { state: 'submitted', ...data, user_id: this.props.match.params.userID },
      addMore ? null : `/admin/users/${this.props.match.params.userID}/#references`
    );

    if (addMore) {
      window.scrollTo(0, 0);
    }
  };

  render() {
    return (
      <ReferenceAddContainer
        carerID={this.props.match.params.userID}
        onSubmit={this.handleSubmit}
        isAdmin
      />
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addReference,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserReferenceAddContainer);
