//@flow
import React from 'react';
import Link from 'react-router-redux-dom-link';
import styled, { css, type ReactComponentStyled } from 'styled-components';

import { ExternalIcon } from 'components/global/icons';
import { type ExternalIconProps } from 'components/global/icons/ExternalIcon';

import type { LinkType } from 'shared/types';

const styledLink = css`
  border-left: 9px solid transparent;
  color: ${props => props.theme.colors.hiPurple};
  display: block;
  font-size: 26px;
  padding: 20px 0 20px 20px;
  position: relative;
  text-decoration: none;

  @media (min-width: 768px) {
    font-size: ${props => props.theme.typography.defaultFontSize};
    &:hover {
      background: ${props => props.theme.colors.hiAccentBlue};
      color: ${props => props.theme.colors.hiCream};
    }

    &.active {
      background: ${props => props.theme.colors.hiPurple};
      border-left-color: ${props => props.theme.colors.hiAccentBlue};
      color: ${props => props.theme.colors.hiCream};
    }
  }
`;

//$FlowFixMe
const StyledInternalLink: ReactComponentStyled<LinkType> = styled(Link)`
  ${props => styledLink};
`;

const StyledExternalLink = styled.a`
  ${props => styledLink};
`;

const StyledExternalIcon: ReactComponentStyled<ExternalIconProps> = styled(
  ExternalIcon,
)`
  margin-left: 8px;
  position: absolute;
  top: 22px;
  display: inline;
`;

type InternalProps = {
  currentPath: string,
  path: string,
  label: string,
};

type ExternalProps = {
  href: string,
  label: string,
};

export const InternalLink = ({ currentPath, path, label }: InternalProps) => (
  <li key={path}>
    <StyledInternalLink
      className={currentPath === path ? 'active' : ''}
      to={path}
    >
      {label}
    </StyledInternalLink>
  </li>
);

export const ExternalLink = ({ href, label }: ExternalProps) => (
  <li key={href}>
    <StyledExternalLink href={href} target="_blank">
      {label}
      <StyledExternalIcon />
    </StyledExternalLink>
  </li>
);
