import keyMirror from 'keymirror';

export default keyMirror({
  USER_CREATE_CUSTOMER: null,
  USER_CREATE_CUSTOMER_SUCCESS: null,
  USER_CREATE_CUSTOMER_FAILURE: null,

  USER_CREATE_USER: null,
  USER_CREATE_USER_SUCCESS: null,
  USER_CREATE_USER_FAILURE: null,

  USER_GET_BY_ID: null,
  USER_GET_BY_ID_SUCCESS: null,
  USER_GET_BY_ID_FAILURE: null,

  USER_GET_BY_EMAIL: null,
  USER_GET_BY_EMAIL_SUCCESS: null,
  USER_GET_BY_EMAIL_FAILURE: null,

  USER_SEARCH: null,
  USER_SEARCH_SUCCESS: null,
  USER_SEARCH_FAILURE: null,

  USER_ARCHIVE: null,
  USER_ARCHIVE_SUCCESS: null,
  USER_ARCHIVE_FAILURE: null,

  USER_SEARCH_AND_FETCH: null,
  USER_SEARCH_AND_FETCH_SUCCESS: null,
  USER_SEARCH_AND_FETCH_FAILURE: null,
});
