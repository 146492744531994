// @flow
import React from 'react';
import styled from 'styled-components';

import FormRow from 'components/forms/FormRow';
import StandardInput from 'components/forms/StandardInput';
import StyledTitleWithLabel from 'components/forms/StyledTitleWithLabel';
import { Col } from 'components/grid';

const StyledCol = styled(Col)`
  @media (min-width: ${props => props.theme.screen.small}px) {
    margin: 5px;
  }
`;

type Props = {
  autosave: (string, string) => void,
  names: Array<string>,
  placeholders: { [string]: string },
  style: { [string]: string },
  title: string,
};

const MultiInput = ({
  autosave,
  names,
  placeholders,
  style,
  title,
  ...otherProps
}: Props) => (
  <>
    <Col style={{ padding: '0 10px' }}>
      <StyledTitleWithLabel title={title} greyTitle extraPadding />
    </Col>
    <FormRow largeMarginBottom>
      {names.map(name => (
        <StyledCol key={name} xs={6}>
          <StandardInput
            name={name}
            placeholder={placeholders[name]}
            type="text"
            {...otherProps}
            meta={otherProps[name].meta}
            input={otherProps[name].input}
            onBlur={(event, value) =>
              event.currentTarget && autosave(event.currentTarget.value, name)
            }
          />
        </StyledCol>
      ))}
    </FormRow>
  </>
);

export default MultiInput;
