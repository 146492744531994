import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { onSubmitActions } from 'redux-form-submit-saga';

import { staffLogin } from '_actions/accountActions';
import StaffLogin from 'components/admin/account/StaffLogin';

import AccountConstants from 'shared/constants/AccountConstants';

const ReduxStaffLogin = reduxForm({
  form: 'staff-login',
  onSubmit: onSubmitActions({
    submit: staffLogin,
    success: AccountConstants.STAFF_LOGIN_SUCCESS,
    failure: AccountConstants.STAFF_LOGIN_FAILURE,
  }),
})(StaffLogin);

export default class StaffLoginContainer extends Component {
  render() {
    return <ReduxStaffLogin />;
  }
}
