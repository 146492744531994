import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { getAccountOwner } from '_actions/needActions';
import { selectAccountOwnerForNeedID } from 'shared/selectors/needSelectors';
import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AccountOwnerComponent from 'components/customers/AccountOwnerComponent';

const StyledLoader = styled(LoadingPlaceholder)`
  margin: auto;
  max-height: 103px;
`;

export class AccountOwnerContainer extends Component {
  componentDidMount() {
    const { getAccountOwner, needID } = this.props;
    getAccountOwner(needID);
  }

  render() {
    const { accountOwner, accountOwnersLoadingState, nameOnly } = this.props;

    if (nameOnly) {
      return accountOwner
        ? `${accountOwner.firstname} ${
            accountOwner.lastname ? accountOwner.lastname : ''
          }`
        : null;
    }

    if (!accountOwner && accountOwnersLoadingState) {
      return <StyledLoader />;
    }

    return <AccountOwnerComponent accountOwner={accountOwner} />;
  }
}

const mapStateToProps = (state, props) => ({
  accountOwner: selectAccountOwnerForNeedID(state, props.needId),
  accountOwnersLoadingState: loadingSelectorFactory('needs', 'accountOwners')(
    state,
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAccountOwner,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountOwnerContainer);
