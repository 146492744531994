import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { confirmTerms } from '_actions/accountActions';
import AccountTerms from 'components/account/AccountTerms';
import { getUser } from 'shared/selectors/accountSelectors';

class AccountTermsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
    };
  }

  confirmTerms = model => {
    this.props.confirmTerms(model);
  };

  render() {
    return (
      <AccountTerms user={this.state.user} confirmTerms={this.confirmTerms} />
    );
  }
}

AccountTermsContainer.propTypes = {
  confirmTerms: PropTypes.func.isRequired,
  user: PropTypes.shape(),
};

AccountTermsContainer.defaultProps = {
  user: null,
};

function mapStateToProps(state) {
  return {
    user: getUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ confirmTerms }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountTermsContainer);
