import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Currency from 'components/modules/Currency';

class ShiftTotalCell extends Component {
  static propTypes = {
    shift: PropTypes.shape().isRequired,
    view: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <td data-heading="Total" className="number">
        <strong>
          <Currency value={this.props.shift.total.carer_total} />
        </strong>
        {this.props.isAdmin && this.props.view !== 'customer' && (
          <div title="SuperCarers commission." className="supplementary-info">
            <Currency
              value={
                this.props.shift.total.customer_total -
                this.props.shift.total.carer_total
              }
            />
            {this.props.shift.type === 'exceptional' && (
              <span>
                {' '}
                @{' '}
                {(this.props.shift.total.exceptional_commission * 100).toFixed(
                  0,
                )}
                %
              </span>
            )}
          </div>
        )}
      </td>
    );
  }
}

export default ShiftTotalCell;
