import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { impersonateUser } from '_actions/accountActions';
import { searchAndFetchUsers } from '_actions/userActions';

import AdminUserSearch from 'components/admin/AdminUserSearch';

import { getUserResults } from 'shared/selectors/userSelectors';

class AdminUserSearchContainer extends Component {
  static propTypes = {
    impersonateUser: PropTypes.func.isRequired,
    searchAndFetchUsers: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  impersonateUser = userId => {
    this.props.impersonateUser(userId);
  };

  updateSearch = e => {
    const name = e.target.value;
    this.props.searchAndFetchUsers({ name, per_page: 20 });
  };

  render() {
    return (
      <AdminUserSearch
        users={this.props.users}
        impersonateUser={this.impersonateUser}
        updateSearch={this.updateSearch}
      />
    );
  }
}

const mapStateToProps = state => ({
  users: getUserResults(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ impersonateUser, searchAndFetchUsers }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserSearchContainer);
