import keyMirror from 'keymirror';

export default keyMirror({
  TESTIMONIAL_GET_BY_ID: null,
  TESTIMONIAL_GET_BY_ID_SUCCESS: null,
  TESTIMONIAL_GET_BY_ID_FAILURE: null,

  TESTIMONIALS_GET_FOR_CARER_BY_ID: null,
  TESTIMONIALS_GET_FOR_CARER_BY_ID_SUCCESS: null,
  TESTIMONIALS_GET_FOR_CARER_BY_ID_FAILURE: null,

  TESTIMONIALS_SEARCH: null,
  TESTIMONIALS_SEARCH_SUCCESS: null,
  TESTIMONIALS_SEARCH_FAILURE: null,

  TESTIMONIAL_ADD: null,
  TESTIMONIAL_ADD_SUCCESS: null,
  TESTIMONIAL_ADD_FAILURE: null,

  TESTIMONIAL_UPDATE: null,
  TESTIMONIAL_UPDATE_SUCCESS: null,
  TESTIMONIAL_UPDATE_FAILURE: null,

  TESTIMONIAL_DELETE: null,
  TESTIMONIAL_DELETE_SUCCESS: null,
  TESTIMONIAL_DELETE_FAILURE: null,

  TESTIMONIALS_SUBMIT: null,
  TESTIMONIALS_SUBMIT_SUCCESS: null,
  TESTIMONIALS_SUBMIT_FAILURE: null,
});
