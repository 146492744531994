import React from 'react';
import styled from 'styled-components';

const CurrencySymbol = styled.span`
  font-size: 18px;
  margin-left: 5px;
`;

const CurrencyInput = ({
  id,
  name,
  value,
  onChange,
  placeholder,
  step,
  type,
}) => (
  <>
    <CurrencySymbol>£ </CurrencySymbol>
    <input
      id={id}
      name={name}
      value={value && value / 100}
      onChange={e => onChange(e.target.value * 100)}
      placeholder={placeholder}
      step={step}
      type={type}
    />
  </>
);

export default CurrencyInput;
