// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import PrimaryNavigationLinks from 'components/global/primarynavigation/PrimaryNavigationLinks';
import { InternalLink } from 'components/global/primarynavigation/NavigationLink';
import { Visible } from 'components/grid';

import { CloseIcon } from 'components/global/icons';

import { type LocationType, type UserType } from 'shared/types';

const MenuButton = styled.a`
  border: 1px solid ${props => props.theme.colors.hiPurple};
  border-radius: 6px;
  display: inline-block;
  color: ${props => props.theme.colors.hiPurple};
  cursor: pointer;
  padding: 4px 10px;
  position: absolute;
  text-decoration: none;
  top: 9px;
  right: 20px;
`;

const CloseButton = styled.a`
  color: ${props => props.theme.colors.hiPurple};
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 100;
`;

const StyledNav = styled.nav`
  @media (min-width: 768px) {
    margin: 25px 0;
  }
  @media (max-width: 767px) {
    > ol {
      background: ${props => props.theme.colors.hiCream};
      display: ${props => (props.primaryMenuOpen ? 'block' : 'none')};
      overflow-y: scroll;
      padding-top: 54px;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: ${props => props.theme.zIndexes.menu};
    }
  }
`;

type Props = {
  user: UserType,
  location: LocationType,
  primaryMenuOpen: boolean,
  openPrimaryMenu: () => {},
  closePrimaryMenu: () => {},
};

class PrimaryNavigation extends Component<Props> {
  static defaultProps = {
    user: null,
  };

  render() {
    const { user, location, primaryMenuOpen } = this.props;
    const path: string = location.pathname;

    return (
      <StyledNav primaryMenuOpen={primaryMenuOpen}>
        <Visible xs sm>
          {!this.props.primaryMenuOpen && (
            <MenuButton onClick={this.props.openPrimaryMenu}>Menu</MenuButton>
          )}
          {this.props.primaryMenuOpen && (
            <CloseButton onClick={this.props.closePrimaryMenu}>
              <CloseIcon />
            </CloseButton>
          )}
        </Visible>
        <ol>
          <PrimaryNavigationLinks path={path} user={user} />
          <Visible xs sm>
            <InternalLink
              currentPath={path}
              path="/account/"
              label="Settings"
            />
            <InternalLink currentPath={path} path="/logout/" label="Logout" />
          </Visible>
        </ol>
      </StyledNav>
    );
  }
}

export default PrimaryNavigation;
