// @flow
import React from 'react';
import styled from 'styled-components';

import Definition from 'components/global/informationdisplay/Definition';
import Summary, { type SummariesType } from 'components/modules/Summary';

const BidsBreakdown = styled.div`
  background-color: ${props => props.theme.colors.lightestBlue};
  border-radius: 4px;
  padding: 25px 18px 15px;
`;

type Props = {
  bidSummary: { [string]: number },
  matchTotal: number,
  openJob: boolean,
  totalBids: number,
};

const BidsSummary = ({ bidSummary, matchTotal, openJob, totalBids }: Props) => {
  const summaries: SummariesType = Object.values(bidSummary).length
    ? [
        {
          states: Object.entries(bidSummary).map(([state, value]) => ({
            name: state,
            //$FlowFixMe - map forces this to be of type 'mixed'
            count: value,
          })),
        },
      ]
    : [];

  const jobSendingLabel = `Job ${openJob ? '' : 'will be '}sent to`;
  const jobSendingAmount = openJob ? totalBids : matchTotal;

  return (
    <BidsBreakdown>
      <Definition
        label={jobSendingLabel}
      >{`${jobSendingAmount} carers`}</Definition>
      {summaries.length > 0 && (
        <Summary summaries={summaries} removeModuleStyling />
      )}
    </BidsBreakdown>
  );
};

export default BidsSummary;
