import StyledInput from 'components/forms/StyledInput';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import InlineValidationError from 'components/forms/InlineValidationError';

const InputWrapper = styled.div`
  position: relative;
  white-space: nowrap;
`;

const InputAffix = styled.div`
  font-size: 18px;
  pointer-events: none;
  position: absolute;
  top: 15px;
  ${props => props.prefix && 'left: 10px;'} ${props =>
    props.suffix && 'right: 10px;'};
`;

const InputHelp = styled.span`
  color: ${props => props.theme.colors.midGrey};
  display: inline-block;
  margin-left: 10px;
`;

class Input extends Component {
  static propTypes = {
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onValidationError: PropTypes.func,
    required: PropTypes.bool,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    help: PropTypes.string,
  };

  static defaultProps = {
    type: 'text',
    value: '',
    onChange: () => {},
    onValidationError: () => {},
    required: false,
    prefix: null,
    suffix: null,
    help: null,
  };

  state = {
    value: this.props.value,
    validationErrors: [],
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  onChange = event => {
    const value = event.target.value.trim();

    const validationErrors = [];
    if (this.props.required && value.length === 0) {
      validationErrors.push('Please enter something.');
    }

    this.setState({
      value,
      validationErrors,
    });

    if (validationErrors.length) {
      this.props.onValidationError(this.props.name);
    }

    this.props.onChange(this.props.name, value);
  };

  render() {
    const {
      help,
      name,
      onChange,
      onValidationError,
      prefix,
      required,
      suffix,
      type,
      value,
      ...otherProps
    } = this.props;

    return (
      <InputWrapper>
        {prefix && <InputAffix prefix={prefix}>{prefix}</InputAffix>}
        <StyledInput
          prefix={prefix}
          suffix={suffix}
          type={type}
          id={name}
          name={name}
          value={this.state.value}
          onChange={this.onChange}
          help={help}
          {...otherProps}
        />
        {suffix && <InputAffix suffix={suffix}>{suffix}</InputAffix>}
        {help && <InputHelp>{help}</InputHelp>}
        {this.state.validationErrors.map(error => (
          <InlineValidationError key={error}>{error}</InlineValidationError>
        ))}
      </InputWrapper>
    );
  }
}

export default Input;
