import PropTypes from 'prop-types';
import React from 'react';

const LocationIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 22"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="location-icon"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path d="M8.5900223,1.06201406 C4.49374247,1.06201406 1.16081382,4.39494271 1.16081382,8.49122253 C1.16081382,13.6173764 7.19983166,18.8368597 8.58955797,19.9698139 C9.9778913,18.833145 16.0192308,13.5932314 16.0192308,8.49122253 C16.0192308,4.39494271 12.6863021,1.06201406 8.5900223,1.06201406 Z M8.5900223,21.0280118 C8.49112096,21.0280118 8.3917553,20.996902 8.30910535,20.9332894 C7.9789699,20.6825536 0.232162765,14.7150419 0.232162765,8.49122253 C0.232162765,3.88279165 3.98159142,0.133827324 8.5900223,0.133827324 C13.1984532,0.133827324 16.9478818,3.88279165 16.9478818,8.49122253 C16.9478818,14.6857894 9.20153902,20.6806963 8.87186789,20.9328251 C8.78875362,20.9964377 8.68938796,21.0280118 8.5900223,21.0280118 L8.5900223,21.0280118 Z" />
        <path d="M8.5900223,5.70526935 C7.05356912,5.70526935 5.80406912,6.95523368 5.80406912,8.49122253 C5.80406912,10.0276757 7.05356912,11.2771757 8.5900223,11.2771757 C10.1264755,11.2771757 11.3759755,10.0276757 11.3759755,8.49122253 C11.3759755,6.95523368 10.1264755,5.70526935 8.5900223,5.70526935 Z M8.5900223,12.2058268 C6.54188239,12.2058268 4.87541806,10.5398268 4.87541806,8.49122253 C4.87541806,6.44308262 6.54188239,4.77661829 8.5900223,4.77661829 C10.6381622,4.77661829 12.3046265,6.44308262 12.3046265,8.49122253 C12.3046265,10.5398268 10.6381622,12.2058268 8.5900223,12.2058268 L8.5900223,12.2058268 Z" />
      </g>
    </svg>
  </div>
);

LocationIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

LocationIcon.defaultProps = {
  className: '',
  height: '22px',
  width: '18px',
};

export default LocationIcon;
