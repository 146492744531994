import PaymentConstants from 'shared/constants/PaymentConstants';
import { updateObject } from 'shared/reducers/reducerHelpers';

const initalState = {};

function updatePaymentMethods(state, action) {
  const updatedPaymentMethods = updateObject(state);
  Object.keys(action.data).forEach(methodType => {
    action.data[methodType].forEach(paymentMethod => {
      updatedPaymentMethods[paymentMethod.id] = paymentMethod;
    });
  });
  return updatedPaymentMethods;
}

function removePaymentMethod(state, action) {
  const updatedPaymentMethods = updateObject(state);
  if (updatedPaymentMethods[action.data.id]) {
    delete updatedPaymentMethods[action.data.id];
  }
  return updatedPaymentMethods;
}

function payments(state = initalState, action) {
  switch (action.type) {
    case PaymentConstants.PAYMENTS_CREATE_PAYMENT_METHOD_SUCCESS:
      return updateObject(state, { [action.data[0].id]: action.data[0] });

    case PaymentConstants.PAYMENTS_DELETE_PAYMENT_METHOD_SUCCESS:
      return removePaymentMethod(state, action);

    case PaymentConstants.PAYMENTS_GET_USER_METHODS_SUCCESS:
      return updatePaymentMethods(state, action);

    default:
      return state;
  }
}

export default payments;
