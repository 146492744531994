import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AdminTimesheetsOverview from 'components/admin/AdminTimesheetsOverview';

class AdminTimesheetsOverviewContainer extends Component {
  render() {
    return <AdminTimesheetsOverview location={this.props.location} />;
  }
}

AdminTimesheetsOverviewContainer.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default AdminTimesheetsOverviewContainer;
