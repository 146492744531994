import { createSelector } from 'reselect';
import {
  selectAllSchedulesForUser,
  selectSchedulesByNeedID,
  selectScheduleByID,
} from 'shared/selectors/scheduleSelectors';
import {
  convertArrayOfDaysToArrayOfWeeks,
  buildEmptyShiftsObj,
} from 'shared/helpers/scheduleHelpers';

function createScheduleWithShiftsObjects(schedules, shifts) {
  if (!schedules || !schedules.length) return [];
  return schedules.map(schedule => {
    return {
      ...schedule,
      shifts: shifts[schedule.id]
        ? convertArrayOfDaysToArrayOfWeeks(
            shifts[schedule.id],
            schedule.repeat_every_n_weeks,
          )
        : buildEmptyShiftsObj(schedule.repeat_every_n_weeks),
    };
  });
}

function filterScheduleShiftsById(schedules, shifts) {
  if (!schedules) {
    return [];
  }
  return schedules.map(schedule => shifts[schedule.id]);
}

export const selectScheduleShifts = state => state.scheduleShifts;

export const selectScheduleShiftsByNeedID = createSelector(
  [selectSchedulesByNeedID, selectScheduleShifts],
  (schedules, shifts) => {
    return filterScheduleShiftsById(schedules, shifts);
  },
);

export const selectSchedulesWithShiftsByNeedID = createSelector(
  [selectSchedulesByNeedID, selectScheduleShifts],
  (schedules, shifts) => {
    return createScheduleWithShiftsObjects(schedules, shifts);
  },
);

export const selectScheduleWithShiftsByScheduleID = createSelector(
  [selectScheduleByID, selectScheduleShifts],
  (schedule, shifts) => {
    if (!schedule) {
      return {};
    }
    return createScheduleWithShiftsObjects([schedule], shifts)[0];
  },
);

export const selectSchedulesWithShiftsByUserID = createSelector(
  [selectAllSchedulesForUser, selectScheduleShifts],
  (schedules, shifts) => {
    return createScheduleWithShiftsObjects(schedules, shifts);
  },
);
