// @flow
import { required, validEmail, requiredEmail, validPhoneNumber } from 'validators';

import type { TaxonomiesClassificationType } from 'shared/types';


const fieldsConfig = (
  currentValues?: Object,
  extraDisplayCriteria: { editMode: boolean, emailVerified: boolean, isCarer: boolean },
  taxonomiesByClassification: TaxonomiesClassificationType,
) => [
  {
    sectionId: 'personal-details',
    displayCriteria: true,
    rows: [
      {
        displayCriteria: true,
        rowId: 'name',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'first-name',
            component: 'StandardInput',
            fieldConfiguration: {
              label: 'First name',
              name: 'firstname',
              validate: [required],
              type: 'text',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'last-name',
            component: 'StandardInput',
            fieldConfiguration: {
              label: 'Last name',
              name: 'lastname',
              validate: [required],
              type: 'text',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
      {
        displayCriteria: !!extraDisplayCriteria.isCarer,
        rowId: 'preferred-name',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'preferred-name',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'preferred_name',
              label: 'Preferred name',
              type: 'text',
              placeholder: 'Leave blank to use your first name',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'title',
            component: 'StandardSelect',
            fieldConfiguration: {
              name: 'title',
              label: 'Title',
              parse: (value: string) => (value === '' ? null : value),
            },
            options: taxonomiesByClassification.user_title,
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
      {
        displayCriteria: true,
        rowId: 'email-number',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'email',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'email',
              label: 'Email',
              type: 'text',
              verified: !!extraDisplayCriteria.emailVerified,
              validate: [requiredEmail, validEmail],
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'phone-number',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'phone_number',
              label: `${!!extraDisplayCriteria.isCarer ? 'Mobile Number' : "Phone Number"}`,
              type: 'text',
              validate: [validPhoneNumber],
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
      {
        displayCriteria: !!extraDisplayCriteria.isCarer,
        rowId: 'gender',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'gender',
            component: 'StandardSelect',
            fieldConfiguration: {
              name: 'gender',
              label: 'Gender',
              parse: (value: string) => (value === '' ? null : value),
            },
            options: taxonomiesByClassification.gender,
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
      {
        displayCriteria: !!extraDisplayCriteria.isCarer,
        rowId: 'address',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'address1',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'address1',
              label: 'House name / number',
              type: 'text',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'address2',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'address2',
              label: 'Street',
              type: 'text',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
      {
        displayCriteria: !!extraDisplayCriteria.isCarer,
        rowId: 'address-continue',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'address3',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'address3',
              label: 'Additional address details',
              type: 'text',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'town',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'town',
              label: 'Town / City',
              type: 'text',
            },
            size: {
              xs: 12,
              md: 3,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'postcode',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'postcode',
              label: 'Postcode',
              type: 'text',
            },
            size: {
              xs: 12,
              md: 3,
            },
          },
        ],
      },
    ],
  },
  {
    sectionId: 'next-of-kin',
    displayCriteria: !!extraDisplayCriteria.isCarer,
    sectionHeader: 'Next of kin',
    rows: [
      {
        rowId: 'nok-name-number',
        displayCriteria: true,
        fields: [
          {
            displayCriteria: true,
            fieldId: 'nok-name',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'next_of_kin_name',
              label: 'Name',
              type: 'text',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'nok-number',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'next_of_kin_number',
              label: 'Number',
              type: 'text',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
      {
        rowId: 'nok-relationship',
        displayCriteria: true,
        fields: [
          {
            displayCriteria: true,
            fieldId: 'nok-relationship',
            component: 'StandardSelect',
            fieldConfiguration: {
              name: 'next_of_kin_relationship',
              label: 'Relationship to you',
              parse: (value: string) => (value === '' ? null : value),
            },
            options: taxonomiesByClassification.care_for,
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
    ],
  },
  {
    sectionId: 'settings',
    displayCriteria: !!extraDisplayCriteria.isCarer,
    sectionHeader: 'Account Settings',
    rows: [

      {
        displayCriteria: true,
        rowId: 'all-settings-text',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'settings-text',
            component: 'StandardTextSpan',
            fieldConfiguration: {
              text:
                "Please set to 'Yes' while still an active user of the platform to ensure optimal communications. Note this does not affect any marketing emails.",
            },
            size: {
              xs: 24,
              md: 12,
            },
          },
        ],
      },

      {
        rowId: 'email-settings',
        displayCriteria: true,
        fields: [
          {
            displayCriteria: true,
            fieldId: 'emails_non_essential_enabled',
            component: 'TrueFalseSelect',
            fieldConfiguration: {
              name: 'emails_non_essential_enabled',
              label: 'Non-essential emails enabled',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
    ],
  },
]

export default fieldsConfig;
