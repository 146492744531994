import ChargeConstants from 'shared/constants/ChargeConstants';
import { addObject, addObjects } from 'shared/reducers/reducerHelpers';

const initalState = {};

function charges(state = initalState, action) {
  switch (action.type) {
    case ChargeConstants.CHARGE_CREATE_SUCCESS:
    case ChargeConstants.CHARGE_GET_BY_ID_SUCCESS:
    case ChargeConstants.CHARGE_UPDATE_SUCCESS:
    case ChargeConstants.CHARGE_PAY_SUCCESS:
      return addObject(state, action);

    case ChargeConstants.CHARGE_SEARCH_SUCCESS:
      return addObjects(state, action);

    default:
      return state;
  }
}

export default charges;
