// @flow
import React from 'react';
import styled from 'styled-components';
// $FlowFixMe
import { Field } from 'redux-form';

import {
  convertCurrencyStringToPence,
  convertObjectCurrencyStringsToPenceInt,
  convertObjectWithPenceValuesToCurrencyString,
  currencyFormat,
} from 'components/helpers/currency';
import Form from 'components/forms/Form';
import StandardTextarea from 'components/forms/StandardTextarea';
import { Col, Container, Row } from 'components/grid';
import JobOfferDetails from 'components/jobOffers/JobOfferDetails';
import {
  BorderedModuleSeparator,
  ModuleSeparator,
} from 'components/global/layout/Module';
import Button from 'components/buttons/Button';
import ButtonGroup from 'components/buttons/ButtonGroup';

import type {
  BidType,
  JobOfferType,
  RateLimitType,
  ScheduleType,
  TaxonomiesClassificationType,
} from 'shared/types';

const JobOfferSection = styled.section`
  padding: 20px;
`;

type Props = {|
  bid: BidType,
  editing: boolean,
  jobOffer: JobOfferType,
  ratesCurrencyConverted: { [key: string]: string },
  rateLimits: RateLimitType,
  schedule: ScheduleType,
  taxonomiesByClassification: TaxonomiesClassificationType,
  toggleEditingState: boolean => void,
  updateJobOffer: (string, Object) => void,
|};

const JobSummaryFormEdit = ({
  bid,
  editing,
  jobOffer,
  schedule,
  taxonomiesByClassification,
  toggleEditingState,
  updateJobOffer,
}: Props) => {
  const onSubmit = async formData => {
    const convertedData = { ...formData };
    convertedData.rates = convertObjectCurrencyStringsToPenceInt(
      formData.rates,
    );

    convertedData.incentive_supercarers = convertedData.priority
      ? convertCurrencyStringToPence(formData.incentive_supercarers)
      : 0;

    updateJobOffer(jobOffer.id, convertedData);
    toggleEditingState(false);
  };

  const convertedShiftTypes = [
    ...new Set(
      jobOffer.shift_types.map(shift =>
        shift === 'half_livein' ? 'livein' : shift,
      ),
    ),
  ];

  const ratesCurrencyConverted = convertObjectWithPenceValuesToCurrencyString(
    jobOffer.rates,
    convertedShiftTypes,
  );

  const initialValues = {
    admin_filters: jobOffer.admin_filters,
    description: jobOffer.description,
    incentive_customer: jobOffer.incentive_customer,
    incentive_supercarers: currencyFormat(jobOffer.incentive_supercarers),
    priority: jobOffer.priority,
    rates: ratesCurrencyConverted,
    state: jobOffer.state,
    title: jobOffer.title,
  };

  return (
    <JobOfferSection>
      <Form
        name="JobPreviewFormEdit"
        destroyOnUnmount
        fields={Object.keys(initialValues)}
        selectFields={['priority', 'rates']}
        values={initialValues}
        onSubmit={onSubmit}
        component={({ currentValues, handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit} id="admin-needs-form">
            <Container>
              <JobOfferDetails
                bid={bid}
                jobOffer={jobOffer}
                schedule={schedule}
                taxonomiesByClassification={taxonomiesByClassification}
                editing={editing}
              />
              <BorderedModuleSeparator />
              <ModuleSeparator />
              <Row>
                <Col>
                  <Field
                    label="Description"
                    component={StandardTextarea}
                    name="description"
                    wysiwyg
                  />
                </Col>
              </Row>
              <ModuleSeparator />
              <Row>
                <ButtonGroup>
                  <Button disabled={pristine} type="submit">
                    Save changes
                  </Button>
                  <Button
                    type="button"
                    className="altRed"
                    onClick={() => toggleEditingState(false)}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </Row>
            </Container>
          </form>
        )}
      />
    </JobOfferSection>
  );
};

export default JobSummaryFormEdit;
