import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Module from 'components/global/layout/Module';
import ReferenceFormContainer from 'containers/references/ReferenceFormContainer';

class ReferenceEdit extends Component {
  static propTypes = {
    carerID: PropTypes.string.isRequired,
    initialValues: PropTypes.shape(),
    isAdmin: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    ready: PropTypes.bool.isRequired,
    workHistory: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    initialValues: null,
  };

  render() {
    return (
      <Module>
        <ReferenceFormContainer
          carerID={this.props.carerID}
          onSubmit={this.props.onSubmit}
          initialValues={this.props.initialValues}
          isAdmin={this.props.isAdmin}
          ready={this.props.ready}
          workHistory={this.props.workHistory}
          editMode
        />
      </Module>
    );
  }
}

export default ReferenceEdit;
