import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import StyledSelect from 'components/forms/StyledSelect';
import { Container, Row, Col, Visible, Hidden } from 'components/grid';

const StyledToolbarRow = styled(Row)`
  font-size: 16px;
  margin-bottom: 10px;
`;

const StyledToolbarLabelCol = styled(Col)`
  margin: auto;
  font-weight: bold;
  text-align: center;
`;

const StyledToolbarNavButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledToolbarNavButton = styled.div`
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  line-height: 38px;
`;

const StyledSmallButton = styled(StyledToolbarNavButton)`
  background: ${props => props.theme.colors.lightGrey};
  border-color: ${props => props.theme.colors.midLightGrey};
  border-radius: 4px;
  padding: 0 10px;
`;

const StyledTextButton = styled(StyledToolbarNavButton)`
  background: none;
  border: none;
  text-decoration: underline;
`;

class CustomCalendarToolbar extends Component {
  static propTypes = {
    view: PropTypes.string.isRequired,
    views: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.node.isRequired,
    localizer: PropTypes.object,
    onNavigate: PropTypes.func.isRequired,
    onViewChange: PropTypes.func.isRequired,
  };

  render() {
    let { label } = this.props;

    const toolbarLabel = (
      <StyledToolbarLabelCol sm={6}>
        <span>{label}</span>
      </StyledToolbarLabelCol>
    );

    return (
      <Container>
        <StyledToolbarRow>
          <Col xs={6} sm={3}>
            <StyledToolbarNavButtons>
              <StyledTextButton onClick={this.navigate.bind(null, 'PREV')}>
                Back
              </StyledTextButton>
              <StyledSmallButton onClick={this.navigate.bind(null, 'TODAY')}>
                Today
              </StyledSmallButton>
              <StyledTextButton onClick={this.navigate.bind(null, 'NEXT')}>
                Next
              </StyledTextButton>
            </StyledToolbarNavButtons>
          </Col>
          <Hidden xs>{toolbarLabel}</Hidden>
          <Col xs={6} sm={3}>
            <span>{this.viewNamesGroup()}</span>
          </Col>
        </StyledToolbarRow>
        <Visible xs>
          <StyledToolbarRow>{toolbarLabel}</StyledToolbarRow>
        </Visible>
      </Container>
    );
  }

  // The following is react-big-calendar functionality
  navigate = action => {
    this.props.onNavigate(action);
  };

  view = event => {
    this.props.onViewChange(event.target.value);
  };

  viewNamesGroup(messages) {
    let viewNames = this.props.views;
    const view = this.props.view;
    return (
      <StyledSelect
        fontSize="16px"
        padding="8px 35px 8px 10px"
        value={view}
        onChange={this.view}
      >
        {viewNames.map(name => (
          <option key={name} value={name}>
            {name.charAt(0).toUpperCase() + name.slice(1)}
          </option>
        ))}
      </StyledSelect>
    );
  }
}

export default CustomCalendarToolbar;
