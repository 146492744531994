import React from 'react';

import Button from 'components/buttons/Button';
import AddPaymentMethod from 'components/payments/AddPaymentMethod';

const PaymentButton = ({
  authenticateViaStripe,
  pay,
  paymentMethods,
  timesheets,
  user,
}) => {
  const paymentMethod = paymentMethods && paymentMethods[0];

  const paymentIntentExists =
    timesheets && timesheets.some(timesheet => timesheet.paymentIntent);

  if (!paymentMethod) {
    return <AddPaymentMethod user={user} />;
  }

  if (paymentIntentExists && timesheets.length === 1) {
    const { paymentIntent } = timesheets[0];
    return (
      <>
        <Button
          onClick={() =>
            authenticateViaStripe(
              paymentIntent.client_secret,
              paymentMethod.stripe_id,
            )
          }
        >
          Authenticate Payment
        </Button>
        <p style={{ fontSize: '13px', textAlign: 'left' }}>
          {paymentMethod.brand} ending with {paymentMethod.last4}
        </p>
      </>
    );
  }

  if (!paymentIntentExists) {
    return (
      <>
        <Button onClick={pay}>Pay Now</Button>
        <p style={{ fontSize: '13px', textAlign: 'left' }}>
          {paymentMethod.brand} ending with {paymentMethod.last4}
        </p>
      </>
    );
  }

  return null;
};

export default PaymentButton;
