import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';

import { required } from 'validators';
import { Container, Col } from 'components/grid';
import { ModuleHeader } from 'components/global/layout/Module';
import { TaxonomySelect } from 'components/forms/ConvenienceSelects';
import FormRow from 'components/forms/FormRow';
import StandardTextarea from 'components/forms/StandardTextarea';
import Form from 'components/forms/Form';

import ButtonGroup from 'components/buttons/ButtonGroup';
import Button from 'components/buttons/Button';

class AdminReferenceResponseForm extends Component {
  static propTypes = {
    initialValues: PropTypes.shape(),
    onSubmit: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    initialValues: null,
  };

  render() {
    const { initialValues } = this.props;

    return (
      <Form
        name="referenceResponse"
        values={initialValues}
        destroyOnUnmount
        enableReinitialize={false}
        fields={[
          'response',
          'description',
        ]}
        onSubmit={this.props.onSubmit}
        component={({ handleSubmit, pristine, currentValues }) => (
          <form onSubmit={handleSubmit}>
            <ModuleHeader>Add response</ModuleHeader>
            <Container>
              <FormRow>
                <Col>
                  <Field
                    component={StandardTextarea}
                    name="description"
                    label="Add reference info"
                    validate={[required]}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col xs={12} md={6}>
                  <Field
                    component={TaxonomySelect}
                    name="response"
                    type="text"
                    label="Flag as"
                    taxonomy={this.props.taxonomiesByClassification.reference_responses}
                    validate={[required]}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col xs={12}>
                  <ButtonGroup>
                    <Button disabled={pristine} type="submit">
                      Submit
                    </Button>
                  </ButtonGroup>
                </Col>
              </FormRow>
            </Container>
          </form>
        )}
      />
    );
  }
}

export default AdminReferenceResponseForm;
