// @flow
import CareNeedConstants from 'shared/constants/CareNeedConstants';
import {
  addObject,
  addObjects,
  deleteObject,
  setLoadingState,
  createReducer,
} from 'shared/reducers/reducerHelpers';
import type {
  GetAccountOwnerAction,
  GetNeedSuccessAction,
  NeedAction,
  NeedsState,
} from 'shared/types';

function addNeeds(state: NeedsState, action: GetNeedSuccessAction) {
  let newState = { ...state };
  action.data.forEach(need => {
    newState = {
      ...newState,
      [need.id]: need,
    };
  });
  return newState;
}

function addAccountOwners(state: NeedsState, action: GetAccountOwnerAction) {
  const newState = setLoadingState(state, 'accountOwners', false);
  return {
    ...newState,
    accountOwners: {
      ...state.accountOwners,
      [action.needID]: action.data,
    },
  };
}

const initialState: NeedsState = {
  accountOwners: {},
  loading: {
    accountOwners: false,
  },
};

const handlers: { [string]: ({}, NeedAction) => {} } = {
  [CareNeedConstants.CARE_NEED_CONVERT_SUCCESS]: addObject,
  [CareNeedConstants.CARE_NEED_CREATE_SUCCESS]: addObject,
  [CareNeedConstants.CARE_NEED_GET_ALL_SUCCESS]: addObject,
  [CareNeedConstants.CARE_NEED_GET_BY_ID_SUCCESS]: addObject,
  [CareNeedConstants.CARE_NEED_UPDATE_SUCCESS]: addObject,
  [CareNeedConstants.CARE_NEEDS_GET_BY_CARER_SUCCESS]: addNeeds,
  [CareNeedConstants.CARE_NEEDS_GET_BY_USER_SUCCESS]: addNeeds,
  [CareNeedConstants.CARE_NEED_ARCHIVE_SUCCESS]: deleteObject,
  [CareNeedConstants.CARE_NEED_SEARCH_SUCCESS]: addObjects,
  [CareNeedConstants.CARE_NEED_SEARCH_SUCCESS]: addObjects,
  [CareNeedConstants.CARE_NEED_RECALCULATE_TOTAL_SUCCESS]: addObject,
  [CareNeedConstants.CARE_NEED_GET_ACCOUNT_OWNER]: (state, action) => {
    return setLoadingState(state, 'accountOwners', true);
  },
  [CareNeedConstants.CARE_NEED_GET_ACCOUNT_OWNER_FAILURE]: (state, action) => {
    return setLoadingState(state, 'accountOwners', false);
  },
  [CareNeedConstants.CARE_NEED_GET_ACCOUNT_OWNER_SUCCESS]: (
    state: NeedsState,
    action: GetAccountOwnerAction,
  ) => {
    return addAccountOwners(
      setLoadingState(state, 'accountOwners', false),
      action,
    );
  },
};

const needs = createReducer(initialState, handlers);

export default needs;
