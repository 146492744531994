import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  createPaymentMethod,
  getUserPaymentMethods,
  removePaymentMethod,
} from '_actions/paymentActions';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AccountPaymentMethods from 'components/account/AccountPaymentMethods';

import { getUser } from 'shared/selectors/accountSelectors';
import { getPaymentMethodsByCurrentUser } from 'shared/selectors/paymentSelectors';

class AccountPaymentMethodsContainer extends Component {
  static propTypes = {
    paymentMethods: PropTypes.arrayOf(PropTypes.shape()),
    user: PropTypes.shape(),
    createPaymentMethod: PropTypes.func.isRequired,
    getUserPaymentMethods: PropTypes.func.isRequired,
    removePaymentMethod: PropTypes.func.isRequired,
  };

  static defaultProps = {
    paymentMethods: null,
    user: null,
  };

  componentDidMount() {
    this.props.getUserPaymentMethods(this.props.user.id);
  }

  render() {
    if (!this.props.user || !this.props.paymentMethods) {
      return <LoadingPlaceholder />;
    }

    return (
      <AccountPaymentMethods
        user={this.props.user}
        paymentMethods={this.props.paymentMethods}
        removePaymentMethod={this.props.removePaymentMethod}
        createPaymentMethod={this.props.createPaymentMethod}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    paymentMethods: getPaymentMethodsByCurrentUser(state),
    user: getUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createPaymentMethod,
      getUserPaymentMethods,
      removePaymentMethod,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountPaymentMethodsContainer);
