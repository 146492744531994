import { createSelector } from 'reselect';
import { getTimesheetByID } from 'shared/selectors/timesheetSelectors';

export const getTimesheetTotals = state => state.totals.timesheets;

export const getTimesheetTotalByID = createSelector(
  [getTimesheetTotals, getTimesheetByID],
  (totals, timesheet) => {
    if (!totals || !timesheet) {
      return null;
    }
    return totals[timesheet.id];
  },
);
