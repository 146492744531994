import React, { Component } from 'react';
import { Field } from 'redux-form';
import currency from 'currency.js';

class CurrencyField extends Component {
  initialFormat = true;

  format = value => {
    if (this.initialFormat) {
      // intial format should be to two decimal places
      this.initialFormat = false;
      return currency(value).divide(100);
    }
    // Can't use currency library here as it will override actual user input
    return value === '' ? '' : value / 100;
  };

  parse = value => (value === '' ? '' : currency(value).multiply(100));

  render() {
    return (
      <Field
        format={this.format}
        parse={this.parse}
        prefix="£"
        step="0.01"
        type="number"
        {...this.props}
      />
    );
  }
}

export default CurrencyField;
