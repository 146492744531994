import PropTypes from 'prop-types';
import React from 'react';

const DeleteIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="delete-icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-5.000000, -3.000000)">
          <path
            d="M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,7 L6,7 L6,19 Z M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 Z"
            id="Shape"
            fill="#9B9B9B"
            fillRule="nonzero"
          />
          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
        </g>
      </g>
    </svg>
  </div>
);

DeleteIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

DeleteIcon.defaultProps = {
  className: '',
  height: '37px',
  width: '37px',
};

export default DeleteIcon;
