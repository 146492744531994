import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Hours extends Component {
  render() {
    const hours = this.props.minutes === 60 ? 'hour' : 'hours';
    return (
      <span>
        {this.props.minutes / 60} {hours}
      </span>
    );
  }
}

Hours.propTypes = {
  minutes: PropTypes.number.isRequired,
};

export default Hours;
