// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { getCarerConflictsWithBids } from '_actions/carerActions';
import { getCareTeamsByCarerId } from '_actions/careTeamActions';
import {
  selectCarerConflictsByID,
  selectCarerBidConflictsByID,
} from 'shared/selectors/carerSelectors';
import { getCarerTeams } from 'shared/selectors/teamSelectors';

import AdminUserTeams from 'components/users/AdminUserTeams';
import BidConflictsContainer from 'containers/users/BidConflictsContainer';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import Module from 'components/global/layout/Module';

import type { ConflictType, TeamType, UserType } from 'shared/types';

const StyledModule = styled(Module)`
  padding: 30px 0 0;
`;

type Props = {
  getCareTeamsByCarerId: string => Object,
  getCarerConflictsWithBids: string => Object,
  bidConflicts: Array<string>,
  conflicts: ConflictType,
  user: UserType,
  teams: Array<TeamType>,
};

class AdminUserTeamsContainer extends Component<Props> {
  static defaultProps = { teams: null };

  componentDidMount() {
    const { user } = this.props;
    this.props.getCareTeamsByCarerId(user.id);
    this.props.getCarerConflictsWithBids(user.id);
  }

  render() {
    const { bidConflicts, conflicts, teams, user } = this.props;

    if (!teams || !conflicts) {
      return <LoadingPlaceholder />;
    }

    return (
      <StyledModule>
        <BidConflictsContainer
          user={user}
          bidConflicts={bidConflicts}
          conflictingBids={conflicts.conflicting_bids}
        />
        <AdminUserTeams teams={teams} />
      </StyledModule>
    );
  }
}

const mapStateToProps = (state, props) => ({
  bidConflicts: selectCarerBidConflictsByID(state, props.user.id),
  conflicts: selectCarerConflictsByID(state, props.user.id),
  teams: getCarerTeams(state, props.user.id),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCareTeamsByCarerId,
      getCarerConflictsWithBids,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserTeamsContainer);
