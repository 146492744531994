import { createSelector } from 'reselect';

export const getTaxonomiesState = state => state.taxonomies;

export const getTaxonomies = createSelector(
  [getTaxonomiesState],
  taxonomies => {
    if (!taxonomies) {
      return null;
    }

    return taxonomies.taxonomies;
  },
);

export const getTaxonomiesByClassifications = (state, classifications) => {
  if (!state.taxonomies || !state.taxonomies.taxonomies) return null;

  const taxonomiesByClassification = {};
  classifications.forEach(classification => {
    return (taxonomiesByClassification[classification] =
      state.taxonomies.taxonomies[classification]);
  });

  return taxonomiesByClassification;
};
