//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { searchFranchise } from '_actions/franchiseActions';

import AdminFranchiseSearch from 'components/admin/franchise/AdminFranchiseSearch';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { getFranchiseResults, getFranchiseSearch } from 'shared/selectors/franchiseSelectors';

import type {
  LocationType,
  SearchType,
  TaxonomiesClassificationType,
} from 'shared/types';

type Props = {|
  franchises: Array<Object>,
  location: LocationType,
  search: SearchType,
  searchFranchise: (Object, number | string) => Object,
  taxonomiesByClassification: TaxonomiesClassificationType,
|};

type State = {
  searchParams: Object,
};


export class AdminFranchiseSearchContainer extends Component<Props, State>  {

  static defaultProps = {
    franchises: null,
    search: null,
    taxonomiesByClassification: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'account_manager',
      'franchise_country',
      'franchise_state',
    ];
  }

  state = {
    searchParams: {},
  };

  componentDidUpdate(prevProps: Props) {
    const page = this.props.location.query.page;
    if (page && page !== prevProps.location.query.page) {
      this.search();
    }
  }

  search = (searchParams?: Object) => {
    const page = searchParams ? 1 : this.props.location.query.page;

    this.props.searchFranchise(searchParams || this.state.searchParams, page);

    if (searchParams) {
      this.setState({ searchParams });
    }

  };

  render() {

    const {
      search,
      franchises,
      location,
      taxonomiesByClassification,
    } = this.props;

    if (!taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminFranchiseSearch
        franchises={franchises}
        location={location}
        search={search}
        searchFranchise={this.search}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

const mapStateToProps = state => ({
  franchises: getFranchiseResults(state),
  search: getFranchiseSearch(state),
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    AdminFranchiseSearchContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchFranchise,
    },
    dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminFranchiseSearchContainer);
