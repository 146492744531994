import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';

import { SC_NAME } from 'components/global/ContactInfo';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import { Container, Row, Col } from 'components/grid';

import { isAgent } from 'shared/selectors/accountSelectors.js';
import { isRejectedCarer } from 'shared/selectors/carerSelectors.js';

class NotAuthorisedContainer extends Component {
  static propTypes = {
    isAgent: bool.isRequired,
    isRejectedCarer: bool.isRequired,
  };

  render() {
    return (
      <Module>
        <ModuleHeader>Not Authorised</ModuleHeader>
        <Container>
          {this.props.isRejectedCarer && (
            <Row>
              <Col>
                You are no longer able to view or apply for jobs, as you have
                been removed from the {SC_NAME} platform. Please refer to our
                email for more information on this decision.
              </Col>
            </Row>
          )}
          {this.props.isAgent && (
            <Row>
              <Col>
                You’re not authorised to view this page. Please get in touch
                with our team if you have any questions.
              </Col>
            </Row>
          )}
        </Container>
      </Module>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAgent: isAgent(state),
    isRejectedCarer: isRejectedCarer(state),
  };
}

export default connect(mapStateToProps)(NotAuthorisedContainer);
