import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { object as obj, shape, string } from 'prop-types';

import {
  CalendarIcon,
  InlineStyledIcon,
} from 'components/global/icons';
import { LocationIcon } from 'components/global/icons/small';

const JobDetailColumn = styled.div`
  display: block;
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-basis: 50%;
    margin-right: 10px;
  }
`;

const JobDetailRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 3px;
  position: relative;
  &.immediate {
    font-weight: bold;
    font-style: italic;
  }
  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
`;

const JobStartIcon = styled(CalendarIcon)`
  margin-right: 7px;
  display: flex;
`;

class JobLocationDateDuration extends Component {
  static propTypes = {
    bid: shape({
      id: string.isRequired,
      state: string.isRequired,
    }).isRequired,
    jobOffer: obj.isRequired,
    schedule: obj,
  };

  static defaultProps = {
    schedule: {},
  };

  getJobLength(bid, schedule) {
    if (bid && bid.totals) {
      return this.getJobLengthStringFromBid(bid.totals);
    }
    if (Object.keys(schedule).length) {
      return this.determineJobLength(schedule);
    }
    return null;
  }

  createJobLengthString(schedule, unit) {
    let totalLength = moment(schedule.end)
      .endOf('day')
      .diff(moment(schedule.start).startOf('day'), unit, true);
    totalLength =
      unit === 'day' ? Math.round(totalLength) : Math.floor(totalLength);

    let lengthString = totalLength === 1 ? unit : unit + 's';

    if (totalLength > 0) {
      return { string: ` for ${totalLength} ${lengthString}`, unit };
    } else {
      let newUnit;
      switch (unit) {
        case 'month':
          newUnit = 'week';
          break;
        case 'week':
          newUnit = 'day';
          break;
        default:
          return null;
      }

      return this.createJobLengthString(schedule, newUnit);
    }
  }

  determineJobLength(schedule) {
    let duration = 0;
    Object.values(schedule.shifts).forEach(week => {
      Object.values(week).forEach(day => {
        duration = day.reduce((accum, value) => {
          return accum + value.duration;
        }, duration);
      });
    });

    if (duration === 0) {
      return null;
    }

    let durationString = `${duration / 60} hours`;

    const numWeeks = schedule.repeat_every_n_weeks;
    let weekString = numWeeks > 1 ? `over ${numWeeks} weeks` : 'a week';

    let jobLength;
    if (schedule.start && schedule.end) {
      jobLength = this.createJobLengthString(schedule, 'month');
      if (jobLength && jobLength.unit === 'day') {
        weekString = '';
      }
    }

    return `${durationString} ${weekString} ${
      jobLength ? jobLength.string : ''
    }`;
  }

  getJobLengthStringFromBid(totals) {
    if (totals.days_per_week) {
      return `${totals.days_per_week} days a week`;
    }
    if (totals.minutes_per_week) {
      return `${Math.floor(totals.minutes_per_week / 60)} hours a week`;
    }
    return null;
  }

  render() {
    const { bid, jobOffer } = this.props;
    const jobOfferDate = moment(jobOffer.start_date).format('Do MMMM');
    const jobLocation = `${jobOffer.location} (${jobOffer.outcode}) ${
      bid && bid.distance ? `approx ${bid.distance.toFixed(0)} miles` : ''
    }`;

    return (
      <JobDetailColumn>
        <InlineStyledIcon
          style={{ marginLeft: '3px' }}
          Icon={LocationIcon}
          label={jobLocation}
        />
        {jobOffer.start_date !== '' && (
          <JobDetailRow
            className={moment().isAfter(jobOffer.start_date) ? 'immediate' : ''}
          >
            <JobStartIcon width="18px" height="21px" />
            {moment().isAfter(jobOffer.start_date)
              ? 'Immediately'
              : jobOfferDate}
          </JobDetailRow>
        )}
      </JobDetailColumn>
    );
  }
}

export default JobLocationDateDuration;
