import PropTypes from 'prop-types';
import React, { Component } from 'react';

class TextInput extends Component {
  static propTypes = {
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    onSave: null,
    onCancel: null,
    placeholder: '',
  };

  state = {
    message: '',
  };

  onSave = () => {
    this.props.onSave(this.state.message);
    this.setState({ message: '' });
  };

  updateMessage = event => {
    this.setState({ message: event.target.value });
  };

  render() {
    return (
      <div>
        <textarea
          value={this.state.message}
          onChange={this.updateMessage}
          placeholder={this.props.placeholder}
        />
        <div className="old-row controls">
          {this.props.onCancel && (
            <div className="old-col col-33">
              <button className="btn btn-alt" onClick={this.props.onCancel}>
                Cancel
              </button>
            </div>
          )}
          {this.props.onSave && (
            <div className="old-col col-66">
              <button
                className="btn"
                disabled={!this.state.message}
                onClick={this.onSave}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TextInput;
