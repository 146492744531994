import CareNeedConstants from 'shared/constants/CareNeedConstants';
import InvoiceConstants from 'shared/constants/InvoiceConstants';
import TimesheetConstants from 'shared/constants/TimesheetConstants';
import JobOfferConstants from 'shared/constants/JobOfferConstants';
import { updateObject } from 'shared/reducers/reducerHelpers';

const initalState = {
  needs: null,
  invoices: {},
  jobOffers: null,
  timesheets: {},
};

function addSummary(state, action, category) {
  const updatedSummaries = updateObject(state[category], {
    [action.isoweek]: action.data,
  });
  return { ...state, [category]: updatedSummaries };
}

function summaries(state = initalState, action) {
  switch (action.type) {
    case InvoiceConstants.INVOICE_SUMMARY_SUCCESS:
      return addSummary(state, action, 'invoices');

    case TimesheetConstants.TIMESHEET_SUMMARY_SUCCESS:
      return addSummary(state, action, 'timesheets');

    case CareNeedConstants.CARE_NEED_GET_STATUS_SUCCESS:
      return updateObject(state, { needs: action.data });

    case JobOfferConstants.JOB_OFFER_GET_STATUS_SUCCESS:
      return updateObject(state, { jobOffers: action.data });

    default:
      return state;
  }
}

export default summaries;
