import UIConstants from 'shared/constants/UIConstants';

const initalState = {
  primaryMenuOpen: false,
};

function ui(state = initalState, action) {
  switch (action.type) {
    case UIConstants.OPEN_PRIMARY_MENU:
      // Can't access body via react have to hack it in here
      document.body.style.overflowY = 'hidden';
      return { ...state, primaryMenuOpen: true };

    case '@@router/LOCATION_CHANGE':
    case UIConstants.CLOSE_PRIMARY_MENU:
      // Can't access body via react have to hack it in here
      document.body.style.overflowY = 'visible';
      return { ...state, primaryMenuOpen: false };

    default:
      return state;
  }
}

export default ui;
