// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';

const StyledRadioContainer = styled.div`
  display: inline-block;
  height: 80px;
  width: 80px;
  margin-right: 10px;
  position: relative;

  input[type='radio']:checked ~ label {
    opacity: 1;
    border: 2px solid ${props => props.theme.colors.hiAccentBlue};
    box-shadow: 0 1px 6px 0 ${props => props.theme.colors.shadowBlue};
    color: ${props => props.theme.colors.hiAccentBlue};
  }

  .icon {
    position: absolute;
    top: 10px;
    width: 40px;
    height: 40px;
    left: 20px;
    right: 20px;
    text-align: center;
  }
`;

const StyledLabel = styled.label`
  border: 2px solid #d4e4e7;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    border: 2px solid #d4e4e7;
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
`;
type Props = {
  checked?: boolean,
  Icon: ComponentType<any>,
  onChange: (event: SyntheticEvent<>) => void,
  name: string,
  value: string,
  label: string,
};

const StyledRadioWithIcon = ({
  checked,
  Icon,
  onChange,
  name,
  value,
  label,
}: Props) => {
  return (
    <StyledRadioContainer>
      <StyledInput
        type="radio"
        id={`${name}-${value}`}
        name={`${name}`}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <StyledLabel htmlFor={`${name}-${value}`}>
        <Icon height="40" width="40" className="icon" />
        {label}
      </StyledLabel>
    </StyledRadioContainer>
  );
};

export default StyledRadioWithIcon;
