// @flow
import { required, validEmail } from 'validators';

import type { TaxonomiesClassificationType } from 'shared/types';

const referenceSendFormFieldsConfig = (
  currentValues: Object,
  extraDisplayCriteria: {},
  taxonomiesByClassification: TaxonomiesClassificationType,
) => [
  {
    displayCriteria: true,
    sectionHeader: 'Send Reference to another Company',
    sectionId: 'email',
    rows: [

      {
        displayCriteria: true,
        rowId: 'context',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'applicant-invite-text',
            component: 'StandardTextSpan',
            fieldConfiguration: {
              text: "Enter email of person/company requesting SuperCarers reference for this carer.",
            },
            size: {
              xs: 24,
              md: 12,
            },
          },
        ],
      },

      {
        displayCriteria: true,
        rowId: 'contact-information',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'contact-email',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'email',
              label: 'Email Address',
              validate: [validEmail, required],
              type: 'text',
            },
            size: {
              xs: 12,
              md: 5,
            },
          },
        ]
      },
    ]
  },
];

export default referenceSendFormFieldsConfig;
