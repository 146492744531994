import { all, takeLatest, take, put, select } from 'redux-saga/effects';

import { getCarerById } from '_actions/carerActions';
import {
  createCareTeam,
  getCareTeamByNeed,
  updateCareTeam,
  updateCareTeamMemberState,
} from '_actions/careTeamActions';
import { updateBid } from '_actions/bidActions';
import { selectBidByJobOfferAndCarerID } from 'shared/selectors/bidsForJobsSelectors';
import CarerConstants from 'shared/constants/CarerConstants';
import CareTeamConstants from 'shared/constants/CareTeamConstants';

export function* createTeamAndUpdateMember(action) {
  try {
    yield put(
      createCareTeam({ need_id: action.data.needId, team: action.data.team }),
    );
    const result = yield take([
      CareTeamConstants.CARETEAM_CREATE_SUCCESS,
      CareTeamConstants.CARETEAM_CREATE_FAILURE,
    ]);
    if (result.type === CareTeamConstants.CARETEAM_CREATE_SUCCESS) {
      yield put(
        updateCareTeamMemberState(
          result.data.id,
          action.data.team[0],
          action.data.memberData,
        ),
      );
    } else {
      yield put({
        type: CareTeamConstants.CARETEAM_CREATE_AND_UPDATE_MEMBER_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: CareTeamConstants.CARETEAM_CREATE_AND_UPDATE_MEMBER_FAILURE,
    });
  }
}

export function* createTeamAndUpdateMemberListener() {
  yield takeLatest(
    CareTeamConstants.CARETEAM_CREATE_AND_UPDATE_MEMBER,
    createTeamAndUpdateMember,
  );
}

export function* updateTeamAndMember(action) {
  try {
    yield put(updateCareTeam(action.data.teamId, { team: action.data.team }));
    const result = yield take([
      CareTeamConstants.CARETEAM_UPDATE_SUCCESS,
      CareTeamConstants.CARETEAM_UPDATE_FAILURE,
    ]);
    if (result.type === CareTeamConstants.CARETEAM_UPDATE_SUCCESS) {
      yield put(
        updateCareTeamMemberState(
          result.data.id,
          action.data.carerId,
          action.data.memberData,
        ),
      );
    } else {
      yield put({ type: CareTeamConstants.CARETEAM_AND_MEMBER_UPDATE_FAILURE });
    }
  } catch (error) {
    yield put({ type: CareTeamConstants.CARETEAM_AND_MEMBER_UPDATE_FAILURE });
  }
}

export function* updateTeamAndMemberListener() {
  yield takeLatest(
    CareTeamConstants.CARETEAM_AND_MEMBER_UPDATE,
    updateTeamAndMember,
  );
}

export function* getTeamMembersForTeam(action) {
  const team = action.data.team;
  if (team) {
    yield all(team.map(carerId => put(getCarerById(carerId))));
  }
}

export function* getTeamMembersForTeamListener() {
  yield takeLatest(
    CareTeamConstants.CARETEAM_GET_TEAMS_BY_OWNER_SUCCESS,
    getTeamMembersForTeam,
  );
}

export function* getCareTeamByNeedWithCarers(action) {
  yield put(getCareTeamByNeed(action.payload.needId));
  const result = yield take([
    CareTeamConstants.CARETEAM_GET_BY_NEED_SUCCESS,
    CareTeamConstants.CARETEAM_GET_BY_NEED_FAILURE,
  ]);

  if (result.type === CareTeamConstants.CARETEAM_GET_BY_NEED_SUCCESS) {
    yield getTeamMembersForTeam(result);

    yield all(
      Array(result.data.team.length).fill(
        take(CarerConstants.CARER_GET_BY_ID_SUCCESS),
      ),
    );

    yield put({
      type: CareTeamConstants.CARETEAM_GET_BY_NEED_WITH_CARERS_SUCCESS,
    });
  } else {
    yield put({
      type: CareTeamConstants.CARETEAM_GET_BY_NEED_WITH_CARERS_FAILURE,
    });
  }
}

export function* getCareTeamByNeedWithCarersListener() {
  yield takeLatest(
    CareTeamConstants.CARETEAM_GET_BY_NEED_WITH_CARERS,
    getCareTeamByNeedWithCarers,
  );
}

export function* removeTeamMember(action) {
  try {
    yield put(updateCareTeam(action.data.teamID, { team: action.data.team }));
    const result = yield take([
      CareTeamConstants.CARETEAM_UPDATE_SUCCESS,
      CareTeamConstants.CARETEAM_UPDATE_FAILURE,
    ]);
    if (result.type === CareTeamConstants.CARETEAM_UPDATE_SUCCESS) {
      const bidForCarer = yield select(
        selectBidByJobOfferAndCarerID,
        action.data.jobOfferID,
        action.data.carerID,
      );

      if (bidForCarer) {
        yield put(updateBid(bidForCarer.id, { state: 'accepted' }));
      }
      yield put({ type: CareTeamConstants.CARETEAM_REMOVE_MEMBER_SUCCESS });
    }
  } catch (error) {
    yield put({ type: CareTeamConstants.CARETEAM_REMOVE_MEMBER_FAILURE });
  }
}

export function* removeTeamMemberListener() {
  yield takeLatest(CareTeamConstants.CARETEAM_REMOVE_MEMBER, removeTeamMember);
}
