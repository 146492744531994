import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';
import toTitleCase from 'components/helpers/toTitleCase';

class TimesheetResultRow extends Component {
  render() {
    const timesheet = this.props.timesheet;
    const startDate = moment(timesheet.start_date).format('DD-MM-YYYY');
    const shifts = timesheet.shifts || [];

    return (
      <tr>
        <td>{timesheet.iso_week}</td>
        <td>{startDate}</td>
        <td>
          <span className={`status ${timesheet.state}`}>
            {toTitleCase(timesheet.state)}
          </span>
        </td>
        <td>{timesheet.customer_name}</td>
        <td>{timesheet.need_name}</td>
        <td>{timesheet.carer_name}</td>
        <td>{shifts.length}</td>
        <td>
          <Link to={`/timesheets/${this.props.id}/confirm/`}>Timesheet</Link>
        </td>
      </tr>
    );
  }
}

TimesheetResultRow.propTypes = {
  timesheet: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
};

TimesheetResultRow.defaultProps = {
  carer: null,
};

export default TimesheetResultRow;
