import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getUser } from 'shared/selectors/accountSelectors';

function TimesheetLink({ timesheetId, user }) {
  return (
    <a
      href={`${
        window.global.SUPERCARERS_API_URL
      }/timesheet/${timesheetId}/view/pdf/?token=${user.access_token}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Download as PDF
    </a>
  );
}

function mapStateToProps(state) {
  return {
    user: getUser(state),
  };
}

TimesheetLink.propTypes = {
  timesheetId: PropTypes.string.isRequired,
  user: PropTypes.shape(),
};

TimesheetLink.defaultProps = {
  user: null,
};

export default connect(mapStateToProps)(TimesheetLink);
