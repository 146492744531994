// @flow
import React, { Component } from 'react';
import { Container, Row, Col } from 'components/grid';
import styled, { css, type Interpolation } from 'styled-components';
import { PortalWithState } from 'react-portal';

import ButtonLink from 'components/buttons/ButtonLink';
import CarerShareDialog from 'components/carers/CarerShareDialog';
import FeaturedTestimonialsContainer from 'containers/carers/testimonials/FeaturedTestimonialsContainer';
import Modal from 'components/modules/Modal';
import Module from 'components/global/layout/Module';
import ProfileCareTypes from 'components/carers/profile/ProfileCareTypes';
import ProfileHeader from 'components/carers/profile/ProfileHeader';
import ProfileOverview from 'components/carers/profile/ProfileOverview';
import ProfilePersonal from 'components/carers/profile/ProfilePersonal';
import ProfileSummary from 'components/carers/profile/ProfileSummary';

import type { CarerType, TaxonomiesClassificationType } from 'shared/types';

const StyledRow = styled(Row)`
  > div + div {
    border-left: 1px solid ${props => props.theme.colors.uiGrey};
  }
`;

const StyledMainSection = styled(Col)`
  > div {
    padding: 20px 35px;
  }

  > div + div {
    border-top: 1px solid ${props => props.theme.colors.uiGrey};
  }
`;

const StyledSideBar = styled(Col)`
  > div {
    padding: 20px 19px;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  display: block;
  margin: 0 auto 20px auto;
  width: 80%;
`;

const ListStyling: Array<Interpolation> = css`
  display: flex;
  flex-wrap: wrap;
  list-style: circle;

  > li {
    margin-right: 25px;
    margin-bottom: 10px;

    @media (min-width: ${props => props.theme.screen.small}px) {
      width: calc(33% - 25px);
    }
  }
`;

type Props = {
  adminView: boolean,
  carer: CarerType,
  currentUserId: string,
  taxonomiesByClassification: TaxonomiesClassificationType,
};

type State = {
  shareableDialogShown: boolean,
};

class CarerProfile extends Component<Props, State> {
  state = {
    shareableDialogShown: false,
  };

  render() {
    const {
      taxonomiesByClassification,
      carer,
      adminView,
      currentUserId,
    } = this.props;
    const { shareableDialogShown } = this.state;

    const currentCarer = carer.id === currentUserId;

    return (
      <section>
        <Module>
          <Container>
            <Row>
              <Col xs={12}>
                All details included on the Care Professional Profile are declared
                by the care professional themselves and have not been verified by
                Home Instead.
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                Any information contained here or on their CV download must be verified
                by the employing franchise.
              </Col>
            </Row>
          </Container>
        </Module>

        <Module noPadding>
          <Container gutterWidth={0}>
            <StyledRow>

              <StyledSideBar sm={12} md={3}>

                <ProfileHeader
                  adminView={adminView}
                  carer={carer}
                  shareableDialogToggle={() =>
                    this.setState({
                      shareableDialogShown: !shareableDialogShown,
                    })
                  }
                />

                <ProfileSummary
                  carer={carer}
                  taxonomiesByClassification={taxonomiesByClassification}
                />

                {currentCarer && (
                  <StyledButtonLink to={'/carers/profile/'}>
                    Edit profile
                  </StyledButtonLink>
                )}

                {adminView && (
                  <StyledButtonLink to={`/admin/carers/profile/${carer.id}/`}>
                    Edit profile
                  </StyledButtonLink>
                )}

                <ProfileCareTypes
                  carer={carer}
                  taxonomiesByClassification={taxonomiesByClassification}
                />

              </StyledSideBar>
              <StyledMainSection sm={12} md={9}>
                <FeaturedTestimonialsContainer carer={carer} />
                <ProfilePersonal
                  carer={carer}
                  taxonomiesByClassification={taxonomiesByClassification}
                />
                <ProfileOverview
                  carer={carer}
                  listStyling={ListStyling}
                  taxonomiesByClassification={taxonomiesByClassification}
                />
              </StyledMainSection>
            </StyledRow>
          </Container>
        </Module>
        {this.state.shareableDialogShown && (
          <PortalWithState
            closeOnOutsideClick
            closeOnEsc
            onClose={() => this.setState({ shareableDialogShown: false })}
            defaultOpen
          >
            {({ openPortal, closePortal, isOpen, portal }) =>
              portal(
                <Modal closePortal={closePortal} key="propose-modal">
                  <CarerShareDialog carer={carer} closePortal={closePortal} />
                </Modal>,
              )
            }
          </PortalWithState>
        )}
      </section>
    );
  }
}

export default CarerProfile;
