// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { notifyCustomerOfBids } from '_actions/jobOfferActions';
import {
  approveBid,
  searchBidsWithCarers,
  updateBid,
  notifyBid,
  renotifyBids,
} from '_actions/bidActions';

import {
  selectGroupedBidsByJobOfferIDAndState,
  selectCarersForBidsByJobOffer,
} from 'shared/selectors/bidSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';

import AdminBidsOverview from 'components/bids/AdminBidsOverview';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import type {
  BidType,
  CarerType,
  JobOfferType,
  ScheduleType,
  TaxonomiesClassificationType,
} from 'shared/types';

type Props = {
  groupedBids: { [string]: Array<BidType> },
  bidCarers: { [string]: CarerType },
  bidStateLabelMappings: { [string]: string },
  bidsWithCarersLoadingState: boolean,
  jobOffer: JobOfferType,
  needID: string,
  proposeCarerBid: BidType => void,
  schedule: ScheduleType,
  taxonomiesForClassification: TaxonomiesClassificationType,
  approveBid: (BidType, string) => Object,
  notifyBid: string => Object,
  notifyCustomerOfBids: string => Object,
  renotifyBids: string => Object,
  searchBidsWithCarers: (Object, ?string) => Object,
  updateBid: (string, Object) => Object,
};

class AdminBidsOverviewContainer extends Component<Props> {
  static defaultProps = {
    groupedBids: null,
    bidCarers: null,
    jobOffer: null,
    schedule: null,
    taxonomiesForClassification: {},
  };

  static get TAXONOMY_CLASSIFICATIONS(): Array<string> {
    return ['bid_admin_rejection'];
  }

  componentDidMount() {
    this.props.searchBidsWithCarers({
      job_offer_id: this.props.jobOffer.id,
      per_page: 1000,
      states: Object.keys(this.props.bidStateLabelMappings),
    });
  }

  addCarerToTeam = (carerID: string, bid: BidType): void => {
    this.props.approveBid(bid, this.props.needID);
  };

  notifyCustomer = (): void => {
    this.props.notifyCustomerOfBids(this.props.jobOffer.id);
  };

  renotifyBids = (): void => {
    this.props.renotifyBids(this.props.jobOffer.id);
  };

  render() {
    const {
      groupedBids,
      bidCarers,
      bidStateLabelMappings,
      bidsWithCarersLoadingState,
      jobOffer,
      notifyBid,
      proposeCarerBid,
      schedule,
      taxonomiesForClassification,
      updateBid,
    } = this.props;

    if (
      !taxonomiesForClassification ||
      bidsWithCarersLoadingState ||
      !groupedBids ||
      !bidCarers
    ) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminBidsOverview
        addCarerToTeam={this.addCarerToTeam}
        groupedBids={groupedBids}
        jobOffer={jobOffer}
        notifyCustomer={this.notifyCustomer}
        proposeCarerBid={proposeCarerBid}
        schedule={schedule}
        taxonomiesForClassification={taxonomiesForClassification}
        updateBid={updateBid}
        bidStateLabelMappings={bidStateLabelMappings}
        notifyBid={notifyBid}
        renotifyBids={this.renotifyBids}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    groupedBids: selectGroupedBidsByJobOfferIDAndState(
      state,
      props.jobOffer.id,
      Object.keys(props.bidStateLabelMappings),
    ),
    bidCarers: selectCarersForBidsByJobOffer(state, props.jobOffer.id),
    bidsWithCarersLoadingState: loadingSelectorFactory(
      'bids',
      'bidsWithCarers',
    )(state),
    taxonomiesForClassification: getTaxonomiesByClassifications(
      state,
      AdminBidsOverviewContainer.TAXONOMY_CLASSIFICATIONS,
    ),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      approveBid,
      notifyBid,
      notifyCustomerOfBids,
      renotifyBids,
      searchBidsWithCarers,
      updateBid,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminBidsOverviewContainer);
