import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createCharge } from '_actions/chargeActions';
import { getUserById } from '_actions/userActions';

import CareNeedActions from 'actions/CareNeedActions';
import CareTeamActions from 'actions/CareTeamActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import AdminCreateCharge from 'components/admin/AdminCreateCharge';
import { getCarers } from 'shared/selectors/carerSelectors';

import { getTeamsForUserByID } from 'shared/selectors/needSelectors';

import { selectUserByID } from 'shared/selectors/userSelectors';

class AdminCreateChargeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumer_id: this.props.location.query.consumer_id || null,
    };
  }

  componentDidMount() {
    if (this.state.consumer_id) {
      this.props.getUserById(this.state.consumer_id);
      // TODO: Create saga for needs with team/schedule
      CareNeedActions.getNeedsByUserId(this.state.consumer_id).then(body => {
        const needs = body.data || [];
        needs.forEach(need => {
          CareTeamActions.getTeamByNeed(need.id);
        });
      });
    }
  }

  createCharge = charge => {
    this.props.createCharge(charge);
  };

  render() {
    let areCarersLoaded = true;
    let teamCarers = [];
    let carerIDs = [];

    if (this.props.teams && this.props.teams.length) {
      areCarersLoaded = false;
      carerIDs = this.props.teams.map(team => team.team);
      carerIDs = [].concat(...carerIDs);
      teamCarers = carerIDs.map(carerID => this.props.carers[carerID]);
      areCarersLoaded = teamCarers.every(carer => carer);
    }

    if (
      !this.props.consumer ||
      !areCarersLoaded ||
      !Object.keys(this.props.consumer).length
    ) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminCreateCharge
        onSubmit={this.createCharge}
        consumer={this.props.consumer}
        carers={teamCarers}
      />
    );
  }
}

AdminCreateChargeContainer.propTypes = {
  carers: PropTypes.shape(),
  createCharge: PropTypes.func.isRequired,
  location: PropTypes.shape(),
  consumer: PropTypes.shape(),
  teams: PropTypes.arrayOf(PropTypes.shape),
  getUserById: PropTypes.func.isRequired,
};

AdminCreateChargeContainer.defaultProps = {
  carers: null,
  consumer: null,
  location: null,
  teams: [],
};

const mapStateToProps = (state, props) => ({
  carers: getCarers(state),
  consumer: selectUserByID(state, props.location.query.consumer_id),
  teams: getTeamsForUserByID(state, props.location.query.consumer_id),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createCharge,
      getUserById,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminCreateChargeContainer);
