import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateCareTeamMemberState } from '_actions/careTeamActions';
import { searchBidsWithCarers } from '_actions/bidActions';
import { getNeedByID } from '_actions/needActions';
import { getSchedulesWithScheduleShiftsByNeedID } from '_actions/scheduleActions';
import CareTeamActions from 'actions/CareTeamActions';

import CustomerBidsContainer from 'containers/bids/CustomerBidsContainer';

import CustomerTeams from 'components/customers/CustomerTeams';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import {
  CustomerEmptyCareTeam,
  CustomerVerifyEmailPlaceholder,
} from 'components/customers/CustomerEmptyStateLayouts';
import CareTeamPlaceholderImage from 'images/customers/GroupOfCarers.png';

import { getUser } from 'shared/selectors/accountSelectors';
import { getCarers } from 'shared/selectors/carerSelectors';
import {
  getNeedsforUserByID,
  getTeamsForUserByID,
} from 'shared/selectors/needSelectors';
import { selectSchedulesWithShiftsByUserID } from 'shared/selectors/scheduleShiftsSelectors';
import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';
import { selectBidResults } from 'shared/selectors/bidSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

class CustomerTeamsContainer extends Component {
  static propTypes = {
    carers: PropTypes.shape(),
    getNeedByID: PropTypes.func.isRequired,
    getSchedulesWithScheduleShiftsByNeedID: PropTypes.func.isRequired,
    searchBidsWithCarers: PropTypes.func.isRequired,
    needs: PropTypes.arrayOf(PropTypes.shape()),
    teams: PropTypes.arrayOf(PropTypes.shape()),
    user: PropTypes.shape().isRequired,
    schedules: PropTypes.shape(),
    taxonomiesByClassification: PropTypes.shape(),
    updateCareTeamMemberState: PropTypes.func.isRequired,
  };

  static defaultProps = {
    carers: null,
    teams: null,
    needs: null,
    schedules: {},
    taxonomiesByClassification: {},
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'care_team_member_customer_rejection',
      'care_team_member_customer_fired',
      'care_type',
      'care_experience_years',
      'condition',
      'condition_experience',
      'qualifications',
    ];
  }

  componentDidMount() {
    const { user } = this.props;
    this.props.searchBidsWithCarers({
      user_id: user.id,
      states: ['proposed', 'accepted'],
      per_page: 1000,
    });
    user.care_needs.forEach(need => {
      this.props.getNeedByID(need.id);
      CareTeamActions.getTeamByNeed(need.id);
      this.props.getSchedulesWithScheduleShiftsByNeedID(need.id);
    });
  }

  render() {
    const {
      bids,
      carers,
      needs,
      schedules,
      teamsLoading,
      bidsLoading,
      taxonomiesByClassification,
      teams,
      updateCareTeamMemberState,
      user,
    } = this.props;

    if (teamsLoading || bidsLoading || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    const hasTeams = teams && !!teams.length;
    const hasBids = bids && !!bids.length;

    if (!hasTeams && !hasBids) {
      return <CustomerEmptyCareTeam />;
    }

    if (!user.confirmed_at) {
      return (
        <CustomerVerifyEmailPlaceholder
          type="careTeam"
          image={CareTeamPlaceholderImage}
        />
      );
    }

    return (
      <>
        {hasBids && <CustomerBidsContainer schedules={schedules} bids={bids} />}
        {hasTeams && (
          <CustomerTeams
            taxonomiesByClassification={taxonomiesByClassification}
            needs={needs}
            schedules={schedules}
            carers={carers}
            teams={teams}
            updateCareTeamMemberState={updateCareTeamMemberState}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const user = getUser(state);
  const toReturn = {
    carers: getCarers(state),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      CustomerTeamsContainer.TAXONOMY_CLASSIFICATIONS,
    ),
    teamsLoading: loadingSelectorFactory('careTeams', 'careTeam')(state),
    bidsLoading: loadingSelectorFactory('bids', 'bids')(state),
  };
  if (user) {
    toReturn.user = user;
    toReturn.needs = getNeedsforUserByID(state, user.id);
    toReturn.teams = getTeamsForUserByID(state, user.id);
    toReturn.schedules = selectSchedulesWithShiftsByUserID(state, user.id);
    toReturn.bids = selectBidResults(state);
  }
  return toReturn;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getNeedByID,
      searchBidsWithCarers,
      getSchedulesWithScheduleShiftsByNeedID,
      updateCareTeamMemberState,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerTeamsContainer);
