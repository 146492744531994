// @flow
import React from 'react';
import styled from 'styled-components';
import { Container, Col, Row } from 'components/grid';
import Definition from 'components/global/informationdisplay/Definition';
import Module, {
  ModuleHero,
  SectionHeader,
} from 'components/global/layout/Module';
import toTitleCase from 'components/helpers/toTitleCase';
import { renderTaxon } from 'components/helpers/taxonomyHelpers';
import { CustomerCompletedCareDetails } from 'components/customers/CustomerEmptyStateLayouts';

import type { NeedType, TaxonomiesClassificationType } from 'shared/types';

const StyledSectionHeader = styled(SectionHeader)`
  padding-left: 0;
`;

const StyledModuleHero = styled(ModuleHero)`
  text-align: start;
`;

type Props = {
  need: NeedType,
  taxonomiesForClassification: TaxonomiesClassificationType,
};

export default function CareNeedSummary({
  need,
  taxonomiesForClassification,
}: Props) {
  return (
    <Module>
      <StyledModuleHero>Care Details Summary</StyledModuleHero>
      <Container>
        <Row>
          <Col>
            <CustomerCompletedCareDetails />
            <StyledSectionHeader>Contact Details</StyledSectionHeader>
            {need.user_name && (
              <Definition label="Contact Name">{need.user_name}</Definition>
            )}
            {need.postcode && (
              <Definition label="Postcode">{need.postcode}</Definition>
            )}
            {need.contact_number && (
              <Definition label="Phone Number">
                {need.contact_number}
              </Definition>
            )}
            <StyledSectionHeader>Care Details</StyledSectionHeader>
            {need.firstname && (
              <Definition label="Care Recipient">
                {need.firstname} {need.lastname}
              </Definition>
            )}
            {need.age && <Definition label="Age">{need.age}</Definition>}
            {need.requested_care_types &&
              !!need.requested_care_types.length && (
                <Definition label="Care Type">
                  {renderTaxon(
                    taxonomiesForClassification['requested_care_type'],
                    need.requested_care_types,
                  )}
                </Definition>
              )}
            {need.services && (
              <Definition label="Services Required">
                {renderTaxon(
                  taxonomiesForClassification['services'],
                  need.services,
                )}
              </Definition>
            )}
            {need.condition_experience && (
              <Definition label="Experience Required">
                {renderTaxon(
                  taxonomiesForClassification['condition'],
                  need.condition_experience,
                )}
              </Definition>
            )}
            {typeof need.needs_license === 'boolean' && (
              <Definition label="Driver">
                {need.needs_license ? 'Yes' : 'No'}
              </Definition>
            )}
            {typeof need.needs_car === 'boolean' && (
              <Definition label="Car">
                {need.needs_car ? 'Yes' : 'No'}
              </Definition>
            )}
            {need.pets && (
              <Definition label="Pets">
                {need.pets.map(toTitleCase).join(', ')}
              </Definition>
            )}
            {need.carer_gender && (
              <Definition label="Preferred Carer Gender">
                {renderTaxon(
                  taxonomiesForClassification['gender_preference'],
                  need.carer_gender,
                )}
              </Definition>
            )}
          </Col>
        </Row>
      </Container>
    </Module>
  );
}

CareNeedSummary.defaultProps = {
  taxonomiesForClassification: {},
};
