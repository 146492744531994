import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Paginator from 'components/global/Paginator';
import TimesheetResultRow from 'components/admin/TimesheetResultRow';
import StandardTable from 'components/global/layout/StandardTable';

class TimesheetResults extends Component {
  render() {
    return (
      <div id="timesheet-results">
        <StandardTable className="timesheets">
          <thead>
            <tr>
              <th>ISO Week</th>
              <th>Start Date</th>
              <th>State</th>
              <th>Customer</th>
              <th>Recipient</th>
              <th>Carer</th>
              <th>Shifts</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {this.props.timesheets.map(timesheet => (
              <TimesheetResultRow
                key={timesheet.id}
                id={timesheet.id}
                timesheet={timesheet}
              />
            ))}
          </tbody>
        </StandardTable>
        <Paginator
          currentPage={this.props.search.pagination.page}
          totalPages={this.props.search.pagination.pages}
          location={this.props.location}
        />
      </div>
    );
  }
}

TimesheetResults.propTypes = {
  location: PropTypes.shape().isRequired,
  search: PropTypes.shape().isRequired,
  timesheets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default TimesheetResults;
