import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';

import {
  getHolidays,
  createHoliday,
  updateHoliday,
  deleteHoliday,
} from '_actions/holidayActions';
import AdminHolidaySettings from 'components/admin/settings/AdminHolidaySettings';

import { selectHolidays } from 'shared/selectors/holidaySelectors';

class AdminHolidaySettingsContainer extends Component {
  constructor() {
    super();
    this.state = {
      new_holiday: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getHolidays();
  }

  onChangeName = (index, name) => {
    const currentHolidays = this.props.dates;
    currentHolidays[index].name = name;
    this.setState({
      dates: currentHolidays,
    });
  };

  onSetDate = e => {
    const newHoliday = this.state.new_holiday;
    newHoliday.date = e.currentTarget.value;
    this.setState({
      newHoliday,
    });
  };

  onSetName = (index, name) => {
    const newHoliday = this.state.new_holiday;
    newHoliday.name = name;
    this.setState({
      newHoliday,
    });
  };

  addHoliday = () => {
    let error;
    let valid = true;
    const holiday = this.state.new_holiday;

    if (!holiday.date) {
      valid = false;
      error = 'Date not set';
    } else if (!moment(holiday.date, 'YYYY-MM-DD', true).isValid()) {
      valid = false;
      error = 'Invalid date selected';
    } else if (!holiday.name) {
      valid = false;
      error = 'Name not set';
    }

    if (!valid) {
      toastr.error(`Unable to add holiday: ${error}`);
    } else {
      this.props.createHoliday(holiday);
      this.setState({
        new_holiday: {},
      });
    }
  };

  deleteHoliday = date => {
    this.props.deleteHoliday(date);
  };

  updateHoliday = (date, name) => {
    this.props.updateHoliday(date, name);
  };

  render() {
    return (
      <AdminHolidaySettings
        dates={this.props.dates}
        newHoliday={this.state.new_holiday}
        onChangeName={this.onChangeName}
        updateHoliday={this.updateHoliday}
        deleteHoliday={this.deleteHoliday}
        addHoliday={this.addHoliday}
        onSetDate={this.onSetDate}
        onSetName={this.onSetName}
      />
    );
  }
}

AdminHolidaySettingsContainer.propTypes = {
  createHoliday: PropTypes.func.isRequired,
  deleteHoliday: PropTypes.func.isRequired,
  dates: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getHolidays: PropTypes.func.isRequired,
  updateHoliday: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  dates: selectHolidays(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createHoliday,
      deleteHoliday,
      getHolidays,
      updateHoliday,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminHolidaySettingsContainer);
