import keyMirror from 'keymirror';

export default keyMirror({
  BID_SEARCH: null,
  BID_SEARCH_SUCCESS: null,
  BID_SEARCH_SUCCESS_FOR_SAGA: null,
  BID_SEARCH_FAILURE: null,

  BID_GET: null,
  BID_GET_SUCCESS: null,
  BID_GET_FAILURE: null,

  BID_CREATE: null,
  BID_CREATE_SUCCESS: null,
  BID_CREATE_FAILURE: null,

  BID_CREATE_MULTIPLE: null,
  BID_CREATE_MULTIPLE_SUCCESS: null,
  BID_CREATE_MULTIPLE_FAILURE: null,

  BID_UPDATE: null,
  BID_UPDATE_SUCCESS: null,
  BID_UPDATE_FAILURE: null,

  BID_APPROVE: null,
  BID_APPROVE_SUCCESS: null,
  BID_APPROVE_FAILURE: null,

  BID_NOTIFY: null,
  BID_NOTIFY_SUCCESS: null,
  BID_NOTIFY_FAILURE: null,

  BID_RENOTIFY: null,
  BID_RENOTIFY_SUCCESS: null,
  BID_RENOTIFY_FAILURE: null,

  SEARCH_BIDS_WITH_JOB_OFFERS_AND_SCHEDULES_AND_SHIFTS: null,
  SEARCH_BIDS_WITH_JOB_OFFERS_AND_SCHEDULES_AND_SHIFTS_SUCCESS: null,
  SEARCH_BIDS_WITH_JOB_OFFERS_AND_SCHEDULES_AND_SHIFTS_FAILURE: null,

  SEARCH_BIDS_WITH_CARERS: null,
  SEARCH_BIDS_WITH_CARERS_SUCCESS: null,
  SEARCH_BIDS_WITH_CARERS_FAILURE: null,
});
