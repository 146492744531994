import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DateCell from 'components/timesheets/DateCell';
import ShiftRateCell from 'components/timesheets/ShiftRateCell';
import ShiftTotalCell from 'components/timesheets/ShiftTotalCell';
import ShiftTypeCell from 'components/timesheets/ShiftTypeCell';

class TimesheetTotalDay extends Component {
  static propTypes = {
    view: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    shifts: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        total: PropTypes.shape({
          minutes_total: PropTypes.number,
          carer_total: PropTypes.number,
          customer_total: PropTypes.number,
        }),
      }),
    ),
    rates: PropTypes.shape().isRequired,
    isAdmin: PropTypes.bool.isRequired,
    taxonomiesByClassification: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    shifts: [],
  };

  render() {
    return (
      <tbody>
        {this.props.shifts.map((shift, index) => {
          const liveIn = ['half_livein', 'livein'].includes(shift.type);
          const rowClass = this.props.index % 2 === 0 ? '' : 'even';
          const multiShiftClass =
            index < this.props.shifts.length - 1 ? 'multiple' : '';
          return (
            <tr key={index} className={`${multiShiftClass} ${rowClass}`}>
              {index === 0 && (
                <DateCell shifts={this.props.shifts} date={this.props.date} />
              )}
              <ShiftTypeCell
                shift={shift}
                liveIn={liveIn}
                view={this.props.view}
                shiftTypes={this.props.taxonomiesByClassification.shift_type}
              />
              <ShiftRateCell
                rates={this.props.rates}
                shift={shift}
                liveIn={liveIn}
                view={this.props.view}
                width={this.props.tdWidth}
                isAdmin={this.props.isAdmin}
              />
              <ShiftTotalCell
                shift={shift}
                view={this.props.view}
                width={this.props.tdWidth}
                isAdmin={this.props.isAdmin}
              />
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export default TimesheetTotalDay;
