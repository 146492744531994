import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { positive, rateLimitValidators } from 'validators';
import StandardInput from 'components/forms/StandardInput';
import CurrencyField from 'components/forms/CurrencyField';
import PercentField from 'components/forms/PercentField';
import Currency from 'components/modules/Currency';
import toTitleCase from 'components/helpers/toTitleCase';
import { calculateCustomerRate } from 'components/helpers/currency';
import BasicFlexContainer from 'components/global/layout/BasicFlexContainer';
import type { RatesType } from 'shared/types';

type Props = {
  currentValues: RatesType,
  rate: number,
  rateLimits: {
    created_at: string,
    id: string,
    minimum: number,
    maximum: number,
    name: string,
  },
  shiftTypes: Array<string>,
};

const Item = styled.div`
  flex-basis: ${({ basis }) => basis};
  min-width: ${({ basis }) => basis};
  padding: ${({ padding }) => padding};
  text-align: center;
`;

const AdminRatesTable = ({
  currentValues,
  rate,
  rateLimits,
  shiftTypes,
}: Props) => {
  const [validators, setValidators] = useState([]);

  useEffect(() => {
    setValidators(rateLimitValidators(rateLimits));
  }, []);

  return (
    <BasicFlexContainer direction="column" margin="0 0 20px">
      <BasicFlexContainer
        borderBottom="1px solid #D4E4E7"
        color="midGrey"
        height="70px"
        weight="700"
      >
        <Item basis="17%">Shift Type</Item>
        <Item basis="20%">Limits</Item>
        <Item basis="20%">Carer Pay</Item>
        <Item basis="20%">Commission %</Item>
        <Item basis="10%">Commission £</Item>
        <Item basis="13%">Total</Item>
      </BasicFlexContainer>
      {shiftTypes.map((shiftType, index) => {
        const shiftTypeCommission = `${shiftType}_commission`;
        const carerRate = currentValues[shiftType];
        const customerRate = calculateCustomerRate(
          carerRate,
          currentValues[shiftTypeCommission],
        );
        const commission = customerRate - carerRate;
        const frequency = shiftType === 'livein' ? 'per day' : 'per hour';
        const limits = rateLimits[shiftType];

        return (
          <BasicFlexContainer
            background={index % 2 === 0 ? '#FCFCFC' : null}
            borderBottom="1px solid #D4E4E7"
            key={shiftType}
            minHeight="120px"
            align="center"
          >
            <Item basis="17%">{toTitleCase(shiftType)}</Item>
            <Item basis="20%">
              <Currency value={limits.minimum} />
              &nbsp;—&nbsp;
              <Currency value={limits.maximum} />
            </Item>
            <Item basis="20%" padding="0 10px 0 0">
              <CurrencyField
                absoluteSubscript
                align="center"
                component={StandardInput}
                name={shiftType}
                validate={validators[shiftType]}
                subscript={[frequency]}
              />
            </Item>
            <Item basis="20%" padding="0 10px 0 0">
              <PercentField
                align="center"
                component={StandardInput}
                name={shiftTypeCommission}
                validate={[positive]}
              />
            </Item>
            <Item basis="10%">
              <Currency value={commission} />
            </Item>
            <Item basis="13%">
              <Currency value={customerRate} />
            </Item>
          </BasicFlexContainer>
        );
      })}
    </BasicFlexContainer>
  );
};

export default AdminRatesTable;
