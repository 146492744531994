import keyMirror from 'keymirror';

export default keyMirror({
  NOTE_ADD_FOR_OBJECT: null,
  NOTE_ADD_FOR_OBJECT_SUCCESS: null,
  NOTE_ADD_FOR_OBJECT_FAILURE: null,

  NOTES_GET_FOR_OBJECT: null,
  NOTES_GET_FOR_OBJECT_SUCCESS: null,
  NOTES_GET_FOR_OBJECT_FAILURE: null,

  NOTE_UPDATE: null,
  NOTE_UPDATE_SUCCESS: null,
  NOTE_UPDATE_FAILURE: null,

  NOTE_DELETE: null,
  NOTE_DELETE_SUCCESS: null,
  NOTE_DELETE_FAILURE: null,
});

export const noteCategories = {
  complaintconcern: 'Complaint / Concern',
  positivefeedback: 'Positive Feedback',
  safeguarding: 'Safeguarding',
  availability: 'Availability',
  othercomms: 'Other Comms',
  workhistory: 'Work History',
  updatehistory: 'Update History',
};
