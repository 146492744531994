import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateAccount } from '_actions/accountActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AccountEdit from 'components/account/AccountEdit';
import {
  getUser,
  isCustomer,
  isCarer,
} from 'shared/selectors/accountSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

const TAXONOMY_CLASSIFICATIONS = [
  'care_for',
  'gender',
  'user_title',
];

const AccountEditContainer = ({
  user,
  isCustomer,
  isCarer,
  updateAccount,
  taxonomiesByClassification,
}) => {
  const updateUserAccount = data => {
    const id = user.id;
    updateAccount({ id, ...data });
  };

  if (!user) {
    return <LoadingPlaceholder />;
  }
  return (
    <AccountEdit
      emailVerified={user.confirmed_at}
      isCustomer={isCustomer}
      isCarer={isCarer}
      user={user}
      updateAccount={updateUserAccount}
      taxonomiesByClassification={taxonomiesByClassification}
    />
  );
};

function mapStateToProps(state) {
  return {
    user: getUser(state),
    isCustomer: isCustomer(state),
    isCarer: isCarer(state),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      TAXONOMY_CLASSIFICATIONS,
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAccount,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountEditContainer);
