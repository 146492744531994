import StripeButton from 'components/payments/StripeButton';
import PropTypes from 'prop-types';
import React from 'react';

const STRIPE_URI = 'https://dashboard.stripe.com/';

const StripeLink = ({ href, children }) => (
  <StripeButton
    href={`${STRIPE_URI}${href}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <span>{children}</span>
  </StripeButton>
);

StripeLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string.isRequired,
};

StripeLink.defaultProps = {
  children: 'View on Stripe',
};

export default StripeLink;
