import PropTypes from 'prop-types';
import React from 'react';

const QuotationMarks = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      className="icon"
      height={height}
      width={width}
      viewBox="200 100 700 700"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M518.367011 300.670897c-53.199643 30.810726-86.603427 64.88273-100.265588 102.245685-11.069103 30.364565-7.444553 55.402821 28.468378 59.418274 43.692105 4.935406 77.56968 42.075281 77.56968 87.077218 0 40.234353-27.102265 74.139557-64.018036 84.428901-77.458139 21.302165-138.493238-48.376801-139.77544-124.384915-1.797949-105.926518 55.876612-185.559183 167.602206-239.204987L518.367011 300.670897 518.367011 300.670897zM759.301386 300.670897c-53.199643 30.810726-86.603427 64.88273-100.265588 102.245685-11.069103 30.364565-7.444553 55.402821 28.440749 59.418274 43.719734 4.935406 77.56968 42.075281 77.56968 87.077218 0 40.234353-27.102265 74.139557-63.990407 84.428901-77.485769 21.302165-138.493238-48.376801-139.804093-124.384915-1.77032-105.926518 55.876612-185.559183 167.602206-239.204987L759.301386 300.670897 759.301386 300.670897z"
      />
    </svg>
  </div>
);

QuotationMarks.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

QuotationMarks.defaultProps = {
  className: '',
  height: '45px',
  width: '45px',
};

export default QuotationMarks;
