import PropTypes from 'prop-types';
import React from 'react';

const SmileyFaceIcon = ({ className, width, height, style }) => (
  <div className={`icon ${className}`} style={style}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Success"
          transform="translate(2.000000, 4.000000)"
          stroke="#3EB38A"
          strokeWidth="2"
        >
          <g id="success">
            <path
              d="M6,9 C7.75298867,11.4427083 9.54286388,11.4427083 11.3696256,9"
              id="Path-2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(8.684813, 9.916016) rotate(-180.000000) translate(-8.684813, -9.916016) "
            />
            <path
              d="M19,9 C20.7529887,11.4427083 22.5428639,11.4427083 24.3696256,9"
              id="Path-2-Copy"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(21.684813, 9.916016) rotate(-180.000000) translate(-21.684813, -9.916016) "
            />
            <circle id="Oval" cx="15" cy="15" r="14" />
            <path
              d="M9,18 C12.8984375,23.4322917 16.8789062,23.4322917 20.9414062,18"
              id="Path-2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

SmileyFaceIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  style: PropTypes.shape(),
};

SmileyFaceIcon.defaultProps = {
  className: '',
  height: '40px',
  width: '40px',
  style: {},
};

export default SmileyFaceIcon;
