import AccountConstants from 'shared/constants/AccountConstants';
import UserConstants from 'shared/constants/UserConstants';
import { updateObject } from 'shared/reducers/reducerHelpers';

const initalState = {};

function users(state = initalState, action) {
  switch (action.type) {
    case UserConstants.USER_GET_BY_ID:
    case UserConstants.USER_GET_BY_EMAIL:
    case UserConstants.USER_GET_BY_EMAIL_FAILURE:
      return state;

    case AccountConstants.ACCOUNT_UPDATE_SUCCESS:
    case UserConstants.USER_GET_BY_ID_SUCCESS:
    case UserConstants.USER_GET_BY_EMAIL_SUCCESS:
      return updateObject(state, { [action.data.id]: action.data });

    default:
      return state;
  }
}

export default users;
