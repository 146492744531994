import styled from 'styled-components';

const StyledFieldTitle = styled.label`
  display: block;
  color: ${props => props.theme.colors.formText};
  margin-bottom: 10px;
  font-weight: 600;

  > em {
    color: ${props => props.theme.colors.midGrey};
    display: block;
    font-size: 16px;
    font-weight: 400;
  }

  ${({ error, theme }) =>
    error
      ? `
    &:after {
      content: ' *';
      color: ${theme.colors.statusRed};
    }
  `
      : ''}
`;

export default StyledFieldTitle;
