import React from 'react';
import { Container, Row, Col } from 'components/grid';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import CarerImage from 'components/carers/CarerImage';
import Module, {
  ModuleHeader,
  ModuleMeta,
} from 'components/global/layout/Module';
import StatusIndicator from 'components/global/StatusIndicator';
import needOwner from 'components/helpers/needOwner';
import stateHelper from 'components/helpers/stateHelper';
import Button from 'components/buttons/Button';
import PaymentButton from 'components/payments/PaymentButton';
import PaymentStatus from 'components/payments/PaymentStatus';
import InvoiceLink from 'components/invoices/InvoiceLink';
import InvoiceTotal from 'components/invoices/InvoiceTotal';
import OutstandingTimesheetMessage from 'components/invoices/OutstandingTimesheetMessage';
import TimesheetBreakdown from 'components/invoices/TimesheetBreakdown';

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

const StyledCarerImage = styled(CarerImage)`
  border-width: 2px;
  float: left;
  height: 54px;
  margin-right: 10px;
  width: 54px;
  > img {
    display: inline;
  }
`;

const StyledCarerName = styled.span`
  float: left;
  line-height: 54px;
`;

const InvoiceDetails = ({
  addNoteForObject,
  authenticateViaStripe,
  reassignTimesheet,
  carers,
  customer,
  invoice,
  invoiceTotal,
  invoiceState,
  need,
  user,
  isAdmin,
  paymentMethods,
  payInvoice,
  timesheets,
  refunds,
  totals,
  voidInvoice,
}) => {
  let total;
  if (invoice.state === 'awaiting_timesheets') {
    total = (
      <Container>
        <Row>
          <Col style={{ marginBottom: '10px' }}>
            One or more of your carers has not yet submitted their timesheet.
            You will receive an email notification when the invoice is ready for
            viewing.
          </Col>
        </Row>
      </Container>
    );
  } else {
    total = <InvoiceTotal total={invoiceTotal} user={user} isAdmin={isAdmin} />;
  }

  const hideFromCustomer = !isAdmin && invoice.state === 'awaiting_timesheets';

  const invoiceOverview = (
    <Module>
      <Container>
        <Row>
          <Col xs={12} md={4}>
            <StyledHeader>Invoice {invoice.slug}</StyledHeader>
          </Col>
          <Col xs={12} md={8}>
            <ModuleMeta>
              <StatusIndicator status={invoiceState} />
              {['paid', 'awaiting_payment', 'requires_action'].includes(
                invoice.state,
              ) && (
                <div>
                  <InvoiceLink slug={invoice.slug} />
                </div>
              )}
            </ModuleMeta>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h5>
              {needOwner(need)} Care
              {isAdmin && ` (${customer.name})`}
            </h5>
          </Col>
        </Row>
      </Container>

      {total}
      <Container>
        {stateHelper.inState(invoice.state, {
          customer: ['awaiting_payment', 'requires_action'],
        }) && (
          <Row>
            <Col xs={12}>
              <PaymentButton
                authenticateViaStripe={authenticateViaStripe}
                user={user}
                paymentMethods={paymentMethods}
                pay={payInvoice}
                timesheets={timesheets}
              />
            </Col>
          </Row>
        )}
        {isAdmin && (
          <Row>
            <Col xs={12}>
              <Button className="danger" onClick={voidInvoice}>
                {invoice.state === 'void' ? 'Unvoid' : 'Void'}
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </Module>
  );

  return (
    <section>
      {invoiceOverview}

      {!hideFromCustomer && timesheets.length > 0 && (
        <StyledHeader>Breakdown of Charges</StyledHeader>
      )}

      {!hideFromCustomer &&
        timesheets.map(timesheet => {
          const carer = carers[timesheet.carer_id];
          const carerName = carer.display_name;

          let breakdown;
          if (['approved', 'queried'].includes(timesheet.state)) {
            const timesheetTotals =
              timesheet.totals || (totals && totals[timesheet.id]);
            const timesheetRefunds = refunds[timesheet.id] || [];
            if (!timesheetTotals) {
              // totals haven't loaded yet
              return null;
            }
            breakdown = (
              <TimesheetBreakdown
                addNoteForObject={addNoteForObject}
                authenticateViaStripe={authenticateViaStripe}
                paymentMethods={paymentMethods}
                refunds={timesheetRefunds}
                timesheet={timesheet}
                totals={timesheetTotals}
              />
            );
          } else {
            breakdown = (
              <OutstandingTimesheetMessage
                state={timesheet.state}
                carer={carer}
                user={user}
              />
            );
          }

          return (
            <Module key={timesheet.id}>
              <Container>
                <Row>
                  <Col xs={12} md={4}>
                    <StyledCarerImage carer={carer} />
                    <StyledCarerName>{carerName}</StyledCarerName>
                  </Col>
                  <Col xs={12} md={8}>
                    {isAdmin && (
                      <ModuleMeta>
                        <StatusIndicator status={timesheet.state} />
                        <div>
                          <Link to={`/timesheets/${timesheet.id}/confirm/`}>
                            Manage Timesheet
                          </Link>
                        </div>
                      </ModuleMeta>
                    )}
                    {timesheet.charge_transferred_at && (
                      <ModuleMeta>
                        <PaymentStatus
                          transferredAt={timesheet.charge_transferred_at}
                        />
                      </ModuleMeta>
                    )}
                  </Col>
                </Row>
              </Container>
              {breakdown}
              {isAdmin && (
                <Container>
                  <Row>
                    <Col xs={12} md={3}>
                      <Button
                        onClick={() => reassignTimesheet(timesheet.id)}
                        className="danger"
                      >
                        Detach Timesheet
                      </Button>
                    </Col>
                  </Row>
                </Container>
              )}
            </Module>
          );
        })}
      {!hideFromCustomer && timesheets.length > 0 && invoiceOverview}
    </section>
  );
};

export default InvoiceDetails;
