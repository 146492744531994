// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { QuotationMarks } from 'components/global/icons';

import { renderTaxon } from 'components/helpers/taxonomyHelpers';

import type {
  TaxonomiesClassificationType,
  TestimonialType,
} from 'shared/types';

const StyledFeaturedTestimonials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;

const StyledQuotationMarks = styled.div`
  font-size: 100px;
  font-weight: bold;
`;

const StyledTestimonial = styled.div`
  font-size: 20px;
  margin: 10px 0;
`;

const StyledCustomerName = styled.div`
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const StyledDate = styled.div`
  font-size: 16px;
`;

type Props = {|
  featuredTestimonials: Array<TestimonialType>,
  taxonomiesByClassification: TaxonomiesClassificationType,
|};

class FeaturedTestimonials extends Component<Props> {
  render() {
    const { featuredTestimonials, taxonomiesByClassification } = this.props;

    return (
      <div>
        {featuredTestimonials.map(testimonial => (
          <Testimonial
            key={testimonial.id}
            testimonial={testimonial}
            taxonomiesByClassification={taxonomiesByClassification}
          />
        ))}
      </div>
    );
  }
}

type TestimonialProps = {
  taxonomiesByClassification: TaxonomiesClassificationType,
  testimonial: TestimonialType,
};

const getDatesDisplay = (testimonial: TestimonialType): string => {
  const momentFormat: string = 'MMM YYYY';
  const startDate: string = testimonial.start_date
    ? moment(testimonial.start_date).format(momentFormat)
    : '';
  const endDate: string = testimonial.end_date
    ? moment(testimonial.end_date).format(momentFormat)
    : '';

  if (endDate) {
    return `(from ${startDate} to ${endDate})`;
  }

  return startDate ? `(from ${startDate})` : '';
};

const Testimonial = ({
  taxonomiesByClassification,
  testimonial,
}: TestimonialProps) => {
  const nameRelationship: string = `${testimonial.firstname}, ${renderTaxon(
    taxonomiesByClassification.testimonial_relationship,
    testimonial.relationship,
  )}`;

  const dateFromTo: string = getDatesDisplay(testimonial);

  return (
    <StyledFeaturedTestimonials>
      <StyledQuotationMarks>
        <QuotationMarks />
      </StyledQuotationMarks>
      <StyledTestimonial>{`“${testimonial.testimonial}”`}</StyledTestimonial>
      <StyledCustomerName>{nameRelationship}</StyledCustomerName>
      <StyledDate>{dateFromTo}</StyledDate>
    </StyledFeaturedTestimonials>
  );
};

export default FeaturedTestimonials;
