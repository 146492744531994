import PropTypes from 'prop-types';
import React from 'react';

const ClockIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="clock_icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="clock"
          transform="translate(4.000000, 3.000000)"
          stroke="#4A4A4A"
        >
          <g id="Group-5">
            <g id="Success-Copy" strokeWidth="2">
              <g id="success_icon">
                <circle id="Oval" cx="12" cy="12" r="11" />
              </g>
            </g>
            <polyline
              id="Path-2"
              strokeWidth="2"
              points="12 4.8 12 12.2550805 17.5629779 12.2550805"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

ClockIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

ClockIcon.defaultProps = {
  className: '',
  height: '33px',
  width: '36px',
};

export default ClockIcon;
