/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';

import { getCarerById } from '_actions/carerActions';
import { deleteTimesheetById } from '_actions/timesheetActions';
import CareNeedActions from 'actions/CareNeedActions';
import TimesheetActions from 'actions/TimesheetActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import TimesheetDetails from 'components/timesheets/TimesheetDetails';
import { isAdmin } from 'shared/selectors/accountSelectors';

import { getCarerforTimesheetByID } from 'shared/selectors/carerSelectors';
import { getNeedforTimesheetByID } from 'shared/selectors/needSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { getTimesheetByID } from 'shared/selectors/timesheetSelectors';
import { getUserForTimesheetByID } from 'shared/selectors/userSelectors';

class TimesheetDetailsContainer extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      actual_shifts: null,
      notes: null,
    };
  }

  componentDidMount() {
    TimesheetActions.getTimesheetById(this.props.match.params.timesheetID).then(
      body => {
        CareNeedActions.getNeedById(body.data.need_id);
        this.props.getCarerById(body.data.carer_id);
      },
    );

    if (this.props.timesheet) {
      this.initActualShifts();
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps !== this.props) {
      this.initActualShifts();
    }
  };

  addDay = e => {
    e.persist();
    const date = e.target.dataset.day;
    const currentShifts = this.state.actual_shifts;
    currentShifts[date] = [];

    this.setState(
      {
        actual_shifts: currentShifts,
      },
      () => this.addShift(e),
    );
  };

  addExpense = e => {
    e.preventDefault();
    const expenses = this.state.expenses || [];
    const newExpense = Object.assign({}, this.state.new_expense);
    if (!newExpense.description || !newExpense.value) {
      toastr.error('Please enter all details to add an expense.');
      return false;
    }

    expenses.push(newExpense);

    this.setState({
      expenses,
      new_expense: {
        description: '',
        value: 0,
      },
    });

    return newExpense;
  };

  addShift = date => {
    const currentShifts = this.state.actual_shifts;
    let baseShift;
    let tempShift;

    if (currentShifts[date] && currentShifts[date].length) {
      tempShift = currentShifts[date][currentShifts[date].length - 1];
      baseShift = {
        shift_type: tempShift.shift_type,
        start: moment(tempShift.start),
        end: moment(tempShift.end),
        duration: tempShift.duration,
      };
    } else {
      currentShifts[date] = [];
      baseShift = { end: moment(date) };
    }

    const newShift = Object.assign({}, baseShift);

    newShift.start = moment(newShift.end);
    const newEnd = newShift.end.add(1, 'h');

    newShift.end = newEnd;
    newShift.duration = 60;

    currentShifts[date].push(newShift);

    this.setState({
      actual_shifts: currentShifts,
    });
  };

  deleteExpense = index => {
    const expenses = this.state.expenses;
    expenses.splice(index, 1);
    this.setState({
      expenses,
    });
  };

  deleteTimesheet = () => {
    const confirmation = confirm(
      'Are you sure you want to delete this timesheet?',
    );

    if (confirmation) {
      this.props.deleteTimesheetById(this.props.timesheet.id);
    }
  };

  groupShiftsByDate = () => {
    const days = {};

    if (this.props.timesheet.shifts) {
      this.props.timesheet.shifts.forEach(shift => {
        const startTime = moment(shift.start);
        const endTime = moment(shift.start).add(shift.duration, 'minutes');

        let shiftType;
        if (shift.type) {
          shiftType = shift.type;
        }

        const date = moment(shift.start).format('YYYY-MM-DD');
        if (!days[date]) {
          days[date] = [];
        }

        const data = {
          start: startTime,
          duration: shift.duration,
          end: endTime,
          shift_type: shiftType,
        };

        if (shiftType === 'exceptional') {
          data.exceptional_total = shift.total;
          data.exceptional_reason = shift.reason;
        }

        days[date].push(data);
      });
    }

    return days;
  };

  initActualShifts = () => {
    let actualShifts;
    let expenses;
    let mileage;

    if (this.props.timesheet && !this.state.actual_shifts) {
      // Group shifts by date
      actualShifts = this.groupShiftsByDate();

      if (this.props.timesheet.expenses) {
        expenses = this.props.timesheet.expenses.filter(
          expense =>
            !(expense.expense_type && expense.expense_type === 'mileage'),
        );
        mileage =
          this.props.timesheet.expenses.filter(
            expense =>
              expense.expense_type && expense.expense_type === 'mileage',
          )[0] || null;
      }

      this.setState({
        actual_shifts: actualShifts,
        expenses,
        mileage,
      });
    }
  };

  removeShift = (date, index) => {
    const currentShifts = this.state.actual_shifts;

    currentShifts[date].splice(index, 1);

    this.setState({
      actual_shifts: currentShifts,
    });
  };

  saveEmpty = () => {
    const data = {
      shifts: [],
    };
    TimesheetActions.update(this.props.timesheet.id, data, true);
  };

  saveTimes = withConfirmation => {
    let errorShift;
    const confirmedShifts = [];

    Object.keys(this.state.actual_shifts).forEach(date => {
      this.validateDaysShifts(date);

      this.state.actual_shifts[date].forEach(shift => {
        if (shift.error) {
          errorShift = shift;
        }

        const confirmed = {
          start: shift.start.format(),
          duration: shift.duration,
          type: shift.shift_type,
        };

        if (confirmed.type === 'exceptional') {
          confirmed.total = shift.exceptional_total;
          confirmed.reason = shift.exceptional_reason;
        }

        confirmedShifts.push(confirmed);
      });
    });

    if (errorShift) {
      toastr.error(
        `Your shift on ${errorShift.start.format('dddd')} has an error: ${
          errorShift.error
        } Please fix this before saving.`,
      );
      return false;
    }

    const data = {
      shifts: confirmedShifts,
    };

    if (this.state.notes) {
      data.expense_details = this.state.notes;
    }

    if (this.state.expenses) {
      // Copy expenses, not a reference, trust me
      data.expenses = this.state.expenses.slice();
    }

    if (this.state.mileage) {
      if (!this.state.mileage.miles && !this.state.mileage.description) {
        toastr.error(
          'Please enter both a description and miles for mileage expenses.',
        );
        return false;
      } else if (!this.state.mileage.miles) {
        toastr.error('Please enter the number of miles travelled.');
        return false;
      } else if (!this.state.mileage.description) {
        toastr.error('Please enter a description for your mileage expenses.');
        return false;
      }

      if (!this.state.expenses) {
        data.expenses = [];
      }
      data.expenses.push(this.state.mileage);
    }

    TimesheetActions.update(this.props.timesheet.id, data, withConfirmation);
    return true;
  };

  updateExpense = (index, e) => {
    const expenses = this.state.expenses;
    const expense = expenses[index];
    const target = e.currentTarget;

    if (target.name === 'description') {
      expense.description = target.value;
    }

    if (target.name === 'value') {
      expense.value = Math.round(target.value * 100);
    }

    this.setState({
      expenses,
    });
  };

  updateMileage = e => {
    const target = e.currentTarget;
    let mileage = this.state.mileage || {};

    // Description update
    if (target.name === 'description') {
      if (target.value === '' && !mileage.miles) {
        mileage = null;
      } else {
        mileage.description = target.value;
      }
    }

    // Miles update
    if (target.name === 'miles') {
      if (target.value === '' && !mileage.description) {
        mileage = null;
      } else {
        mileage.miles = Number(target.value);
      }
    }

    if (mileage) {
      mileage.value = mileage.miles * 45;
      mileage.expense_type = 'mileage';
    }

    this.setState({
      mileage,
    });
  };

  updateNewExpense = (index, e) => {
    const newExpense = this.state.new_expense || {
      description: '',
      value: 0,
    };
    const target = e.currentTarget;

    if (target.name === 'description') {
      newExpense.description = target.value;
    }

    if (target.name === 'value') {
      newExpense.value = Math.round(target.value * 100);
    }

    this.setState({
      new_expense: newExpense,
    });
  };

  updateNotes = e => {
    e.preventDefault();
    this.setState({
      notes: e.currentTarget.value,
    });
  };

  updateShiftDuration = (duration, date, index) => {
    const currentShifts = this.state.actual_shifts;

    currentShifts[date][index] = duration;

    this.setState(
      {
        actual_shifts: currentShifts,
      },
      () => this.validateDaysShifts(date),
    );
  };

  updateShiftExceptionalReason = (reason, date, index) => {
    const currentShifts = this.state.actual_shifts;

    currentShifts[date][index].exceptional_reason = reason;

    this.setState(
      {
        actual_shifts: currentShifts,
      },
      () => this.validateDaysShifts(date),
    );
  };

  updateShiftExceptionalTotal = (total, date, index) => {
    const currentShifts = this.state.actual_shifts;
    const pennyTotal = parseInt((total * 100).toFixed(2), 10);
    currentShifts[date][index].exceptional_total = pennyTotal;

    this.setState(
      {
        actual_shifts: currentShifts,
      },
      () => this.validateDaysShifts(date),
    );
  };

  updateShiftType = (e, date, index) => {
    const value = e.currentTarget.value;
    const currentShifts = this.state.actual_shifts;

    currentShifts[date][index].shift_type = value;

    // Clear any errors from previous shift type, we'll bring them back if need be
    delete currentShifts[date][index].error;

    this.setState(
      {
        actual_shifts: currentShifts,
      },
      () => this.validateDaysShifts(date),
    );
  };

  validateDaysShifts = date => {
    const currentShifts = this.state.actual_shifts;
    const daysShifts = currentShifts[date];

    daysShifts.forEach(shiftProp => {
      const shift = shiftProp;
      delete shift.error;
      if (!shift.shift_type) {
        shift.error = 'Please select a shift type.';
      }
      if (shift.shift_type === 'exceptional') {
        if (
          !shift.exceptional_total ||
          !shift.exceptional_reason ||
          shift.exceptional_total === '' ||
          shift.exceptional_reason === ''
        ) {
          shift.error =
            'Please enter all additional exceptional shift details.';
        }
      }
    });

    this.setState({
      actual_shifts: currentShifts,
    });
  };

  render() {
    if (!this.state.actual_shifts || !this.props.taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    return (
      <TimesheetDetails
        actualShifts={this.state.actual_shifts}
        timesheet={this.props.timesheet}
        updateShiftType={this.updateShiftType}
        updateShiftDuration={this.updateShiftDuration}
        updateShiftExceptionalTotal={this.updateShiftExceptionalTotal}
        updateShiftExceptionalReason={this.updateShiftExceptionalReason}
        addShift={this.addShift}
        removeShift={this.removeShift}
        carer={this.props.carer}
        isAdmin={this.props.isAdmin}
        deleteTimesheet={this.deleteTimesheet}
        saveTimes={this.saveTimes}
        consumer={this.props.consumer}
        careNeed={this.props.need}
        saveEmpty={this.saveEmpty}
        expenses={this.state.expenses}
        mileage={this.state.mileage}
        newExpense={this.state.new_expense}
        updateMileage={this.updateMileage}
        addExpense={this.addExpense}
        updateNewExpense={this.updateNewExpense}
        updateExpense={this.updateExpense}
        deleteExpense={this.deleteExpense}
        updateNotes={this.updateNotes}
        taxonomiesByClassification={this.props.taxonomiesByClassification}
      />
    );
  }
}

TimesheetDetailsContainer.propTypes = {
  carer: PropTypes.shape(),
  consumer: PropTypes.shape(),
  need: PropTypes.shape(),
  timesheet: PropTypes.shape(),
  match: PropTypes.shape({
    params: PropTypes.shape({
      timesheetID: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  deleteTimesheetById: PropTypes.func.isRequired,
  getCarerById: PropTypes.func.isRequired,
  taxonomiesByClassification: PropTypes.object,
};

TimesheetDetailsContainer.defaultProps = {
  carer: null,
  consumer: null,
  need: null,
  timesheet: null,
  taxonomiesByClassification: null,
};

TimesheetDetailsContainer.TAXONOMY_CLASSIFICATIONS = ['shift_type'];

const mapStateToProps = (state, props) => ({
  carer: getCarerforTimesheetByID(state, props.match.params.timesheetID),
  consumer: getUserForTimesheetByID(state, props.match.params.timesheetID),
  need: getNeedforTimesheetByID(state, props.match.params.timesheetID),
  timesheet: getTimesheetByID(state, props.match.params.timesheetID),
  isAdmin: isAdmin(state),
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    TimesheetDetailsContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteTimesheetById,
      getCarerById,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimesheetDetailsContainer);
