import PropTypes from 'prop-types';
import React from 'react';

const NightIcon = ({ className, width, height, ...otherProps }) => (
  <div className={`icon ${className}`} {...otherProps}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="night-icon"
        stroke="none"
        strokeWidth="0.6"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path d="M6.83173147,4.52259368 C5.86200589,4.91963227 4.97766495,5.50451707 4.23725812,6.24553379 C2.73936753,7.74281448 1.91479584,9.73410632 1.91479584,11.8516455 C1.91479584,13.9691846 2.73936753,15.9604764 4.23725812,17.4577571 C5.73453881,18.9550378 7.72583065,19.7802194 9.84336978,19.7802194 C11.9609089,19.7802194 13.9522008,18.9550378 15.4494814,17.4577571 C16.1898883,16.7179602 16.775383,15.8336192 17.1718117,14.8632838 C16.7412291,14.9248827 16.3069872,14.9559871 15.8709156,14.9559871 C13.4295248,14.9559871 11.1357273,14.0063879 9.41217734,12.2828379 C7.37392395,10.2445845 6.43164343,7.35065502 6.83173147,4.52259368 Z M9.84336978,21 C7.39953934,21 5.10208257,20.0479612 3.37426334,18.320142 C1.64644411,16.5923228 0.695015238,14.2954759 0.695015238,11.8516455 C0.695015238,9.40781501 1.64644411,7.11096813 3.37426334,5.3831489 C4.50560985,4.25180239 5.92604437,3.43454938 7.48187453,3.02043387 C7.6910669,2.96493385 7.91550654,3.02409321 8.06980878,3.17839545 C8.22411103,3.33208781 8.28388028,3.55591755 8.22777037,3.76632971 C7.50078113,6.49741848 8.28449017,9.43038095 10.2745622,11.420453 C11.7681836,12.9134645 13.7552062,13.7362065 15.8709156,13.7362065 C16.5655807,13.7362065 17.2584161,13.6453328 17.9286855,13.466635 C18.1384878,13.411135 18.3629274,13.4709042 18.5166198,13.6252065 C18.670922,13.7788988 18.7306913,14.0027286 18.6745814,14.2131407 C18.2604659,15.7689709 17.4432129,17.1894054 16.3118663,18.320142 C14.5840471,20.0479612 12.2872002,21 9.84336978,21 L9.84336978,21 Z" />
      </g>
    </svg>
  </div>
);

NightIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

NightIcon.defaultProps = {
  className: '',
  height: '17px',
  width: '17px',
};

export default NightIcon;
