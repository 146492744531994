import { createSelector } from 'reselect';
import { getUser } from 'shared/selectors/accountSelectors';

export const selectBids = state => state.bids;
export const selectBidSearch = state => state.search.bids;
export const selectBidSearchByName = (state, name) => {
  if (!state.search || !state.search.bids) {
    return null;
  }

  if (state.search.bids[name]) {
    return state.search.bids[name];
  }

  return null;
};

export const selectBidsByIDs = (state, ids) => {
  const bids = selectBids(state);
  if (!bids) {
    return null;
  }

  return ids.map(id => bids[id]).filter(bid => bid !== undefined);
};

export const selectBidsByJobOfferID = (state, id) =>
  Object.values(state.bids).filter(bid => bid.job_offer_id === id);

export const selectGroupedBidsByJobOfferID = createSelector(
  [selectBidsByJobOfferID],
  bids => {
    if (!bids) {
      return null;
    }

    return bids.reduce((accumulator, bid) => {
      if (!accumulator[bid.state]) {
        accumulator[bid.state] = [];
      }
      accumulator[bid.state].push(bid);
      return accumulator;
    }, {});
  },
);

export const selectGroupedBidsByJobOfferIDAndState = (state, id, bidStates) => {
  const bids = selectGroupedBidsByJobOfferID(state, id);
  if (!bids) {
    return null;
  }

  if (!Array.isArray(bidStates)) {
    bidStates = [bidStates];
  }

  const newBids = { ...bids };
  Object.keys(bids).forEach(state => {
    if (!bidStates.includes(state)) {
      delete newBids[state];
    }
  });

  return newBids;
};

// TODO: Strip this out as carer details loaded with bids
export const selectCarersForBidsByJobOffer = (state, id) => {
  return {};
};

export const selectCarerBids = createSelector(
  [selectBids, getUser],
  (bids, user) => {
    if (!bids) {
      return null;
    }
    return Object.values(bids).filter(bid => bid.carer_id === user.id);
  },
);

export const selectBidResults = createSelector(
  [selectBids, selectBidSearch],
  (bids, search) => {
    if (!search || !search.searchOrder) {
      return [];
    }
    const results = [];
    search.searchOrder.forEach(id => {
      if (bids[id]) {
        results.push(bids[id]);
      }
    });
    return results;
  },
);

export const selectBidResultsBySearchName = createSelector(
  [selectBids, selectBidSearchByName],
  (bids, search) => {
    if (!search || !search.searchOrder) {
      return [];
    }
    const results = [];
    search.searchOrder.forEach(id => {
      if (bids[id]) {
        results.push(bids[id]);
      }
    });
    return results;
  },
);

export const selectCarerBidByJobOfferID = createSelector(
  [selectBidsByJobOfferID, getUser],
  (bids, user) => {
    if (!bids || !bids.length) {
      return null;
    }
    const matches = bids.filter(bid => bid.carer_id === user.id);
    if (matches.length) {
      return matches[0];
    }
    return null;
  },
);
