import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Currency from 'components/modules/Currency';
import Days from 'components/modules/Days';
import Hours from 'components/modules/Hours';

class TimesheetTotalCarerGross extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    timesheetTotal: PropTypes.shape({
      application_total: PropTypes.number.isRequired,
      application_total_gross: PropTypes.number.isRequired,
      application_total_net: PropTypes.number.isRequired,
      carer_total: PropTypes.number.isRequired,
      carer_total_gross: PropTypes.number.isRequired,
      carer_total_net: PropTypes.number.isRequired,
      customer_total: PropTypes.number.isRequired,
      customer_total_net: PropTypes.number.isRequired,
      insurance_payment: PropTypes.number.isRequired,
      livein_days: PropTypes.number.isRequired,
      minutes_total: PropTypes.number.isRequired,
    }).isRequired,
    view: PropTypes.string.isRequired,
  };

  render() {
    return (
      <tbody>
        <tr>
          <td rowSpan="1">
            <strong>
              {this.props.isAdmin || this.props.view === 'customer'
                ? 'Carer '
                : ''}
              Total
            </strong>
          </td>
          <td />
          <td className="number">
            <strong>
              {!!this.props.timesheetTotal.livein_days && (
                <Days
                  context="Live-in"
                  days={this.props.timesheetTotal.livein_days}
                />
              )}
              {!!this.props.timesheetTotal.minutes_total && (
                <Hours minutes={this.props.timesheetTotal.minutes_total} />
              )}
            </strong>
          </td>
          <td className="number">
            <strong>
              <Currency value={this.props.timesheetTotal.carer_total_gross} />
            </strong>
          </td>
        </tr>
        {(this.props.isAdmin ||
          !!this.props.timesheetTotal.insurance_payment) &&
          this.props.view === 'carer' && (
            <tr>
              <td />
              <td />
              <td className="number">
                <i>Insurance Cost</i>
              </td>
              <td className="number">
                <i>
                  <Currency
                    value={-this.props.timesheetTotal.insurance_payment}
                  />
                </i>
              </td>
            </tr>
          )}
      </tbody>
    );
  }
}

export default TimesheetTotalCarerGross;
