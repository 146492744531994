import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUser } from 'shared/selectors/accountSelectors';
import { getCarerByID } from 'shared/selectors/carerSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { selectWorkHistoryByCarerID } from 'shared/selectors/workHistorySelectors';
import { getCarerById } from '_actions/carerActions';
import {
  deleteWorkHistoryInstance,
  getWorkHistoryByCarerID,
  updateWorkHistoryInstance,
} from '_actions/workHistoryActions';

import CarerWorkHistorySummary from 'components/carers/CarerWorkHistorySummary';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

class CarerWorkHistorySummaryContainer extends Component {
  static propTypes = {
    workHistory: PropTypes.arrayOf(PropTypes.shape()),
    carer: PropTypes.shape().isRequired,
    taxonomiesByClassification: PropTypes.shape(),
    user: PropTypes.shape().isRequired,
    actions: PropTypes.shape({
      deleteWorkHistoryInstance: PropTypes.func.isRequired,
      getCarerById: PropTypes.func.isRequired,
      getWorkHistoryByCarerID: PropTypes.func.isRequired,
      updateWorkHistoryInstance: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    workHistory: null,
    carer: null,
    user: null,
    taxonomiesByClassification: {},
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['work_history_employment_type'];
  }

  UNSAFE_componentWillMount() {
    this.props.actions.getWorkHistoryByCarerID(this.props.user.id);
    this.props.actions.getCarerById(this.props.user.id);
  }

  deleteWorkHistoryInstance = workHistoryInstanceID => {
    this.props.actions.deleteWorkHistoryInstance(workHistoryInstanceID, this.props.user.id);
  };

  render() {
    const {
      workHistory,
      carer,
      taxonomiesByClassification,
    } = this.props;

    if (!workHistory || !carer || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    const isApproved = (carer.profile_details.hi_compliance_fields || {}).work_history === true;

    return (
      <CarerWorkHistorySummary
        carer={carer}
        workHistory={workHistory}
        deleteWorkHistoryInstance={this.deleteWorkHistoryInstance}
        isApproved={isApproved}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

const mapStateToProps = state => {
  const user = getUser(state);
  return {
    carer: getCarerByID(state, user.id),
    workHistory: selectWorkHistoryByCarerID(state, user.id),
    user,
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      CarerWorkHistorySummaryContainer.TAXONOMY_CLASSIFICATIONS,
    ),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      deleteWorkHistoryInstance,
      getCarerById,
      getWorkHistoryByCarerID,
      updateWorkHistoryInstance,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerWorkHistorySummaryContainer);
