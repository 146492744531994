import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import momentPropTypes from 'react-moment-proptypes';
import styled from 'styled-components';

import StyledSelect from 'components/forms/StyledSelect';

const StyledDurationSelector = styled.div`
  select {
    width: 45%;
  }
  span {
    display: inline-block;
    width: 10%;
    text-align: center;
  }
`;

class DurationSelector extends Component {
  componentDidUpdate() {
    // if end is before start, set it to be 15 minutes after
    const shiftTimes = this.props.shiftTimes;
    if (shiftTimes.duration < 0) {
      shiftTimes.end = moment(shiftTimes.start).add(15, 'minutes');
      shiftTimes.duration = 15;
      this.props.updateDuration(shiftTimes);
    }
    // if start is before earliestStart, set it to be the same
    if (
      this.props.earliestStart &&
      shiftTimes.start.isBefore(this.props.earliestStart)
    ) {
      shiftTimes.start = this.props.earliestStart;
      shiftTimes.duration = moment
        .duration(shiftTimes.end.diff(shiftTimes.start))
        .asMinutes();
      this.props.updateDuration(shiftTimes);
    }
    // if end is after latestEnd, set it to be the same
    if (this.props.latestEnd && shiftTimes.end.isBefore(this.props.latestEnd)) {
      shiftTimes.end = this.props.latestEnd;
      shiftTimes.duration = moment
        .duration(shiftTimes.end.diff(shiftTimes.start))
        .asMinutes();
      this.props.updateDuration(shiftTimes);
    }
  }

  updateDuration = (e, side) => {
    const shiftTimes = this.props.shiftTimes;
    const value = e.currentTarget.value;

    shiftTimes[side] = moment(value);

    shiftTimes.duration = moment
      .duration(shiftTimes.end.diff(shiftTimes.start))
      .asMinutes();

    this.props.updateDuration(shiftTimes);
  };

  createTimeOptions = (from, to, today, tomorrow) => {
    let timeOptions = [];
    const countTo = to < from ? 23.75 : to;
    timeOptions = this.renderOptions(from, countTo, today);

    if (to < from) {
      timeOptions = [
        ...timeOptions,
        ...this.renderOptions(0, to, tomorrow, true),
      ];
    }

    return timeOptions;
  };

  renderOptions = (from, to, day, showDay = false) => {
    const options = [];
    for (let i = from; i <= to; i += 0.25) {
      const time =
        i === 24.0
          ? '00:00'
          : moment(day)
              .startOf('day')
              .add(i, 'hours')
              .format('YYYY-MM-DD HH:mm');
      options.push(
        <option key={time} value={time}>
          {`${moment(time).format('HH:mm')} ${
            showDay ? moment(day).format('dddd') : ''
          }`}
        </option>,
      );
    }

    return options;
  };

  render() {
    const shiftTimes = this.props.shiftTimes;
    const start = shiftTimes.start.format('YYYY-MM-DD HH:mm');
    const end = shiftTimes.end.format('YYYY-MM-DD HH:mm');

    const today = moment(shiftTimes.start).format('YYYY-MM-DD');
    const tomorrow = moment(shiftTimes.start)
      .add(1, 'day')
      .format('YYYY-MM-DD');

    let earliestStart = 0.0;
    const latestStart = 23.75;

    const earliestEnd = moment
      .duration(
        moment(start)
          .add(15, 'minute')
          .format('HH:mm'),
      )
      .asHours();
    let latestEnd = moment.duration(moment(start).format('HH:mm')).asHours();

    if (this.props.earliestStart) {
      earliestStart = moment
        .duration(this.props.earliestStart.format('YYYY-MM-DD HH:mm'))
        .asHours();
    }

    if (this.props.latestEnd) {
      latestEnd = moment
        .duration(this.props.latestEnd.format('YYYY-MM-DD HH:mm'))
        .asHours();
    }

    return (
      <StyledDurationSelector>
        <StyledSelect
          value={start}
          onChange={e => this.updateDuration(e, 'start')}
        >
          {this.createTimeOptions(earliestStart, latestStart, today, tomorrow)}
        </StyledSelect>
        <span className="to">to</span>
        <StyledSelect value={end} onChange={e => this.updateDuration(e, 'end')}>
          {this.createTimeOptions(earliestEnd, latestEnd, today, tomorrow)}
        </StyledSelect>
      </StyledDurationSelector>
    );
  }
}

DurationSelector.propTypes = {
  shiftTimes: PropTypes.shape({
    duration: PropTypes.number,
    start: momentPropTypes.momentObj.isRequired,
    end: momentPropTypes.momentObj.isRequired,
  }).isRequired,
  // optional earliest start time
  earliestStart: momentPropTypes.momentObj,
  // optional latest end time
  latestEnd: momentPropTypes.momentObj,
  // function that will be called with the updated duration object
  updateDuration: PropTypes.func.isRequired,
};

DurationSelector.defaultProps = {
  earliestStart: null,
  latestEnd: null,
};

export default DurationSelector;
