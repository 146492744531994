// @flow
import React from 'react';
import styled from 'styled-components';

import InputWrapper from 'components/forms/InputWrapper';
import StyledInput from 'components/forms/StyledInput';
import StyledTitleWithLabel from 'components/forms/StyledTitleWithLabel';
import type { InputType } from 'shared/types';

const CustomStyledInput = styled(StyledInput)`
  &.changed {
    border-color: ${props => props.theme.colors.changeGreen};
  }
`;

const SubscriptText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.uiDarkGrey};
  width: 100%;
  position: ${({ absoluteSubscript }) => absoluteSubscript && 'absolute'};
`;

const StyledPicker = styled.label`
  border-left: 1px solid ${props => props.theme.colors.uiGrey};
  position: absolute;
  bottom: 0;
  right: -40px;
  top: 36px;
  padding: 5px;

  &:hover {
    cursor: pointer;
  }

  > input {
    position: relative;
    top: 9px;
  }
`;

const StyledInputWrapper = styled(InputWrapper)`
  width: ${props => (props.withPicker ? '80%' : '100%')};
`;

type Props = {|
  absoluteSubscript?: boolean,
  align?: string,
  changes: Object,
  extraPadding?: boolean,
  greyTitle?: boolean,
  help?: string,
  input: InputType,
  label: ?string,
  name: string,
  onBlur: Object => void,
  pick: ?(any, any) => void,
  placeholder: string,
  prefix: string,
  subscript?: Array<string>,
  suffix: string,
  title: ?string,
  titleHelp?: string,
  type: string,
  meta: {
    touched: boolean,
    error: boolean,
    warning: boolean,
  },
  verified?: boolean,
|};

const StandardInput = ({
  absoluteSubscript,
  align,
  changes,
  extraPadding,
  greyTitle,
  help,
  input,
  label,
  meta,
  prefix,
  subscript,
  suffix,
  type,
  pick,
  title,
  titleHelp,
  verified,
  ...otherProps
}: Props) => {
  const { touched, error } = meta;

  const withPicker = !!pick && changes.lsh !== changes.rhs;
  return (
    <StyledInputWrapper
      withPicker={withPicker}
      {...{ help, meta, prefix, suffix }}
    >
      <StyledTitleWithLabel
        htmlFor={`${input.title}`}
        title={title}
        label={label}
        error={touched && error}
        extraPadding={extraPadding}
        greyTitle={greyTitle}
        verified={verified}
        help={titleHelp}
      />
      <CustomStyledInput
        align={align}
        className={`
          ${touched && error ? 'error' : ''}
          ${changes.lhs !== changes.rhs ? 'changed' : ''}
        `}
        {...{ help, prefix, suffix, ...input, ...otherProps }}
        id={`${input.name}`}
        type={type}
      />
      {pick && changes.lhs !== changes.rhs && (
        <StyledPicker
          htmlFor={`${input.name}-picker-input`}
          key={`${input.name}-picker`}
        >
          <input
            id={`${input.name}-picker-input`}
            onChange={e => {
              e.persist();
              pick(input.value, e.target.checked);
            }}
            type="checkbox"
          />
        </StyledPicker>
      )}
      {subscript &&
        !!subscript.length &&
        subscript.map(line => (
          <SubscriptText absoluteSubscript={absoluteSubscript}>
            {line}
          </SubscriptText>
        ))}
    </StyledInputWrapper>
  );
};

StandardInput.defaultProps = {
  changes: {},
  help: '',
  label: null,
  prefix: '',
  suffix: '',
  type: 'text',
  pick: null,
  title: null,
};

export default StandardInput;
