import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledElement = styled.button`
  appearance: none;
  background: white;
  border-radius: 7px;
  border: none;
  box-shadow: 0 1px 4px 0 ${props => props.theme.colors.shadowGrey};
  color: ${props => props.theme.colors.midGrey};
  display: block;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:hover {
    color: ${props => props.theme.colors.hiAccentBlue};
    cursor: pointer;
  }

  &:disabled {
    color: ${props => props.theme.colors.midGrey};
    cursor: wait;
    opacity: 0.8;
  }

  &:active {
    box-shadow: inset 0 1px 4px 0 ${props => props.theme.colors.shadowGrey};
  }
`;

class LoadMore extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    params: PropTypes.shape().isRequired,
    search: PropTypes.shape().isRequired,
  };

  constructor() {
    super();
    this.state = { loading: false };
  }

  UNSAFE_componentWillReceiveProps() {
    // TODO: Only set if onClick handler (e.g. method to load more ) changes
    this.setState({ loading: false });
  }

  handleClick = e => {
    e.preventDefault();
    const { params, search } = this.props;
    this.setState({ loading: true });
    const newParams = { ...params };
    newParams.page = search.pagination.page + 1;
    newParams.per_page = search.pagination.per_page;
    this.props.onClick(newParams);
  };

  render() {
    return (
      <StyledElement disabled={this.state.loading} onClick={this.handleClick}>
        {this.state.loading ? 'Loading…' : 'Load More'}
      </StyledElement>
    );
  }
}

export default LoadMore;
