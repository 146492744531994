// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import FranchiseUsersSummary from 'components/franchise/FranchiseUsersSummary';

import { populateFranchiseWithID, updateFranchise } from '_actions/franchiseActions';

import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { getFranchiseByID } from 'shared/selectors/franchiseSelectors';
import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';
import { getUsersForFranchise } from 'shared/selectors/userSelectors';

import type { FranchiseType, TaxonomiesClassificationType } from 'shared/types';

type Props = {
  franchise: FranchiseType,
  franchiseID: string,
  franchiseUsers: Array,
  franchiseUsersLoadingState: boolean,
  populateFranchiseWithID: (value: string) => mixed,
  taxonomiesByClassification: TaxonomiesClassificationType,
  updateFranchise: (value: string, Object) => Object,
};

class AdminFranchiseUsersContainer extends Component<Props> {

  static defaultProps = {};

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'account_manager',
      'franchise_country',
      'franchise_state',
    ];
  }

  componentDidMount() {
    if (!!this.props.franchiseID) {
      this.props.populateFranchiseWithID(this.props.franchiseID);
    }
  }

  updateLeadContact = (userID: string) => {
    this.props.updateFranchise(this.props.franchiseID, {'lead_contact_id': userID});
  };

  render() {
    const {
      franchise,
      franchiseUsers,
      franchiseUsersLoadingState,
      taxonomiesByClassification,
    } = this.props;

    if ( !franchise || !taxonomiesByClassification || franchiseUsersLoadingState) {
      return <LoadingPlaceholder />;
    }

    return (
      <FranchiseUsersSummary
        franchise={franchise}
        franchiseUsers={franchiseUsers}
        isAdmin={true}
        taxonomiesByClassification={taxonomiesByClassification}
        updateLeadContact={this.updateLeadContact}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    franchise: getFranchiseByID(state, props.franchiseID),
    franchiseUsers: getUsersForFranchise(state, props.franchiseID),
    franchiseUsersLoadingState: loadingSelectorFactory('franchises', 'franchiseUsers')(state),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      AdminFranchiseUsersContainer.TAXONOMY_CLASSIFICATIONS,
    ),
  };
};

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      populateFranchiseWithID,
      updateFranchise,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminFranchiseUsersContainer);
