// @flow
import React from 'react';

import ExpandableText from 'components/global/informationdisplay/ExpandableText';
import { ModuleHeader } from 'components/global/layout/Module';

import { renderTaxon } from 'components/helpers/taxonomyHelpers';
import { text2html } from 'components/helpers/stringHelpers';

import type {
  CarerType,
  ProfileDataType,
  TaxonomiesClassificationType,
} from 'shared/types';

type Props = {|
  carer: CarerType,
  taxonomiesByClassification: TaxonomiesClassificationType,
|};

const ProfilePersonal = ({ carer, taxonomiesByClassification }: Props) => {
  const data: ProfileDataType = carer.profile_details.profile_data;

  if (!data.bio && !data.hobbies && !data.other_hobbies) {
    return null;
  }

  const hobbies: Array<string> = data.hobbies
    ? data.hobbies.map(hobby =>
        renderTaxon(taxonomiesByClassification['hobbies'], hobby),
      )
    : [];
  if (data.other_hobbies) {
    hobbies.push(data.other_hobbies);
  }

  return (
    <div>
      {data.bio && (
        <div id="bio">
          <ModuleHeader noIndent>About me...</ModuleHeader>
          <ExpandableText>{text2html(data.bio)}</ExpandableText>
        </div>
      )}
      {hobbies.length > 0 && (
        <div id="interests">
          <ModuleHeader noIndent>I enjoy…</ModuleHeader>
          <p>{hobbies.join(', ')}</p>
        </div>
      )}
    </div>
  );
};

export default ProfilePersonal;
