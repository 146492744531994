// @flow
import { required, validEmail } from 'validators';

import type { TaxonomiesClassificationType } from 'shared/types';

const franchiseUserFieldsConfig = (
  currentValues: Object,
  extraDisplayCriteria: { editMode: boolean },
  taxonomiesByClassification: TaxonomiesClassificationType,
) => [
  {
    displayCriteria: true,
    sectionHeader: 'Create Franchisee',
    sectionId: 'franchise',
    rows: [
      {
        displayCriteria: true,
        rowId: 'franchise-info',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'franchise-id',
            component: 'StandardSelect',
            fieldConfiguration: {
              name: 'franchise_id',
              label: "Franchise",
              validate: [required],
              disabled: true,
            },
            options: taxonomiesByClassification.franchises,
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
    ]
  },

  {
    displayCriteria: true,
    sectionHeader: 'User Details',
    sectionId: 'user-details',
    rows: [
      {
        displayCriteria: true,
        rowId: 'contact-name',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'contact-firstname',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'firstname',
              label: 'First Name',
              type: 'text',
              validate: [required],
            },
            size: {
              xs: 12,
              md: 5,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'contact-lastname',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'lastname',
              label: 'Last Name',
              type: 'text',
              validate: [required],
            },
            size: {
              xs: 12,
              md: 5,
            },
          },
        ],
      },
      {
        displayCriteria: true,
        rowId: 'contact-information',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'contact-email',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'email',
              label: 'Email Address',
              validate: [validEmail, required],
              type: 'text',
            },
            size: {
              xs: 12,
              md: 5,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'contact-phone',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'phone_number',
              label: 'Phone Number',
              type: 'text',
            },
            size: {
              xs: 12,
              md: 5,
            },
          },
        ],
      },
    ],
  },
];

export default franchiseUserFieldsConfig;
