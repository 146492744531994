// @flow
import React, { useEffect } from 'react';
import {
  getCarerProfileByCarerId,
  updateCarerProfile,
} from '_actions/carerActions';
import AccountAvailability from 'components/account/AccountAvailability';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getLiveProfileByCarerID } from 'shared/selectors/carerSelectors';
import { getUser } from 'shared/selectors/accountSelectors';

import type { ProfileType } from 'shared/types';

type Props = {
  getCarerProfileByCarerId: string => {},
  profile: ProfileType,
  updateCarerProfile: (string, Object, boolean) => {},
  userID: string,
};

function AccountAvailabilityContainer({
  getCarerProfileByCarerId,
  profile = null,
  updateCarerProfile,
  userID,
}: Props) {
  useEffect(() => {
    if (!profile) {
      getCarerProfileByCarerId(userID);
    }
  }, [userID]);

  const updateAvailability = data => {
    updateCarerProfile(
      userID,
      { work_state: data.available ? 'available' : 'unavailable' },
      false, // do not notify
    );
  };

  if (!profile || profile.user_id !== userID) {
    return <LoadingPlaceholder />;
  }
  return (
    <AccountAvailability
      available={profile.work_state === 'available'}
      updateAvailability={updateAvailability}
    />
  );
}

const mapStateToProps = state => {
  const userID = getUser(state).id;
  return {
    userID,
    profile: getLiveProfileByCarerID(state, userID),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateCarerProfile,
      getCarerProfileByCarerId,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountAvailabilityContainer);
