import React from 'react';
import styled from 'styled-components';

import RequiredTitle from 'components/forms/required/RequiredTitle';
import StyledLabel from 'components/forms/StyledLabel';
import StyledFieldTitle from 'components/forms/StyledFieldTitle';
import StyledFieldTitleTag from 'components/forms/StyledFieldTitleTag';
import TickIcon from 'components/global/icons/small/TickIcon';

const StyledTitle = styled.div`
  color: ${props => (props.greyTitle ? props.theme.colors.midGrey : '')};
  padding-bottom: ${props => (props.extraPadding ? '10px' : '')};
`;

export default ({
  error,
  extraPadding,
  greyTitle,
  help,
  label,
  tag,
  title,
  verified,
  ...otherProps
}) => (
  <>
    {title && (
      <StyledFieldTitle {...otherProps}>
        <StyledTitle greyTitle={greyTitle} extraPadding={extraPadding}>
          {RequiredTitle(title, !error)}
        </StyledTitle>
        {verified && (
          <TickIcon
            height="18px"
            width="18px"
            marginLeft="10px"
            label="Verified"
          />
        )}
        {tag && <StyledFieldTitleTag>{tag}</StyledFieldTitleTag>}
        {help !== '' && <em>{help}</em>}
      </StyledFieldTitle>
    )}
    {label && (
      <StyledLabel {...otherProps}>
        {title ? label : RequiredTitle(label, !error)}
        {verified && (
          <TickIcon
            height="18px"
            width="18px"
            marginLeft="10px"
            label="Verified"
          />
        )}
      </StyledLabel>
    )}
  </>
);
