// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AdminUserTestimonials from 'components/users/AdminUserTestimonials';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import {
  deleteTestimonial,
  getTestimonialsByCarerID,
} from '_actions/testimonialActions';
import { selectTestimonialsByCarerID } from 'shared/selectors/testimonialSelectors';
import type {
  CarerType,
  TestimonialType,
  TaxonomiesClassificationType,
} from 'shared/types';

type Props = {
  deleteTestimonial: Function,
  getTestimonialsByCarerID: Function,
  testimonials: Array<TestimonialType>,
  user: CarerType,
  taxonomiesByClassification: TaxonomiesClassificationType,
};

export class AdminUserTestimonialsContainer extends Component<Props> {
  componentDidMount() {
    this.props.getTestimonialsByCarerID(this.props.user.id);
  }

  render() {
    if (!this.props.testimonials) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminUserTestimonials
        carer={this.props.user}
        deleteTestimonial={this.props.deleteTestimonial}
        testimonials={this.props.testimonials}
        taxonomiesByClassification={this.props.taxonomiesByClassification}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  taxonomiesByClassification: getTaxonomiesByClassifications(state, [
    'testimonial_relationship',
  ]),
  testimonials: selectTestimonialsByCarerID(state, props.user.id),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteTestimonial,
      getTestimonialsByCarerID,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserTestimonialsContainer);
