import React from 'react';
import styled from 'styled-components';

import { SC_NAME } from 'components/global/ContactInfo';
import Button from 'components/buttons/Button';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

const ModuleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;

  @media (min-width: ${props => props.theme.screen.small}px) {
    width: auto;
  }
`;

const CustomModule = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 7px;
  box-shadow: 0 1px 4px 0 ${props => props.theme.colors.shadowGrey};
  width: 100%;
  padding: 50px 20px;
  text-align: center;

  @media (min-width: ${props => props.theme.screen.small}px) {
    width: unset;
    padding: 50px;
  }
`;

const StyledHeader = styled.h2`
  display: block;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 20px;
`;

const AccountConfirm = ({ magicTokenError }) =>
  magicTokenError ? (
    <ModuleContainer>
      <CustomModule>
        <StyledHeader>
          We haven’t been able to log you into the {SC_NAME} app – please log
          in using the link we’ve emailed you.
        </StyledHeader>
        <a href="http://www.supercarers.com">
          <Button>Back to website</Button>
        </a>
      </CustomModule>
    </ModuleContainer>
  ) : (
    <LoadingPlaceholder />
  );

export default AccountConfirm;
