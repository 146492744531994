import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Row, Col } from 'components/grid';
import styled from 'styled-components';

import Module, { ModuleHeader } from 'components/global/layout/Module';
import Paginator from 'components/global/Paginator';
import InvoiceTable from 'components/invoices/InvoiceTable';

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  display: block;
  margin-bottom: 10px;

  > input {
    cursor: pointer;
  }

  @media (min-width: 768px) {
    float: right;
    margin-right: 10px;
  }
`;

class CustomerInvoicesOverview extends Component {
  static propTypes = {
    showVoid: PropTypes.bool.isRequired,
    toggleVoid: PropTypes.func.isRequired,
  };

  render() {
    const { showVoid, toggleVoid } = this.props;
    return (
      <section>
        <Module>
          <Container>
            <Row>
              <Col xs={12} md={8}>
                <StyledHeader>Invoice Overview</StyledHeader>
              </Col>
              <Col xs={12} md={4}>
                <StyledLabel htmlFor="toggle-void">
                  <input
                    type="checkbox"
                    onChange={toggleVoid}
                    id="toggle-void"
                    name="toggle-void"
                    checked={showVoid}
                    value={showVoid}
                  />{' '}
                  Show void invoices
                </StyledLabel>
              </Col>
            </Row>
          </Container>
          <InvoiceTable invoices={this.props.invoices} />
          {!!this.props.invoices && this.props.invoices.length > 0 && (
            <Paginator
              location={this.props.location}
              currentPage={parseInt(this.props.currentPage, 10)}
              totalPages={this.props.search.pagination.pages || 1}
              path="/invoices/"
            />
          )}
        </Module>
      </section>
    );
  }
}

CustomerInvoicesOverview.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      page: PropTypes.string,
    }),
  }),
  search: PropTypes.shape(),
  invoices: PropTypes.arrayOf(PropTypes.shape()),
  currentPage: PropTypes.string,
};

CustomerInvoicesOverview.defaultProps = {
  location: {
    query: {
      page: '1',
    },
  },
  search: {},
  invoices: {},
  currentPage: '1',
};

export default CustomerInvoicesOverview;
