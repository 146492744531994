// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateUser } from '_actions/accountActions';

import { getUser, isImpersonating } from 'shared/selectors/accountSelectors';

import CustomerRedirect from 'components/customers/CustomerRedirect';

import type {
  UserType,
} from 'shared/types';

type Props = {
  user: UserType,
  isImpersonating: Boolean,
  updateUser: (string, Object) => void,
};

export class CustomerHomeContainer extends Component<Props> {
  static defaultProps = {
    user: null,
  };

  setModalViewed = () => {
    const { isImpersonating, updateUser, user } = this.props;
    if (!isImpersonating) {
      updateUser(user.id, { show_welcome_modal: false });
    }
  };

  render() {
    const {
      user,
    } = this.props;

    return <CustomerRedirect user={user} />;

  }
}

function mapStateToProps(state) {
  const user = getUser(state);
  return {
    user,
    isImpersonating: isImpersonating(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUser,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerHomeContainer);
