import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AdminChargesOverview from 'components/admin/AdminChargesOverview';

class AdminChargesOverviewContainer extends Component {
  render() {
    return <AdminChargesOverview location={this.props.location} />;
  }
}

AdminChargesOverviewContainer.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default AdminChargesOverviewContainer;
