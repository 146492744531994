import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import _ from 'lodash';

import { hasRole } from 'shared/helpers/accountHelpers';
import { isFranchiseMember } from 'shared/selectors/accountSelectors';

import {
  getUser,
  hasConfirmedTerms,
  isLoggedIn,
  setRedirect,
} from 'shared/selectors/accountSelectors.js';
import { isRejectedCarer } from 'shared/selectors/carerSelectors.js';

class AuthenticatedRoute extends Component {
  static propTypes = {
    hasConfirmedTerms: PropTypes.bool.isRequired,
    hasRole: PropTypes.bool.isRequired,
    isFranchiseMember: PropTypes.bool,
    isLoggedIn: PropTypes.bool.isRequired,
    isRejectedCarer: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isFranchiseMember: false,
    isRejectedCarer: false,
  };

  componentDidMount() {
    if (!this.props.hasConfirmedTerms || !this.props.isLoggedIn) {
      setRedirect(this.props.location.pathname);
    }
  }

  render() {
    if (!this.props.isLoggedIn) {
      return <Redirect to="/" />;
    } else if (!this.props.hasRole || this.props.isRejectedCarer) {
      return <Redirect to="/unauthorised/" />;
    } else if (!this.props.isFranchiseMember && !this.props.hasConfirmedTerms) {
      return <Redirect to="/terms/" />;
    }

    return <Route {...this.props} />;
  }
}

function mapStateToProps(state, props) {
  const user = getUser(state);
  let allowedRoles = props.roles || ['Carer', 'Customer', 'Franchisee'];
  allowedRoles.push('Admin', 'Super');
  allowedRoles = _.uniq(allowedRoles);
  return {
    hasRole: hasRole(user, ...allowedRoles),
    isFranchiseMember: isFranchiseMember(state),
    hasConfirmedTerms: hasConfirmedTerms(state),
    isLoggedIn: isLoggedIn(state),
    isRejectedCarer: isRejectedCarer(state),
    roles: allowedRoles,
  };
}

export default connect(mapStateToProps)(AuthenticatedRoute);
