import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DiscountPropType from 'proptypes/DiscountPropType';
import { getDiscountResults } from 'shared/selectors/discountSelectors';
import {
  searchDiscounts,
  assignDiscountAndRefresh,
  unassignDiscountAndRefresh,
} from '_actions/discountActions';
import AdminUserDiscounts from 'components/users/AdminUserDiscounts';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

class AdminUserDiscountsContainer extends Component {
  static propTypes = {
    assignDiscountAndRefresh: PropTypes.func.isRequired,
    discounts: PropTypes.arrayOf(DiscountPropType),
    searchDiscounts: PropTypes.func.isRequired,
    unassignDiscountAndRefresh: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    discounts: null,
  };

  UNSAFE_componentWillMount() {
    this.props.searchDiscounts({ user_id: this.props.user.id });
  }

  assignDiscount = data =>
    this.props.assignDiscountAndRefresh(this.props.user.id, data.slug, {
      user_id: this.props.user.id,
    });

  unassignDiscount = discountId =>
    this.props.unassignDiscountAndRefresh(this.props.user.id, discountId, {
      user_id: this.props.user.id,
    });

  render() {
    if (!this.props.discounts) {
      return <LoadingPlaceholder />;
    }
    return (
      <AdminUserDiscounts
        assignDiscount={this.assignDiscount}
        discounts={this.props.discounts}
        unassignDiscount={this.unassignDiscount}
        user={this.props.user}
      />
    );
  }
}

const mapStateToProps = state => ({
  discounts: getDiscountResults(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchDiscounts,
      unassignDiscountAndRefresh,
      assignDiscountAndRefresh,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserDiscountsContainer);
