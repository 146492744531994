import React from 'react';

import Module, { ModuleHeader } from 'components/global/layout/Module';
import { Container, Row, Col } from 'components/grid';

export default function NoBidsMessage() {
  return (
    <Module>
      <ModuleHeader>Please check back later</ModuleHeader>
      <Container>
        <Row>
          <Col xs={12}>
            <p>
              We are currently in the process of finding and inviting carers to
              apply for your need.
            </p>
            <p>
              Please keep an eye on your email for a notification that we have
              applications for you to review.
            </p>
          </Col>
        </Row>
      </Container>
    </Module>
  );
}
