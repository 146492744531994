// @flow
import React from 'react';
// $FlowFixMe
import { Field } from 'redux-form';
import Button from 'components/buttons/Button';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import StandardTextarea from 'components/forms/StandardTextarea';

import Module, {
  ModuleHeader,
  ModuleHero,
} from 'components/global/layout/Module';
import { Container, Col } from 'components/grid';
import { required } from 'validators';

type Props = {
  convertNeed: Object => void,
  needFranchiseID: string,
  needState: ?string,
  needID: string,
  shiftsAssigned: boolean,
};

const ConvertCustomer = ({
  needID,
  needFranchiseID,
  needState,
  convertNeed,
  shiftsAssigned,
}: Props) => {
  if (
    !['new', 'need_submitted', 'team_proposed', 'job_created'].includes(
      needState,
    )
  ) {
    return <ModuleHero>This customer has already been converted.</ModuleHero>;
  }

  if (!needFranchiseID && !shiftsAssigned) {
    return (
      <ModuleHero>
        Cannot convert. There are no shifts assigned to carers.
      </ModuleHero>
    );
  }

  return (
    <Module>
      <Form
        name={`convertCustomer-${needID}`}
        fields={[
          'message',
          'checkedSchedule',
          'checkedShifts',
          'checkedCareTeam',
        ]}
        onSubmit={convertNeed}
        component={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <ModuleHeader>Convert Customer</ModuleHeader>
            <Container>
              <FormRow>
                <Col xs={12}>
                  This will set the need status to &ldquo;customer&rdquo;. Please
                  check the following:
                </Col>
              </FormRow>
              {
                needFranchiseID
                ? (
                  <FormRow>
                    <Col xs={12}>
                      <label htmlFor="checkedCareTeam">
                        <Field
                          id="checkedCareTeam"
                          name="checkedCareTeam"
                          component="input"
                          type="checkbox"
                          validate={[required]}
                        />
                        Assigned correct states to carers in care team.
                      </label>
                    </Col>
                  </FormRow>
                ) : (
                  <div>
                    <FormRow>
                      <Col xs={12}>
                        <label htmlFor="checkedSchedule">
                          <Field
                            id="checkedSchedule"
                            name="checkedSchedule"
                            component="input"
                            type="checkbox"
                            validate={[required]}
                          />
                          The schedule start date is correct
                        </label>
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col xs={12}>
                        <label htmlFor="checkedShifts">
                          <Field
                            id="checkedShifts"
                            name="checkedShifts"
                            component="input"
                            type="checkbox"
                            validate={[required]}
                          />
                          All shifts are assigned to carers
                        </label>
                      </Col>
                    </FormRow>
                  </div>
                )
              }
              {
                !needFranchiseID && (
                <FormRow>
                  <Col xs={12}>
                    Clicking convert will send out a welcome email with
                    carer and shift details.
                    If you would like to include a custom message to the customer
                    in the email please enter it below.
                    <Field component={StandardTextarea} name="message" />
                  </Col>
                </FormRow>
              )}
            </Container>
            <Container>
              <FormRow>
                <Col xs={12}>
                  <Button type="submit" disabled={invalid}>
                    Convert
                  </Button>
                </Col>
              </FormRow>
            </Container>
          </form>
        )}
      />
    </Module>
  );
};

export default ConvertCustomer;
