import React from 'react';
import PropTypes from 'prop-types';
import SuperCarersClient from 'clients/SuperCarersClient';
import DBSCheck, { Status } from 'components/carers/DBSCheck';

export default class DBSCheckContainer extends React.Component {
  static propTypes = {
    certificateId: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
  };

  state = {
    status: Status.UNVERIFIED,
  };

  componentDidUpdate = prevProps => {
    if (prevProps.certificateId !== this.props.certificateId) {
      this.setState({ status: Status.UNVERIFIED });
    }
  };

  onVerify = () => {
    if (this.state.status !== Status.UNVERIFIED) {
      return;
    }

    const { certificateId, dob, lastname } = this.props;

    SuperCarersClient.get('/dbscheck/registered-online/', {
      dob,
      lastname,
      certificate_id: certificateId,
    })
      .then((response = {}) =>
        response.data.registered_online === true
          ? this.setState({ status: Status.REGISTERED })
          : this.setState({ status: Status.NOT_REGISTERED }),
      )
      .catch(() => this.setState({ status: Status.NOT_REGISTERED }));

    this.setState({ status: Status.LOADING });
  };

  render() {
    return <DBSCheck status={this.state.status} onVerify={this.onVerify} />;
  }
}
