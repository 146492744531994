import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';

import toTitleCase from 'components/helpers/toTitleCase';

class ChargeTableRow extends Component {
  render() {
    const { charge, payee } = this.props;
    const created = moment(charge.created_at).format('dddd, MMMM Do YYYY');

    return (
      <tr>
        <td>{created}</td>

        <td>
          <span className={`status ${charge.state}`}>
            {toTitleCase(charge.state)}
          </span>
        </td>

        <td>{charge.nice_amount}</td>

        <td>{payee}</td>

        <td>
          <Link to={`/charges/${charge.id}/`}>View</Link>
        </td>
      </tr>
    );
  }
}

ChargeTableRow.propTypes = {
  charge: PropTypes.shape({
    id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    nice_amount: PropTypes.string.isRequired,
  }).isRequired,
  payee: PropTypes.string.isRequired,
};

export default ChargeTableRow;
