// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { convertNeed } from '_actions/needActions';
import { selectScheduleShiftsByNeedID } from 'shared/selectors/scheduleShiftsSelectors';

import ConvertCustomer from 'components/bids/ConvertCustomer';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import type { NeedType, ScheduleShiftObjectType } from 'shared/types';

type Props = {
  convertNeed: (string, Object) => void,
  getNeedByID: string => Object,
  need: NeedType,
  needID: string,
  scheduleShifts: ScheduleShiftObjectType,
};

const ConvertCustomerContainer = ({
  convertNeed,
  getNeedByID,
  need,
  needID,
  scheduleShifts,
}: Props) => {
  const callConvertNeed = ({ message }: { message: string }): void => {
    convertNeed(needID, { message });
  };

  if (!need) {
    return <LoadingPlaceholder />;
  }

  const shiftsAssigned = scheduleShifts.some(
    schedule => schedule && schedule.find(shift => shift.user_id),
  );

  return (
    <ConvertCustomer
      name="Convert Customer"
      convertNeed={callConvertNeed}
      needFranchiseID={need.franchise_id}
      needID={needID}
      needState={need.state}
      shiftsAssigned={shiftsAssigned}
    />
  );
};

const mapStateToProps = (state, props) => ({
  scheduleShifts: selectScheduleShiftsByNeedID(state, props.needID),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      convertNeed,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConvertCustomerContainer);
