import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/buttons/Button';
import styled from 'styled-components';

export const Status = {
  UNVERIFIED: 'UNVERIFIED',
  LOADING: 'LOADING',
  REGISTERED: 'REGISTERED',
  NOT_REGISTERED: 'NOT_REGISTERED',
};

const StatusButton = styled(Button)`
  background-color: ${props => props.theme.colors[props.color]} !important;
  border-color: transparent;
`;

export default class DBSCheck extends React.Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
    onVerify: PropTypes.func.isRequired,
  };

  render() {
    const { status } = this.props;
    let buttonText = 'Verify';
    let buttonColor;

    if (status === Status.LOADING) {
      buttonText = 'Processing...';
    } else if (status === Status.REGISTERED) {
      buttonText = '✔ Registered';
      buttonColor = 'statusGreen';
    } else if (status === Status.NOT_REGISTERED) {
      buttonText = '✘ Not Registered';
      buttonColor = 'statusRed';
    }

    return (
      <StatusButton
        type="button"
        color={buttonColor}
        onClick={this.props.onVerify}
      >
        {buttonText}
      </StatusButton>
    );
  }
}
