import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

const Tile = styled.div`
  border-radius: 2px;
  width: 300px;
  height: 300px;
  background: ${({ background }) => background};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  filter: blur(0.5px);
  transition: all 1s;
  margin: 10px;

  &:hover {
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.16), 0 10px 16px rgba(0, 0, 0, 0.23);
  }
`;

const Text = styled.div`
  font-size: 24px;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CareSolvedContainer = () => {
  return (
    <Container>
      <Tile background="#d25a68">
        <Text>Care Coach</Text>
      </Tile>
      <Tile background="#5ba7d0" />
      <Tile background="#ece8dd" />
      <Tile />
    </Container>
  );
};

export default CareSolvedContainer;
