import React from 'react';
import { Field } from 'redux-form';

const PercentField = ({ ...otherProps }) => (
  <Field
    format={value => (value === '' ? '' : value * 100)}
    onBlur={value => value * 100}
    name="exceptional_commission"
    parse={value => (value === '' ? '' : value / 100)}
    suffix="%"
    type="number"
    {...otherProps}
  />
);

export default PercentField;
