// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateNeed } from '_actions/needActions';
import CareNeedActions from 'actions/CareNeedActions';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import CareNeedManage from 'components/careNeeds/CareNeedManage';
import { CustomerVerifyEmailPlaceholder } from 'components/customers/CustomerEmptyStateLayouts';
import CareTeamPlaceholderImage from 'images/customers/GroupOfCarers.png';

import { getUser } from 'shared/selectors/accountSelectors';
import { getNeedByID } from 'shared/selectors/needSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { bindActionCreators } from 'redux';
import {
  convertNeedToFormData,
  convertArrayToTaxon,
} from 'components/helpers/taxonomyHelpers';

import type {
  MatchType,
  TaxonomiesClassificationType,
  UserType,
} from 'shared/types';

type InitialValues = {
  care_days: ?Array<string>,
  carer_gender: ?string,
  condition_experience: ?Array<string>,
  date_flexibility: ?boolean,
  date_prospective_end: ?string,
  date_prospective_start: ?string,
  needs_car: ?boolean,
  needs_license: ?boolean,
  pets: ?Array<string>,
  relationship: string,
  requested_care_types: ?Array<string>,
  schedule_description: ?string,
  services: ?Array<string>,
  smoker_preference: ?string,
  start_date_accuracy: ?string,
  story: ?string,
  story_equipment: ?string,
  story_third_parties: ?string,
};

// Have not imported NeedType as we don't use the whole object and this freaks out at values we don't use
type Props = {
  need: Object,
  match: MatchType,
  taxonomiesByClassification: TaxonomiesClassificationType,
  actions: {
    updateNeed: (Object, ?string) => void,
  },
  user: UserType,
};

export class CareNeedManageContainer extends Component<Props> {
  static defaultProps = {
    need: null,
    taxonomiesByClassification: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'hobbies',
      'condition',
      'services',
      'pets',
      'smoker_preference',
      'gender_preference',
      'requested_care_type',
      'start_date_accuracy',
      'care_need_required',
    ];
  }

  componentDidMount() {
    if (!this.props.need) {
      CareNeedActions.getNeedById(this.props.needId);
    }
  }

  onSubmit = (data: InitialValues, redirect: ?string) => {
    const localData = data;
    if (data.condition_experience) {
      localData.condition_experience = convertArrayToTaxon(
        localData.condition_experience,
      );
    }

    if (data.services) {
      localData.services = convertArrayToTaxon(localData.services);
    }
    this.props.actions.updateNeed(localData, redirect);
  };

  render() {
    const { need, taxonomiesByClassification, user } = this.props;

    if (!need || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    const submissableFields = [
      'care_days',
      'carer_gender',
      'condition_experience',
      'date_flexibility',
      'date_prospective_end',
      'date_prospective_start',
      'firstname',
      'lastname',
      'needs_car',
      'needs_license',
      'pets',
      'postcode',
      'relationship',
      'requested_care_types',
      'schedule_description',
      'services',
      'smoker_preference',
      'start_date_accuracy',
      'story',
      'story_equipment',
      'story_third_parties',
    ];

    const initialValues = convertNeedToFormData(need, submissableFields);
    const needLocked = !['new', 'need_submitted'].includes(need.state);
    const emailVerified = user.confirmed_at;
    const needSubmitted = need.state === 'need_submitted';
    const requiredFields = taxonomiesByClassification.care_need_required.map(
      taxon => taxon.value,
    );

    if (needLocked && !emailVerified) {
      return (
        <CustomerVerifyEmailPlaceholder
          type="careDetails"
          image={CareTeamPlaceholderImage}
        />
      );
    }

    return (
      <CareNeedManage
        onSubmit={this.onSubmit}
        need={need}
        needLocked={needLocked}
        needSubmitted={needSubmitted}
        initialValues={initialValues}
        taxonomiesByClassification={taxonomiesByClassification}
        requiredFields={requiredFields}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    needId: state.accounts.user.care_needs[0].id,
    need: getNeedByID(state, state.accounts.user.care_needs[0].id),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      CareNeedManageContainer.TAXONOMY_CLASSIFICATIONS,
    ),
    user: getUser(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateNeed,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CareNeedManageContainer);
