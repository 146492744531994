/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TabbedComponents from 'components/global/layout/TabbedComponents';
import Paginator from 'components/global/Paginator';
import AdminCarerProfileChangeTab from 'components/admin/carers/AdminCarerProfileChangeTab';

const TaxonomyPropType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string,
    title: PropTypes.string,
  }),
);

class AdminCarerProfileChangeOverview extends Component {
  static propTypes = {
    profiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    location: PropTypes.shape().isRequired,
    filterProfiles: PropTypes.func.isRequired,
    onSearchProfileStateChange: PropTypes.func.isRequired,
    profileFilter: PropTypes.string.isRequired,
    taxonomiesByClassification: PropTypes.shape({
      profile_needs_approval: TaxonomyPropType,
    }),
    search: PropTypes.shape(),
    noPendingChanges: PropTypes.bool,
  };

  handleOnTabChange = profileState => {
    this.props.onSearchProfileStateChange(profileState);
  };

  render() {
    const neededFields = {};
    const taxonomiesByClassification = this.props.taxonomiesByClassification;

    //TODO LP improve once able to test
    taxonomiesByClassification.profile_needs_approval &&
      taxonomiesByClassification.profile_needs_approval.forEach(
        field => (neededFields[field.taxon_id] = field.value),
      );

    const tabProps = {
      onMount: this.handleOnTabChange,
      profiles: this.props.profiles,
      noPendingChanges: this.props.noPendingChanges,
      filterProfiles: this.props.filterProfiles,
      profileFilter: this.props.profileFilter,
      filterQueried: this.props.filterQueried,
      queried: this.props.queried,
      neededFields,
    };

    return (
      <section className="component-wrapper">
        <TabbedComponents>
          <AdminCarerProfileChangeTab
            name="Approved"
            profileState="approved"
            default
            {...tabProps}
          />
          <AdminCarerProfileChangeTab
            name="Applied"
            profileState="applied"
            {...tabProps}
          />
        </TabbedComponents>
        {this.props.search && this.props.search.pagination ? (
          <Paginator
            currentPage={this.props.search.pagination.page}
            totalPages={this.props.search.pagination.pages}
            location={this.props.location}
          />
        ) : (
          ''
        )}
      </section>
    );
  }
}

export default AdminCarerProfileChangeOverview;
