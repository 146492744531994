// @flow
import React from 'react';

import { CareTypeIcons } from 'components/global/icons/IconLabelMappings';

import { renderTaxon } from 'components/helpers/taxonomyHelpers';

import type {
  CarerType,
  ProfileDataType,
  TaxonomiesClassificationType,
} from 'shared/types';

type Props = {|
  carer: CarerType,
  taxonomiesByClassification: TaxonomiesClassificationType,
|};

const ProfileCareTypes = ({ carer, taxonomiesByClassification }: Props) => {
  const data: ProfileDataType = carer.profile_details.profile_data;

  if (!data || !data.care_type || data.care_type.length < 1) {
    return null;
  }

  return (
    <div>
      <div className="care-types">
        {data.care_type.map(type => (
          <CareTypeIcons
            key={type}
            careType={type}
            label={renderTaxon(taxonomiesByClassification['care_type'], type)}
          />
        ))}
      </div>
    </div>
  );
};

export default ProfileCareTypes;
