import PropTypes from 'prop-types';
import React from 'react';

const ExperienceIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 24"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="experience-icon"
        stroke="currentColor"
        strokeWidth="0.2"
        fill="currentColor"
        fillRule="evenodd"
      >
        <g>
          <path d="M9.69514345,16.5683315 C9.88780469,16.5683315 10.0723062,16.6286231 10.2282484,16.7419533 L14.9591019,20.1840164 L13.1580593,14.6344659 C13.0352094,14.2586632 13.1684857,13.849768 13.4894366,13.6185745 L18.2189302,10.2018973 L12.3679215,10.2018973 C11.973986,10.2018973 11.6271957,9.95030442 11.5057058,9.57540831 L9.69559677,4.00455179 L7.8850344,9.57540831 C7.76354449,9.95030442 7.41720757,10.2018973 7.02327199,10.2018973 L1.17135671,10.2018973 L5.90175689,13.6203878 C6.22225454,13.8520346 6.35507747,14.2609298 6.23268091,14.6367325 L4.42529179,20.1808431 L9.16249178,16.7410466 C9.31888737,16.6281698 9.50293553,16.5683315 9.69514345,16.5683315 Z M14.9645418,21.0979106 C14.7741472,21.0979106 14.5905523,21.0371657 14.4323434,20.9220223 L9.69514345,17.4749726 L4.95749014,20.9147691 C4.55902135,21.2035343 3.97333117,21.1033505 3.68819253,20.7103215 C3.51729068,20.4755015 3.47195862,20.1799365 3.56307606,19.9002377 L5.3709185,14.3556738 L0.64051832,10.9371833 C0.315487469,10.7023633 0.185384465,10.3043478 0.308687661,9.92310519 C0.431990857,9.54140927 0.770621325,9.2952562 1.17135671,9.2952562 L7.02327199,9.2952562 L8.83292772,3.72439967 C8.95668424,3.34406371 9.2953147,3.09791064 9.69559677,3.09791064 C10.0954255,3.09791064 10.434056,3.34406371 10.5578125,3.72439967 L12.3679215,9.2952562 L18.2189302,9.2952562 C18.6196656,9.2952562 18.958296,9.54140927 19.0815992,9.92310519 C19.2049024,10.3043478 19.0747994,10.7023633 18.7497686,10.9371833 L14.0198217,14.3538605 L15.8276642,19.9083975 C15.9192349,20.1880962 15.8734495,20.4832079 15.703001,20.7184813 C15.5307392,20.955568 15.254667,21.0979106 14.9645418,21.0979106 L14.9645418,21.0979106 Z" />
        </g>
      </g>
    </svg>
  </div>
);

ExperienceIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

ExperienceIcon.defaultProps = {
  className: '',
  height: '24px',
  width: '19px',
};

export default ExperienceIcon;
