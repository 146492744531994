import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUser } from 'shared/selectors/accountSelectors';

import ReferenceEditContainer from 'containers/references/ReferenceEditContainer';

class CarerReferenceEditContainer extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        referenceID: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
  };

  static defaultProps = {
    user: null,
  };


  render() {
    return (
      <ReferenceEditContainer
        carerID={this.props.user.id}
        referenceID={this.props.match.params.referenceID}
        summaryURL='/references/summary/'
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: getUser(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {},
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerReferenceEditContainer);
