import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ChargeTableRow from 'components/charges/ChargeTableRow';

class ChargeTable extends Component {
  render() {
    const { charges } = this.props;

    if (charges.length <= 0) {
      return <div className="no-results">No charges.</div>;
    }

    return (
      <table className="charges">
        <thead>
          <tr>
            <th>Created</th>
            <th>Status</th>
            <th>Amount</th>
            <th>Payee</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {charges.map(charge => {
            const payee = charge.carer_name || 'SuperCarers';

            return (
              <ChargeTableRow key={charge.id} charge={charge} payee={payee} />
            );
          })}
        </tbody>
      </table>
    );
  }
}

ChargeTable.propTypes = {
  charges: PropTypes.shape().isRequired,
  carers: PropTypes.shape().isRequired,
};

export default ChargeTable;
