// @flow
import React from 'react';
import styled from 'styled-components';

import JobOfferDetails from 'components/jobOffers/JobOfferDetails';
import {
  BorderedModuleSeparator,
  ModuleSeparator,
} from 'components/global/layout/Module';
import Definition from 'components/global/informationdisplay/Definition';

import type {
  BidType,
  JobOfferType,
  ScheduleType,
  TaxonomiesClassificationType,
} from 'shared/types';

const JobOfferSection = styled.section`
  padding: 20px;
`;

const StyledScheduleSection = styled.section`
  padding: 0 10px;
`;

const StyledJobDescription = styled.div`
  padding: 20px 0;
`;

type Props = {|
  bid: BidType,
  jobOffer: JobOfferType,
  needID?: string,
  preview: boolean,
  schedule: ScheduleType,
  taxonomiesByClassification: TaxonomiesClassificationType,
  toggleEditingState: boolean => void,
  updateJobOffer: (string, Object) => void,
|};

const JobSummary = ({
  bid,
  jobOffer,
  needID,
  schedule,
  taxonomiesByClassification,
  toggleEditingState,
}: Props) => {
  return (
    <JobOfferSection>
      <JobOfferDetails
        bid={bid}
        needID={needID}
        jobOffer={jobOffer}
        schedule={schedule}
        taxonomiesByClassification={taxonomiesByClassification}
        editable={toggleEditingState}
      />
      <BorderedModuleSeparator />
      <ModuleSeparator />
      <StyledScheduleSection>
          {jobOffer.description && (
          <StyledJobDescription>
              <Definition label="Description">
                <div
                  id="jobSummaryDesc"
                  dangerouslySetInnerHTML={{ __html: jobOffer.description }}
                />
              </Definition>
          </StyledJobDescription>
        )}

        {jobOffer.need.schedule_description && (
          <StyledJobDescription>
              <Definition label="Care Times">
                <div
                  id="jobSummaryScheduleDesc"
                  dangerouslySetInnerHTML={{ __html: jobOffer.need.schedule_description }}
                />
              </Definition>
          </StyledJobDescription>
        )}
      </StyledScheduleSection>
    </JobOfferSection>
  );
};

export default JobSummary;
