// @flow
import React from 'react';
import { Container, Row, Col } from 'components/grid';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';
import moment from 'moment';

import Notes from 'components/modules/Notes';
import PaymentStatus from 'components/payments/PaymentStatus';
import TimesheetControlsContainer from 'containers/timesheets/TimesheetControlsContainer';
import TimesheetTotalContainer from 'containers/timesheets/TimesheetTotalContainer';
import Module, {
  ModuleHeader,
  ModuleHero,
  ModuleMeta,
} from 'components/global/layout/Module';

import StatusIndicator from 'components/global/StatusIndicator';
import TimesheetLink from 'components/timesheets/TimesheetLink';

import type { TimesheetType, TotalsType, RefundType } from 'shared/types';

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

type Props = {
  state: string,
  timesheet: TimesheetType,
  isAdmin: boolean,
  empty: boolean,
  refunds: Array<RefundType>,
  totals: TotalsType,
  stripeEnabled: boolean,
};

function TimesheetDetailsConfirm({
  state,
  timesheet,
  isAdmin,
  empty,
  refunds,
  stripeEnabled,
  totals,
}: Props) {
  let emptyText = <p>This timesheet is empty.</p>;
  if (timesheet.state === 'new') {
    emptyText = (
      <p>
        You are about to submit an empty timesheet.
        <br />
        Please confirm this below or go back to edit this timesheet.
      </p>
    );
  }

  let title = `Care for ${timesheet.need_name || timesheet.customer_name}`;
  let submittedAt;
  if (isAdmin) {
    title = `${timesheet.carer_name}’s care for ${timesheet.need_name ||
      timesheet.customer_name}`;
    if (timesheet.submitted_at) {
      submittedAt = moment(timesheet.submitted_at);
    }
  }

  return (
    <Module>
      <Container>
        <Row>
          <Col xs={12} md={4}>
            <StyledHeader>{title}</StyledHeader>
          </Col>
          <Col xs={12} md={8}>
            <ModuleMeta>
              <StatusIndicator status={state} />
              {!!submittedAt && (
                <p>
                  Submitted: {submittedAt.format('MMMM Do YYYY, h:mm:ss a')}
                </p>
              )}
              {timesheet.charge_transferred_at &&
                timesheet.charge_fulfilment_type === 'stripe' && (
                  <PaymentStatus
                    transferredAt={timesheet.charge_transferred_at}
                  />
                )}
              {timesheet.charge_transferred_at &&
                timesheet.charge_fulfilment_type === 'manual' && (
                  <p>
                    Your payment has been processed and you will receive the
                    money shortly.
                  </p>
                )}
              {timesheet.state === 'approved' && (
                <TimesheetLink timesheetId={timesheet.id} />
              )}
              {isAdmin && (
                <div>
                  {timesheet.invoice_id ? (
                    <Link to={`/invoices/${timesheet.invoice_id}/`}>
                      Manage Invoice
                    </Link>
                  ) : (
                    'No invoice created yet'
                  )}
                  <br />
                  {timesheet.charge_id ? (
                    <Link to={`/admin/charges/${timesheet.charge_id}/`}>
                      Manage Charge
                    </Link>
                  ) : (
                    'No charge created yet'
                  )}
                </div>
              )}
            </ModuleMeta>
          </Col>
        </Row>
      </Container>
      {empty && <ModuleHero>{emptyText}</ModuleHero>}

      {!empty && (
        <TimesheetTotalContainer
          view="carer"
          timesheet={timesheet}
          refunds={refunds}
          totals={totals}
        />
      )}

      {isAdmin && timesheet.notes.length > 0 && (
        <div className="timesheet-notes">
          <h2>Notes</h2>
          <Notes notes={timesheet.notes} />
        </div>
      )}

      <TimesheetControlsContainer
        state={timesheet.state}
        id={timesheet.id}
        stripeEnabled={stripeEnabled}
      />
    </Module>
  );
}

export default TimesheetDetailsConfirm;
