import FormRow from 'components/forms/FormRow';
import InlineValidationError from 'components/forms/InlineValidationError';

import Button from 'components/buttons/Button';
import DurationSelector from 'components/inputs/DurationSelector';
import ShiftSelect from 'components/modules/ShiftSelect';
import ExceptionalDetails from 'components/timesheets/ExceptionalDetails';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Visible, Hidden } from 'components/grid';

class TimesheetShift extends Component {
  static propTypes = {
    date: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    previousShift: PropTypes.shape(),
    updateShiftType: PropTypes.func.isRequired,
    updateShiftDuration: PropTypes.func.isRequired,
    updateShiftExceptionalTotal: PropTypes.func.isRequired,
    updateShiftExceptionalReason: PropTypes.func.isRequired,
    removeShift: PropTypes.func.isRequired,
    shift: PropTypes.shape().isRequired,
    taxonomiesByClassification: PropTypes.object.isRequired,
  };

  static defaultProps = {
    previousShift: null,
  };

  UNSAFE_componentWillMount() {
    this.removeAfterMidnight(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    this.removeAfterMidnight(nextProps);
  }

  removeAfterMidnight = props => {
    if (
      props.previousShift &&
      !props.previousShift.end.isSame(props.date, 'day')
    ) {
      props.removeShift(props.date, props.index);
    }
  };

  removeShift = () => this.props.removeShift(this.props.date, this.props.index);

  render() {
    const date = this.props.date;
    const index = this.props.index;
    const shift = this.props.shift;
    let earliestStart;

    if (this.props.previousShift) {
      earliestStart = this.props.previousShift.end;
    }

    return (
      <div>
        {shift.error && (
          <FormRow>
            <Col>
              <InlineValidationError>{shift.error}</InlineValidationError>
            </Col>
          </FormRow>
        )}

        <FormRow style={{ justifyContent: 'space-between' }}>
          <Col xs={12} md={3}>
            <ShiftSelect
              name="shift_type"
              value={shift.shift_type}
              onChange={e => this.props.updateShiftType(e, date, index)}
              shiftTypes={this.props.taxonomiesByClassification.shift_type}
            />
          </Col>

          {!['half_livein', 'livein'].includes(shift.shift_type) && (
            <Col xs={12} md={6}>
              <DurationSelector
                shiftTimes={shift}
                earliestStart={earliestStart}
                updateDuration={shiftTimes =>
                  this.props.updateShiftDuration(shiftTimes, date, index)
                }
              />
            </Col>
          )}

          <Hidden sm xs>
            <Col xs={12} md={3}>
              <Button className="danger" onClick={this.removeShift}>
                Remove Shift
              </Button>
            </Col>
          </Hidden>
        </FormRow>

        {shift.shift_type === 'exceptional' && (
          <ExceptionalDetails
            key={index}
            total={shift.exceptional_total || 0}
            reason={shift.exceptional_reason || ''}
            updateTotal={total =>
              this.props.updateShiftExceptionalTotal(total, date, index)
            }
            updateReason={reason =>
              this.props.updateShiftExceptionalReason(reason, date, index)
            }
          />
        )}
        <Visible sm xs>
          <FormRow>
            <Col xs={12} md={3}>
              <Button
                className="danger"
                onClick={() => this.props.removeShift(date, index)}
              >
                Remove Shift
              </Button>
            </Col>
          </FormRow>
        </Visible>
      </div>
    );
  }
}

export default TimesheetShift;
