import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import routes from 'routes/routes';

import { Chevron } from 'components/global/icons';

const NavItem = styled.span`
  span,
  a {
    display: inline-block;
    padding-right: 10px;
    text-decoration: none;
    white-space: nowrap;
    color: ${props => props.theme.colors.darkBlue};
  }
  a:hover {
    color: ${props => props.theme.colors.hiAccentBlue};
  }
  span {
    color: ${props => props.theme.colors.midGrey};
  }
  @media (max-width: 767px) {
    a {
      display: none;
    }
    span {
      color: white;
      pointer-events: none;
      position: fixed;
      top: 14px;
      width: 100%;
      text-align: center;
      z-index: ${props => props.theme.zIndexes.title};

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media (max-width: 410px) {
        left: 140px;
        > div {
          width: calc(100vw - 240px);
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.screen.small}px) {
    display: none;
  }
`;

const StyledChevron = styled(Chevron)`
  position: relative;
  top: 1px;
`;

const Breadcrumb = ({ element, name, current }) => {
  if (!name) {
    return null;
  }
  document.title += ` | ${name}`;

  return (
    <NavItem>
      {current ? (
        <span>
          <div>{name}</div>
        </span>
      ) : (
        <Link to={element}>{name}</Link>
      )}
      {!current && <StyledChevron />}
    </NavItem>
  );
};

Breadcrumb.propTypes = {
  element: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  current: PropTypes.bool.isRequired,
};

const BreadcrumbSwitch = ({ element, current }) => (
  <Switch location={{ pathname: element }}>
    {routes.map(route => (
      <Route key={route.path} exact path={route.path}>
        <Breadcrumb element={element} name={route.name} current={current} />
      </Route>
    ))}
  </Switch>
);

BreadcrumbSwitch.propTypes = {
  element: PropTypes.string.isRequired,
  current: PropTypes.bool.isRequired,
};

const StyledNav = styled.nav`
  background-color: ${props => props.theme.colors.lightGrey};
  position: relative;

  @media (min-width: 768px) {
    display: block;
  }
`;

const Breadcrumbs = ({ pathname }) => {
  const elements = pathname
    .split('/')
    .filter(Boolean)
    .reduce((accumulator, element, index) => {
      accumulator.push(`${accumulator[index - 1] || '/'}${element}/`);
      return accumulator;
    }, []);

  document.title = 'Home Instead';

  return (
    <StyledNav>
      {elements.map((element, index) => (
        <BreadcrumbSwitch
          key={element}
          element={element}
          current={index === elements.length - 1}
        />
      ))}
    </StyledNav>
  );
};

Breadcrumbs.propTypes = {
  pathname: PropTypes.string.isRequired,
};

class BreadcrumbsContainer extends Component {
  render() {
    return (
      <Route
        path="/:path"
        render={props => <Breadcrumbs pathname={props.location.pathname} />}
      />
    );
  }
}

export default BreadcrumbsContainer;
