// @flow
import ApiConstants from 'shared/constants/ApiConstants';
import JobOfferConstants from 'shared/constants/JobOfferConstants';

import type { APIAction, JobOfferType } from 'shared/types';

export function getJobOfferByID(jobOfferID: string): APIAction {
  return {
    endpoint: `/job-offer/${jobOfferID}/`,
    method: 'get',
    onError: { type: JobOfferConstants.JOB_OFFER_GET_BY_ID_FAILURE },
    onStart: { type: JobOfferConstants.JOB_OFFER_GET_BY_ID },
    onSuccess: {
      type: JobOfferConstants.JOB_OFFER_GET_BY_ID_SUCCESS,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function getJobOfferByIDForMultiple(jobOfferID: string): APIAction {
  return {
    endpoint: `/job-offer/${jobOfferID}/`,
    method: 'get',
    onError: {
      type: JobOfferConstants.JOB_OFFER_GET_BY_ID_FOR_MULTIPLE_FAILURE,
    },
    onStart: { type: JobOfferConstants.JOB_OFFER_GET_BY_ID },
    onSuccess: {
      type: JobOfferConstants.JOB_OFFER_GET_BY_ID_FOR_MULTIPLE_SUCCESS,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function getJobOfferByReference(reference: string): APIAction {
  return {
    endpoint: `/job-offer/by/reference/${reference}/`,
    method: 'get',
    onError: { type: JobOfferConstants.JOB_OFFER_GET_BY_REFERENCE_FAILURE },
    onStart: { type: JobOfferConstants.JOB_OFFER_GET_BY_REFERENCE },
    onSuccess: {
      type: JobOfferConstants.JOB_OFFER_GET_BY_REFERENCE_SUCCESS,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function getBidSummary(jobOfferID: string): APIAction {
  return {
    endpoint: `/bid/summary/`,
    method: 'get',
    onError: { type: JobOfferConstants.JOB_OFFER_GET_BID_SUMMARY_FAILURE },
    onStart: { type: JobOfferConstants.JOB_OFFER_GET_BID_SUMMARY },
    onSuccess: {
      type: JobOfferConstants.JOB_OFFER_GET_BID_SUMMARY_SUCCESS,
      jobOfferID,
    },
    type: ApiConstants.API_REQUEST,
    payload: { job_offer_id: jobOfferID },
  };
}

export function getMatchTotal(jobOfferID: string): APIAction {
  return {
    endpoint: `/job-offer/${jobOfferID}/match/total/`,
    method: 'get',
    onError: { type: JobOfferConstants.JOB_OFFER_GET_MATCH_TOTAL_FAILURE },
    onStart: { type: JobOfferConstants.JOB_OFFER_GET_MATCH_TOTAL },
    onSuccess: {
      type: JobOfferConstants.JOB_OFFER_GET_MATCH_TOTAL_SUCCESS,
      jobOfferID,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function createJobOffer(jobOffer: JobOfferType): APIAction {
  return {
    endpoint: '/job-offer/',
    method: 'post',
    onError: { type: JobOfferConstants.JOB_OFFER_CREATE_FAILURE },
    onStart: { type: JobOfferConstants.JOB_OFFER_CREATE },
    onSuccess: {
      type: JobOfferConstants.JOB_OFFER_CREATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Job offer created',
      },
    },
    payload: jobOffer,
    type: ApiConstants.API_REQUEST,
  };
}

export function updateJobOffer(
  jobOfferID: string,
  jobOffer: JobOfferType,
): APIAction {
  return {
    endpoint: `/job-offer/${jobOfferID}/`,
    method: 'put',
    onError: {
      type: JobOfferConstants.JOB_OFFER_UPDATE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Job offer update failure',
      },
    },
    onStart: { type: JobOfferConstants.JOB_OFFER_UPDATE },
    onSuccess: {
      type: JobOfferConstants.JOB_OFFER_UPDATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Job offer updated',
      },
    },
    payload: jobOffer,
    type: ApiConstants.API_REQUEST,
  };
}

export function notifyCustomerOfBids(jobOfferID: string): APIAction {
  return {
    endpoint: `/notification/job/${jobOfferID}/customer/propose/bids/`,
    method: 'post',
    onError: {
      type: JobOfferConstants.JOB_OFFER_NOTIFY_OF_BIDS_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to notify customer',
      },
    },
    onStart: { type: JobOfferConstants.JOB_OFFER_NOTIFY_OF_BIDS },
    onSuccess: {
      type: JobOfferConstants.JOB_OFFER_NOTIFY_OF_BIDS_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Customer notified',
      },
    },
    type: ApiConstants.API_REQUEST,
  };
}

export type SearchJobOffersParams = {
  user_id?: string,
  state?: string,
  need_id?: string,
  reference?: string,
  outcode?: string,
  location?: string,
  state?: string,
  care_type?: string,
  customer_name?: string,
  caree_name?: string,
  carer_gender?: Array<string>,
  pets?: Array<string>,
  needs_license?: boolean,
  needs_car?: boolean,
  page?: number,
  per_page?: number,
};
export function searchJobOffers(
  params: SearchJobOffersParams,
  page: number,
): APIAction {
  const query = { ...params, page: page || 1, per_page: 10 };
  return {
    endpoint: '/job-offer/find/',
    method: 'get',
    onError: { type: JobOfferConstants.JOB_OFFER_SEARCH_FAILURE },
    onStart: { type: JobOfferConstants.JOB_OFFER_SEARCH },
    onSuccess: {
      type: JobOfferConstants.JOB_OFFER_SEARCH_SUCCESS,
    },
    payload: query,
    type: ApiConstants.API_REQUEST,
  };
}

export function getJobOfferStatus(): APIAction {
  return {
    endpoint: '/job-offer/state/',
    method: 'get',
    onError: { type: JobOfferConstants.JOB_OFFER_GET_STATUS_FAILURE },
    onStart: { type: JobOfferConstants.JOB_OFFER_GET_STATUS },
    onSuccess: { type: JobOfferConstants.JOB_OFFER_GET_STATUS_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function getJobOfferByReferenceForCarer(reference: string) {
  return {
    type: JobOfferConstants.JOB_OFFER_GET_BY_REFERENCE_FOR_CARER,
    reference,
  };
}
