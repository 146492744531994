import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { arrayOf, func, object as obj } from 'prop-types';
import moment from 'moment';
import 'moment/locale/en-gb';

import CustomerScheduleShifts from 'components/customers/CustomerScheduleShifts';
import {
  CustomerEmptySchedule,
  CustomerVerifyEmailPlaceholder,
} from 'components/customers/CustomerEmptyStateLayouts';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import SchedulePlaceholderImage from 'images/customers/DogCatMouseChicken.png';

import { getUser } from 'shared/selectors/accountSelectors';
import { getCarers } from 'shared/selectors/carerSelectors';
import { getCareTeamsByCustomerId } from '_actions/careTeamActions';
import { getNeedsforUserByID } from 'shared/selectors/needSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import {
  fetchShiftsForCustomerUserID,
  fetchMoreShiftsForCustomerUserID,
} from '_actions/shiftActions';
import { selectShiftsByCustomerID } from 'shared/selectors/shiftsSelectors';
import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';

class CustomerScheduleContainer extends Component {
  static propTypes = {
    fetchShiftsForCustomerUserID: func.isRequired,
    getCareTeamsByCustomerId: func.isRequired,
    needs: arrayOf(obj).isRequired,
    user: obj.isRequired,
    shifts: arrayOf(obj),
    taxonomiesByClassification: obj,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['shift_type'];
  }

  static defaultProps = {
    taxonomiesByClassification: null,
  };

  constructor(props) {
    super(props);

    const now = moment();

    this.state = {
      initialTime: now,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.shifts || !props.carers) {
      return { shifts: [] };
    }

    const shiftsForEvents = [];
    props.shifts.forEach(shift => {
      const carer = props.carers[shift.user_id];

      if (shift.active) {
        shiftsForEvents.push({
          ...shift,
          carer_name: carer ? carer.display_name : null,
          carer,
          customerID: props.user.id,
          shiftTypes: props.taxonomiesByClassification.shift_type,
        });
      }
    });

    return {
      shifts: shiftsForEvents,
    };
  }

  componentDidMount() {
    const now = this.state.initialTime.clone();
    this.props.fetchShiftsForCustomerUserID({
      userID: this.props.user.id,
      starts_after: now
        .clone()
        .startOf('month')
        .startOf('isoWeek')
        .format(),
      starts_before: now
        .endOf('month')
        .clone()
        .endOf('isoWeek')
        .format(),
    });
    this.props.getCareTeamsByCustomerId(this.props.user.id);
  }

  onNavigate = date => {
    const currentDate = moment(date);
    this.props.fetchMoreShiftsForCustomerUserID({
      userID: this.props.user.id,
      starts_after: currentDate
        .clone()
        .startOf('month')
        .startOf('isoWeek')
        .format(),
      starts_before: currentDate
        .clone()
        .endOf('month')
        .endOf('isoWeek')
        .format(),
    });
  };

  render() {
    const {
      needs,
      taxonomiesByClassification,
      shiftsLoading,
      user,
    } = this.props;
    const { shifts, initialTime } = this.state;
    if (shiftsLoading || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    if (!shifts || !shifts.length) {
      return <CustomerEmptySchedule />;
    }

    if (!user.confirmed_at) {
      return (
        <CustomerVerifyEmailPlaceholder
          type="schedule"
          image={SchedulePlaceholderImage}
        />
      );
    }

    return (
      <CustomerScheduleShifts
        shifts={shifts}
        onNavigate={this.onNavigate}
        initialTime={initialTime.toDate()}
        taxonomiesByClassification={taxonomiesByClassification}
        need={needs[0]}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const user = getUser(state);
  let shifts;
  if (user) {
    shifts = selectShiftsByCustomerID(state, user.id);
  }

  return {
    carers: getCarers(state),
    needs: getNeedsforUserByID(state, user.id),
    user,
    shifts,
    shiftsLoading: loadingSelectorFactory('shifts', 'shifts')(state),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      CustomerScheduleContainer.TAXONOMY_CLASSIFICATIONS,
    ),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchShiftsForCustomerUserID,
      fetchMoreShiftsForCustomerUserID,
      getCareTeamsByCustomerId,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerScheduleContainer);
