// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import Link from 'react-router-redux-dom-link';

import FlexTable from 'components/global/layout/FlexTable';
import FlexTableRow from 'components/global/layout/FlexTableRow';
import FlexTableItem from 'components/global/layout/FlexTableItem';
import StatusIndicator from 'components/global/StatusIndicator';
import { ModuleHero } from 'components/global/layout/Module';

import { renderTaxon } from 'components/helpers/taxonomyHelpers';

import type { TaxonomiesClassificationType } from 'shared/types';

const CustomFlexTableRow = styled(FlexTableRow)`
  background: ${({ priority, theme }) => priority && theme.colors.darkerBeige};
`;

type Props = {|
  franchises: Object,
  taxonomiesByClassification: TaxonomiesClassificationType,
|};

class AdminFranchiseSearchResults extends Component<Props> {
  render() {
    const { franchises, taxonomiesByClassification } = this.props;

    if (!franchises || !franchises.length) {
      return <ModuleHero>No search results</ModuleHero>;
    }

    return (
      <FlexTable>
        <FlexTableRow header>
          <FlexTableItem xs={0} sm={12}>
            Name
          </FlexTableItem>
          <FlexTableItem xs={0} sm={12}>
            Location
          </FlexTableItem>
          <FlexTableItem xs={0} sm={12}>
            AM
          </FlexTableItem>
          <FlexTableItem xs={0} sm={6}>State</FlexTableItem>
          <FlexTableItem xs={0} sm={6}/>
        </FlexTableRow>
        {franchises.map(franchise => (
          <CustomFlexTableRow key={franchise.id} priority={false}>
            <FlexTableItem xs={0} sm={12}>
              {franchise.name}
            </FlexTableItem>
            <FlexTableItem xs={0} sm={12}>
              {`${franchise.county} (${
                renderTaxon(
                  taxonomiesByClassification.franchise_country,
                  franchise.country)
              })`}
            </FlexTableItem>
            <FlexTableItem xs={0} sm={12}>
              {franchise.account_manager && renderTaxon(
                taxonomiesByClassification.account_manager,
                franchise.account_manager,
              )}
            </FlexTableItem>
            <FlexTableItem xs={0} sm={6}>
              <StatusIndicator status={franchise.state} />
            </FlexTableItem>
            <FlexTableItem xs={0} sm={6}>
              <Link to={`/admin/franchises/${franchise.id}/`}>
                View
              </Link>
            </FlexTableItem>
          </CustomFlexTableRow>
        ))}
      </FlexTable>
    );
  }
}

export default AdminFranchiseSearchResults;
