/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Col, Row } from 'components/grid';

import FormRow from 'components/forms/FormRow';
import StyledTextarea from 'components/forms/StyledTextarea';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import Button from 'components/buttons/Button';
import StripeHandler from 'components/modules/stripe/StripeHandler';
import ExpensesForm from 'components/timesheets/ExpensesForm';
import TimesheetDay from 'components/timesheets/TimesheetDay';

class TimesheetDetails extends Component {
  static propTypes = {
    actualShifts: PropTypes.shape().isRequired,
    updateShiftType: PropTypes.func.isRequired,
    updateShiftDuration: PropTypes.func.isRequired,
    updateShiftExceptionalTotal: PropTypes.func.isRequired,
    updateShiftExceptionalReason: PropTypes.func.isRequired,
    addShift: PropTypes.func.isRequired,
    removeShift: PropTypes.func.isRequired,
    timesheet: PropTypes.shape().isRequired,
    carer: PropTypes.shape(),
    isAdmin: PropTypes.bool.isRequired,
    deleteTimesheet: PropTypes.func.isRequired,
    saveTimes: PropTypes.func.isRequired,
    consumer: PropTypes.shape(),
    careNeed: PropTypes.shape(),
    saveEmpty: PropTypes.func.isRequired,
    expenses: PropTypes.arrayOf(PropTypes.shape()),
    mileage: PropTypes.shape(),
    updateMileage: PropTypes.func.isRequired,
    updateExpense: PropTypes.func.isRequired,
    deleteExpense: PropTypes.func.isRequired,
    newExpense: PropTypes.shape(),
    addExpense: PropTypes.func.isRequired,
    updateNewExpense: PropTypes.func.isRequired,
    updateNotes: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.object.isRequired,
  };

  static defaultProps = {
    expenses: [],
    mileage: {},
    newExpense: null,
    consumer: null,
    careNeed: null,
    carer: null,
  };

  render() {
    let consumer;
    let save;
    // let confirm = true;

    if (this.props.isAdmin) {
      // Admins can pretty much always save details
      if (this.props.timesheet.state !== 'approved') {
        save = true;
      }

      if (this.props.consumer) {
        consumer = (
          <div className="old-col">
            <strong>Customer</strong>
            <br />
            {this.props.consumer.name}
            <br />
          </div>
        );
      }
    } else if (this.props.timesheet.state === 'new') {
      save = true;
    }

    if (this.props.carer) {
      // confirm = this.props.carer.has_stripe_account && this.props.carer.stripe_account_enabled;
    }

    return (
      <section>
        <h1>Timesheet Submission</h1>
        {this.props.careNeed && (
          <Module>
            <ModuleHeader>
              {`${moment(this.props.timesheet.start_date).format(
                'dddd, MMMM Do YYYY',
              )} to ${moment(this.props.timesheet.start_date)
                .add(6, 'days')
                .format('dddd, MMMM Do YYYY')}`}
            </ModuleHeader>

            <Container>
              <FormRow>
                <Col xs={12} md={6}>
                  <strong>Client</strong>
                  <br />
                  {this.props.careNeed.firstname} {this.props.careNeed.lastname}
                  <br />
                  {this.props.careNeed.address1}
                  <br />
                  {this.props.careNeed.address2}
                  <br />
                  {this.props.careNeed.address3}
                  <br />
                  {this.props.careNeed.postcode}
                </Col>

                <Col xs={12} md={6}>
                  {consumer}
                </Col>
              </FormRow>
              <Row>
                <Col>
                  If you did not work for this client this week please{' '}
                  <div role="button" onClick={() => this.props.saveEmpty()}>
                    submit an empty Timesheet
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  Please email{' '}
                  <a href="mailto:carers@supercarers.com">
                    carers@supercarers.com
                  </a>{' '}
                  if you have any problems with filling in your timesheet.
                </Col>
              </Row>
            </Container>
          </Module>
        )}
        {[...Array(8).keys()].map(offset => {
          const date = moment(this.props.timesheet.start_date)
            .add(offset, 'days')
            .format('YYYY-MM-DD');
          return (
            (offset < 7 || this.props.actualShifts[date]) &&
            <TimesheetDay
              key={offset}
              date={date}
              shifts={this.props.actualShifts[date] || []}
              updateShiftType={this.props.updateShiftType}
              updateShiftDuration={this.props.updateShiftDuration}
              updateShiftExceptionalTotal={
                this.props.updateShiftExceptionalTotal
              }
              updateShiftExceptionalReason={
                this.props.updateShiftExceptionalReason
              }
              addShift={() => this.props.addShift(date)}
              removeShift={this.props.removeShift}
              taxonomiesByClassification={this.props.taxonomiesByClassification}
            />
          );
        })}
        <ExpensesForm
          expenses={this.props.expenses}
          mileage={this.props.mileage}
          newExpense={this.props.newExpense}
          updateMileage={this.props.updateMileage}
          addExpense={this.props.addExpense}
          updateNewExpense={this.props.updateNewExpense}
          updateExpense={this.props.updateExpense}
          deleteExpense={this.props.deleteExpense}
        />
        <Module>
          <ModuleHeader>Other Details</ModuleHeader>
          <Container>
            <Row>
              <Col>
                <StyledTextarea
                  onChange={this.props.updateNotes}
                  defaultValue={this.props.timesheet.expense_details}
                  placeholder="Tell us about any other expenses this week."
                />
              </Col>
            </Row>
          </Container>
        </Module>
        <StripeHandler user={this.props.carer} />
        <Module>
          <Container>
            <FormRow>
              {confirm && (
                <Col xs={12} md={4}>
                  <Button
                    className="btn"
                    onClick={() => this.props.saveTimes('submitted')}
                  >
                    Confirm Timesheet
                  </Button>
                </Col>
              )}
              {save && (
                <Col xs={12} md={4}>
                  <Button
                    className="alt"
                    onClick={() => this.props.saveTimes()}
                  >
                    Save changes for later
                  </Button>
                </Col>
              )}
              {this.props.isAdmin && (
                <Col xs={12} md={2}>
                  <Button
                    className="danger"
                    onClick={this.props.deleteTimesheet}
                  >
                    Delete
                  </Button>
                </Col>
              )}
            </FormRow>
            <Row>
              <Col>
                If you did not work for this client this week please{' '}
                <div role="button" onClick={() => this.props.saveEmpty()}>
                  submit an empty Timesheet
                </div>
                .
              </Col>
            </Row>
          </Container>
        </Module>
      </section>
    );
  }
}

export default TimesheetDetails;
