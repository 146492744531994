import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import { LogoutIcon, SettingsIcon } from 'components/global/icons';
import { DownTriangleIcon } from 'components/global/icons/small';

const InlineIconStyles = `
  display: inline-block;
  left: 15px;
  margin-right: 10px;
  position: absolute;
  top: 20px;
`;

const InlineLogoutIcon = styled(LogoutIcon)`
  ${InlineIconStyles};
`;
const InlineSettingsIcon = styled(SettingsIcon)`
  ${InlineIconStyles};
`;

const StyledDiv = styled.div`
  position: relative;
  text-align: right;
  >div[role="button"] {
    color: ${props => props.theme.colors.darkBlue};
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    >strong {
      color: ${props => props.theme.colors.orange};
    }
  }
}`;

const Panel = styled.div`
  background: white;
  border-radius: 7px;
  box-shadow: 0 1px 4px 0 ${props => props.theme.colors.shadowGrey};
  display: none;
  min-width: 250px;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: left;
  top: 35px;
  z-index: ${props => props.theme.zIndexes.menu};
  @media (min-width: 768px) {
    display: block;
  }
  > ol {
    > li {
      border-bottom: 1px solid ${props => props.theme.colors.uiGrey};
      position: relative;
      &:last-child {
        border: none;
      }
      > a {
        color: ${props => props.theme.colors.darkBlue};
        display: block;
        text-decoration: none;
        padding: 20px 20px 20px 50px;

        &:hover {
          background-color: ${props => props.theme.colors.darkBlue};
          color: white;
        }
      }
    }
  }
`;

const StyledDownTriangleIcon = styled(DownTriangleIcon)`
  display: inline-block;
  margin-left: 10px;
`;

class DropdownHelperMenu extends Component {
  static propTypes = {
    className: PropTypes.string,
    impersonating: PropTypes.bool.isRequired,
    user: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    className: null,
  };

  state = {
    isOpen: false,
  };

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { className, user, impersonating } = this.props;
    return (
      <StyledDiv className={className}>
        <div role="button" onClick={this.toggleOpen}>
          {impersonating && <strong>Impersonating</strong>}{' '}
          {user.name && user.name.toUpperCase()}
          <StyledDownTriangleIcon />
        </div>
        {this.state.isOpen && (
          <Panel>
            <ol>
              <li>
                <Link onClick={this.toggleOpen} to="/account/">
                  <InlineSettingsIcon />
                  Settings
                </Link>
              </li>
              <li>
                <Link onClick={this.toggleOpen} to="/logout/">
                  <InlineLogoutIcon />
                  Logout
                </Link>
              </li>
            </ol>
          </Panel>
        )}
      </StyledDiv>
    );
  }
}

export default DropdownHelperMenu;
