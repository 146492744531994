import PropTypes from 'prop-types';
import React, { Component } from 'react';

class HolidayRow extends Component {
  render() {
    let date;
    const index = this.props.index;
    const name = this.props.name || '';
    let addHoliday;
    let updateHoliday;
    let removeHoliday;

    if (this.props.addHoliday) {
      addHoliday = (
        <div className="old-col col-20">
          <button
            onClick={e => this.props.addHoliday(e)}
            className="btn btn-small"
          >
            Add
          </button>
        </div>
      );
      date = (
        <input
          type="date"
          value={this.props.date}
          onChange={this.props.onSetDate}
        />
      );
    }

    if (this.props.updateHoliday) {
      updateHoliday = (
        <div className="old-col col-20">
          <button
            onClick={() => this.props.updateHoliday(this.props.date, name)}
            className="btn btn-small HolidayRowUpdateHoliday"
          >
            Update
          </button>
        </div>
      );
    }

    if (this.props.deleteHoliday) {
      removeHoliday = (
        <div className="old-col col-20">
          <button
            onClick={() => this.props.deleteHoliday(this.props.date)}
            className="btn btn-alt btn-small HolidayRowDeleteHoliday"
          >
            Remove
          </button>
        </div>
      );
      date = <input type="date" value={this.props.date} disabled />;
    }

    return (
      <div className="old-row mid">
        <div className="old-col col-33">{date}</div>
        <div className="old-col">
          <input
            type="text"
            onChange={e =>
              this.props.onChangeName(index, e.currentTarget.value)
            }
            value={name}
            placeholder="Holiday Name"
          />
        </div>
        {addHoliday}
        {updateHoliday}
        {removeHoliday}
      </div>
    );
  }
}

HolidayRow.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  addHoliday: PropTypes.func,
  updateHoliday: PropTypes.func,
  deleteHoliday: PropTypes.func,
  onSetDate: PropTypes.func,
  onChangeName: PropTypes.func.isRequired,
};

HolidayRow.defaultProps = {
  index: -1,
  updateHoliday: null,
  addHoliday: null,
  deleteHoliday: null,
  onSetDate: () => {},
};

export default HolidayRow;
