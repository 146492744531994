import keyMirror from 'keymirror';

export default keyMirror({
  ADMIN_CREATE_NEED: null,
  ADMIN_CREATE_NEED_SUCCESS: null,
  ADMIN_CREATE_NEED_FAILURE: null,

  NEED_AND_CUSTOMER_UPDATE: null,
  NEED_AND_CUSTOMER_UPDATE_SUCCESS: null,
  NEED_AND_CUSTOMER_UPDATE_FAILURE: null,

  CARE_NEED_CREATE: null,
  CARE_NEED_CREATE_SUCCESS: null,
  CARE_NEED_CREATE_FAILURE: null,

  CARE_NEED_ARCHIVE: null,
  CARE_NEED_ARCHIVE_SUCCESS: null,
  CARE_NEED_ARCHIVE_FAILURE: null,

  CARE_NEED_UPDATE: null,
  CARE_NEED_UPDATE_SUCCESS: null,
  CARE_NEED_UPDATE_FAILURE: null,

  CARE_NEED_GET_BY_ID: null,
  CARE_NEED_GET_BY_ID_SUCCESS: null,
  CARE_NEED_GET_BY_ID_FAILURE: null,

  CARE_NEED_GET_BY_ID_WITH_USER: null,
  CARE_NEED_GET_BY_ID_WITH_USER_SUCCESS: null,
  CARE_NEED_GET_BY_ID_WITH_USER_FAILURE: null,

  CARE_NEEDS_GET_BY_USER: null,
  CARE_NEEDS_GET_BY_USER_SUCCESS: null,
  CARE_NEEDS_GET_BY_USER_FAILURE: null,

  CARE_NEEDS_GET_BY_CARER: null,
  CARE_NEEDS_GET_BY_CARER_SUCCESS: null,
  CARE_NEEDS_GET_BY_CARER_FAILURE: null,

  CARE_NEED_GET_ALL: null,
  CARE_NEED_GET_ALL_SUCCESS: null,
  CARE_NEED_GET_ALL_FAILURE: null,

  CARE_NEED_SEARCH: null,
  CARE_NEED_SEARCH_SUCCESS: null,
  CARE_NEED_SEARCH_FAILURE: null,

  CARE_NEED_TIMESHEET_GENERATE: null,
  CARE_NEED_TIMESHEET_GENERATE_FAILURE: null,
  CARE_NEED_TIMESHEET_GENERATE_SUCCESS: null,

  CARE_NEED_GET_STATUS: null,
  CARE_NEED_GET_STATUS_SUCCESS: null,
  CARE_NEED_GET_STATUS_FAILURE: null,

  CARE_NEED_GET_ACCOUNT_OWNER: null,
  CARE_NEED_GET_ACCOUNT_OWNER_SUCCESS: null,
  CARE_NEED_GET_ACCOUNT_OWNER_FAILURE: null,

  CARE_NEED_CONVERT: null,
  CARE_NEED_CONVERT_SUCCESS: null,
  CARE_NEED_CONVERT_FAILURE: null,

  CARE_NEED_RECALCULATE_TOTAL_FAILURE: null,
  CARE_NEED_RECALCULATE_TOTAL: null,
  CARE_NEED_RECALCULATE_TOTAL_SUCCESS: null,
});

export const NEW_CUSTOMER_STATES = ['new', 'need_submitted', 'holding'];
export const CUSTOMER_IN_PROGRESS_STATES = [
  'new',
  'need_submitted',
  'holding',
  'job_created',
  'job_proposed',
];
