import ApiConstants from 'shared/constants/ApiConstants';
import ShiftConstants from 'shared/constants/ShiftConstants';

export function createShift(data) {
  return {
    endpoint: '/shift/',
    method: 'post',
    onError: { type: ShiftConstants.SHIFT_CREATE_FAILURE },
    onStart: { type: ShiftConstants.SHIFT_CREATE },
    onSuccess: { type: ShiftConstants.SHIFT_CREATE_SUCCESS },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function modifyShift(shiftID, data) {
  return {
    endpoint: `/shift/${shiftID}/`,
    method: 'put',
    onError: { type: ShiftConstants.SHIFT_MODIFY_FAILURE },
    onStart: { type: ShiftConstants.SHIFT_MODIFY },
    onSuccess: { type: ShiftConstants.SHIFT_MODIFY_SUCCESS },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function deleteShift(shiftID) {
  return {
    endpoint: `/shift/${shiftID}/`,
    method: 'delete',
    onError: { type: ShiftConstants.SHIFT_DELETE_FAILURE },
    onStart: { type: ShiftConstants.SHIFT_DELETE },
    onSuccess: {
      type: ShiftConstants.SHIFT_DELETE_SUCCESS,
      shiftID,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function deleteMultipleShifts(shiftIDs) {
  return {
    payload: { shiftIDs },
    type: ShiftConstants.SHIFT_DELETE_MULTIPLE,
  };
}

export function getShiftById(shiftID) {
  return {
    endpoint: `/shift/${shiftID}/`,
    method: 'get',
    onError: { type: ShiftConstants.SHIFT_GET_BY_ID_FAILURE },
    onStart: { type: ShiftConstants.SHIFT_GET_BY_ID },
    onSuccess: { type: ShiftConstants.SHIFT_GET_BY_ID_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function getShiftsByParams(params, tag) {
  return {
    endpoint: `/shift/find/`,
    method: 'get',
    onError: { type: ShiftConstants.SHIFT_GET_BY_PARAMS_FAILURE, tag },
    onStart: { type: ShiftConstants.SHIFT_GET_BY_PARAMS, tag },
    onSuccess: { type: ShiftConstants.SHIFT_GET_BY_PARAMS_SUCCESS, tag },
    payload: params,
    type: ApiConstants.API_REQUEST,
  };
}

export function fetchShiftsForNeedID(params) {
  return {
    payload: params,
    type: ShiftConstants.SHIFT_GET_ALL_FOR_NEED_ID,
  };
}

export function fetchShiftsForCarerUserID(params) {
  return {
    payload: params,
    type: ShiftConstants.SHIFT_GET_ALL_FOR_CARER_USER_ID,
  };
}

export function fetchShiftsForCustomerUserID(params) {
  return {
    payload: params,
    type: ShiftConstants.SHIFT_GET_ALL_FOR_CUSTOMER_USER_ID,
  };
}

export function fetchMoreShiftsForCustomerUserID(params) {
  return {
    payload: params,
    type: ShiftConstants.SHIFT_GET_MORE_FOR_CUSTOMER_USER_ID,
  };
}
