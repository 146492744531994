import React from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import {
  defaultGutterWidth,
  getScreenClass,
  screenClassToInt,
  getWidth,
} from 'components/grid/screen-utils';

export default class Container extends React.Component {
  static propTyes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    outerGutter: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number,
      }),
    ]),
    gutterWidth: PropTypes.number,
    rowGutter: PropTypes.number,
    colGutter: PropTypes.number,
    style: PropTypes.shape(),
    className: PropTypes.string,
  };

  static defaultProps = {
    gutterWidth: defaultGutterWidth,
    rowGutter: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      screenClass: screenClassToInt[getScreenClass()],
    };
    this.setScreenClass = this.setScreenClass.bind(this);
  }

  componentDidMount = () => {
    this.eventListener = throttle(this.setScreenClass, 100);
    window.addEventListener('resize', this.eventListener);
    this.setScreenClass();
  };

  componentWillUnmount = () => {
    this.eventListener.cancel();
    window.removeEventListener('resize', this.eventListener);
  };

  setScreenClass() {
    const screenClass = screenClassToInt[getScreenClass()];
    if (screenClass && screenClass !== this.state.screenClass) {
      this.setState({ screenClass });
    }
  }

  render() {
    if (!this.props.children) {
      return <div />;
    }

    const {
      outerGutter,
      gutterWidth,
      rowGutter,
      colGutter,
      children,
      style,
      className,
      name,
    } = this.props;

    const { screenClass } = this.state;

    let theOuterGutter;
    if (!isNaN(outerGutter)) {
      theOuterGutter = outerGutter;
    } else if (typeof outerGutter === 'object') {
      const screenArr = [
        outerGutter.xl,
        outerGutter.lg,
        outerGutter.md,
        outerGutter.sm,
        outerGutter.xs,
      ];
      theOuterGutter = getWidth(screenArr, screenClass, gutterWidth);
    } else {
      theOuterGutter = gutterWidth;
    }

    let defaultRowGutter = gutterWidth;
    defaultRowGutter = !isNaN(rowGutter) ? rowGutter : defaultRowGutter;
    let defaultColGutter = gutterWidth;
    defaultColGutter = !isNaN(colGutter) ? colGutter : defaultColGutter;

    let containerRowPadding = defaultRowGutter / 2;
    let containerColPadding = defaultColGutter / 2;
    if (!theOuterGutter) {
      containerRowPadding = 0;
      containerColPadding = 0;
    } else {
      containerRowPadding = rowGutter === 0 ? 0 : theOuterGutter / 2;
      containerColPadding = colGutter === 0 ? 0 : theOuterGutter / 2;
    }
    const containerStyle = {
      padding: `${containerRowPadding}px ${containerColPadding}px`,
      ...style,
    };

    const rowCount = React.Children.count(children);
    const modifiedChildren = React.Children.map(children, (child, index) => {
      if (!child) {
        return child;
      }
      const padding = `${
        !theOuterGutter && index === 0 ? 0 : defaultRowGutter / 2
      }px 0 ${
        !theOuterGutter && index === rowCount - 1 ? 0 : defaultRowGutter / 2
      }px 0`;
      const rowStyle = Object.assign({ padding }, child.props.style);
      return React.cloneElement(child, {
        style: rowStyle,
        defaultColGutter,
        outerGutter: theOuterGutter,
        screenClass,
      });
    });

    return (
      <div className={className} style={containerStyle} name={name}>
        {modifiedChildren}
      </div>
    );
  }
}
