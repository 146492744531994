import { createSelector } from 'reselect';

export const getInvoices = state => state.invoices;
export const getInvoiceByID = (state, id) => state.invoices[id];
export const getInvoiceSearch = state => state.search.invoices;

export const getInvoiceResults = createSelector(
  [getInvoices, getInvoiceSearch],
  (invoices, search) => {
    if (!search || !search.searchOrder) {
      return [];
    }
    return search.searchOrder.map(id => invoices[id]);
  },
);
