import UIConstants from 'shared/constants/UIConstants';

export function openPrimaryMenu() {
  return {
    type: UIConstants.OPEN_PRIMARY_MENU,
  };
}

export function closePrimaryMenu() {
  return {
    type: UIConstants.CLOSE_PRIMARY_MENU,
  };
}
