import { Form } from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InputDEPRECATED from 'components/inputs/InputDEPRECATED';
import Select from 'components/inputs/Select';

class StaffBasicsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: null,
    };
  }

  readyStatus = () => {
    this.setState({ status: null });
  };

  unreadyStatus = () => {
    this.setState({ status: 'invalid' });
  };

  render() {
    const { user, onSubmit } = this.props;

    return (
      <Form
        className={`${this.state.status} grid grid--center`}
        onValid={this.readyStatus}
        onInvalid={this.unreadyStatus}
        onSubmit={onSubmit}
      >
        <div className="old-row">
          <InputDEPRECATED
            name="email"
            type="text"
            initialValue={user.email || ''}
            title="User Email"
            validations="isEmail,isExisty"
            validationError="This is not a valid email"
            required
          />
        </div>

        <div className="old-row">
          <div className="old-col">
            <InputDEPRECATED
              name="firstname"
              type="text"
              initialValue={user.firstname || ''}
              title="First Name"
              validations="isExisty"
              validationError="First name is required"
              required
            />
          </div>

          <div className="old-col">
            <InputDEPRECATED
              name="lastname"
              type="text"
              title="Last name"
              initialValue={user.lastname || ''}
              validations="isExisty"
              validationError="Last name is required"
              required
            />
          </div>
        </div>

        <div className="old-row">
          <Select
            name="role"
            ref={c => {
              this.role = c;
            }}
            title="Role"
            validations="isExisty"
            initialValue={user.roles || ''}
            options={[
              { title: 'Please Select', value: '' },
              { title: 'Admin', value: 'admin' },
              { title: 'Call Centre Agent', value: 'agent' },
              { title: 'Recruiter', value: 'recruiter' },
            ]}
            required
          />
        </div>

        <div className="old-row hasButton">
          <InputDEPRECATED
            type="hidden"
            name="user_id"
            initialValue={user.id || ''}
          />

          <input
            className="btn"
            type="submit"
            name="submit"
            value="Save User"
            disabled={!!this.state.status}
          />
        </div>
      </Form>
    );
  }
}

StaffBasicsForm.propTypes = {
  user: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
};

StaffBasicsForm.defaultProps = {
  user: {},
};

export default StaffBasicsForm;
