export default [
  {
    label: 'Jan',
    days: 31,
    value: 1,
  },
  {
    label: 'Feb',
    days: year => (year % 4 ? 28 : 29),
    value: 2,
  },
  {
    label: 'Mar',
    days: 31,
    value: 3,
  },
  {
    label: 'Apr',
    days: 30,
    value: 4,
  },
  {
    label: 'May',
    days: 31,
    value: 5,
  },
  {
    label: 'Jun',
    days: 30,
    value: 6,
  },
  {
    label: 'Jul',
    days: 31,
    value: 7,
  },
  {
    label: 'Aug',
    days: 31,
    value: 8,
  },
  {
    label: 'Sep',
    days: 30,
    value: 9,
  },
  {
    label: 'Oct',
    days: 31,
    value: 10,
  },
  {
    label: 'Nov',
    days: 30,
    value: 11,
  },
  {
    label: 'Dec',
    days: 31,
    value: 12,
  },
];
