import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Notes extends Component {
  static propTypes = {
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        from_user_id: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };

  render() {
    return (
      <div className="notes">
        {this.props.notes && this.props.notes.length > 0 && (
          <table className="padded striped">
            <tbody>
              {this.props.notes.map(note => (
                <tr key={note.id}>
                  <td className="td-left">
                    {moment(note.created_at).format('HH:mm dddd, MMMM Do YYYY')}
                  </td>
                  <td className="td-right right-align">{note.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default Notes;
