import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import FormComponents from 'components/forms/FormComponents';
import { Container, Col } from 'components/grid';
import FormRow from 'components/forms/FormRow';
import { SectionHeader } from 'components/global/layout/Module';

/////////// DESCRIPTION //////////////
/* Wrapper component for passing in a fields config
The structure of the fields config should be as follows:
const fieldsConfig = (
  currentValues,
  extraDisplayCriteria,
  taxonomiesByClassification,
) => [
  {
    displayCriteria: true,
    sectionHeader: 'Care times',
    sectionId: 'care-times',
    rows: [
      {
        displayCriteria: true,
        rowId: 'schedule_description',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'schedule_description',
            component: StandardTextArea,
            fieldConfiguration: {
              name: 'schedule_description',
              type: 'text',
              placeholder: 'Schedule description...',
            },
            size: {
              xs: 12,
              md: 11,
            },
          },
          {
            NEXT FIELD
          },
        ],
      },
      {
        NEXT ROW
      },
    ],
  },
  {
    NEXT SECTION
  },
*/
///////// END OF DESCRIPTION //////////

const StyledSectionHeader = styled(SectionHeader)`
  padding-left: 0;
`;

const FormTemplate = ({
  currentValues,
  extraDisplayCriteria,
  fieldsConfig,
  taxonomiesByClassification,
  searchableOptions,
}) => {
  return (
    <Container gutterWidth={40}>
      {fieldsConfig(
        currentValues,
        extraDisplayCriteria,
        taxonomiesByClassification,
        searchableOptions,
      ).map(section => {
        if (!section.displayCriteria) {
          return null;
        }

        return (
          <Fragment key={section.sectionId}>
            <StyledSectionHeader id={section.sectionId}>
              {section.sectionHeader}
            </StyledSectionHeader>
            {section.rows.map(row => {
              if (!row.displayCriteria) {
                return null;
              }

              return (
                <FormRow key={row.rowId} colGutter={20}>
                  {row.fields.map(
                    field =>
                      field.displayCriteria && (
                        <Col
                          xs={field.size.xs}
                          md={field.size.md}
                          key={field.fieldId}
                        >
                          <Field
                            key={`field_${field.fieldId}`}
                            component={FormComponents(field.component)}
                            {...{ ...field.fieldConfiguration }}
                          >
                            {field.options && (
                              <>
                                <option value="">Select option</option>
                                {field.options.map(option => (
                                  <option
                                    key={option.taxon_id}
                                    value={option.taxon_id}
                                  >
                                    {option.value}
                                  </option>
                                ))}
                              </>
                            )}
                          </Field>
                        </Col>
                      ),
                  )}
                </FormRow>
              );
            })}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default FormTemplate;
