import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import InlineValidationError from 'components/forms/InlineValidationError';
import StyledLabelCheckbox from 'components/forms/StyledLabelCheckbox';
import StyledTitleWithLabel from 'components/forms/StyledTitleWithLabel';

const SelectedValue = styled.div`
  background: ${props => props.theme.colors.lightestGrey};
  border-radius: 4px;
  position: absolute;
  right: 5px;
  padding: 5px;
  top: 42%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const StyledTupleContainer = styled.div`
  display: block;
  vertical-align: top;
  position: relative;
  @media (min-width: 768px) {
    display: inline-block;
    width: 48%;

    &:nth-child(2n) {
      margin-right: 4%;
    }
  }
`;

const CustomStyledLabelCheckbox = styled(StyledLabelCheckbox)`
  width: 100%;

  &.isSelected {
    > label {
      padding-right: 50%;
    }
  }
`;

const Dropdown = styled.div`
  background: white;
  border: 1px solid ${props => props.theme.colors.uiGrey};
  border-top: 0;
  margin-top: -7px;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: ${props => props.theme.zIndexes.menu};

  > span {
    display: block;
    padding: 10px;
    &:hover {
      background: ${props => props.theme.colors.lightestGrey};
      cursor: pointer;
    }
  }
`;

class StandardTupleCheckboxGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
    };
  }

  onSelect = (value, dropdown) => {
    const { input, transform } = this.props;
    const newValue = [...input.value];
    newValue.push(transform(value, dropdown));
    this.setState(
      {
        open: null,
      },
      input.onChange(newValue),
    );
  };

  onToggle = e => {
    e.persist();
    e.stopPropagation();
    if (e.target.checked) {
      this.setState({
        open: e.target.value,
      });
    } else {
      const { input, keyMap } = this.props;
      const newValue = (input.value || []).filter(
        item => item[keyMap[0]] !== e.target.value,
      );
      input.onChange(newValue);
      this.setState({
        open: null,
      });
    }
  };

  render() {
    const {
      dropdownOptions,
      help,
      input,
      keyMap,
      label,
      options,
      title,
      meta: { touched, error, warning },
    } = this.props;

    const selected = (input.value || []).map(value => value[keyMap[0]]);

    return (
      <div id={input.name}>
        <StyledTitleWithLabel
          title={title}
          label={label}
          help={help}
          error={touched && error}
        />
        {options.map(option => {
          let dropDownLabel;
          const isSelected = selected.includes(option.key);
          if (isSelected) {
            const dropDownValue =
              input.value[selected.indexOf(option.key)][keyMap[1]];
            const dropDownItem = dropdownOptions.find(({ taxon_id, value }) =>
              dropDownValue === taxon_id ? value : null,
            );
            dropDownLabel = dropDownItem ? dropDownItem.value : 'No value';
          }
          return (
            <StyledTupleContainer key={option.key}>
              <CustomStyledLabelCheckbox
                key={option.key}
                name={option.key}
                value={option.key}
                checked={this.state.open === option.key || isSelected}
                label={option.label}
                onChange={this.onToggle}
                className={isSelected ? 'isSelected' : null}
              />
              {isSelected && <SelectedValue>{dropDownLabel}</SelectedValue>}
              {this.state.open === option.key && (
                <Dropdown>
                  {dropdownOptions.map(({ taxon_id, value }) => (
                    <span
                      role="button"
                      onClick={() => this.onSelect(option.key, taxon_id)}
                      key={taxon_id}
                    >
                      {value}
                    </span>
                  ))}
                </Dropdown>
              )}
            </StyledTupleContainer>
          );
        })}
        {touched &&
          ((error && <InlineValidationError>{error}</InlineValidationError>) ||
            (warning && (
              <InlineValidationError>{warning}</InlineValidationError>
            )))}
      </div>
    );
  }
}

StandardTupleCheckboxGroup.propTypes = {
  dropdownOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  help: PropTypes.string,
  input: PropTypes.shape().isRequired,
  keyMap: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  transform: PropTypes.func.isRequired,
  title: PropTypes.string,
};

StandardTupleCheckboxGroup.defaultProps = {
  advice: null,
  label: null,
  help: '',
  title: null,
};

export default StandardTupleCheckboxGroup;
