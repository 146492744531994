import React from 'react';

export const SC_NAME = 'Home Instead Live-in Care'
export const SC_NUMBER = '020 8629 1030';
export const JOBS_MAIL = 'jobs@liveincare.homeinstead.co.uk'
export const DEFAULT_MAIL = 'hello@liveincare.homeinstead.co.uk'
export const HOME_INSTEAD_URL = 'https://homeinstead.co.uk'

export const SCJobEmail = () => (
  <a href={`mailto:${JOBS_MAIL}`}>{JOBS_MAIL}</a>
);

export const SCDefaultEmail = () => (
  <a href={`mailto:${DEFAULT_MAIL}`}>{DEFAULT_MAIL}</a>
);


export const SCContactNumber = () => (
  <a href={`tel:${SC_NUMBER}`}>{SC_NUMBER}</a>
);

export const openContactNumber = (number = null) => {
  const numberToOpen = number ? number : SC_NUMBER;
  window.location.href = `tel:${numberToOpen}`;
};

export const redirectToHomeInstead = () => {
  window.location.href = HOME_INSTEAD_URL;
};

