import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

import CarerWorkHistoryForm from 'components/carers/CarerWorkHistoryForm';

class CarerWorkHistoryFormContainer extends Component {
  static propTypes = {
    editMode: PropTypes.bool,
    taxonomiesByClassification: PropTypes.shape().isRequired,
    ready: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    editMode: false,
    ready: true,
    taxonomiesByClassification: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['organization_country', 'work_history_employment_type'];
  }

  handleSubmit = ({ data, addMore }) => {
    const newData = { ...data };
    if (data.current_employer && data.employment_end_date) {
      delete newData.employment_end_date;
    }

    delete newData.current_employer;

    this.props.onSubmit(newData, addMore);
  };

  render() {
    const { taxonomiesByClassification } = this.props;
    if (!this.props.ready || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    return (
      <CarerWorkHistoryForm
        onSubmit={this.handleSubmit}
        initialValues={this.props.initialValues}
        taxonomiesByClassification={this.props.taxonomiesByClassification}
        editMode={this.props.editMode}
      />
    );
  }
}

const mapStateToProps = state => ({
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    CarerWorkHistoryFormContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

export default connect(mapStateToProps)(CarerWorkHistoryFormContainer);
