import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReduxToastrMessage from 'components/global/ReduxToastrMessage';

class AppMain extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  render() {
    return (
      <div>
        <ReduxToastrMessage />
        {this.props.children}
      </div>
    );
  }
}

export default AppMain;
