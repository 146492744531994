import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getCarerRates,
  getRateLimits,
  setCarerRates,
  resetCarerRates,
} from '_actions/rateActions';
import {
  selectFields,
  selectCarerRates,
  selectRateLimits,
} from 'shared/selectors/ratesSelectors';
import AdminUserRates from 'components/users/AdminUserRates';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

class AdminUserRatesContainer extends Component {
  static propTypes = {
    fields: PropTypes.shape({
      settings: PropTypes.arrayOf(PropTypes.string).isRequired,
      shiftTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    getCarerRates: PropTypes.func.isRequired,
    getRateLimits: PropTypes.func.isRequired,
    rates: PropTypes.shape(),
    rateLimits: PropTypes.shape(),
    resetCarerRates: PropTypes.func.isRequired,
    setCarerRates: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    rates: null,
    rateLimits: null,
  };

  UNSAFE_componentWillMount() {
    this.props.getCarerRates(this.props.user.id);
    this.props.getRateLimits();
  }

  setCarerRates = data => this.props.setCarerRates(this.props.user.id, data);

  resetCarerRates = () => this.props.resetCarerRates(this.props.user.id);

  render() {
    if (!this.props.rates || !this.props.rateLimits) {
      return <LoadingPlaceholder />;
    }
    return (
      <AdminUserRates
        fields={this.props.fields}
        rates={this.props.rates}
        rateLimits={this.props.rateLimits}
        resetRates={this.resetCarerRates}
        setCarerRates={this.setCarerRates}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  fields: selectFields(state),
  rates: selectCarerRates(state, props.user.id),
  rateLimits: selectRateLimits(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCarerRates,
      getRateLimits,
      resetCarerRates,
      setCarerRates,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserRatesContainer);
