const filterCarerSchedules = (schedules, carerID) => {
  //create new array of shcedules
  const newSchedules = schedules.map(schedule => {
    const scheduleShifts = { ...schedule.shifts };
    //create a new schedule shift object and filter the days within in based on carer
    Object.keys(scheduleShifts).forEach(week => {
      const weeklySchedule = { ...scheduleShifts[week] };
      Object.keys(weeklySchedule).forEach(day => {
        const carerShifts = weeklySchedule[day].filter(shift => {
          return shift.user_id === carerID;
        });

        //reassign the shifts to the day or delete the day if we don't have any
        if (carerShifts.length) {
          weeklySchedule[day] = carerShifts;
        } else {
          delete weeklySchedule[day];
        }
      });

      if (Object.keys(weeklySchedule).length) {
        scheduleShifts[week] = weeklySchedule;
      } else {
        delete scheduleShifts[week];
      }
    });

    //create a new schedule object with out updated information
    return { ...schedule, shifts: scheduleShifts };
  });

  //finally filter out any schedules that don't have shifts
  return newSchedules.filter(schedule => Object.keys(schedule.shifts).length);
};

export default filterCarerSchedules;
