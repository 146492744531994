import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';

import Module, {
  ModuleHeader,
  ModuleHero,
} from 'components/global/layout/Module';
import StandardTable from 'components/global/layout/StandardTable';

import { Container, Col } from 'react-grid-system';
import Button from 'components/buttons/Button';
import StandardInput from 'components/forms/StandardInput';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import DiscountPropType from 'proptypes/DiscountPropType';
import { required } from 'validators';
import { trim } from 'normalizers';

class AdminUserDiscounts extends Component {
  static propTypes = {
    assignDiscount: PropTypes.func.isRequired,
    discounts: PropTypes.arrayOf(DiscountPropType).isRequired,
    unassignDiscount: PropTypes.func.isRequired,
  };

  render() {
    return [
      <Module>
        <ModuleHeader>Discounts</ModuleHeader>
        {this.props.discounts.length === 0 && (
          <ModuleHero>No discounts assigned to this user</ModuleHero>
        )}
        {this.props.discounts.length > 0 && (
          <StandardTable>
            <thead>
              <tr>
                <th>Slug</th>
                <th>Discount</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {this.props.discounts.map(discount => (
                <tr>
                  <td>{discount.slug}</td>
                  <td>{parseInt(discount.percent * 100, 10)}%</td>
                  <td>
                    <Button
                      className="danger"
                      onClick={() => this.props.unassignDiscount(discount.id)}
                    >
                      Unassign
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </StandardTable>
        )}
      </Module>,
      <Module>
        <ModuleHeader>Assign Discount</ModuleHeader>
        <Form
          name="assignDiscount"
          fields={['slug']}
          onSubmit={this.props.assignDiscount}
          component={({ handleSubmit, pristine }) => (
            <form onSubmit={handleSubmit}>
              <Container>
                <FormRow>
                  <Col xs={12} md={6}>
                    <Field
                      component={StandardInput}
                      name="slug"
                      type="text"
                      validate={[required]}
                      normalize={trim}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Button disabled={pristine} type="submit">
                      Assign
                    </Button>
                  </Col>
                </FormRow>
              </Container>
            </form>
          )}
        />
      </Module>,
    ];
  }
}

export default AdminUserDiscounts;
