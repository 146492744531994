import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledRadioContainer = styled.div`
  display: inline-block;
  margin: 0 auto;

  > label {
    background: ${props =>
      props.higherContrast
        ? props.theme.colors.formGrey
        : props.theme.colors.lightestGrey};
    border: 2px solid ${props => props.theme.colors.lightestGrey};
    border-radius: 30px;
    cursor: pointer;
    display: block;
    padding: 14px 20px;
    overflow: hidden;
    user-select: none;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out,
      color 0.2s ease-in-out;
  }

  > input:checked + label {
    background: white;
    border: 2px solid ${props => props.theme.colors.hiAccentBlue};
    box-shadow: 0 1px 6px 0 ${props => props.theme.colors.shadowBlue};
    color: ${props => props.theme.colors.hiAccentBlue};
  }

  > input {
    position: absolute;
    left: -9999em;
  }
`;

const StyledRadio = ({
  className,
  checked,
  onChange,
  name,
  value,
  label,
  higherContrast,
}) => (
  <StyledRadioContainer higherContrast={higherContrast} className={className}>
    <input
      type="radio"
      id={`${name}-${value}`}
      name={`${name}`}
      onChange={onChange}
      value={value}
      checked={checked}
    />
    <label htmlFor={`${name}-${value}`}>{label}</label>
  </StyledRadioContainer>
);

export default StyledRadio;
