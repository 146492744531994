// @flow
/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import { ModuleHero } from 'components/global/layout/Module';

import FranchiseUsersTable from 'components/franchise/FranchiseUsersTable';

import type { FranchiseType, TaxonomiesClassificationType } from 'shared/types';

type Props = {
  franchise: FranchiseType,
  franchiseUsers: Array,
  isAdmin: boolean,
  taxonomiesByClassification: TaxonomiesClassificationType,
  updateLeadContact: (value: string) => Object,
};

type State = {
  displayArchived: boolean,
};

const CheckboxLabel = styled.label`
  color: ${props => props.theme.colors.midGrey};
  vertical-align: top;
  margin: 2px 0;
  display: block;
`;

const CheckboxInput = styled.input`
  width: 24px;
  height: 24px;
  vertical-align: bottom;
  margin-right: 10px;
`;

class FranchiseUsersSummary extends Component<Props, State> {
  state = {
    displayArchived: false,
  };

  toggleArchiveFilter = e => {
    if (e.target.checked) {
      this.setState({displayArchived: true})
    } else {
      this.setState({displayArchived: false})
    }
  }

  render() {
  	 const {
  	 	franchise,
  	 	franchiseUsers,
      isAdmin,
  	 	taxonomiesByClassification,
      updateLeadContact,
  	} = this.props;

    const addUser = isAdmin ?
      <ModuleHero><Link to={`/admin/franchises/${franchise.id}/user/`}>Add User</Link></ModuleHero>
      : null;

    if (!franchiseUsers || franchiseUsers.length === 0) {
      return (
        <section>
          <ModuleHero>There are no users for this Franchise!</ModuleHero>
          {addUser}
        </section>
      );
    }

    return (
      <section>
        <ModuleHero>
           <CheckboxLabel>
             <CheckboxInput
              type="checkbox"
              id="display-archived"
              name="display-archived"
              checked={this.state.displayArchived}
              onChange={this.toggleArchiveFilter}/>
                Show Archived Users
          </CheckboxLabel>
        </ModuleHero>
        <FranchiseUsersTable
          displayArchived={this.state.displayArchived}
          franchise={franchise}
          franchiseUsers={franchiseUsers}
          isAdmin={isAdmin}
          taxonomiesByClassification={taxonomiesByClassification}
          updateLeadContact={updateLeadContact}
        />
        {addUser}
      </section>
    );
  }
}

export default FranchiseUsersSummary;
