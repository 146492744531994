import React from 'react';
import styled from 'styled-components';
import Link from 'react-router-redux-dom-link';

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const StyledLinkButton = styled(Link)`
  color: ${props => props.theme.colors.hiCream};
  background: ${props => props.theme.colors.hiPurple};
  border: 1px solid ${props => props.theme.colors.hiPurple};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  flex-basis: 100%;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: ${props => props.theme.colors.hiPurpleHover};
    border-color: ${props => props.theme.colors.hiPurpleHover};
  }

  @media (min-width: ${props => props.theme.screen.small}px) {
    flex-basis: 45%;
  }
`;

const CarerHomeDashboard = ({ user }) => {
  return (
    <StyledContainer>
      <StyledLinkButton to={`/carers/${user.id}/`}>Profile</StyledLinkButton>
      <StyledLinkButton to="/jobs/">Jobs</StyledLinkButton>
      <StyledLinkButton to="/work-history/">Work History</StyledLinkButton>
      <StyledLinkButton to="/references/">References</StyledLinkButton>
    </StyledContainer>
  );
};

export default CarerHomeDashboard;
