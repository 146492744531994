import React from 'react';
import styled from 'styled-components';
import { object as obj } from 'prop-types';

import StatusIndicator from 'components/global/StatusIndicator';
import { ModuleMeta } from 'components/global/layout/Module';

import stateHelper from 'components/helpers/stateHelper';

const StyledModuleMeta = styled(ModuleMeta)`
  margin-bottom: 0px;
`;

const JobStatus = ({ bid, jobOffer }) => (
  <StyledModuleMeta>
    <StatusIndicator status={stateHelper.bidDisplayState(jobOffer, bid)} />
  </StyledModuleMeta>
);

JobStatus.propTypes = {
  bid: obj,
  jobOffer: obj.isRequired,
};

export default JobStatus;
