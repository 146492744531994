import React from 'react';

export function ordinalSuffixOf(i) {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

export const upperCaseFirstChar = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const text2html = textBlock => {
  if (!textBlock) {
    return '';
  }
  const html = textBlock.split('\n').map((item, i) => {
    if (item) {
      /* eslint-disable react/no-array-index-key */
      return (
        <span key={i} style={{ display: 'block', marginBottom: '10px' }}>
          {item}
        </span>
      );
      /* eslint-enable react/no-array-index-key */
    }
    return null;
  });
  return html;
};
