// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { AsteriskIcon } from 'components/global/icons/small';
import { Container, Col } from 'components/grid';
import {
  RequiredField,
  RequiredFields,
} from 'components/forms/required/RequiredFields';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import MultiInput from 'components/forms/fields/MultiInput';
import StandardMultiCheckboxGroup from 'components/forms/StandardMultiCheckboxGroup';
import StandardRadioGroup from 'components/forms/StandardRadioGroup';
import StandardTextarea from 'components/forms/StandardTextarea';
import StandardDateInput from 'components/forms/StandardDateInput';
import StandardInput from 'components/forms/StandardInput';
import Module, {
  ModuleHeader,
  ModuleSubheader,
} from 'components/global/layout/Module';
import {
  UpdateNeedButton,
  SubmitNeedButton,
} from 'components/careNeeds/CareNeedSubmitButtons';
import { validDate } from 'validators';
import {
  taxonomyToMultiCheckboxGroup,
  taxonomyToStandardRadioGroup,
} from 'components/helpers/taxonomyHelpers';
import {
  checkIfKeyHasValueInObject,
  moveObjectToLastInArray,
  sortObjectStrings,
} from 'components/helpers/objectHelpers';
import daysMap from 'components/helpers/daysMap';
import { asyncValidPostcode } from 'validators';

import type { TaxonomiesClassificationType } from 'shared/types';

import CareNeedSummary from 'components/careNeeds/CareNeedSummary';

const StyledModule = styled(Module)`
  @media (min-width: ${props => props.theme.screen.small}px) {
    padding: 30px 20px;
  }
`;

const StyledModuleHeader = styled(ModuleHeader)`
  font-size: 32px;
  padding-top: 20px;
  line-height: 1.2em;
`;

const StyledModuleSubHeader = styled(ModuleSubheader)`
  font-size: 24px;
  padding: 10px 0;
`;

type InitialValues = {
  care_days: ?Array<string>,
  carer_gender: ?string,
  condition_experience: ?Array<string>,
  date_flexibility: ?boolean,
  date_prospective_end: ?string,
  date_prospective_start: ?string,
  needs_car: ?boolean,
  needs_license: ?boolean,
  pets: ?Array<string>,
  relationship: string,
  requested_care_types: ?Array<string>,
  schedule_description: ?string,
  services: ?Array<string>,
  smoker_preference: ?string,
  start_date_accuracy: ?string,
  story: ?string,
  story_equipment: ?string,
  story_third_parties: ?string,
};

// We don't pass down the whole need object so cannot use NeedType
type Props = {
  taxonomiesByClassification: TaxonomiesClassificationType,
  initialValues: InitialValues,
  onSubmit: (Object, ?string) => void,
  need: Object,
  requiredFields: Array<string>,
  needLocked: boolean,
  needSubmitted: boolean,
};

class CareNeedManage extends Component<Props> {
  static defaultProps = {
    requiredFields: [],
  };

  disableSubmission = (
    currentValues: Object,
    requiredFields: Array<string>,
    invalid: boolean,
  ) => {
    if (invalid) {
      return true;
    }

    const remainingFields = requiredFields.filter(field => {
      const valueNotSet = !checkIfKeyHasValueInObject(field, currentValues);
      return valueNotSet;
    });
    return remainingFields.length > 0;
  };

  autoSaveField = (value: any, name: string) => {
    this.props.onSubmit({
      [name]: value,
      id: this.props.need.id,
      autosave: true,
    });
  };

  getWeekDays = () => {
    //$FlowFixMe - map is looking for a type - but adding that type messes up prettier and eslint
    return Object.values(daysMap).map(
      (day: any): Object => ({
        label: day,
        key: day.toLowerCase(),
      }),
    );
  };

  render() {
    const {
      taxonomiesByClassification,
      initialValues,
      onSubmit,
      need,
      requiredFields,
      needLocked,
      needSubmitted,
    } = this.props;

    if (needLocked) {
      return (
        <CareNeedSummary
          need={need}
          taxonomiesForClassification={taxonomiesByClassification}
        />
      );
    }
    const selfCare =
      initialValues.relationship &&
      initialValues.relationship.toLowerCase() === 'myself';

    return (
      <section>
        <StyledModule>
          <StyledModuleHeader>Tell us a little more...</StyledModuleHeader>
          <StyledModuleSubHeader>
            Telling us more now will help us speed up the process to find the
            best carer for you.
          </StyledModuleSubHeader>
          <Form
            asyncValidate={asyncValidPostcode}
            asyncBlurFields={['postcode']}
            shouldAsyncValidate={syncValidationPasses => {
              return syncValidationPasses;
            }}
            name="careNeedManage"
            enableReinitialize={false}
            fields={[
              'care_days',
              'carer_gender',
              'condition_experience',
              'date_flexibility',
              'date_prospective_end',
              'date_prospective_start',
              'firstname',
              'lastname',
              'needs_car',
              'needs_license',
              'pets',
              'postcode',
              'requested_care_types',
              'schedule_description',
              'services',
              'smoker_preference',
              'start_date_accuracy',
              'story',
              'story_equipment',
              'story_third_parties',
            ]}
            selectFields={[
              'requested_care_types',
              'needs_license',
              'start_date_accuracy',
              ...requiredFields,
            ]}
            values={initialValues}
            component={({
              error,
              handleSubmit,
              invalid,
              pristine,
              currentValues,
              ...otherProps
            }) => (
              <form onSubmit={handleSubmit}>
                <Container>
                  <FormRow largeMarginBottom>
                    <Col xs={12}>
                      <RequiredField
                        name="condition_experience"
                        component={StandardMultiCheckboxGroup}
                        currentValues={currentValues}
                        greyTitle
                        extraPadding
                        title={'What condition experience should a carer have?'}
                        higherContrast
                        options={taxonomyToMultiCheckboxGroup(
                          moveObjectToLastInArray(
                            sortObjectStrings(
                              taxonomiesByClassification.condition,
                              'taxon_id',
                            ),
                            'taxon_id',
                            'noneoftheabove',
                          ),
                        )}
                        requiredFields={requiredFields}
                        onChange={(event, value) =>
                          this.autoSaveField(value, 'condition_experience')
                        }
                      />
                    </Col>
                  </FormRow>
                  <FormRow largeMarginBottom>
                    <Col xs={12}>
                      <RequiredField
                        name="services"
                        component={StandardMultiCheckboxGroup}
                        currentValues={currentValues}
                        greyTitle
                        extraPadding
                        higherContrast
                        title="What type of care support is needed?"
                        options={taxonomyToMultiCheckboxGroup(
                          sortObjectStrings(
                            taxonomiesByClassification.services,
                            'taxon_id',
                          ),
                        )}
                        requiredFields={requiredFields}
                        onChange={(event, value) =>
                          this.autoSaveField(value, 'services')
                        }
                      />
                    </Col>
                  </FormRow>
                  <FormRow largeMarginBottom>
                    <Col xs={12} md={8}>
                      <RequiredField
                        name="requested_care_types"
                        component={StandardMultiCheckboxGroup}
                        currentValues={currentValues}
                        greyTitle
                        extraPadding
                        higherContrast
                        title="What type of care do you need?"
                        options={taxonomyToMultiCheckboxGroup(
                          taxonomiesByClassification.requested_care_type,
                        )}
                        requiredFields={requiredFields}
                        onChange={(event, value) =>
                          this.autoSaveField(value, 'requested_care_types')
                        }
                      />
                    </Col>
                  </FormRow>
                  {currentValues.requested_care_types &&
                    currentValues.requested_care_types.includes(
                      'liveincare',
                    ) && (
                      <FormRow largeMarginBottom>
                        <Col xs={12}>
                          <RequiredField
                            name="care_days"
                            component={StandardMultiCheckboxGroup}
                            currentValues={currentValues}
                            greyTitle
                            extraPadding
                            title="What days do you need care?"
                            higherContrast
                            options={this.getWeekDays()}
                            requiredFields={requiredFields}
                            selectAll="Every Day"
                            onChange={(event, value) =>
                              this.autoSaveField(value, 'care_days')
                            }
                          />
                        </Col>
                      </FormRow>
                    )}
                  {currentValues.requested_care_types &&
                    (currentValues.requested_care_types.includes(
                      'overnightcare',
                    ) ||
                      currentValues.requested_care_types.includes(
                        'daytimecare',
                      )) && (
                      <FormRow largeMarginBottom>
                        <Col xs={12}>
                          <RequiredField
                            name="schedule_description"
                            component={StandardTextarea}
                            currentValues={currentValues}
                            greyTitle
                            extraPadding
                            title="When would you like care to take place?"
                            placeholder="e.g. Monday to Friday from 8am to 4:30pm"
                            requiredFields={requiredFields}
                            onBlur={(event, value) =>
                              this.autoSaveField(value, 'schedule_description')
                            }
                          />
                        </Col>
                      </FormRow>
                    )}
                  {currentValues.requested_care_types &&
                    currentValues.requested_care_types.length > 0 && (
                      <FormRow largeMarginBottom>
                        <Col xs={12}>
                          <RequiredField
                            name="date_flexibility"
                            component={StandardRadioGroup}
                            currentValues={currentValues}
                            greyTitle
                            extraPadding
                            title="Are you flexible on times and dates? This can sometimes help when finding the best match possible"
                            higherContrast
                            options={[
                              { label: 'Yes', value: true },
                              { label: 'No', value: false },
                            ]}
                            requiredFields={requiredFields}
                            onChange={(event, value) =>
                              this.autoSaveField(value, 'date_flexibility')
                            }
                          />
                        </Col>
                      </FormRow>
                    )}
                  <FormRow largeMarginBottom>
                    <Col xs={12}>
                      <RequiredField
                        name="start_date_accuracy"
                        component={StandardRadioGroup}
                        currentValues={currentValues}
                        greyTitle
                        extraPadding
                        title="Do you know when care will start?"
                        higherContrast
                        options={taxonomiesByClassification.start_date_accuracy.map(
                          ({ taxon_id, value }) => ({
                            label: value,
                            value: taxon_id,
                          }),
                        )}
                        requiredFields={requiredFields}
                        onChange={(event, value) =>
                          this.autoSaveField(value, 'start_date_accuracy')
                        }
                      />
                    </Col>
                  </FormRow>
                  {currentValues.start_date_accuracy === 'exact' && (
                    <FormRow largeMarginBottom>
                      <Col xs={12} md={6}>
                        <RequiredField
                          component={StandardDateInput}
                          currentValues={currentValues}
                          greyTitle
                          extraPadding
                          name="date_prospective_start"
                          granularity="day"
                          title="Start date"
                          validate={[validDate]}
                          requiredFields={requiredFields}
                        />
                      </Col>
                    </FormRow>
                  )}
                  {currentValues.start_date_accuracy === 'exact' && (
                    <FormRow largeMarginBottom>
                      <Col xs={12} md={6}>
                        <RequiredField
                          component={StandardDateInput}
                          currentValues={currentValues}
                          greyTitle
                          extraPadding
                          name="date_prospective_end"
                          granularity="day"
                          title="End Date (Optional)"
                          validate={[validDate]}
                          requiredFields={requiredFields}
                        />
                      </Col>
                    </FormRow>
                  )}
                  <FormRow largeMarginBottom>
                    <Col xs={12}>
                      <RequiredField
                        name="needs_license"
                        component={StandardRadioGroup}
                        currentValues={currentValues}
                        greyTitle
                        extraPadding
                        higherContrast
                        title="Do you need your carer to have a driving license?"
                        options={[
                          { label: 'Yes', value: true },
                          { label: 'No', value: false },
                        ]}
                        requiredFields={requiredFields}
                        onChange={(event, value) =>
                          this.autoSaveField(value, 'needs_license')
                        }
                      />
                    </Col>
                  </FormRow>
                  {currentValues.needs_license && (
                    <FormRow largeMarginBottom>
                      <Col xs={12}>
                        <RequiredField
                          name="needs_car"
                          component={StandardRadioGroup}
                          currentValues={currentValues}
                          greyTitle
                          extraPadding
                          higherContrast
                          title="Do you need your carer to have a car?"
                          options={[
                            { label: 'Yes', value: true },
                            { label: 'No', value: false },
                          ]}
                          requiredFields={requiredFields}
                          onChange={(event, value) =>
                            this.autoSaveField(value, 'needs_car')
                          }
                        />
                      </Col>
                    </FormRow>
                  )}
                  {!selfCare && (
                    <RequiredFields
                      component={MultiInput}
                      currentValues={currentValues}
                      names={['firstname', 'lastname']}
                      title={
                        initialValues.relationship
                          ? `What is your ${initialValues.relationship}'s name?`
                          : "What is the care recipient's name?"
                      }
                      placeholders={{
                        firstname: 'First Name',
                        lastname: 'Last Name',
                      }}
                      requiredFields={requiredFields}
                      autosave={this.autoSaveField}
                    />
                  )}
                  <FormRow largeMarginBottom>
                    <Col xs={12} md={6}>
                      <RequiredField
                        name="postcode"
                        component={StandardInput}
                        currentValues={currentValues}
                        greyTitle
                        extraPadding
                        title={
                          initialValues.relationship
                            ? `What is your ${
                                selfCare
                                  ? ''
                                  : `${initialValues.relationship}'s`
                              }
                                full postcode?`
                            : "What is the care recipient's full postcode?"
                        }
                        requiredFields={requiredFields}
                        onBlur={(event, value) =>
                          this.autoSaveField(value, 'postcode')
                        }
                      />
                    </Col>
                  </FormRow>
                  <FormRow largeMarginBottom>
                    <Col xs={12}>
                      <RequiredField
                        name="carer_gender"
                        component={StandardRadioGroup}
                        currentValues={currentValues}
                        greyTitle
                        extraPadding
                        higherContrast
                        title="Would you like your carer to be male or female?"
                        options={taxonomyToStandardRadioGroup(
                          taxonomiesByClassification.gender_preference,
                        )}
                        requiredFields={requiredFields}
                        onChange={(event, value) =>
                          this.autoSaveField(value, 'carer_gender')
                        }
                      />
                    </Col>
                  </FormRow>
                  <FormRow largeMarginBottom>
                    <Col xs={12}>
                      <RequiredField
                        name="smoker_preference"
                        component={StandardRadioGroup}
                        currentValues={currentValues}
                        greyTitle
                        extraPadding
                        higherContrast
                        title="Would you prefer your carer not to smoke?"
                        options={taxonomyToStandardRadioGroup(
                          taxonomiesByClassification.smoker_preference,
                        )}
                        requiredFields={requiredFields}
                        onChange={(event, value) =>
                          this.autoSaveField(value, 'smoker_preference')
                        }
                      />
                    </Col>
                  </FormRow>
                  <FormRow largeMarginBottom>
                    <Col xs={12}>
                      <RequiredField
                        component={StandardMultiCheckboxGroup}
                        currentValues={currentValues}
                        greyTitle
                        extraPadding
                        name="pets"
                        higherContrast
                        title="Are there any pets in the home?"
                        options={taxonomyToMultiCheckboxGroup(
                          taxonomiesByClassification.pets,
                        )}
                        requiredFields={requiredFields}
                        onChange={(event, value) =>
                          this.autoSaveField(value, 'pets')
                        }
                      />
                    </Col>
                  </FormRow>
                  <FormRow largeMarginBottom>
                    <Col xs={12}>
                      <RequiredField
                        name="story"
                        component={StandardTextarea}
                        currentValues={currentValues}
                        greyTitle
                        extraPadding
                        title={
                          initialValues.relationship
                            ? `What would you like carers to know about ${
                                selfCare
                                  ? 'you'
                                  : `your ${initialValues.relationship}`
                              }?`
                            : 'What would you like carers to know?'
                        }
                        type="text"
                        placeholder="e.g. My mother is a very private, independent lady, who lives alone and now needs some extra support,
                        due to her early onset dementia. She can sometimes have quite challenging behaviours..."
                        requiredFields={requiredFields}
                        onBlur={(event, value) =>
                          this.autoSaveField(value, 'story')
                        }
                      />
                    </Col>
                  </FormRow>
                  <FormRow largeMarginBottom>
                    <Col xs={12}>
                      <RequiredField
                        name="story_equipment"
                        component={StandardTextarea}
                        currentValues={currentValues}
                        greyTitle
                        extraPadding
                        title="Is there any equipment or home modifications the carer should be aware of?"
                        placeholder="e.g. There is a ceiling hoist in the living room and a portable hoist in the bedroom,
                        which the carer will need experience with and need to learn how to use. We also have a stair lift and an adapted bathroom."
                        requiredFields={requiredFields}
                        onBlur={(event, value) =>
                          this.autoSaveField(value, 'story_equipment')
                        }
                      />
                    </Col>
                  </FormRow>
                  <FormRow largeMarginBottom>
                    <Col xs={12}>
                      <RequiredField
                        name="story_third_parties"
                        component={StandardTextarea}
                        currentValues={currentValues}
                        greyTitle
                        extraPadding
                        title="Is there anyone else involved in the care?"
                        placeholder="e.g. A care agency comes in the morning 9am - 10am to help with getting my mother out of bed in the morning.
                        Me and my sister also live close by and we visit regularly.  We would want a carer regularly to update us."
                        requiredFields={requiredFields}
                        onBlur={(event, value) =>
                          this.autoSaveField(value, 'story_third_parties')
                        }
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col xs={12}>
                      {this.disableSubmission(
                        currentValues,
                        requiredFields,
                        invalid,
                      ) && (
                        <>
                          You can only submit once all required fields are
                          complete and correct <AsteriskIcon />
                        </>
                      )}
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col xs={12}>
                      {error && (
                        <strong>
                          {error} <AsteriskIcon />
                        </strong>
                      )}
                    </Col>
                  </FormRow>
                  <FormRow largeMarginBottom>
                    {(this.disableSubmission(
                      currentValues,
                      requiredFields,
                      invalid,
                    ) ||
                      needSubmitted) && (
                      <Col xs={12} md={6}>
                        <UpdateNeedButton
                          invalid={invalid}
                          handleSubmit={handleSubmit}
                          onSubmit={onSubmit}
                          need={need}
                          needSubmitted={needSubmitted}
                        />
                      </Col>
                    )}
                    {!needSubmitted && (
                      <Col xs={12} md={6}>
                        <SubmitNeedButton
                          disabled={this.disableSubmission(
                            currentValues,
                            requiredFields,
                            invalid,
                          )}
                          handleSubmit={handleSubmit}
                          onSubmit={onSubmit}
                          need={need}
                        />
                      </Col>
                    )}
                  </FormRow>
                </Container>
              </form>
            )}
          />
        </StyledModule>
      </section>
    );
  }
}

export default CareNeedManage;
