// @flow
import React from 'react';
import styled from 'styled-components';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
// $FlowFixMe
import { Field } from 'redux-form';
import Button from 'components/buttons/Button';
import StandardTextarea from 'components/forms/StandardTextarea';
import type { NoteType } from 'shared/types';

const EditFormContainer = styled.div`
  margin: 10px;
`;

type Props = {|
  note: NoteType,
  saveNote: (NoteType, { message: string }) => void,
|};

const NoteEditForm = ({ note, saveNote }: Props) => (
  <EditFormContainer>
    <Form
      name="editNote"
      fields={['message']}
      values={note}
      onSubmit={values => saveNote(note, values)}
      component={({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <FormRow>
            <Field component={StandardTextarea} name="message" />
          </FormRow>
          <FormRow>
            <Button disabled={pristine} type="submit">
              Update Note
            </Button>
          </FormRow>
        </form>
      )}
    />
  </EditFormContainer>
);

export default NoteEditForm;
