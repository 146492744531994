import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Container, Row, Col } from 'components/grid';
import BidsListContainer from 'containers/bids/BidsListContainer';
import Module from 'components/global/layout/Module';
import TabbedComponents from 'components/global/layout/TabbedComponents';

class CustomerBids extends Component {
  static propTypes = {
    jobOffers: PropTypes.arrayOf(PropTypes.shape()),
    schedules: PropTypes.arrayOf(PropTypes.shape()),
    updateBid: PropTypes.func.isRequired,
  };

  static defaultProps = {
    jobOffers: null,
  };

  render() {
    const { activeBids, jobOffers, updateBid, schedules } = this.props;

    const bidsList =
      jobOffers.length > 1 ? (
        <TabbedComponents>
          {jobOffers.map((jobOffer, index) => {
            return (
              <BidsListContainer
                key={jobOffer.id}
                default
                name={`Job ${index + 1}`}
                jobOffer={jobOffer}
                updateBid={updateBid}
                schedules={schedules}
              />
            );
          })}
        </TabbedComponents>
      ) : (
        <BidsListContainer
          key={jobOffers[0].id}
          jobOffer={jobOffers[0]}
          updateBid={updateBid}
          schedules={schedules}
        />
      );

    return (
      <>
        {activeBids && (
          <Module>
            <Container>
              <Row>
                <Col xs={12}>
                  <p>
                    We’ve selected carers for you based on their suitable
                    experience for the role. We recommend booking a carer to get
                    to know them – there’s a notice period of only 24 hours in
                    your first week of care.
                  </p>
                  <p>
                    If you’d like to talk to the carer first, choose “Contact
                    carer” to exchange phone numbers.
                  </p>
                </Col>
              </Row>
            </Container>
          </Module>
        )}
        {bidsList}
      </>
    );
  }
}

export default CustomerBids;
