import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import AdminNeedContainer from 'containers/admin/needs/AdminNeedContainer';
import AdminFranchiseContainer from 'containers/admin/needs/AdminFranchiseContainer';
import ConvertCustomerContainer from 'containers/bids/ConvertCustomerContainer';
import AdminNeedsEditFormContainer from 'containers/admin/needs/AdminNeedsEditFormContainer';
import AdminNeedJobOffersContainer from 'containers/admin/needs/AdminNeedJobOffersContainer';
import JobOfferDetailsContainer from 'containers/admin/needs/jobs/JobOfferDetailsContainer';

import NeedHistory from 'components/admin/needs/history/NeedHistory';
import TabbedComponents from 'components/global/layout/TabbedComponents';
import { HistoryIcon, InlineStyledIcon } from 'components/global/icons';

const StyledHistoryLabel = styled.div`
  ${({ alert }) =>
    alert &&
    css`
      font-weight: bold;
      color: ${({ theme }) => theme.colors.warningRed};
    `}
`;

export default class AdminNeedTabbedComponents extends PureComponent {
  componentDidMount() {
    this.props.getNeedByIdWithUser(this.props.match.params.needID);
    this.props.getSchedulesWithScheduleShiftsByNeedID(
      this.props.match.params.needID,
    );
    this.props.getCareTeamByNeedWithCarers(this.props.match.params.needID);
  }

  render() {
    const { location, need, schedules, match, team } = this.props;

    const accountQueried =
      need && need.state === 'queried' && !!need.query_message;

    const HistoryLabel = () => (
      <StyledHistoryLabel alert={accountQueried}>
        <InlineStyledIcon
          style={{ marginLeft: '3px' }}
          Icon={HistoryIcon}
          label="History"
        />
      </StyledHistoryLabel>
    );

    return (
      <TabbedComponents>
        <div name="Care need" default>
          {need && <AdminNeedContainer match={match} need={need} />}
        </div>
        <div name="Edit need" noLabel>
          <AdminNeedsEditFormContainer match={match} need={need} />
        </div>
        <div name="Jobs">
          <AdminNeedJobOffersContainer
            id={match.params.needID}
            need={need}
            schedules={schedules}
          />
        </div>
        <div name="Job details" noLabel>
          <JobOfferDetailsContainer location={location} />
        </div>
        {match.params.needID && need && need.franchise_id && (
          <AdminFranchiseContainer
            franchiseID={need.franchise_id}
            name="Franchise"
            need={need}
            needID={match.params.needID}
          />
        )}
        {match.params.needID && team && (
          <ConvertCustomerContainer
            name="Convert customer"
            need={need}
            needID={match.params.needID}
          />
        )}
        <div name="History" label={HistoryLabel} className="floatRight">
          {need && <NeedHistory need={need} />}
        </div>
      </TabbedComponents>
    );
  }
}
