import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { searchInvoices } from '_actions/invoiceActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import CustomerInvoicesOverview from 'components/invoices/CustomerInvoicesOverview';
import {
  CustomerEmptyInvoices,
  CustomerVerifyEmailPlaceholder,
} from 'components/customers/CustomerEmptyStateLayouts';
import InvoicesPlaceholderImage from 'images/customers/CarerLookingUp.png';

import { getUser } from 'shared/selectors/accountSelectors';
import {
  getInvoiceSearch,
  getInvoiceResults,
} from 'shared/selectors/invoiceSelectors';
import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';

class CustomerInvoicesOverviewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: this.props.location.query.page || '1',
      showVoid: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.getInvoices();
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newPage = props.location.query.page;
    if (newPage && newPage !== this.state.current_page) {
      this.setState({ current_page: newPage }, this.getInvoices);
    }
  }

  getInvoices = () => {
    const states = [
      'awaiting_timesheets',
      'awaiting_payment',
      'queried',
      'paid',
    ];
    if (this.state.showVoid) {
      states.push('void');
    }
    this.props.searchInvoices(
      { customer_id: this.props.user.id, states },
      this.state.current_page,
    );
  };

  toggleVoid = e => {
    e.preventDefault();
    this.setState({ showVoid: !this.state.showVoid }, this.getInvoices);
  };

  render() {
    const { invoices, invoicesLoading, search, user } = this.props;

    if (invoicesLoading) {
      return <LoadingPlaceholder />;
    }

    if (!invoices || !invoices.length || !search) {
      return <CustomerEmptyInvoices />;
    }

    if (!user.confirmed_at) {
      return (
        <CustomerVerifyEmailPlaceholder
          type="invoice"
          image={InvoicesPlaceholderImage}
        />
      );
    }

    return (
      <CustomerInvoicesOverview
        location={this.props.location}
        search={this.props.search}
        invoices={this.props.invoices}
        currentPage={this.state.current_page}
        showVoid={this.state.showVoid}
        toggleVoid={this.toggleVoid}
      />
    );
  }
}

CustomerInvoicesOverviewContainer.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      page: PropTypes.string,
    }),
  }),
  invoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  search: PropTypes.shape(),
  user: PropTypes.shape(),
  searchInvoices: PropTypes.func.isRequired,
};

CustomerInvoicesOverviewContainer.defaultProps = {
  location: {
    query: {
      page: '1',
    },
  },
  search: null,
  user: null,
};

const mapStateToProps = state => ({
  invoices: getInvoiceResults(state),
  invoicesLoading: loadingSelectorFactory('invoices', 'invoices')(state),
  search: getInvoiceSearch(state),
  user: getUser(state),
});

const mapDispatchToProps = dispatch => ({
  searchInvoices: bindActionCreators(searchInvoices, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerInvoicesOverviewContainer);
