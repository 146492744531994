import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import CarerImage from 'components/carers/CarerImage';

const Card = styled.div`
  display: block;
  margin-bottom: 40px;
  padding-top: 50px;
  width: 100%;

  header {
    background: ${props => props.theme.colors.darkBlue};
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    min-height: 140px;
    padding-top: 50px;
    position: relative;

    > a.profile {
      color: white;
      font-size: 15px;
      position: absolute;
      left: 15px;
      top: 15px;
      text-decoration: none;
      @media (min-width: 768px) {
        font-size: ${props => props.theme.typography.defaultFontSize};
      }
    }

    > a.details {
      display: block;
      left: 50%;
      position: absolute;
      text-align: center;
      text-decoration: none;
      top: -50px;
      transform: translateX(-50%);

      > h3 {
        color: white;
        font-size: 24px;
        font-weight: 500;
        text-decoration: none;
      }
    }
  }

  section {
    background: white;
    border: 1px solid ${props => props.theme.colors.uiGrey};
    box-shadow: 0 1px 4px 0 ${props => props.theme.colors.shadowGrey};
  }
`;

const Pane = styled.div`
  height: 400px;
  overflow-y: scroll;

  p {
    margin-bottom: 10px;
  }
`;

const Actions = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  > div,
  > a,
  > button {
    border-radius: 0 !important;
    display: inline-block;
    width: 50% !important;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
    > button {
      border-radius: 0 !important;
      max-width: none;
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }
  }
`;

const PaneLinks = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
  left: 0;
  right: 0;
`;

const PaneLink = styled.a`
  border-bottom: 4px solid transparent;
  color: ${props => props.theme.colors.midGrey};
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  margin: 0 40px;
  padding-bottom: 2px;
  text-decoration: none;

  &.active {
    border-bottom-color: ${props => props.theme.colors.hiAccentBlue};
    color: white;
  }

  &:hover {
    color: white;
  }
`;

class CarerCard extends Component {
  static propTypes = {
    carer: PropTypes.shape().isRequired,
    panes: PropTypes.shape().isRequired,
    actions: PropTypes.arrayOf(PropTypes.element),
  };

  static defaultProps = {
    actions: null,
  };

  constructor(props) {
    super(props);
    this.state.active = Object.keys(props.panes)[0];
  }

  state = {
    active: null,
  };

  switchPane = title => {
    this.setState({ active: title });
  };

  render() {
    const { carer, panes, actions } = this.props;
    return (
      <Card>
        <header>
          <Link className="details" to={`/carers/${carer.id}/`}>
            <CarerImage carer={carer} className="avatar" />
            <h3>{carer.display_name}</h3>
          </Link>
          <Link className="profile" to={`/carers/${carer.id}/`}>
            View Profile
          </Link>
          <PaneLinks>
            {Object.keys(panes).map(title => (
              <PaneLink
                className={title === this.state.active ? 'active' : null}
                key={title}
                onClick={() => this.switchPane(title)}
              >
                {title}
              </PaneLink>
            ))}
          </PaneLinks>
        </header>
        <section>
          <Pane>{this.state.active && panes[this.state.active]}</Pane>
          <Actions>{actions}</Actions>
        </section>
      </Card>
    );
  }
}

export default CarerCard;
