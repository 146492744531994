import PropTypes from 'prop-types';
import React from 'react';

const JobsIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 24"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="jobs-icon"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path
          d="M24.8409332,20.7302609 C24.8409332,21.7306957 24.0275854,22.5434783 23.0277158,22.5434783 L4.04545493,22.5434783 C3.04558537,22.5434783 2.23223754,21.7306957 2.23223754,20.7302609 L2.23223754,11.5686522 L12.2105854,14.2896087 C12.6446723,14.4077391 13.0906288,14.467087 13.5365854,14.467087 C13.9825419,14.467087 14.4284984,14.4077391 14.8625854,14.2896087 L24.8409332,11.5686522 L24.8409332,20.7302609 Z M1.66702015,5.02173913 C1.66702015,4.71030435 1.92080276,4.45652174 2.23223754,4.45652174 L24.8409332,4.45652174 C25.152368,4.45652174 25.4061506,4.71030435 25.4061506,5.02173913 L25.4061506,10.2426522 L14.5647158,13.1993043 C13.8921071,13.3824348 13.1804984,13.3824348 12.5084549,13.1993043 L1.66702015,10.2426522 L1.66702015,5.02173913 Z M10.7104984,2.23973913 C10.7104984,1.904 10.9834984,1.63043478 11.3198028,1.63043478 L15.753368,1.63043478 C16.0896723,1.63043478 16.3626723,1.904 16.3626723,2.23973913 L16.3626723,3.32608696 L10.7104984,3.32608696 L10.7104984,2.23973913 Z M24.8409332,3.32608696 L17.4931071,3.32608696 L17.4931071,2.23973913 C17.4931071,1.28056522 16.7125419,0.5 15.753368,0.5 L11.3198028,0.5 C10.3606288,0.5 9.58006363,1.28056522 9.58006363,2.23973913 L9.58006363,3.32608696 L2.23223754,3.32608696 C1.29736797,3.32608696 0.536585366,4.08686957 0.536585366,5.02173913 L0.536585366,10.673913 C0.536585366,10.9293913 0.707281018,11.1526522 0.953150583,11.2193478 L1.10180276,11.2600435 L1.10180276,20.7302609 C1.10180276,22.3535652 2.42215058,23.673913 4.04545493,23.673913 L23.0277158,23.673913 C24.6510201,23.673913 25.971368,22.3535652 25.971368,20.7302609 L25.971368,11.2600435 L26.1200201,11.2193478 C26.3658897,11.1526522 26.5365854,10.9293913 26.5365854,10.673913 L26.5365854,5.02173913 C26.5365854,4.08686957 25.7758028,3.32608696 24.8409332,3.32608696 L24.8409332,3.32608696 Z"
          id="icon_jobs"
        />
      </g>
    </svg>
  </div>
);

JobsIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

JobsIcon.defaultProps = {
  className: '',
  height: '24px',
  width: '27px',
};

export default JobsIcon;
