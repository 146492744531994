import styled from 'styled-components';

const StyledSubheader = styled.h3`
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
`;

export default StyledSubheader;
