// @flow
import React, { Component, type AbstractComponent } from 'react';
import store from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { isLoggedIn } from 'shared/selectors/accountSelectors.js';

import LoginWrapper from 'components/login/LoginWrapper';
import StaffLoginWrapper from 'components/login/StaffLoginWrapper';

type Props = {
  availableLoggedIn: boolean,
  isLoggedIn: boolean,
  roles: Array<string>,
  verifying?: boolean,
};

export function withLoginWrapper(
  WrappedComponent: AbstractComponent<Props>,
): AbstractComponent<Props> {
  return class extends Component<Props> {
    componentDidMount() {
      if (
        this.props.isLoggedIn &&
        !this.props.availableLoggedIn &&
        !this.props.verifying
      ) {
        store.dispatch(push('/home/'));
      }
    }

    render() {
      if (this.props.isLoggedIn) {
        return <WrappedComponent {...this.props} />;
      }

      if (this.props.roles && this.props.roles.includes('Admin')) {
        return (
          <StaffLoginWrapper>
            <WrappedComponent {...this.props} />
          </StaffLoginWrapper>
        );
      }

      return (
        <LoginWrapper>
          <WrappedComponent {...this.props} />
        </LoginWrapper>
      );
    }
  };
}

function mapStateToProps(state, props) {
  return {
    isLoggedIn: isLoggedIn(state),
  };
}

//$FlowFixMe
const composedLoginWrapper = compose(
  connect(mapStateToProps),
  withLoginWrapper,
);

export default composedLoginWrapper;
