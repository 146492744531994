import keyMirror from 'keymirror';

export default keyMirror({
  TIMESHEET_GET_BY_ID: null,
  TIMESHEET_GET_BY_ID_SUCCESS: null,
  TIMESHEET_GET_BY_ID_FAILURE: null,

  TIMESHEET_GET_BY_CARER_ID: null,
  TIMESHEET_GET_BY_CARER_ID_SUCCESS: null,
  TIMESHEET_GET_BY_CARER_ID_FAILURE: null,

  TIMESHEET_UPDATE: null,
  TIMESHEET_UPDATE_SUCCESS: null,
  TIMESHEET_UPDATE_FAILURE: null,

  TIMESHEET_REASSIGN: null,
  TIMESHEET_REASSIGN_SUCCESS: null,
  TIMESHEET_REASSIGN_FAILURE: null,

  TIMESHEET_GET_AMENDS_BY_NEED_ID: null,
  TIMESHEET_GET_AMENDS_BY_NEED_ID_SUCCESS: null,
  TIMESHEET_GET_AMENDS_BY_NEED_ID_FAILURE: null,

  TIMESHEET_CREATE_AMENDS: null,
  TIMESHEET_CREATE_AMENDS_SUCCESS: null,
  TIMESHEET_CREATE_AMENDS_FAILURE: null,

  TIMESHEET_DELETE_AMENDS: null,
  TIMESHEET_DELETE_AMENDS_SUCCESS: null,
  TIMESHEET_DELETE_AMENDS_FAILURE: null,

  TIMESHEET_SEARCH: null,
  TIMESHEET_SEARCH_SUCCESS: null,
  TIMESHEET_SEARCH_FAILURE: null,

  TIMESHEET_DELETE: null,
  TIMESHEET_DELETE_SUCCESS: null,
  TIMESHEET_DELETE_FAILURE: null,

  TIMESHEET_GET_TOTAL_BY_ID: null,
  TIMESHEET_GET_TOTAL_BY_ID_SUCCESS: null,
  TIMESHEET_GET_TOTAL_BY_ID_FAILURE: null,

  TIMESHEET_SUMMARY: null,
  TIMESHEET_SUMMARY_SUCCESS: null,
  TIMESHEET_SUMMARY_FAILURE: null,

  TIMESHEET_RECALCULATE_TOTALS: null,
  TIMESHEET_RECALCULATE_TOTALS_SUCCESS: null,
  TIMESHEET_RECALCULATE_TOTALS_FAILURE: null,
});
