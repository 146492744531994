import React from 'react';
import CollapsibleSection from 'components/global/dynamic/CollapsibleSection';
import StandardTable from 'components/global/layout/StandardTable';
import { ModuleHero } from 'components/global/layout/Module';
import Link from 'react-router-redux-dom-link';

const AdminUserTeams = ({ teams }) => {
  return (
    <CollapsibleSection header="Teams" id="teams-section" openByDefault={false}>
      {teams.length === 0 && (
        <ModuleHero>This carer is not in any teams</ModuleHero>
      )}
      {teams.length > 0 && (
        <StandardTable>
          <tbody>
            {teams.map(team => (
              <tr key={team.customer_id}>
                <td>
                  <Link to={`/admin/users/${team.customer_id}/`}>
                    {team.customer_name}
                  </Link>
                </td>
                <td>
                  <Link to={`/admin/needs/${team.need_id}/#care-team`}>
                    View team
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </StandardTable>
      )}
    </CollapsibleSection>
  );
};

export default AdminUserTeams;
