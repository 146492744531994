import ApiConstants from 'shared/constants/ApiConstants';
import ChargeConstants from 'shared/constants/ChargeConstants';

export function createCharge(charge) {
  return {
    endpoint: '/charge/',
    method: 'post',
    onError: {
      type: ChargeConstants.CHARGE_CREATE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to create charge',
        dataSent: charge,
      },
    },
    onStart: { type: ChargeConstants.CHARGE_CREATE },
    onSuccess: {
      type: ChargeConstants.CHARGE_CREATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Charge created.',
      },
    },
    payload: charge,
    redirect: response => `/admin/charges/${response.data.id}/`,
    type: ApiConstants.API_REQUEST,
  };
}

export function updateCharge(chargeId, data) {
  return {
    endpoint: `/charge/${chargeId}/`,
    method: 'put',
    onError: {
      type: ChargeConstants.CHARGE_UPDATE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to update charge',
        dataSent: data,
      },
    },
    onStart: { type: ChargeConstants.CHARGE_UPDATE },
    onSuccess: {
      type: ChargeConstants.CHARGE_UPDATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Charge updated successfully.',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function getChargeById(chargeId) {
  return {
    endpoint: `/charge/${chargeId}/`,
    method: 'get',
    onError: { type: ChargeConstants.CHARGE_GET_BY_ID_FAILURE },
    onStart: { type: ChargeConstants.CHARGE_GET_BY_ID },
    onSuccess: { type: ChargeConstants.CHARGE_GET_BY_ID_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function payCharge(chargeId, data) {
  return {
    endpoint: `/charge/${chargeId}/pay/`,
    method: 'put',
    onError: {
      type: ChargeConstants.CHARGE_PAY_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title:
          'Unable to pay charge. Please contact Home Instead Live-in Care for assistance.',
        dataSent: data,
      },
    },
    onStart: { type: ChargeConstants.CHARGE_PAY },
    onSuccess: {
      type: ChargeConstants.CHARGE_PAY_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Charge updated successfully.',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function notifyCustomerOfCharge(chargeId) {
  return {
    endpoint: `/charge/send/to/consumer/${chargeId}/`,
    method: 'put',
    onError: {
      type: ChargeConstants.CHARGE_CREATE_NOTIFY_EMAIL_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to send email.',
      },
    },
    onStart: { type: ChargeConstants.CHARGE_CREATE_NOTIFY_EMAIL },
    onSuccess: {
      type: ChargeConstants.CHARGE_CREATE_NOTIFY_EMAIL_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Email sent successfully.',
      },
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function searchCharges(params, page) {
  const query = Object.assign({ page: page || 1 }, params);
  return {
    endpoint: '/charge/find/',
    method: 'get',
    onError: { type: ChargeConstants.CHARGE_SEARCH_FAILURE },
    onStart: { type: ChargeConstants.CHARGE_SEARCH },
    onSuccess: { type: ChargeConstants.CHARGE_SEARCH_SUCCESS },
    payload: query,
    type: ApiConstants.API_REQUEST,
  };
}
