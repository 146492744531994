import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TextInput from 'components/forms/TextInput';
import Button from 'components/buttons/Button';

class TimesheetQuery extends Component {
  static propTypes = {
    queryTimesheet: PropTypes.func.isRequired,
  };

  state = {
    querying: false,
  };

  startQuerying = () => {
    this.setState({ querying: true });
  };

  stopQuerying = () => {
    this.setState({ querying: false });
  };

  render() {
    if (this.state.querying) {
      // submitting a note actually does the querying of the timesheet
      return (
        <div>
          <p>Please let us know why you are querying this timesheet.</p>
          <TextInput
            onSave={this.props.queryTimesheet}
            onCancel={this.stopQuerying}
            placeholder="Please insert comments here."
          />
        </div>
      );
    }

    // this button starts the query process, but doesn't actually query the timesheet
    return (
      <Button className="alt" onClick={this.startQuerying}>
        Query Timesheet
      </Button>
    );
  }
}

export default TimesheetQuery;
