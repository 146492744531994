import PropTypes from 'prop-types';
import React from 'react';

const LanguageIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 19"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g id="language-icon" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-159.000000, -76.000000)" fill="currentColor">
          <g transform="translate(158.500000, 74.000000)">
            <path
              d="M7.33764789,18.9943585 C7.42042944,18.9943585 7.50321099,19.0128755 7.58054639,19.0509985 C8.73948812,19.6277463 9.98720217,19.9202048 11.2882885,19.9202048 C15.900637,19.9202048 19.6535822,16.1471088 19.6535822,11.5097081 C19.6535822,6.87176273 15.900637,3.09866673 11.2882885,3.09866673 C6.67648471,3.09866673 2.92353948,6.87176273 2.92353948,11.5097081 C2.92353948,12.7312806 3.17787491,13.90765 3.67946576,15.0072287 L3.71704423,15.1232318 C3.737195,15.2218072 4.14511199,17.3474413 2.26074245,19.7181525 C2.77322561,19.7851402 3.43874572,19.8483156 4.1244166,19.8483156 C5.48541067,19.8483156 6.4684416,19.6037832 6.96730937,19.1397709 C7.07024169,19.0444632 7.20312787,18.9943585 7.33764789,18.9943585 Z M11.2882885,21.0094358 C9.93437446,21.0094358 8.63110964,20.7289588 7.41062636,20.1767187 C6.67866317,20.6815773 5.57636145,20.9375465 4.12496122,20.9375465 L4.1244166,20.9375465 C2.56028096,20.9375465 1.13992381,20.642365 1.08001611,20.6298388 C0.884499153,20.588448 0.727105281,20.4435803 0.669920656,20.2518757 C0.613280647,20.060171 0.665563733,19.8532171 0.807163756,19.7116171 C2.86145332,17.6578722 2.70678252,15.7397365 2.65831175,15.3922718 C2.11151781,14.1690654 1.83430854,12.8630775 1.83430854,11.5097081 C1.83430854,6.27105186 6.07522922,2.00943578 11.2882885,2.00943578 C16.5018925,2.00943578 20.7428131,6.27105186 20.7428131,11.5097081 C20.7428131,16.7478197 16.5018925,21.0094358 11.2882885,21.0094358 L11.2882885,21.0094358 Z"
              transform="translate(10.695143, 11.509436) scale(-1, 1) translate(-10.695143, -11.509436)"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

LanguageIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

LanguageIcon.defaultProps = {
  className: '',
  height: '21px',
  width: '18px',
};

export default LanguageIcon;
