import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';

class ChargeSearchFilters extends Component {
  render() {
    const filters = this.props.filters;
    return (
      <div className="module">
        <div className="old-row">
          <div className="old-col">
            <select name="state" onChange={this.props.updateFilters}>
              <option value="">Charge State</option>
              <option value="unpaid">Unpaid</option>
              <option value="paid">Paid</option>
              <option value="failed">Failed</option>
              <option value="void">Voided</option>
            </select>
          </div>
        </div>
        <div className="old-row">
          <div className="old-col col-50">
            <label htmlFor="customer_name">Customer Name</label>
            <input
              name="customer_name"
              type="text"
              value={filters.customer_name || ''}
              onChange={this.props.updateFilters}
            />
          </div>
          <div className="old-col col-50">
            <label htmlFor="carer_name">Payee Name</label>
            <input
              name="carer_name"
              type="text"
              value={filters.carer_name || ''}
              onChange={this.props.updateFilters}
            />
          </div>
        </div>
        <div className="old-row">
          <div className="old-col col-50">
            <Link to={this.props.location.pathname}>Reset Filters</Link>
          </div>
        </div>
      </div>
    );
  }
}

ChargeSearchFilters.propTypes = {
  location: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  updateFilters: PropTypes.func.isRequired,
};

export default ChargeSearchFilters;
