import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getDefaultRates,
  getRateLimits,
  setDefaultRates,
} from '_actions/rateActions';

import LoadingPlaceholder from 'components/LoadingPlaceholder';

import DefaultRates from 'components/rates/DefaultRates';
import {
  selectFields,
  selectDefaultRates,
  selectRateLimits,
} from 'shared/selectors/ratesSelectors';

class DefaultRatesContainer extends Component {
  static propTypes = {
    defaultRates: PropTypes.shape(),
    fields: PropTypes.shape({
      settings: PropTypes.arrayOf(PropTypes.string).isRequired,
      shiftTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    getDefaultRates: PropTypes.func.isRequired,
    getRateLimits: PropTypes.func.isRequired,
    rateLimits: PropTypes.shape(),
    setDefaultRates: PropTypes.func.isRequired,
  };

  static defaultProps = {
    defaultRates: null,
    rateLimits: null,
  };

  UNSAFE_componentWillMount() {
    this.props.getDefaultRates();
    this.props.getRateLimits();
  }

  render() {
    if (!this.props.defaultRates || !this.props.rateLimits) {
      return <LoadingPlaceholder />;
    }

    return (
      <DefaultRates
        defaultRates={this.props.defaultRates.rate}
        fields={this.props.fields}
        rateLimits={this.props.rateLimits}
        setDefaultRates={this.props.setDefaultRates}
      />
    );
  }
}

const mapStateToProps = state => ({
  fields: selectFields(state),
  defaultRates: selectDefaultRates(state),
  rateLimits: selectRateLimits(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDefaultRates,
      getRateLimits,
      setDefaultRates,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefaultRatesContainer);
