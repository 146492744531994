import React from 'react';
import { reduxForm } from 'redux-form';
import { onSubmitActions } from 'redux-form-submit-saga';

import { login } from '_actions/accountActions';
import AccountConstants from 'shared/constants/AccountConstants';

import AccountLoginForm from 'components/account/AccountLoginForm';
import AccountLoginModule from 'components/account/AccountLoginModule';

import { scrollToTop } from 'components/helpers/domHelpers';
import { parseQueryString } from 'components/helpers/urlHelpers';

const queryStringObject = parseQueryString(window.location.search);

const ReduxAccountLogin = reduxForm({
  form: 'login',
  onSubmit: onSubmitActions({
    submit: login,
    success: AccountConstants.LOGIN_SUCCESS,
    failure: AccountConstants.LOGIN_FAILURE,
  }),
  initialValues: {
    email: queryStringObject && queryStringObject.email,
  },
  onSubmitFail: () => {
    scrollToTop();
  },
})(AccountLoginForm);

const message = 'Login to the Home Instead Live-in Care Talent Platform';

function AccountLoginContainer() {
  return (
    <>
      <AccountLoginModule title={'Login'} message={message}>
        <ReduxAccountLogin />
      </AccountLoginModule>
    </>
  );
}

export default AccountLoginContainer;
