// @flow
import React from 'react';
import Link from 'react-router-redux-dom-link';

import Module, { ModuleHero } from 'components/global/layout/Module';
import Paginator from 'components/global/Paginator';
import StandardList from 'components/global/layout/StandardList';
import JobOfferRowContainer from 'containers/jobOffers/JobOfferRowContainer';

import type {
  BidType,
  JobOfferType,
  LocationType,
  SearchType,
  TaxonomiesClassificationType,
} from 'shared/types';

type JobOffersWithBids = [
  {
    bid: BidType,
    jobOffer: JobOfferType,
  },
];

type Props = {
  jobOffersWithBids: JobOffersWithBids,
  searchWithName: SearchType,
  searchOrder: string,
  location: LocationType,
  title: string,
  taxonomiesByClassification: TaxonomiesClassificationType,
  workState: string,
};

const CarerJobOffers = ({
  jobOffersWithBids,
  searchWithName,
  searchOrder,
  location,
  title,
  taxonomiesByClassification,
  workState,
}: Props) => {
  return (
    <section>
      {workState === 'unavailable' ? (
        <Module>
          <ModuleHero>
            You are currently not receiving new job offers.{' '}
            <Link to="/account">Change</Link>
          </ModuleHero>
        </Module>
      ) : (
        <>
          {jobOffersWithBids.length === 0 ? (
            <Module>
              <ModuleHero>No jobs found.</ModuleHero>
            </Module>
          ) : (
            <>
              <StandardList>
                {jobOffersWithBids.map(details => (
                  <JobOfferRowContainer
                    key={details.jobOffer.id}
                    jobOffer={details.jobOffer}
                    bid={details.bid}
                    newJob={title === 'New jobs'}
                  />
                ))}
              </StandardList>
              <Paginator
                currentPage={searchWithName.pagination.page}
                totalPages={searchWithName.pagination.pages}
                location={location}
                perPage={10}
              />
            </>
          )}
        </>
      )}
    </section>
  );
};

export default CarerJobOffers;
