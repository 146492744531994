import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import Link from 'react-router-redux-dom-link';
import { PortalWithState } from 'react-portal';

import { Container, Row, Col } from 'components/grid';
import _ from 'lodash';

import Modal from 'components/modules/Modal';
import Message from 'components/modules/Message';
import { JOBS_MAIL } from 'components/global/ContactInfo';
import StatusIndicator from 'components/global/StatusIndicator';
import { StyledBulletedList } from 'components/global/informationdisplay/DisplayLists';
import Module, { ModuleHeader, ModuleSubheader } from 'components/global/layout/Module';
import Button from 'components/buttons/Button';
import ButtonGroup from 'components/buttons/ButtonGroup';
import DBSCheckContainer from 'containers/carers/DBSCheckContainer';

import FormRow from 'components/forms/FormRow';
import InlineValidationError from 'components/forms/InlineValidationError';
import StandardDateInput from 'components/forms/StandardDateInput';
import StandardInput from 'components/forms/StandardInput';
import StandardMultiCheckboxGroup from 'components/forms/StandardMultiCheckboxGroup';
import StandardRadioGroup from 'components/forms/StandardRadioGroup';
import StandardTextarea from 'components/forms/StandardTextarea';
import StandardTupleCheckboxAdder from 'components/forms/StandardTupleCheckboxAdder';
import StandardTupleCheckboxGroup from 'components/forms/StandardTupleCheckboxGroup';
import StyledFieldTitleTag from 'components/forms/StyledFieldTitleTag';

import {
  englishRequired,
  required,
  requiredList,
  requiredBoolean,
  validDate,
} from 'validators';

class CarerEditProfile extends Component {
  static propTypes = {
    carer: PropTypes.shape().isRequired,
    countryPreference: PropTypes.arrayOf(PropTypes.string),
    dbsDate: PropTypes.string,
    dbsDOB: PropTypes.string,
    dbsNumber: PropTypes.string,
    dbsState: PropTypes.string.isRequired,
    diff: PropTypes.shape().isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isStaff: PropTypes.bool.isRequired,
    itemApprove: PropTypes.func.isRequired,
    profile: PropTypes.shape().isRequired,
    queryProfile: PropTypes.func.isRequired,
    rtwCertification: PropTypes.bool.isRequired,
    rtwExpires: PropTypes.bool.isRequired,
    sectionApprove: PropTypes.func.isRequired,
    sectionReject: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    taxonomiesByClassification: PropTypes.shape().isRequired,
    valid: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    dbsNumber: '',
    dbsDOB: '',
    dbsDate: null,
    countryPreference: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      picked: {},
    };
  }

  togglePickValue = (field, item, picked, key = null) => {
    let pickedField;

    if (key) {
      pickedField = this.state.picked[field]
        ? { ...this.state.picked[field] }
        : {};
      if (picked) {
        pickedField = {
          ...pickedField,
          [key]: item,
        };
      } else {
        delete pickedField[key];
      }
    } else {
      pickedField = this.state.picked[field]
        ? [...this.state.picked[field]]
        : [];
      if (picked) {
        pickedField.push(item);
      } else {
        pickedField.splice(pickedField.indexOf(item), 1);
      }
    }

    const newState = { ...this.state };
    newState.picked[field] = pickedField;

    this.setState(newState);
  };

  requiredIfNotAdmin = this.props.isStaff ? () => undefined : required;
  requiredListIfNotAdmin = this.props.isStaff ? () => undefined : requiredList;
  requiredBooleanIfNotAdmin = this.props.isStaff
    ? () => undefined
    : requiredBoolean;
  englishRequiredIfNotAdmin = this.props.isStaff
    ? () => undefined
    : englishRequired;

  render() {
    const {
      carer,
      countryPreference,
      hiComplianceFields,
      dbsDate,
      dbsDOB,
      dbsNumber,
      dbsState,
      diff,
      handleSubmit,
      isStaff,
      itemApprove,
      profile,
      queryProfile,
      rtwCertification,
      rtwExpires,
      sectionApprove,
      sectionReject,
      submitFailed,
      taxonomiesByClassification,
      valid,
    } = this.props;

    let columnXS = 24;
    let columnM = 12;

    if (isStaff && profile.live_status === 'draft') {
      columnXS = 12;
      columnM = 9;
    }

    return (
      <form onSubmit={handleSubmit}>
        <Container outerGutter={0}>
          <Row>
            <Col xs={columnXS} md={columnM}>
              <Module id="header-text">
                <ModuleHeader>Edit your CAREGiver Profile</ModuleHeader>
                <Container>
                  <Row>
                    <Col>
                      <p>
                        This information is used to create{' '}
                        <Link to={`/carers/${profile.user_id}/`}>
                          your CAREGiver profile
                        </Link>
                        .
                      </p>
                      <StyledBulletedList>
                        <li>
                          <strong>Some required fields</strong>: As part of our
                          process we require some elements of this form to be
                          completed before you are approved. Required fields are
                          marked by an asterisk (*).
                        </li>
                        <li>
                          <strong>Shared with Home Instead franchises</strong>:
                          Once approved your profile may be shared with Home
                          Instead franchises who are your potential employers to
                          give them a little more information about you and your
                          experience. They may also share this with their client
                          as part of their matching process.
                        </li>
                      </StyledBulletedList>
                    </Col>
                  </Row>
                </Container>
              </Module>

              {!isStaff &&
                profile.live_status === 'draft' &&
                !profile.queried_at && (
                  <Module id="changes-pending">
                    <ModuleHeader>Changes Pending</ModuleHeader>
                    <Container>
                      <Row>
                        <Col>
                          There are existing changes on this profile pending
                          approval. Please feel free to make further changes. We
                          will let you know when your new profile is live via
                          email.
                        </Col>
                      </Row>
                    </Container>
                  </Module>
                )}

              {!isStaff &&
                profile.live_status === 'draft' &&
                profile.queried_at && (
                  <Module id="profile-queried">
                    <ModuleHeader>Outstanding Query</ModuleHeader>
                    <Container>
                      <Row>
                        <Col>
                          We recently sent you an email asking for more
                          information about your profile. Please review this
                          email and answer our questions so we can approve your
                          outstanding profile changes.
                        </Col>
                      </Row>
                    </Container>
                  </Module>
                )}

              <Module id='country_preference'>
                <ModuleHeader>Location</ModuleHeader>
                <Container>
                  <FormRow>
                    <Col>
                      <p>
                        Please indicate which country / countries you would like to
                        apply for care in.
                      </p>
                      <p>
                        This is important as each country may have different
                        requirements before work can begin, e.g. DBS, PVG, Training modules.
                      </p>
                      <Field
                        name="country_preference"
                        component={StandardMultiCheckboxGroup}
                        title="Which countries would you like to apply for care in? *"
                        help="You can select multiple options"
                        options={taxonomiesByClassification.franchise_country.map(
                          ({ taxon_id, value }) => ({
                            key: taxon_id,
                            label: value,
                          }),
                        )}
                        validate={[this.requiredListIfNotAdmin]}
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field
                        name="work_postcode"
                        component={StandardInput}
                        title="What postcode do you want to use to find nearby work? *"
                        validate={[this.requiredIfNotAdmin]}
                      />
                    </Col>
                  </FormRow>
                </Container>
              </Module>

              {!countryPreference.length &&
                <Module id="country_preference_note">
                  <Container>
                    <Row>
                      <Col>
                        <strong>
                          Please select and save your preferred countries of
                          working to view more fields.
                        </strong>
                      </Col>
                    </Row>
                  </Container>
                </Module>
              }

              {!!countryPreference.length && [
                <Module id="carer-offer">
                  <ModuleHeader>Care Offer</ModuleHeader>
                  <Container>
                    <FormRow>
                      <Col>
                        <Field
                          name="care_type"
                          component={StandardMultiCheckboxGroup}
                          title="What types of care would you like to do? *"
                          help="You can select multiple options"
                          options={taxonomiesByClassification.care_type.map(
                            ({ taxon_id, value }) => ({
                              key: taxon_id,
                              label: value,
                            }),
                          )}
                          validate={[this.requiredListIfNotAdmin]}
                        />
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col>
                        <Field
                          name="services"
                          component={StandardMultiCheckboxGroup}
                          title="Services *"
                          options={taxonomiesByClassification.services.map(
                            ({ taxon_id, value }) => ({
                              key: taxon_id,
                              label: value,
                            }),
                          )}
                          validate={[this.requiredListIfNotAdmin]}
                        />
                      </Col>
                    </FormRow>
                  </Container>
                </Module>,

                <Module id="experience">
                  <ModuleHeader>Experience</ModuleHeader>
                  <Container>
                    <FormRow>
                      <Col>
                        <Field
                          name="care_experience_years"
                          component={StandardRadioGroup}
                          title="How many years experience do you have in the care/social sector? *"
                          options={taxonomiesByClassification.care_experience_years.map(
                            ({ taxon_id, value }) => ({
                              label: value,
                              value: taxon_id,
                            }),
                          )}
                          validate={[this.requiredIfNotAdmin]}
                          changes={diff.care_experience_years}
                        />
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col>
                        <Field
                          name="condition_experience"
                          component={StandardTupleCheckboxGroup}
                          title="What conditions do you have experience with? *"
                          help="Please specify how much experience you have with each (excluding training)"
                          keyMap={['condition', 'experience']}
                          options={taxonomiesByClassification.condition.map(
                            ({ taxon_id, value }) => ({
                              key: taxon_id,
                              label: value,
                            }),
                          )}
                          dropdownOptions={
                            taxonomiesByClassification.condition_experience
                          }
                          transform={(condition, experience) => ({
                            condition,
                            experience,
                          })}
                          validate={[this.requiredListIfNotAdmin]}
                        />
                      </Col>
                    </FormRow>
                    {isStaff &&
                      ['care_experience_years'].some(field =>
                        profile.fields_changed.includes(field),
                      ) && (
                        <FormRow>
                          <Col>
                            <ButtonGroup>
                              <Button
                                type="button"
                                name="approve"
                                onClick={() => {
                                  sectionApprove(['care_experience_years']);
                                }}
                              >
                                Approve
                              </Button>
                              <Button
                                type="button"
                                className="danger"
                                name="reject"
                                onClick={() => {
                                  sectionReject(['care_experience_years']);
                                }}
                              >
                                Reject changes
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </FormRow>
                      )}
                  </Container>
                </Module>,

                <Module id="about">
                  <ModuleHeader>About me</ModuleHeader>
                  <Container>
                    <FormRow>
                      <Col>
                        <p>
                          Please provide details of your skills and experience,
                          both personal and professional, which you could bring to
                          the CAREGiver role.
                        </p>
                        <Field
                          name="bio"
                          component={StandardTextarea}
                          title="Please write a paragraph or two for your profile"
                          help={
                            <div>
                              This will be shared with Home Instead franchised
                              offices who are looking for CAREGivers.
                              <br />
                              <br />
                              Some examples of interesting and useful content
                              include:
                              <ul>
                                <li>
                                  - An introduction to yourself, why you became a
                                  CAREGiver and how long you have been in the
                                  field
                                </li>
                                <li>
                                  - What you enjoy about your work as a CAREGiver
                                </li>
                                <li>
                                  - Real life examples of care that you have
                                  provided in the past, especially examples of
                                  types of roles and day to day activities
                                </li>
                                <li>
                                  - Any specific area of expertise or interest
                                  that you have, or any special achievements you
                                  have had in your CAREGiver career
                                </li>
                                <li>
                                  - Any roles you have held in care-related fields
                                  (e.g. nursing, social care) and any care
                                  experience you have outside of work (such as
                                  caring for family members)
                                </li>
                              </ul>
                            </div>
                          }
                          changes={diff.bio}
                        />
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col>
                        <Field
                          name="hobbies"
                          component={StandardMultiCheckboxGroup}
                          title="What do you enjoy in your spare time?"
                          help="Please provide your hobbies and interests so we can match your interests with those of the client"
                          options={taxonomiesByClassification.hobbies.map(
                            ({ taxon_id, value }) => ({
                              key: taxon_id,
                              label: value,
                            }),
                          )}
                        />
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col>
                        <Field
                          name="other_hobbies"
                          component={StandardInput}
                          title="Other interests not included above? (Optional)"
                        />
                      </Col>
                    </FormRow>
                    {isStaff &&
                      ['bio'].some(field =>
                        profile.fields_changed.includes(field),
                      ) && (
                        <FormRow>
                          <Col>
                            <ButtonGroup>
                              <Button
                                type="button"
                                name="approve"
                                onClick={() => {
                                  sectionApprove(['bio']);
                                }}
                              >
                                Approve
                              </Button>
                              <Button
                                type="button"
                                className="danger"
                                name="reject"
                                onClick={() => {
                                  sectionReject(['bio']);
                                }}
                              >
                                Reject changes
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </FormRow>
                      )}
                  </Container>
                </Module>,

                <Module id="personal">
                  <ModuleHeader>Personal</ModuleHeader>
                  <Container>
                    <FormRow>
                      <Col>
                        <Field
                          name="languages"
                          component={StandardTupleCheckboxAdder}
                          title="What languages do you speak? *"
                          help={
                            "Level of English required" + (
                            countryPreference.includes('wls')
                            ? ". Please also enter level of Welsh if interested in jobs in Wales."
                            : ""
                          )}
                          additionType="language"
                          keyMap={['language', 'level']}
                          options={_.sortBy(
                            taxonomiesByClassification.languages,
                            'value',
                          ).map(({ taxon_id, value }) => ({
                            key: taxon_id,
                            value: value,
                          }))}
                          dropdownOptions={
                            taxonomiesByClassification.language_level
                          }
                          transform={(language, level) => ({
                            language,
                            level,
                          })}
                          validate={[
                            this.englishRequiredIfNotAdmin,
                            this.requiredListIfNotAdmin,
                          ]}
                        />
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col>
                        <Field
                          name="driving_license"
                          component={StandardRadioGroup}
                          title="Do you hold a full current driving licence?"
                          options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                          ]}
                          changes={diff.driving_license}
                        />
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col>
                        <Field
                          name="car"
                          component={StandardRadioGroup}
                          title="Do you have access to a car or alternative reliable transport?"
                          options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                          ]}
                        />
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col>
                        <Field
                          name="car_insurance"
                          component={StandardRadioGroup}
                          title="Do you have business insurance for your vehicle?"
                          help="If as part of your role you drive one of your clients, you will need to have business insurance. You will need to provide proof of this."
                          options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                          ]}
                        />
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col>
                        <Field
                          name="likes_animals"
                          component={StandardRadioGroup}
                          title="Are you happy to work with pets?"
                          options={taxonomiesByClassification.likes_animals.map(
                            ({ taxon_id, value }) => ({
                              value: taxon_id,
                              label: value,
                            }),
                          )}
                        />
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col>
                        <Field
                          name="smoker"
                          component={StandardRadioGroup}
                          title="Do you smoke?"
                          options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                          ]}
                        />
                      </Col>
                    </FormRow>
                    {isStaff &&
                      ['driving_license'].some(field =>
                        profile.fields_changed.includes(field),
                      ) && (
                        <FormRow>
                          <Col>
                            <ButtonGroup>
                              <Button
                                type="button"
                                name="approve"
                                onClick={() => {
                                  sectionApprove(['driving_license']);
                                }}
                              >
                                Approve
                              </Button>
                              <Button
                                type="button"
                                className="danger"
                                name="reject"
                                onClick={() => {
                                  sectionReject(['driving_license']);
                                }}
                              >
                                Reject changes
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </FormRow>
                      )}
                  </Container>
                </Module>,

                <Module id="dbs">
                  <Row>
                    <Col md={9} xs={9}>
                      <ModuleHeader>
                        Regulated Work with Children and/or Adults
                        {isStaff && (
                          <StyledFieldTitleTag>Proof needed</StyledFieldTitleTag>
                        )}
                      </ModuleHeader>
                    </Col>
                  </Row>
                  <Container>
                    <FormRow>
                      <Col>
                        <p>
                          The positions you will be applying for with Home Instead offices
                          are exempt from the provision of Section 4 of the Rehabilitation
                          of Offenders Act 1974. This means that you are not entitled by law
                          to withhold information about any civil or criminal convictions, which
                          for other purposes are “spent” under the provisions of the Act.
                        </p>
                        <p>
                          If you are successful with this application and receive a job offer from
                          a Home Instead office, any failure to disclose such information could
                          result in withdrawal of offer, dismissal or disciplinary action from
                          them as your employer. Any information will be completely confidential
                          and will be considered only in relation to an application.
                        </p>
                        <p>
                          In view of this, please answer the following questions:
                        </p>
                      </Col>
                    </FormRow>

                    <FormRow>
                      <Col>
                        <Field
                          name="dbs_check.self_conviction"
                          component={StandardRadioGroup}
                          title="Do you have any previous civil or criminal convictions, cautions, reprimands, final warnings or any pending prosecutions? *"
                          help={`If yes, please detail offence(s) including date(s) and sentence(s) where relevant and email to ${JOBS_MAIL}`}
                          options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                          ]}
                          validate={[this.requiredBooleanIfNotAdmin]}
                          changes={diff['dbs_check.self_conviction']}
                          {...(isStaff
                            ? {
                                pick: (value, picked) => {
                                  this.togglePickValue(
                                    'dbs_check',
                                    value,
                                    picked,
                                    'self_conviction',
                                  );
                                },
                              }
                            : null)}
                        />
                      </Col>
                    </FormRow>

                    <FormRow>
                      <Col>
                        <Field
                          name="dbs_check.self_barred"
                          component={StandardRadioGroup}
                          title="I can confirm that I am not barred from working with vulnerable adults or children or subject to sanctions imposed by a regulatory body. *"
                          options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                          ]}
                          validate={[this.requiredBooleanIfNotAdmin]}
                          changes={diff['dbs_check.self_barred']}
                          {...(isStaff
                            ? {
                                pick: (value, picked) => {
                                  this.togglePickValue(
                                    'dbs_check',
                                    value,
                                    picked,
                                    'self_barred',
                                  );
                                },
                              }
                            : null)}
                        />
                      </Col>
                    </FormRow>

                  </Container>

                  {
                    ['eng', 'wls'].some(country => countryPreference.includes(country)) && [
                    <Row>
                      <Col md={9} xs={9}>
                        <ModuleSubheader>
                          Disclosure and Barring Service Check (England & Wales)
                        </ModuleSubheader>
                      </Col>
                      {isStaff && (
                        <Col md={3} xs={3}>
                          <StatusIndicator
                            status={
                              hiComplianceFields.dbs === true
                                ? 'dbs_compliant'
                                : 'dbs_non-compliant'
                            }
                          />
                        </Col>
                      )}
                    </Row>,

                    <Container>

                      {isStaff && [
                        <Row>
                          <Col md={6} xs={6}>
                            <StatusIndicator status={dbsState} />
                          </Col>
                        </Row>,
                        <Row>
                          <Col md={6} xs={6}>
                            <p>
                              <strong>Carer lastname</strong> (must match DBS):{' '}
                              {carer.lastname}
                            </p>
                          </Col>
                        </Row>,
                        <Row>
                          <Col md={6} xs={6}>
                            <p>
                              <strong>DBS Last Checked Online</strong>:{' '}
                              {dbsDate ? moment(dbsDate).format('HH:mm, Do MMMM YYYY') : 'N/A'}
                            </p>
                          </Col>
                        </Row>
                      ]}

                      <FormRow>
                        <Col md={8} xs={12}>
                          <Field
                            name="dbs_check.certificate_id"
                            component={StandardInput}
                            title="What is your DBS certificate number?"
                            changes={diff['dbs_check.certificate_id']}
                            {...(isStaff
                              ? {
                                  pick: (value, picked) => {
                                    this.togglePickValue(
                                      'dbs_check',
                                      value,
                                      picked,
                                      'certificate_id',
                                    );
                                  },
                                }
                              : null)}
                          />
                        </Col>
                        {isStaff && dbsNumber && (
                          <Col
                            md={4}
                            xs={12}
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                          >
                            <DBSCheckContainer
                              dob={dbsDOB}
                              lastname={carer.lastname}
                              certificateId={dbsNumber}
                            />
                          </Col>
                        )}
                      </FormRow>

                      {dbsNumber && [
                        <FormRow key="issue-date">
                          <Col xs={12} md={6}>
                            <Field
                              name="dbs_check.issue_date"
                              past
                              component={StandardDateInput}
                              title="What is the issue date of your DBS? *"
                              validate={[required, validDate]}
                              changes={diff['dbs_check.issue_date']}
                              {...(isStaff
                                ? {
                                    pick: (value, picked) => {
                                      this.togglePickValue(
                                        'dbs_check',
                                        value,
                                        picked,
                                        'issue_date',
                                      );
                                    },
                                  }
                                : null)}
                            />
                          </Col>
                        </FormRow>,
                        <FormRow key="dob">
                          <Col xs={12} md={6}>
                            <Field
                              name="dbs_check.dob"
                              past
                              component={StandardDateInput}
                              title="What is the date of birth on your certificate? *"
                              validate={[required, validDate]}
                              changes={diff['dbs_check.dob']}
                              {...(isStaff
                                ? {
                                    pick: (value, picked) => {
                                      this.togglePickValue(
                                        'dbs_check',
                                        value,
                                        picked,
                                        'dob',
                                      );
                                    },
                                  }
                                : null)}
                            />
                          </Col>
                        </FormRow>,
                        <FormRow key="registered-online">
                          <Col>
                            <Field
                              name="dbs_check.registered_online"
                              component={StandardRadioGroup}
                              title="Is your DBS Certificate registered online? *"
                              options={[
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                              ]}
                              validate={[this.requiredBooleanIfNotAdmin]}
                              changes={diff['dbs_check.registered_online']}
                              {...(isStaff
                                ? {
                                    pick: (value, picked) => {
                                      this.togglePickValue(
                                        'dbs_check',
                                        value,
                                        picked,
                                        'registered_online',
                                      );
                                    },
                                  }
                                : null)}
                            />
                          </Col>
                        </FormRow>,
                      ]}

                      {isStaff &&
                        dbsNumber && [
                          <FormRow key="children">
                            <Col>
                              <Field
                                name="dbs_check.children"
                                component={StandardRadioGroup}
                                title="Does the DBS cover children's workforce? (ADMIN ONLY)"
                                help="All checks must be enhanced and cover Adult's workforce"
                                options={[
                                  { value: true, label: 'Yes' },
                                  { value: false, label: 'No' },
                                ]}
                                changes={diff['dbs_check.children']}
                                {...(isStaff
                                  ? {
                                      pick: (value, picked) => {
                                        this.togglePickValue(
                                          'dbs_check',
                                          value,
                                          picked,
                                          'children',
                                        );
                                      },
                                    }
                                  : null)}
                              />
                            </Col>
                          </FormRow>,
                          <FormRow key="conviction">
                            <Col>
                              <Field
                                name="dbs_check.conviction"
                                component={StandardRadioGroup}
                                title="Are there any outcomes (e.g. convictions) on the DBS? (ADMIN ONLY)"
                                options={[
                                  { value: true, label: 'Yes' },
                                  { value: false, label: 'No' },
                                ]}
                                changes={diff['dbs_check.conviction']}
                                {...(isStaff
                                  ? {
                                      pick: (value, picked) => {
                                        this.togglePickValue(
                                          'dbs_check',
                                          value,
                                          picked,
                                          'conviction',
                                        );
                                      },
                                    }
                                  : null)}
                              />
                            </Col>
                          </FormRow>,
                        ]}
                      </Container>,
                    ]}

                  <Container>
                    {isStaff &&
                      ['dbs_check'].some(field =>
                        profile.fields_changed.includes(field),
                      ) && (
                        <FormRow>
                          <Col>
                            <ButtonGroup>
                              <Button
                                name="approve"
                                type="button"
                                onClick={() => {
                                  itemApprove(['dbs_check'], this.state.picked);
                                }}
                              >
                                Approve
                              </Button>
                              <Button
                                type="button"
                                className="danger"
                                name="reject"
                                onClick={() => {
                                  sectionReject(['dbs_check']);
                                }}
                              >
                                Reject changes
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </FormRow>
                      )}
                  </Container>

                  {
                    countryPreference.includes('sct') && [
                    <Row>
                      <Col md={9} xs={9}>
                        <ModuleSubheader>
                          Disclosure Scotland Protecting Vulnerable Groups (Scotland)
                        </ModuleSubheader>
                      </Col>
                    </Row>,
                    <Container>
                      <FormRow>
                        <Col>
                          <p>
                            This is required for work in Scotland only. It must be
                            an <strong>Enhanced</strong> PVG.
                          </p>
                        </Col>
                      </FormRow>
                      <FormRow>
                        <Col md={8} xs={12}>
                          <Field
                            name="scotland_pvg.certificate_id"
                            component={StandardInput}
                            title="What is your Enhanced PVG certificate number?"
                            changes={diff['scotland_pvg.certificate_id']}
                            {...(isStaff
                              ? {
                                  pick: (value, picked) => {
                                    this.togglePickValue(
                                      'scotland_pvg',
                                      value,
                                      picked,
                                      'certificate_id',
                                    );
                                  },
                                }
                              : null)}
                          />
                        </Col>
                      </FormRow>
                      {isStaff && [
                        <FormRow>
                          <Col xs={12} md={6}>
                            <Field
                              name="scotland_pvg.last_checked_date"
                              component={StandardDateInput}
                              title="Date last checked PVG? (ADMIN ONLY)"
                              help="Admin: Enter the date you verified the applicant's information on copy of the certificate"
                              validate={[validDate]}
                              changes={diff['scotland_pvg.last_checked_date']}
                              {...(isStaff
                                ? {
                                    pick: (value, picked) => {
                                      this.togglePickValue(
                                        'scotland_pvg',
                                        value,
                                        picked,
                                        'last_checked_date',
                                      );
                                    },
                                  }
                                : null)
                              }
                            />
                          </Col>
                        </FormRow>,
                      ]}
                      {isStaff &&
                        ['scotland_pvg'].some(field =>
                          profile.fields_changed.includes(field),
                        ) && (
                          <FormRow>
                            <Col>
                              <ButtonGroup>
                                <Button
                                  name="approve"
                                  type="button"
                                  onClick={() => {
                                    itemApprove(['scotland_pvg'], this.state.picked);
                                  }}
                                >
                                  Approve
                                </Button>
                                <Button
                                  type="button"
                                  className="danger"
                                  name="reject"
                                  onClick={() => {
                                    sectionReject(['scotland_pvg']);
                                  }}
                                >
                                  Reject changes
                                </Button>
                              </ButtonGroup>
                            </Col>
                          </FormRow>
                        )}
                    </Container>,
                  ]}

                  {
                    countryPreference.includes('nir') && [
                    <Row>
                      <Col md={9} xs={9}>
                        <ModuleSubheader>
                          AccessNI Enhanced Disclosure Certificate (NIR)
                        </ModuleSubheader>
                      </Col>
                    </Row>,
                    <Container>
                      <FormRow>
                        <Col>
                          <p>
                            This is required for work in Northern Ireland only. It must be
                            an <strong>Enhanced</strong> Disclosure Certificate registered with AccessNI.
                          </p>
                        </Col>
                      </FormRow>
                      <FormRow>
                        <Col md={8} xs={12}>
                          <Field
                            name="ni_access_ni.certificate_id"
                            component={StandardInput}
                            title="What is your Enhanced AccessNI certificate number?"
                            changes={diff['ni_access_ni.certificate_id']}
                          />
                        </Col>
                      </FormRow>
                      {isStaff && [
                        <FormRow>
                          <Col xs={12} md={6}>
                            <Field
                              name="ni_access_ni.last_checked_date"
                              component={StandardDateInput}
                              title="Date last checked AccessNI? (ADMIN ONLY)"
                              help="Admin: Enter the date you verified the applicant's information on copy of the certificate"
                              validate={[validDate]}
                              changes={diff['ni_access_ni.last_checked_date']}
                            />
                          </Col>
                        </FormRow>,
                      ]}
                    </Container>,
                  ]}

                </Module>,

                <Module id="social_care">
                  <Row>
                    <Col md={9} xs={9}>
                      <ModuleHeader>
                        Social Care Services
                        {isStaff && (
                          <StyledFieldTitleTag>Proof needed</StyledFieldTitleTag>
                        )}
                      </ModuleHeader>
                    </Col>
                  </Row>
                  <Container>
                    <FormRow>
                      <Col>
                        <p>
                          Scotland, Wales and NIR require specific registration with their
                          national social care services to perform home live-in care.
                        </p>
                      </Col>
                    </FormRow>
                  </Container>

                  {
                    countryPreference.includes('sct') && [
                    <Row>
                      <Col md={9} xs={9}>
                        <ModuleSubheader>
                          Scottish Social Services Council (SSSC)
                        </ModuleSubheader>
                      </Col>
                    </Row>,
                    <Container>
                      <FormRow>
                        <Col>
                          <p>
                            This is required for work in Scotland only. It must be
                            a registration for <strong>"Support work and care at home service"</strong>.
                          </p>
                        </Col>
                      </FormRow>

                      <FormRow>
                        <Col md={8} xs={12}>
                          <Field
                            name="scotland_social_services.certificate_id"
                            component={StandardInput}
                            title="What is your SSSC registration number?"
                            changes={diff['scotland_social_services.certificate_id']}
                            {...(isStaff
                              ? {
                                  pick: (value, picked) => {
                                    this.togglePickValue(
                                      'scotland_social_services',
                                      value,
                                      picked,
                                      'certificate_id',
                                    );
                                  },
                                }
                              : null)}
                          />
                        </Col>
                      </FormRow>

                      {isStaff && [
                        <FormRow>
                          <Col xs={12} md={6}>
                            <Field
                              name="scotland_social_services.expiry_date"
                              component={StandardDateInput}
                              title="SSSC expiry date of registration? (ADMIN ONLY)"
                              validate={[validDate]}
                              changes={diff['scotland_social_services.expiry_date']}
                              from={2019}
                              {...(isStaff
                                ? {
                                    pick: (value, picked) => {
                                      this.togglePickValue(
                                        'scotland_social_services',
                                        value,
                                        picked,
                                        'expiry_date',
                                      );
                                    },
                                  }
                                : null)
                              }
                            />
                          </Col>
                        </FormRow>,
                      ]}

                      {isStaff &&
                        ['scotland_social_services'].some(field =>
                          profile.fields_changed.includes(field),
                        ) && (
                          <FormRow>
                            <Col>
                              <ButtonGroup>
                                <Button
                                  name="approve"
                                  type="button"
                                  onClick={() => {
                                    itemApprove(['scotland_social_services'], this.state.picked);
                                  }}
                                >
                                  Approve
                                </Button>
                                <Button
                                  type="button"
                                  className="danger"
                                  name="reject"
                                  onClick={() => {
                                    sectionReject(['scotland_social_services']);
                                  }}
                                >
                                  Reject changes
                                </Button>
                              </ButtonGroup>
                            </Col>
                          </FormRow>
                        )
                      }
                    </Container>,
                  ]}

                  {
                    countryPreference.includes('wls') && [
                    <Row>
                      <Col md={9} xs={9}>
                        <ModuleSubheader>
                          Social Care Wales (SCW)
                        </ModuleSubheader>
                      </Col>
                    </Row>,

                    <Container>

                      <FormRow>
                        <Col>
                          <p>
                            This is required for work in Wales only.
                            You will have to have completed your All Wales Induction
                            Framework training before being able to register.
                          </p>
                        </Col>
                      </FormRow>

                      <FormRow>
                        <Col md={8} xs={12}>
                          <Field
                            name="wales_social_care.certificate_id"
                            component={StandardInput}
                            placeholder="e.g. W/0000000"
                            title="What is your SCW registration number?"
                            changes={diff['wales_social_care.certificate_id']}
                            {...(isStaff
                              ? {
                                  pick: (value, picked) => {
                                    this.togglePickValue(
                                      'wales_social_care',
                                      value,
                                      picked,
                                      'certificate_id',
                                    );
                                  },
                                }
                              : null)}
                          />
                        </Col>
                      </FormRow>

                      {isStaff && [
                        <FormRow>
                          <Col xs={12} md={6}>
                            <Field
                              name="wales_social_care.expiry_date"
                              component={StandardDateInput}
                              title="SCW expiry date of registration? (ADMIN ONLY)"
                              help="Admin: Date of expiry is 3 years after date of registration"
                              validate={[validDate]}
                              changes={diff['wales_social_care.expiry_date']}
                              {...(isStaff
                                ? {
                                    pick: (value, picked) => {
                                      this.togglePickValue(
                                        'wales_social_care',
                                        value,
                                        picked,
                                        'expiry_date',
                                      );
                                    },
                                  }
                                : null)
                              }
                            />
                          </Col>
                        </FormRow>,
                      ]}

                      {isStaff &&
                        ['wales_social_care'].some(field =>
                          profile.fields_changed.includes(field),
                        ) && (
                          <FormRow>
                            <Col>
                              <ButtonGroup>
                                <Button
                                  name="approve"
                                  type="button"
                                  onClick={() => {
                                    itemApprove(['wales_social_care'], this.state.picked);
                                  }}
                                >
                                  Approve
                                </Button>
                                <Button
                                  type="button"
                                  className="danger"
                                  name="reject"
                                  onClick={() => {
                                    sectionReject(['wales_social_care']);
                                  }}
                                >
                                  Reject changes
                                </Button>
                              </ButtonGroup>
                            </Col>
                          </FormRow>
                        )}
                    </Container>,
                  ]}

                  {
                    countryPreference.includes('nir') && [
                    <Row>
                      <Col md={9} xs={9}>
                        <ModuleSubheader>
                          Northern Ireland Social Care Council (NISCC)
                        </ModuleSubheader>
                      </Col>
                    </Row>,

                    <Container>

                      <FormRow>
                        <Col>
                          <p>
                            This is required for work in Northern Ireland only.
                          </p>
                        </Col>
                      </FormRow>

                      <FormRow>
                        <Col md={8} xs={12}>
                          <Field
                            name="ni_social_care.certificate_id"
                            component={StandardInput}
                            title="What is your NISCC registration number?"
                            changes={diff['ni_social_care.certificate_id']}
                          />
                        </Col>
                      </FormRow>

                    </Container>,
                  ]}

                </Module>,


                <Module id="rtw">
                  <Row>
                    <Col md={9} xs={9}>
                      <ModuleHeader>
                        Right to Work
                        {isStaff && (
                          <StyledFieldTitleTag>Proof needed</StyledFieldTitleTag>
                        )}
                      </ModuleHeader>
                    </Col>
                    {isStaff && (
                      <Col md={3} xs={3}>
                        <StatusIndicator
                          status={
                            hiComplianceFields.right_to_work === true
                              ? 'right_to_work_compliant'
                              : 'right_to_work_non-compliant'
                          }
                        />
                      </Col>
                    )}
                  </Row>
                  <Container>

                    <FormRow>
                      <Col>
                        <Field
                          name="right_to_work.self_permit"
                          component={StandardRadioGroup}
                          title="Do you need a permit in order to work in the UK? *"
                          options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                          ]}
                          validate={[this.requiredBooleanIfNotAdmin]}
                          changes={diff['right_to_work.self_permit']}
                          {...(isStaff
                            ? {
                                pick: (value, picked) => {
                                  this.togglePickValue(
                                    'right_to_work',
                                    value,
                                    picked,
                                    'self_permit',
                                  );
                                },
                              }
                            : null)}
                        />
                      </Col>
                    </FormRow>

                    <FormRow>
                      <Col>
                        <Field
                          name="right_to_work.validation"
                          component={StandardRadioGroup}
                          title="What evidence do you have to demonstrate a right to work in the UK?"
                          options={taxonomiesByClassification.right_to_work.map(
                            ({ taxon_id, value }) => ({
                              label: value,
                              value: taxon_id,
                            }),
                          )}
                          changes={diff['right_to_work.validation']}
                          {...(isStaff
                            ? {
                                pick: (value, picked) => {
                                  this.togglePickValue(
                                    'right_to_work',
                                    value,
                                    picked,
                                    'validation',
                                  );
                                },
                              }
                            : null)}
                        />
                      </Col>
                    </FormRow>

                    {isStaff && rtwCertification && [
                      <FormRow>
                        <Col xs={12} md={6}>
                          <Field
                            name="right_to_work.certificate_id"
                            component={StandardInput}
                            title="Home Office Reference Number (ADMIN ONLY)"
                            changes={diff['right_to_work.certificate_id']}
                            {...(isStaff
                              ? {
                                  pick: (value, picked) => {
                                    this.togglePickValue(
                                      'right_to_work',
                                      value,
                                      picked,
                                      'certificate_id',
                                    );
                                  },
                                }
                              : null)}
                          />
                        </Col>
                      </FormRow>,
                      <FormRow>
                        <Col xs={12} md={6}>
                          <Field
                            name="right_to_work.last_checked_date"
                            component={StandardDateInput}
                            title="Date Home Office check submitted (ADMIN ONLY)"
                            validate={[validDate]}
                            changes={diff['right_to_work.last_checked_date']}
                            from={2010}
                            {...(isStaff
                              ? {
                                  pick: (value, picked) => {
                                    this.togglePickValue(
                                      'right_to_work',
                                      value,
                                      picked,
                                      'last_checked_date',
                                    );
                                  },
                                }
                              : null)}
                          />
                        </Col>
                      </FormRow>,
                      <FormRow>
                        <Col xs={12} md={6}>
                          <Field
                            name="right_to_work.settled_status_confirmed"
                            component={StandardRadioGroup}
                            title="Confirmed settled status (ADMIN ONLY)"
                            options={[
                              { value: true, label: 'Yes' },
                              { value: false, label: 'No' },
                            ]}
                            changes={diff['right_to_work.settled_status_confirmed']}
                            {...(isStaff
                              ? {
                                  pick: (value, picked) => {
                                    this.togglePickValue(
                                      'right_to_work',
                                      value,
                                      picked,
                                      'settled_status_confirmed',
                                    );
                                  },
                                }
                              : null)}
                          />
                        </Col>
                      </FormRow>
                    ]}

                    {rtwExpires && (
                      <FormRow>
                        <Col xs={12} md={6}>
                          <Field
                            name="right_to_work.expiry_date"
                            component={StandardDateInput}
                            title="When does your right to work expire?"
                            validate={[validDate]}
                            changes={diff['right_to_work.expiry_date']}
                            from={2015}
                            {...(isStaff
                              ? {
                                  pick: (value, picked) => {
                                    this.togglePickValue(
                                      'right_to_work',
                                      value,
                                      picked,
                                      'expiry_date',
                                    );
                                  },
                                }
                              : null)}
                          />
                        </Col>
                      </FormRow>
                    )}

                    {isStaff &&
                      ['right_to_work'].some(field =>
                        profile.fields_changed.includes(field),
                      ) && (
                        <FormRow>
                          <Col>
                            <ButtonGroup>
                              <Button
                                name="approve"
                                type="button"
                                onClick={() => {
                                  itemApprove(
                                    ['right_to_work'],
                                    this.state.picked,
                                  );
                                }}
                              >
                                Approve
                              </Button>
                              <Button
                                type="button"
                                className="danger"
                                name="reject"
                                onClick={() => {
                                  sectionReject(['right_to_work']);
                                }}
                              >
                                Reject changes
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </FormRow>
                      )}
                    {!valid && submitFailed && (
                      <FormRow>
                        <Col>
                          <InlineValidationError>
                            Please make sure you have completed all the required
                            fields correctly.
                          </InlineValidationError>
                        </Col>
                      </FormRow>
                    )}
                  </Container>
                </Module>
              ]}

              {!isStaff && <Button type="submit">Save profile changes</Button>}

              {isStaff && (
                <ButtonGroup rightAlign>
                  <Button className="alt">Save Profile</Button>
                  {profile.live_status === 'draft' && (
                    <PortalWithState closeOnOutsideClick closeOnEsc>
                      {({ openPortal, closePortal, isOpen, portal }) => [
                        <Button
                          key="foo"
                          onClick={openPortal}
                          className={`danger ${isOpen ? 'open' : ''}`}
                          type="button"
                        >
                          Query Profile
                        </Button>,
                        portal(
                          <Modal key="query-modal">
                            <ModuleHeader>Query Changes</ModuleHeader>
                            <Container>
                              <Row>
                                <Col>
                                  <Message
                                    placeholder={`Please enter a message for ${
                                      profile.user_name
                                    }.`}
                                    onSubmit={queryProfile}
                                    buttonText="Query Changes"
                                    closePortal={closePortal}
                                  />
                                </Col>
                              </Row>
                            </Container>
                          </Modal>,
                        ),
                      ]}
                    </PortalWithState>
                  )}
                </ButtonGroup>
              )}
            </Col>

            {isStaff && profile.live_status === 'draft' && (
              <Col xs={12} md={3}>
                <Module>
                  <ModuleHeader>Profile Changes</ModuleHeader>
                  <Container>
                    <Row>
                      <Col>
                        {profile.fields_changed.map(field => (
                          <p key={field}>
                            {
                              (
                                taxonomiesByClassification.profile_needs_approval.find(
                                  option => option.taxon_id === field,
                                ) || { value: field.replace('_', ' ') }
                              ).value // Handle old profile changes pre-taxonomy
                            }
                          </p>
                        ))}
                      </Col>
                    </Row>
                  </Container>
                </Module>
              </Col>
            )}
          </Row>
        </Container>
      </form>
    );
  }
}

export default CarerEditProfile;
