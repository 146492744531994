// @flow
import React, { Component } from 'react';
// $FlowFixMe
import { Field } from 'redux-form';
import styled from 'styled-components';

import { Container, Col, Row } from 'components/grid';
import CollapsibleSection from 'components/global/dynamic/CollapsibleSection';
import { ModuleHeader, ModuleMeta } from 'components/global/layout/Module';
import FormRow from 'components/forms/FormRow';
import Button from 'components/buttons/Button';
import StandardInput from 'components/forms/StandardInput';
import StandardSelect from 'components/forms/StandardSelect';
import StandardMultiSelect from 'components/forms/StandardMultiSelect';
import { taxonomyToMultiselect } from 'components/helpers/taxonomyHelpers';

import type { TaxonomiesClassificationType } from 'shared/types';

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

type Props = {
  handleSubmit: Object => void,
  reset: Object => void,
  taxonomiesForClassification: TaxonomiesClassificationType,
};

class CarerSearchForm extends Component<Props, { expanded: boolean }> {
  state = {
    expanded: false,
  };

  render() {
    const { handleSubmit, reset, taxonomiesForClassification } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Container>

          <Row>
            <Col xs={12} md={4}>
              <StyledHeader>Find carers</StyledHeader>
            </Col>
            <Col xs={12} md={8}>
              <ModuleMeta>
                <div role="button" onClick={reset}>
                  Reset filters
                </div>
              </ModuleMeta>
            </Col>
          </Row>

          <FormRow>
            <Col xs={12} md={6}>
              <Field component={StandardInput} label="Carer name" name="name" />
            </Col>
            <Col xs={6} md={3}>
              <Field
                label="Postcode"
                component={StandardInput}
                name="postcode"
              />
            </Col>
            <Col xs={6} md={3}>
              <Field
                label="Distance (Miles)"
                component={StandardInput}
                name="distance"
              />
            </Col>
          </FormRow>
        </Container>

        <CollapsibleSection
          subheader="Show more filters"
          id="extra-filters"
          maxHeight="800px"
          openByDefault={false}
        >
          <Container>
            <FormRow key="gender_animals">
              <Col xs={12} md={6}>
                <Field component={StandardSelect} name="gender" label="Gender">
                  <option value="">Any gender</option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                </Field>
              </Col>

              <Col xs={12} md={6}>
                <Field
                  component={StandardMultiSelect}
                  name="likes_animals"
                  label="Likes animals?"
                  options={taxonomyToMultiselect(
                    taxonomiesForClassification.likes_animals,
                  )}
                />
              </Col>
            </FormRow>

            <FormRow key="driver">
              <Col xs={12} md={6}>
                <Field
                  id="is_driver"
                  component={StandardSelect}
                  name="is_driver"
                  label="Is driver?"
                >
                  <option value="">All carers</option>
                  <option value="true">Only drivers</option>
                  <option value="false">Only non-drivers</option>
                </Field>
              </Col>
              <Col xs={12} md={6}>
                <Field
                  id="has_car"
                  component={StandardSelect}
                  name="has_car"
                  label="Has car?"
                >
                  <option value="">All carers</option>
                  <option value="true">Has a car</option>
                  <option value="false">Doesn’t have a car</option>
                </Field>
              </Col>
            </FormRow>
          </Container>
        </CollapsibleSection>

        <Container>
          <FormRow>
            <Col xs={12} md={4}>
              <Button type="submit">Search</Button>
            </Col>
          </FormRow>
        </Container>
      </form>
    );
  }
}

export default CarerSearchForm;
