import { push } from 'react-router-redux';
import { select, put, call, takeEvery, takeLatest } from 'redux-saga/effects';

import SuperCarersClient from 'clients/SuperCarersClient';
import { isStaff } from 'shared/helpers/accountHelpers';

import AccountConstants from 'shared/constants/AccountConstants';
import { getRedirect } from 'shared/selectors/accountSelectors';
import { refreshAccount } from '_actions/accountActions';

export function* authorize(username, password, isStaff = false) {
  const url = isStaff ? '/user/staff_login/' : '/user/login/';
  const successAction = isStaff
    ? AccountConstants.STAFF_LOGIN_SUCCESS
    : AccountConstants.LOGIN_SUCCESS;
  const failAction = isStaff
    ? AccountConstants.STAFF_LOGIN_FAILURE
    : AccountConstants.LOGIN_FAILURE;

  try {
    const response = yield call(SuperCarersClient.post, url, {
      username,
      password,
    });
    yield put({
      type: successAction,
      data: response.data,
    });
    let redirect = yield select(getRedirect);
    if (!redirect) {
      redirect = '/home/';
    }
    yield put({ type: AccountConstants.SET_REDIRECT, data: null });
    yield put(push(redirect));
  } catch (error) {
    let msg;
    if (error.response && error.response.status === 401) {
      msg =
        'Unable to log in. Please check your email and password and try again.';
    } else {
      msg =
        'An unexpected error occurred. Please contact the Home Instead Live-in Care team.';
    }
    // TODO: Update all reducers to use payload instead of data
    yield put({
      type: failAction,
      payload: { _error: msg },
    });
  } finally {
    // Clean up code goes here
  }
  return true;
}

export function* loginFlow(action) {
  if (
    action.type === AccountConstants.LOGIN ||
    action.type === AccountConstants.STAFF_LOGIN
  ) {
    // logging in with the login form
    const { email, password } = action.payload;
    yield call(
      authorize,
      email,
      password,
      action.type === AccountConstants.STAFF_LOGIN,
    );
  } else if (
    action.type === AccountConstants.GET_LOCAL_STORAGE_USER_DATA_SUCCESS ||
    action.type === AccountConstants.GET_LOCAL_STORAGE_STAFF_USER_DATA_SUCCESS
  ) {
    // otherwise load from local storage or account verify
    const successAction =
      action.type === AccountConstants.GET_LOCAL_STORAGE_USER_DATA_SUCCESS
        ? AccountConstants.LOGIN_SUCCESS
        : AccountConstants.STAFF_LOGIN_SUCCESS;

    yield put({ type: successAction, data: action.data });
    // then refresh the user account in case some details not in local storage have changed
    yield put(refreshAccount(action.data.id));
  }
}

export function* loginFlowListener() {
  yield takeLatest(
    [
      AccountConstants.LOGIN,
      AccountConstants.STAFF_LOGIN,
      AccountConstants.GET_LOCAL_STORAGE_USER_DATA_SUCCESS,
      AccountConstants.GET_LOCAL_STORAGE_STAFF_USER_DATA_SUCCESS,
    ],
    loginFlow,
  );
}

function* logoutFlow(action) {
  try {
    yield call(SuperCarersClient.post, '/user/logout/');
  } catch (error) {
    yield put({ type: AccountConstants.LOGOUT_FAILURE, error });
  } finally {
    yield put({ type: AccountConstants.LOGOUT_SUCCESS });
    yield put(push('/'));
  }
}

export function* logoutFlowListener() {
  yield takeLatest(AccountConstants.LOGOUT, logoutFlow);
}

export function* resetPasswordFlow(action) {
  const { token, password } = action;
  try {
    yield call(SuperCarersClient.request, 'POST', '/user/password/reset/', {
      body: { token, password },
    });
    // Password reset successfully
    yield put({
      type: AccountConstants.RESET_PASSWORD_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        message: 'Your password has now been reset.',
      },
    });
    yield put(push('/'));
  } catch (error) {
    if (error.response && error.response.status === 400) {
      let reason;
      if (error.response && error.response.data.message !== '') {
        reason = error.response.data.message;
      } else {
        reason =
          'There has been a problem resetting your password. Please contact the Supercarers team for more information.';
      }
      yield put({
        type: AccountConstants.RESET_PASSWORD_FAILURE,
        toast: {
          type: 'error',
          public: false,
          message: reason,
        },
      });
      return;
    }
    yield put({ type: AccountConstants.RESET_PASSWORD_FAILURE, error });
  }
}

export function* resetPasswordFlowListener() {
  yield takeLatest(AccountConstants.RESET_PASSWORD, resetPasswordFlow);
}

export function* verifyAccountFlow({ token, password }) {
  try {
    const response = yield call(SuperCarersClient.post, '/user/verify/', {
      token,
      password,
    });
    yield put({
      type: AccountConstants.ACCOUNT_VERIFY_SUCCESS,
      data: response.data,
    });
    yield put(push('/'));
  } catch (error) {
    if (error.response && error.response.status === 409) {
      yield put({
        type: AccountConstants.ACCOUNT_VERIFY_FAILURE,
        toast: {
          type: 'error',
          public: false,
          message: 'This account has already been created. Please log in.',
        },
      });
      yield put(push('/'));
    } else {
      yield put({
        type: AccountConstants.ACCOUNT_VERIFY_FAILURE,
        toast: {
          type: 'error',
          public: false,
          message: 'Unable to verify account. Please try again with a more complex password (see suggestions).',
        },
      });
    }
  }
}

export function* verifyAccountFlowListener() {
  yield takeLatest(AccountConstants.ACCOUNT_VERIFY, verifyAccountFlow);
}

export function* stopImpersonatingUser() {
  yield takeEvery(AccountConstants.IMPERSONATE_STOP, () => put(push('/')));
}

export function* getLocalStorageLoginDataListener() {
  yield takeLatest(
    AccountConstants.GET_LOCAL_STORAGE_LOGIN_DATA,
    getLocalStorageLoginData,
  );
}

export function* getLocalStorageLoginData(action) {
  let persistedUser;
  try {
    persistedUser = localStorage.getItem('user');
  } catch (e) {
    persistedUser = false;
  }

  if (persistedUser && JSON.parse(persistedUser)) {
    const userData = JSON.parse(persistedUser);
    const successAction = isStaff(userData)
      ? AccountConstants.GET_LOCAL_STORAGE_STAFF_USER_DATA_SUCCESS
      : AccountConstants.GET_LOCAL_STORAGE_USER_DATA_SUCCESS;
    yield put({
      type: successAction,
      data: userData,
    });
  }

  let persistedImpersonator;
  try {
    persistedImpersonator = localStorage.getItem('impersonator');
  } catch (e) {
    persistedImpersonator = false;
  }

  if (persistedImpersonator && JSON.parse(persistedImpersonator)) {
    yield put({
      type: AccountConstants.GET_LOCAL_STORAGE_IMPERSONATOR_DATA_SUCCESS,
      data: JSON.parse(persistedImpersonator),
    });
  }

  yield put({
    type: AccountConstants.GET_LOCAL_STORAGE_LOGIN_DATA_SUCCESS,
  });
}

function* checkIfVerified(action) {
  try {
    const response = yield call(SuperCarersClient.post, '/user/verified/', {
      token: action.token,
    });
    if (response.data.verified) {
      yield put(push('/'));
    }
  } catch (e) {
    yield put(push('/recover-password'));
    yield put({
      type: AccountConstants.CHECK_IF_VERIFIED_FAILURE,
      toast: {
        type: 'error',
        public: false,
        message:
          'Verification link has expired. Enter your email to receive a new link.',
      },
    });
  }

  yield put({
    type: AccountConstants.CHECK_IF_VERIFIED_SUCCESS,
  });
}

export function* checkIfVerifiedListener() {
  yield takeLatest(AccountConstants.CHECK_IF_VERIFIED, checkIfVerified);
}

export function* magicTokenLogin({ token }) {
  try {
    const response = yield call(
      SuperCarersClient.post,
      `/user/magic_token/${token}/`,
    );

    if (response.success) {
      yield put({
        type: AccountConstants.LOGIN_SUCCESS,
        data: response.data,
      });
    } else {
      throw response && response.message;
    }
    yield put({ type: AccountConstants.MAGIC_TOKEN_LOGIN_SUCCESS });
    yield put(push('/home/'));
  } catch (reason) {
    yield put({
      type: AccountConstants.MAGIC_TOKEN_LOGIN_FAILURE,
      reason,
    });
  }
}

export function* magicTokenLoginListener() {
  yield takeLatest(AccountConstants.MAGIC_TOKEN_LOGIN, magicTokenLogin);
}
