import ApiConstants from 'shared/constants/ApiConstants';
import FranchiseConstants from 'shared/constants/FranchiseConstants';

export function addFranchise(payload, redirect) {
  return {
    endpoint: '/franchise/',
    method: 'post',
    onError: { type: FranchiseConstants.FRANCHISE_ADD_FAILURE },
    onStart: { type: FranchiseConstants.FRANCHISE_ADD },
    onSuccess: {
      type: FranchiseConstants.FRANCHISE_ADD_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Reference added',
      },
    },
    payload,
    redirect,
    type: ApiConstants.API_REQUEST,
  };
}

export function updateFranchise(objectID, data, redirect = null) {
  return {
    endpoint: `/franchise/${objectID}/`,
    method: 'put',
    onError: { type: FranchiseConstants.FRANCHISE_UPDATE_FAILURE },
    onStart: { type: FranchiseConstants.FRANCHISE_UPDATE },
    onSuccess: {
      type: FranchiseConstants.FRANCHISE_UPDATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Franchise details updated',
      },
    },
    payload: data,
    redirect,
    type: ApiConstants.API_REQUEST,
  };
}

export function deleteFranchise(objectID) {
  return {
    endpoint: `/franchise/${objectID}/`,
    method: 'delete',
    onError: { type: FranchiseConstants.FRANCHISE_DELETE_FAILURE },
    onStart: { type: FranchiseConstants.FRANCHISE_DELETE },
    onSuccess: { type: FranchiseConstants.FRANCHISE_DELETE_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function getFranchiseById(objectID) {
  return {
    endpoint: `/franchise/${objectID}/`,
    method: 'get',
    onError: {
      type: FranchiseConstants.FRANCHISE_GET_BY_ID_FAILURE,
    },
    onStart: { type: FranchiseConstants.FRANCHISE_GET_BY_ID },
    onSuccess: { type: FranchiseConstants.FRANCHISE_GET_BY_ID_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function getAllFranchise() {
  return {
    endpoint: '/franchise/find/',
    method: 'get',
    onError: { type: FranchiseConstants.FRANCHISE_GET_ALL_FAILURE },
    onStart: { type: FranchiseConstants.FRANCHISE_GET_ALL },
    onSuccess: { type: FranchiseConstants.FRANCHISE_GET_ALL_SUCCESS },
    payload: {
      per_page: 1000,
      order_by_parameter: ['name'],
      order_by_direction: ['asc'],
      order_by_nulls_first: ['False']
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function searchFranchise(params, page) {
  const query = { ...params, page: page || 1, per_page: 10 };
  return {
    endpoint: '/franchise/find/',
    method: 'get',
    onError: { type: FranchiseConstants.FRANCHISE_SEARCH_FAILURE },
    onStart: { type: FranchiseConstants.FRANCHISE_SEARCH },
    onSuccess: { type: FranchiseConstants.FRANCHISE_SEARCH_SUCCESS },
    payload: query,
    type: ApiConstants.API_REQUEST,
  };
}

export function populateFranchiseWithID(franchiseID) {
  return {
    type: FranchiseConstants.POPULATE_FRANCHISE,
    franchiseID,
  };
}
