import styled from 'styled-components';

const BasicFlexContainer = styled.div`
  display: flex;
  background: ${({ background }) => background};
  border-bottom: ${({ borderBottom }) => borderBottom};
  color: ${({ color, theme }) => theme.colors[color]};
  align-items: ${({ align }) => align};
  height: ${({ height }) => height};
  justify-content: ${({ justify }) => justify};
  flex-direction: ${({ direction }) => direction};
  flex-wrap: ${({ wrap }) => wrap};
  font-weight: ${({ weight }) => weight};
  margin: ${({ margin }) => margin};
  min-height: ${({ minHeight }) => minHeight};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  > * {
    flex: ${({ flex }) => flex};
  }
  @media (min-width: ${props => props.theme.screen.small}px) {
    align-items: ${({ alignTablet }) => alignTablet};
    height: ${({ heightTablet }) => heightTablet};
    justify-content: ${({ justifyTablet }) => justifyTablet};
    flex-direction: ${({ directionTablet }) => directionTablet};
    flex-wrap: ${({ tabletWrap }) => tabletWrap};
    margin: ${({ tabletMargin }) => tabletMargin};
    min-height: ${({ minHeightTablet }) => minHeightTablet};
    padding: ${({ tabletPadding }) => tabletPadding};
    width: ${({ tabletWidth }) => tabletWidth};
    > * {
      flex: ${({ tabletFlex }) => tabletFlex};
    }
  }
  @media (min-width: ${props => props.theme.screen.medium}px) {
    align-items: ${({ alignDesktop }) => alignDesktop};
    height: ${({ heightDesktop }) => heightDesktop};
    justify-content: ${({ justifyDesktop }) => justifyDesktop};
    flex-direction: ${({ directionDesktop }) => directionDesktop};
    flex-wrap: ${({ desktopWrap }) => desktopWrap};
    margin: ${({ desktopMargin }) => desktopMargin};
    min-height: ${({ minHeightDesktop }) => minHeightDesktop};
    padding: ${({ desktopPadding }) => desktopPadding};
    width: ${({ desktopWidth }) => desktopWidth};
    > * {
      flex: ${({ desktopFlex }) => desktopFlex};
    }
  }
`;

export default BasicFlexContainer;
