// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import AdminBidRowContainer from 'containers/bids/AdminBidRowContainer';
import Button from 'components/buttons/Button';
import StandardFlexTable from 'components/global/layout/StandardFlexTable';
import Module, {
  ModuleHero,
} from 'components/global/layout/Module';
import { Container, Col, Row } from 'components/grid';

import toTitleCase from 'components/helpers/toTitleCase';

import type {
  BidType,
  JobOfferType,
  ScheduleType,
  TaxonomiesClassificationType,
  TaxonomyType,
} from 'shared/types';

type AdminBidsTableProps = {
  addCarerToTeam: (string, BidType) => void,
  bids: Array<BidType>,
  jobOffer: JobOfferType,
  notifyBid: string => Object,
  proposeCarerBid: BidType => void,
  rejectionReasons: TaxonomyType,
  schedule: ScheduleType,
  title: string,
  updateBid: (string, Object) => Object,
  show_header_text: bool,
};

const StyledRow = styled.div`
  margin: 0 10px;
`;

const RenotifyText = styled.p`
  margin: 10px 0 0;
  padding: 20px 0 10px;
  border-top: solid 1px #D4E4E7;
`;

const AdminBidsTable = ({
  addCarerToTeam,
  bids,
  jobOffer,
  notifyBid,
  proposeCarerBid,
  rejectionReasons,
  schedule,
  title,
  updateBid,
  show_header_text
}: AdminBidsTableProps) => {
  const bidsSortedByDistance = bids.sort((a, b) => a.distance - b.distance);

  let header_text = undefined;

  if (show_header_text) {
    header_text = `${toTitleCase(title)} (${bids.length})`
  }

  return (
    <StandardFlexTable header={header_text}>
      {bidsSortedByDistance.map(bid => (
        <StyledRow>
          <AdminBidRowContainer
            addCarerToTeam={addCarerToTeam}
            bid={bid}
            carerID={bid.carer_id}
            jobOffer={jobOffer}
            key={bid.id}
            proposeCarerBid={proposeCarerBid}
            rejectionReasons={rejectionReasons}
            state={bid.id}
            schedule={schedule}
            updateBid={updateBid}
            notifyBid={notifyBid}
          />
        </StyledRow>
      ))}
    </StandardFlexTable>
  );
};

type Props = {
  addCarerToTeam: (string, BidType) => void,
  bidStateLabelMappings: { [string]: string },
  groupedBids: { [string]: Array<BidType> },
  jobOffer: JobOfferType,
  notifyBid: string => Object,
  proposeCarerBid: BidType => void,
  renotifyBids: string => Object,
  schedule: ScheduleType,
  taxonomiesForClassification: TaxonomiesClassificationType,
  updateBid: (string, Object) => Object,
};

class AdminBidsOverview extends Component<Props> {
  smsAllNew = () => {
    const unnotifiedNew = this.props.groupedBids.new.filter(
      bid => !bid.notified_at,
    );
    unnotifiedNew.forEach(bid => this.props.notifyBid(bid.id));
  };

  allBidsBeenNotified = (proposedBids: Array<BidType>): boolean =>
    !proposedBids.filter(bid => !bid.proposed_at).length;

  render() {
    const {
      addCarerToTeam,
      groupedBids,
      bidStateLabelMappings,
      jobOffer,
      notifyBid,
      proposeCarerBid,
      renotifyBids,
      schedule,
      taxonomiesForClassification,
      updateBid,
    } = this.props;

    let show_header_text = false;
    if (Object.keys(bidStateLabelMappings).length > 1) {
      show_header_text = true;
    }

    return (
      <Module>
        {!Object.keys(groupedBids).length && (
          <ModuleHero>No applications to display.</ModuleHero>
        )}
        {(groupedBids.applied
            || groupedBids.contacted
            || groupedBids.proposed
            || groupedBids.accepted
          ) && (
          <Container style={{ marginBottom: '40px' }}>
            <Row>
              <Col xs={12}>
                <p>
                  When engaging with a Live-in Care Professional (CP) who has
                  applied to a job, please make sure they are not currently
                  actively working with another client of a franchise. We are
                  unable to prevent the job you have newly created from being
                  sent to CPs who may already be in placements, as it is sent to
                  all registered users who match the filters. By us all
                  collectively being mindful of this we can prevent other
                  franchises jobs or relationships breaking down impacting
                  continuity of care for clients.
                </p>
              </Col>
            </Row>
          </Container>
        )}
        {Object.entries(bidStateLabelMappings).map(([state, value]) => {
          if (groupedBids[state]) {
            return (
              // $FlowFixMe
              <AdminBidsTable
                addCarerToTeam={addCarerToTeam}
                bids={groupedBids[state]}
                jobOffer={jobOffer}
                key={state}
                notifyBid={notifyBid}
                proposeCarerBid={proposeCarerBid}
                rejectionReasons={
                  taxonomiesForClassification.bid_admin_rejection
                }
                schedule={schedule}
                title={value}
                updateBid={updateBid}
                show_header_text={show_header_text}
              />
            );
          }
          return null;
        })}
        {!Object.keys(groupedBids).length
          && 'applied' in bidStateLabelMappings
          && (
          <Container>
            <Row>
              <Col xs={12} sm={12}>
                <RenotifyText>
                  This button can be used if no applications have been received
                  after 1 week. Note everytime you click this, all relevant Care
                  Pros are notified and you will SPAM them if you do this regularly.
                </RenotifyText>
                <Button type="button" onClick={renotifyBids}>
                  Renotify Care Pros
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </Module>
    );
  }
}

export default AdminBidsOverview;
