// @flow
import React from 'react';
import styled from 'styled-components';
// $FlowFixMe
import { Field } from 'redux-form';

import BasicFlexContainer from 'components/global/layout/BasicFlexContainer';
import BidsSummaryContainer from 'containers/jobOffers/BidsSummaryContainer';
import Definition from 'components/global/informationdisplay/Definition';
import JobLocationDateDuration from 'components/jobOffers/JobLocationDateDuration';
import JobStatus from 'components/jobOffers/JobStatus';
import JobRef from 'components/jobOffers/JobRef';
import NeedsSummary from 'components/careNeeds/NeedsSummary';
import ShiftTypeHeadings from 'components/jobOffers/ShiftTypeHeadings';
import { ModuleSeparator } from 'components/global/layout/Module';
import { Container, Row, Col } from 'components/grid';
import Button from 'components/buttons/Button';
import StandardInput from 'components/forms/StandardInput';
import StandardSelect from 'components/forms/StandardSelect';
import ButtonLink from 'components/buttons/ButtonLink';
import PriorityIndicator from 'components/global/PriorityIndicator';

import type {
  BidType,
  JobOfferType,
  ScheduleType,
  TaxonomiesClassificationType,
} from 'shared/types';

const JobOfferBreakdown = styled.div`
  background-color: ${props => props.theme.colors.beige};
  border-radius: 4px;
`;

const NameAgeRelationshipColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.beige};
`;

const EditingColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-top: 5px;
  gap: 10px;
  background-color: ${props => props.theme.colors.beige};
`;

const StyledJobDetailsHeadings = styled(ShiftTypeHeadings)`
  margin-left: 0;
  margin-bottom: 0;
  > span {
    margin-left: 0;
  }
`;

type Props = {|
  bid: BidType,
  jobOffer: JobOfferType,
  schedule: ScheduleType,
  taxonomiesByClassification: TaxonomiesClassificationType,
  editable?: boolean => void,
  editing?: boolean,
  needID?: string,
|};

const JobOfferDetails = ({
  bid,
  jobOffer,
  schedule,
  taxonomiesByClassification,
  editable,
  editing,
  needID,
}: Props) => {
  const { need } = jobOffer;
  const jobPosted = jobOffer.state !== 'pending' && jobOffer.state !== 'draft';
  return (
    <Container>
      <Row>
        <Col xs={12} md={7}>
          <StyledJobDetailsHeadings jobOffer={jobOffer} />
        </Col>
        {!editing && (
          <Col xs={12} md={5}>
            <JobStatus jobOffer={jobOffer} bid={bid} />
            <JobRef jobOffer={jobOffer} />
            <PriorityIndicator jobOffer={jobOffer} />
          </Col>
        )}
      </Row>
      <ModuleSeparator />
      {!editing ? (
        <Row>
          <Col xs={12} md={5}>
            {jobOffer.title || `Job in ${jobOffer.location}`}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} md={5}>
            <Field
              component={StandardInput}
              label="Job title"
              name="title"
              type="text"
            />
          </Col>
          {jobPosted && (
            <Col xs={12} md={5}>
              <Field component={StandardSelect} name="state" label="Job status">
                <option value="">Please select</option>
                <option value="open">Open</option>
                <option value="on_hold">On hold</option>
                <option value="fulfilled">Fulfilled</option>
                <option value="closed">Closed</option>
              </Field>
              <JobRef jobOffer={jobOffer} />
            </Col>
          )}
        </Row>
      )}
      <ModuleSeparator />
      <JobOfferBreakdown style={{ padding: '25px 18px' }}>
        <BasicFlexContainer wrap="wrap">
          <JobLocationDateDuration
            bid={bid}
            jobOffer={jobOffer}
            schedule={schedule}
            editable={editable}
          />
          <NameAgeRelationshipColumn>
            <Definition label="HI Office">{need.franchise_name}</Definition>
            <Definition label="Care for">{need.firstname}</Definition>
            <Definition label="Age">{need.age}</Definition>
          </NameAgeRelationshipColumn>
          {editable && (
            <EditingColumn>
              <Button onClick={() => editable(true)}>
                Edit job
              </Button>
              {jobPosted && needID && (
                <ButtonLink to={`/admin/needs/${needID}/${jobOffer.id}/#applicants`}>
                  View applicants
                </ButtonLink>
              )}
            </EditingColumn>
          )}
        </BasicFlexContainer>
      </JobOfferBreakdown>
      <ModuleSeparator />
      {jobOffer.bids && (
        <>
          <BidsSummaryContainer jobOffer={jobOffer} />
          <ModuleSeparator />
        </>
      )}
      <NeedsSummary
        editing={editing}
        jobOffer={jobOffer}
        need={need}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    </Container>
  );
};

export default JobOfferDetails;
