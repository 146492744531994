import keyMirror from 'keymirror';

const AccountConstants = keyMirror({
  GET_LOCAL_STORAGE_LOGIN_DATA: null,
  GET_LOCAL_STORAGE_LOGIN_DATA_SUCCESS: null,

  GET_LOCAL_STORAGE_USER_DATA_SUCCESS: null,
  GET_LOCAL_STORAGE_STAFF_USER_DATA_SUCCESS: null,
  GET_LOCAL_STORAGE_IMPERSONATOR_DATA_SUCCESS: null,

  SET_REDIRECT: null,

  LOGIN: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAILURE: null,

  STAFF_LOGIN: null,
  STAFF_LOGIN_SUCCESS: null,
  STAFF_LOGIN_FAILURE: null,

  ACCOUNT_CREATE: null,
  ACCOUNT_CREATE_SUCCESS: null,
  ACCOUNT_CREATE_FAILURE: null,

  ACCOUNT_UPDATE: null,
  ACCOUNT_UPDATE_SUCCESS: null,
  ACCOUNT_UPDATE_FAILURE: null,

  ACCOUNT_VERIFY: null,
  ACCOUNT_VERIFY_SUCCESS: null,
  ACCOUNT_VERIFY_FAILURE: null,

  ACCOUNT_CONFIRM_TERMS: null,
  ACCOUNT_CONFIRM_TERMS_SUCCESS: null,
  ACCOUNT_CONFIRM_TERMS_FAILURE: null,

  ACCOUNT_RECOVER: null,
  ACCOUNT_RECOVER_SUCCESS: null,
  ACCOUNT_RECOVER_FAILURE: null,

  ACCOUNT_REFRESH: null,
  ACCOUNT_REFRESH_SUCCESS: null,
  ACCOUNT_REFRESH_FAILURE: null,

  CHANGE_PASSWORD: null,
  CHANGE_PASSWORD_SUCCESS: null,
  CHANGE_PASSWORD_FAILURE: null,

  CHECK_IF_VERIFIED: null,
  CHECK_IF_VERIFIED_SUCCESS: null,
  CHECK_IF_VERIFIED_FAILURE: null,

  MAGIC_TOKEN_LOGIN: null,
  MAGIC_TOKEN_LOGIN_SUCCESS: null,
  MAGIC_TOKEN_LOGIN_FAILURE: null,

  RESET_PASSWORD: null,
  RESET_PASSWORD_SUCCESS: null,
  RESET_PASSWORD_FAILURE: null,

  IMPERSONATE: null,
  IMPERSONATE_SUCCESS: null,
  IMPERSONATE_FAILURE: null,
  IMPERSONATE_STOP: null,

  TOGGLE_FEATURE: null,

  LOGOUT: null,
  LOGOUT_SUCCESS: null,
  LOGOUT_FAILURE: null,

  SEND_EMAIL_VERIFICATION: null,
  SEND_EMAIL_VERIFICATION_FAULURE: null,
  SEND_EMAIL_VERIFICATION_SUCCESS: null,
});

export default AccountConstants;
