import React from 'react';
import { DayIcon, LiveinIcon, NightIcon } from 'components/global/icons/small';
import InlineStyledIcon from 'components/global/icons/InlineStyledIcon';

export const ShiftTypeIcons = ({ shiftType }) => {
  const iconLabels = {
    short_duty: { icon: DayIcon, label: 'Day-time care (short shift)' },
    long_duty: { icon: DayIcon, label: 'Day-time care (long shift)' },
    working_night: { icon: NightIcon, label: 'Working night care' },
    sleeping_night: { icon: NightIcon, label: 'Sleeping night care' },
    livein: { icon: DayIcon, label: 'Live-in care' },
    half_livein: { icon: DayIcon, label: 'Live-in care (half day)' },
  };

  const iconLabel = iconLabels[shiftType];
  if (iconLabel) {
    return <InlineStyledIcon Icon={iconLabel.icon} label={iconLabel.label} />;
  }

  return null;
};

export const CareTypeIcons = ({ careType, label }) => {
  const icons = {
    dayshifts: DayIcon,
    nightshifts: NightIcon,
    liveincareanytime: LiveinIcon,
    liveincareweekendsonly: LiveinIcon,
    liveincareoccasionalonly: LiveinIcon,
  };

  if (icons[careType]) {
    return <InlineStyledIcon Icon={icons[careType]} label={label} />;
  }

  return null;
};
