import PropTypes from 'prop-types';
import React from 'react';

const NeedsIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 30"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="needs-icon"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path d="M6.24994737,6.65789474 L16.7500526,6.65789474 L15.8857368,3.63157895 L13.3157895,3.63157895 C12.9816842,3.63157895 12.7105263,3.36102632 12.7105263,3.02631579 L12.7105263,2.42105263 C12.7105263,1.75405263 12.1676053,1.21052632 11.5,1.21052632 C10.8323947,1.21052632 10.2894737,1.75405263 10.2894737,2.42105263 L10.2894737,3.02631579 C10.2894737,3.36102632 10.0183158,3.63157895 9.68421053,3.63157895 L7.11426316,3.63157895 L6.24994737,6.65789474 Z M17.5526316,7.86842105 L5.44736842,7.86842105 C5.25731579,7.86842105 5.07876316,7.77944737 4.96436842,7.62813158 C4.84997368,7.47621053 4.81305263,7.28010526 4.86571053,7.09731579 L6.07623684,2.86047368 C6.15007895,2.60021053 6.38794737,2.42105263 6.65789474,2.42105263 L9.07894737,2.42105263 C9.07894737,1.08584211 10.1647895,0 11.5,0 C12.8352105,0 13.9210526,1.08584211 13.9210526,2.42105263 L16.3421053,2.42105263 C16.6120526,2.42105263 16.8499211,2.60021053 16.9237632,2.86047368 L18.1342895,7.09731579 C18.1869474,7.28010526 18.1500263,7.47621053 18.0356316,7.62813158 C17.9212368,7.77944737 17.7426842,7.86842105 17.5526316,7.86842105 L17.5526316,7.86842105 Z" />
        <path d="M20.5789474,28.4473684 L2.42105263,28.4473684 C1.08584211,28.4473684 0,27.3621316 0,26.0263158 L0,5.44736842 C0,4.11215789 1.08584211,3.02631579 2.42105263,3.02631579 L6.05263158,3.02631579 L6.05263158,4.23684211 L2.42105263,4.23684211 C1.75344737,4.23684211 1.21052632,4.78036842 1.21052632,5.44736842 L1.21052632,26.0263158 C1.21052632,26.6939211 1.75344737,27.2368421 2.42105263,27.2368421 L20.5789474,27.2368421 C21.2465526,27.2368421 21.7894737,26.6939211 21.7894737,26.0263158 L21.7894737,5.44736842 C21.7894737,4.78036842 21.2465526,4.23684211 20.5789474,4.23684211 L16.9473684,4.23684211 L16.9473684,3.02631579 L20.5789474,3.02631579 C21.9141579,3.02631579 23,4.11215789 23,5.44736842 L23,26.0263158 C23,27.3621316 21.9141579,28.4473684 20.5789474,28.4473684" />
        <path d="M19.9736842,26.0263158 L3.02631579,26.0263158 C2.69221053,26.0263158 2.42105263,25.7557632 2.42105263,25.4210526 L2.42105263,6.05747368 C2.42105263,5.72336842 2.69221053,5.45221053 3.02631579,5.45221053 L6.05263158,5.45221053 L6.05263158,6.66273684 L3.63157895,6.66273684 L3.63157895,24.8157895 L19.3684211,24.8157895 L19.3684211,6.66273684 L16.9473684,6.66273684 L16.9473684,5.45221053 L19.9736842,5.45221053 C20.3077895,5.45221053 20.5789474,5.72336842 20.5789474,6.05747368 L20.5789474,25.4210526 C20.5789474,25.7557632 20.3077895,26.0263158 19.9736842,26.0263158" />
        <polygon points="6.05263158 13.3157895 10.2894737 13.3157895 10.2894737 12.1052632 6.05263158 12.1052632" />
        <polygon points="6.05263158 16.3421053 16.9473684 16.3421053 16.9473684 15.1315789 6.05263158 15.1315789" />
        <polygon points="6.05263158 19.3684211 16.9473684 19.3684211 16.9473684 18.1578947 6.05263158 18.1578947" />
        <polygon points="6.05263158 22.3947368 16.9473684 22.3947368 16.9473684 21.1842105 6.05263158 21.1842105" />
      </g>
    </svg>
  </div>
);

NeedsIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

NeedsIcon.defaultProps = {
  className: '',
  height: '30px',
  width: '26px',
};

export default NeedsIcon;
