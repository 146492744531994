import React, { Component } from 'react';
import { Container, Col, Row } from 'components/grid';

import Module, { ModuleHeader } from 'components/global/layout/Module';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
    h3 {
        font-weight: bold;
        font-size: 20px;
        margin: 24px 0 10px;
    }
    h4 {
        font-size: 18px;
        font-weight: bold;
        margin: 22px 0 10px;
    }
    h5 {
        font-weight: bold;
        margin: 20px 0 10px;
    }
    
    ul {
        list-style-type: disc;
        margin-bottom: 16px;
    }
    
    li {
        margin: 6px 0 6px 24px;
    }
    
    ul ul {
        list-style-type: circle;
    }
    
    li li {
        margin-left: 48px;
    }
    
    p {
        margin: 10px 0;
    }
`;

class PrivacyPolicy extends Component {

  render() {
    return (
      <section>
        <Module>
          <ModuleHeader>Our Privacy Policy</ModuleHeader>
          <StyledContainer>
            <Row>
              <Col>
                <p>
                  We are committed to protecting the privacy of our Users.
                </p>
                <p>
                  Please read this Privacy Policy carefully as it contains important information about our practices -
                  how we will collect, use and share your personal information, information that we collect from you,
                  and that we receive from third parties.
                </p>
                <p>
                  Home Instead Limited ("us," "we," "our," or "the Company") is a company registered in England and
                  Wales under company number 05564869, with its registered office at Unit 2 Walnut Tree Business Centre,
                  Walnut Tree Farm, Northwich Road, Lower Stretton, Warrington, Cheshire, England, WA4 4PG.
                </p>
                <p>
                  We operate the website at, https://supercarers.com (“Website”) under our trading name SuperCarers
                  (“SuperCarers”). We also provide an online platform for care professionals and franchisees accessible
                  online via liveincareapp.homeinstead.co.uk (“Platform”).
                </p>
                <p>
                  We license a nationwide franchise network, with each franchisee operating under the Home Instead brand
                  (referred to as a “Franchised Offices” in this privacy promise). Throughout the rest of this privacy
                  promise, where we say “We” or “Our” we are referring to Home Instead and its Franchised Offices.
                </p>
                <p>
                  This privacy promise sets out how we collect and use personal data, in accordance with the UK’s
                  General Data Protection Regulation (“GDPR”) and Data Protection Act 2018 (“Data Protection
                  Legislation”).
                </p>

                <h3>Our promise to you</h3>
                <p>We respect your privacy and comply with data protection law whenever We process your data. The law says that the personal information We hold about you must be:</p>
                <ul>
                  <li>Used lawfully, fairly and in a transparent way.</li>
                  <li>Collected only for valid purposes that We have clearly explained to you and not used in any way that is incompatible with those purposes.</li>
                  <li>Relevant to the purposes We have told you about and limited only to those purposes.</li>
                  <li>Accurate and kept up to date.</li>
                  <li>Kept only as long as necessary for the purposes We have told you about.</li>
                  <li>Kept securely.</li>
                </ul>

                <h3>The basis for the processing of your data</h3>

                <p>We process your data for the performance of the contract that you have entered into with us for the use of the Platform and that is governed by our Website and Platform Terms of Use.</p>
                <p>When it comes to special categories of data such as health data, we rely on Article 9 (2) (h) GDPR exception in connection with Schedule 1, Part 1 of the Data Protection Act 2018, pursuant to which we can process the data for the assessment of the working capacity of an employee as well as the management of health or social care systems and services.</p>
                <p>In addition, some data is processed based on the legitimate interest, notably for your safety and security, web and app analytics for the purposes of enhancing web and app performance, website logs etc. to prevent fraudulent or other malicious activities, website visitor logs for cybersecurity and/or error tracking purposes.</p>
                <p>We may ask for your consent in other cases where we process your data that falls outside the scope of this Policy. Such consent can be revoked at any time by contacting us at <a href="mailto:privacy@homeinstead.co.uk">privacy@homeinstead.co.uk</a>.</p>

                <h3>1. Policy Updates</h3>

                <p>From time to time this Privacy Policy may be updated and a new version will be posted on our Website/Platform. As such you should check this page to review the changes and ensure that you continue to agree to the Privacy Policy.</p>
                <p>The date the Privacy Policy was last updated will be displayed at the top of the page.</p>

                <h3>2. Information collected</h3>

                <h4>2.1 Personal information</h4>
                <p>Personal Information is information that could potentially identify you individually. We may collect, store and/or use the following types of personal information:</p>
                <ul>
                  <li>Information that you give to us. This will be the inforamtio you provide via forms on the Website, the Platform, or provide by e-mail, over the phone or other such means.</li>
                  <li>
                    This includes information provided for the purpose of registering for or using our service, in
                    subscribing for our services (email notifications and/or newsletters) or for any other reason. This
                    includes but is not limited to:
                    <ul>
                      <li>
                        Contact information: your name, email address and phone number
                      </li>
                    </ul>
                  </li>
                  <li>We may receive and store information about you where you interact with us through social media. This will include personal information that is available on your profile such as: name, location and work details.</li>
                  <li>
                    Information you upload on to the Platform during the application process and thereafter. Personal data such as:
                    <ul>
                      <li>
                        qualifications, professional skills and other experiences (CV), personal preferences (activities of daily living you enjoy or animal preferences), nationality, languages, DBS certificate details) and any other personal data which you disclose to us for use in your profile.
                      </li>
                      <li>A photograph of your face, which we may take for you and display on your profile. A photograph of your face is required by the relevant legislation which governs provision of care services and if you are successful if being selected for a live-in care assignment your photograph (amongst other details) will be shared with the relevant Home Instead office who will be employing you.</li>
                      <li>Supplemental information about you from third parties, for example results of background checks carried out by Trust ID and Security Watchdog including and not limited to: Enhanced DBS (criminal records check including spent convictions), identity and right to work checks. The results of these checks will be shared with us by the third-party provider. Information obtained for the purposes of verifying your work history and reference checks or that information obtained by way of video, audio or face to face interview.</li>
                    </ul>
                  </li>
                </ul>

                <p>Information might be provided; directly from you; from a third-party hiring platform or job board, on which you might have uploaded a CV or other personal information to; or, where you have been referred by Home Instead Franchise Office to work through the platform; or engaged with us via social media channels. We will not use data from the open internet in this regard; we will only gather information about you where you have previously intended to let us know.</p>
                <p>We may collect data and feedback from Home Instead offices who have employed you and reference it against your profile. This information may be publicly available on your profile.</p>
                <p>If you call our phone lines, calls are recorded for training and monitoring purposes.</p>

                <h4>2.2 Other Information Collected – technical and technology information as it relates to your data:</h4>

                <ul>
                  <li>
                    We may collect and store certain information automatically through technology each time you visit the Website or Platform. This is typically designed to help us learn and improve the way we serve you. Examples of this information include the following:
                    <ul>
                      <li>
                        information about how you interact with the Website/Platform - the computer or device used for access, the internet protocol (IP) address used to connect your computer or device to the internet, other connection information such as: browser type, version, location and language preferences, and your operating system
                      </li>
                      <li>information about your visit including: referral source, page visits, duration and navigation information, searches made, the full Uniform Resource Locators (URL)</li>
                      <li>We also may receive information about you from other third parties we work with - such as any websites or social media platforms (for example, Facebook), other advertising and analytics networks, and other channels who link Your Personal Data to our Website/Platform.</li>
                    </ul>
                  </li>
                </ul>

                <h3>3. Using your Personal Information:</h3>
                <h4>3.1	How your personal information is used</h4>

                <p>Your profile will be visible to all the users, who are currently hiring for an assignment, the Home Instead Franchise Office will be able: </p>

                <ul>
                  <li>to contact you in respect of an application to join the Platform – such as arranging an interview, induction or any training;</li>
                  <li>to assess your CV, qualifications and any other information provided to ascertain your suitability to join the platform;</li>
                  <li>to complete the registration process, including to contact third parties to obtain references on you;</li>
                  <li>to provide you with information regarding job opportunities with Home Instead offices that may be of interest to you based on the information we have collected;</li>
                  <li>to monitor your ongoing compliance and administer your account including: criminal records information shared with by third party DBS provider, RTW documentation, updates to training and other compliance information;</li>
                  <li>to provide your profile to Home Instead offices who may have a vacancy that may be of interest to you and for which you have applied;</li>
                </ul>

                <p>We might use your personal information for:</p>

                <ul>
                  <li>carrying out our obligations under Our Terms & Conditions;</li>
                  <li>administering and maintaining the account you hold with us;</li>
                  <li>ensuring that Our Service is presented in the most effective manner for you and your computer or mobile device;</li>
                  <li>providing you with information, products and/or services that you request from us, and other commercial communications;</li>
                  <li>where you have consented and opted in to specific communications such as those for direct marketing purposes, information about other services or products which we feel may interest you;</li>
                  <li>notifying you about a change to Our Service;</li>
                  <li>record-keeping and tracking your use of Our Service including through the use of cookies;</li>
                  <li>requesting feedback on Services and to address and investigate service complaints that might arise or violation of any of our Terms, Policies or Standards; and</li>
                  <li>to conduct troubleshooting, data analysis and internal reporting</li>
                </ul>

                <p>We will not provide your information to any third parties for direct marketing purposes without your express consent.</p>
                <p>If you apply for a specific care assignment, your full profile information will be visible to the relevant Home Instead office, who is advertising the assignment.  The data visible will include everything that is contained in your profile including: full first name, surname, picture, contact information, summary key info and background checks info (driving or car status, languages, pet preference, smoking or not), condition experience, services provided, qualifications and a map with a broad indication of where you live. Once the Home Instead office receives your application, they may want to interview you.</p>

                <h3>4. Retention of personal data</h3>
                <p>We retain personal data on Users and from closed accounts for up to 7 years from the date from the account was closed or for the purposes the data was collected for, including for the purposes of satisfying any legal, accounting, or reporting requirements and to comply with other obligations such as safeguarding Users, to the extent that it is permitted by Law.</p>
                <p>We may delete accounts or specific information before account deactivation where we believe it may no longer be relevant or where an account has been inactive for more than a year.</p>
                <p>If you have sent us an email or other correspondence, we will retain your information until we are satisfied it is outdated or no longer relevant, subject to your exercise of the right to erasure.</p>

                <h3>5. Disclosure of your information:</h3>
                <p>Subject to the need to perform the contract with you based on our Terms and Conditions and our legitimate interests, we may share your personal information in the ways described below:</p>
                <p>We do not allow our third-parties to use your personal data for their own purposes (such as marketing or reselling) and we only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>

                <table>
                  <thead>
                    <tr>
	              <th><b>Recipient category</b></th>
                      <th><b>Explanation</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Home Instead franchise network or Home Instead offices</td>
                      <td>Where you have applied for a care assignment, your personal data will be shared with the relevant Home Instead office.
Home Instead offices may also provide information about you to keep your profile up to date.
Each Home Instead office is independently owned and operated and will be a data controller in their own right.  This means the use of your personal data by any Home Instead office will be governed by their own privacy policy.</td>
                    </tr>
                  <tr>
                    <td>Home Instead Inc. </td>
                    <td>We may share your personal data Home Instead Inc. for internal administrative purposes.</td>
                  </tr>
                  <tr>
                    <td>Other companies and organisations.</td>
                    <td>Where we are under a duty to disclose or share your personal information in order to comply with any legal obligation, or in order to enforce or apply such other terms as apply to our relationship, or to protect the rights, property, or safety of our customers, ourselves or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection, credit risk reduction and relating to safeguarding issues.</td>
                  </tr>
                  <tr>
                    <td>Third-party tools, software and service providers: hosting, analytics, CRM, emailing, app integration, communication, identity verification, system administrators.</td>
                    <td>We may share your personal data with third-parties who perform functions on our behalf in connection with the operation of our business and assist us in the communication with you, the performance of the contract you have entered into with us and the improvement and optimisation of our website.</td>
                  </tr>
                  <tr>
                    <td>Prospective seller or buyer of our business or assets.</td>
                    <td>Just in the event that we sell or buy any business or assets. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this Privacy Policy.</td>
                  </tr>
                  <tr>
                    <td>Any other third parties.</td>
                    <td>Just with your consent.</td>
                  </tr>
                  </tbody>
                </table>

                <h3>6. Cookies:</h3>
                <p>Certain parts of Our Website/Platform use “cookies” to keep track of your visit and to help you navigate between sections. A cookie is a small data file that certain websites store on your computer’s hard-drive when you visit such websites. Cookies can contain information such as your user ID and the pages you have visited.</p>
                <p>We use cookies on Our Website/Platform to enable us to deliver content that is specific to your interests and gives us an idea of which parts of Our Service you are visiting and to recognise you when you return to Our Service. Our cookies do not read data from your computer’s hard-drive or read cookies created by other websites that you have visited.</p>
                <p>You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the setting of cookies. Unless you have adjusted your browser settings so that it will refuse cookies, our system will issue cookies when you access Our Service. Please note Third Party, providers of content and services may also use cookies over which we have no control.</p>


                <h4>Types of cookies used on SuperCarers:</h4>
                <p>SuperCarers uses different types of cookies. Permanent or persistent cookies are those that are not removed from your browser or computer after a session ends and you close your browser. These are used to recognise you as a User and for example remember the details about care requests made by a User to enable sign up functionality, should the User leave and return to the site. Session cookies enable us to track you while using our Website and for example make care bookings.</p>
                <p>SuperCarers uses a number of third party services to embed content such as reviews and videos, and to help analyse site features and are primarily for own internal purposes. An example of this would be Google Analytics, which provides information, insights and reporting on the use of the Website.</p>

                <h3>7. Security:</h3>
                <p>Our Service has numerous security measures in place to protect the loss, misuse and alteration of information under our control, such as passwords and firewalls. We do take data security seriously and will use our reasonable endeavours to protect the integrity of your personal information.</p>

                <ul>
                  <li>If you register to use our Service, in particular if you have an account with us, you will be asked to create a password. You must keep this password confidential and must not disclose it or share it with anyone. You will be responsible for all activities that occur under your password. If you know or suspect that someone else knows your password you should notify us immediately at the address provided at the end of this Privacy Policy. If we have reason to believe that there is likely to be a breach of security or misuse of Our Service, we may require you to change your password or we may suspend your account until your identity has been verified and the account has been satisfactorily secured.</li>
                  <li>Our Service may, from time to time, contain links to and from other websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</li>
                </ul>

                <p>Additionally, personal information and content requested may be transmitted electronically to addresses provided by you (email for example) and this is done so at your own risk and you understand these risks.</p>


                <h3>8. Storing Your Personal Data - where do we store it?</h3>

                <p>Your personal data may be collected, processed and/or otherwise transferred to a destination outside the European Economic Area (“EEA”) and in particular, the United States.</p>
                <p>Data we collect from you is primarily stored on servers located in the EU/EEA, however in particular third-party technologies and services we use may store data in the United States, which results in this transfer of information internationally outside of the EEA. Please see section 9 for further information.</p>
                <p>We will take all reasonable steps necessary to ensure that your data is treated securely and appropriately in accordance with this Privacy Policy.</p>
                <p>We have put the necessary steps in place to ensure all company laptops are encrypted and have appropriate protection against viruses, malware, ransom-ware and malicious code and are regularly updated.</p>

                <h3>9.International data transfers.</h3>

                <p>The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (“EEA”), where the laws on processing personal data may be less stringent than in your country. It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. This includes staff engaged in, among other things, the fulfilment of your order, the processing of your payment details and the provision of support services.</p>
                <p>We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy, by ensuring at least one of the following safeguards is implemented:</p>
                <ul>
                  <li>transferring your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission;</li>
                  <li>entering into specific contractual terms which have been approved by the European Commission and which give personal data the same protection as within the EEA;</li>
                </ul>
                <p>For further information on the safeguards used, please contact us at <a href="mailto:privacy@homeinstead.co.uk">privacy@homeinstead.co.uk</a>. </p>

                <h3>10. Your rights:</h3>
                <p>Because the data We hold about you is your data, you have the following rights in respect of the personal data We hold about you:</p>
                <p>Right to Access – this means that you can ask Us for a copy of all personal data We hold about you. This enables you to receive a copy of the personal information We hold about you and to check that We are lawfully processing it.</p>
                <p>Correction Right – if you believe that any of the information We hold about you is incorrect or out of date, you have the right to correct such information by providing Us with the correct up to date information. In addition, you can ask Us to delete the incorrect or out of date information and We will be happy to do so unless We are prevented from doing so by law or regulation.</p>
                <p>Right to be Forgotten – this enables you to ask Us to delete or remove personal information where there is no good reason for Us continuing to process it. Please note that where We are obliged to keep your personal data because of a regulatory or legal requirement, We will not be able to delete the data and must continue to retain it.</p>
                <p>Right to Restrict Processing – in some limited circumstances you have the right to restrict the processing of your data.</p>
                <p>Right of Objection to Processing – you have the right to object to Us using your data for direct marketing purposes and to profiling.</p>
                <p>Right of Data Portability – you have the right to request a copy of the personal data We hold about you in a commonly used and machine-readable format. We can provide your data either to you or to such other third party as you specify in your request.</p>
                <p>Automated Decision-Making Objection Right – automated decision-making is where a decision is made entirely by technological means without human intervention. We do not use or rely on automated decision-making.</p>
                <p>If you would like to exercise any of the above rights please contact the relevant Privacy Champion using the contact details above. You can exercise your rights at any time by writing to us at: Unit 2 Walnut Tree Farm, Northwich Road, Lower Stretton, Warrington, England, WA4 4PG, our registered office address. Alternatively email us on <a href="mailto:privacy@homeinstead.co.uk">privacy@homeinstead.co.uk</a>.</p>

                <h3>11. Third party websites</h3>
                <p>Our Website/Platform contains links to other websites and services. These websites and services will have their own Privacy Policy and we accept no liability or responsibility for these Policies or how these might collect, store and use Your Personal Information.</p>

                <h3>12. Our Contact information</h3>
                <p>If you have any questions about Our Privacy Policy or how we treat your personal information please do not hesitate to contact us by email at: <a href="mailto:privacy@homeinstead.co.uk">privacy@homeinstead.co.uk</a></p>

                <h4>National Office Privacy Champion contact details:</h4>
                <p>Privacy Champion: Ben Damm</p>
                <p>Address:	Unit 2 Walnut Tree Farm, Northwich Road, Lower Stretton, Warrington, England, WA4 4PG </p>
                <p>Contact email: <a href="mailto:ben.damm@homeinstead.co.uk">ben.damm@homeinstead.co.uk</a></p>
                <p>Contact telephone:	<a href="tel:+441925730273">01925 730273</a></p>

                <p>You may always launch a complaint regarding our processing of your data with <a href="https://ico.org.uk/">ico.org.uk</a></p>
              </Col>
            </Row>
          </StyledContainer>
        </Module>
      </section>
    );
  }
}

export default PrivacyPolicy;
