import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonGroup from 'components/buttons/ButtonGroup';
import Button from 'components/buttons/Button';
import ButtonWithReason from 'components/buttons/ButtonWithReason';
import Module, {
  BorderedModuleSeparator,
  ModuleSeparator,
} from 'components/global/layout/Module';
import CarerBid from 'components/jobOffers/CarerBid';
import ApplicationFormContainer from 'containers/bids/ApplicationFormContainer';
import { SC_NAME } from 'components/global/ContactInfo';
import Definition from 'components/global/informationdisplay/Definition';
import JobOfferDetails from 'components/jobOffers/JobOfferDetails';
import { Container, Row, Col } from 'components/grid';

const JobOfferModule = styled(Module)`
  padding: 20px;
`;

const WithdrawalNotice = () => (
  <Module>
    <Container>
      <Row>
        <Col xs={12}>
          <p>
            You have withdrawn your application for this job. Please contact
            {SC_NAME} if you would like to be reconsidered.
          </p>
        </Col>
      </Row>
    </Container>
  </Module>
);

class JobOffer extends Component {
  state = {
    isApplying: false,
  };

  render() {
    const {
      bid,
      jobOffer,
      updateBid,
      taxonomiesByClassification,
      isAdmin,
      schedule,
    } = this.props;
    let canApply = false;
    let hasPlacedBid = false;
    let hasWithdrawn = false;
    let bidDetails = null;

    if (bid) {
      canApply =
        bid &&
        bid.state === 'new' &&
        this.state.isApplying !== true &&
        jobOffer.state === 'open';
      hasPlacedBid =
        bid && ['proposed', 'applied', 'accepted'].includes(bid.state);
      hasWithdrawn =
        bid && ['carerdeclined', 'carer_withdrawn'].includes(bid.state);
      if (hasPlacedBid) {
        bidDetails = (
          <CarerBid
            bid={bid}
            updateBid={updateBid}
            jobOffer={jobOffer}
            rejectionReasons={taxonomiesByClassification.bid_carer_rejection}
            schedule={schedule}
          />
        );
      } else if (hasWithdrawn) {
        bidDetails = <WithdrawalNotice />;
      }
    }

    return (
      <section>
        {bidDetails}
        <JobOfferModule>
          <JobOfferDetails
            bid={bid}
            jobOffer={jobOffer}
            schedule={schedule}
            taxonomiesByClassification={taxonomiesByClassification}
          />
          <BorderedModuleSeparator />
          <ModuleSeparator />

          {jobOffer.description && (
            <Definition label="Message to carers">
              <div dangerouslySetInnerHTML={{ __html: jobOffer.description }} />
            </Definition>
          )}

          {jobOffer.need.schedule_description && (
            <Definition label="Care Times">
              <div dangerouslySetInnerHTML={{ __html: jobOffer.need.schedule_description }} />
            </Definition>
          )}

          {canApply && (
            <ButtonGroup>
              <Button
                onClick={() => this.setState({ isApplying: true })}
                type="button"
              >
                Apply
              </Button>
              <ButtonWithReason
                className="danger"
                action={reason =>
                  this.props.updateBid(bid.id, {
                    state: 'carer_declined',
                    state_reason: reason,
                  })
                }
                reasons={taxonomiesByClassification.bid_carer_rejection}
                otherReasoning="Please tell us why to improve the jobs we send you."
                cta="Not interested"
                key="decline"
              />
            </ButtonGroup>
          )}
        </JobOfferModule>
        {this.state.isApplying && !hasPlacedBid && !hasWithdrawn && (
          <ApplicationFormContainer
            jobOffer={jobOffer}
            bid={bid}
            schedule={schedule}
          />
        )}
        {isAdmin && (
          <Module>
            <Container>
              <Row>
                <Col xs={12}>
                  <Link
                    to={`/admin/needs/${jobOffer.need_id}/?jobID=${
                      jobOffer.id
                    }#job-details`}
                  >
                    View Job on Admin
                  </Link>
                </Col>
              </Row>
            </Container>
          </Module>
        )}
      </section>
    );
  }
}

JobOffer.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  bid: PropTypes.shape(),
  jobOffer: PropTypes.shape().isRequired,
  updateBid: PropTypes.func.isRequired,
  taxonomiesByClassification: PropTypes.shape(),
  schedule: PropTypes.shape().isRequired,
};

JobOffer.defaultProps = {
  bid: null,
  need: null,
  taxonomiesByClassification: {},
};

export default JobOffer;
