// @flow
import React from 'react';

import ButtonWithReason from 'components/buttons/ButtonWithReason';

import type { TaxonomiesClassificationType } from 'shared/types';

type Props = {|
  carerID: string,
  taxonomiesByClassification: TaxonomiesClassificationType,
  teamID: string,
  fireCareTeamMember: (string, string, mixed) => void,
|};

const FireWithReasonButton = ({
  carerID,
  taxonomiesByClassification,
  teamID,
  fireCareTeamMember,
}: Props) => (
  <ButtonWithReason
    className="danger"
    action={reason =>
      fireCareTeamMember(teamID, carerID, {
        reason: `${reason || ''}`.trim(),
        state: 'fired',
      })
    }
    reasons={taxonomiesByClassification.care_team_member_customer_fired}
    otherReasoning="Please tell us why for our records."
    cta="Release Carer"
    key="fire"
  />
);

export default FireWithReasonButton;
