import { all, takeLatest, take, put } from 'redux-saga/effects';

import { getCareTeamByNeed } from '_actions/careTeamActions';
import { getCarerById } from '_actions/carerActions';
import { getCareNeedCarerRates } from '_actions/rateActions';
import CareTeamConstants from 'shared/constants/CareTeamConstants';
import RatesConstants from 'shared/constants/RatesConstants';

export function* fetchCareNeedRates(action) {
  try {
    yield put(getCareTeamByNeed(action.careNeedId));
    const result = yield take([
      CareTeamConstants.CARETEAM_GET_BY_NEED_SUCCESS,
      CareTeamConstants.CARETEAM_GET_BY_NEED_FAILURE,
    ]);
    if (result.type === CareTeamConstants.CARETEAM_GET_BY_NEED_SUCCESS) {
      yield all(
        result.data.team.reduce(
          (accumulator, carerId) =>
            accumulator.concat([
              put(getCarerById(carerId)),
              put(getCareNeedCarerRates(action.careNeedId, carerId)),
            ]),
          [],
        ),
      );
      yield put({
        type: RatesConstants.RATES_FETCH_CARE_NEED_RATES_SUCCESS,
      });
    } else {
      yield put({
        type: RatesConstants.RATES_FETCH_CARE_NEED_RATES_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: RatesConstants.RATES_FETCH_CARE_NEED_RATES_FAILURE,
    });
  }
}

export function* fetchCareNeedRatesListener() {
  yield takeLatest(
    RatesConstants.RATES_FETCH_CARE_NEED_RATES,
    fetchCareNeedRates,
  );
}
