import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';
import { Row, Col } from 'components/grid';

class InvoiceSearchFilters extends Component {
  render() {
    const filters = this.props.filters;

    return (
      <div className="module component-wrapper">
        <Row>
          <Col xs={3}>
            <label htmlFor="state">Invoice State</label>
            <select
              name="state"
              value={filters.state || 'all'}
              onChange={this.props.updateFilters}
            >
              <option value="all">All</option>
              <option value="awaiting_timesheets">Awaiting Timesheets</option>
              <option value="awaiting_payment">Awaiting Payment</option>
              <option value="requires_action">Requires Action</option>
              <option value="queried">Queried</option>
              <option value="paid">Paid</option>
              <option value="void">Void</option>
            </select>
          </Col>
          <Col xs={3}>
            <label htmlFor="begin">From Date</label>
            <input
              name="begin"
              type="date"
              placeholder="e.g. 2016-01-01"
              value={filters.begin || ''}
              onChange={this.props.updateFilters}
            />
          </Col>
          <Col xs={3}>
            <label htmlFor="end">To Date</label>
            <input
              name="end"
              type="date"
              placeholder="e.g. 2016-01-01"
              value={filters.end || ''}
              onChange={this.props.updateFilters}
            />
          </Col>
          <Col xs={3}>
            <label htmlFor="age_older_than">Older Than (days)</label>
            <input
              name="age_older_than"
              type="number"
              placeholder="e.g. 2"
              value={filters.age_older_than || ''}
              onChange={this.props.updateFilters}
              step="1"
              min="0"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '15px' }}>
          <Col xs={6}>
            <label htmlFor="customer_name">Customer Name</label>
            <input
              name="customer_name"
              type="text"
              value={filters.customer_name || ''}
              onChange={this.props.updateFilters}
            />
          </Col>
          <Col xs={6}>
            <label htmlFor="oustanding_invoices">Quick Filters</label>
            <button
              name="oustanding_invoices"
              className="btn"
              onClick={() =>
                this.props.presetFilters({
                  age_older_than: 2,
                  state: 'awaiting_payment',
                })
              }
            >
              Outstanding Invoices (&gt; 2 days old)
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to={this.props.location.pathname}>Reset Filters</Link>
          </Col>
        </Row>
      </div>
    );
  }
}

InvoiceSearchFilters.propTypes = {
  location: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  presetFilters: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
};

export default InvoiceSearchFilters;
