import PropTypes from 'prop-types';
import React from 'react';

const HistoryIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path d="M710.4 710.4c-6.4 0-12.8 0-19.2-6.4L524.8 595.2c-19.2-12.8-19.2-32-12.8-44.8 12.8-12.8 32-19.2 44.8-6.4l166.4 115.2c12.8 12.8 19.2 32 6.4 44.8 0 6.4-6.4 6.4-19.2 6.4z" />
      <path d="M537.6 601.6c-19.2 0-32-12.8-32-32V345.6c0-19.2 12.8-32 32-32s32 12.8 32 32v224c0 19.2-12.8 32-32 32z" />
      <path d="M550.4 966.4c-115.2 0-224-44.8-300.8-128-12.8-12.8-12.8-32 0-44.8 12.8-12.8 32-12.8 44.8 0 70.4 70.4 160 108.8 256 108.8 198.4 0 364.8-160 364.8-364.8s-160-364.8-364.8-364.8c-160 0-307.2 108.8-352 262.4 0 19.2-19.2 32-32 25.6-19.2-6.4-25.6-25.6-25.6-38.4 51.2-179.2 224-307.2 409.6-307.2 236.8 0 428.8 192 428.8 428.8 0 230.4-192 422.4-428.8 422.4z" />
      <path d="M160 492.8c-19.2 0-32-6.4-32-25.6l-25.6-166.4c0-19.2 6.4-32 25.6-38.4 19.2 0 32 6.4 38.4 25.6L192 460.8c0 12.8-12.8 32-32 32z" />
      <path d="M160 492.8c-12.8 0-25.6-6.4-32-12.8-6.4-19.2 0-38.4 19.2-44.8l160-83.2c12.8-6.4 32 0 44.8 12.8 6.4 12.8 0 32-12.8 44.8L172.8 492.8h-12.8z" />
    </svg>
  </div>
);

HistoryIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

HistoryIcon.defaultProps = {
  className: '',
  height: '20px',
  width: '20px',
};

export default HistoryIcon;
