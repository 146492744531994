// @flow
import * as React from 'react';
import Link from 'react-router-redux-dom-link';
import styled, { css } from 'styled-components';

const StyledDiv = styled.div`
  background: white;
  border-radius: 7px;
  box-shadow: 0 1px 4px 0 ${props => props.theme.colors.shadowGrey};
  margin: 0 auto;
  margin-bottom: 20px;
  padding: ${props => {
      if (props.noPadding) {
        return '';
      } else if (props.panelled) {
        return '10px';
      } else {
        return '20px';
      }
    }}
    0;
  border-left: ${props => (props.tab ? '7px' : '0px')} solid
    ${props => (props.tab ? props.theme.colors.tabs[props.tab] : 'transparent')};

  > p {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    display: ${props => (props.panelled ? 'flex' : 'block')};
    padding: ${props => {
        if (props.noPadding || props.panelled) {
          return '';
        } else {
          return '30px';
        }
      }}
      0;
    .error + & {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.danger {
    background-color: ${props => props.theme.colors.warningRed};
    color: white;
  }

  &.disabled {
    background-color: ${props => props.theme.colors.midLightGrey};
  }
`;

export type ModuleProps = {
  className?: string,
  children: React.Node,
  id?: string,
  tab?: null,
  panelled?: null,
  noPadding?: boolean,
};

const Module = ({
  className,
  children,
  id,
  tab = null,
  panelled = null,
  noPadding = false,
}: ModuleProps) => {
  return (
    <StyledDiv
      id={id}
      className={className}
      tab={tab}
      panelled={panelled}
      noPadding={noPadding}
    >
      {children}
    </StyledDiv>
  );
};

Module.defaultProps = {
  className: '',
  id: '',
  tab: null,
  panelled: null,
};

export const ModulePanel = styled.div`
  background: white;
  border-bottom: 1px solid ${props => props.theme.colors.uiGrey};
  flex-basis: 0;
  flex-grow: ${props => (props.size ? props.size : 1)};
  flex-shrink: ${props => (props.size ? props.size : 1)};
  padding: 10px 0;

  @media (min-width: 768px) {
    border-bottom: 0;
    border-right: 1px solid ${props => props.theme.colors.uiGrey};
    padding: 0;
  }

  &:last-child {
    border: none;
    border-radius: inherit;
  }

  > h2 {
    margin-top: 10px;
    @media (min-width: 768px) {
      margin-top: 20px;
    }
  }

  > h3 {
    background: white;
    font-weight: bold;
    padding: 10px 20px 0;
    margin-bottom: -10px;
    @media (min-width: 768px) {
      border-bottom: 1px solid ${props => props.theme.colors.uiGrey};
      margin: 0;
      padding: 10px;
      text-align: center;
    }
  }

  > p {
    margin: 20px;
    text-align: center;
  }

  > div {
    padding: 10px 20px;
    @media (min-width: 768px) {
      padding: 20px;
      text-align: center;
    }
  }

  @media (min-width: 768px) {
    &.disabled {
      background: ${props => props.theme.colors.lightGrey};
      > h3 {
        background: #e8e8e8;
        border-color: ${props => props.theme.colors.lightGrey};
        color: ${props => props.theme.colors.midGrey};
      }
    }
  }

  &.complete {
    > h3 {
      color: ${props => props.theme.colors.midGrey};
    }
  }
  @media (min-width: 768px) {
    &.complete {
      > h3 {
        background: ${props => props.theme.colors.changeGreen};
        border-color: ${props => props.theme.colors.changeGreen};
        color: white;
      }
    }
  }
`;

export const StyledHeader = styled.h2`
  display: block;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
  margin-left: ${props => (props.noIndent ? '' : '20px')};
  @media (min-width: 768px) {
    margin-bottom: 20px;
  }

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `}
`;

type ModuleHeaderProps = {
  className: string,
  children: React.Node,
  centered: boolean,
  noIndent: boolean,
};

export const ModuleHeader = ({
  className,
  children,
  centered,
  noIndent,
}: ModuleHeaderProps) => (
  <StyledHeader centered={centered} className={className} noIndent={noIndent}>
    {children}
  </StyledHeader>
);

ModuleHeader.defaultProps = {
  className: '',
  noIndent: false,
  centered: false,
};

export const BorderedHeader: typeof ModuleHeader = styled(ModuleHeader)`
  border-bottom: 1px solid ${props => props.theme.colors.uiGrey};
  margin: 0 0 20px;
  padding: 0 0 20px 20px;
`;

export const SectionHeader: typeof ModuleHeader = styled(ModuleHeader)`
  &:not(:first-child) {
    border-top: 1px solid ${props => props.theme.colors.uiGrey};
    padding-top: 20px;
  }
  margin: 0 0 20px;
  padding-left: 20px;
`;

const StyledHero = styled.div`
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  padding: 20px;
  color: ${props => props.theme.colors.midGrey};
`;

type ModuleHeroProps = {
  children: React.Node,
};

export const ModuleHero = ({ children }: ModuleHeroProps) => (
  <StyledHero>{children}</StyledHero>
);

const StyledSubheader = styled.h3`
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: ${({ noMargin }) => (noMargin ? '0' : '20px')};
  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`;

type ModuleSubheaderProps = {
  children: React.Node,
  className?: string,
  noMargin?: boolean,
};

export const ModuleSubheader = ({
  className,
  children,
  noMargin,
}: ModuleSubheaderProps) => (
  <StyledSubheader noMargin={noMargin} className={className}>
    {children}
  </StyledSubheader>
);

ModuleSubheader.defaultProps = {
  className: '',
};

const StyledLinkFooter = styled.div`
  display: block;
  padding: 20px 20px 0;
`;

type ModuleLinkFooterProps = {
  to: string,
  children: React.Element<any>,
  className?: string,
};

export const ModuleLinkFooter = ({
  to,
  className,
  children,
}: ModuleLinkFooterProps) => (
  <StyledLinkFooter className={className}>
    <Link to={to}>{children}</Link>
  </StyledLinkFooter>
);

ModuleLinkFooter.defaultProps = {
  className: null,
};

export const ModuleMeta = styled.div`
  margin-bottom: 10px;
  > span,
  > div {
    display: inline-block;
    margin-bottom: 10px;
    width: 50%;
    &:only-child {
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    text-align: right;
    > span,
    > div {
      display: block;
      margin-bottom: 0;
      width: auto;
    }
  }
`;

export const ModuleMessage = styled.div`
  margin: ${({ noMargin }) => (noMargin ? '0' : '0 20px 20px 20px')};
  font-size: 20px;
  font-weight: 600;
`;

export const ModuleSeparator = styled.div`
  width: 100%;
  height: 20px;
`;

// export const BorderedModuleSeparator: typeof ModuleSeparator = styled(ModuleSeparator)`
// $FlowFixMe
export const BorderedModuleSeparator = styled(ModuleSeparator)`
  border-bottom: 1px solid ${props => props.theme.colors.uiGrey};
`;

export const ModuleText = styled.div`
  margin: 20px;

  &.danger {
    color: ${props => props.theme.colors.warningRed};
  }


`;

export default Module;
