import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getUser } from 'shared/selectors/accountSelectors';

function InvoiceLink({ slug, user }) {
  return (
    <a
      href={`${
        window.global.SUPERCARERS_API_URL
      }/invoice/${slug}/view/pdf/?token=${user.access_token}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Download PDF
    </a>
  );
}

InvoiceLink.propTypes = {
  slug: PropTypes.string.isRequired,
  user: PropTypes.shape(),
};

InvoiceLink.defaultProps = {
  user: null,
};

function mapStateToProps(state) {
  return {
    user: getUser(state),
  };
}

export default connect(mapStateToProps)(InvoiceLink);
