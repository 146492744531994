// @flow
import React from 'react';
// $FlowFixMe
import { Field } from 'redux-form';

import { trim } from 'normalizers';
import { matchesPassword } from 'validators';

import { Container, Col, Row } from 'components/grid';
import FormRow from 'components/forms/FormRow';
import StandardInput from 'components/forms/StandardInput';
import StyledLinkLogo from 'components/global/StyledLinkLogo';
import Button from 'components/buttons/Button';

type Props = {
  handleSubmit: () => void,
  pristine: () => void,
  error: ?string,
};

function AccountConfirmForm({ handleSubmit, pristine, error }: Props) {
  return (
    <form id="account-confirm-form" onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col xs={12} md={11}>
            <StyledLinkLogo />
          </Col>
        </Row>
        <FormRow>
          <Col xs={12} md={12}>
            <Field
              name="password"
              label="Create Password"
              component={StandardInput}
              type="password"
              normalize={trim}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col xs={12} md={12}>
            <Field
              name="confirm_password"
              label="Confirm Password"
              component={StandardInput}
              type="password"
              validate={[matchesPassword]}
              normalize={trim}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col xs={12} md={12}>
            <Button type="submit" disabled={pristine}>
              Set Password
            </Button>
          </Col>
        </FormRow>
      </Container>
      <Field name="token" component="input" type="hidden" />
    </form>
  );
}

export default AccountConfirmForm;
