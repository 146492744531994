import PropTypes from 'prop-types';
import React from 'react';

const MarkCompliant = ({ markCompliant, compliant, message }) => {
  if (compliant) {
    return (
      <div
        role="button"
        className={`status`}
        onClick={() => markCompliant(false)}
      >
        Revert override to {message}
      </div>
    );
  } else {
    return (
      <div
        role="button"
        className={`status`}
        onClick={() => markCompliant(true)}
      >
        Override to {message}
      </div>
    );
  }
};

MarkCompliant.propTypes = {
  markCompliant: PropTypes.func.isRequired,
  compliant: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default MarkCompliant;
