import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Col, Row } from 'components/grid';

import FormRow from 'components/forms/FormRow';
import StyledInput from 'components/forms/StyledInput';
import StyledLabel from 'components/forms/StyledLabel';
import StyledTextarea from 'components/forms/StyledTextarea';
import List from 'components/global/informationdisplay/List';
import Module, {
  ModuleHeader,
  ModuleSubheader,
} from 'components/global/layout/Module';
import ExpenseRow from 'components/timesheets/ExpenseRow';

class ExpensesForm extends Component {
  static propTypes = {
    expenses: PropTypes.arrayOf(PropTypes.shape()),
    mileage: PropTypes.shape(),
    updateMileage: PropTypes.func.isRequired,
    updateExpense: PropTypes.func.isRequired,
    deleteExpense: PropTypes.func.isRequired,
    newExpense: PropTypes.shape(),
    addExpense: PropTypes.func.isRequired,
    updateNewExpense: PropTypes.func.isRequired,
  };

  static defaultProps = {
    expenses: [],
    mileage: {},
    newExpense: null,
  };
  render() {
    const expenses = this.props.expenses || [];
    const mileage = this.props.mileage || {};

    return (
      <Module>
        <ModuleHeader>Expenses</ModuleHeader>

        <Container>
          <Row>
            <Col>Please record any expenses to be reimbursed here.</Col>
          </Row>
        </Container>

        <ModuleSubheader>Weekly Mileage (Petrol Expense)</ModuleSubheader>
        <Container>
          <Row>
            <Col>
              Note that, unless pre-agreed with your customer, travel costs for
              hourly and overnight care shifts should not be passed on.
            </Col>
          </Row>

          <FormRow>
            <Col xs={12} md={9}>
              <StyledLabel htmlFor="description">
                Details of Journey(s)
              </StyledLabel>
              <StyledTextarea
                name="description"
                value={mileage.description || ''}
                onChange={e => this.props.updateMileage(e)}
                placeholder="Short description of any trips taken"
              />
            </Col>
            <Col xs={12} md={3}>
              <StyledLabel htmlFor="miles">Total Miles Travelled</StyledLabel>
              <StyledInput
                name="miles"
                type="number"
                value={Math.round(mileage.miles) || ''}
                onChange={e => this.props.updateMileage(e)}
                min="0"
                step="1"
              />
            </Col>
          </FormRow>
        </Container>

        <ModuleSubheader>Other Expenses</ModuleSubheader>
        <List>
          {expenses.map((expense, index) => (
            <ExpenseRow
              key={expense.description}
              index={index}
              expense={expense}
              onUpdateExpense={this.props.updateExpense}
              deleteExpense={this.props.deleteExpense}
            />
          ))}

          <ExpenseRow
            expense={this.props.newExpense}
            addExpense={this.props.addExpense}
            onUpdateExpense={this.props.updateNewExpense}
          />
        </List>
      </Module>
    );
  }
}

export default ExpensesForm;
