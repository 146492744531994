import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import stateHelper from 'components/helpers/stateHelper';
import Currency from 'components/modules/Currency';
import Days from 'components/modules/Days';

import Hours from 'components/modules/Hours';
import ButtonLink from 'components/buttons/ButtonLink';
import StatusIndicator from 'components/global/StatusIndicator';

class TimesheetTableRow extends Component {
  static propTypes = {
    timesheet: PropTypes.shape({
      id: PropTypes.string.isRequired,
      start_date: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      charge_status: PropTypes.string.isRequired,
      charge_transferred_at: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { timesheet } = this.props;

    const weekCommencing = moment(timesheet.start_date)
      .startOf('isoweek')
      .format('dddd, MMMM Do');

    const state = stateHelper.timesheetDisplayState(timesheet);

    let action;
    if (timesheet.state === 'new') {
      action = (
        <ButtonLink to={`/timesheets/${timesheet.id}/`}>
          Start timesheet
        </ButtonLink>
      );
    } else {
      action = (
        <ButtonLink className="alt" to={`/timesheets/${timesheet.id}/confirm/`}>
          View
        </ButtonLink>
      );
    }

    let time = '-';
    let amount = '-';
    const totals = timesheet.totals;
    if (totals) {
      if (totals.total.livein_days) {
        time = <Days context={'Live-in'} days={totals.total.livein_days} />;
      } else if (totals.total.minutes_total) {
        time = <Hours minutes={totals.total.minutes_total} />;
      }
      amount = <Currency value={totals.total.carer_total} />;
    }

    return (
      <tr>
        <td data-heading="Week">{weekCommencing}</td>
        {timesheet.need_name && (
          <td data-heading="Client">{timesheet.need_name}</td>
        )}
        {!timesheet.need_name && (
          <td data-heading="Customer">{timesheet.customer_name}</td>
        )}
        <td data-heading="Time">{time}</td>
        <td data-heading="Amount">{amount}</td>
        <td data-heading="Status">
          <StatusIndicator status={state} />
        </td>
        <td>{action}</td>
      </tr>
    );
  }
}

export default TimesheetTableRow;
