// @flow
import React, { Component } from 'react';
import Form from 'components/forms/Form';
import Module from 'components/global/layout/Module';

import CarerSearchForm from 'components/carers/carersearch/CarerSearchForm';
import CarerSearchFormNoFilters from 'components/carers/carersearch/CarerSearchFormNoFilters';

import type { TaxonomiesClassificationType } from 'shared/types';

type Props = {
  noFilters: boolean,
  initialSearch: ?{
    state?: string,
    work_state?: string,
    care_team_state?: Array<string>,
    work_preference?: Array<string>,
  },
  name: string,
  onSubmit: Object => void,
  taxonomiesForClassification: TaxonomiesClassificationType,
};

class CarerSearch extends Component<Props> {
  static defaultProps = {
    noFilters: false,
    initialSearch: {},
    name: 'carerSearch',
  };

  render() {
    const {
      noFilters,
      taxonomiesForClassification,
      onSubmit,
      name,
      initialSearch,
    } = this.props;

    const fields = noFilters
      ? []
      : [
          'name',
          'postcode',
          'distance',
          'gender',
          'is_driver',
          'likes_animals',
          'has_car',
        ];

    const FormComponent = noFilters
      ? CarerSearchFormNoFilters
      : CarerSearchForm;

    return (
      <Module>
        <Form
          destroyOnUnmount={false}
          enableReinitialize={false}
          name={name}
          fields={fields}
          onSubmit={onSubmit}
          values={initialSearch}
          component={({ handleSubmit, reset, pristine }) => (
            <FormComponent
              handleSubmit={handleSubmit}
              reset={reset}
              pristine={pristine}
              taxonomiesForClassification={taxonomiesForClassification}
            />
          )}
        />
      </Module>
    );
  }
}

export default CarerSearch;
