import CurrencyInput from 'components/forms/CurrencyInput';
import FormRow from 'components/forms/FormRow';
import Input from 'components/forms/Input';
import StyledInput from 'components/forms/StyledInput';
import StyledLabel from 'components/forms/StyledLabel';
import StyledSelect from 'components/forms/StyledSelect';
import Module, {
  ModuleHeader,
  ModuleMeta,
} from 'components/global/layout/Module';
import Button from 'components/buttons/Button';
import ButtonGroup from 'components/buttons/ButtonGroup';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Col, Row } from 'components/grid';

import styled from 'styled-components';
import StatusIndicator from 'components/global/StatusIndicator';

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

const FulfilmentType = styled.span`
  color: ${props => props.theme.colors.statusGreen};
  display: inline-block;
  white-space: nowrap;
  font-weight: 600;
`;

class AdminChargeForm extends Component {
  static propTypes = {
    carers: PropTypes.arrayOf(PropTypes.shape()),
    charge: PropTypes.shape(),
    chargeState: PropTypes.string,
    consumer: PropTypes.shape().isRequired,
    edit: PropTypes.bool,
    saveCharge: PropTypes.func.isRequired,
    updateValue: PropTypes.func.isRequired,
    voidCharge: PropTypes.func,
  };

  static defaultProps = {
    charge: {},
    carers: [],
    chargeState: null,
    edit: false,
    voidCharge: null,
  };

  render() {
    let feePercent = 0;
    const consumer = this.props.consumer;
    const charge = this.props.charge;
    const disabled =
      this.props.chargeState === 'paid' || charge.charge_type === 'custom';
    const carerAmount = (charge.unit || 0) - (charge.application_fee || 0);

    if (charge.application_fee && charge.unit) {
      feePercent = charge.application_fee / charge.unit;
    }

    return (
      <Module>
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <StyledHeader>
                {this.props.edit ? 'Edit' : 'Create'} Charge
              </StyledHeader>
            </Col>
            <Col xs={12} md={8}>
              <ModuleMeta>
                <StatusIndicator status={this.props.chargeState} />
                {charge.fulfilment_type === 'stripe' && (
                  <FulfilmentType>USING STRIPE</FulfilmentType>
                )}
                {charge.fulfilment_type === 'manual' && (
                  <FulfilmentType>MANUALLY</FulfilmentType>
                )}
              </ModuleMeta>
            </Col>
          </Row>
          <FormRow>
            <Col xs={12} md={6}>
              <StyledLabel htmlFor="customer">Customer</StyledLabel>
              <StyledInput name="customer" value={consumer.name} readOnly />
            </Col>
            <Col xs={12} md={6}>
              <StyledLabel htmlFor="carer">Carer</StyledLabel>
              <StyledSelect
                name="carer"
                onChange={e =>
                  this.props.updateValue('carer_id', e.target.value)
                }
                value={charge.carer_id}
                disabled={disabled}
              >
                <option key="none" value="">
                  Please select
                </option>
                <option
                  key="44162539-05f8-40a7-90ff-3272be10a85d"
                  value="44162539-05f8-40a7-90ff-3272be10a85d"
                >
                  SuperCarers
                </option>
                {this.props.carers &&
                  this.props.carers.map(carer => (
                    <option key={carer.id} value={carer.id}>
                      {carer.display_name}
                    </option>
                  ))}
              </StyledSelect>
            </Col>
          </FormRow>
          <FormRow>
            <Col xs={12} md={4}>
              <StyledLabel htmlFor="unit">Customer pays</StyledLabel>
              <CurrencyInput
                name="unit"
                onChange={this.props.updateValue}
                value={charge.unit}
              />
            </Col>
            <Col xs={12} md={4}>
              <StyledLabel htmlFor="carer_amount">Carer receives</StyledLabel>
              <CurrencyInput name="carer_amount" value={carerAmount} readOnly />
            </Col>
            <Col xs={12} md={4}>
              <StyledLabel htmlFor="application_fee">
                Commission ({(feePercent * 100).toFixed(0)}
                %)
              </StyledLabel>
              <CurrencyInput
                name="application_fee"
                onChange={this.props.updateValue}
                value={charge.application_fee}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col xs={12}>
              <StyledLabel htmlFor="description">Description</StyledLabel>
              <Input
                name="description"
                value={charge.description}
                onChange={this.props.updateValue}
                readOnly={disabled}
              />
            </Col>
          </FormRow>
          {this.props.chargeState !== 'paid' && (
            <FormRow>
              <ButtonGroup>
                <Button
                  disabled={disabled || !charge.carer_id || !charge.unit}
                  onClick={this.props.saveCharge}
                >
                  {this.props.edit ? 'Update Charge' : 'Create Charge'}
                </Button>
                {this.props.voidCharge && (
                  <Col xs={12} md={4}>
                    <Button className="danger" onClick={this.props.voidCharge}>
                      Void Charge
                    </Button>
                  </Col>
                )}
              </ButtonGroup>
            </FormRow>
          )}
        </Container>
      </Module>
    );
  }
}

export default AdminChargeForm;
