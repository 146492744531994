import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminUserOnboarding from 'components/users/AdminUserOnboarding';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { updateCarerProfile } from '_actions/carerActions';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

class AdminUserOnboardingContainer extends Component {
  static propTypes = {
    user: PropTypes.shape().isRequired,
    profile: PropTypes.shape().isRequired,
    updateCarerProfile: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.shape(),
  };

  static defaultProps = {
    taxonomiesByClassification: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'care_experience_types',
      'care_experience_types',
      'carer_profile_care_team_states',
      'carer_profile_states',
      'carer_profile_work_preferences',
      'fountain_funnel',
      'organization_country',
      'rejection_reasons',
      'source',
      'work_state',
    ];
  }

  updateCarerProfile = data => {
    if (data.care_team_state) {
      delete data.care_team_state;
    }

    delete data.fountain_id;

    this.props.updateCarerProfile(this.props.user.id, data, true, true);
  };

  render() {
    const { profile, taxonomiesByClassification } = this.props;
    if (!taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }
    return (
      <AdminUserOnboarding
        data={{ ...profile, ...profile.profile_data }}
        getTaxonomiesByClassifications={
          this.props.getTaxonomiesByClassifications
        }
        updateCarerProfile={this.updateCarerProfile}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

const mapStateToProps = state => ({
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    AdminUserOnboardingContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateCarerProfile,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserOnboardingContainer);
