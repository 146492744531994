// @flow
import moment from 'moment';
import ApiConstants from 'shared/constants/ApiConstants';
import CareNeedConstants from 'shared/constants/CareNeedConstants';
import { ExtractReturn } from 'shared/types/ExtractReturn';

import type {
  APIAction,
  NeedType,
  SchedulesType,
  UserType,
  UpdateNeedAndCustomerAction,
  GetNeedByIdWithUserAction,
  RecalculateValueSuccessAction,
} from 'shared/types';

export function getNeedByID(needID: string): APIAction {
  return {
    endpoint: `/care/need/${needID}/`,
    method: 'get',
    onError: { type: CareNeedConstants.CARE_NEED_GET_BY_ID_FAILURE },
    onStart: { type: CareNeedConstants.CARE_NEED_GET_BY_ID },
    onSuccess: { type: CareNeedConstants.CARE_NEED_GET_BY_ID_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function adminCreateNeed(payload: {
  customerData: UserType,
  needData: NeedType,
  schedules: SchedulesType,
}): UpdateNeedAndCustomerAction {
  return {
    type: CareNeedConstants.ADMIN_CREATE_NEED,
    customerData: payload.customerData,
    needData: payload.needData,
    schedules: payload.schedules,
  };
}

export function createNeed(payload: APIAction, redirect: ?boolean = null) {
  return {
    endpoint: '/care/need/',
    method: 'post',
    onError: { type: CareNeedConstants.CARE_NEED_CREATE_FAILURE },
    onSuccess: { type: CareNeedConstants.CARE_NEED_CREATE_SUCCESS },
    onStart: { type: CareNeedConstants.CARE_NEED_CREATE },
    payload,
    redirect,
    type: ApiConstants.API_REQUEST,
  };
}

export function updateNeedAndCustomer(payload: {
  customerData: UserType,
  needData: NeedType,
  schedules: SchedulesType,
}): UpdateNeedAndCustomerAction {
  return {
    type: CareNeedConstants.NEED_AND_CUSTOMER_UPDATE,
    customerData: payload.customerData,
    needData: payload.needData,
    schedules: payload.schedules,
  };
}

export function updateNeed(payload: NeedType, redirect: string): APIAction {
  return {
    endpoint: `/care/need/${payload.id}/`,
    method: 'put',
    onError: { type: CareNeedConstants.CARE_NEED_UPDATE_FAILURE },
    onStart: { type: CareNeedConstants.CARE_NEED_UPDATE },
    onSuccess: {
      type: CareNeedConstants.CARE_NEED_UPDATE_SUCCESS,
      toast: payload.autosave
        ? null
        : {
            type: 'success',
            public: false,
            title: 'Care details updated',
          },
    },
    payload,
    redirect,
    type: ApiConstants.API_REQUEST,
  };
}

export function setNeedArchived(needId: string, archive: boolean): APIAction {
  const status = archive ? 'archived' : 'unarchived';
  return {
    endpoint: `/care/need/${needId}/archive/`,
    method: 'put',
    onError: { type: CareNeedConstants.CARE_NEED_ARCHIVE_FAILURE },
    onStart: { type: CareNeedConstants.CARE_NEED_ARCHIVE },
    onSuccess: {
      type: CareNeedConstants.CARE_NEED_ARCHIVE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: `Care need ${status}`,
      },
    },
    payload: { archived: archive },
    redirect: archive ? '/home/' : `/admin/needs/${needId}/#care-need`,
    type: ApiConstants.API_REQUEST,
  };
}

export function getNeedsByUserId(userId: string): APIAction {
  return {
    endpoint: `/care/need/by/owner/${userId}/`,
    method: 'get',
    onError: { type: CareNeedConstants.CARE_NEEDS_GET_BY_USER_FAILURE },
    onStart: { type: CareNeedConstants.CARE_NEEDS_GET_BY_USER },
    onSuccess: { type: CareNeedConstants.CARE_NEEDS_GET_BY_USER_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function getNeedsByCarerId(carerId: string): APIAction {
  return {
    endpoint: `/care/need/by/carer/${carerId}/`,
    method: 'get',
    onError: { type: CareNeedConstants.CARE_NEEDS_GET_BY_CARER_FAILURE },
    onStart: { type: CareNeedConstants.CARE_NEEDS_GET_BY_CARER },
    onSuccess: { type: CareNeedConstants.CARE_NEEDS_GET_BY_CARER_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function generateNeedTimesheets(
  needId: string,
  week: string,
): APIAction {
  const data = { needs: [needId] };
  return {
    endpoint: `/timesheet/generate/for/week/using/date/${week}/`,
    method: 'put',
    onError: { type: CareNeedConstants.CARE_NEED_TIMESHEET_GENERATE_FAILURE },
    onStart: { type: CareNeedConstants.CARE_NEED_TIMESHEET_GENERATE },
    onSuccess: {
      type: CareNeedConstants.CARE_NEED_TIMESHEET_GENERATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Timesheets generated.',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function generateAdhocTimesheet(
  needId: string,
  carerId: string,
  date: moment,
) {
  const isoWeek = date.isoWeek();
  const paddedIsoWeek = isoWeek / 10 < 1 ? `0${isoWeek}` : isoWeek;
  const isoYearWeek = `${date.isoWeekYear()}W${paddedIsoWeek}`;
  const data = {
    need_id: needId,
    carer_id: carerId,
    iso_week: isoYearWeek,
    adhoc: true,
  };
  return {
    endpoint: '/timesheet/',
    method: 'post',
    onError: { type: CareNeedConstants.CARE_NEED_TIMESHEET_GENERATE_FAILURE },
    onStart: { type: CareNeedConstants.CARE_NEED_TIMESHEET_GENERATE },
    onSuccess: {
      type: CareNeedConstants.CARE_NEED_TIMESHEET_GENERATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Timesheet generated.',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function getNeedsStatus(): APIAction {
  return {
    endpoint: '/care/need/state/',
    method: 'get',
    onError: { type: CareNeedConstants.CARE_NEED_GET_STATUS_FAILURE },
    onStart: { type: CareNeedConstants.CARE_NEED_GET_STATUS },
    onSuccess: { type: CareNeedConstants.CARE_NEED_GET_STATUS_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function getAccountOwner(needID: string): APIAction {
  return {
    endpoint: `/care/need/${needID}/accountowner/`,
    method: 'get',
    onError: { type: CareNeedConstants.CARE_NEED_GET_ACCOUNT_OWNER_FAILURE },
    onStart: { type: CareNeedConstants.CARE_NEED_GET_ACCOUNT_OWNER },
    onSuccess: {
      type: CareNeedConstants.CARE_NEED_GET_ACCOUNT_OWNER_SUCCESS,
      needID,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function convertNeed(needId: string, data: Object): APIAction {
  return {
    endpoint: `/care/need/${needId}/convert/`,
    method: 'put',
    onError: { type: CareNeedConstants.CARE_NEED_CONVERT_FAILURE },
    onStart: { type: CareNeedConstants.CARE_NEED_CONVERT },
    onSuccess: {
      type: CareNeedConstants.CARE_NEED_CONVERT_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Customer converted. Do you still need to fulfill the job?',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function searchNeeds(params: Object, page: string): APIAction {
  const query = Object.assign({ page: page || 1 }, params);
  return {
    endpoint: '/care/need/find/',
    method: 'get',
    onError: { type: CareNeedConstants.CARE_NEED_SEARCH_FAILURE },
    onStart: { type: CareNeedConstants.CARE_NEED_SEARCH },
    onSuccess: { type: CareNeedConstants.CARE_NEED_SEARCH_SUCCESS },
    payload: query,
    type: ApiConstants.API_REQUEST,
  };
}

export function getNeedByIdWithUser(needId: string): GetNeedByIdWithUserAction {
  return {
    type: CareNeedConstants.CARE_NEED_GET_BY_ID_WITH_USER,
    needId,
  };
}

export function recalculateTotalProjectedValue(
  needId: string,
): RecalculateValueSuccessAction {
  return {
    endpoint: `/care/need/${needId}/totals/`,
    method: 'put',
    onError: { type: CareNeedConstants.CARE_NEED_RECALCULATE_TOTAL_FAILURE },
    onStart: { type: CareNeedConstants.CARE_NEED_RECALCULATE_TOTAL },
    onSuccess: { type: CareNeedConstants.CARE_NEED_RECALCULATE_TOTAL_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export type NeedsActions = ExtractReturn<typeof getNeedByIdWithUser>;
