//@flow
import React from 'react';
import Button from 'components/buttons/Button';

type UpdateNeedButtonProps = {|
  invalid: boolean,
  handleSubmit: (() => {}) => any,
  onSubmit: (Object, ?string) => any,
  need: Object,
  needSubmitted: boolean,
|};

export const UpdateNeedButton = ({
  invalid,
  handleSubmit,
  onSubmit,
  need,
  needSubmitted,
}: UpdateNeedButtonProps) => (
  <Button
    disabled={invalid}
    type="submit"
    fullWidth
    onClick={handleSubmit(data => onSubmit({ ...data, id: need.id }))}
  >
    {!needSubmitted ? 'Save progress' : 'Update details'}
  </Button>
);

type SubmitNeedButtonProps = {|
  handleSubmit: (() => {}) => any,
  onSubmit: (Object, ?string) => any,
  need: Object,
  disabled: boolean,
|};

export const SubmitNeedButton = ({
  handleSubmit,
  onSubmit,
  need,
  disabled,
}: SubmitNeedButtonProps) => (
  <Button
    disabled={disabled}
    type="submit"
    className="altGreen"
    fullWidth
    onClick={handleSubmit(data =>
      onSubmit(
        {
          ...data,
          id: need.id,
          state: 'need_submitted',
        },
        '/home/',
      ),
    )}
  >
    Submit
  </Button>
);
