// @flow
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import StandardTable from 'components/global/layout/StandardTable';
import TimesheetTotalCommission from 'components/timesheets/TimesheetTotalCommission';
import TimesheetTotalCustomer from 'components/timesheets/TimesheetTotalCustomer';
import TimesheetTotalDay from 'components/timesheets/TimesheetTotalDay';
import TimesheetTotalExpenses from 'components/timesheets/TimesheetTotalExpenses';
import TimesheetTotalCarer from 'components/timesheets/TimesheetTotalCarer';
import TimesheetTotalCarerGross from 'components/timesheets/TimesheetTotalCarerGross';
import TimesheetTotalRefunds from 'components/timesheets/TimesheetTotalRefunds';

import type {
  RefundType,
  TaxonomiesClassificationType,
  TimesheetType,
  TotalType,
  TotalsType,
} from 'shared/types';

type Props = {
  isAdmin: boolean,
  refunds: Array<RefundType>,
  taxonomiesByClassification: TaxonomiesClassificationType,
  timesheet: TimesheetType,
  timesheetTotal: TotalType,
  totals: TotalsType,
  view: string,
};

const TimesheetTable = styled(StandardTable)`
  @media (min-width: 768px) {
    tbody {
      border-bottom: 1px solid ${props => props.theme.colors.uiGrey};
      > tr {
        border-bottom: none;
        > td {
          min-width: 100px;
          &:first-child {
            padding: 10px;
            &[rowspan] {
              padding-left: 20px;
            }
          }
        }
        background-color: transparent !important;
        &.multiple {
          border-bottom: none;
        }
        &.even {
          background-color: ${props =>
            props.theme.colors.lightestGrey} !important;
        }
        &.total {
          td {
            padding-top: 30px !important;
            padding-bottom: 30px !important;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    > table {
      border-top: 1px solid ${props => props.theme.colors.uiGrey};
    }
    tbody {
      border: none;
      > tr {
        border-bottom: none;
      }
    }
  }
`;

const TimesheetTotal = ({
  isAdmin,
  refunds,
  taxonomiesByClassification,
  timesheet,
  timesheetTotal,
  totals,
  view,
}: Props) => {
  const groupedShifts = {};

  totals.shifts.forEach(shift => {
    const date = moment(shift.start).format('YYYY-MM-DD');
    if (!groupedShifts[date]) {
      groupedShifts[date] = [];
    }
    groupedShifts[date].push(shift);
  });

  return (
    <TimesheetTable>
      {Object.keys(groupedShifts)
        .sort()
        .map((date, index) => (
          <TimesheetTotalDay
            key={index}
            index={index}
            view={view}
            date={date}
            shifts={groupedShifts[date]}
            rates={totals.rates}
            isAdmin={isAdmin}
            taxonomiesByClassification={taxonomiesByClassification}
          />
        ))}
      {timesheet.expenses && timesheet.expenses.length > 0 && (
        <TimesheetTotalExpenses expenses={timesheet.expenses} />
      )}
      <TimesheetTotalCarerGross
        timesheetTotal={timesheetTotal}
        isAdmin={isAdmin}
        view={view}
      />
      {view === 'customer' && (
        <TimesheetTotalCommission
          discounts={totals.discounts}
          isAdmin={isAdmin}
          timesheetTotal={timesheetTotal}
          view={view}
        />
      )}
      {refunds && refunds.length > 0 && (
        <TimesheetTotalRefunds
          view={view}
          refunds={refunds}
          isAdmin={isAdmin}
        />
      )}
      {view === 'customer' && (
        <TimesheetTotalCustomer timesheetTotal={timesheetTotal} />
      )}
      {view !== 'customer' &&
        (isAdmin ||
          !!timesheetTotal.insurance_payment ||
          (refunds && refunds.length > 0)) && (
          <TimesheetTotalCarer
            timesheetTotal={timesheetTotal}
            isAdmin={isAdmin}
          />
        )}
      {view !== 'customer' && isAdmin && (
        <TimesheetTotalCommission
          discounts={totals.discounts}
          timesheetTotal={timesheetTotal}
          view={view}
        />
      )}
    </TimesheetTable>
  );
};

export default TimesheetTotal;
