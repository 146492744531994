// @flow
import React from 'react';
import styled from 'styled-components';
// $FlowFixMe
import { Field } from 'redux-form';

import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import Button from 'components/buttons/Button';
import Paginator from 'components/global/Paginator';
import StandardInput from 'components/forms/StandardInput';
import { TaxonomySelect } from 'components/forms/ConvenienceSelects';
import Module, {
  ModuleHeader,
  ModuleMeta,
} from 'components/global/layout/Module';
import { Container, Row, Col } from 'components/grid';

import AdminFranchiseSearchResults from 'components/admin/franchise/AdminFranchiseSearchResults';

import type {
  LocationType,
  SearchType,
  TaxonomiesClassificationType,
} from 'shared/types';

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

type Props = {|
  franchises: Array<Object>,
  location: LocationType,
  search: SearchType,
  searchFranchise: (Object, number | string) => Object,
  taxonomiesByClassification: TaxonomiesClassificationType,
|};

const AdminFranchiseSearch = ({
  franchises,
  location,
  search,
  searchFranchise,
  taxonomiesByClassification,
}: Props) => {
  return (
    <div>
      <Form
        name="franchiseSearch"
        fields={[
          'fuzzy_name',
          'account_manager',
          'country',
          'postcode',
          'state',
        ]}
        onSubmit={searchFranchise}
        component={({ handleSubmit, reset }) => (
          <form onSubmit={handleSubmit}>
            <Module>
              <Container>
                <Row>
                  <Col xs={12} md={4}>
                    <StyledHeader>Find Franchise Offices</StyledHeader>
                  </Col>
                  <Col xs={12} md={8}>
                    <ModuleMeta>
                      <div role="button" onClick={reset}>
                        Reset filters
                      </div>
                    </ModuleMeta>
                  </Col>
                </Row>
                <FormRow>
                  <Col xs={12} md={6}>
                    <Field
                      component={StandardInput}
                      label="Franchise Name"
                      name="fuzzy_name"
                    />
                  </Col>
                  <Col xs={6} md={3}>
                    <Field
                      component={TaxonomySelect}
                      name="country"
                      type="text"
                      label="Country"
                      taxonomy={taxonomiesByClassification.franchise_country}
                    />
                  </Col>
                  <Col xs={6} md={3}>
                    <Field
                      component={StandardInput}
                      label="Postcode"
                      name="postcode"
                    />
                  </Col>
                </FormRow>
                <FormRow>
                  <Col xs={12} md={6}>
                    <Field
                      component={TaxonomySelect}
                      name="state"
                      type="text"
                      label="State"
                      taxonomy={taxonomiesByClassification.franchise_state}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      component={TaxonomySelect}
                      name="account_manager"
                      type="text"
                      label="Account Manager"
                      taxonomy={taxonomiesByClassification.account_manager}
                    />
                  </Col>
                </FormRow>
              </Container>

              <Container>
                <FormRow>
                  <Col xs={12} md={4}>
                    <Button type="submit">Search</Button>
                  </Col>
                </FormRow>
              </Container>
            </Module>
          </form>
        )}
      />
      <Module>
        <ModuleHeader>
          Search Results ({search && search.pagination && search.pagination.total})
        </ModuleHeader>
        <AdminFranchiseSearchResults
          franchises={franchises}
          taxonomiesByClassification={taxonomiesByClassification}
        />
        {search && search.pagination && (
          <Paginator
            currentPage={search.pagination.page}
            totalPages={search.pagination.pages}
            location={location}
          />
        )}
      </Module>
    </div>
  );
};

export default AdminFranchiseSearch;
