import React, { Component } from 'react';
import { Container, Row, Col } from 'components/grid';

import ButtonLink from 'components/buttons/ButtonLink';
import Module from 'components/global/layout/Module';

class QuestionnairePrompt extends Component {
  render() {
    return (
      <Module>
        <Container>
          <Row>
            <Col xs={12} md={10}>
              <p>
                <strong>Please complete your profile!</strong>
              </p>
              <p>
                Your profile is what we will show to potential customers, so
                that they can decide which carers they would like to meet. A
                more complete profile will greatly increase your chances of
                securing work, so please do take the time to do this well!
              </p>
              <br />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={10}>
              <ButtonLink to={'/carers/profile/'}>
                Complete your profile
              </ButtonLink>
            </Col>
          </Row>
        </Container>
      </Module>
    );
  }
}

export default QuestionnairePrompt;
