// @flow
import * as React from 'react';
import styled from 'styled-components';

const StyledDefinition = styled.div`
  margin: 0 0 10px;
  word-wrap: break-word;
  & > span:first-child {
    font-weight: bold;
    margin-right: 5px;
  }
`;

type Props = {
  label: string,
  children: React.Node,
  className?: string,
};

const Definition = ({ label, children, className }: Props) => (
  <StyledDefinition className={className}>
    <span>{label}:</span>
    {children}
  </StyledDefinition>
);

export default Definition;
