import PropTypes from 'prop-types';
import React, { Component } from 'react';

import StripeConnect from 'components/payments/StripeConnect';
import StripeIssueBanner from 'components/modules/stripe/StripeIssueBanner';

class StripeHandler extends Component {
  static propTypes = {
    user: PropTypes.shape(),
  };

  static defaultProps = {
    user: null,
  };

  render() {
    const { user } = this.props;

    if (!user) {
      return null;
    }
    if (!user.has_stripe_account) {
      return <StripeConnect carer={user} />;
    } else if (!user.stripe_account_enabled) {
      return (
        <StripeIssueBanner stripeErrorMessage={user.stripe_error_message} />
      );
    }

    return null;
  }
}

export default StripeHandler;
