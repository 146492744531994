import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AdminUserReferenceSummary from 'components/references/AdminUserReferenceSummary';

import {
  deleteReference,
  getReferencesByCarerID,
  updateReference,
  sendReference,
} from '_actions/referencesActions';
import { updateCarerProfile } from '_actions/carerActions';

import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import {
  selectReferencesByCarerID,
  selectReferencesSearch,
} from 'shared/selectors/referencesSelectors';


class AdminUserReferenceSummaryContainer extends Component {
  static propTypes = {
    deleteReference: PropTypes.func.isRequired,
    getReferencesByCarerID: PropTypes.func.isRequired,
    profile: PropTypes.shape().isRequired,
    references: PropTypes.arrayOf(PropTypes.shape()),
    search: PropTypes.shape(),
    sendReference: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.shape(),
    updateCarerProfile: PropTypes.func.isRequired,
    updateReference: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    references: null,
    search: null,
    taxonomiesByClassification: {},
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['reference_roles', 'reference_relationships', 'reference_responses'];
  }

  componentDidMount() {
    this.props.getReferencesByCarerID(this.props.user.id);
  }

  deleteReference = referenceID => {
    this.props.deleteReference(referenceID, this.props.user.id)
  };

  inviteReference = referenceId => {
    this.props.updateReference(referenceId, { state: 'invited' });
  };

  submitReference = referenceId => {
    this.props.updateReference(referenceId, { state: 'submitted' });
  };

  sendReference = formData => {
    this.props.sendReference({...formData, 'user_id': this.props.user.id})
  };

  addReferenceResponse = (data, reference, closePortal) => {
    this.props.updateReference(reference.id, {
      state: 'responded',
      ...data,
    });
    closePortal();
  };

  markReferencesHICompliant = value => {
    this.props.updateCarerProfile(this.props.user.id, {
      hi_references_mark_compliant: value,
    });
  };

  render() {
    const { references, taxonomiesByClassification } = this.props;

    if (!references || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    const sortedReferences = [...references];
    sortedReferences.sort((a, b) =>
      this.props.search.searchOrder
        ? this.props.search.searchOrder.indexOf(a.id) -
          this.props.search.searchOrder.indexOf(b.id)
        : null,
    );

    return (
      <AdminUserReferenceSummary
        addReferenceResponse={this.addReferenceResponse}
        carer={this.props.user}
        deleteReference={this.deleteReference}
        inviteReference={this.inviteReference}
        markReferencesHICompliant={this.markReferencesHICompliant}
        references={sortedReferences}
        referencesHICompliant={(this.props.profile.hi_compliance_fields || {}).references}
        referencesMarkedHICompliant={this.props.profile.hi_references_mark_compliant}
        sendReference={this.sendReference}
        submitReference={this.submitReference}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  references: selectReferencesByCarerID(state, props.user.id),
  search: selectReferencesSearch(state),
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    AdminUserReferenceSummaryContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteReference,
      getReferencesByCarerID,
      updateCarerProfile,
      updateReference,
      sendReference,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserReferenceSummaryContainer);
