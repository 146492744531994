export const selectWorkHistory = state => state.workHistory;
export const selectWorkHistorySearch = state => state.search.workHistory;
export const selectWorkHistoryByCarerID = (state, carerID) =>
  state.workHistory[carerID] ? state.workHistory[carerID] : null;

export const selectWorkHistoryInstanceByID = (state, workHistoryID) => {
  let workHist;
  Object.keys(state.workHistory).forEach(carerID => {
    if (!workHist) {
      const carerWorkHistory = state.workHistory[carerID];
      const matches = carerWorkHistory.filter(item => item.id === workHistoryID);
      if (matches.length) {
        workHist = matches[0];
      }
    }
  });
  return workHist;
};