import React, { Component } from 'react';
import { connect } from 'react-redux';
import TermsAndConditions from 'components/site/TermsAndConditions';

class TermsAndConditionsContainer extends Component {
  render() {
    return (
      <TermsAndConditions/>
    );
  }
}

export default connect()(TermsAndConditionsContainer);
