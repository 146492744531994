import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.darkBlue};
  font-weight: 600;
`;

const StyledTickIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ marginLeft }) => marginLeft};
`;

const TickIcon = ({ className, height, label, marginLeft, width }) => (
  <StyledTickIcon className={`icon ${className}`} marginLeft={marginLeft}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="Artboard-2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icon/stripe-account-active"
          transform="translate(1.000000, 1.000000)"
          fill="#3EB38A"
          fillRule="nonzero"
        >
          <g id="check">
            <path
              d="M23,11.5 C23,17.8516488 17.8509783,23 11.4995681,23 C5.14815788,23 0,17.8516488 0,11.5 C0,5.14748742 5.14902167,0 11.4995681,0 C17.8501145,0 23,5.14748742 23,11.5 Z M17,8.90566038 L15.1748454,7 L10.2090475,12.1823899 L7.8260155,9.69541779 L6,11.6010782 L10.2090475,16 L17,8.90566038 Z"
              id="Shape-Copy-2"
            />
          </g>
        </g>
      </g>
    </svg>
    <StyledLabel>{label}</StyledLabel>
  </StyledTickIcon>
);

TickIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

TickIcon.defaultProps = {
  className: '',
  height: '25px',
  width: '25px',
};

export default TickIcon;
