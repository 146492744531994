import PropTypes from 'prop-types';
import React from 'react';

const StopwatchIcon = ({ className, width, height, ...otherProps }) => (
  <div className={`icon ${className}`} {...otherProps}>
    <svg height={height} width={width} version="1.1" viewBox="0 0 18 20">
      <g id="Page-1" fill="none" stroke="none" strokeWidth="1">
        <g
          id="New-jobs---desktop"
          fill="currentColor"
          transform="translate(-310.000000, -342.000000)"
        >
          <g id="Job-card-Copy" transform="translate(248.000000, 193.000000)">
            <g
              id="Hours-&amp;-Duration"
              transform="translate(62.000000, 149.000000)"
            >
              <g id="Stopwatch">
                <path
                  id="Fill-354"
                  d="M8.95214634,4.11666667 C4.59482927,4.11666667 1.04970732,7.48166667 1.04970732,11.6166667 C1.04970732,15.7520833 4.59482927,19.1166667 8.95214634,19.1166667 C13.3094634,19.1166667 16.8545854,15.7520833 16.8545854,11.6166667 C16.8545854,7.48166667 13.3094634,4.11666667 8.95214634,4.11666667 Z M8.95214634,19.95 C4.11014634,19.95 0.171658537,16.2120833 0.171658537,11.6166667 C0.171658537,7.02166667 4.11014634,3.28333333 8.95214634,3.28333333 C13.7937073,3.28333333 17.7326341,7.02166667 17.7326341,11.6166667 C17.7326341,16.2120833 13.7937073,19.95 8.95214634,19.95 Z"
                />
                <path
                  id="Fill-355"
                  d="M8.51312195,3.28333333 L9.39117073,3.28333333 L9.39117073,2.45 L8.51312195,2.45 L8.51312195,3.28333333 Z M9.83019512,4.11666667 L8.07409756,4.11666667 C7.83131707,4.11666667 7.63507317,3.93041667 7.63507317,3.7 L7.63507317,2.03333333 C7.63507317,1.80333333 7.83131707,1.61666667 8.07409756,1.61666667 L9.83019512,1.61666667 C10.0729756,1.61666667 10.2692195,1.80333333 10.2692195,2.03333333 L10.2692195,3.7 C10.2692195,3.93041667 10.0729756,4.11666667 9.83019512,4.11666667 Z"
                />
                <path
                  id="Fill-356"
                  d="M11.1472683,2.03333333 C10.9044878,2.03333333 10.7082439,1.84708333 10.7082439,1.61666667 C10.7082439,1.38708333 10.511561,1.2 10.2692195,1.2 L7.63507317,1.2 C7.39273171,1.2 7.19604878,1.38708333 7.19604878,1.61666667 C7.19604878,1.84708333 6.99980488,2.03333333 6.75702439,2.03333333 C6.5142439,2.03333333 6.318,1.84708333 6.318,1.61666667 C6.318,0.9275 6.90892683,0.366666667 7.63507317,0.366666667 L10.2692195,0.366666667 C10.9953659,0.366666667 11.5862927,0.9275 11.5862927,1.61666667 C11.5862927,1.84708333 11.3900488,2.03333333 11.1472683,2.03333333"
                />
                <path
                  id="Fill-357"
                  d="M8.95214634,5.36666667 C8.70936585,5.36666667 8.51312195,5.18041667 8.51312195,4.95 L8.51312195,3.7 C8.51312195,3.47 8.70936585,3.28333333 8.95214634,3.28333333 C9.19492683,3.28333333 9.39117073,3.47 9.39117073,3.7 L9.39117073,4.95 C9.39117073,5.18041667 9.19492683,5.36666667 8.95214634,5.36666667"
                />
                <polygon
                  id="Fill-358"
                  points="8.51312195 19.5333333 9.39117073 19.5333333 9.39117073 18.2833333 8.51312195 18.2833333"
                />
                <path
                  id="Fill-359"
                  d="M17.2936098,12.0333333 L15.9765366,12.0333333 C15.7337561,12.0333333 15.5375122,11.8470833 15.5375122,11.6166667 C15.5375122,11.3866667 15.7337561,11.2 15.9765366,11.2 L17.2936098,11.2 C17.5363902,11.2 17.7326341,11.3866667 17.7326341,11.6166667 C17.7326341,11.8470833 17.5363902,12.0333333 17.2936098,12.0333333"
                />
                <path
                  id="Fill-360"
                  d="M1.9277561,12.0333333 L0.610682927,12.0333333 C0.367902439,12.0333333 0.171658537,11.8470833 0.171658537,11.6166667 C0.171658537,11.3866667 0.367902439,11.2 0.610682927,11.2 L1.9277561,11.2 C2.17053659,11.2 2.36678049,11.3866667 2.36678049,11.6166667 C2.36678049,11.8470833 2.17053659,12.0333333 1.9277561,12.0333333"
                />
                <path
                  id="Fill-361"
                  d="M13.9188293,7.31958333 C13.806439,7.31958333 13.6944878,7.27875 13.608439,7.1975 C13.4372195,7.03458333 13.4372195,6.77125 13.608439,6.60833333 L14.5396098,5.72458333 C14.7112683,5.56166667 14.9891707,5.56166667 15.1603902,5.72458333 C15.3320488,5.88708333 15.3320488,6.15083333 15.1603902,6.31375 L14.2292195,7.1975 C14.1436098,7.27875 14.0312195,7.31958333 13.9188293,7.31958333"
                />
                <path
                  id="Fill-362"
                  d="M3.05385366,17.63125 C2.94146341,17.63125 2.8295122,17.5904167 2.74346341,17.5091667 C2.5722439,17.34625 2.5722439,17.0829167 2.74346341,16.92 L3.67463415,16.03625 C3.84629268,15.8733333 4.12419512,15.8733333 4.29541463,16.03625 C4.46707317,16.1991667 4.46707317,16.4625 4.29541463,16.6254167 L3.3642439,17.5091667 C3.27863415,17.5904167 3.1662439,17.63125 3.05385366,17.63125"
                />
                <path
                  id="Fill-363"
                  d="M14.85,17.63125 C14.7380488,17.63125 14.6256585,17.5904167 14.5396098,17.5091667 L13.608439,16.6254167 C13.4372195,16.4625 13.4372195,16.1991667 13.608439,16.03625 C13.7800976,15.8733333 14.058,15.8733333 14.2292195,16.03625 L15.1603902,16.92 C15.3320488,17.0829167 15.3320488,17.34625 15.1603902,17.5091667 C15.0747805,17.5904167 14.9623902,17.63125 14.85,17.63125"
                />
                <path
                  id="Fill-364"
                  d="M3.98502439,7.31958333 C3.87263415,7.31958333 3.76068293,7.27875 3.67463415,7.1975 L2.74346341,6.31375 C2.5722439,6.15083333 2.5722439,5.88708333 2.74346341,5.72458333 C2.91512195,5.56166667 3.19302439,5.56166667 3.3642439,5.72458333 L4.29541463,6.60833333 C4.46707317,6.77125 4.46707317,7.03458333 4.29541463,7.1975 C4.20980488,7.27875 4.09741463,7.31958333 3.98502439,7.31958333"
                />
                <path
                  id="Fill-365"
                  d="M8.95214634,12.45 C8.82878049,12.45 8.70936585,12.4004167 8.62595122,12.3120833 L4.67473171,8.14541667 C4.51273171,7.97458333 4.52721951,7.71083333 4.70721951,7.55708333 C4.88765854,7.40333333 5.16468293,7.41666667 5.32712195,7.58833333 L8.84765854,11.3004167 L11.1933659,6.84708333 C11.3022439,6.64125 11.5652195,6.55875 11.7825366,6.66083333 C11.9994146,6.76375 12.0872195,7.01416667 11.9787805,7.22 L9.34463415,12.22 C9.27965854,12.3433333 9.15497561,12.4279167 9.01097561,12.4466667 L8.95214634,12.45"
                />
                <path
                  id="Fill-366"
                  d="M8.95214634,16.2 C8.70936585,16.2 8.51312195,16.01375 8.51312195,15.7833333 C8.51312195,15.5533333 8.70936585,15.3666667 8.95214634,15.3666667 C11.1310244,15.3666667 12.9033659,13.6845833 12.9033659,11.6166667 C12.9033659,9.54916667 11.1310244,7.86666667 8.95214634,7.86666667 C8.70936585,7.86666667 8.51312195,7.68041667 8.51312195,7.45 C8.51312195,7.22 8.70936585,7.03333333 8.95214634,7.03333333 C11.6148293,7.03333333 13.7814146,9.08958333 13.7814146,11.6166667 C13.7814146,14.1441667 11.6148293,16.2 8.95214634,16.2"
                />
                <path
                  id="Fill-367"
                  d="M9.8297561,17.0333333 C9.74590244,17.0333333 9.66160976,17.0108333 9.58653659,16.96375 L8.26946341,16.1304167 C8.0675122,16.0025 8.01307317,15.7441667 8.14785366,15.5525 L9.02590244,14.3025 C9.15980488,14.1108333 9.43243902,14.0591667 9.63439024,14.1866667 C9.83634146,14.3145833 9.89078049,14.5729167 9.75643902,14.7645833 L9.12204878,15.6679167 L10.0734146,16.27 C10.2753659,16.3979167 10.3298049,16.65625 10.1954634,16.8479167 C10.1107317,16.9683333 9.97156098,17.0333333 9.8297561,17.0333333"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

StopwatchIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

StopwatchIcon.defaultProps = {
  className: '',
  height: '21px',
  width: '18px',
};

export default StopwatchIcon;
