import moment from 'moment';
import { createSelector } from 'reselect';

export const selectHolidays = state => state.holidays;

// Example memoized selector
export const getFutureHolidays = createSelector(
  selectHolidays,
  holidays => {
    const today = moment();
    return holidays.filter(holiday => moment(holiday.date) > today);
  },
);
