import { Row } from 'components/grid';
import styled from 'styled-components';

const FormRow = styled(Row)`
  margin-bottom: ${props => (props.largeMarginBottom ? '25px' : '20px')};
  justify-content: ${({ justify }) => justify};
  input,
  select {
    margin-bottom: 5px;
  }

  @media (min-width: 768px) {
    input,
    select {
      margin-bottom: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 20px;
  }
`;

export default FormRow;
