const PropTypes = require('prop-types');
const React = require('react');

function Days({ days, context }) {
  const suffix = days === 1 ? 'day' : 'days';
  return (
    <span style={{ display: 'block', whiteSpace: 'nowrap' }}>
      {days} {context} {suffix}
    </span>
  );
}

Days.propTypes = {
  days: PropTypes.number.isRequired,
  context: PropTypes.string,
};

Days.defaultProps = {
  context: null,
};

module.exports = Days;
