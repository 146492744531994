import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ReferenceEditContainer from 'containers/references/ReferenceEditContainer';

class AdminUserReferenceEditContainer extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        referenceID: PropTypes.string.isRequired,
        userID: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  render() {
    return (
      <ReferenceEditContainer
        carerID={this.props.match.params.userID}
        referenceID={this.props.match.params.referenceID}
        summaryURL={`/admin/users/${this.props.match.params.userID}/#references`}
        isAdmin
      />
    );
  }
}

export default AdminUserReferenceEditContainer;
