// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { DownTriangleIcon } from 'components/global/icons/small';

import type { NoteType } from 'shared/types';

import NoteEditForm from 'components/notes/NoteEditForm';
import { noteCategories } from 'shared/constants/NoteConstants';

const NoteHistoryTitle = styled.div`
  padding: 0 20px;
  color: #979797;
  font-weight: 600;
`;

const NoteContainer = styled.div`
  padding: 20px 0;
  margin: 2px 20px 2px 20px;
  border-radius: 4px;
  background: ${props => props.theme.colors.lightGrey};
`;

const NoteDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const NoteDetail = styled.div`
  margin: 5px 20px;
  text-align: start;
  width: 100%;
  @media (min-width: ${props => props.theme.screen.small}px) {
    text-align: center;
    flex: 1;
    width: auto;
  }
`;

const Note = styled.div`
  cursor: pointer;
  background-color: ${props => props.theme.colors.lightestGrey};
  text-align: center;
  padding: 10px;
  margin: 5px;
`;

const DisplayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  background: ${props => (props.open ? 'white' : '#56A6D1')};
  border: 2px solid #56a6d1;
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(86, 166, 209, 0.6);
  color: ${props => (props.open ? '#56A6D1' : 'white')};
  min-width: 150px;
  height: 40px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
`;

const StyledDownTriangleIcon = styled(DownTriangleIcon)`
  transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(-90deg)')};
`;

const LastEditedText = styled.div`
  font-size: 0.8em;
  color: ${props => props.theme.colors.midGrey};
`;

type Props = {|
  category?: string,
  notes: NoteType,
  updateNote: (string, string) => void,
|};

const NotesHistory = ({ category, notes, updateNote }: Props) => {
  const [displayedNote, changeDisplayedNote] = useState(null);
  const [editingNote, changeEditStatus] = useState(null);

  const toggleNoteDisplay = note => {
    changeDisplayedNote(note.id === displayedNote ? null : note.id);
    changeEditStatus(note.id === displayedNote && null);
  };

  const editNote = note => {
    changeEditStatus(note.id);
  };

  const saveNote = (note, values) => {
    updateNote(note.id, values.message);
    changeEditStatus(null);
  };

  if (!notes) {
    return null;
  }

  return (
    <>
      <NoteHistoryTitle>Note History</NoteHistoryTitle>
      {notes.map(note => (
        <NoteContainer key={note.id}>
          <NoteDetailsContainer>
            <NoteDetail>
              {moment(note.created_at).format('DD/MM/YYYY')}
            </NoteDetail>
            <NoteDetail>{note.author_name}</NoteDetail>
            {!category && (
              <NoteDetail>{noteCategories[note.category]}</NoteDetail>
            )}
            <NoteDetail>
              <DisplayButton
                open={note.id === displayedNote}
                onClick={() => toggleNoteDisplay(note)}
              >
                {note.id === displayedNote ? 'Hide Note' : 'Show Note'}
                <StyledDownTriangleIcon open={note.id === displayedNote} />
              </DisplayButton>
            </NoteDetail>
          </NoteDetailsContainer>
          {note.id === displayedNote && note.id !== editingNote && (
            <Note onClick={() => editNote(note)}>
              {note.message}
              <LastEditedText>
                Last edited -{' '}
                {moment(note.updated_at).format('DD/MM/YYYY [at] HH:mm')}
              </LastEditedText>
            </Note>
          )}
          {note.id === editingNote && (
            <NoteEditForm note={note} saveNote={saveNote} />
          )}
        </NoteContainer>
      ))}
    </>
  );
};

export default NotesHistory;
