/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Col, Row } from 'react-grid-system';
import Link from 'react-router-redux-dom-link';
import moment from 'moment';
import styled from 'styled-components';

import StandardTable from 'components/global/layout/StandardTable';
import Module, {
  ModuleHeader,
  ModuleHero,
  ModuleMeta,
} from 'components/global/layout/Module';
import { DeleteIcon, EditIcon } from 'components/global/icons';
import StatusIndicator from 'components/global/StatusIndicator';
import ConfirmationDialog from 'components/global/ConfirmationDialog';
import { renderTaxon } from 'components/helpers/taxonomyHelpers';
import toTitleCase from 'components/helpers/toTitleCase'
import MarkCompliant from 'components/modules/MarkCompliant';
import NotesContainer from 'containers/notes/NotesContainer';

const DeleteLink = styled.a`
  color: ${props => props.theme.colors.midGrey};
`;

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;


class AdminUserWorkHistorySummary extends Component {
  static propTypes = {
    carer: PropTypes.shape().isRequired,
    workHistoryCompliant: PropTypes.bool.isRequired,
    markWorkHistoryCompliant: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.shape(),
    workHistoryMarkCompliant: PropTypes.bool.isRequired,
    workHistory: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    deleteWorkHistoryInstance: PropTypes.func.isRequired,
    updateWorkHistoryInstance: PropTypes.func.isRequired,
  };

  state = {
    deletingWorkHistoryInstance: null,
  };

  confirmDeletion = workHistoryInstanceID => {
    this.setState({
      deletingWorkHistoryInstance: workHistoryInstanceID,
    });
  };

  deleteWorkHistoryInstance = workHistoryInstanceID => {
      this.props.deleteWorkHistoryInstance(workHistoryInstanceID, this.props.carer.id);
  };

  render() {
  	const {
  		carer,
  		workHistoryCompliant,
  		markWorkHistoryCompliant,
      taxonomiesByClassification,
  		workHistoryMarkCompliant,
  		workHistory,
  	} = this.props;
  	return (
  		<section>
  		  <Module>
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <StyledHeader>Work History Summary</StyledHeader>
              </Col>
              <Col xs={12} md={6}>
                <ModuleMeta>
                  {workHistoryCompliant !== null && (
                    <StatusIndicator
                      status={
                        workHistoryCompliant
                          ? 'work_history_compliant'
                          : 'work_history_non-compliant'
                      }
                    />
                  )}
                  {(!workHistoryCompliant || workHistoryMarkCompliant) && (
                    <MarkCompliant
                      markCompliant={markWorkHistoryCompliant}
                      compliant={workHistoryMarkCompliant}
                      message="work history compliant"
                    />
                  )}
                </ModuleMeta>
              </Col>
            </Row>
          </Container>
          {workHistory.length === 0 && (
            <ModuleHero>This carer has not entered any work history</ModuleHero>
          )}
          {workHistory.length > 0 && (
            <StandardTable className="user-work-history">
              <thead>
                <tr>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Type</th>
                  <th>Role</th>
                  <th>Organisation</th>
                  <th>Refs</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {workHistory.map(workHistoryInstance => (

                  <tr key={workHistoryInstance.id}>

                    <td>
                      {workHistoryInstance.employment_start_date
                        ? moment(workHistoryInstance.employment_start_date).format(
                            'YYYY-MM',
                          )
                        : 'No start date'}
                    </td>

                    <td>
                      {workHistoryInstance.employment_end_date
                        ? moment(workHistoryInstance.employment_end_date).format(
                            'YYYY-MM',
                          )
                        : 'Present'}
                    </td>

                    <td>
                      {renderTaxon(
                        taxonomiesByClassification['work_history_employment_type'],
                        workHistoryInstance.employment_type,
                      )}
                    </td>

                    <td>
                      {toTitleCase(workHistoryInstance.user_role)}
                    </td>

                    <td>
                      {toTitleCase(workHistoryInstance.organization)}
                    </td>

                   <td>
                      {
                        workHistoryInstance.reference_ids &&
                        workHistoryInstance.reference_ids.length
                      }
                   </td>

                    <td>
                      <Link to={`/admin/users/${carer.id}/work-history/${workHistoryInstance.id}/`}>
                        <EditIcon />
                      </Link>
                    </td>

                    <td>
                      <DeleteLink
                        onClick={() => this.confirmDeletion(workHistoryInstance.id)}
                        role="button"
                      >
                        <DeleteIcon />
                      </DeleteLink>
                    </td>

                  </tr>
                ))}
              </tbody>
            </StandardTable>
          )}

          <Container>
            <Row>
              <Col>
                <Link to={`/admin/users/${carer.id}/work-history/add/`}>
                  Add past employment
                </Link>
              </Col>
            </Row>
          </Container>

          {this.state.deletingWorkHistoryInstance && (
            <ConfirmationDialog
              title="Delete this employment?"
              message="Caution. This cannot be undone."
              confirmation="Yes, delete it"
              onConfirm={() => {
                this.deleteWorkHistoryInstance(this.state.deletingWorkHistoryInstance);
                this.setState({ deletingWorkHistoryInstance: null });
              }}
              onCancel={() => {
                this.setState({ deletingWorkHistoryInstance: null });
              }}
              danger
            />
          )}

        </Module>

        <NotesContainer
          objectID={carer.id}
          category="workhistory"
          placeholder="e.g. Comments on work history"
        />

  		</section>
  	);
  }
}

export default AdminUserWorkHistorySummary;