// @flow
import React, { Component, type Element, type Node, type Portal } from 'react';
import * as Sentry from '@sentry/browser';

import ErrorMessage from 'components/errorhandling/ErrorMessage';

type Props = {
  children: Element<any> | Portal | Iterable<?Node>,
};

type State = {
  hasError: boolean,
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(
    error: Error,
    info: {
      componentStack: string,
    },
  ) {
    this.setState({ hasError: true });

    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key]);
      });

      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
