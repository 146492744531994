import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';

import toTitleCase from 'components/helpers/toTitleCase';

class ChargeResultRow extends Component {
  render() {
    const charge = this.props.charge;
    const created = moment(charge.created_at).format('DD-MM-YYYY');
    const status = charge.void ? 'void' : charge.state;
    return (
      <tr>
        <td>{created}</td>
        <td>
          <span className={`status ${status}`}>
            {toTitleCase(status || 'none')}
          </span>
        </td>
        <td>{this.props.customer}</td>
        <td>{charge.description}</td>
        <td>{charge.nice_amount}</td>
        <td>{this.props.payee}</td>
        <td>
          <Link to={`/admin/charges/${charge.id}/`}>Manage charge</Link>
        </td>
      </tr>
    );
  }
}

ChargeResultRow.propTypes = {
  charge: PropTypes.shape().isRequired,
  customer: PropTypes.string.isRequired,
  payee: PropTypes.string.isRequired,
};

export default ChargeResultRow;
