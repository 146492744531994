import React, { useEffect } from 'react';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddStripeCard from 'components/payments/AddStripeCard';
import { addPaymentMethod } from '_actions/paymentActions';
import { getScreenClass } from 'components/grid/screen-utils';

const createStripeElement = stripe => {
  const elements = stripe.elements();
  const screenSize = getScreenClass();
  const cardElementStyle = {
    base: {
      color: '#4A4A4A',
      fontFamily: 'Open Sans,sans-serif',
      fontSize: screenSize === 'xs' ? '15px' : '18px',
    },
    empty: {
      color: '#979797',
    },
    complete: {
      color: '#3EB38A',
    },
  };
  return elements.create('card', {
    hidePostalCode: true,
    style: cardElementStyle,
  });
};

const AddStripeCardContainer = ({
  addPaymentMethod,
  changeCardDetailsStatus,
  setupIntentSecret,
}) => {
  const stripe = window.Stripe(window.global.STRIPE_KEY);
  const stripeElement = createStripeElement(stripe);

  useEffect(() => {
    stripeElement.mount('#card-element');
    stripeElement.on('change', event => {
      if (event.complete) {
        changeCardDetailsStatus(true);
      }
    });
  }, []);

  const submitPaymentMethod = formData => {
    addPaymentMethod(setupIntentSecret, stripeElement, formData, stripe);
  };

  return <AddStripeCard submitPaymentMethod={submitPaymentMethod} />;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addPaymentMethod,
      changeCardDetailsStatus: complete =>
        change('addStripeCard', 'card-details', complete),
    },
    dispatch,
  );
}

export default connect(
  null,
  mapDispatchToProps,
)(AddStripeCardContainer);
