// @flow
import ApiConstants from 'shared/constants/ApiConstants';
import NoteConstants from 'shared/constants/NoteConstants';

export function addNoteForObject(
  objectType?: string,
  objectId: string,
  message: string,
  category?: ?string = null,
  formName: string,
) {
  return {
    endpoint: '/note/',
    method: 'post',
    onError: {
      type: NoteConstants.NOTE_ADD_FOR_OBJECT_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to add note',
      },
    },
    onStart: { type: NoteConstants.NOTE_ADD_FOR_OBJECT },
    onSuccess: {
      formName,
      type: NoteConstants.NOTE_ADD_FOR_OBJECT_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Note added',
      },
    },
    payload: {
      object_type: objectType,
      object_id: objectId,
      message,
      category,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export type GetNotesForObjectType = {|
  object_id: string,
  category?: string,
  object_type?: string,
|};

export function getNotesForObject(data: GetNotesForObjectType) {
  return {
    endpoint: '/note/find/',
    method: 'get',
    onError: {
      type: NoteConstants.NOTES_GET_FOR_OBJECT_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to get notes',
      },
    },
    onStart: { type: NoteConstants.NOTES_GET_FOR_OBJECT },
    onSuccess: { type: NoteConstants.NOTES_GET_FOR_OBJECT_SUCCESS },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function updateNote(id: string, message: string) {
  return {
    endpoint: `/note/${id}/`,
    method: 'put',
    onError: {
      type: NoteConstants.NOTE_UPDATE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to update note',
      },
    },
    onStart: {
      type: NoteConstants.NOTE_UPDATE,
    },
    onSuccess: {
      type: NoteConstants.NOTE_UPDATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Note updated',
      },
    },
    payload: { message },
    type: ApiConstants.API_REQUEST,
  };
}

export function deleteNote(id: string) {
  return {
    endpoint: `/note/${id}/`,
    method: 'delete',
    onError: {
      type: NoteConstants.NOTE_DELETE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to delete note',
      },
    },
    onStart: {
      type: NoteConstants.NOTE_DELETE,
    },
    onSuccess: {
      type: NoteConstants.NOTE_DELETE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Note deleted',
      },
    },
    type: ApiConstants.API_REQUEST,
  };
}
