import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFranchiseById } from '_actions/franchiseActions';
import { createUser } from '_actions/userActions';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AdminFranchiseUserForm from 'components/admin/needs/AdminFranchiseUserForm';

import { getFranchiseByID } from 'shared/selectors/franchiseSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

class AdminFranchiseUserFormContainer extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      createUser: PropTypes.func.isRequired,
      getFranchiseById: PropTypes.func.isRequired,
    }).isRequired,
    editMode: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    franchise: PropTypes.shape().isRequired,
    initialValues: PropTypes.shape(),
    match: PropTypes.shape({
      params: PropTypes.shape({
        franchiseID: PropTypes.string,
      }),
    }),
    onUpdate: PropTypes.func,
    taxonomiesByClassification: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    archiveNeed: () => {},
    editMode: false,
    franchise: null,
    franchiseUsers: [],
    initialValues: {},
    onUpdate: () => {},
    taxonomiesByClassification: {},
    unarchiveNeed: () => {},
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [];
  }

  UNSAFE_componentWillMount() {
    this.props.actions.getFranchiseById(this.props.match.params.franchiseID);
  }

  onSubmit = formData => {
    const { editMode, actions, onUpdate} = this.props;

    if (editMode) {
      onUpdate(formData);
    } else {
      actions.createUser(
        { ...formData, role: 'Franchisee' },
        `/admin/franchises/${this.props.match.params.franchiseID}/#users`
      );
    }
  };

  render() {
    const {
      franchise,
      initialValues,
      formName,
      editMode,
      taxonomiesByClassification,
    } = this.props;

    if (!taxonomiesByClassification || !franchise ) {
      return <LoadingPlaceholder />;
    }

    taxonomiesByClassification.franchises = [{value: franchise.name, taxon_id: franchise.id}];
    initialValues.franchise_id = franchise.id;

    return (
      <AdminFranchiseUserForm
        editMode={editMode}
        formName={formName}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  formName: `AdminFranchiseUser${props.editMode ? 'Edit' : 'Create'}Form`,
  franchise: getFranchiseByID(state, props.match.params.franchiseID),
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    AdminFranchiseUserFormContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createUser,
      getFranchiseById,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminFranchiseUserFormContainer);
