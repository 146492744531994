// @flow
import React from 'react';
// $FlowFixMe
import { Field } from 'redux-form';

import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';

import Module, { ModuleHeader } from 'components/global/layout/Module';
import Button from 'components/buttons/Button';
import { Container, Col } from 'components/grid';

type Props = {
  available: boolean,
  updateAvailability: Object => void,
};

const AccountAvailability = ({ available, updateAvailability }: Props) => (
  <Form
    name="changeAvailability"
    fields={['available']}
    values={{ available }}
    onSubmit={updateAvailability}
    component={({ handleSubmit, pristine }) => (
      <section>
        <Module>
          <ModuleHeader>Change Work Availability</ModuleHeader>
          <form onSubmit={handleSubmit}>
            <Container>
              <FormRow>
                <Col>
                  <label htmlFor="available">
                    <Field
                      id="available"
                      name="available"
                      component="input"
                      type="checkbox"
                    />
                    I am available to work and I want to receive new job offers.
                  </label>
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <Button type="submit" disabled={pristine}>
                    Save
                  </Button>
                </Col>
              </FormRow>
            </Container>
          </form>
        </Module>
      </section>
    )}
  />
);

export default AccountAvailability;
