import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-redux-dom-link';
import { Container, Col, Row } from 'components/grid';

import { TableCarerImage } from 'components/carers/CarerImage';
import Module, {
  ModuleHeader,
  ModuleHero,
} from 'components/global/layout/Module';
import StandardTable from 'components/global/layout/StandardTable';
import Button from 'components/buttons/Button';
import StyledCheckbox from 'components/forms/StyledCheckbox';

class CarerSearchResults extends Component {
  static propTypes = {
    action: PropTypes.shape(),
    carers: PropTypes.arrayOf(PropTypes.shape()),
    search: PropTypes.shape(),
    selectAction: PropTypes.shape(),
    selectable: PropTypes.bool,
    unselectable: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    action: null,
    carers: [],
    search: {},
    selectable: false,
    selectAction: null,
    unselectable: [],
  };

  state = {
    selected: [],
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    // Loop over selected ids to check if they should still be selected or not
    if (nextProps.search) {
      const selected = this.state.selected.slice();
      selected.forEach(id => {
        if (!nextProps.search.searchOrder.includes(id)) {
          selected.splice(selected.indexOf(id), 1);
        }
      });
      this.setState({ selected });
    }
  };

  handleChange = e => {
    const newValue = [...this.state.selected];
    if (e.target.checked) {
      newValue.push(e.target.value);
    } else {
      newValue.splice(newValue.indexOf(e.target.value), 1);
    }
    this.setState({ selected: newValue });
  };

  toggleAll = e => {
    e.preventDefault();
    if (this.state.selected.length) {
      this.setState({ selected: [] });
    } else {
      const selected = this.props.search.searchOrder.filter(
        id => !this.props.unselectable.includes(id),
      );
      this.setState({ selected });
    }
  };

  selectAction = () => {
    this.props.selectAction.onClick(this.state.selected);
    this.setState({ selected: [] });
  };

  render() {
    const { action, carers, search, selectable, selectAction } = this.props;
    if (carers.length <= 0) {
      return (
        <Module>
          <ModuleHero>No search results</ModuleHero>
        </Module>
      );
    }

    return (
      <Module>
        <ModuleHeader>Carer Results ({search.pagination.total})</ModuleHeader>
        <StandardTable>
          <thead>
            <tr>
              <th>Name</th>
              {search.distance.length > 0 && <th>Distance</th>}
              {action && <th />}
              {selectable && (
                <th onClick={this.toggleAll} style={{ cursor: 'pointer' }}>
                  Selected {`(${this.state.selected.length})`}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {carers.map((carer, index) => {
              let distance;
              if (search.distance.length > 0) {
                distance = search.distance[index].distance[0];
              }
              const unselectable = this.props.unselectable.includes(carer.id);
              return (
                <tr key={carer.id}>
                  <td>
                    <TableCarerImage carer={carer} className="avatar" />
                    <Link to={`/carers/${carer.id}/`}>{carer.name}</Link>
                  </td>
                  {distance && <td>{distance.toFixed(0)} miles</td>}
                  {action && (
                    <td>
                      <Button onClick={() => action.onClick(carer.id)}>
                        {action.text}
                      </Button>
                    </td>
                  )}
                  {selectable && (
                    <td>
                      {!unselectable && (
                        <StyledCheckbox
                          value={carer.id}
                          checked={this.state.selected.includes(carer.id)}
                          onChange={this.handleChange}
                          name={carer.id}
                        />
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </StandardTable>
        {selectAction && (
          <Container>
            <Row>
              <Col>
                <Button
                  onClick={this.selectAction}
                  disabled={!this.state.selected.length}
                  multiclick
                >
                  {selectAction.text}
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </Module>
    );
  }
}

export default CarerSearchResults;
