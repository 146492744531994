import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

const removedFields = [
  'password',
  'new_password',
  'confirm_password',
  'client_secret',
];

const scrub = obj => {
  Object.entries(obj).forEach(([key, value]) => {
    if (removedFields.includes(key)) {
      obj[key] = '[REMOVED]';
    } else if (value !== null && typeof value === 'object') {
      obj[key] = scrub(value);
    }
  });
  return obj;
};

const scrubReduxForm = obj => {
  const { meta } = obj;
  if (removedFields.includes(meta.field)) {
    obj.payload = '[REMOVED]';
  }

  return obj;
};

export const logRocketSessionURL = LogRocket.sessionURL;

export const logRocketMiddleware = LogRocket.reduxMiddleware({
  actionSanitizer(action) {
    const actionCopy = JSON.parse(JSON.stringify(action));
    const scrubFunction = action.type.includes('@@redux-form')
      ? scrubReduxForm
      : scrub;
    const scrubbed = scrubFunction(actionCopy);
    return scrubbed;
  },
});

export const initLogRocket = () => {
  if (window.global.LOGROCKET_APP_ID) {
    try {
      LogRocket.init(window.global.LOGROCKET_APP_ID, {
        rootHostname: 'supercarers.com',
        network: {
          requestSanitizer: request => {
            if (request.body) {
              const requestCopy = JSON.parse(request.body);
              const scrubbed = scrub(requestCopy);
              request.body = scrubbed;
            }
            return request;
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  }
};

export const identifyUser = user => {
  LogRocket.identify(user.id, {
    name: user.name,
    email: user.email,
  });
};

export const addLogRocketToSentry = () => {
  Sentry.configureScope(scope => {
    scope.addEventProcessor(async (event, hint) => {
      event.extra.sessionURL = logRocketSessionURL;
      return event;
    });
  });
};
