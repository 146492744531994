import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Module, { ModuleHeader } from 'components/global/layout/Module';
import { Container, Row, Col } from 'components/grid';

class NeedSearchFilters extends Component {
  render() {
    const filters = this.props.filters;

    return (
      <Module>
        <ModuleHeader>Filter</ModuleHeader>

        <Container>
            <Row>
              <Col xs={12} md={12}>
                <label htmlFor="caree_name">Care Recipient Name</label>
                <input
                  name="caree_name"
                  type="text"
                  value={filters.caree_name || ''}
                  onChange={this.props.updateFilters}
                />
              </Col>
            </Row>
        </Container>
      </Module>
    );
  }
}

NeedSearchFilters.propTypes = {
  location: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  updateFilters: PropTypes.func.isRequired,
};

export default NeedSearchFilters;
