import PropTypes from 'prop-types';
import React from 'react';

const EditIcon = ({ style, className, width, height }) => (
  <div className={`icon ${className}`} style={style}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 26"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="edit-icon"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-75.000000, -917.000000)">
          <g transform="translate(57.179688, 919.000000)">
            <g transform="translate(19.373047, 0.000000)">
              <path d="M21.8242973,6.28998333 C22.0585676,6.04920556 22.0585676,5.65809444 21.8242973,5.41731667 L16.7292162,0.180705556 C16.4943514,-0.0600722222 16.1144054,-0.0600722222 15.8801351,0.180705556 L2.29305405,14.1452056 L1.86851351,14.5815389 L0.594297297,21.9998167 L7.81267568,20.6908167 L8.23721622,20.2544833 L21.8242973,6.28998333 Z" />
              <path d="M17.7967514,10.4294056 L11.8525892,4.32012778" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

EditIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape(),
  height: PropTypes.string,
  width: PropTypes.string,
};

EditIcon.defaultProps = {
  className: '',
  height: '26px',
  width: '26px',
};

export default EditIcon;
