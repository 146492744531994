import RefundConstants from 'shared/constants/RefundConstants';
import { updateObject } from 'shared/reducers/reducerHelpers';

const initalState = {};

function refunds(state = initalState, action) {
  let newRefunds;

  switch (action.type) {
    case RefundConstants.REFUND_CREATE_SUCCESS:
      newRefunds = updateObject(state);
      newRefunds[action.data.id] = action.data;
      return newRefunds;

    case RefundConstants.REFUND_SEARCH_SUCCESS:
      newRefunds = {};
      action.data.results.forEach(refund => {
        newRefunds[refund.id] = refund;
      });
      return updateObject(state, newRefunds);

    case RefundConstants.REFUND_CREATE:
    case RefundConstants.REFUND_CREATE_FAILURE:
    case RefundConstants.REFUND_SEARCH:
    case RefundConstants.REFUND_SEARCH_FAILURE:
    default:
      return state;
  }
}

export default refunds;
