/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Row, Col } from 'components/grid';

import ButtonLink from 'components/buttons/ButtonLink';
import Button from 'components/buttons/Button';

class TimesheetControls extends Component {
  submitTimesheet = e => this.props.updateState(e, 'submitted');
  approveTimesheet = e => this.props.updateState(e, 'approved');

  timesheetRecalculate = () => {
    const confirmation = confirm(
      'Are you sure you want to use the latest rate? This cannot be undone.',
    );
    if (confirmation) {
      this.props.timesheetRecalculate();
    }
  };

  render() {
    const { stripeEnabled, id, inState } = this.props;

    if (
      !inState({
        carer: ['new'],
        admin: ['submitted', 'approval_needed', 'approved', 'queried'],
      })
    ) {
      return null;
    }

    return (
      <Container>
        <Row>
          {inState({ carer: ['new'] }) && (
            <Col xs={12} md={3}>
              <Button onClick={this.submitTimesheet} disabled={!stripeEnabled}>
                {stripeEnabled ? 'Submit' : 'Stripe Not Enabled'}
              </Button>
            </Col>
          )}

          {inState({
            carer: ['new', 'queried'],
            admin: ['submitted', 'approval_needed'],
          }) && (
            <Col xs={12} md={3}>
              <ButtonLink className="alt" to={`/timesheets/${id}/`}>
                Edit Details
              </ButtonLink>
            </Col>
          )}

          {inState({ admin: ['submitted', 'approval_needed', 'queried'] }) && (
            <Col xs={12} md={3}>
              <Button onClick={this.approveTimesheet}>Approve</Button>
            </Col>
          )}

          {inState({ admin: ['approved'] }) && (
            <Col xs={12} md={3}>
              <Button className="alt" onClick={this.timesheetRecalculate}>
                Recalculate Totals Using Current Rates
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}

TimesheetControls.propTypes = {
  id: PropTypes.string.isRequired,
  inState: PropTypes.func.isRequired,
  stripeEnabled: PropTypes.bool.isRequired,
  updateState: PropTypes.func.isRequired,
  timesheetRecalculate: PropTypes.func.isRequired,
};

export default TimesheetControls;
