import 'babel-polyfill';
import ErrorBoundary from 'components/errorhandling/ErrorBoundary';
import ErrorPage from 'components/errorhandling/404';
import AppContainer from 'containers/AppContainer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
// import registerServiceWorker from './registerServiceWorker';

import { ConnectedRouter } from 'react-router-redux';
import ScrollTop from 'routes/ScrollTop';
import routes from 'routes/routes';
import store, { history } from 'store';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';
import globalstyles from 'styles/global'; // eslint-disable-line no-unused-vars

const render = appRoutes => {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <ScrollTop>
            <AppContainer>
              <ErrorBoundary>
                <Switch>
                  {appRoutes.map(route => (
                    <route.route
                      key={route.path}
                      exact
                      path={route.path}
                      component={route.component}
                      roles={route.roles}
                      {...route.extraProps}
                    />
                  ))}
                  <Route path="/" component={ErrorPage} />
                </Switch>
              </ErrorBoundary>
            </AppContainer>
          </ScrollTop>
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>,
    document.getElementById('root'),
  );
};

render(routes);

if (module.hot) {
  module.hot.accept();
}

// registerServiceWorker();
