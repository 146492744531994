import PropTypes from 'prop-types';
import React, { Component } from 'react';

import StaffBasicsForm from 'components/users/StaffBasicsForm';

class AdminCreateStaff extends Component {
  render() {
    return (
      <div className="component-wrapper grid grid-center">
        <div className="module grid__cell u-4/6">
          <h1>Create Staff</h1>
          <StaffBasicsForm onSubmit={this.props.createUser} />
        </div>
      </div>
    );
  }
}

AdminCreateStaff.propTypes = {
  createUser: PropTypes.func.isRequired,
};

export default AdminCreateStaff;
