import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Row, Col } from 'components/grid';
import styled from 'styled-components';

import StyledLabel from 'components/forms/StyledLabel';
import StyledSelect from 'components/forms/StyledSelect';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import Button from 'components/buttons/Button';

const SpacedRow = styled(Row)`
  margin: 30px 0;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

class AdhocTimesheetCreator extends Component {
  render() {
    const weekStart = moment()
      .startOf('isoweek')
      .subtract(1, 'week');
    const weekOptions = [];

    for (let x = 0; x < 4; x += 1) {
      const niceDate = weekStart.format('dddd, MMMM Do');
      const isoDate = weekStart.format('YYYY-MM-DD');
      const option = (
        <option key={isoDate} value={isoDate}>
          {niceDate}
        </option>
      );
      weekOptions.push(option);
      weekStart.subtract(1, 'week');
    }

    return (
      <Module>
        <ModuleHeader>Create a Timesheet</ModuleHeader>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <p>
                Missing a timesheet? To create your own, select your client
                below and the week you would like to submit the timesheet for.
                If your client is missing from the list, contact us on{' '}
                <a href="mailto:carers@supercarers.com">
                  carers@supercarers.com
                </a>
                .
              </p>
            </Col>
          </Row>
          <SpacedRow>
            <Col xs={12} md={6}>
              <StyledLabel htmlFor="client">Client</StyledLabel>
              <StyledSelect
                onChange={e => this.props.onSelectNeed(e.currentTarget.value)}
                name="client"
              >
                <option value="">Please select&hellip;</option>
                {Object.keys(this.props.needs).map(needID => {
                  const need = this.props.needs[needID];
                  const name =
                    need.firstname || need.lastname
                      ? `${need.firstname} ${need.lastname}`
                      : need.user_name;
                  return (
                    <option key={needID} value={needID}>
                      {name}
                    </option>
                  );
                })}
              </StyledSelect>
            </Col>
            <Col xs={12} md={6}>
              <StyledLabel htmlFor="week-commencing">
                Week commencing
              </StyledLabel>
              <StyledSelect
                onChange={e => this.props.onSelectDate(e.currentTarget.value)}
                name="week-commencing"
              >
                <option value="">Please select&hellip;</option>
                {weekOptions}
              </StyledSelect>
            </Col>
          </SpacedRow>
          <Row>
            <Col xs={12} md={5}>
              <Button
                disabled={!this.props.needId || !this.props.date}
                onClick={this.props.createAdhocTimesheet}
              >
                Create Timesheet
              </Button>
            </Col>
          </Row>
        </Container>
      </Module>
    );
  }
}

AdhocTimesheetCreator.propTypes = {
  onSelectNeed: PropTypes.func.isRequired,
  onSelectDate: PropTypes.func.isRequired,
  createAdhocTimesheet: PropTypes.func.isRequired,
  needs: PropTypes.shape().isRequired,
  needId: PropTypes.string,
  date: PropTypes.string,
};

AdhocTimesheetCreator.defaultProps = {
  date: null,
  needId: undefined,
};

export default AdhocTimesheetCreator;
