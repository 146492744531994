import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { arrayOf, func, object as obj } from 'prop-types';
import moment from 'moment';
import 'moment/locale/en-gb';

import CarerScheduleShifts from 'components/carers/CarerScheduleShifts';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import { getUser } from 'shared/selectors/accountSelectors';
import { fetchShiftsForCarerUserID } from '_actions/shiftActions';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { selectNeedsForShifts } from 'shared/selectors/needSelectors';
import { selectSchedulesForShifts } from 'shared/selectors/scheduleSelectors';
import { selectShiftsByUserID } from 'shared/selectors/shiftsSelectors';

class CarerScheduleContainer extends Component {
  static propTypes = {
    fetchShiftsForCarerUserID: func.isRequired,
    needs: obj.isRequired,
    user: obj.isRequired,
    shifts: arrayOf(obj),
    taxonomiesByClassification: obj,
  };

  static defaultProps = {
    taxonomiesByClassification: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['shift_type'];
  }

  constructor(props) {
    super(props);

    const now = moment();

    this.state = {
      initialTime: now,
      scheduleSelection: { options: {}, value: [] },
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { shifts, shiftSchedules, taxonomiesByClassification, needs } = props;

    if (!shifts || !shiftSchedules || !needs) {
      return {
        shifts: [],
        scheduleSelection: { options: {}, value: [] },
      };
    }

    const shiftsForEvents = [];
    const scheduleSelection = { ...state.scheduleSelection };
    Object.values(needs).forEach((need, index) => {
      if (!need) {
        return false;
      }
      const needScheduleIDs = Object.values(shiftSchedules).map(schedule =>
        schedule.care_need_id === need.id ? schedule.id : null,
      );
      shifts.forEach(shift => {
        if (!needScheduleIDs.includes(shift.schedule_id)) {
          return false;
        }
        if (!scheduleSelection.options[need.id]) {
          scheduleSelection.options[need.id] = need.user_name;
          scheduleSelection.value.push(need.id);
        }
        if (shift.active && scheduleSelection.value.includes(need.id)) {
          shiftsForEvents.push({
            ...shift,
            need,
            needIndex: index,
            amendedBadge: shift.amended ? true : false,
            onHoldBadge: shift.on_hold ? true : false,
            adhocBadge: shift.schedule_shift_id ? false : true,
            name: need.name ? need.name : 'Customer',
            shiftTypes: taxonomiesByClassification.shift_type,
          });
        }
      });
    });

    return {
      shifts: shiftsForEvents,
      scheduleSelection,
    };
  }

  fetchCarerShifts(date) {
    this.props.fetchShiftsForCarerUserID({
      userID: this.props.user.id,
      starts_after: date
        .clone()
        .startOf('month')
        .startOf('isoWeek')
        .format(),
      starts_before: date
        .clone()
        .endOf('month')
        .endOf('isoWeek')
        .format(),
    });
  }

  componentDidMount() {
    this.fetchCarerShifts(this.state.initialTime.clone());
  }

  onChangeScheduleSelection = e => {
    const id = e.currentTarget.value;
    const newState = { ...this.state };
    if (newState.scheduleSelection.value.includes(id)) {
      if (newState.scheduleSelection.value.length === 1) {
        return false;
      }
      newState.scheduleSelection.value.splice(
        newState.scheduleSelection.value.indexOf(id),
        1,
      );
    } else {
      newState.scheduleSelection.value.push(id);
    }
    this.setState(newState);
  };

  onNavigate = date => {
    this.fetchCarerShifts(moment(date));
  };

  render() {
    const { taxonomiesByClassification } = this.props;

    if (this.state.shifts === null || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    return (
      <CarerScheduleShifts
        shifts={this.state.shifts}
        onChangeScheduleSelection={this.onChangeScheduleSelection}
        scheduleSelection={this.state.scheduleSelection}
        onNavigate={this.onNavigate}
        initialTime={this.state.initialTime.toDate()}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const user = getUser(state);
  let shifts;
  if (user) {
    shifts = selectShiftsByUserID(state, user.id);
  }

  return {
    needs: selectNeedsForShifts(state, shifts),
    user,
    shifts,
    shiftSchedules: selectSchedulesForShifts(state, shifts),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      CarerScheduleContainer.TAXONOMY_CLASSIFICATIONS,
    ),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchShiftsForCarerUserID,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerScheduleContainer);
