import React from 'react';
import { Container, Col } from 'components/grid';
import { Field } from 'redux-form';

import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import StandardInput from 'components/forms/StandardInput';
import StyledLabel from 'components/forms/StyledLabel';
import ValidationError from 'components/forms/ValidationError';
import TickIcon from 'components/global/icons/small/TickIcon';

import BasicFlexContainer from 'components/global/layout/BasicFlexContainer';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import Button from 'components/buttons/Button';

import { required, matchesPassword } from 'validators';

type Props = {
  email: string,
  updatePassword: Object => void,
};

const AccountChangePassword = ({ updatePassword, email }: Props) => (
  <Form
    name="changePassword"
    fields={['current_password', 'password', 'confirm_password']}
    onSubmit={updatePassword}
    component={({ handleSubmit, pristine, error }) => (
      <section id="change-password">
        {error && <ValidationError>{error}</ValidationError>}
        <Module>
          <ModuleHeader>Change Password</ModuleHeader>
          <form onSubmit={handleSubmit}>
            <Container>
              <FormRow>
                <Col xs={12}>
                  <StyledLabel>Account email: </StyledLabel>
                  <BasicFlexContainer
                    direction="column"
                    directionTablet="row"
                    align="center"
                  >
                    {email}{' '}
                    <TickIcon
                      height={'18px'}
                      width={'18px'}
                      marginLeft="10px"
                      label="Verified"
                    />
                  </BasicFlexContainer>
                </Col>
              </FormRow>
              <FormRow>
                <Col xs={12} md={5}>
                  <Field
                    name="current_password"
                    type="password"
                    label="Current Password"
                    component={StandardInput}
                    validate={[required]}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col xs={12} md={5}>
                  <Field
                    name="password"
                    type="password"
                    label="New Password"
                    component={StandardInput}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col xs={12} md={5}>
                  <Field
                    name="confirm_password"
                    type="password"
                    label="Confirm New Password"
                    component={StandardInput}
                    validate={[required, matchesPassword]}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col xs={12} md={5}>
                  <Button type="submit" disabled={pristine}>
                    Change password
                  </Button>
                </Col>
              </FormRow>
            </Container>
          </form>
        </Module>
      </section>
    )}
  />
);

export default AccountChangePassword;
