import FormRow from 'components/forms/FormRow';
import StandardTextarea from 'components/forms/StandardTextarea';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import InlineValidationError from 'components/forms/InlineValidationError';

import Form from 'components/forms/Form';
import Button from 'components/buttons/Button';
import React from 'react';
import { Container, Col } from 'components/grid';
import { Field } from 'redux-form';

const ApplicationForm = ({ admin, initialValues, submitBid }) => (
  <Form
    key="submitBid"
    name="submitBid"
    fields={['admin_description', 'description', 'availability']}
    selectFields={['availability']}
    values={initialValues}
    onSubmit={submitBid}
    component={({
      currentValues,
      handleSubmit,
      submitFailed,
      invalid,
      error,
    }) => (
      <Module>
        <ModuleHeader>Your Application</ModuleHeader>
        <form onSubmit={handleSubmit}>
          <Container>
            <FormRow>
              <Col xs={12}>
                <strong>Message for Franchise</strong>
                {!admin && (
                  <>
                    <p>
                      Please write a brief message to the franchise here. Don’t
                      forget to check your spelling and grammar - first
                      impressions are very important!
                    </p>
                    <p>
                      Please include key details of what you expect from the
                      franchise; any travel expenses you will need covered,
                      as well as the shift patterns you would like to work.
                    </p>
                  </>
                )}
                <Field component={StandardTextarea} name="description" />
              </Col>
            </FormRow>
          </Container>
          {admin && (
            <Container>
              <FormRow>
                <Col xs={12}>
                  <h3>Admin Message</h3>
                  <Field
                    component={StandardTextarea}
                    name="admin_description"
                  />
                </Col>
              </FormRow>
            </Container>
          )}
          <Container>
            <FormRow>
              <Col xs={12} md={4}>
                {submitFailed && invalid && (
                  <InlineValidationError>
                    {error
                      ? error
                      : 'Please complete all required fields above to apply.'}
                  </InlineValidationError>
                )}
                <Button type="submit">Apply now</Button>
              </Col>
            </FormRow>
          </Container>
        </form>
      </Module>
    )}
  />
);

export default ApplicationForm;
