/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { getWidth } from 'components/grid/screen-utils';

export default class Row extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    outerGutter: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    screenClass: PropTypes.number,
    defaultColGutter: PropTypes.number,
    style: PropTypes.shape(),
    colGutter: PropTypes.number,
  };

  render() {
    //Gutter width is passed via client code, defaultGutterWidth is passed from the container behind the scenes
    const {
      style,
      className,
      children,
      defaultColGutter,
      colGutter,
      outerGutter,
      screenClass,
    } = this.props;

    const colGutterWidth = colGutter || defaultColGutter;
    const colCount = React.Children.count(this.props.children);
    let colStart = 1;

    const modifiedChildren = React.Children.map(children, (child, index) => {
      if (!child) {
        return child;
      }
      const { xs, sm, md, lg, xl, offset } = child.props;
      const width = getWidth([xl, lg, md, sm, xs], screenClass);
      const offsetWidth = offset
        ? getWidth(
            [offset.xl, offset.lg, offset.md, offset.sm, offset.xs],
            screenClass,
            0,
          )
        : null;
      const totalWidth = width + offsetWidth;
      const first = !outerGutter && colStart === 1;
      const last = !outerGutter && (colStart + totalWidth - 1) % 12 === 0;
      colStart = (colStart + totalWidth) % 12;
      return React.cloneElement(child, {
        first,
        last,
        width,
        offsetWidth,
        _colGutter: colGutterWidth,
      });
    });

    const theStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      ...style,
    };

    return (
      <div className={className} style={theStyle}>
        {modifiedChildren}
      </div>
    );
  }
}
