import PropTypes from 'prop-types';
// App 2.0 component
import React, { Component } from 'react';
import { Container } from 'components/grid';

import Module, { ModuleHeader } from 'components/global/layout/Module';
import AddPaymentMethod from 'components/payments/AddPaymentMethod';
import RemovePaymentMethod from 'components/payments/RemovePaymentMethod';

class AccountPaymentMethods extends Component {
  static propTypes = {
    user: PropTypes.shape().isRequired,
    paymentMethods: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    createPaymentMethod: PropTypes.func.isRequired,
    removePaymentMethod: PropTypes.func.isRequired,
  };

  render() {
    const paymentMethod =
      this.props.paymentMethods && this.props.paymentMethods[0];

    return (
      <Module>
        <ModuleHeader>Payment Method</ModuleHeader>
        {paymentMethod ? (
          <RemovePaymentMethod
            paymentMethod={paymentMethod}
            removePaymentMethod={this.props.removePaymentMethod}
          />
        ) : (
          <Container>
            <AddPaymentMethod
              user={this.props.user}
              createPaymentMethod={this.props.createPaymentMethod}
            />
          </Container>
        )}
        {paymentMethod && (
          <Container>
            <p>
              Please remove the payment method above before adding a new one.
            </p>
          </Container>
        )}
      </Module>
    );
  }
}

export default AccountPaymentMethods;
