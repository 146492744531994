import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import moment from 'moment';

import { getCarerById } from '_actions/carerActions';
import { fetchShiftsForCarerUserID } from '_actions/shiftActions';
import CarerHome from 'components/carers/CarerHome';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import { getUser } from 'shared/selectors/accountSelectors';
import { selectShiftsByUserID } from 'shared/selectors/shiftsSelectors';
import {
  getUserAsCarer,
  getCarerProfileCompleted,
} from 'shared/selectors/carerSelectors';

class CarerHomeContainer extends Component {
  static propTypes = {
    carer: PropTypes.shape(),
    fetchShiftsForCarerUserID: PropTypes.func.isRequired,
    profileComplete: PropTypes.bool.isRequired,
    user: PropTypes.shape(),
    getCarerById: PropTypes.func.isRequired,
  };

  static defaultProps = {
    carer: null,
    user: null,
  };

  UNSAFE_componentWillMount() {
    this.props.fetchShiftsForCarerUserID({
      userID: this.props.user.id,
      starts_after: moment()
        .startOf('isoWeek')
        .format(),
      starts_before: moment()
        .startOf('isoWeek')
        .add(2, 'weeks')
        .format(),
    });
    this.props.getCarerById(this.props.user.id);
  }

  render() {
    const { carer, profileComplete, user } = this.props;

    if (!carer || !carer.profile_details) {
      return <LoadingPlaceholder />;
    }

    const fountainState = carer.profile_details.fountain_state || '';

    if (
      [
        'start',
        'prescreen',
        'interview',
        'newdbsrequired',
        'dbsinprogress',
        'thirdpartydbsinprogress',
        'dbsregisteronline',
        'dbscomplete',
        'inductionselect',
        'inductionheadoffice',
        'inductionlondonandhomecounties',
        'inductionbirmingham',
        'inductionbradfordleeds',
        'rejected',
        'failedtrustidcheck',
        'onhold',
        'unresponsive',
        'thirdpartydbsappliedfor',
        'newdbsapplication',
        'dbsappliedfor',
        'fileaudit',
        'finalchecks',
      ].includes(fountainState.replace(/ /g, ''))
    ) {
      return <Redirect to="/onboarding/" />;
    }

    return (
      <CarerHome
        carer={carer}
        profileComplete={profileComplete}
        user={user}
      />
    );
  }
}

const mapStateToProps = state => {
  const user = getUser(state);
  return {
    carer: getUserAsCarer(state),
    profileComplete: getCarerProfileCompleted(state),
    shifts: selectShiftsByUserID(state, user.id),
    user,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchShiftsForCarerUserID,
      getCarerById,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerHomeContainer);
