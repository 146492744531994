import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import MainAppContainer from 'containers/MainAppContainer';

import { getLocalStorageLoginData } from '_actions/accountActions';
import { getTaxonomiessRequest } from '_actions/taxonomyActions';
import { getTaxonomies } from 'shared/selectors/taxonomySelectors';
import { isLoggedIn } from 'shared/selectors/accountSelectors.js';
import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';

class AppContainer extends Component {
  // user data is set in local storage after successful login, before loading
  // the app go see if we have it first
  componentDidMount() {
    this.props.getLocalStorageLoginData();
    this.props.getTaxonomiessRequest();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.getTaxonomiessRequest();
    }
  }

  render() {
    const {
      children,
      isLoggedIn,
      loginLoadingState,
      logoutLoadingState,
      taxonomies,
    } = this.props;

    // we're waiting to see if the user is in local storage at this point
    if (loginLoadingState) {
      return <LoadingPlaceholder />;
    }

    // if there's no user set in store, or  in the process of logging out,
    // show the login wrapper
    if (!isLoggedIn || logoutLoadingState) {
      return children;
    }

    // otherwise show the main app
    return (
      <MainAppContainer taxonomies={taxonomies}>{children}</MainAppContainer>
    );
  }
}

AppContainer.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  loginLoadingState: PropTypes.bool.isRequired,
  logoutLoadingState: PropTypes.bool.isRequired,
  taxonomies: PropTypes.shape(),
};

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
  loginLoadingState: loadingSelectorFactory('accounts', 'login')(state),
  logoutLoadingState: loadingSelectorFactory('accounts', 'logout')(state),
  taxonomies: getTaxonomies(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLocalStorageLoginData,
      getTaxonomiessRequest,
    },
    dispatch,
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AppContainer),
);
