import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Currency from 'components/modules/Currency';
import Days from 'components/modules/Days';
import Hours from 'components/modules/Hours';

class TimesheetTotalCustomer extends Component {
  static propTypes = {
    timesheetTotal: PropTypes.shape({
      application_total: PropTypes.number,
      application_total_gross: PropTypes.number,
      application_total_net: PropTypes.number,
      carer_total: PropTypes.number,
      carer_total_gross: PropTypes.number,
      carer_total_net: PropTypes.number,
      customer_total: PropTypes.number,
      customer_total_net: PropTypes.number.isRequired,
      insurance_payment: PropTypes.number,
      livein_days: PropTypes.number.isRequired,
      minutes_total: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <tbody>
        <tr className="total">
          <td rowSpan="1">
            <strong>Total</strong>
          </td>
          <td />
          <td className="number">
            <strong>
              {!!this.props.timesheetTotal.livein_days && (
                <Days
                  context="Live-in"
                  days={this.props.timesheetTotal.livein_days}
                />
              )}
              {!!this.props.timesheetTotal.minutes_total && (
                <Hours minutes={this.props.timesheetTotal.minutes_total} />
              )}
            </strong>
          </td>
          <td className="number">
            <strong>
              <Currency value={this.props.timesheetTotal.customer_total_net} />
            </strong>
          </td>
        </tr>
      </tbody>
    );
  }
}

export default TimesheetTotalCustomer;
