import { createBid, updateBid, searchBids } from '_actions/bidActions';
import { getCareTeamByNeed } from '_actions/careTeamActions';
import BidConstants from 'shared/constants/BidConstants';
import { all, takeLatest, take, put } from 'redux-saga/effects';

const BID_BUFFER_VALUE = 10;

export function* getBidsWithCarers(action) {
  try {
    const { params, page } = action.payload;
    yield put(searchBids(params, page));
    const result = yield take([
      BidConstants.BID_SEARCH_SUCCESS,
      BidConstants.BID_SEARCH_FAILURE,
    ]);
    if (result.type === BidConstants.BID_SEARCH_SUCCESS) {
      yield put({
        type: BidConstants.SEARCH_BIDS_WITH_CARERS_SUCCESS,
      });
    } else {
      yield put({
        type: BidConstants.SEARCH_BIDS_WITH_CARERS_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: BidConstants.SEARCH_BIDS_WITH_CARERS_FAILURE,
    });
  }
}

export function* getBidsWithCarersListener() {
  yield takeLatest(BidConstants.SEARCH_BIDS_WITH_CARERS, getBidsWithCarers);
}

export function* createMultipleBids(action) {
  const { carerIDs, jobOfferID } = action.payload;
  let bidsCreated = 0;
  try {
    // Initially send off only 10 requests
    const carerIDRequests = carerIDs.slice();
    const carerIDBatch = carerIDRequests.splice(0, BID_BUFFER_VALUE);
    yield all(
      carerIDBatch.map(carerID =>
        put(createBid({ carer_id: carerID, job_offer_id: jobOfferID })),
      ),
    );

    while (bidsCreated < carerIDs.length) {
      const result = yield take([
        BidConstants.BID_CREATE_SUCCESS,
        BidConstants.BID_CREATE_FAILURE,
      ]);

      // Whenever we get a response back we can send off for the next one
      if (carerIDRequests.length) {
        yield put(
          createBid({
            carer_id: carerIDRequests.shift(),
            job_offer_id: jobOfferID,
          }),
        );
      }

      if (result.type === BidConstants.BID_CREATE_SUCCESS) {
        bidsCreated += 1;
      } else {
        throw new Error();
      }
    }
    yield put({
      type: BidConstants.BID_CREATE_MULTIPLE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: `${carerIDs.length} bid(s) have been created.`,
      },
    });
  } catch (error) {
    yield put({
      type: BidConstants.BID_CREATE_MULTIPLE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title:
          'An error occurred. Please check if any bids were created then try again.',
      },
    });
  }
}

export function* createMultipleBidsListener() {
  yield takeLatest(BidConstants.BID_CREATE_MULTIPLE, createMultipleBids);
}

export function* approveBid(action) {
  const { bid, needID } = action.payload;
  try {
    yield put(updateBid(bid.id, { state: 'approved' }));
    const result = yield take([
      BidConstants.BID_UPDATE_SUCCESS,
      BidConstants.BID_UPDATE_FAILURE,
    ]);
    if (result.type === BidConstants.BID_UPDATE_SUCCESS) {
      if (result.data.id === bid.id) {
        yield put(getCareTeamByNeed(needID));
        yield put({
          type: BidConstants.BID_APPROVE_SUCCESS,
          toast: {
            type: 'success',
            public: false,
            title: 'Bid approved. Carer added to team.',
          },
        });
      }
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put({
      type: BidConstants.BID_APPROVE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'An error occurred. Please reload the page and try again.',
      },
    });
  }
}

export function* approveBidListener() {
  yield takeLatest(BidConstants.BID_APPROVE, approveBid);
}
