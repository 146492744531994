// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AdminFranchise from 'components/admin/needs/AdminFranchise';

import { populateFranchiseWithID } from '_actions/franchiseActions';
import { updateNeed } from '_actions/needActions';

import { getFranchiseByID } from 'shared/selectors/franchiseSelectors';
import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';
import { getNeedByID } from 'shared/selectors/needSelectors';
import { getUsersForFranchise } from 'shared/selectors/userSelectors';

import type { FranchiseType, NeedType } from 'shared/types';

type Props = {
  franchise: FranchiseType,
  franchiseID: string,
  franchiseUsers: Array,
  franchiseUsersLoadingState: boolean,
  need: NeedType,
  needID: string,
  populateFranchiseWithID: (value: string) => mixed,
  updateNeed: (value: string) => mixed,
};

class AdminFranchiseContainer extends Component<Props> {

  static defaultProps = {
    need: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [];
  }

  componentDidMount() {
    if (!!this.props.franchiseID) {
      this.props.populateFranchiseWithID(this.props.franchiseID);
    }
  }

  onNeedSubmit = ({ data }) => {
    this.props.updateNeed({ id: this.props.needID, ...data });
  };

  render() {
    const {
      franchise,
      franchiseUsers,
      franchiseUsersLoadingState,
      need,
    } = this.props;

    if (
      !need ||
      !franchise ||
      franchiseUsersLoadingState
    ) {
      return <LoadingPlaceholder />;
    }

    const initialNeedValues = { ...need };

    return (
      <AdminFranchise
        franchise={franchise}
        franchiseUsers={franchiseUsers}
        initialNeedValues={initialNeedValues}
        onNeedSubmit={this.onNeedSubmit}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    franchise: getFranchiseByID(state, props.franchiseID),
    franchiseUsers: getUsersForFranchise(state, props.franchiseID),
    franchiseUsersLoadingState: loadingSelectorFactory('franchises', 'franchiseUsers')(state),
    need: getNeedByID(state, props.needID),
  };
};

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      populateFranchiseWithID,
      updateNeed,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminFranchiseContainer);
