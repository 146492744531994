import PropTypes from 'prop-types';
import React, { Component } from 'react';

class StripeAuth extends Component {
  render() {
    let message;
    let error;

    if (this.props.location.query.error) {
      message = (
        <p>Something went wrong. Please contact the Home Instead Live-in Care team.</p>
      );
      error = <p>{this.props.location.query.error_description}.</p>;
    } else {
      message = <p>Thank you, please wait while you are redirected.</p>;
    }

    return (
      <section className="component-wrapper stripe-connect">
        <div className="module boxed">
          <div className="old-row">
            <div className="old-col">
              {message}
              {error}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

StripeAuth.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      code: PropTypes.string,
      error: PropTypes.string,
      error_description: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default StripeAuth;
