import PropTypes from 'prop-types';
import React from 'react';
import { currencyFormat } from 'components/helpers/currency';
import styled from 'styled-components';

const Frequency = styled.span`
  color: ${props => props.theme.colors.midGrey};
  display: inline-block;
  margin-left: 10px;
`;

const Currency = ({ value, frequency }) => (
  <span>
    {currencyFormat(value)}
    {frequency && <Frequency>{frequency}</Frequency>}
  </span>
);

Currency.propTypes = {
  value: PropTypes.number.isRequired,
  frequency: PropTypes.string,
};

Currency.defaultProps = {
  frequency: null,
};

export default Currency;
