import { getUser } from 'shared/selectors/accountSelectors';
import store from 'store';
import axios from 'axios';
import qs from 'qs';

class SuperCarersClient {
  static request(method, url, { params, body }) {
    const options = {
      url: window.global.SUPERCARERS_API_URL + url,
      method,
      paramsSerializer: p => qs.stringify(p, { arrayFormat: 'repeat' }),
      timeout: 30000,
    };

    const user = getUser(store.getState());
    if (user && user.access_token) {
      options.headers = {
        Authorization: `JWT ${user.access_token}`,
      };
    }

    if (params) {
      options.params = params;
    }

    if (body) {
      options.data = body;
    } else {
      options.data = {};
    }

    return axios(options).then(response => response.data);
  }

  static get(url, params) {
    return SuperCarersClient.request('GET', url, { params });
  }

  static delete(url, params) {
    return SuperCarersClient.request('DELETE', url, { params });
  }

  static post(url, body) {
    return SuperCarersClient.request('POST', url, { body });
  }

  static put(url, body) {
    return SuperCarersClient.request('PUT', url, { body });
  }

  static errorDescription(error) {
    if (error.response) {
      if (error.response.body.description) {
        return error.response.body.description;
      }
      return error.response.body.message;
    }
    return error;
  }
}

export default SuperCarersClient;
