import keyMirror from 'keymirror';

export default keyMirror({
  PAYMENTS_ADD_PAYMENT: null,
  PAYMENTS_ADD_PAYMENT_SUCCESS: null,
  PAYMENTS_ADD_PAYMENT_FAILURE: null,

  PAYMENTS_GET_SETUP_INTENT: null,
  PAYMENTS_GET_SETUP_INTENT_SUCCESS: null,
  PAYMENTS_GET_SETUP_INTENT_FAILURE: null,

  PAYMENTS_CREATE_PAYMENT_METHOD: null,
  PAYMENTS_CREATE_PAYMENT_METHOD_SUCCESS: null,
  PAYMENTS_CREATE_PAYMENT_METHOD_FAILURE: null,

  PAYMENTS_DELETE_PAYMENT_METHOD: null,
  PAYMENTS_DELETE_PAYMENT_METHOD_SUCCESS: null,
  PAYMENTS_DELETE_PAYMENT_METHOD_FAILURE: null,

  PAYMENTS_GET_USER_METHODS: null,
  PAYMENTS_GET_USER_METHODS_SUCCESS: null,
  PAYMENTS_GET_USER_METHODS_FAILURE: null,

  PAYMENTS_CONNECT_STRIPE: null,
  PAYMENTS_CONNECT_STRIPE_SUCCESS: null,
  PAYMENTS_CONNECT_STRIPE_FAILURE: null,

  PAYMENTS_GET_PAYMENT_INTENT: null,
  PAYMENTS_GET_PAYMENT_INTENT_SUCCESS: null,
  PAYMENTS_GET_PAYMENT_INTENT_FAILURE: null,

  PAYMENTS_AUTHENTICATE_PAYMENT: null,
  PAYMENTS_AUTHENTICATE_PAYMENT_SUCCESS: null,
  PAYMENTS_AUTHENTICATE_PAYMENT_FAILURE: null,
});
