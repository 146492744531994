// @flow
import React, { Component, type ComponentType } from 'react';
import styled from 'styled-components';

import InlineValidationError from 'components/forms/InlineValidationError';
import StyledRadio from 'components/forms/StyledRadio';
import StyledRadioWithIcon from 'components/forms/StyledRadioWithIcon';
import StyledTitleWithLabel from 'components/forms/StyledTitleWithLabel';
import type { InputType } from 'shared/types';

const CustomStyledRadio = styled(StyledRadio)`
  margin-bottom: 5px;
  vertical-align: top;
  width: 100%;

  &.added {
    > label,
    > input:checked + label {
      border-color: ${props => props.theme.colors.changeGreen};
      box-shadow: none;
      color: ${props => props.theme.colors.changeGreen};
    }
  }

  &.removed {
    > label {
      box-shadow: 0 0 6px 0 ${props => props.theme.colors.shadowOrange};
    }
  }

  &:last-child {
    margin-bottom: 10px;
  }

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  @media (min-width: 768px) {
    margin-right: 3%;
    width: 30%;

    &:nth-of-type(2n) {
      margin-right: ${props => (props.withPicker ? '30%' : '3%')};
    }

    &:nth-of-type(3n) {
      margin-right: ${props => (props.withPicker ? '3%' : '0')};
    }
  }
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledPicker = styled.label`
  border-left: 1px solid ${props => props.theme.colors.uiGrey};
  display: inline-block;
  position: absolute;
  height: 50px;
  padding: 5px;
  top: 36px;
  right: 30%;

  &:hover {
    cursor: pointer;
  }

  > input {
    position: relative;
    top: 6px;
  }
`;

type Props = {
  changes: Object,
  help?: boolean,
  extraPadding?: boolean,
  greyTitle?: boolean,
  higherContrast?: boolean,
  input: InputType,
  label: string,
  options: [
    {
      value: string,
      label: string,
      icon: ComponentType<any>,
      className?: string,
    },
  ],
  pick: (any, any) => void,
  tag: string,
  title: string,
  meta: {
    touched: boolean,
    error: boolean,
    warning: boolean,
  },
};

class StandardRadioGroup extends Component<Props> {
  static defaultProps = {
    advice: null,
    changes: {},
    label: null,
    help: '',
    pick: null,
    tag: null,
    title: null,
  };

  onChange = (e: SyntheticEvent<Object>) => {
    const { input } = this.props;
    //$FlowFixMe - value error of events is a quirk of flow
    if (e.target.value.toLowerCase() === 'true') {
      return input.onChange(true);
    }
    //$FlowFixMe - value error of events is a quirk of flow
    if (e.target.value.toLowerCase() === 'false') {
      return input.onChange(false);
    }
    //$FlowFixMe - value error of events is a quirk of flow
    return input.onChange(e.target.value);
  };

  render() {
    const {
      changes,
      extraPadding,
      greyTitle,
      help,
      input,
      label,
      options,
      pick,
      tag,
      title,
      meta: { touched, error, warning },
      higherContrast,
    } = this.props;

    const withIcons = options.some(option => option.icon);
    const withPicker = !!pick && (changes.lhs || changes.rhs);

    return (
      <StyledWrapper id={`${input.name}`}>
        <StyledTitleWithLabel
          title={title}
          label={label}
          tag={tag}
          help={help}
          error={touched && error}
          extraPadding={extraPadding}
          greyTitle={greyTitle}
        />
        {withIcons
          ? options.map(option => (
              <StyledRadioWithIcon
                name={input.name}
                key={option.label}
                value={option.value}
                label={option.label}
                onChange={this.onChange}
                checked={input.value === option.value}
                Icon={option.icon}
                withPicker={!!withPicker}
              />
            ))
          : options.map(option => (
              <CustomStyledRadio
                name={input.name}
                key={option.label}
                value={option.value}
                label={option.label}
                onChange={this.onChange}
                checked={input.value === option.value}
                className={`
                ${changes.lhs === option.value ? 'removed' : ''}
                ${changes.rhs === option.value ? 'added' : ''}
              `}
                withPicker={!!withPicker}
                higherContrast={higherContrast}
              />
            ))}
        {pick && changes.kind && (
          <StyledPicker
            htmlFor={`${input.name}-picker-input`}
            key={`${input.name}-picker`}
          >
            <input
              id={`${input.name}-picker-input`}
              onChange={e => {
                e.persist();
                pick(input.value, e.target.checked);
              }}
              type="checkbox"
            />
          </StyledPicker>
        )}
        {touched &&
          ((error && <InlineValidationError>{error}</InlineValidationError>) ||
            (warning && (
              <InlineValidationError>{warning}</InlineValidationError>
            )))}
      </StyledWrapper>
    );
  }
}

export default StandardRadioGroup;
