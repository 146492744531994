import { injectGlobal } from 'styled-components';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

// Allow use of viewheights (vh) units on mobile, despite browser controls being included in vh calculations
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

injectGlobal`

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
      display: block;
  }
  body {
      line-height: 1;
  }
  ol, ul {
      list-style: none;
  }
  b {
      font-weight: bold;
  }
  i {
      font-style: italic;
  }
  blockquote, q {
      quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
      content: '';
      content: none;
  }
  table {
      border-collapse: collapse;
      border-spacing: 0;
  }

  * {
      box-sizing: border-box;
  }

  @media (min-width: 30em) {
      .old-row {
          width: 100%;
          display: table;
          table-layout: fixed;
      }
      .old-row + .old-row {
          margin-top: 20px;
      }
      .old-col {
          display: table-cell;
          padding: 0 5px 0 0;
          vertical-align: top;
      }
      .col-20 {
          width: 20%;
      }
      .col-25 {
          width: 25%;
      }
      .col-33 {
          width: 33.33333333%;
      }
      .col-40 {
          width: 40%;
      }
      .col-60 {
          width: 60%;
      }
      .col-80 {
          width: 80%;
      }
      .old-row.mid .old-col {
          text-align: left;
          vertical-align: middle;

          > * {
              margin: 0 !important;
          }
      }
  }

  @media (max-width: 30em) {
      .old-col + .old-col {
          margin-bottom: 10px;
          margin-top: 20px;
      }
  }

  html {
      background: #f7f7f7;
  }

  body {
      background: #f7f7f7;
  }

  div.module {
      border-radius: 7px;
      background-color: #ffffff;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
      margin-bottom: 20px;
      padding: 35px 20px;

      h2 {
          padding-left: 0;
      }

      &.boxed {
          background: white;
      }
  }
  .loading-placeholder {
      height: 100vh;

      &.noHeight {
        height: auto;
      }

      svg {
          display: block;
          margin: 20% auto 0;
      }

      .path {
          stroke-dasharray: 460;
          stroke-dashoffset: 460;
          animation: draw-in 3s ease-out;
          animation-iteration-count: infinite;
      }

      @keyframes draw-in {
          0% {
              stroke-dashoffset: -460;
          }
          2% {
              stroke-dashoffset: -460;
          }
          25% {
              stroke-dashoffset: 0;
          }
          75% {
              stroke-dashoffset: 0;
          }
          100% {
              stroke-dashoffset: 460;
          }
      }
  }
  a,
  div[role="button"] {
      color: #1c7589;
      cursor: pointer;
      display: inline-block;
      text-decoration: underline;
  }
  .times-picker {
      margin-bottom: 30px;
      user-select: none;
      -webkit-user-select: none;

      .header {
          display: inline-block;
          font-weight: 500;
          margin-bottom: 20px;
          min-width: 200px;
      }

      .day {
          background: white;
          border: 1px solid #D4E4E7;
          margin-bottom: 20px;
          padding: 20px 40px 30px;
          position: relative;

          &.collapsed {
              background: white;
              cursor: pointer;
              padding-bottom: 20px;

              span.header {
                  color: #4A4A4A;
              }

              .hours,
              p.error {
                  display: none;
              }

              span.header,
              .summary {
                  margin-bottom: 0;
              }
          }
      }

      .summary {
          display: inline-block;
          margin-bottom: 20px;
          white-space: nowrap;

          span.header {
              margin-bottom: 0px;
              margin-right: 10px;
              min-width: 0;
          }

          ol {
              display: inline-block;
              margin: 0;
              max-width: 700px;
              overflow: hidden;
              padding: 0;
              text-overflow: ellipsis;
              vertical-align: bottom;
          }

          li {
              color: #4A4A4A;
              display: inline-block;
          }

      }

      .hours {
          border: 1px solid #D4E4E7;
          line-height: 0;
      }

      .hour {
          display: inline-block;
          line-height: 0;
          user-select: none;
          width: 4.16666666667%;
          -webkit-user-select: none;

          span.label {
              background: white;
              border-bottom: 1px solid #D4E4E7;
              border-right: 1px solid #D4E4E7;
              cursor: default;
              display: block;
              line-height: 24px;
              font-size: 11px;
              text-align: center;
          }

          .increment {
              border-right: 1px solid #D4E4E7;
              cursor: pointer;
              display: inline-block;
              min-height: 60px;
              width: 50%;

              &.half {
                  border-right-color: #D4E4E7;
              }

              &:hover {
                  background: orange;
                  border-right-color: orange;
              }

              &.checked {
                  background: #2E404F;
                  border-right-color: #D4E4E7;

                  &:hover {
                      background: #D4E4E7;
                  }
              }
          }

          &:last-child {
              span.label,
              .increment:last-child {
                  border-right: none;
              }
          }
      }

      p.error {
          color: orange;
          margin-bottom: 0;
      }
  }

  .component-wrapper {
      .btn {
          background: #56a6d1;
          border: none;
          border-radius: 4px;
          box-shadow: 0 1px 6px 0 rgba(86, 166, 209, 0.6);
          color: white;
          cursor: pointer;
          display: inline-block;
          font-size: 18px;
          line-height: 26px;
          text-align: center;
          text-decoration: none;
          padding: 15px;
          width: 100%;
      }

      .btn.btn-alt {
          background: #dd6254;
      }

      strong {
          font-weight: 600;
      }

      h1 {
          font-size: 22px;
          margin-bottom: 20px;
          font-weight: 600;
      }

      table {
          background: white;
          border: 1px solid #f7f7f7;
          font-feature-settings: 'tnum' on; // Make numbers line up nice in tables
          margin-bottom: 10px;
          width: 100%;

          tr {
              border-bottom: 1px solid #f7f7f7;
          }

          tbody:last-child {
              tr:last-child {
                  border: none;
              }
          }

          th {
              font-weight: bold;
          }

          th,
          td {
              vertical-align: top;
              padding: 10px;

              &.td-20 {
                  width: 20%;
              }

              &.td-25 {
                  width: 25%;
              }

              &.number,
              &.right-align {
                  text-align: right;
              }

              &.number-negative {
                  color: red;
              }
          }

          &.striped {
              tr {
                  &:nth-child(odd) {
                      td {
                          background-color: #f7f7f7;
                      }
                  }
              }
          }

          &.padded {
              tr {
                  th,
                  td {
                      vertical-align: middle;
                      padding: 15px;

                      &.td-left {
                          padding-left: 30px;
                      }

                      &.td-right {
                          padding-right: 30px;
                      }

                      &.range {
                          white-space: nowrap;
                      }
                  }

                  &.total {
                      th,
                      td {
                          background: #f8f6ef;
                          padding-top: 30px;
                          padding-bottom: 30px;
                      }
                  }
              }
          }
      }

      textarea {
          border: solid 1px #dfe5e8;
          border-radius: 2px;
          color: #4a4a4a;
          font-size: 18px;
          padding: 15px 10px;
          width: 100%;
      }

      label {
          color: #979797;
          display: block;
          margin-bottom: 5px;
          font-weight: 600;
      }
      input {
          border-radius: 2px;
          background-color: rgba(247, 247, 247, 0.4);
          border: solid 1px #dfe5e8;
          color: #4a4a4a;
          font-size: 18px;
          padding: 15px 10px;
          width: 100%;
      }
      select {
          appearance: none;
          -webkit-appearance: none;
          background-color: #fcfcfc;
          &:not([multiple]) {
              background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"><g transform="translate(8.131728, 2.131728) rotate(-45.000000) translate(-8.131728, -2.131728) translate(2.631728, -3.868272)" stroke="#4A4A4A" stroke-width="1.5"><path d="M0.75,0.6 L0.75,10.2"></path><path d="M10.35,10.2496938 L0.75,10.2496938"></path></g></g></svg>');
              background-position: right 10px center;
              background-repeat: no-repeat;
              background-size: 16px 100%;
          }
          border-radius: 6px;
          border: 1px solid #d4e4e7;
          color: #4a4a4a;
          display: block;
          font-size: 18px;
          padding: 15px 35px 15px 10px;
          width: 100%;

          &.error {
              border-color: #d86663;
          }

          &:focus {
              background-color: white;
          }
      }
  }

`;

export default 'globalstyles';
