const needOwner = need => {
  if (need.relationship === 'myself') {
    return 'Your';
  }

  if (need.firstname) {
    if (
      (need.firstname.trim().toLowerCase() === 'mr' ||
        need.firstname.trim().toLowerCase() === 'mrs') &&
      need.lastname
    ) {
      return `${need.firstname} ${need.lastname}’s`;
    }
    return `${need.firstname.trim()}’s`;
  }
  return `Your ${need.relationship}’s`;
};

export default needOwner;
