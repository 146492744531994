// @flow
import React, { Component } from 'react';

import Module from 'components/global/layout/Module';

import FranchiseForm from 'components/franchise/FranchiseForm';

import type { FranchiseType, TaxonomiesClassificationType } from 'shared/types';


type Props = {
  franchise: FranchiseType,
  isAdmin: boolean,
  taxonomiesByClassification: TaxonomiesClassificationType,
  updateFranchise: Object => Object,
};

class AdminFranchiseInfo extends Component<Props> {

  static defaultProps = {};

  render() {
    const {
      franchise,
      isAdmin,
      taxonomiesByClassification,
      updateFranchise,
    } = this.props;

  	return (
      <Module>

        <FranchiseForm
          franchise={franchise}
          isAdmin={isAdmin}
          taxonomiesByClassification={taxonomiesByClassification}
          updateFranchise={updateFranchise}
        />

      </Module>
  	);
  }

 }

export default AdminFranchiseInfo;