// @flow
import React from 'react';
import styled from 'styled-components';

import Button from 'components/buttons/Button';
import AvatarImage from 'components/global/AvatarImage';
import Module from 'components/global/layout/Module';
import WelcomeMessage from 'components/customers/WelcomeMessage';
import { redirectToHomeInstead } from 'components/global/ContactInfo';
import HITulip from 'images/HITulip.png';

import type { UserType } from 'shared/types';

const StyledCustomerIntro = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.screen.small}px) {
    flex-wrap: wrap;
  }
`;


const StyledModule = styled(Module)`
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 10px;
  width: 40%;
`;

const StyledModuleElement = styled.div`
  max-width: 300px;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-left: 6px;
`;

const StyledButtonText = styled.div`
  display: inline;
`;


type Props = {
  user: UserType,
};

const CustomerRedirect = ({
  user
}: Props) => {
  return (
    <section>

      <WelcomeMessage need={null} user={user} redirect={true} />

      <StyledCustomerIntro>
        <StyledModule>
          <StyledModuleElement>
            <AvatarImage src={HITulip} alt={'Home Instead'}/>
          </StyledModuleElement>
          <StyledModuleElement>
            <div>If you are looking for care assistance, please click here to get started.</div>
          </StyledModuleElement>
          <StyledModuleElement>
            <StyledButton onClick={() => redirectToHomeInstead()}>
              <StyledButtonText>Visit Home Instead</StyledButtonText>
            </StyledButton>
          </StyledModuleElement>
        </StyledModule>
      </StyledCustomerIntro>

    </section>
  );
};

CustomerRedirect.defaultProps = {};

export default CustomerRedirect;
