// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import CarerJobOffers from 'components/jobOffers/CarerJobOffers';

import { selectBidSearchByName } from 'shared/selectors/bidSelectors';
import {
  searchBidsWithJobOffers,
  type SearchParamsType,
} from '_actions/bidActions';
import { selectJobOffersWithBidResultsForUser } from 'shared/selectors/jobOfferSelectors';

import type {
  BidType,
  CarerProfileType,
  HistoryType,
  JobOfferType,
  LocationType,
  SearchType,
} from 'shared/types';

const BIDS_SEARCH_ORDER_TAXONOMY = [
  { taxon_id: 'schedule_start', value: 'Starting soonest' },
  { taxon_id: 'distance', value: 'Distance' },
];

const BIDS_STATUS_ORDER_TAXONOMY = [
  { taxon_id: 'awaiting_response', value: 'Awaiting response' },
  { taxon_id: 'successful', value: 'Successful' },
  { taxon_id: 'unsuccessful', value: 'Unsuccessful' },
  { taxon_id: 'declined', value: 'Declined' },
];

type JobOffersWithBids = [
  {
    bid: BidType,
    jobOffer: JobOfferType,
  },
];

type Props = {
  default?: boolean,
  jobOffersWithBids: JobOffersWithBids,
  location: LocationType,
  name: string,
  title: string,
  history: HistoryType,
  profile: CarerProfileType,
  searchWithName: SearchType,
  searchBidsWithJobOffers: (SearchParamsType, ?number, string) => void,
  tab: string,
  userID: string,
};

const CarerJobOffersContainer = ({
  jobOffersWithBids,
  location,
  name,
  title,
  history,
  profile,
  searchWithName,
  searchBidsWithJobOffers,
  tab,
  userID,
}: Props) => {
  const bidStates =
    tab === 'new' ? ['new', 'contacted'] : ['accepted', 'applied', 'proposed'];
  const jobStates = ['open'];

  useEffect(() => {
    const { page, order, filter } = location.query;
    const action = order ? order : filter;

    if (!searchWithName) {
      const params = buildSearch();

      params.page = page || 1;
      params.per_page = 10;
      params.order_by_field = action || BIDS_SEARCH_ORDER_TAXONOMY[0].taxon_id;
      params.priority_first = true;
      searchBidsWithJobOffers(
        params,
        undefined,
        `${name}_page${params.page}_ordered_by_${params.order_by_field}`,
      );
    }
  }, [location]);

  const buildSearch = () => {
    const params = {};
    params.carer_id = userID;
    if (jobStates) {
      params.job_states = jobStates;
    }
    if (bidStates) {
      params.states = bidStates;
    }
    return params;
  };

  if (!searchWithName || !profile || !jobOffersWithBids) {
    return <LoadingPlaceholder />;
  }

  return (
    <CarerJobOffers
      jobOffersWithBids={jobOffersWithBids}
      searchWithName={searchWithName}
      searchOrder={location.query.order || location.query.filter}
      location={location}
      workState={profile.work_state}
      title={title}
      taxonomiesByClassification={{
        jobs_search_order: BIDS_SEARCH_ORDER_TAXONOMY,
        job_status_order: BIDS_STATUS_ORDER_TAXONOMY,
      }}
    />
  );
};

const mapStateToProps = (state, props) => {
  const action = props.location.query.order
    ? props.location.query.order
    : props.location.query.filter;
  const searchName = `${props.name}_page${props.location.query.page ||
    1}_ordered_by_${action || BIDS_SEARCH_ORDER_TAXONOMY[0].taxon_id}`;
  return {
    jobOffersWithBids: selectJobOffersWithBidResultsForUser(state, searchName),
    searchWithName: selectBidSearchByName(state, searchName),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchBidsWithJobOffers,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerJobOffersContainer);
