// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
// $FlowFixMe
import { Field } from 'redux-form';
import { PortalWithState } from 'react-portal';

import Button from 'components/buttons/Button';
import ButtonGroup from 'components/buttons/ButtonGroup';
import Form from 'components/forms/Form';
import Modal from 'components/modules/Modal';
import { ModuleHeader, ModuleSeparator } from 'components/global/layout/Module';
import StandardTextarea from 'components/forms/StandardTextarea';
import { Col, Container, Row } from 'components/grid';

const StyledQueryMessage = styled.div`
  font-style: italic;
`;

const StyledRequestBlurb = styled.p`
  margin-bottom: 15px;
`;

type Props = {
  buttonLabel: string,
  updateQueryMessage: string => void,
  queryMessage: string,
  needState: string,
  title: string,
};

function ChangeRequest({
  buttonLabel,
  needState,
  queryMessage,
  updateQueryMessage,
  title,
}: Props) {
  const [showRequestDialog, hideShowRequestDialog] = useState(false);

  const onSubmit = ({ query_message }) => {
    updateQueryMessage(query_message);
  };

  const QueryMessageForm = (
    <Form
      name="ChangeRequestForm"
      fields={['query_message']}
      onSubmit={onSubmit}
      component={({ currentValues, handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit} id="change-request-form">
          <Row>
            <Col>
              <StyledRequestBlurb>
                Let us know your request below and one of our team will get back
                to you as soon as possible.
              </StyledRequestBlurb>
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                data-testid="query-field"
                component={StandardTextarea}
                name="query_message"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonGroup>
                <Button
                  type="button"
                  className="alt"
                  onClick={() => hideShowRequestDialog(false)}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={pristine}>
                  Submit
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </form>
      )}
    />
  );

  const CurrentQuery = (
    <>
      <Row>
        <Col>
          We're currently working on the below request and will get back to you
          as soon as possible.
        </Col>
      </Row>
      <ModuleSeparator />
      <Row>
        <Col>
          <StyledQueryMessage>{`${queryMessage}`}</StyledQueryMessage>
        </Col>
      </Row>
    </>
  );

  const accountQueried = needState === 'queried' && !!queryMessage;

  return (
    <>
      <Button type="button" onClick={() => hideShowRequestDialog(true)}>
        {buttonLabel}
      </Button>
      {showRequestDialog && (
        <PortalWithState
          closeOnOutsideClick
          closeOnEsc
          defaultOpen
          onClose={() => hideShowRequestDialog(false)}
        >
          {({ closePortal, portal }) =>
            portal(
              <Modal
                closePortal={closePortal}
                key="change-request"
                data-testid="change-request-modal"
                small
              >
                <Container outerGutter={60}>
                  <ModuleHeader noIndent>{title}</ModuleHeader>
                  {accountQueried ? CurrentQuery : QueryMessageForm}
                </Container>
              </Modal>,
            )
          }
        </PortalWithState>
      )}
    </>
  );
}

export default ChangeRequest;
