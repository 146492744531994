import keyMirror from 'keymirror';

export default keyMirror({
  RATES_GET_DEFAULT: null,
  RATES_GET_DEFAULT_SUCCESS: null,
  RATES_GET_DEFAULT_FAILURE: null,

  RATES_SET_DEFAULT: null,
  RATES_SET_DEFAULT_SUCCESS: null,
  RATES_SET_DEFAULT_FAILURE: null,

  RATES_GET_LIMITS: null,
  RATES_GET_LIMITS_SUCCESS: null,
  RATES_GET_LIMITS_FAILURE: null,

  RATES_GET_CARER: null,
  RATES_GET_CARER_SUCCESS: null,
  RATES_GET_CARER_FAILURE: null,

  RATES_SET_CARER: null,
  RATES_SET_CARER_SUCCESS: null,
  RATES_SET_CARER_FAILURE: null,

  RATES_GET_CARE_NEED: null,
  RATES_GET_CARE_NEED_SUCCESS: null,
  RATES_GET_CARE_NEED_FAILURE: null,

  RATES_RESET_CARE_NEED: null,
  RATES_RESET_CARE_NEED_SUCCESS: null,
  RATES_RESET_CARE_NEED_FAILURE: null,

  RATES_SET_CARE_NEED: null,
  RATES_SET_CARE_NEED_SUCCESS: null,
  RATES_SET_CARE_NEED_FAILURE: null,

  RATES_GET_CARE_NEED_CARER: null,
  RATES_GET_CARE_NEED_CARER_SUCCESS: null,
  RATES_GET_CARE_NEED_CARER_FAILURE: null,

  RATES_SET_CARE_NEED_CARER: null,
  RATES_SET_CARE_NEED_CARER_SUCCESS: null,
  RATES_SET_CARE_NEED_CARER_FAILURE: null,

  RATES_FETCH_CARE_NEED_RATES: null,
  RATES_FETCH_CARE_NEED_RATES_SUCCESS: null,
  RATES_FETCH_CARE_NEED_RATES_FAILURE: null,

  RATES_SET_BID: null,
  RATES_SET_BID_SUCCESS: null,
  RATES_SET_BID_FAILURE: null,
});
