import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'components/grid';

import { getInvoiceSummary } from '_actions/invoiceActions';
import AdminInvoiceSearchContainer from 'containers/admin/AdminInvoiceSearchContainer';
import WeeklySummaryContainer from 'containers/modules/WeeklySummaryContainer';
import { ModuleHeader } from 'components/global/layout/Module';

class UnconnectedAdminInvoicesOverview extends Component {
  render() {
    return (
      <Container>
        <ModuleHeader>Invoices Overview</ModuleHeader>
        <WeeklySummaryContainer
          title="Invoices Summary"
          states={[
            'awaiting_timesheets',
            'awaiting_payment',
            'requires_action',
            'queried',
            'paid',
            'void',
          ]}
          filterLink="/admin/invoices/"
          category="invoices"
          getSummary={this.props.getInvoiceSummary}
        />
        <AdminInvoiceSearchContainer location={this.props.location} />
      </Container>
    );
  }
}

UnconnectedAdminInvoicesOverview.propTypes = {
  getInvoiceSummary: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
};

function mapStateToProps(dispatch) {
  return bindActionCreators(
    {
      getInvoiceSummary,
    },
    dispatch,
  );
}

const AdminInvoicesOverview = connect(
  null,
  mapStateToProps,
)(UnconnectedAdminInvoicesOverview);
export default AdminInvoicesOverview;
export { UnconnectedAdminInvoicesOverview };
