// @flow
import React, { Component, type AbstractComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { isLoggedIn } from 'shared/selectors/accountSelectors.js';

import LoginWrapper from 'components/login/LoginWrapper';

type Props = {
  availableLoggedIn: boolean,
  isLoggedIn: boolean,
  roles: Array<string>,
  verifying?: boolean,
};

export function withLoginWrapper(
  WrappedComponent: AbstractComponent<Props>,
): AbstractComponent<Props> {
  return class extends Component<Props> {
    render() {
      if (this.props.isLoggedIn) {
        return (
          <WrappedComponent {...this.props} />
        )
      } else {
        return (
          <LoginWrapper>
            <WrappedComponent {...this.props} />
          </LoginWrapper>
        )
      }
    }
  };
}

function mapStateToProps(state, props) {
  return {
    isLoggedIn: isLoggedIn(state),
  };
}

//$FlowFixMe
const SiteRouteWrapper = compose(
  connect(mapStateToProps),
  withLoginWrapper,
);

export default SiteRouteWrapper;
