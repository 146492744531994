export const calculateCarerGrossTotal = (carerTotal, insurancePayment) =>
  carerTotal + insurancePayment;

export const calculateApplicationGrossTotal = (customerTotal, carerTotal) =>
  customerTotal - carerTotal;

export const calculateApplicationTotal = (
  customerTotal,
  carerTotal,
  insurancePayment,
) => customerTotal - calculateCarerGrossTotal(carerTotal, insurancePayment);
