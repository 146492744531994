import React, { Component } from 'react';
import { arrayOf, func, object as obj } from 'prop-types';
import { withTheme } from 'styled-components';

import CalendarShifts from 'components/shifts/CalendarShifts';
import CustomCalendarToolbar from 'components/schedules/CustomCalendarToolbar';
import CarerShiftInformation from 'components/shifts/CarerShiftInformation';
import {
  CustomEvent,
  CustomMonthEvent,
} from 'components/admin/needs/calendar/CustomEvents';

class CarerScheduleShifts extends Component {
  static propTypes = {
    initialTime: obj.isRequired,
    onChangeScheduleSelection: func.isRequired,
    onNavigate: func.isRequired,
    shifts: arrayOf(obj).isRequired,
    scheduleSelection: obj.isRequired,
    taxonomiesByClassification: obj.isRequired,
  };

  eventPropGetter = (event, start, end, isSelected) => {
    let backgroundColor = this.props.theme.colors.calendar[
      event.needIndex % this.props.theme.colors.calendar.length
    ];

    return {
      style: {
        backgroundColor,
        fontSize: '14px',
        borderColor: 'transparent',
      },
    };
  };

  dayRangeHeaderFormat = ({ start, end }, culture, localizer) => {
    return localizer.format(start, 'MMMM YYYY', culture);
  };

  render() {
    const {
      initialTime,
      onNavigate,
      onChangeScheduleSelection,
      shifts,
      scheduleSelection,
      taxonomiesByClassification,
    } = this.props;

    return (
      <CalendarShifts
        calendarHeader="Your clients"
        checkboxSelector={true}
        components={{
          event: CustomEvent,
          toolbar: CustomCalendarToolbar,
          month: {
            event: CustomMonthEvent,
          },
        }}
        formats={{
          dayRangeHeaderFormat: this.dayRangeHeaderFormat,
        }}
        initialTime={initialTime}
        onChangeScheduleSelection={onChangeScheduleSelection}
        noShiftsMessage="You do not yet have any upcoming shifts"
        onNavigate={onNavigate}
        shifts={shifts}
        scheduleSelection={scheduleSelection}
        shiftInformationModal={CarerShiftInformation}
        shiftInformationProps={{ showAdminLinks: false }}
        shiftStyles={this.eventPropGetter}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

export default withTheme(CarerScheduleShifts);
