import React from 'react';
import styled from 'styled-components';
import { object as obj } from 'prop-types';

import { ModuleMeta } from 'components/global/layout/Module';

const StyledModuleMeta = styled(ModuleMeta)`
  margin-bottom: 0px;
`;

const StyledReference = styled.div`
  display: inline;
`;

const JobRef = ({ jobOffer }) => (
  <StyledModuleMeta>
    <div>
      <StyledReference>Reference: </StyledReference>
      <strong>{jobOffer.reference}</strong>
    </div>
  </StyledModuleMeta>
);

JobRef.propTypes = {
  jobOffer: obj.isRequired,
};

export default JobRef;
