import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import {
  calculateApplicationTotal,
  calculateApplicationGrossTotal,
  calculateCarerGrossTotal,
} from 'components/helpers/timesheetHelpers';
import TimesheetTotal from 'components/timesheets/TimesheetTotal';

import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { isAdmin } from 'shared/selectors/accountSelectors';

class TimesheetTotalContainer extends Component {
  timesheetTotal = () => {
    let timesheetTotal = {
      // total customer paid originally
      customer_total: 0,
      // total customer has paid incl. refunds
      customer_total_net: 0,
      // total carer paid (according to customer)
      carer_total_gross: 0,
      // total carer actually paid (minus insurance payment)
      carer_total: 0,
      // total carer paid incl. refunds
      carer_total_net: 0,
      // total supercarers has recieved (plus insurance payment)
      application_total_gross: 0,
      // total supercarers has received (according to customer)
      application_total: 0,
      // total supercares has received (according to customer) incl. refunds
      application_total_net: 0,
      // additional data
      insurance_payment: this.props.totals['insurance_payment'],
      minutes_total: 0,
      livein_days: 0,
    };

    ['carer_total', 'customer_total', 'minutes_total', 'livein_days'].forEach(
      key => {
        timesheetTotal[key] = this.props.totals.total[key];
      },
    );

    timesheetTotal.application_total = calculateApplicationTotal(
      timesheetTotal.customer_total,
      timesheetTotal.carer_total,
      timesheetTotal.insurance_payment,
    );

    timesheetTotal.application_total_gross = calculateApplicationGrossTotal(
      timesheetTotal.customer_total,
      timesheetTotal.carer_total,
    );

    timesheetTotal.carer_total_gross = calculateCarerGrossTotal(
      timesheetTotal.carer_total,
      timesheetTotal.insurance_payment,
    );

    timesheetTotal.carer_total_net = timesheetTotal.carer_total;
    timesheetTotal.application_total_net = timesheetTotal.application_total;
    timesheetTotal.customer_total_net = timesheetTotal.customer_total;

    this.props.refunds.forEach(refund => {
      timesheetTotal.carer_total_net += refund.totals.carer_total;
      timesheetTotal.application_total_net += refund.totals.application_total;
      timesheetTotal.customer_total_net -= refund.totals.customer_total;
    });

    return timesheetTotal;
  };

  render() {
    const { taxonomiesByClassification } = this.props;
    const timesheetTotal = this.timesheetTotal();

    if (!taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    return (
      <TimesheetTotal
        view={this.props.view}
        timesheet={this.props.timesheet}
        totals={this.props.totals}
        refunds={this.props.refunds}
        timesheetTotal={timesheetTotal}
        isAdmin={this.props.isAdmin}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

TimesheetTotalContainer.propTypes = {
  // one of [carer|customer] which fields to display
  view: PropTypes.string.isRequired,
  timesheet: PropTypes.shape({
    expenses: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        expense_type: PropTypes.string,
        miles: PropTypes.number,
      }),
    ),
  }).isRequired,
  totals: PropTypes.shape({
    insurance_payment: PropTypes.number.isRequired,
    shifts: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        total: PropTypes.shape({
          carer_total: PropTypes.number,
          customer_total: PropTypes.number,
          minutes_total: PropTypes.number,
        }),
      }),
    ),
    total: PropTypes.shape({
      carer_total: PropTypes.number,
      customer_total: PropTypes.number,
      minutes_total: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  refunds: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string.isRequired,
      description: PropTypes.string,
      totals: PropTypes.shape({
        carer_total: PropTypes.number,
        customer_total: PropTypes.number,
        application_total: PropTypes.number,
      }),
    }),
  ).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  taxonomiesByClassification: PropTypes.shape(),
};

TimesheetTotalContainer.defaultProps = {
  taxonomiesByClassification: null,
};

TimesheetTotalContainer.TAXONOMY_CLASSIFICATIONS = ['shift_type'];

function mapStateToProps(state) {
  return {
    isAdmin: isAdmin(state),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      TimesheetTotalContainer.TAXONOMY_CLASSIFICATIONS,
    ),
  };
}

export default connect(mapStateToProps)(TimesheetTotalContainer);
