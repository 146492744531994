/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toastr } from 'react-redux-toastr';

import { Container, Row, Col } from 'components/grid';
import { BorderedHeader } from 'components/global/layout/Module';
import StyledInput from 'components/forms/StyledInput';
import Button from 'components/buttons/Button';

const CopyInput = styled(StyledInput)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  @media (min-width: 768px) {
    width: 80%;
  }
  &[readonly] {
    background-color: ${props => props.theme.colors.lightestGrey};
  }
`;

const CopyButton = styled.button`
  background: white;
  border: 0px;
  color: ${props => props.theme.colors.statusBlue};
  font-size: 18px;
  margin: 10px 0;
  text-align: right;
  width: 100%;

  @media (min-width: 768px) {
    background-color: ${props => props.theme.colors.lightestGrey};
    border: 1px solid;
    border-color: ${props => props.theme.colors.uiGrey};
    border-radius: 6px;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: inline-block;
    margin: 0 0 20px;
    padding: 16px 10px;
    text-align: center;
    width: 20%;
  }

  &:hover {
    cursor: pointer;
  }
`;

class CarerShareDialog extends Component {
  static propTypes = {
    carer: PropTypes.shape().isRequired,
    closePortal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  copyLink = e => {
    this.inputRef.current.focus();
    this.inputRef.current.select();
    try {
      document.execCommand('copy');
      toastr.success('Link copied to clipboard');
    } catch (e) {
      toastr.error('Unable to copy link');
    }
  };

  render() {
    const { carer, closePortal } = this.props;
    if (!window) {
      return null;
    }
    const link = `${window.location.origin}/carers/${carer.slug}/`;
    return (
      <>
        <BorderedHeader>Get a shareable link</BorderedHeader>
        <Container>
          <Row>
            <Col>
              <CopyInput
                readOnly
                value={link}
                innerRef={this.inputRef} // innerRef as it's a styled-component
              />
              <CopyButton onClick={this.copyLink} type="button">
                Copy link
              </CopyButton>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button onClick={closePortal}>Done</Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CarerShareDialog;
