import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ChargeResults from 'components/admin/ChargeResults';
import ChargeSearchFilters from 'components/admin/ChargeSearchFilters';

class AdminChargeSearch extends Component {
  render() {
    let results;

    if (this.props.charges.length) {
      results = (
        <ChargeResults
          search={this.props.search}
          charges={this.props.charges}
          location={this.props.location}
        />
      );
    } else {
      results = (
        <div className="old-row">
          <div className="module">
            <h2>No charges found.</h2>
          </div>
        </div>
      );
    }

    return (
      <div>
        <ChargeSearchFilters
          filters={this.props.filters}
          location={this.props.location}
          updateFilters={this.props.updateFilters}
        />
        {results}
      </div>
    );
  }
}

AdminChargeSearch.propTypes = {
  search: PropTypes.shape(),
  charges: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  location: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  updateFilters: PropTypes.func.isRequired,
};

AdminChargeSearch.defaultProps = {
  search: {},
};

export default AdminChargeSearch;
