// @flow
import React from 'react';
import { Container, Col } from 'react-grid-system';

import FormRow from 'components/forms/FormRow';
import FormTemplate from 'components/forms/FormTemplate';
import Form from 'components/forms/Form';

import Button from 'components/buttons/Button';

import fieldsConfig from 'components/franchise/FranchiseFormFields';

import type { FranchiseType, TaxonomiesClassificationType } from 'shared/types';

type Props = {
  franchise: FranchiseType,
  isAdmin: boolean,
  taxonomiesByClassification: TaxonomiesClassificationType,
  updateFranchise: Object => Object,
};

const FranchiseForm = ({
  franchise,
  isAdmin,
  taxonomiesByClassification,
  updateFranchise,
}: Props) => (
  <Form
    name="franchiseForm"
    fields={[
      'name',
      'phone_number',
      'country',
      'county',
      'postcode',
      'town',
      'account_manager',
      'state',
    ]}
    values={franchise}
    onSubmit={updateFranchise}
    component={({ handleSubmit, pristine }) => (
      <form onSubmit={handleSubmit}>
        <FormTemplate
          extraDisplayCriteria={{ isAdmin: isAdmin }}
          fieldsConfig={fieldsConfig}
          taxonomiesByClassification={taxonomiesByClassification}
        />
        <Container>
          <FormRow>
            <Col xs={12} md={6}>
              <Button disabled={pristine} type="submit">
                Save Changes
              </Button>
            </Col>
          </FormRow>
        </Container>
      </form>
    )}
  />
);

export default FranchiseForm;
