// @flow
import React, { Component } from 'react';
import store from 'store'

import { getUser } from 'shared/selectors/accountSelectors';
import { hasRole } from 'shared/helpers/accountHelpers';

import AdminBidRow from 'components/bids/AdminBidRow';

import type {
  BidType,
  JobOfferType,
  ScheduleType,
  TaxonomyType,
} from 'shared/types';

type Props = {|
  addCarerToTeam: (string, BidType) => void,
  bid: BidType,
  carerID: string,
  jobOffer: JobOfferType,
  proposeCarerBid: BidType => void,
  rejectionReasons: TaxonomyType,
  schedule: ScheduleType,
  updateBid: (string, Object) => Object,
  notifyBid: string => Object,
|};

class AdminBidRowContainer extends Component<Props> {
  static defaultProps = {
    carer: null,
  };

  static get TAXONOMY_CLASSIFICATIONS(): Array<string> {
    return ['bid_admin_rejection'];
  }

  render() {
    const {
      addCarerToTeam,
      bid,
      rejectionReasons,
      jobOffer,
      notifyBid,
      proposeCarerBid,
      schedule,
      updateBid,
    } = this.props;

    const isAdmin = hasRole(getUser(store.getState()), 'Admin');

    return (
      <AdminBidRow
        addCarerToTeam={addCarerToTeam}
        bid={bid}
        jobOffer={jobOffer}
        key={bid.id}
        proposeCarerBid={proposeCarerBid}
        rejectionReasons={rejectionReasons}
        schedule={schedule}
        updateBid={updateBid}
        notifyBid={notifyBid}
        isAdmin={isAdmin}
      />
    );
  }
}

export default AdminBidRowContainer;
