import { openPrimaryMenu, closePrimaryMenu } from '_actions/uiActions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from 'react-router-redux-dom-link';
import { bindActionCreators } from 'redux';

import { getPrimaryMenuOpen } from 'shared/selectors/uiSelectors';
import styled, { withTheme } from 'styled-components';
import Logo from 'components/global/Logo';
import PrimaryNavigation from 'components/global/primarynavigation/PrimaryNavigation';

const StyledHeader = styled.header`
  background: ${props => props.theme.colors.white};
  color: white;
  height: 54px;
  padding: 15px 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: ${props =>
    props.primaryMenuOpen
      ? props.theme.zIndexes.nav
      : props.theme.zIndexes.title};

  > h1 {
    display: none;
  }

  @media (min-width: 768px) {
    height: auto;
    position: fixed;
    bottom: 0;
    width: 25%;

    > h1 {
      display: block;
    }
  }

  @media (min-width: 992px) {
    width: 16.6667%;
  }
`;

const StyledHomeLink = styled(Link)`
  margin: 0 25px;
  display: block;

  @media (max-width: 767px) {
    display: inline-block;
    width: 140px;
    margin: -6px 10px 0;
  }

  @media (max-width: 339px) {
    margin: -6px 5px 0;
  }
`;

class AppHeader extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    isAgent: PropTypes.bool.isRequired,
    location: PropTypes.shape().isRequired,
    primaryMenuOpen: PropTypes.bool.isRequired,
    openPrimaryMenu: PropTypes.func.isRequired,
    closePrimaryMenu: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: null,
  };

  render() {
    const { user, isAgent, location, primaryMenuOpen } = this.props;

    return (
      <StyledHeader primaryMenuOpen={primaryMenuOpen}>
        <StyledHomeLink to={isAgent ? '/admin/needs/new/' : '/home/'}>
          <Logo width={'100%'} />
        </StyledHomeLink>
        <PrimaryNavigation
          user={user}
          location={location}
          openPrimaryMenu={this.props.openPrimaryMenu}
          closePrimaryMenu={this.props.closePrimaryMenu}
          primaryMenuOpen={primaryMenuOpen}
        />
      </StyledHeader>
    );
  }
}

const mapStateToProps = state => ({
  primaryMenuOpen: getPrimaryMenuOpen(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openPrimaryMenu,
      closePrimaryMenu,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(AppHeader));
