import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateWorkHistoryInstance, getWorkHistoryInstanceByID } from '_actions/workHistoryActions';
import { selectWorkHistoryInstanceByID } from 'shared/selectors/workHistorySelectors';
import { isAdmin, isRecruiter } from 'shared/selectors/accountSelectors';

import CarerWorkHistoryEdit from 'components/carers/CarerWorkHistoryEdit';

class CarerWorkHistoryEditContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      updateWorkHistoryInstance: PropTypes.func.isRequired,
      getWorkHistoryInstanceByID: PropTypes.func.isRequired,
    }).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isRecruiter: PropTypes.bool.isRequired,
    workHistory: PropTypes.shape({
      user_id: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        workHistoryInstanceID: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    workHistory: null,
  };

  UNSAFE_componentWillMount() {
    this.props.actions.getWorkHistoryInstanceByID(this.props.match.params.workHistoryInstanceID);
  }

  updateWorkHistoryInstance = data => {
    const redirect =
      this.props.isAdmin || this.props.isRecruiter
        ? `/admin/users/${this.props.workHistory.user_id}/#work-history`
        : '/work-history/summary/';

    const updatedData = { ...data };

    if (!updatedData.employment_end_date) {
      updatedData.employment_end_date = null;
    }

    this.props.actions.updateWorkHistoryInstance(
      this.props.workHistory.id,
      updatedData,
      redirect,
    );
  };

  render() {
    const { workHistory } = this.props;

    const initialValues = {
      current_employer: workHistory && !workHistory.employment_end_date,
      ...workHistory,
    };

    return (
      <CarerWorkHistoryEdit
        onSubmit={this.updateWorkHistoryInstance}
        initialValues={initialValues}
        ready={!!workHistory}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  workHistory: selectWorkHistoryInstanceByID(state, props.match.params.workHistoryInstanceID),
  isAdmin: isAdmin(state),
  isRecruiter: isRecruiter(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateWorkHistoryInstance,
      getWorkHistoryInstanceByID,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerWorkHistoryEditContainer);
