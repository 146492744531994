import styled from 'styled-components';

export default styled.ul`
  list-style: none;

  > li:before {
    content: '> ';
    color: ${props => props.theme.colors.hiAccentBlue};
    font-size: 18px;
    position: relative;
    top: 0px;
    margin-right: 35px;
    left: 30px;
  }
`;
