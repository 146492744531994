import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import TeamResults from 'components/admin/TeamResults';
import TeamSearchFilters from 'components/admin/TeamSearchFilters';

class AdminTeamSearch extends Component {
  render() {
    let results;

    if (!this.props.teams) {
      results = <LoadingPlaceholder />;
    } else if (this.props.teams.length) {
      results = (
        <TeamResults
          search={this.props.search}
          teams={this.props.teams}
          location={this.props.location}
        />
      );
    } else {
      results = <div className="no-results">No teams found.</div>;
    }

    return (
      <div>
        <TeamSearchFilters
          filters={this.props.filters}
          location={this.props.location}
          updateFilters={this.props.updateFilters}
        />
        <div className="old-row">
          <div className="module">
            <h2>Teams</h2>
            {results}
          </div>
        </div>
      </div>
    );
  }
}

AdminTeamSearch.propTypes = {
  location: PropTypes.shape().isRequired,
  search: PropTypes.shape(),
  teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filters: PropTypes.shape().isRequired,
  updateFilters: PropTypes.func.isRequired,
};

AdminTeamSearch.defaultProps = {
  search: null,
};

export default AdminTeamSearch;
