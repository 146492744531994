import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import AdminHome from 'components/admin/AdminHome';

import { isFranchiseMember, isRecruiter } from 'shared/selectors/accountSelectors';

class AdminHomeContainer extends Component {
  static propTypes = {
    isFranchiseMember: PropTypes.bool.isRequired,
    isRecruiter: PropTypes.bool.isRequired,
  };

  render() {

    if (this.props.isFranchiseMember) {
      return <Redirect to="/admin/needs/" />;
    }

    return <AdminHome isRecruiter={this.props.isRecruiter} />;
  }
}

const mapStateToProps = (state, props) => ({
  isFranchiseMember: isFranchiseMember(state),
  isRecruiter: isRecruiter(state),
});

export default connect(mapStateToProps)(AdminHomeContainer);
