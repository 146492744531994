import _ from 'lodash';

export const bidStates = {
  new: 'New',
  applied: 'Applied',
  contacted: 'Contacted',
  proposed: 'Proposed',
  accepted: 'Accepted',
  carer_withdrawn: 'Carer withdrawn',
  carer_declined: 'Carer declined',
  admin_rejected: 'Admin rejected',
  customer_rejected: 'Franchise rejected',
};

export const checkForConflicts = (bid, bidConflicts) => {
  if (!bidConflicts || !bid) {
    return null;
  }
  const bids = _.flatten(Object.values(bidConflicts));
  return bids.find(conflictingBid => conflictingBid !== bid.id);
};

export const activeBid = bidState => {
  return ['accepted', 'applied', 'new', 'proposed', 'contacted'].includes(bidState);
};

export const activeNotNewBid = bidState => {
  return ['accepted', 'applied', 'proposed', 'contacted'].includes(bidState);
};

export const proposableBid = bidState => {
  return ['contacted', 'applied', 'new'].includes(bidState);
};

export const bidPending = bidState => {
  return ['applied', 'contacted', 'proposed'].includes(bidState);
};

export const carerApplied = bidState => {
  return ['accepted', 'applied', 'proposed', 'customer_rejected'].includes(
    bidState,
  );
};

export const isBidRejected = bidState => {
  return [
    'customer_rejected',
    'carer_declined',
    'carer_withdrawn',
    'admin_rejected',
  ].includes(bidState);
};

export const selectBidStates = (stateArray = [], exclude = false) => {
  if (!stateArray.length) {
    return bidStates;
  }
  if (exclude) {
    return Object.entries(bidStates).reduce((acc, [stateKey, stateValue]) => {
      if (!stateArray.includes(stateKey)) {
        acc[stateKey] = stateValue;
      }
      return acc;
    }, {});
  } else {
    return Object.entries(bidStates).reduce((acc, [stateKey, stateValue]) => {
      if (stateArray.includes(stateKey)) {
        acc[stateKey] = stateValue;
      }
      return acc;
    }, {});
  }
};
