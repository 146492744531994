import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AdminNeedsOverview from 'components/admin/AdminNeedsOverview';

class AdminNeedsOverviewContainer extends Component {
  render() {
    return <AdminNeedsOverview location={this.props.location} />;
  }
}

AdminNeedsOverviewContainer.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default AdminNeedsOverviewContainer;
