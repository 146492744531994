import styled from 'styled-components';

const List = styled.div`
  & > * {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid ${props => props.theme.colors.uiGrey};

    &:last-child {
      border-bottom: 1px solid ${props => props.theme.colors.uiGrey};
    }
  }
`;

export default List;
