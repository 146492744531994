import PropTypes from 'prop-types';
import React, { Component } from 'react';

import InputWrapper from 'components/forms/InputWrapper';
import StyledLabel from 'components/forms/StyledLabel';
import MultiSelect from 'components/forms/MultiSelect';

class StandardMultiSelect extends Component {
  static propTypes = {
    customOnChange: PropTypes.func,
    help: PropTypes.node,
    input: PropTypes.shape().isRequired,
    label: PropTypes.string,
    options: PropTypes.shape().isRequired,
    meta: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    customOnChange: null,
    help: null,
    label: '',
  };

  render() {
    const { help, input, label, options, meta, customOnChange } = this.props;
    const { touched, error } = meta;

    return (
      <InputWrapper {...{ help, meta }}>
        {label && (
          <StyledLabel
            error={touched && error}
            htmlFor={`select-${input.name}`}
          >
            {label}
          </StyledLabel>
        )}
        <MultiSelect
          className={touched && error ? 'error' : ''}
          options={options}
          customOnChange={customOnChange}
          {...input}
        />
      </InputWrapper>
    );
  }
}

export default StandardMultiSelect;
