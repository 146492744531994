import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Col, Row } from 'components/grid';

import { SC_NAME } from 'components/global/ContactInfo';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import Button from 'components/buttons/Button';

class AccountTerms extends Component {
  state = {
    agreed: false,
  };

  toggleAgree = e => {
    this.setState({
      agreed: !!e.currentTarget.checked,
    });
  };

  render() {
    let title;

    if (this.props.user && this.props.user.confirmed_tos_at) {
      title = 'Our Terms & Privacy Policy Have Changed';
    } else {
      title = 'Please Accept Our Terms & Privacy Policy';
    }

    return (
      <section>
        <Module>
          <ModuleHeader>{title}</ModuleHeader>
          <Container>
            <Row>
              <Col>
                <p>
                  Before you can use our platform, you will need to accept the
                  Terms of use, found here:
                </p>
                <p>
                  <a
                    href="/terms-and-conditions"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms
                  </a>{' '}
                  &amp;{' '}
                  <a
                    href="/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </p>
                <p>
                  All Home Instead live-in care job opportunities are subject
                  to the above terms and conditions.
                  By applying for any Home Instead live-in care assignment,
                  you accept these terms and conditions.
                </p>
              </Col>
            </Row>
            {this.props.user.roles &&
              this.props.user.roles.includes('Customer') && (
                <Row>
                  <Col>
                    <p>To summarise some key points:</p>
                    <p>
                      {SC_NAME} is an introductory agency as defined by the
                      Care Quality Commission, the UK healthcare regulator. This
                      means that we do not direct or manage the care services on
                      an on-going basis; we simply make the introductions to
                      vetted carers and provide the tools you need to manage the
                      care with them directly.
                    </p>
                    <p>
                      You have full choice over which carers you would like to
                      work with. The resulting contract(s) for care services
                      will be directly between you and the carer(s) you select.
                    </p>
                    <p>
                      Payment must made through our secure payment platform,
                      which is provided by Stripe Payments Europe Limited. For
                      this you will need to register a debit or credit card.
                      Because the money is paid directly to your carers and not
                      to us, we are unable to take payment via bank transfers or
                      cheques.
                    </p>
                    <p>
                      You will receive invoice summaries every week for the week
                      just gone, which include both the invoice(s) from your
                      carer(s) and our invoice for commission. It is vital that
                      you review all invoices and query any which you do not
                      agree with within 24 hours, at which point payment will be
                      automatically taken. If you wish to raise a dispute after
                      this point, it will need to be processed as a refund -
                      which is possible, but may take some time to clear through
                      the payment system.
                    </p>
                  </Col>
                </Row>
              )}
            <Row style={{ marginTop: '20px' }}>
              <Col>
                <p>
                  <input
                    onChange={this.toggleAgree}
                    type="checkbox"
                    id="agree"
                  />{' '}
                  <label htmlFor="agree">
                    I agree to the terms and conditions
                  </label>
                </p>
                <br/>
                <Button
                  disabled={!this.state.agreed}
                  onClick={this.props.confirmTerms}
                >
                  Accept
                </Button>
              </Col>
            </Row>
          </Container>
        </Module>
      </section>
    );
  }
}

AccountTerms.propTypes = {
  user: PropTypes.shape(),
  confirmTerms: PropTypes.func.isRequired,
};

AccountTerms.defaultProps = {
  user: null,
};

export default AccountTerms;
