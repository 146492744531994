import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getNeedsByCarerId } from '_actions/needActions';
import { searchTimesheets } from '_actions/timesheetActions';
import CarerTimesheetsOverview from 'components/timesheets/CarerTimesheetsOverview';

import { getUser } from 'shared/selectors/accountSelectors';
import { getNeeds } from 'shared/selectors/needSelectors';

import {
  getTimesheetSearch,
  getTimesheetResults,
} from 'shared/selectors/timesheetSelectors';

class CarerTimesheetsOverviewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: props.location.query.page || 1,
    };
  }

  componentDidMount() {
    this.getTimesheets();
    this.props.getNeedsByCarerId(this.props.user.id);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newPage = props.location.query.page;
    if (newPage && newPage !== this.state.current_page) {
      this.setState({ current_page: newPage }, this.getTimesheets);
    }
  }

  getTimesheets = () => {
    this.props.searchTimesheets(
      { carer_id: this.props.user.id, per_page: 5, order_by: 'desc' },
      this.state.current_page,
    );
  };

  render() {
    return (
      <CarerTimesheetsOverview
        location={this.props.location}
        search={this.props.search}
        timesheets={this.props.timesheets}
        needs={this.props.needs}
        currentPage={this.state.current_page}
        resultsPerPage={5}
        user={this.props.user}
      />
    );
  }
}

CarerTimesheetsOverviewContainer.propTypes = {
  getNeedsByCarerId: PropTypes.func.isRequired,
  timesheets: PropTypes.arrayOf(PropTypes.shape()),
  search: PropTypes.shape(),
  needs: PropTypes.shape(),
  location: PropTypes.shape({
    query: PropTypes.shape({
      page: PropTypes.number,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape(),
};

CarerTimesheetsOverviewContainer.defaultProps = {
  search: null,
  timesheets: null,
  user: null,
  needs: null,
};

const mapStateToProps = state => ({
  needs: getNeeds(state), // TODO: This should be more specific but not sure how yet
  user: getUser(state),
  timesheets: getTimesheetResults(state),
  search: getTimesheetSearch(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getNeedsByCarerId, searchTimesheets }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerTimesheetsOverviewContainer);
