import styled from 'styled-components';

export const ThickUnderline = styled.div`
  width: ${({ width = 50 }) => width}px;
  margin: 8px ${({ centered }) => (centered ? 'auto' : '0')} 15px;
  border: ${props =>
      props.themeColour
        ? props.theme.colors[props.themeColour]
        : props.theme.colors.hiCream}
    solid 3px;
  border-radius: 5px;
`;
