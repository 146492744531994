// @flow
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLink = styled.a`
  display: inline-block;

  @media (min-width: ${props => props.theme.screen.medium}px) {
    position: absolute;
    bottom: 8px;
  }

  > span {
    display: inline-block;
    text-decoration: underline;
  }
`;

const PREFIX = `https://www.fountain.com/supercarers/applicants`;

type Props = {
  id: string,
};

const FountainButton = ({ id }: Props) => {
  let href = `${PREFIX}/${id}/`;
  return (
    <StyledLink href={href} target="_blank">
      <span>View on Fountain</span>
    </StyledLink>
  );
};

FountainButton.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FountainButton;
