import React from 'react';

import JobOfferPanel from 'components/admin/needs/jobs/jobofferpanel/JobOfferPanel';
import StandardList from 'components/global/layout/StandardList';
import { Container } from 'components/grid';

//TODO rename?
const AdminNeedJobOffers = ({ need, jobOffers, schedules, updateJobOffer }) => (
  <Container outerGutter={0}>
    <StandardList name="Jobs" default={jobOffers}>
      {jobOffers.map(jobOffer => (
        <JobOfferPanel
          key={jobOffer.id}
          jobOffer={jobOffer}
          needID={need.id}
          updateJobOffer={updateJobOffer}
          schedule={schedules.find(
            schedule => schedule.id === jobOffer.schedule_id,
          )}
        />
      ))}
    </StandardList>
  </Container>
);

export default AdminNeedJobOffers;
