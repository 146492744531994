import keyMirror from 'keymirror';

const CareTeamConstants = keyMirror({
  CARETEAM_CREATE: null,
  CARETEAM_CREATE_SUCCESS: null,
  CARETEAM_CREATE_FAILURE: null,

  CARETEAM_UPDATE: null,
  CARETEAM_UPDATE_SUCCESS: null,
  CARETEAM_UPDATE_FAILURE: null,

  CARETEAM_GET_BY_NEED: null,
  CARETEAM_GET_BY_NEED_SUCCESS: null,
  CARETEAM_GET_BY_NEED_FAILURE: null,

  CARETEAM_GET_BY_NEED_WITH_CARERS: null,
  CARETEAM_GET_BY_NEED_WITH_CARERS_SUCCESS: null,
  CARETEAM_GET_BY_NEED_WITH_CARERS_FAILURE: null,

  CARETEAM_GET_TEAMS_BY_CARER: null,
  CARETEAM_GET_TEAMS_BY_CARER_SUCCESS: null,
  CARETEAM_GET_TEAMS_BY_CARER_FAILURE: null,

  CARETEAM_GET_TEAMS_BY_OWNER: null,
  CARETEAM_GET_TEAMS_BY_OWNER_SUCCESS: null,
  CARETEAM_GET_TEAMS_BY_OWNER_FAILURE: null,

  CARETEAM_SEARCH: null,
  CARETEAM_SEARCH_SUCCESS: null,
  CARETEAM_SEARCH_FAILURE: null,

  NOTIFY_EMAIL: null,
  NOTIFY_EMAIL_SUCCESS: null,
  NOTIFY_EMAIL_FAILURE: null,

  UPDATE_TEAM_MEMBER_STATE: null,
  UPDATE_TEAM_MEMBER_STATE_SUCCESS: null,
  UPDATE_TEAM_MEMBER_STATE_FAILURE: null,

  CARETEAM_CREATE_AND_UPDATE_MEMBER: null,
  CARETEAM_CREATE_AND_UPDATE_MEMBER_SUCCESS: null,
  CARETEAM_CREATE_AND_UPDATE_MEMBER_FAILURE: null,

  CARETEAM_AND_MEMBER_UPDATE: null,
  CARETEAM_AND_MEMBER_UPDATE_SUCCESS: null,
  CARETEAM_AND_MEMBER_UPDATE_FAILURE: null,

  CARETEAM_REMOVE_MEMBER: null,
  CARETEAM_REMOVE_MEMBER_SUCCESS: null,
  CARETEAM_REMOVE_MEMBER_FAILURE: null,

  SEND_CARER_CONFIRMATION: null,
  SEND_CARER_CONFIRMATION_SUCCESS: null,
  SEND_CARER_CONFIRMATION_FAILURE: null,
});

export default CareTeamConstants;
