import keyMirror from 'keymirror';

const DiscountConstants = keyMirror({
  DISCOUNT_CREATE: null,
  DISCOUNT_CREATE_SUCCESS: null,
  DISCOUNT_CREATE_FAILURE: null,

  DISCOUNT_GET_BY_ID: null,
  DISCOUNT_GET_BY_ID_SUCCESS: null,
  DISCOUNT_GET_BY_ID_FAILURE: null,

  DISCOUNT_UPDATE: null,
  DISCOUNT_UPDATE_SUCCESS: null,
  DISCOUNT_UPDATE_FAILURE: null,

  DISCOUNT_SEARCH: null,
  DISCOUNT_SEARCH_SUCCESS: null,
  DISCOUNT_SEARCH_FAILURE: null,

  DISCOUNT_ASSIGN: null,
  DISCOUNT_ASSIGN_SUCCESS: null,
  DISCOUNT_ASSIGN_FAILURE: null,

  DISCOUNT_UNASSIGN: null,
  DISCOUNT_UNASSIGN_SUCCESS: null,
  DISCOUNT_UNASSIGN_FAILURE: null,
});

export default DiscountConstants;
