import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StyledTabs from 'components/global/layout/StyledTabs';
import Link from 'react-router-redux-dom-link';
import { withRouter } from 'react-router';

class TabbedComponents extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    let tab;
    if (props.location && props.location.hash !== '') {
      tab = props.location.hash.slice(1);
    } else {
      const initial = React.Children.toArray(props.children).find(child =>
        child ? child.props.default : null,
      );
      if (initial) {
        tab = this.niceHash(initial.props.name);
      }
    }
    this.state = {
      selected: tab,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let selected;

    if (
      nextProps.location &&
      nextProps.location.hash !== '' &&
      nextProps.location.hash.slice(1) !== this.state.selected
    ) {
      this.setState({
        selected: window.location.hash.slice(1),
      });
      return true;
    }

    React.Children.forEach(nextProps.children, child => {
      if (!child) {
        return false;
      }
      if (this.niceHash(child.props.name) === this.state.selected) {
        selected = this.niceHash(child.props.name);
      }
      return true;
    });
    if (!selected) {
      const initial = React.Children.toArray(nextProps.children).find(
        child => child.props.default,
      );
      if (initial) {
        this.setState({
          selected: this.niceHash(initial.props.name),
        });
      }
    }
    return false;
  }

  niceHash = hash => hash.toLowerCase().replace(/ /g, '-');

  render() {
    const { children, location } = this.props;
    return (
      <div>
        <StyledTabs>
          {React.Children.toArray(children).map(child =>
            child && !child.props.noLabel ? (
              <Link
                className={`
                    ${
                      this.niceHash(child.props.name) === this.state.selected
                        ? 'active'
                        : ''
                    }
                    ${child.props.className ? child.props.className : ''}
                  `}
                key={child.props.name}
                to={{
                  pathname: location ? location.pathname : '',
                  hash: this.niceHash(child.props.name),
                }}
              >
                {child.props.label ? (
                  <child.props.label
                    active={
                      this.niceHash(child.props.name) === this.state.selected
                    }
                  />
                ) : (
                  child.props.name
                )}
              </Link>
            ) : null,
          )}
        </StyledTabs>
        {React.Children.toArray(children).map(child =>
          child
            ? this.niceHash(child.props.name) === this.state.selected
              ? child
              : null
            : null,
        )}
      </div>
    );
  }
}

export default withRouter(TabbedComponents);
