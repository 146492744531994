import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { magicTokenErrorSelector } from 'shared/selectors/errorSelectors';

import { magicTokenLogin } from '_actions/accountActions';

import MagicTokenLogin from 'components/account/MagicTokenLogin';

class MagicTokenLoginContainer extends React.Component {
  constructor(props) {
    super(props);
    props.magicTokenLogin(props.match.params.magic_token);
  }

  render() {
    const { magicTokenError } = this.props;
    return <MagicTokenLogin magicTokenError={magicTokenError} />;
  }
}

const mapStateToProps = state => ({
  magicTokenError: magicTokenErrorSelector(state),
});

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators({ magicTokenLogin }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MagicTokenLoginContainer);
