// NOTE
// Remaining actions to be ported during app rebuild
// Nothing more to be done here for now
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { getUser } from 'shared/selectors/accountSelectors';
import store from 'store';

import TimesheetConstants from 'shared/constants/TimesheetConstants';
import SuperCarersClient from 'clients/SuperCarersClient';

const Bluebird = require('bluebird');
const rp = require('request-promise');

const TimesheetActions = {
  // TODO: Remains because of chaining elsewhere, needs sagas
  getTimesheetById(timesheetID) {
    store.dispatch({
      type: TimesheetConstants.TIMESHEET_GET_BY_ID,
    });

    return SuperCarersClient.get(`/timesheet/${timesheetID}/`)
      .then(body => {
        const data = body.data;

        store.dispatch({
          type: TimesheetConstants.TIMESHEET_GET_BY_ID_SUCCESS,
          data,
        });

        return body;
      })
      .catch(error => {
        store.dispatch({
          type: TimesheetConstants.TIMESHEET_GET_BY_ID_FAILURE,
          error: SuperCarersClient.errorDescription(error),
        });
      });
  },

  // TODO: Create saga for post submission logic
  update(timesheetID, data, withConfirmation = false) {
    store.dispatch({
      type: TimesheetConstants.TIMESHEET_UPDATE,
    });

    return SuperCarersClient.put(`/timesheet/${timesheetID}/`, data)
      .then(body => {
        if (withConfirmation) {
          store.dispatch(push(`/timesheets/${timesheetID}/confirm/`));
        } else if (data.state && data.state === 'submitted') {
          toastr.success('Timesheet submitted');
        } else {
          toastr.success('Timesheet saved');
        }
        store.dispatch({
          type: TimesheetConstants.TIMESHEET_UPDATE_SUCCESS,
          data: body.data,
        });
        return body;
      })
      .catch(() => {
        store.dispatch({
          type: TimesheetConstants.TIMESHEET_UPDATE_FAILURE,
          error: 'Unknown Error',
          dataSent: data,
        });

        toastr.error('Unable to save Timesheet.');
      });
  },

  reassignTimesheet(timesheetId) {
    store.dispatch({
      type: TimesheetConstants.TIMESHEET_REASSIGN,
    });

    return SuperCarersClient.put(`/timesheet/${timesheetId}/reassign/`)
      .then(body => {
        store.dispatch({
          type: TimesheetConstants.TIMESHEET_REASSIGN_SUCCESS,
          data: body.data,
        });
        store.dispatch(push(`/invoices/${body.data.invoice.id}/`));
        toastr.success('Successfully reassigned Timesheet.');
        return body;
      })
      .catch(() => {
        store.dispatch({
          type: TimesheetConstants.TIMESHEET_REASSIGN_FAILURE,
          error: 'Unknown Error',
        });

        toastr.error('Unable to reassign Timesheet.');
      });
  },

  // TODO: Looks like entire amends flow should be a saga
  getTimesheetAmendsByNeedId(needID) {
    const user = getUser(store.getState());
    const uri = `${
      window.global.SUPERCARERS_API_URL
    }/amend/timesheet/find/?per_page=10000&need_id=${needID}`;

    const options = {
      uri,
      method: 'GET',
      json: true,
      simple: false,
      resolveWithFullResponse: true,
      headers: {
        Authorization: `JWT ${user.access_token}`,
      },
    };

    rp(options)
      .then(response => {
        switch (response.statusCode) {
          case 200:
            store.dispatch({
              type: TimesheetConstants.TIMESHEET_GET_AMENDS_BY_NEED_ID_SUCCESS,
              data: response.body.data,
            });

            break;

          default:
            store.dispatch({
              type: TimesheetConstants.TIMESHEET_GET_AMENDS_BY_NEED_ID_FAILURE,
              error: response.body.description || 'Unknown Error',
            });

            break;
        }

        return null;
      })
      .catch(() => {
        store.dispatch({
          type: TimesheetConstants.TIMESHEET_GET_AMENDS_BY_NEED_ID_FAILURE,
          error: 'Unknown Error',
        });
      });

    store.dispatch({
      type: TimesheetConstants.TIMESHEET_GET_AMENDS_BY_NEED_ID,
    });
  },

  // TODO: Create use case saga
  createAmends(data) {
    const user = getUser(store.getState());
    const uri = `${window.global.SUPERCARERS_API_URL}/amend/timesheet/`;

    const options = {
      uri,
      method: 'POST',
      json: true,
      simple: false,
      resolveWithFullResponse: true,
      headers: {
        Authorization: `JWT ${user.access_token}`,
      },
    };

    const promises = data.map(amend => {
      options.body = amend;
      return rp(options);
    });

    Bluebird.all(promises).then(responses => {
      let failCount = 0;

      responses.forEach(response => {
        if (!response.body.success) {
          failCount += 1;
        }
      });

      if (!failCount) {
        store.dispatch({
          type: TimesheetConstants.TIMESHEET_CREATE_AMENDS_SUCCESS,
          data: responses,
        });
        toastr.success('Amends saved');
      } else {
        store.dispatch({
          type: TimesheetConstants.TIMESHEET_CREATE_AMENDS_FAILURE,
          error: 'Unknown Error',
          dataSent: data,
        });
        toastr.error(
          `${failCount} amend(s) of ${
            responses.length
          } failed to save. Please check and try again.`,
        );
      }
    });

    store.dispatch({
      type: TimesheetConstants.TIMESHEET_CREATE_AMENDS,
    });
  },

  // TODO: Create use case saga
  deleteAmends(data) {
    const user = getUser(store.getState());
    const baseURI = `${window.global.SUPERCARERS_API_URL}/amend/timesheet/`;

    const options = {
      method: 'DELETE',
      json: true,
      simple: false,
      resolveWithFullResponse: true,
      headers: {
        Authorization: `JWT ${user.access_token}`,
      },
    };

    const promises = data.map(amend => {
      options.uri = `${baseURI + amend.date}/${amend.need_id}/${
        amend.carer_id
      }/`;
      return rp(options);
    });

    Bluebird.all(promises)
      .then(responses => {
        store.dispatch({
          type: TimesheetConstants.TIMESHEET_DELETE_AMENDS_SUCCESS,
          data: responses,
        });

        toastr.success('Amends deleted');

        return null;
      })
      .catch(() => {
        store.dispatch({
          type: TimesheetConstants.TIMESHEET_DELETE_AMENDS_FAILURE,
          error: 'Unknown Error',
        });
      });

    store.dispatch({
      type: TimesheetConstants.TIMESHEET_DELETE_AMENDS,
    });
  },
};

export default TimesheetActions;
