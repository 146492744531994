import React from 'react';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import StatusIndicator from 'components/global/StatusIndicator';

import stateHelper from 'components/helpers/stateHelper';

const StyledStatusViewButton = styled.div`
  width: 35%;
  min-width: 235px;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const JobStatusView = ({ jobOffer, needID }) => (
  <StyledStatusViewButton>
    <StatusIndicator status={stateHelper.bidDisplayState(jobOffer)} />
    <Link
      className="btn"
      to={`/admin/needs/${needID}/?jobID=${jobOffer.id}#job-details`}
    >
      View job
    </Link>
  </StyledStatusViewButton>
);

export default JobStatusView;
