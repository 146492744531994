import { createSelector } from 'reselect';

export const getFranchises = state => state.franchises;
export const getFranchiseByID = (state, id) => state.franchises[id];
export const getFranchiseSearch = state => state.search.franchises;

export const getFranchiseResults = createSelector(
  [getFranchises, getFranchiseSearch],
  (franchises, search) => {
    if (!search || !search.searchOrder) {
      return [];
    }
    return search.searchOrder.map(id => franchises[id]);
  },
);
