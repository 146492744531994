import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';
import toTitleCase from 'components/helpers/toTitleCase';

class InvoiceResultRow extends Component {
  render() {
    const invoice = this.props.invoice;
    const startDate = moment(invoice.start_date).format('DD-MM-YYYY');
    const status = invoice.void ? 'void' : invoice.state;
    return (
      <tr>
        <td>{invoice.slug}</td>
        <td>{startDate}</td>
        <td>
          <span className={`status ${status}`}>
            {toTitleCase(status || 'none')}
          </span>
        </td>
        <td>{moment(invoice.state_changed_at).fromNow()}</td>
        <td>{invoice.user_name}</td>
        <td>
          <Link to={`/invoices/${this.props.id}/`}>Manage Invoice</Link>
        </td>
      </tr>
    );
  }
}

InvoiceResultRow.propTypes = {
  invoice: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
};

export default InvoiceResultRow;
