// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import store from 'store';

import { getJobOfferByID } from '_actions/jobOfferActions';
import {
  getCareTeamByNeedWithCarers,
  removeTeamMember,
} from '_actions/careTeamActions';
import { getSchedulesWithScheduleShiftsByNeedID } from '_actions/scheduleActions';

import { selectJobOfferByID } from 'shared/selectors/jobOfferSelectors';
import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';
import {
  getTeamForNeedByID,
  selectTeamMembersWithCarers,
} from 'shared/selectors/needSelectors';
import { selectScheduleForJobOfferByID } from 'shared/selectors/jobOfferSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { getUser } from 'shared/selectors/accountSelectors';

import { hasRole } from 'shared/helpers/accountHelpers';

import AdminBids from 'components/bids/AdminBids';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import type {
  JobOfferType,
  MatchType,
  ScheduleType,
  TaxonomiesClassificationType,
  TeamMembersWithCarersType,
  TeamType,
} from 'shared/types';

//TODO needs to be in actions
export type RemoveTeamMemberType = {|
  teamID: string,
  carerID: string,
  jobOfferID: string,
  team: Array<string>,
|};

type Props = {|
  careTeamWithCarersLoadingState: boolean,
  jobOffer: JobOfferType,
  match: MatchType,
  schedule: ScheduleType,
  teamMembersWithCarers: TeamMembersWithCarersType,
  getCareTeamByNeedWithCarers: string => Object,
  getJobOfferByID: string => Object,
  getSchedulesWithScheduleShiftsByNeedID: string => Object,
  removeTeamMember: RemoveTeamMemberType => Object,
  taxonomiesByClassification: TaxonomiesClassificationType,
  team: TeamType,
|};

class AdminBidsContainer extends Component<Props> {
  static defaultProps = {
    jobOffer: null,
    match: null,
    params: {},
    taxonomiesByClassification: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'care_team_member_states',
    ];
  }

  componentDidMount() {
    this.props.getCareTeamByNeedWithCarers(this.props.match.params.needID);
    this.props.getJobOfferByID(this.props.match.params.jobOfferID);
    this.props.getSchedulesWithScheduleShiftsByNeedID(
      this.props.match.params.needID,
    );
  }

  render() {
    const {
      careTeamWithCarersLoadingState,
      jobOffer,
      match,
      removeTeamMember,
      schedule,
      taxonomiesByClassification,
      team,
      teamMembersWithCarers,
      isAdmin,
    } = this.props;

    if (
      !schedule ||
      !schedule.shifts ||
      !jobOffer ||
      !taxonomiesByClassification ||
      careTeamWithCarersLoadingState
    ) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminBids
        jobOffer={jobOffer}
        needID={match.params.needID}
        removeTeamMember={removeTeamMember}
        schedule={schedule}
        taxonomiesByClassification={taxonomiesByClassification}
        team={team}
        teamMembersWithCarers={teamMembersWithCarers}
        isAdmin={isAdmin}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    careTeamWithCarersLoadingState: loadingSelectorFactory(
      'careTeams',
      'careTeamWithCarers',
    )(state),
    jobOffer: selectJobOfferByID(state, props.match.params.jobOfferID),
    schedule: selectScheduleForJobOfferByID(
      state,
      props.match.params.jobOfferID,
    ),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      AdminBidsContainer.TAXONOMY_CLASSIFICATIONS,
    ),
    team: getTeamForNeedByID(state, props.match.params.needID),
    teamMembersWithCarers: selectTeamMembersWithCarers(
      state,
      props.match.params.needID,
    ),
    isAdmin: hasRole(getUser(store.getState()), 'Admin'),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCareTeamByNeedWithCarers,
      getJobOfferByID,
      getSchedulesWithScheduleShiftsByNeedID,
      removeTeamMember,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminBidsContainer);
