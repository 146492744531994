import React, { Component } from 'react';
import { bool } from 'prop-types';
import Link from 'react-router-redux-dom-link';
import { Container, Col, Row } from 'components/grid';

import AdminUserSearchContainer from 'containers/admin/AdminUserSearchContainer';
import Module from 'components/global/layout/Module';

class AdminHome extends Component {
  static propTypes = {
    isRecruiter: bool.isRequired,
  };

  render() {
    const { isRecruiter } = this.props;

    if (isRecruiter) {
      return (
        <section>
          <AdminUserSearchContainer />
          <Module>
            <Container rowGutter={0}>
              <Row>
                <Col xs={12} md={3}>
                  <strong>Tasks</strong>
                  <br />
                  <Link className="btn" to="/admin/carers/changes/">
                    Carer Profile Changes
                  </Link>
                </Col>
              </Row>
            </Container>
          </Module>
        </section>
      )
    }

    return (
      <section>
        <AdminUserSearchContainer />
        <Module>
          <Container rowGutter={0}>
            <Row>
              <Col xs={12} md={3}>
                <strong>Admin</strong>
                <br />
                <Link className="btn" to="/admin/needs/">
                  Needs
                </Link>
                <br />
                <Link className="btn" to="/admin/job-offers/">
                  Job Offers
                </Link>
                <br />
                <Link className="btn" to="/admin/teams/?state=needs_approval">
                  Teams
                </Link>
                <br />
                <Link className="btn" to="/admin/franchises/">
                  Franchises
                </Link>
              </Col>
              <Col xs={12} md={3}>
                <strong>Tasks</strong>
                <br />
                <Link className="btn" to="/admin/carers/changes/">
                  Carer Profile Changes
                </Link>
                <br />
                <Link className="btn" to="/admin/staff/new/">
                  New Staff Account
                </Link>
                <br />
                <Link className="btn" to="/admin/applicant/new/">
                  Invite New Applicant
                </Link>
              </Col>
              <Col xs={12} md={3}>
                <strong>Settings</strong>
                <br />
                <Link className="btn" to="/admin/default-rates/">
                  Default Rates
                </Link>
              </Col>
              <Col xs={12} md={3}>
                <strong>Deprecated</strong>
                <br />
                <Link className="btn" to="/admin/charges/">
                  Charges
                </Link>
                <br />
                <Link className="btn" to="/admin/invoices/">
                  Invoices
                </Link>
                <br />
                <Link className="btn" to="/admin/timesheets/">
                  Timesheets
                </Link>
                <br />
                <Link className="btn" to="/admin/holidays/">
                  Holidays
                </Link>
                <br />
                <Link className="btn" to="/admin/discounts/">
                  Discounts
                </Link>
              </Col>
            </Row>
          </Container>
        </Module>
      </section>
    );
  }
}

export default AdminHome;
