// @flow
/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import React, { Component } from 'react';
import moment from 'moment';
import { Container, Row, Col } from 'react-grid-system';

import FormRow from 'components/forms/FormRow';
import ConfirmationDialog from 'components/global/ConfirmationDialog';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import Definition from 'components/global/informationdisplay/Definition';
import StyledBoolean from 'components/global/StyledBoolean';
import SetConfirmPasswordContainer from 'containers/account/SetConfirmPasswordContainer';
import Button from 'components/buttons/Button';

import type { UserType } from 'shared/types';

type Props = {
  archiveUser?: (value: string, value: boolean) => Object,
  impersonateUser: () => void,
  loadingImpersonator: boolean,
  sendEmailVerification: string => void,
  user: UserType,
};

type State = {
  archivingUser: ?Object,
  verificationSent: boolean,
};

class AdminUserAccount extends Component<Props, State> {

  state = {
    archivingUser: null,
    verificationSent: false,
  };

  confirmArchive = (userID: string, archive: boolean) => {
    this.setState({
      archivingUser: {'userID': userID, 'archive': archive}
    });
  };

  sendVerification = (userID) => {
    this.props.sendEmailVerification(userID);
    this.setState({verificationSent: true});
  };

  render() {
    const {
      archiveUser,
      impersonateUser,
      loadingImpersonator,
      user,
    } = this.props;

    let confirmation = '';
    let confirmationTitle = '';

    if (this.state.archivingUser) {
      confirmationTitle = `
        ${this.state.archivingUser.archive ? "Archive" : "Unarchive"} this User
      `;
      confirmation = `Yes,
        ${this.state.archivingUser.archive ? "archive" : "unarchive"} them
      `
    }

    return (
      <section>
        <Module key="login">
          <ModuleHeader>Impersonate</ModuleHeader>
          <FormRow>
            <Col>
              <Button
                className="alt"
                disabled={loadingImpersonator}
                onClick={impersonateUser}
              >
                Impersonate
              </Button>
            </Col>
          </FormRow>
          <ModuleHeader>Login</ModuleHeader>
          {user.confirmed_at ? (
            <SetConfirmPasswordContainer accountID={user.id} />
          ) : (
            <>
              <FormRow>
                <Col>
                  Users must verify their email before you can reset their password.
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <Button
                    disabled={this.state.verificationSent}
                    onClick={() => this.sendVerification(user.id)}
                  >
                    Resend Verification Email
                  </Button>
                </Col>
              </FormRow>
            </>
          )}
        </Module>
        <Module key="details">
          <ModuleHeader>Account Details</ModuleHeader>
          <Container>
            <Row>
              <Col>
                <Definition label="Verified email address">
                  <StyledBoolean value={user.confirmed_at} />
                </Definition>
                <Definition label="Accepted latest terms and conditions">
                  <StyledBoolean value={user.confirmed_tos} />
                </Definition>
                <Definition label="Last logged in">
                  {
                    user.last_login
                    ? moment(user.last_login).format('HH:mm, Do MMMM YYYY')
                    : 'Never'
                  }
                </Definition>
                <Definition label="Referral code">{user.referral_code}</Definition>
              </Col>
            </Row>
          </Container>
        </Module>
        <Module key="archive">
          <ModuleHeader>Archive User</ModuleHeader>
          <FormRow>
            <Col>
              <Button
                className="danger"
                onClick={() => this.confirmArchive(user.id, !user.archived)}
              >
                {user.archived ? 'Unarchive' : 'Archive'} User
              </Button>
            </Col>
          </FormRow>
        </Module>
        {this.state.archivingUser && (
          <ConfirmationDialog
            title={confirmationTitle}
            confirmation={confirmation}
            onConfirm={() => {
              archiveUser(
                this.state.archivingUser.userID,
                this.state.archivingUser.archive
              );
              this.setState({ archivingUser: null });
            }}
            onCancel={() => {
              this.setState({ archivingUser: null });
            }}
            danger
          />
        )}
      </section>
    );
  }
}

export default AdminUserAccount;
