import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Paginator from 'components/global/Paginator';
import NeedResultRow from 'components/admin/NeedResultRow';
import StandardTable from 'components/global/layout/StandardTable';

class NeedResults extends Component {
  render() {
    return (
      <div id="need-results">
        <StandardTable>
          <thead>
            <tr>
              <th>Created</th>
              <th>Dates</th>
              <th>Recipient</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {this.props.needs.map(need => (
              <NeedResultRow
                key={need.id}
                id={need.id}
                need={need}
                taxonomiesByClassification={this.props.taxonomiesByClassification}
              />
            ))}
          </tbody>
        </StandardTable>
        <Paginator
          currentPage={this.props.pagination.page}
          totalPages={this.props.pagination.pages}
          location={this.props.location}
        />
      </div>
    );
  }
}

NeedResults.propTypes = {
  location: PropTypes.shape().isRequired,
  needs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pagination: PropTypes.shape().isRequired,
  taxonomiesByClassification: PropTypes.shape().isRequired,
};

export default NeedResults;
