import TimesheetConstants from 'shared/constants/TimesheetConstants';
import { updateObject } from 'shared/reducers/reducerHelpers';

const initalState = {
  timesheets: {},
};

function totals(state = initalState, action) {
  let updatedTotals = {};
  switch (action.type) {
    case TimesheetConstants.TIMESHEET_GET_TOTAL_BY_ID_SUCCESS:
      updatedTotals = updateObject(state.timesheets, {
        [action.timesheet_id]: action.data,
      });
      return { ...state, timesheets: updatedTotals };

    default:
      return state;
  }
}

export default totals;
