import PropTypes from 'prop-types';
import React from 'react';

const LiveinIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 22"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="livein-icon"
        stroke="currentColor"
        strokeWidth="0.2"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path d="M2.953351,11.2119663 C3.18476922,11.2119663 3.37182879,11.3994444 3.37182879,11.6304441 L3.37182879,18.7445666 C3.37182879,18.9751479 3.55930684,19.1630444 3.79030659,19.1630444 L15.5076848,19.1630444 C15.7386846,19.1630444 15.9261626,18.9751479 15.9261626,18.7445666 L15.9261626,11.6304441 C15.9261626,11.3994444 16.1132222,11.2119663 16.3446404,11.2119663 L18.2478774,11.2119663 L15.6336467,8.6546485 C15.5528804,8.57597467 15.5076848,8.4680074 15.5076848,8.35543688 L15.5076848,4.51632158 L13.4152959,4.51632158 L13.4152959,6.53589542 C13.4152959,6.7041235 13.3144427,6.85644941 13.1591875,6.92173195 C13.0047692,6.98743296 12.8248237,6.95311778 12.7043021,6.83510705 L9.6322566,3.830018 L1.14301605,11.2119663 L2.953351,11.2119663 Z M15.5076848,20 L3.79030659,20 C3.09814431,20 2.5348732,19.4367289 2.5348732,18.7445666 L2.5348732,12.0489219 L1.14301605,12.0489219 C0.790239273,12.0489219 0.482658093,11.8354982 0.359207144,11.5053192 C0.235756194,11.1747218 0.327821309,10.8119015 0.593554709,10.5804833 L9.08321374,3.19853501 C9.40167534,2.92108423 9.91598455,2.93656791 10.2172886,3.23159475 L12.5783403,5.5411737 L12.5783403,4.51632158 C12.5783403,4.05474058 12.9541333,3.67936599 13.4152959,3.67936599 L15.5076848,3.67936599 C15.9688474,3.67936599 16.3446404,4.05474058 16.3446404,4.51632158 L16.3446404,8.17925772 L18.8329094,10.6135431 C19.0773004,10.8524939 19.1517895,11.2115478 19.0224798,11.527917 C18.8940072,11.8447047 18.5893553,12.0489219 18.2478774,12.0489219 L16.7631182,12.0489219 L16.7631182,18.7445666 C16.7631182,19.4367289 16.1998471,20 15.5076848,20 L15.5076848,20 Z" />
      </g>
    </svg>
  </div>
);

LiveinIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

LiveinIcon.defaultProps = {
  className: '',
  height: '22px',
  width: '18px',
};

export default LiveinIcon;
