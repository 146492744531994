import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Row, Col } from 'components/grid';

import InvoiceActions from 'actions/InvoiceActions';
import TimesheetActions from 'actions/TimesheetActions';
import { ModuleHeader } from 'components/global/layout/Module';
import Button from 'components/buttons/Button';
import TimesheetTotalContainer from 'containers/timesheets/TimesheetTotalContainer';
import TextInput from 'components/forms/TextInput';
import Notes from 'components/modules/Notes';
import TimesheetQuery from 'components/invoices/TimesheetQuery';

class TimesheetBreakdown extends Component {
  state = {
    newNoteVisible: false,
  };

  queryTimesheet = message => {
    this.addNewNote(message);
    TimesheetActions.update(
      this.props.timesheet.id,
      { state: 'queried' },
      false,
    ).then(() => {
      InvoiceActions.getInvoiceById(this.props.timesheet.invoice_id);
    });
  };

  addNewNote = message => {
    this.props.addNoteForObject('timesheet', this.props.timesheet.id, message);
    this.hideNewNote();
  };

  hideNewNote = () => {
    this.setState({ newNoteVisible: false });
  };

  showNewNote = () => {
    this.setState({ newNoteVisible: true });
  };

  render() {
    const paymentMethod =
      this.props.paymentMethods && this.props.paymentMethods[0];

    return (
      <div className="TimesheetBreakdown" style={{ marginTop: '10px' }}>
        <TimesheetTotalContainer
          view="customer"
          timesheet={this.props.timesheet}
          refunds={this.props.refunds}
          totals={this.props.totals}
        />
        {['approved', 'queried'].includes(this.props.timesheet.state) && (
          <Container className="timesheet-notes">
            {this.props.timesheet.state === 'approved' &&
              this.props.timesheet.charge_status !== 'paid' && (
                <Row>
                  <Col xs={12} lg={5}>
                    <TimesheetQuery queryTimesheet={this.queryTimesheet} />
                  </Col>
                  {this.props.timesheet.paymentIntent && paymentMethod && (
                    <Col xs={12} lg={5}>
                      <Button
                        onClick={() =>
                          this.props.authenticateViaStripe(
                            this.props.timesheet.paymentIntent.client_secret,
                            paymentMethod.stripe_id,
                          )
                        }
                      >
                        Authenticate Payment
                      </Button>
                    </Col>
                  )}
                </Row>
              )}

            {this.props.timesheet.state === 'queried' && (
              <ModuleHeader>Notes</ModuleHeader>
            )}

            {this.props.timesheet.state === 'queried' &&
              !this.state.newNoteVisible && (
                <Row>
                  <Col xs={12} md={3}>
                    <Button onClick={this.showNewNote}>Add Note</Button>
                  </Col>
                </Row>
              )}

            {this.state.newNoteVisible && (
              <Row>
                <Col xs={12}>
                  <TextInput
                    onSave={this.addNewNote}
                    onCancel={this.hideNewNote}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                <Notes notes={this.props.timesheet.notes} />
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
}

TimesheetBreakdown.propTypes = {
  timesheet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    invoice_id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    charge_status: PropTypes.string.isRequired,
    expenses: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        expense_type: PropTypes.string,
        miles: PropTypes.number,
      }),
    ),
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        from_user_id: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  totals: PropTypes.shape({
    shifts: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        total: PropTypes.shape({
          minutes_total: PropTypes.number,
          carer_total: PropTypes.number,
          customer_total: PropTypes.number,
        }),
      }),
    ),
    total: PropTypes.shape({
      minutes_total: PropTypes.number.isRequired,
      carer_total: PropTypes.number,
      customer_total: PropTypes.number,
    }).isRequired,
  }).isRequired,
  addNoteForObject: PropTypes.func.isRequired,
  refunds: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string.isRequired,
      description: PropTypes.string,
      totals: PropTypes.shape({
        carer_total: PropTypes.number,
        customer_total: PropTypes.number,
        supercarers_total: PropTypes.number,
      }),
    }),
  ).isRequired,
};

export default TimesheetBreakdown;
