import DiscountConstants from 'shared/constants/DiscountConstants';

import { addObject, addObjects } from 'shared/reducers/reducerHelpers';

const initalState = {};

function discounts(state = initalState, action, returnDefaultState = true) {
  switch (action.type) {
    case DiscountConstants.DISCOUNT_CREATE_SUCCESS:
      return addObject(state, action);

    case DiscountConstants.DISCOUNT_CREATE_FAILURE:
      return state;

    case DiscountConstants.DISCOUNT_GET_BY_ID_SUCCESS:
      return addObject(state, action);

    case DiscountConstants.DISCOUNT_GET_BY_ID_FAILURE:
      return state;

    case DiscountConstants.DISCOUNT_UPDATE_SUCCESS:
      return addObject(state, action);

    case DiscountConstants.DISCOUNT_UPDATE_FAILURE:
      return state;

    case DiscountConstants.DISCOUNT_SEARCH_SUCCESS:
      return addObjects(state, action);

    case DiscountConstants.DISCOUNT_SEARCH_FAILURE:
      return state;

    default:
      return returnDefaultState ? state : false;
  }
}

export default discounts;
