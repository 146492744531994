import ApiConstants from 'shared/constants/ApiConstants';
import RefundConstants from 'shared/constants/RefundConstants';

export function createRefund(chargeId, amount, feeAmount, description, preset) {
  const data = {
    amount,
    fee_amount: feeAmount,
    charge_id: chargeId,
    description,
    preset,
  };
  return {
    endpoint: '/refund/',
    method: 'post',
    onError: {
      type: RefundConstants.REFUND_CREATE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to refund.',
        dataSent: data,
      },
    },
    onStart: { type: RefundConstants.REFUND_CREATE },
    onSuccess: {
      type: RefundConstants.REFUND_CREATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Refund created.',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function searchRefunds(params, page) {
  const query = Object.assign({ page: page || 1 }, params);
  return {
    endpoint: '/refund/find/',
    method: 'get',
    onError: { type: RefundConstants.REFUND_SEARCH_FAILURE },
    onStart: { type: RefundConstants.REFUND_SEARCH },
    onSuccess: { type: RefundConstants.REFUND_SEARCH_SUCCESS },
    payload: query,
    type: ApiConstants.API_REQUEST,
  };
}
