import { Container, Col } from 'components/grid';
import FormRow from 'components/forms/FormRow';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Form from 'components/forms/Form';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import Button from 'components/buttons/Button';
import AdminRatesSettings from 'components/rates/AdminRatesSettings';
import AdminRatesTable from 'components/rates/AdminRatesTable';

class DefaultRates extends Component {
  static propTypes = {
    fields: PropTypes.shape({
      settings: PropTypes.arrayOf(PropTypes.string).isRequired,
      shiftTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    defaultRates: PropTypes.shape().isRequired,
    rateLimits: PropTypes.shape().isRequired,
    setDefaultRates: PropTypes.func.isRequired,
  };

  render() {
    const shiftTypeFields = this.props.fields.shiftTypes.concat(
      this.props.fields.shiftTypes.map(shiftType => `${shiftType}_commission`),
    );
    return (
      <Module>
        <ModuleHeader>Default Rates</ModuleHeader>
        <Form
          name="defaultRates"
          fields={this.props.fields.settings.concat(shiftTypeFields)}
          selectFields={shiftTypeFields}
          onSubmit={this.props.setDefaultRates}
          values={this.props.defaultRates}
          component={({ currentValues, handleSubmit, pristine }) => (
            <form onSubmit={handleSubmit}>
              <AdminRatesSettings />
              <AdminRatesTable
                currentValues={currentValues}
                rate={this.props.defaultRates}
                rateLimits={this.props.rateLimits}
                shiftTypes={this.props.fields.shiftTypes}
              />

              <Container>
                <FormRow>
                  <Col>To change the rate limits, please ask the dev team.</Col>
                </FormRow>
                <FormRow>
                  <Col>
                    <Button disabled={pristine} type="submit">
                      Save Changes
                    </Button>
                  </Col>
                </FormRow>
              </Container>
            </form>
          )}
        />
      </Module>
    );
  }
}

export default DefaultRates;
