// @flow
import React, { Component, type AbstractComponent, type Element } from 'react';
import { checkIfKeyHasValueInObject } from 'components/helpers/objectHelpers';
import RequiredTitle from 'components/forms/required/RequiredTitle';

type Props = {
  currentValues: Object,
  name: string,
  names: Array<string>,
  requiredFields: Array<string>,
  title: string | Element<any>,
};

export function requiredField(
  WrappedComponent: AbstractComponent<Props>,
): AbstractComponent<Props> {
  return class extends Component<Props> {
    render() {
      const { currentValues, name, names, requiredFields, title } = this.props;

      let required = false;
      if (name) {
        required = requiredFields.includes(name);
      } else if (names) {
        required = names.some(name => requiredFields.includes(name));
      }

      const completed = checkIfKeyHasValueInObject(name, currentValues);

      return (
        <WrappedComponent
          {...this.props}
          required={required}
          title={RequiredTitle(title, completed || !required)}
        />
      );
    }
  };
}

export default requiredField;
