// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import Link from 'react-router-redux-dom-link';

import FlexTable from 'components/global/layout/FlexTable';
import FlexTableRow from 'components/global/layout/FlexTableRow';
import FlexTableItem from 'components/global/layout/FlexTableItem';
import StatusIndicator from 'components/global/StatusIndicator';
import { ModuleHero } from 'components/global/layout/Module';

import type { TaxonomiesClassificationType } from 'shared/types';

const CustomFlexTableRow = styled(FlexTableRow)`
  background: ${({ priority, theme }) => priority && theme.colors.darkerBeige};
`;

type Props = {|
  jobOffers: Object,
  taxonomiesByClassification: TaxonomiesClassificationType,
|};

class AdminJobOffersResult extends Component<Props> {
  render() {
    const { jobOffers } = this.props;

    if (!jobOffers.length) {
      return <ModuleHero>No search results</ModuleHero>;
    }

    return (
      <FlexTable>
        <FlexTableRow header>
          <FlexTableItem xs={0} sm={6}>
            <h2>Reference</h2>
          </FlexTableItem>
          <FlexTableItem xs={0} sm={12}>
            Location
          </FlexTableItem>
          <FlexTableItem xs={0} sm={12}>
            Franchise
          </FlexTableItem>
          <FlexTableItem xs={0} sm={12}>
            Recipient
          </FlexTableItem>
          <FlexTableItem xs={0} sm={6}>Status</FlexTableItem>
          <FlexTableItem xs={0} sm={6}/>
        </FlexTableRow>
        {jobOffers.map(jobOffer => (
          <CustomFlexTableRow key={jobOffer.id} priority={jobOffer.priority}>
            <FlexTableItem xs={0} sm={6}>
              {jobOffer.reference}
            </FlexTableItem>
            <FlexTableItem xs={0} sm={12}>
              {`${jobOffer.location} (${jobOffer.outcode})`}
            </FlexTableItem>
            <FlexTableItem xs={0} sm={12}>
              {jobOffer.need && jobOffer.need.franchise_name}
            </FlexTableItem>
            <FlexTableItem xs={0} sm={12}>
              {jobOffer.need_name}
            </FlexTableItem>
            <FlexTableItem xs={0} sm={6}>
              <StatusIndicator status={jobOffer.state} />
            </FlexTableItem>
            <FlexTableItem xs={0} sm={6}>
              <Link
                to={
                  `/admin/needs/${jobOffer.need_id}/` +
                  `?jobID=${jobOffer.id}#job-details`
                }
              >
                View
              </Link>
            </FlexTableItem>
          </CustomFlexTableRow>
        ))}
      </FlexTable>
    );
  }
}

export default AdminJobOffersResult;
