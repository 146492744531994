import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import StandardTable from 'components/global/layout/StandardTable';
import { ModuleSubheader } from 'components/global/layout/Module';

import daysMap from 'components/helpers/daysMap';

function assignedShiftParser(shift) {
  const shiftString = `${shift.start} to ${shift.end}`;

  if (shift.type === 'livein') {
    return shiftString + ' (Live-in)';
  }

  return shiftString;
}

function formatWeekNumber(weekNumber) {
  return `Week ${parseInt(weekNumber.split('_')[1], 10) + 1}`;
}

function ShiftsTable({ shifts, title }) {
  const weeks = Object.keys(shifts).map(week => {
    const rows = Object.keys(daysMap).map(day => {
      const daysShifts = shifts[week][day];
      if (!daysShifts) {
        return null;
      }

      return (
        <tr key={day}>
          <td>{daysMap[day]}</td>
          <td>
            {daysShifts &&
              daysShifts.map(shift => (
                <div key={shift.start}>{assignedShiftParser(shift)}</div>
              ))}
          </td>
        </tr>
      );
    });

    return (
      <Fragment key={week}>
        <tr>
          <td style={{ fontWeight: 'bold' }}>{formatWeekNumber(week)}</td>
          <td />
        </tr>
        {rows}
      </Fragment>
    );
  });

  return (
    <Fragment>
      <ModuleSubheader>{title}</ModuleSubheader>
      <StandardTable className="fixed">
        <tbody>{weeks}</tbody>
      </StandardTable>
    </Fragment>
  );
}

ShiftsTable.propTypes = {
  shifts: PropTypes.shape().isRequired,
};

export default ShiftsTable;
