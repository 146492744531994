// NOTE
// Remaining actions to be ported during app rebuild
// Nothing more to be done here for now
// Temp stopgap during action migration
import { getUserById } from '_actions/userActions';

import { getUser } from 'shared/selectors/accountSelectors';
import store from 'store';

import CareNeedConstants from 'shared/constants/CareNeedConstants';
import SuperCarersClient from 'clients/SuperCarersClient';

import CareTeamActions from 'actions/CareTeamActions';
const rp = require('request-promise');

const CareNeedActions = {
  // TODO Refactor into sagas as appropriate to component
  getNeedById(needId, bare = false) {
    const uri = `${window.global.SUPERCARERS_API_URL}/care/need/${needId}/`;
    const user = getUser(store.getState());

    store.dispatch({
      type: CareNeedConstants.CARE_NEED_GET_BY_ID,
    });

    const options = {
      uri,
      method: 'GET',
      json: true,
      simple: false,
      resolveWithFullResponse: true,
      headers: {
        Authorization: `JWT ${user.access_token}`,
      },
    };

    rp(options)
      .then(response => {
        switch (response.statusCode) {
          case 200:
            store.dispatch({
              type: CareNeedConstants.CARE_NEED_GET_BY_ID_SUCCESS,
              data: response.body.data,
            });

            if (!bare) {
              CareTeamActions.getTeamByNeed(response.body.data.id);
              store.dispatch(getUserById(response.body.data.user_id));
            }

            break;

          case 404:
            // No Care Needs, still a success
            store.dispatch({
              type: CareNeedConstants.CARE_NEED_GET_BY_ID_SUCCESS,
              data: [],
            });

            break;

          default:
            store.dispatch({
              type: CareNeedConstants.CARE_NEED_GET_BY_ID_FAILURE,
              error: response.body.description || 'Unknown Error',
            });

            break;
        }

        return null;
      })
      .catch(err => {
        store.dispatch({
          type: CareNeedConstants.CARE_NEED_GET_BY_ID_FAILURE,
          error: err,
        });
      });

    return true;
  },

  // TODO: Create saga for needs with team/schedule
  getNeedsByUserId(userId) {
    store.dispatch({
      type: CareNeedConstants.CARE_NEEDS_GET_BY_USER,
    });

    return SuperCarersClient.get(`/care/need/by/owner/${userId}/`)
      .then(body => {
        store.dispatch({
          type: CareNeedConstants.CARE_NEEDS_GET_BY_USER_SUCCESS,
          data: body.data,
        });
        return body;
      })
      .catch(error => {
        if (error.response && error.response.statusCode === 404) {
          // No Care Profiles, still a success
          store.dispatch({
            type: CareNeedConstants.CARE_NEEDS_GET_BY_USER_SUCCESS,
            data: [],
          });
          return error.response.body;
        }
        store.dispatch({
          type: CareNeedConstants.CARE_NEEDS_GET_BY_USER_FAILURE,
          error: SuperCarersClient.errorDescription(error),
        });
        return error;
      });
  },
};

export default CareNeedActions;
