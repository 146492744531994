// @flow
import React from 'react';
import styled from 'styled-components';
import { object as obj } from 'prop-types';

import {
  OneLineList,
} from 'components/global/informationdisplay/DisplayLists';

import type {
  JobOfferType,
  NeedType,
  TaxonomiesClassificationType,
} from 'shared/types';

const StyledNeedOneList = styled(OneLineList)`
  flex-basis: 50%;

  @media (max-width: ${props => props.theme.screen.small}px) {
    flex-basis: 100%;
  }
`;

const StyledNeedSummaryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

type Props = {
  editing?: boolean,
  jobOffer: JobOfferType,
  need: NeedType,
  taxonomiesByClassification: TaxonomiesClassificationType,
};
const NeedsSummary = ({
  jobOffer,
  need,
  taxonomiesByClassification,
}: Props) => {
  return (
    <StyledNeedSummaryContainer>
      <StyledNeedOneList
        list={need.needs_license ? String(need.needs_license) : null}
        taxonomies={[
          { taxon_id: 'true', value: 'Yes' },
          { taxon_id: 'false', value: 'No' },
        ]}
        label="Driver Needed"
      />
      <StyledNeedOneList
        list={need.needs_car ? String(need.needs_car) : null}
        taxonomies={[
          { taxon_id: 'true', value: 'Yes' },
          { taxon_id: 'false', value: 'No' },
        ]}
        label="Car Needed"
      />
      <StyledNeedOneList
        list={need.pets}
        taxonomies={taxonomiesByClassification.pets}
        label="Pets"
      />
    </StyledNeedSummaryContainer>
  );
};

NeedsSummary.propTypes = {
  need: obj.isRequired,
  taxonomiesByClassification: obj.isRequired,
};

export default NeedsSummary;
