import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AdminChargeForm from 'components/admin/AdminChargeForm';

class AdminChargeFormContainer extends Component {
  static propTypes = {
    consumer: PropTypes.shape().isRequired,
    onSubmit: PropTypes.func.isRequired,
    charge: PropTypes.shape(),
    edit: PropTypes.bool.isRequired,
    carers: PropTypes.arrayOf(PropTypes.shape()),
    voidCharge: PropTypes.func,
  };

  static defaultProps = {
    charge: {},
    carers: [],
    voidCharge: null,
  };

  constructor(props) {
    super(props);
    const initialCharge = props.charge;

    this.state = {
      carers: props.carers,
      consumer: props.consumer,
      charge: {
        carer_id: initialCharge.carer_id || '',
        consumer_id: initialCharge.consumer_id || props.consumer.id,
        unit: initialCharge.unit || 0.0,
        unit_type: initialCharge.unit_type || 'hour',
        quantity: initialCharge.quantity || 1,
        description: initialCharge.description || '',
        charge_type: initialCharge.charge_type || 'default',
        application_fee: initialCharge.application_fee || 0,
        fulfilment_type: initialCharge.fulfilment_type || '',
      },
      chargeState: initialCharge.state || '',
      carerAmount: 0,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      carers: props.carers,
      consumer: props.consumer,
      chargeState: props.charge.state,
    });
  }

  saveCharge = () => {
    const updatedCharge = { ...this.state.charge };
    delete updatedCharge.fulfilment_type;
    this.props.onSubmit(updatedCharge);
  };

  updateValue = (name, value) => {
    const charge = this.state.charge;
    charge[name] = value;

    this.setState({
      charge,
    });
  };

  render() {
    return (
      <AdminChargeForm
        carers={this.state.carers}
        charge={this.state.charge}
        chargeState={this.state.chargeState}
        consumer={this.state.consumer}
        edit={this.props.edit}
        saveCharge={this.saveCharge}
        updateValue={this.updateValue}
        voidCharge={this.props.voidCharge}
      />
    );
  }
}

export default AdminChargeFormContainer;
