import { createSelector } from 'reselect';

import { getCarerByID } from 'shared/selectors/carerSelectors';

export const getTeams = state => state.careTeams;
export const getTeamSearch = state => state.search.careTeams;

export const getTeamCarers = (state, teamId) => {
  const team = state.careTeams[teamId];
  if (!team || !team.team) {
    return null;
  }
  return team.team
    .map(carerId => getCarerByID(state, carerId))
    .filter(carer => carer !== undefined);
};

export const getTeamResults = createSelector(
  [getTeams, getTeamSearch],
  (teams, search) => {
    if (!search || !search.searchOrder) {
      return [];
    }
    return search.searchOrder.map(id => teams[id]);
  },
);

export const getCarerTeams = (state, carerId) => {
  const teams = state.careTeams;
  if (!teams) {
    return null;
  }
  return Object.values(teams).filter(team => {
    if (!team.team) {
      return false;
    }
    return team.team.includes(carerId);
  });
};
// getTeamForNeedByID in needSelectors to avoid circular imports
// getTeamsForUserByID in needSelectors to avoid circular imports
