// @flow
import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  color: ${props => props.theme.colors.statusGrey};
  text-transform: capitalize;
  display: block;
  font-weight: 600;
  &.active
  &.compliant,
  &.connected,
  &.open,
  &.approved,
  &.responded,
  &.valid,
  &.dbs_compliant,
  &.right_to_work_compliant,
  &.references_compliant,
  &.sssc_compliant,
  &.pvg_compliant,
  &.scw_compliant,
  &.safeguarding_compliant,
  &.insurance_compliant,
  &.training_compliant,
  &.work_history_compliant,
  &.supercarers_compliant,
  &.home_instead_compliant,
  &.your_bid_was_successful,
  &.paid {
    > span {
      color: ${props => props.theme.colors.statusGreen};
    }
  }
  &.inactive,
  &.override,
  &.new,
  &.awaiting_your_response,
  &.awaiting_customer_response,
  &.home_instead_compliant_override,
  &.on_hold,
  &.notchecked,
  &.queried {
    > span {
      color: ${props => props.theme.colors.statusOrange};
    }
  }
  &.blacklisted,
  &.non-compliant,
  &.id_verification_required,
  &.unpaid,
  &.requires_action,
  &.closed,
  &.awaiting_payment,
  &.admin_rejected,
  &.customer_rejected,
  &.failed,
  &.non-responsive,
  &.references_non-compliant,
  &.dbs_non-compliant,
  &.changed,
  &.expired,
  &.right_to_work_non-compliant,
  &.insurance_non-compliant,
  &.work_history_non-compliant,
  &.supercarers_non-compliant,
  &.training_non-compliant,
  &.sssc_non-compliant,
  &.pvg_non-compliant,
  &.scw_non-compliant,
  &.safeguarding_non-compliant,
  &.home_instead_non-compliant,
  &.your_bid_was_unsuccessful,
  &.you_declined_this_job,
  &.your_bid_was_withdrawn,
  &.processing_payment {
    > span {
      color: ${props => props.theme.colors.statusRed};
    }
  }
  &.elite
  &.submitted {
    > span {
      color: ${props => props.theme.colors.statusBlue};
    }
  }
  &.request_sent,
  &.applied,
  &.proposed,
  &.pending {
    > span {
      color: ${props => props.theme.colors.statusPurple};
    }
  }
`;

// const StatusIndicator = ({ status }: { status: ?string }) => (
//   <StyledSpan className={`status ${status || 'none'}`}>
//     <span>●</span> {(status || 'none').replace(/_/g, ' ')}
//   </StyledSpan>
// );

const StyledDot = styled.span`
  ${props =>
    props.large
      ? `font-size: 30px;
    vertical-align: text-bottom;`
      : ''};
`;

const StatusIndicator = ({
  status,
  largeDot,
}: {
  status: ?string,
  largeDot?: boolean,
}) => (
  <StyledSpan className={status ? `status ${status}` : ''}>
    <StyledDot large={largeDot}>●</StyledDot>{' '}
    {(status || 'none').replace(/_/g, ' ')}
  </StyledSpan>
);

StatusIndicator.defaultProps = {
  status: null,
};

export default StatusIndicator;
