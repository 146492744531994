import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AdminTeamsOverview from 'components/admin/AdminTeamsOverview';

class AdminTeamsOverviewContainer extends Component {
  render() {
    return <AdminTeamsOverview location={this.props.location} />;
  }
}

AdminTeamsOverviewContainer.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default AdminTeamsOverviewContainer;
