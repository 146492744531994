import React from 'react';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import Definition from 'components/global/informationdisplay/Definition';
import { EditIcon } from 'components/global/icons';
import { ModuleSubheader } from 'components/global/layout/Module';
import type { NeedType, UserType } from 'shared/types';

const InlineEditIcon = styled(EditIcon)`
  display: inline;
  vertical-align: sub;
  margin-right: 6px;
`;

const SummaryMeta = styled.div`
  @media (min-width: ${props => props.theme.screen.medium}px) {
    position: absolute;
    top: 18px;
    right: 10px;
    text-align: end;
  }

  > * {
    display: block;
  }
`;

type Props = {
  customer: UserType,
  need: NeedType,
};

const SummaryRecipientModule = ({ customer, need }: Props) => (
  <>
    <SummaryMeta>
      <Link to={`/admin/needs/${need.id}/#edit-need`}>
        <InlineEditIcon height="20" width="20" />
        Edit need
      </Link>
    </SummaryMeta>
    <ModuleSubheader noMargin style={{ display: 'inline-block' }}>
      Care recipient
    </ModuleSubheader>
    <Definition label="Name">
      {need.firstname} {need.lastname}
    </Definition>
    <Definition label="Age">
      {need.age ? `${need.age} years old` : 'Unknown'}
    </Definition>
    <Definition label="Postcode">{need.postcode}</Definition>

    <Definition label="Description">
      <div
        id="needDescription"
        dangerouslySetInnerHTML={{ __html: need.story }}
      />
    </Definition>
  </>
);

export default SummaryRecipientModule;
