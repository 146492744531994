import { put, take, all } from 'redux-saga/effects';
import uuidv4 from 'uuid/v4';

/**
 * @description a helper function to stop the tiresome boilerplate of putting
 * the same action multiple times with different arguments, and verifying that
 * each individual request is successful.
 *
 * @param action The action to call.
 * It is assumed the actions last argument is a tag which is included in its
 * onError and onSucccess payloads as the prop 'tag'
 * @param argsArray Array of arguments for each iteration of the action.
 * If the action accepts more than 1 argument (excluding the tag), then
 * argsArray's elements must also be arrays.
 * @returns true if successful, false otherwise.
 **/
export function* runParallel(action, argsArray, successString, failString) {
  const tasks = [];
  const tasksPending = new Set();
  argsArray.forEach(args => {
    const tag = uuidv4();
    if (Array.isArray(args)) {
      tasks.push(put(action.apply(null, args.concat(tag))));
    } else {
      tasks.push(put(action(args, tag)));
    }
    tasksPending.add(tag);
  });

  yield all(tasks);
  while (tasksPending.size) {
    const result = yield take([successString, failString]);
    if (tasksPending.has(result.tag)) {
      if (result.type === successString) {
        tasksPending.delete(result.tag);
      } else {
        return false;
      }
    }
  }
  return true;
}
