import BidConstants from 'shared/constants/BidConstants';
import CareNeedConstants from 'shared/constants/CareNeedConstants';
import CarerConstants from 'shared/constants/CarerConstants';
import CareTeamConstants from 'shared/constants/CareTeamConstants';
import ChargeConstants from 'shared/constants/ChargeConstants';
import DiscountConstants from 'shared/constants/DiscountConstants';
import FranchiseConstants from 'shared/constants/FranchiseConstants';
import InvoiceConstants from 'shared/constants/InvoiceConstants';
import JobOfferConstants from 'shared/constants/JobOfferConstants';
import NoteConstants from 'shared/constants/NoteConstants';
import ReferencesConstants from 'shared/constants/ReferencesConstants';
import RefundConstants from 'shared/constants/RefundConstants';
import TimesheetConstants from 'shared/constants/TimesheetConstants';
import UserConstants from 'shared/constants/UserConstants';
import { updateObject } from 'shared/reducers/reducerHelpers';

const initalState = {
  bids: null,
  careTeams: null,
  carerProfiles: null,
  carers: null,
  charges: null,
  discounts: null,
  franchises: null,
  invoices: null,
  jobOffers: null,
  needs: null,
  notes: null,
  references: null,
  refunds: null,
  timesheets: null,
  users: null,
  lastSearch: {},
};

const parsePagination = data => ({
  page: data.page,
  pages: data.pages,
  per_page: data.per_page,
  total: data.total,
});

const parseSearch = (state, category, action) => {
  const data = action.data;
  const newSearch = {};
  newSearch.pagination = parsePagination(data);
  if (data.results) {
    newSearch.searchOrder = data.results.map(item => item.id);
    newSearch.results = data.results;
  } else if (data.ids) {
    newSearch.searchOrder = data.ids;
  }
  if (data.distance) {
    newSearch.distance = data.distance;
  }
  const searchName = action.payload;
  const previousSearch = state[category];
  let categorySearch = searchName ? { [searchName]: newSearch } : newSearch;

  if (previousSearch) {
    categorySearch = { ...previousSearch, ...categorySearch };
  }

  return { [category]: categorySearch };
};

const updateSearch = (state, category, action) => {
  const results = Object.assign({}, state[category]);
  const latest = parseSearch(state, category, action)[category];
  results.pagination = latest.pagination;
  results.distance = results.distance.concat(latest.distance);
  results.searchOrder = results.searchOrder.concat(latest.searchOrder);
  return { [category]: results };
};

const removeItemFromSearch = (state, category, id) => {
  const results = Object.assign({}, state[category]);
  if (!results || !results.results) {
    return { [category]: results };
  }
  delete results.results[id];
  results.searchOrder = results.searchOrder.filter(data => data !== id);
  return { [category]: results };
};

const removeAllItemsFromCategory = (state, category) => ({
  [category]: { results: [] },
});

const search = (state = initalState, action) => {
  const newSearch = {};
  let lastSearch;
  switch (action.type) {
    case DiscountConstants.DISCOUNT_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'discounts', action));

    case CareNeedConstants.CARE_NEED_GET_ALL_SUCCESS:
      newSearch.pagination = parsePagination(action.data);
      return updateObject(state, { needs: newSearch });

    case CareNeedConstants.CARE_NEED_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'needs', action));

    case CareNeedConstants.CARE_NEED_ARCHIVE_SUCCESS:
      return updateObject(
        state,
        removeItemFromSearch(state, 'needs', action.data.id),
      );

    case BidConstants.BID_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'bids', action, state));

    case InvoiceConstants.INVOICE_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'invoices', action));

    case JobOfferConstants.JOB_OFFER_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'jobOffers', action));

    case FranchiseConstants.FRANCHISE_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'franchises', action));

    case RefundConstants.REFUND_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'refunds', action));

    case TimesheetConstants.TIMESHEET_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'timesheets', action));

    case UserConstants.USER_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'users', action));

    case CarerConstants.CARER_SEARCH:
    case CarerConstants.CARER_SEARCH_MORE:
      lastSearch = { ...state.lastSearch };
      lastSearch.carers = action.payload;
      return updateObject(state, { lastSearch });

    case CarerConstants.CARER_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'carers', action));

    case CarerConstants.CARER_SEARCH_MORE_SUCCESS:
      return updateObject(state, updateSearch(state, 'carers', action));

    case CarerConstants.CARER_PROFILE_SEARCH:
      return removeAllItemsFromCategory(state, 'carerProfiles');
    case CarerConstants.CARER_PROFILE_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'carerProfiles', action));

    case CareTeamConstants.CARETEAM_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'careTeams', action));

    case ChargeConstants.CHARGE_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'charges', action));

    case ReferencesConstants.REFERENCES_GET_FOR_CARER_BY_ID_SUCCESS:
    case ReferencesConstants.REFERENCES_SEARCH_SUCCESS:
      return updateObject(state, parseSearch(state, 'references', action));

    case NoteConstants.NOTES_GET_FOR_OBJECT_SUCCESS:
      return updateObject(state, parseSearch(state, 'notes', action));

    default:
      return state;
  }
};

export default search;
