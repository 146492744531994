// @flow
import React from 'react';

import Module, { ModuleHeader } from 'components/global/layout/Module';
import AccountChangePasswordContainer from 'containers/account/AccountChangePasswordContainer';
import PersonalDetailsForm from 'components/account/PersonalDetailsForm';
// import AccountPaymentMethodsContainer from 'containers/account/AccountPaymentMethodsContainer';
import AccountAvailabilityContainer from 'containers/account/AccountAvailabilityContainer';
// import StripeConnect from 'components/payments/StripeConnect';

import type { TaxonomiesClassificationType, UserType } from 'shared/types';

type Props = {
  emailVerified: boolean,
  isCarer: boolean,
  isCustomer: boolean,
  updateAccount: Object => Object,
  user: UserType,
  taxonomiesByClassification: TaxonomiesClassificationType,
};

const AccountEdit = ({
  emailVerified,
  isCarer,
  isCustomer,
  taxonomiesByClassification,
  updateAccount,
  user,
}: Props) => (
  <section>
    {/* {isCarer && !user.has_stripe_account && <StripeConnect carer={user} />} */}
    {isCarer && <AccountAvailabilityContainer />}
    <Module>
      <ModuleHeader>Personal Details</ModuleHeader>
      <PersonalDetailsForm
        isCarer={isCarer}
        updateAccount={updateAccount}
        user={user}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    </Module>
    {emailVerified && (
      <>
        <AccountChangePasswordContainer />
        {/*{isCustomer && <AccountPaymentMethodsContainer />}*/}
      </>
    )}
  </section>
);

export default AccountEdit;
