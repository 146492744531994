import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { generateAdhocTimesheet } from '_actions/needActions';

import AdhocTimesheetCreator from 'components/timesheets/AdhocTimesheetCreator';

class AdhocTimesheetCreatorContainer extends Component {
  constructor() {
    super();
    this.state = {
      need_id: null,
      date: null,
    };
  }

  onSelectDate = value => {
    let date = value;
    if (value === '') {
      date = null;
    }
    this.setState({
      date,
    });
  };

  onSelectNeed = value => {
    let needID = value;
    if (value === '') {
      needID = null;
    }
    this.setState({
      need_id: needID,
    });
  };

  createAdhocTimesheet = () => {
    const date = moment(this.state.date);
    this.props.generateAdhocTimesheet(
      this.state.need_id,
      this.props.carerID,
      date,
    );
  };

  render() {
    return (
      <AdhocTimesheetCreator
        onSelectNeed={this.onSelectNeed}
        onSelectDate={this.onSelectDate}
        needs={this.props.needs}
        needId={this.state.need_id}
        date={this.state.date}
        createAdhocTimesheet={this.createAdhocTimesheet}
      />
    );
  }
}

AdhocTimesheetCreatorContainer.propTypes = {
  needs: PropTypes.shape().isRequired,
  carerID: PropTypes.string.isRequired,
  generateAdhocTimesheet: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      generateAdhocTimesheet,
    },
    dispatch,
  );
}

export default connect(
  null,
  mapDispatchToProps,
)(AdhocTimesheetCreatorContainer);
