export const selectNotes = state => state.notes;

export const selectNotesByObjectID = (state, id, category = null) => {
  if (state.notes[id] && category) {
    return state.notes[id].filter(note => note.category === category);
  }
  if (state.notes[id]) {
    return state.notes[id];
  }
  return [];
};
