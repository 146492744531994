import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Row, Col } from 'components/grid';

class OutstandingTimesheetMessage extends Component {
  render() {
    let message;
    if (
      this.props.user.isAdmin &&
      ['submitted', 'approval_needed'].includes(this.props.state)
    ) {
      message = 'Waiting for an admin to approve timesheet.';
    } else {
      let pronoun;
      switch (this.props.carer.gender) {
        case 'Male':
          pronoun = 'his';
          break;
        case 'Female':
          pronoun = 'her';
          break;
        default:
          pronoun = 'their';
      }
      message = `Waiting for ${
        this.props.carer.firstname
      } to submit ${pronoun} timesheet.`;
    }

    return (
      <Container>
        <Row>
          <Col style={{ marginBottom: '10px' }}>{message}</Col>
        </Row>
      </Container>
    );
  }
}

OutstandingTimesheetMessage.propTypes = {
  state: PropTypes.string.isRequired,
  carer: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    isAdmin: PropTypes.bool.isRequired,
  }).isRequired,
};

export default OutstandingTimesheetMessage;
