import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPassword } from '_actions/accountActions';


import AccountResetPasswordForm from 'components/account/AccountResetPasswordForm';
import AccountLoginModule from 'components/account/AccountLoginModule';
import { SC_NAME } from 'components/global/ContactInfo';

const MESSAGE =
  `Please set a new password to access your ${SC_NAME} account. We’ll then take you to the homepage to log in.`;

class AccountResetPasswordContainer extends Component {
  setPassword = ({ password }) => {
    const token = this.props.match.params.reset_token;
    this.props.resetPassword({ token, password });
  };

  render() {
    return (
      <AccountLoginModule
        title={'Password Reset'}
        message={MESSAGE}
        rules={true}
      >
        <AccountResetPasswordForm resetPassword={this.setPassword} />
      </AccountLoginModule>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetPassword,
    },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(AccountResetPasswordContainer);
