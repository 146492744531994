import PropTypes from 'prop-types';
import React from 'react';
import {
  MasterCard,
  Visa,
  AmericanExpress,
} from 'components/payments/CardImages';

const AcceptedPaymentTypes = {
  mastercard: MasterCard,
  visa: Visa,
  amex: AmericanExpress,
  amercianexpress: AmericanExpress,
};

const CardBrand = ({ brand, className, height, width }) => {
  const Brand = AcceptedPaymentTypes[brand.toLowerCase()] || 'Other';

  if (!Brand) {
    return null;
  }
  return (
    <div className={className}>
      <Brand width={width} height={height} />
    </div>
  );
};

CardBrand.propTypes = {
  brand: PropTypes.string.isRequired,
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

CardBrand.defaultProps = {
  className: 'logo',
  color: 'white',
  height: '100%',
  width: '100%',
};

export default CardBrand;
