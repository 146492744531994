// @flow
import React from 'react';
import styled from 'styled-components';

import { currencyFormat } from 'components/helpers/currency';

import type { JobOfferType } from 'shared/types';

type Props = {
  jobOffer: JobOfferType,
  positioning?: ?string,
};

const StyledPriorityIndicator = styled.div`
  font-weight: 700;
  text-align: right;

  ${({ positioning }) => positioning}
`;

export default function PriorityIndicator({ jobOffer, positioning }: Props) {
  const priority = jobOffer.priority || !!jobOffer.incentive_supercarers;
  const incentive =
    jobOffer.incentive_customer + jobOffer.incentive_supercarers;

  if (!priority && !incentive) {
    return null;
  }

  return (
    <StyledPriorityIndicator positioning={positioning}>
      {incentive ? `${currencyFormat(incentive)} BONUS` : 'PRIORITY'}
    </StyledPriorityIndicator>
  );
}
