// @flow
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { SCContactNumber } from 'components/global/ContactInfo.jsx';
import { ThickUnderline } from 'components/global/StyledUnderlines';
import NumberedList from 'components/global/layout/NumberedList';

import type { NeedType, UserType } from 'shared/types';

const StyledWelcomeMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 30px;
  padding-top: 30px;
`;

const WelcomeHeader = styled.div`
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const StyledInlineMessage = styled.div`
  display: inline;
`;

const StyledThankYouMessage = styled(StyledInlineMessage)`
  font-size: 1.2em;
  line-height: 1.4em;
`;

type Props = { need: NeedType, user: UserType, redirect: boolean };

class WelcomeMessage extends PureComponent<Props> {
  determineWelcomeMessage = (need: NeedType, user: UserType, redirect: boolean) => {
    if (redirect) {
      return (
        <p>
          SuperCarers no longer operates an introductory care service.
          If you are looking for care, Home Instead have local offices throughout the UK.
        </p>
      )
    }

    if (!need) {
      return ''
    }

    if (need.state === 'new') {
      return 'Please let us know the details of your care requirements so that we can start finding carers.';
    }

    if (need.state === 'need_submitted' || need.state === 'job_created') {
      const relationshipString =
        need.relationship && need.relationship !== 'myself'
          ? `${need.relationship}'s `
          : '';
      return (
        <>
          <StyledThankYouMessage>
            <p>{`Thank you for sharing more information about your ${relationshipString}care needs.`}</p>
            <NumberedList>
              {!user.confirmed_at && (
                <p>
                  Verify your email to gain full access to your account. Until
                  you have verified we cannot show you any details regarding
                  your {relationshipString}care. If you haven't already received
                  a link, then follow the instructions at the bottom of this
                  page.
                </p>
              )}
              <p>
                We have already started matching your specific requirements with
                our network of qualified and experienced carers.
              </p>
              <p>
                As soon as we have matched carers, you will be able to view them
                in your care hub and our dedicated team will be in touch to
                discuss your choices.
              </p>
              <p>
                In the meantime, if you have any questions please don't hesitate
                to get in touch.
              </p>
            </NumberedList>
          </StyledThankYouMessage>
        </>
      );
    }

    if (need.state === 'team_proposed') {
      return '';
    }

    return (
      <>
        <StyledInlineMessage>
          Anything you need? Give us a call on <SCContactNumber />.
        </StyledInlineMessage>
      </>
    );
  };

  render() {
    const { need, user, redirect } = this.props;

    return (
      <StyledWelcomeMessage>
        <WelcomeHeader>
          {`Hello ${user.firstname ? user.firstname : ''}`}
        </WelcomeHeader>
        <ThickUnderline themeColour={'hiAccentBlue'} />
        <div>{this.determineWelcomeMessage(need, user, redirect)}</div>
      </StyledWelcomeMessage>
    );
  }
}

export default WelcomeMessage;
