import moment from 'moment';

import MetaConstants from 'shared/constants/MetaConstants';

const initalState = {
  lastCheckedForUpdate: null,
  lastCheckedUpdateEtag: null,
  requiresUpdate: false,
};

function meta(state = initalState, action) {
  let requiresUpdate = false;

  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      if (state.requiresUpdate) {
        window.location.reload();
      }
      return state;

    case MetaConstants.META_CHECK_FOR_UPDATE_SUCCESS:
      if (state.lastCheckedUpdateEtag) {
        requiresUpdate = state.lastCheckedUpdateEtag !== action.etag;
      }

      return {
        lastCheckedForUpdate: moment(),
        lastCheckedUpdateEtag: action.etag,
        requiresUpdate,
      };

    default:
      return state;
  }
}

export default meta;
