export const differentiateArrayOfObjects = (a, b) =>
  a.filter(item => {
    let allMatched = false;
    b.forEach(potentialMatch => {
      const match = Object.keys(item).every(
        itemKey => item[itemKey] === potentialMatch[itemKey],
      );
      if (match) {
        allMatched = true;
      }
    });
    return !allMatched;
  });
