// @flow
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import zohoImage from 'components/global/icons/ZOHO.svg';

const StyledLink = styled.a`
  display: inline-block;

  @media (min-width: ${props => props.theme.screen.medium}px) {
    position: absolute;
    bottom: 8px;
  }

  > img {
    display: inline-block;
    margin-right: 10px;
    width: 50px;
    position: relative;
    top: 1px;
  }

  > span {
    display: inline-block;
    text-decoration: underline;
  }
`;

const ZOHO_PREFIX = `https://crm.zoho.eu/crm/tab`;

type Props = {
  id: string,
  resource: string,
  user: boolean,
};

const ZohoButton = ({ id, resource, user }: Props) => {
  let href;
  if (user) {
    href = `${ZOHO_PREFIX}/Contacts/${id}/`;
  } else if (resource === 'leads') {
    href = `${ZOHO_PREFIX}/Leads/${id}/`;
  } else if (resource === 'deals') {
    href = `${ZOHO_PREFIX}/Potentials/${id}/`;
  } else {
    return null;
  }
  return (
    <StyledLink href={href} target="_blank">
      <img src={zohoImage} alt="ZOHO" />
      <span>View on ZOHO</span>
    </StyledLink>
  );
};

ZohoButton.propTypes = {
  id: PropTypes.string.isRequired,
  resource: PropTypes.string,
  user: PropTypes.bool,
};

ZohoButton.defaultProps = {
  resource: null,
  user: false,
};

export default ZohoButton;
