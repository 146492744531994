// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import { getCarerProfileByCarerId } from '_actions/carerActions';
import { getUser } from 'shared/selectors/accountSelectors';
import { getLiveProfileByCarerID } from 'shared/selectors/carerSelectors';

import CarerJobOffersOverview from 'components/jobOffers/CarerJobOffersOverview';

import type { CarerProfileType, HistoryType, LocationType } from 'shared/types';

type Props = {
  getCarerProfileByCarerId: (string, Object) => void,
  history: HistoryType,
  location: LocationType,
  profile: CarerProfileType,
  userID: string,
};

const CarerJobOffersOverviewContainer = ({
  getCarerProfileByCarerId,
  history,
  location,
  profile,
  userID,
}: Props) => {
  useEffect(() => {
    if (!profile) {
      getCarerProfileByCarerId(userID, {
        approved: true,
      });
    }
  }, []);

  if (!profile) {
    return null;
  }

  return (
    <CarerJobOffersOverview
      location={location}
      history={history}
      profile={profile}
      userID={userID}
    />
  );
};

const mapStateToProps = (state, props) => {
  const userID = getUser(state).id;
  return {
    profile: getLiveProfileByCarerID(state, userID),
    userID,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCarerProfileByCarerId,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CarerJobOffersOverviewContainer));
