import PropTypes from 'prop-types';
import React from 'react';

const DayIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 22"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="day-icon"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <g transform="translate(0.256081, 1.754833)">
          <path d="M9.94761333,4.84655298 C7.00400101,4.84655298 4.60945626,7.24109772 4.60945626,10.1847101 C4.60945626,13.1283224 7.00400101,15.5228671 9.94761333,15.5228671 C12.8912257,15.5228671 15.2857704,13.1283224 15.2857704,10.1847101 C15.2857704,7.24109772 12.8912257,4.84655298 9.94761333,4.84655298 Z M9.94761333,16.5396589 C6.44324032,16.5396589 3.59266444,13.6890831 3.59266444,10.1847101 C3.59266444,6.68033703 6.44324032,3.82976116 9.94761333,3.82976116 C13.4519864,3.82976116 16.3025622,6.68033703 16.3025622,10.1847101 C16.3025622,13.6890831 13.4519864,16.5396589 9.94761333,16.5396589 L9.94761333,16.5396589 Z" />
          <path
            d="M19.3337307,10.631668 L17.5458988,10.631668 C17.299178,10.631668 17.0989408,10.4314309 17.0989408,10.1847101 C17.0989408,9.93798925 17.299178,9.73775208 17.5458988,9.73775208 L19.3337307,9.73775208 C19.5804515,9.73775208 19.7806886,9.93798925 19.7806886,10.1847101 C19.7806886,10.4314309 19.5804515,10.631668 19.3337307,10.631668"
            stroke="currentColor"
            strokeWidth="0.1"
          />
          <path
            d="M2.34932787,10.631668 L0.561495993,10.631668 C0.314775194,10.631668 0.114538024,10.4314309 0.114538024,10.1847101 C0.114538024,9.93798925 0.314775194,9.73775208 0.561495993,9.73775208 L2.34932787,9.73775208 C2.59604867,9.73775208 2.79628584,9.93798925 2.79628584,10.1847101 C2.79628584,10.4314309 2.59604867,10.631668 2.34932787,10.631668"
            stroke="currentColor"
            strokeWidth="0.1"
          />
          <path
            d="M9.50065536,19.5708274 L9.50065536,17.7829955 C9.50065536,17.5362747 9.70089253,17.3360375 9.94761333,17.3360375 C10.1943341,17.3360375 10.3945713,17.5362747 10.3945713,17.7829955 L10.3945713,19.5708274 C10.3945713,19.8175482 10.1943341,20.0177854 9.94761333,20.0177854 C9.70089253,20.0177854 9.50065536,19.8175482 9.50065536,19.5708274 Z"
            stroke="currentColor"
            strokeWidth="0.1"
          />
          <path
            d="M9.94761333,3.03338255 C9.70089253,3.03338255 9.50065536,2.83314538 9.50065536,2.58642459 L9.50065536,0.798592711 C9.50065536,0.551871912 9.70089253,0.351634742 9.94761333,0.351634742 C10.1943341,0.351634742 10.3945713,0.551871912 10.3945713,0.798592711 L10.3945713,2.58642459 C10.3945713,2.83314538 10.1943341,3.03338255 9.94761333,3.03338255"
            stroke="currentColor"
            strokeWidth="0.1"
          />
          <path
            d="M16.6636038,17.3476585 C16.5491825,17.3476585 16.4347613,17.3038566 16.3476045,17.2166998 L14.8873928,15.7564881 C14.7130792,15.5821745 14.7130792,15.2992501 14.8873928,15.1244895 C15.0621534,14.9501759 15.3450778,14.9501759 15.5193914,15.1244895 L16.9796031,16.5847012 C17.1543636,16.7590148 17.1543636,17.0419392 16.9796031,17.2166998 C16.8924462,17.3038566 16.778025,17.3476585 16.6636038,17.3476585"
            stroke="currentColor"
            strokeWidth="0.1"
          />
          <path
            d="M4.69183458,5.37588927 C4.57741334,5.37588927 4.4629921,5.33208739 4.3758353,5.24493058 L2.91562361,3.7847189 C2.74086305,3.61040529 2.74086305,3.3274809 2.91562361,3.15272033 C3.09038418,2.97795977 3.37286162,2.97795977 3.54762218,3.15272033 L5.00783386,4.61293201 C5.18214747,4.78724562 5.18214747,5.07017002 5.00783386,5.24493058 C4.9202301,5.33208739 4.80625582,5.37588927 4.69183458,5.37588927"
            stroke="currentColor"
            strokeWidth="0.1"
          />
          <path
            d="M3.2316229,17.3476585 C3.11720166,17.3476585 3.00278042,17.3038566 2.91562361,17.2166998 C2.74086305,17.0419392 2.74086305,16.7590148 2.91562361,16.5847012 L4.3758353,15.1244895 C4.5501489,14.9501759 4.8330733,14.9501759 5.00783386,15.1244895 C5.18214747,15.2992501 5.18214747,15.5821745 5.00783386,15.7564881 L3.54762218,17.2166998 C3.46046538,17.3038566 3.34604414,17.3476585 3.2316229,17.3476585"
            stroke="currentColor"
            strokeWidth="0.1"
          />
          <path
            d="M15.2033921,5.37588927 C15.0889708,5.37588927 14.9749966,5.33208739 14.8873928,5.24493058 C14.7130792,5.07017002 14.7130792,4.78724562 14.8873928,4.61293201 L16.3476045,3.15272033 C16.5219181,2.97795977 16.8048425,2.97795977 16.9796031,3.15272033 C17.1543636,3.3274809 17.1543636,3.61040529 16.9796031,3.7847189 L15.5193914,5.24493058 C15.4322346,5.33208739 15.3178133,5.37588927 15.2033921,5.37588927"
            stroke="currentColor"
            strokeWidth="0.1"
          />
        </g>
      </g>
    </svg>
  </div>
);

DayIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

DayIcon.defaultProps = {
  className: '',
  height: '22px',
  width: '18px',
};

export default DayIcon;
