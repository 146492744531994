// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateNeed } from '_actions/needActions';

import ChangeRequest from 'components/customers/ChangeRequest';

import type { APIAction, NeedType } from 'shared/types';

type Props = {
  buttonLabel: string,
  need: NeedType,
  prefix: string,
  title: string,
  updateNeed: NeedType => APIAction,
};

export function ChangeRequestContainer({
  buttonLabel,
  need,
  prefix,
  title,
  updateNeed,
}: Props) {
  if (!need) {
    return null;
  }

  const updateQueryMessage = (message: string) => {
    // this is a change
    const queryMessage = `${prefix}: ${message}`;

    updateNeed({ id: need.id, query_message: queryMessage, state: 'queried' });
  };

  const queryMessage =
    need.query_message &&
    need.query_message.substring(need.query_message.indexOf(':') + 1);

  return (
    <ChangeRequest
      buttonLabel={buttonLabel}
      queryMessage={queryMessage}
      needState={need.state}
      title={title}
      updateQueryMessage={updateQueryMessage}
    />
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateNeed }, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(ChangeRequestContainer);
