import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayOf, func, shape } from 'prop-types';

import BidsList from 'components/bids/BidsList';
import NoBidsMessage from 'components/bids/NoBidsMessage';
import Module, { ModuleHeader } from 'components/global/layout/Module';

import {
  selectBidsByJobOfferID,
  selectCarersForBidsByJobOffer,
} from 'shared/selectors/bidSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

class BidsListContainer extends Component {
  static propTypes = {
    bids: arrayOf(shape()),
    carers: shape(),
    jobOffer: shape(),
    schedules: arrayOf(shape().isRequired),
    updateBid: func.isRequired,
    taxonomiesByClassification: shape().isRequired,
  };

  static defaultProps = {
    bids: null,
    carers: null,
    jobOffer: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['bid_customer_rejection'];
  }

  rejectBid = (bidID, reason) => {
    this.props.updateBid(bidID, {
      state: 'customer_rejected',
      state_reason: reason,
    });
  };

  render() {
    const {
      bids,
      carers,
      jobOffer,
      schedules,
      taxonomiesByClassification,
      updateBid,
    } = this.props;

    const schedule = schedules.find(sched => jobOffer.schedule_id === sched.id);

    if (!bids || !carers || !Object.keys(carers).length) {
      return <NoBidsMessage />;
    }

    const outstandingBids = bids.filter(bid =>
      ['proposed', 'accepted'].includes(bid.state),
    );

    if (!outstandingBids.length) {
      return <NoBidsMessage />;
    }

    return (
      <Module>
        <ModuleHeader>Your Care Team Candidates</ModuleHeader>
        <BidsList
          carers={carers}
          outstandingBids={outstandingBids}
          rejectBid={this.rejectBid}
          rejectionReasons={taxonomiesByClassification.bid_customer_rejection}
          schedule={schedule}
          shiftTypes={jobOffer.shift_types}
          updateBid={updateBid}
        />
      </Module>
    );
  }
}

const mapStateToProps = (state, props) => {
  let bids = null;
  let carers = null;

  if (props.jobOffer) {
    bids = selectBidsByJobOfferID(state, props.jobOffer.id);
    carers = selectCarersForBidsByJobOffer(state, props.jobOffer.id);
  }
  return {
    bids,
    carers,
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      BidsListContainer.TAXONOMY_CLASSIFICATIONS,
    ),
  };
};

export default connect(mapStateToProps)(BidsListContainer);
