import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getCarerById,
  getCarerInductionSlot,
  getCarerProfileByCarerId,
} from '_actions/carerActions';

import CarerOnboarding from 'components/carers/CarerOnboarding';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import { getUser } from 'shared/selectors/accountSelectors';
import {
  getUserAsCarer,
  selectCarerInductionSlot,
  getCarerProfileCompleted,
} from 'shared/selectors/carerSelectors';

class CarerHomeContainer extends Component {
  static propTypes = {
    carer: PropTypes.shape(),
    getCarerInductionSlot: PropTypes.func.isRequired,
    getCarerProfileByCarerId: PropTypes.func.isRequired,
    inductionSlot: PropTypes.shape(),
    profileComplete: PropTypes.bool.isRequired,
    user: PropTypes.shape(),
    getCarerById: PropTypes.func.isRequired,
  };

  static defaultProps = {
    carer: null,
    inductionSlot: null,
    user: null,
  };

  UNSAFE_componentWillMount() {
    this.props.getCarerById(this.props.user.id);
    this.props.getCarerProfileByCarerId(this.props.user.id, {
      live_status: 'draft',
    });
    this.props.getCarerInductionSlot(this.props.user.id);
  }

  render() {
    const { carer, inductionSlot, profileComplete, user } = this.props;
    if (!carer || !carer.profile_details) {
      return <LoadingPlaceholder />;
    }

    return (
      <CarerOnboarding
        carer={carer}
        inductionSlot={inductionSlot}
        profileComplete={profileComplete}
        user={user}
      />
    );
  }
}

const mapStateToProps = state => ({
  carer: getUserAsCarer(state),
  inductionSlot: selectCarerInductionSlot(state),
  profileComplete: getCarerProfileCompleted(state),
  user: getUser(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCarerById,
      getCarerProfileByCarerId,
      getCarerInductionSlot,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerHomeContainer);
