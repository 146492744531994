// NOTE
// Remaining actions to be ported during app rebuild
// Nothing more to be done here for now
import { getCarerById } from '_actions/carerActions';
import { getUser } from 'shared/selectors/accountSelectors';
import store from 'store';

import CareTeamConstants from 'shared/constants/CareTeamConstants';
const rp = require('request-promise');

const CareTeamActions = {
  // TODO: Create use case sagas
  getTeamByNeed(needId) {
    const uri = `${
      window.global.SUPERCARERS_API_URL
    }/care/team/by/need/${needId}/`;
    const user = getUser(store.getState());

    store.dispatch({
      type: CareTeamConstants.CARETEAM_GET_BY_NEED,
    });

    const options = {
      uri,
      method: 'GET',
      json: true,
      simple: false,
      resolveWithFullResponse: true,

      headers: {
        Authorization: `JWT ${user.access_token}`,
      },
    };

    rp(options)
      .then(response => {
        switch (response.statusCode) {
          case 200:
            store.dispatch({
              type: CareTeamConstants.CARETEAM_GET_BY_NEED_SUCCESS,
              data: response.body.data,
            });

            response.body.data.team.forEach(carerId =>
              store.dispatch(getCarerById(carerId)),
            );

            break;

          case 404:
            // No Care Needs, still a success
            store.dispatch({
              type: CareTeamConstants.CARETEAM_GET_BY_NEED_SUCCESS,
              data: [],
            });

            break;

          default:
            store.dispatch({
              type: CareTeamConstants.CARETEAM_GET_BY_NEED_FAILURE,
              error: response.body.message || 'Unknown Error',
            });

            break;
        }

        return null;
      })
      .catch(err => {
        store.dispatch({
          type: CareTeamConstants.CARETEAM_GET_BY_NEED_FAILURE,
          error: err,
        });
      });

    return true;
  },
};

export default CareTeamActions;
