import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import moment from 'moment';

import { Container, Col } from 'components/grid';
import {
  required,
  validEmail,
  emailOrPhone,
  validPhoneNumber,
} from 'validators';
import ButtonLink from 'components/buttons/ButtonLink';
import { ModuleHeader, ModuleText } from 'components/global/layout/Module';
import { TaxonomySelect } from 'components/forms/ConvenienceSelects';
import FormRow from 'components/forms/FormRow';
import StandardInput from 'components/forms/StandardInput';
import StandardRadioGroup from 'components/forms/StandardRadioGroup';
import StandardSelect from 'components/forms/StandardSelect';
import Form from 'components/forms/Form';

import Button from 'components/buttons/Button';

class ReferenceForm extends Component {
  static propTypes = {
    carerID: PropTypes.string.isRequired,
    editMode: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape(),
    isAdmin: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.shape({
      reference_relationships: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      reference_roles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      reference_time_known: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }).isRequired,
    workHistory: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  static defaultProps = {
    initialValues: null,
  };

  getWorkHistoryLabel = (workHistoryInstance) => {

    let label = `
      ${moment(workHistoryInstance.employment_start_date).format('MMMYY')}
      - ${
      workHistoryInstance.employment_end_date
        ? moment(workHistoryInstance.employment_end_date).format('MMMYY')
        : 'Today'
      } : ${workHistoryInstance.user_role}
    `;

    if (workHistoryInstance.organization) {
      label += `, ${workHistoryInstance.organization} (${workHistoryInstance.organization_town})`;
    }

    return label;
  };

  render() {
    const {
      carerID,
      editMode,
      initialValues,
      isAdmin,
      taxonomiesByClassification,
      workHistory,
    } = this.props;
    // destroyOnUnmount must be set to false until we can find out why it is
    // unmounting when initialized under some circumstances
    return (
      <Form
        name="referenceForm"
        values={initialValues}
        enableReinitialize={false}
        fields={[
          'email',
          'firstname',
          'lastname',
          'phone_number',
          'professional_reference',
          'reference_relationship',
          'reference_role',
          'time_known',
          'work_history_instance_id',
        ]}
        selectFields={[
          'professional_reference',
          'work_history_instance_id',
        ]}
        component={({ handleSubmit, pristine, currentValues }) => (
          <form id="referenceForm">
            <ModuleHeader>Reference Type</ModuleHeader>
            <Container>

              {!editMode && (
                <ModuleText>
                  Please select reference type you want to add.
                  You need two professional and two personal references.
                </ModuleText>
              )}

              <FormRow>
                <Col>
                  <Field
                    name="professional_reference"
                    component={StandardRadioGroup}
                    options={[
                      {
                        label: "Professional",
                        value: true,
                      },
                      {
                        label: "Personal",
                        value: false,
                      },
                  ]}
                  />
                </Col>
              </FormRow>

              {currentValues.professional_reference && (
                workHistory.length
                ? (
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardSelect}
                        name="work_history_instance_id"
                        type="text"
                        label="Related Employment"
                        validate={[required]}
                      >
                        <option value="">
                          Please Select
                        </option>
                        {workHistory
                          .filter(instance => instance.employment_type !== 'gapinemployment')
                          .map(instance => (
                            <option key={instance.id} value={instance.id}>
                              {this.getWorkHistoryLabel(instance)}
                            </option>
                          ))}
                      </Field>
                    </Col>

                    {
                      isAdmin &&
                      currentValues.work_history_instance_id && (
                      <Col xs={12} md={6}>
                        <ButtonLink
                          className="alt"
                          to={`/admin/users/${carerID}/work-history/${currentValues.work_history_instance_id}/`}
                          margin="10px 10px 0 0"
                          width="150px"
                        >
                          Go to Work History
                        </ButtonLink>
                      </Col>
                    )}

                  </FormRow>
                )
                : (
                  <ModuleText className="danger">
                    <strong>
                      Please submit your work history before submitting professional references.
                    </strong>
                  </ModuleText>
                )
              )}

            </Container>
            {(
                currentValues.professional_reference === false ||
                (
                  currentValues.professional_reference &&
                  (isAdmin || currentValues.work_history_instance_id)
                )
              ) && (
              <div>
                <ModuleHeader>Referee details</ModuleHeader>
                <Container>
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardInput}
                        name="firstname"
                        type="text"
                        label="Referee first name"
                        validate={[required]}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardInput}
                        name="lastname"
                        type="text"
                        label="Referee last name"
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    {currentValues.professional_reference === false
                      ? (
                        <Col xs={12} md={6}>
                          <Field
                            component={TaxonomySelect}
                            name="reference_relationship"
                            type="text"
                            label="Referee Relationship to You"
                            taxonomy={taxonomiesByClassification.reference_relationships}
                            validate={[required]}
                          />
                        </Col>
                      )
                      : (
                        <Col xs={12} md={6}>
                          <Field
                            component={TaxonomySelect}
                            name="reference_role"
                            type="text"
                            label="Referee Relationship to You"
                            taxonomy={taxonomiesByClassification.reference_roles}
                            validate={[required]}
                          />
                        </Col>
                      )
                    }
                    <Col xs={12} md={6}>
                      <Field
                        component={TaxonomySelect}
                        name="time_known"
                        type="text"
                        label="Time you have known this person"
                        taxonomy={taxonomiesByClassification.reference_time_known}
                        validate={[required]}
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardInput}
                        name="email"
                        type="text"
                        label="Referee email"
                        validate={[emailOrPhone, validEmail]}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardInput}
                        name="phone_number"
                        type="text"
                        label="Referee contact number"
                        validate={[emailOrPhone, validPhoneNumber]}
                      />
                    </Col>
                  </FormRow>

                  <br/>

                  <FormRow>
                    <Col xs={12}>
                      <Button
                        onClick={handleSubmit(data =>
                          this.props.onSubmit({ data }),
                        )}
                        disabled={pristine}
                        type="button"
                      >
                        Save
                      </Button>
                    </Col>
                  </FormRow>

                </Container>
              </div>
            )}
          </form>
        )}
      />
    );
  }
}

export default ReferenceForm;
