// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AdminNeed from 'components/admin/needs/AdminNeed';

import { getNeedByIdWithUser } from '_actions/needActions';
import { getUserById } from '_actions/userActions';

import { getNeedByID } from 'shared/selectors/needSelectors';
import { getUserForNeedByID } from 'shared/selectors/userSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { selectSchedulesWithShiftsByNeedID } from 'shared/selectors/scheduleShiftsSelectors';

import type {
  NeedType,
  TaxonomiesClassificationType,
  SchedulesType,
  UserType,
} from 'shared/types';

type Props = {
  customer: UserType,
  getUserById: (value: string) => mixed,
  getNeedByIdWithUser: (value: string) => mixed,
  getSchedulesWithScheduleShiftsByNeedID: (value: string) => mixed,
  getTaxonomyByClassification: (value: string) => mixed,
  need: NeedType,
  match: {
    isExact: boolean,
    params: {
      needID: string,
    },
    path: string,
    url: string,
  },
  schedules: SchedulesType,
  sendEmailVerification: string => void,
  taxonomiesByClassification: TaxonomiesClassificationType,
};

class AdminNeedContainer extends Component<Props> {
  static defaultProps = {
    customer: null,
    need: null,
    schedules: null,
    taxonomiesByClassification: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'account_manager',
      'lead_lost_reasons',
      'customer_lapsed_reasons',
      'need_sources',
      'lead_sources',
      'services',
      'condition',
      'hobbies',
      'pets',
      'gender_preference',
      'requested_care_type',
      'equipment',
      'symptoms',
      'smoker_preference',
      'start_date_accuracy',
    ];
  }

  componentDidMount() {
    // This is necessary because the account reducer for ACCOUNT_UPDATE_SUCCESS
    // Does not function correctly. This is the case for all account areas and needs
    // to be corrected and fully refactored to seperate out account and user actions
    this.props.getUserById(this.props.need.user_id);
  }

  render() {
    const {
      need,
      customer,
      schedules,
      taxonomiesByClassification,
      sendEmailVerification,
    } = this.props;

    if (!need || !customer || !schedules || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminNeed
        need={need}
        customer={customer}
        schedules={schedules}
        sendEmailVerification={sendEmailVerification}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const need = getNeedByID(state, ownProps.match.params.needID);
  const schedules = selectSchedulesWithShiftsByNeedID(
    state,
    ownProps.match.params.needID,
    true,
  );

  return {
    customer: getUserForNeedByID(state, ownProps.match.params.needID),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      AdminNeedContainer.TAXONOMY_CLASSIFICATIONS,
    ),
    need,
    schedules,
  };
};

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      getNeedByIdWithUser,
      getUserById,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminNeedContainer);
