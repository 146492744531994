import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUser } from 'shared/selectors/accountSelectors';
import { getCarerByID } from 'shared/selectors/carerSelectors';
import {
  selectReferencesByCarerID,
  selectReferencesSearch,
} from 'shared/selectors/referencesSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

import { getCarerById } from '_actions/carerActions';
import {
  deleteReference,
  getReferencesByCarerID,
  submitReferences,
  updateReference,
} from '_actions/referencesActions';

import CarerReferenceSummary from 'components/references/CarerReferenceSummary';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

class CarerReferenceSummaryContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      deleteReference: PropTypes.func.isRequired,
      getCarerById: PropTypes.func.isRequired,
      getReferencesByCarerID: PropTypes.func.isRequired,
      submitReferences: PropTypes.func.isRequired,
      updateReference: PropTypes.func.isRequired,
    }).isRequired,
    carer: PropTypes.shape(),
    references: PropTypes.arrayOf(PropTypes.shape()),
    taxonomiesByClassification: PropTypes.shape(),
    search: PropTypes.shape(),
    user: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    carer: null,
    references: null,
    search: null,
    taxonomiesByClassification: {},
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['reference_roles', 'reference_relationships'];
  }

  UNSAFE_componentWillMount() {
    this.props.actions.getCarerById(this.props.user.id);
    this.props.actions.getReferencesByCarerID(this.props.user.id);
  }

  deleteReference = referenceID => {
    this.props.actions.deleteReference(referenceID, this.props.user.id)
  };

  submitReferences = e => {
    e.preventDefault();
    this.props.actions.submitReferences(this.props.references);
  };

  render() {
    const { carer, references, taxonomiesByClassification, search, user } = this.props;
    const numRequiredReferences = 4;

    if (
      !references ||
      !taxonomiesByClassification ||
      !carer
    ) {
      return <LoadingPlaceholder />;
    }

    const isSubmitted =
      (user.references_submitted || null) &&
      user.references_submitted >= numRequiredReferences;

    const sortedReferences = [...references];
    sortedReferences.sort(
      (a, b) =>
        search.searchOrder.indexOf(a.id) - search.searchOrder.indexOf(b.id),
    );

    return (
      <CarerReferenceSummary
        referencesCompliant={!!(carer.profile_details.hi_compliance_fields || {}).references}
        deleteReference={this.deleteReference}
        isSubmitted={isSubmitted}
        numRequiredReferences={numRequiredReferences}
        references={sortedReferences}
        submitReferences={this.submitReferences}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

const mapStateToProps = state => {
  const user = getUser(state);
  return {
    carer: getCarerByID(state, user.id),
    user,
    references: selectReferencesByCarerID(state, user.id),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      CarerReferenceSummaryContainer.TAXONOMY_CLASSIFICATIONS,
    ),
    search: selectReferencesSearch(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCarerById,
      submitReferences,
      deleteReference,
      getReferencesByCarerID,
      updateReference,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerReferenceSummaryContainer);
