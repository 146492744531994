import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAllFranchise } from '_actions/franchiseActions';
import { adminCreateNeed, createNeed } from '_actions/needActions';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AdminNeedsForm from 'components/admin/needs/AdminNeedsForm';

import { getFranchises } from 'shared/selectors/franchiseSelectors';
import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';


class AdminNeedsFormContainer extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      adminCreateNeed: PropTypes.func.isRequired,
      createNeed: PropTypes.func.isRequired,
      getAllFranchise: PropTypes.func.isRequired,
      populateFranchiseWithID: PropTypes.func.isRequired,
    }).isRequired,
    archiveNeed: PropTypes.func,
    editMode: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    franchises: PropTypes.shape().isRequired,
    franchisesLoadingState: PropTypes.bool,
    initialValues: PropTypes.shape(),
    onUpdate: PropTypes.func,
    taxonomiesByClassification: PropTypes.shape().isRequired,
    unarchiveNeed: PropTypes.func,
  };

  static defaultProps = {
    archiveNeed: () => {},
    editMode: false,
    franchises: {},
    franchiseUsers: [],
    initialValues: {},
    onUpdate: () => {},
    taxonomiesByClassification: {},
    unarchiveNeed: () => {},
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'account_manager',
      'services',
      'lead_lost_reasons',
      'customer_lapsed_reasons',
      'lead_sources',
      'condition',
      'need_sources',
      'care_for',
      'gender_preference',
      'partner_source',
      'pets',
      'requested_care_duration',
      'requested_care_type',
      'smoker_preference',
    ];
  }

  UNSAFE_componentWillMount() {
    this.props.actions.getAllFranchise();
  }

  onSubmit = formData => {
    const { editMode, actions } = this.props;
    const customerData = {
      email: formData.contact_email,
      firstname: formData.contact_firstname,
      lastname: formData.contact_lastname,
      phone_number: formData.contact_phone_number || null,
      confirmed_tos: formData.confirmed_tos,
    };
    const needData = {
      admin_notes: formData.admin_notes,
      age: parseInt(formData.age, 10),
      carer_gender: formData.carer_gender || null,
      contact_number: formData.contact_phone_number,
      firstname: formData.firstname,
      lastname: formData.lastname,
      postcode: formData.postcode || null,
      story: formData.story || null,
      schedule_description: formData.schedule_description || null,
      schedule_end_date: formData.schedule_end_date || null,
      schedule_start_date: formData.schedule_start_date || null,
      smart_recruiter_id: formData.smart_recruiter_id,
    };

    if (formData.pets) {
      needData.pets = formData.pets;
    }

    if (formData.franchise_id) {
      needData.franchise_id = formData.franchise_id;
    }

    if (formData.schedule_description) {
      needData.schedule_description = formData.schedule_description;
    }

    if (formData.needs_car !== '') {
      needData.needs_car = formData.needs_car;
    }

    if (formData.needs_license !== '') {
      needData.needs_license = formData.needs_license;
    }

    if (editMode) {
      this.props.onUpdate({ customerData, needData });
    } else if (formData.franchise_id) {
      actions.createNeed(needData, "/admin/needs/");
    } else {
      actions.adminCreateNeed({ needData, customerData });
    }
  };

  render() {
    const {
      archiveNeed,
      franchises,
      franchisesLoadingState,
      taxonomiesByClassification,
      initialValues,
      formName,
      editMode,
      needID,
      unarchiveNeed,
    } = this.props;


    if (!taxonomiesByClassification || franchisesLoadingState ) {
      return <LoadingPlaceholder />;
    }

    var franchiseOptions = Object.values(franchises).map(function(franchise){
      return {value: franchise.name, taxon_id: franchise.id}
    });

    taxonomiesByClassification.franchises = franchiseOptions;

    return (
      <AdminNeedsForm
        archiveNeed={archiveNeed}
        editMode={editMode}
        formName={formName}
        needID={needID}
        onSubmit={this.onSubmit}
        taxonomiesByClassification={taxonomiesByClassification}
        initialValues={initialValues}
        unarchiveNeed={unarchiveNeed}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  formName: `AdminNeeds${props.editMode ? 'Edit' : 'Create'}Form`,
  franchises: getFranchises(state),
  franchisesLoadingState: loadingSelectorFactory('franchises', 'franchises')(state),
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    AdminNeedsFormContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      adminCreateNeed,
      createNeed,
      getAllFranchise,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminNeedsFormContainer);
