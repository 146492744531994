import PropTypes from 'prop-types';
import React, { Component } from 'react';

import toTitleCase from 'components/helpers/toTitleCase';

class Feature extends Component {
  render() {
    const active = this.props.active ? 'active' : 'inactive';
    const name = toTitleCase(this.props.name);
    const title = `${name} feature is ${active}. Click to ${
      active ? 'de' : ''
    }activate.`;

    return (
      <div
        role="button"
        className={`status ${active}`}
        onClick={this.props.toggleFeature}
        title={title}
        style={{ marginRight: '5px' }}
      >
        {name}: {active}
      </div>
    );
  }
}

Feature.propTypes = {
  active: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  toggleFeature: PropTypes.func.isRequired,
};

export default Feature;
