import { createSelector } from 'reselect';
import { getInvoiceByID } from 'shared/selectors/invoiceSelectors';

export const getTimesheets = state => state.timesheets;
export const getTimesheetByID = (state, id) => state.timesheets[id];
export const getTimesheetSearch = state => state.search.timesheets;

export const getAmends = state => state.amends;

export const getTimesheetsForInvoiceByID = createSelector(
  [getTimesheets, getInvoiceByID],
  (timesheets, invoice) => {
    if (!timesheets || !invoice) {
      return null;
    }
    const invoiceTimesheets = [];
    invoice.timesheet_ids.forEach(timesheetId => {
      const invoiceTimesheet = timesheets[timesheetId];
      if (invoiceTimesheet) {
        invoiceTimesheets.push(invoiceTimesheet);
      }
    });

    return invoiceTimesheets;
  },
);

export const getTimesheetResults = createSelector(
  [getTimesheets, getTimesheetSearch],
  (timesheets, search) => {
    if (!search || !search.searchOrder) {
      return [];
    }
    return search.searchOrder.map(id => timesheets[id]);
  },
);
