import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Paginator from 'components/global/Paginator';
import ChargeTable from 'components/charges/ChargeTable';

class CustomerChargesOverview extends Component {
  render() {
    const page =
      this.props.search &&
      this.props.search.pagination &&
      this.props.search.pagination.pages
        ? this.props.search.pagination.pages
        : 1;

    return (
      <section className="component-wrapper">
        <h1>Your Charges</h1>
        <div className="module boxed">
          <h2>All Charges</h2>
          <ChargeTable charges={this.props.charges} />
        </div>
        <Paginator
          location={this.props.location}
          currentPage={parseInt(this.props.currentPage, 10)}
          totalPages={page}
          path="/invoices/"
        />
      </section>
    );
  }
}

CustomerChargesOverview.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      page: PropTypes.number,
    }),
  }).isRequired,
  search: PropTypes.shape(),
  charges: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentPage: PropTypes.number.isRequired,
  pagination: PropTypes.shape(),
};

CustomerChargesOverview.defaultProps = {
  pagination: {},
  search: null,
};

export default CustomerChargesOverview;
