import React from 'react';

const PercentageInput = ({ id, name, value, onChange, placeholder, step }) => (
  <input
    id={id}
    name={name}
    value={(value || 0) * 100}
    onChange={e => onChange(parseInt(e.target.value, 10) / 100)}
    placeholder={placeholder}
    step={step}
    type="number"
  />
);

export default PercentageInput;
