import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { searchCharges } from '_actions/chargeActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import CustomerChargesOverview from 'components/charges/CustomerChargesOverview';

import { getUser } from 'shared/selectors/accountSelectors';
import {
  getChargeSearch,
  getChargeResults,
} from 'shared/selectors/chargeSelectors';

class CustomerChargesOverviewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.location.query.page || 1,
    };
  }

  UNSAFE_componentWillMount() {
    this.getCharges();
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newPage = props.location.query.page;

    if (newPage && newPage !== this.state.currentPage) {
      this.setState({ currentPage: newPage }, this.getCharges);
    }
  }

  getCharges = () => {
    this.props.searchCharges(
      {
        consumer_id: this.props.user.id,
        // filter out charges attached to timesheets
        timesheet_charge: false,
      },
      this.state.currentPage,
    );
  };

  render() {
    if (!this.props.charges) {
      return <LoadingPlaceholder />;
    }

    return (
      <CustomerChargesOverview
        location={this.props.location}
        search={this.props.search}
        charges={this.props.charges}
        currentPage={this.state.currentPage}
      />
    );
  }
}

CustomerChargesOverviewContainer.propTypes = {
  charges: PropTypes.arrayOf(PropTypes.shape()),
  location: PropTypes.shape().isRequired,
  search: PropTypes.shape(),
  searchCharges: PropTypes.func.isRequired,
  user: PropTypes.shape(),
};

CustomerChargesOverviewContainer.defaultProps = {
  charges: null,
  search: null,
  user: null,
};

function mapStateToProps(state) {
  return {
    charges: getChargeResults(state),
    search: getChargeSearch(state),
    user: getUser(state),
  };
}

const mapDispatchToProps = dispatch => ({
  searchCharges: bindActionCreators(searchCharges, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerChargesOverviewContainer);
