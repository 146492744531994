import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import InvoiceResults from 'components/admin/InvoiceResults';
import InvoiceSearchFilters from 'components/admin/InvoiceSearchFilters';

class AdminInvoiceSearch extends Component {
  render() {
    let results;

    if (!this.props.invoices) {
      results = <LoadingPlaceholder />;
    } else if (this.props.invoices.length) {
      results = (
        <InvoiceResults
          invoices={this.props.invoices}
          pagination={this.props.search.pagination}
          location={this.props.location}
        />
      );
    } else {
      results = <div className="no-results">No invoices found.</div>;
    }

    return (
      <div>
        <InvoiceSearchFilters
          filters={this.props.filters}
          location={this.props.location}
          presetFilters={this.props.presetFilters}
          updateFilters={this.props.updateFilters}
        />
        <div className="old-row">
          <div className="module component-wrapper">
            <h2>Invoices</h2>
            {results}
          </div>
        </div>
      </div>
    );
  }
}

AdminInvoiceSearch.propTypes = {
  search: PropTypes.shape(),
  invoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  location: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  presetFilters: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
};

AdminInvoiceSearch.defaultProps = {
  search: {},
};

export default AdminInvoiceSearch;
