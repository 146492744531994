import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import WeeklySummary from 'components/modules/WeeklySummary';

import { getSummariesByCategory } from 'shared/selectors/summarySelectors';

class WeeklySummaryContainer extends Component {
  constructor(...props) {
    super(...props);
    const configureWeek = function(offset, cumulative, title) {
      const week = moment().add(offset, 'weeks');
      return {
        isoweek: week.format('GGGG[W]WW'),

        // Monday
        start: week.isoWeekday(1),

        cumulative,
        title,
      };
    };

    this.state = {
      weeks: [
        configureWeek(-1, false, 'Last Week'),
        configureWeek(-2, false, 'Previous Week'),
        configureWeek(-3, false, 'Two Weeks Ago'),
        configureWeek(-4, true, 'Older'),
      ],

      summaries: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.state.weeks.forEach(week => {
      this.props.getSummary(week.isoweek, week.cumulative);
    });
  }

  render() {
    return (
      <WeeklySummary
        title={this.props.title}
        states={this.props.states}
        filterLink={this.props.filterLink}
        weeks={this.state.weeks}
        summaries={this.props.summaries}
      />
    );
  }
}

WeeklySummaryContainer.propTypes = {
  title: PropTypes.string.isRequired,
  states: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterLink: PropTypes.string,
  summaries: PropTypes.shape(),
  getSummary: PropTypes.func.isRequired,
};

WeeklySummaryContainer.defaultProps = {
  filterLink: '',
  summaries: {},
};

const mapStateToProps = (state, props) => ({
  summaries: getSummariesByCategory(state, props),
});

export default connect(mapStateToProps)(WeeklySummaryContainer);
