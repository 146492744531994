import ApiConstants from 'shared/constants/ApiConstants';
import WorkHistoryConstants from 'shared/constants/WorkHistoryConstants';

export function addWorkHistoryInstance(payload, redirect) {
  return {
    endpoint: '/work_history/',
    method: 'post',
    onError: { type: WorkHistoryConstants.WORK_HISTORY_ADD_FAILURE },
    onStart: { type: WorkHistoryConstants.WORK_HISTORY_ADD },
    onSuccess: {
      type: WorkHistoryConstants.WORK_HISTORY_ADD_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Work History added',
      },
    },
    payload,
    redirect,
    type: ApiConstants.API_REQUEST,
  };
}

export function updateWorkHistoryInstance(workHistoryInstanceID, data, redirect = null) {
  return {
    endpoint: `/work_history/${workHistoryInstanceID}/`,
    method: 'put',
    onError: { type: WorkHistoryConstants.WORK_HISTORY_UPDATE_FAILURE },
    onStart: { type: WorkHistoryConstants.WORK_HISTORY_UPDATE },
    onSuccess: {
      type: WorkHistoryConstants.WORK_HISTORY_UPDATE_SUCCESS,
    },
    payload: data,
    redirect,
    type: ApiConstants.API_REQUEST,
  };
}

export function deleteWorkHistoryInstance(workHistoryInstanceID, userID) {
  return {
    endpoint: `/work_history/${workHistoryInstanceID}/`,
    method: 'delete',
    onError: { type: WorkHistoryConstants.WORK_HISTORY_DELETE_FAILURE },
    onStart: { type: WorkHistoryConstants.WORK_HISTORY_DELETE },
    onSuccess: {
      type: WorkHistoryConstants.WORK_HISTORY_DELETE_SUCCESS,
      // Pass back payload to remove from store (no object returned by API)
      payload: { workHistoryInstanceID, userID },
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function getWorkHistoryInstanceByID(workHistoryInstanceID) {
  return {
    endpoint: `/work_history/${workHistoryInstanceID}/`,
    method: 'get',
    onError: {
      type: WorkHistoryConstants.WORK_HISTORY_GET_BY_ID_FAILURE,
    },
    onStart: { type: WorkHistoryConstants.WORK_HISTORY_GET_BY_ID },
    onSuccess: { type: WorkHistoryConstants.WORK_HISTORY_GET_BY_ID_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function getWorkHistoryByCarerID(carerID) {
  return {
    endpoint: '/work_history/find/',
    method: 'get',
    onError: {
      type: WorkHistoryConstants.WORK_HISTORY_GET_FOR_CARER_BY_ID_FAILURE,
    },
    onStart: { type: WorkHistoryConstants.WORK_HISTORY_GET_FOR_CARER_BY_ID },
    onSuccess: {
      carer_id: carerID,
      type: WorkHistoryConstants.WORK_HISTORY_GET_FOR_CARER_BY_ID_SUCCESS,
    },
    payload: {
      user_id: carerID,
      order_by_parameter: ['employment_end_date', 'employment_start_date'],
      order_by_direction: ['desc', 'desc'],
      order_by_nulls_first: ['True', 'False'],
      per_page: 50,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function searchWorkHistory(params, page) {
  const query = Object.assign({ page: page || 1 }, params);
  return {
    endpoint: '/work_history/find/',
    method: 'get',
    onError: { type: WorkHistoryConstants.WORK_HISTORY_SEARCH_FAILURE },
    onStart: { type: WorkHistoryConstants.WORK_HISTORY_SEARCH },
    onSuccess: { type: WorkHistoryConstants.WORK_HISTORY_SEARCH_SUCCESS },
    payload: query,
    type: ApiConstants.API_REQUEST,
  };
}

export function submitWorkHistory(workHistory) {
  return {
    workHistory,
    type: WorkHistoryConstants.WORK_HISTORY_SUBMIT,
  };
}
