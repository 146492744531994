import { changePassword } from '_actions/accountActions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AccountChangePassword from 'components/account/AccountChangePassword';
import { getUser } from 'shared/selectors/accountSelectors';
import type { UserType } from 'shared/types';

type Props = {
  changePassword: Object => void,
  user: UserType,
};

const AccountChangePasswordContainer = ({ changePassword, user }: Props) => {
  const updatePassword = data => {
    changePassword({
      userId: user.id,
      ...data,
    });
  };

  return (
    <AccountChangePassword email={user.email} updatePassword={updatePassword} />
  );
};

const mapStateToProps = state => ({
  user: getUser(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePassword,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountChangePasswordContainer);
