// @flow
import React from 'react';
// $FlowFixMe
import { Field } from 'redux-form';

import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import { Container, Col } from 'components/grid';
import Button from 'components/buttons/Button';
import StandardTextarea from 'components/forms/StandardTextarea';
import StandardSelect from 'components/forms/StandardSelect';

import { noteCategories } from 'shared/constants/NoteConstants';

type Props = {
  category?: string,
  placeholder?: string,
  saveNote: ({ message: string, category?: string }) => void,
};

const NotesForm = ({ category, placeholder, saveNote }: Props) => (
  <Form
    name="notes"
    fields={['message', 'category']}
    onSubmit={saveNote}
    component={({ handleSubmit, pristine }) => (
      <form onSubmit={handleSubmit}>
        <Container>
          <FormRow>
            <Col>
              <Field
                component={StandardTextarea}
                label="New Note"
                name="message"
                placeholder={placeholder}
              />
            </Col>
          </FormRow>
          {!category && (
            <FormRow>
              <Col>
                <Field
                  component={StandardSelect}
                  label="Category"
                  name="category"
                >
                  <option value="">Please choose</option>
                  {Object.keys(noteCategories).map(key => (
                    <option key={key} value={key}>
                      {noteCategories[key]}
                    </option>
                  ))}
                </Field>
              </Col>
            </FormRow>
          )}
          <FormRow>
            <Col>
              <Button disabled={pristine} type="submit">
                Save Note
              </Button>
            </Col>
          </FormRow>
        </Container>
      </form>
    )}
  />
);

export default NotesForm;
