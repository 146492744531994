import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const ignoredMixinMethods = [
  'getDefaultProps',
  'getInitialState',
  'componentWillMount',
  'componentWillUnmount',
  'componentDidMount',
  'blurValue',
  'changeValue',
  'keyUp',
];

class InputDEPRECATED extends Component {
  static contextTypes = {
    formsy: PropTypes.shape(),
  };

  constructor(props) {
    super(props);

    this.state = Object.assign(
      this.state || {},
      Formsy.Mixin.getInitialState.bind(this)(),
    );
    Object.getOwnPropertyNames(Formsy.Mixin).forEach(method => {
      if (typeof Formsy.Mixin[method] !== 'function') {
        return;
      }

      if (ignoredMixinMethods.indexOf(method) !== -1) {
        return;
      }
      this[method] = Formsy.Mixin[method].bind(this);
    });
  }

  UNSAFE_componentWillMount() {
    Formsy.Mixin.componentWillMount.bind(this)();
  }

  componentDidMount() {
    if (this.props.initialValue) {
      this.setValue(this.props.initialValue);
    }
  }

  componentWillUnmount() {
    Formsy.Mixin.componentWillUnmount.bind(this)();
  }

  blurValue = event => {
    let value = event.currentTarget.value;
    if (value === '') {
      value = null;
    }
    this.setValue(value);
  };

  changeValue = event => {
    if (this.getErrorMessage() != null) {
      this.setValue(event.currentTarget.value);
    } else if (this.isValidValue(event.target.value)) {
      this.setValue(event.target.value);
    } else {
      this.setState({
        _value: event.currentTarget.value,
        _isPristine: false,
      });
    }
  };

  keyUp = event => {
    if (event.keyCode === '13') {
      this.setValue(event.currentTarget.value);
    }
  };

  render() {
    const errorClass = this.showError() ? 'form-group has-error' : 'form-group';
    const className = this.showRequired() ? 'form-group' : errorClass;
    const errorMessage = this.getErrorMessage();
    return (
      <div className={className}>
        <label htmlFor={this.props.name}>{this.props.title}</label>
        <div>
          <input
            type={this.props.type}
            name={this.props.name}
            onBlur={this.blurValue}
            onChange={this.changeValue}
            defaultValue={this.props.initialValue}
            placeholder={this.props.placeholder}
          />
          <span className="validation-error">{errorMessage}</span>
        </div>
      </div>
    );
  }
}

InputDEPRECATED.propTypes = {
  initialValue: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

InputDEPRECATED.defaultProps = {
  initialValue: null,
  placeholder: '',
  name: '',
  title: '',
  validationError: '',
  validationErrors: {},
};

export default InputDEPRECATED;
