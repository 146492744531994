// @flow
/* eslint-disable react/no-array-index-key, radix */
import React, { Component } from 'react';
import StyledSelect from 'components/forms/StyledSelect';
import { Container, Row, Col } from 'components/grid';
import months from 'shared/constants/months';
import moment from 'moment';

type Props = {
  disabled: boolean,
  spacing: number,
  onChange: (newIsoDate: ?string) => void,
  value: string,
  name: string,
  className: string,
  past: boolean,
  granularity: 'day' | 'month',
  from: number,
  to: number,
};

type DateObj = {
  day: string | number,
  month: string | number,
  year: string | number,
};

class DateInput extends Component<Props> {
  static defaultProps = {
    spacing: 2,
    className: null,
    onChange: () => {},
    past: false,
    granularity: 'day',
    from: null,
    to: null,
  };

  getYears = (past: boolean, from: number, to: number) => {
    /* eslint-disable no-plusplus */
    const currentYear = new Date().getFullYear();
    let years = [];

    if (!past) {
      const yearFrom = from || currentYear;
      const yearTo = to || currentYear + 100;
      for (let i = yearFrom; i <= yearTo; i++) {
        years.push(i);
      }
    } else {
      const yearFrom = from || currentYear - 100;
      const yearTo = to || currentYear;
      for (let i = yearTo; i >= yearFrom; i--) {
        years.push(i);
      }
    }

    return years;
  };

  getDays = (noDays: number) => {
    let days = [];
    for (let x = 1; x <= noDays; x++) {
      days.push(x);
    }
    return days;
  };

  onYearChange = (e: Object) => {
    const year = e.target.value === 'YYYY' ? '' : e.target.value;
    const { month, day } = this.getSelectedDateParsed(year);
    const recalculatedDay =
      parseInt(month) === 1 && parseInt(day) === 29 && parseInt(year) % 4
        ? 28
        : day;
    const newIsoDate = this.dateToISOString({
      year,
      month,
      day: recalculatedDay,
    });
    this.props.onChange(newIsoDate);
  };

  onMonthChange = (e: Object) => {
    const month =
      e.target.value === 'MM' ? '' : this.convertToDoubleDigits(e.target.value);
    const { year, day } = this.getSelectedDateParsed(month);
    const newIsoDate = this.dateToISOString({
      year,
      month,
      day,
    });
    this.props.onChange(newIsoDate);
  };

  onDayChange = (e: Object) => {
    const day =
      e.target.value === 'DD' ? '' : this.convertToDoubleDigits(e.target.value);
    const { year, month } = this.getSelectedDateParsed(day);
    const newIsoDate = this.dateToISOString({ year, month, day });
    this.props.onChange(newIsoDate);
  };

  getDaysForMonth = (month: ?number | string, year: ?number | string) => {
    if (!month) {
      return 31;
    }
    const localYear = year || new Date().getFullYear();
    return moment(`${localYear}-${month}`, 'YYYY-MM').daysInMonth();
  };

  getSelectedDateParsed = (dateValue: ?number | ?string) => {
    const date = this.props.value;

    if (!date) {
      return { day: '', month: '', year: '' };
    }

    const dateComponents = date.split('T')[0].split('-');
    if (!dateComponents.length === 3) {
      return { day: '', month: '', year: '' };
    }

    let day = this.props.granularity !== 'day' ? '' : dateComponents[2];
    if (this.props.granularity !== 'day' && dateValue) {
      day = 1;
    }

    const month = dateComponents[1];
    const year = dateComponents[0];

    return { day, month, year };
  };

  dateToISOString = ({ year, month, day }: DateObj) => {
    if (!day && !month && !year) {
      return null;
    }
    return `${year}-${month}-${day}`;
  };

  convertToDoubleDigits = (value: string) => {
    return value.length === 1 ? `0${value}` : value;
  };

  render() {
    const {
      className,
      disabled,
      spacing,
      past,
      from,
      to,
      name,
      ...otherProps
    } = this.props;

    const selectedDate = this.getSelectedDateParsed();
    const selectedDay = parseInt(selectedDate.day) || selectedDate.day;
    const selectedMonth = parseInt(selectedDate.month) || selectedDate.month;
    const selectedYear = selectedDate.year;
    const noDays = this.getDaysForMonth(selectedMonth, selectedYear);

    const years = this.getYears(past, from, to);
    const days = this.getDays(noDays);

    return (
      <Container
        name={name}
        className={className}
        colGutter={spacing}
        outerGutter={0}
        {...otherProps}
      >
        <Row>
          {this.props.granularity === 'day' && (
            <Col xs={3} style={{ display: 'flex' }}>
              <StyledSelect
                className={this.props.className}
                disabled={disabled}
                onChange={this.onDayChange}
                value={selectedDay}
                name={`${name}-day`}
                style={{ width: 'auto', flex: 1 }}
              >
                <option value="DD">DD</option>
                {days.map(day => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </StyledSelect>
            </Col>
          )}
          <Col
            xs={this.props.granularity === 'day' ? 4 : 6}
            style={{ display: 'flex' }}
          >
            <StyledSelect
              disabled={disabled}
              className={this.props.className}
              onChange={this.onMonthChange}
              value={selectedMonth}
              name={`${name}-month`}
              style={{ width: 'auto', flex: 1 }}
            >
              <option value="MM">MM</option>
              {months.map(month => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </StyledSelect>
          </Col>
          <Col
            xs={this.props.granularity === 'day' ? 5 : 6}
            style={{ display: 'flex' }}
          >
            <StyledSelect
              disabled={disabled}
              className={this.props.className}
              onChange={this.onYearChange}
              value={selectedYear}
              name={`${name}-year`}
              style={{ width: 'auto', flex: 1 }}
            >
              <option value="YYYY">YYYY</option>
              {years.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </StyledSelect>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DateInput;
