// @flow
import React from 'react';
// $FlowFixMe
import { Field } from 'redux-form';
import styled from 'styled-components';

// $FlowFixMe
import { trim } from 'normalizers';
// $FlowFixMe
import { matchesPassword } from 'validators';

import { Container, Col, Row } from 'components/grid';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import StandardInput from 'components/forms/StandardInput';
import StyledLinkLogo from 'components/global/StyledLinkLogo';
import Button from 'components/buttons/Button';
import ValidationError from 'components/forms/ValidationError';

import { scrollToTop } from 'components/helpers/domHelpers';

const ValidationErrorContainer = styled(ValidationError)`
  position: relative;
  top: -50px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.small}px) {
    position: absolute;
    top: -86px;
  }
`;

type Props = {
  resetPassword: () => void,
};

function AccountResetPasswordForm({ resetPassword }: Props) {
  return (
    <Form
      name="resetPassword"
      onSubmit={resetPassword}
      onSubmitFail={scrollToTop}
      component={({ handleSubmit, error, pristine }) => (
        <>
          {error && (
            <ValidationErrorContainer>{error}</ValidationErrorContainer>
          )}
          <form id="reset-password-form" onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col xs={12} md={11}>
                  <StyledLinkLogo />
                </Col>
              </Row>
              <FormRow>
                <Col xs={12}>
                  <Field
                    name="password"
                    label="Create Password"
                    component={StandardInput}
                    type="password"
                    normalize={trim}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col xs={12}>
                  <Field
                    name="confirm_password"
                    label="Confirm Password"
                    component={StandardInput}
                    type="password"
                    validate={[matchesPassword]}
                    normalize={trim}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <Button type="submit" disabled={pristine} fullWidth>
                    Reset
                  </Button>
                </Col>
              </FormRow>
            </Container>
          </form>
        </>
      )}
    />
  );
}

export default AccountResetPasswordForm;
