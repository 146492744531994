import PropTypes from 'prop-types';
import React, { Component } from 'react';

import InputWrapper from 'components/forms/InputWrapper';
import StyledLabel from 'components/forms/StyledLabel';
import CheckboxWithChildren from 'components/forms/CheckboxWithChildren';

import { childrenTaxonomyClassifications } from 'components/helpers/taxonomyHelpers';

class StandardCheckboxWithChildren extends Component {
  static propTypes = {
    input: PropTypes.shape().isRequired,
    label: PropTypes.string,
    options: PropTypes.shape().isRequired,
    meta: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    label: '',
  };

  valuesToSelectedValues(values) {
    const childTaxonomy = childrenTaxonomyClassifications(this.props.options);
    if (!values) return [];
    const selectedValues = values.reduce((arr, obj) => {
      let allValues = [];
      arr.push(obj.taxon_id);

      if (obj.children && Object.keys(obj.children).length !== 0) {
        const childrenValues = obj.children[childTaxonomy].map(child => {
          return `${obj.taxon_id}_${child}`;
        });
        allValues = arr.concat(childrenValues);
      }

      return arr.concat(allValues);
    }, []);

    return [...new Set(selectedValues)];
  }

  render() {
    const { help, input, label, options, meta } = this.props;
    const { touched, error } = meta;

    return (
      <InputWrapper {...{ help, meta }}>
        {label && <StyledLabel error={touched && error}>{label}</StyledLabel>}
        <CheckboxWithChildren
          className={touched && error ? 'error' : ''}
          options={options}
          childrenTaxonomyClassifications={childrenTaxonomyClassifications(
            options,
          )}
          currentSelected={this.valuesToSelectedValues(input.value)}
          {...input}
        />
      </InputWrapper>
    );
  }
}

export default StandardCheckboxWithChildren;
