import React from 'react';
import PropTypes from 'prop-types';

import { SCJobEmail, SC_NAME } from 'components/global/ContactInfo';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import { Container, Row, Col } from 'components/grid';

const description = {
  unresponsive: (
    <p>
      We noticed you haven&#39;t been responding to our messages, so have
      labelled your application to join {SC_NAME} as &#39;on hold&#39;
      <br />
      <br />
      If you want to reopen your application or have any questions about the
      application process, please do get in touch with us at <SCJobEmail/>.
    </p>
  ),
  onhold: (
    <p>
      Thank you for your application to {SC_NAME}.
      <br />
      <br />
      Your application is currently on hold.
      <br />
      <br />
      If you have any questions please contact us at <SCJobEmail/>.
    </p>
  ),
  rejected: (
    <p>
      Thank you for your application to {SC_NAME}. Unfortunately we have
      decided to not progress your application at this point.
      <br />
      <br />
      If you have any questions please contact us at <SCJobEmail/>.
    </p>
  ),
  failedtrustidcheck: (
    <p>
      We have run your background check, but your details did not match on any
      of the databases that they check.
      <br />
      <br />
      We still think you'll be a great carer though, so would love you to try
      again! The easiest way to get onto a government database is to register to
      vote. More information about how to do this can be found here.
      <br />
      <br />
      Once you have registered to vote please let us know at <SCJobEmail/> so that we
      can re-run your check.
    </p>
  ),
};

const CarerOnboardingStatusNotification = ({ carer }) => (
  <Module>
    <ModuleHeader>Hi {carer.firstname}</ModuleHeader>
    <Container>
      <Row>
        <Col>{description[carer.profile_details.fountain_state]}</Col>
      </Row>
    </Container>
  </Module>
);

CarerOnboardingStatusNotification.propTypes = {
  carer: PropTypes.shape().isRequired,
};

export default CarerOnboardingStatusNotification;
