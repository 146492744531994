import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AdminNeedSearchContainer from 'containers/admin/AdminNeedSearchContainer';

class AdminNeedsOverview extends Component {
  render() {
    return (
      <div className="component-wrapper wide">
        <h1>Needs Overview</h1>
        <Link to="/admin/needs/new/">Add new need</Link>
        <AdminNeedSearchContainer location={this.props.location} />
      </div>
    );
  }
}

AdminNeedsOverview.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default AdminNeedsOverview;
