import styled from 'styled-components';

const StyledSelect = styled.select`
  appearance: none;
  background-color: ${props => props.theme.colors.lightestGrey};
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"><g transform="translate(8.131728, 2.131728) rotate(-45.000000) translate(-8.131728, -2.131728) translate(2.631728, -3.868272)" stroke="%234A4A4A" stroke-width="1.5"><path d="M0.75,0.6 L0.75,10.2"></path><path d="M10.35,10.2496938 L0.75,10.2496938"></path></g></g></svg>');
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 16px 100%;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.colors.uiGrey};
  color: ${props => props.theme.colors.formText};
  font-family: ${props => props.theme.typography.fontStack};
  font-size: ${props => props.fontSize || '18px'};
  padding: ${props => props.padding || '15px 35px 15px 10px'};
  width: 100%;

  &.error {
    border-color: ${props => props.theme.colors.warningRed};
  }

  &:focus {
    background-color: white;
  }

  &[disabled],
  &[readonly] {
    background-color: ${props => props.theme.colors.uiGrey};
  }
`;

export default StyledSelect;
