import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { submitBid } from '_actions/bidActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { getRateLimits } from '_actions/rateActions';
import ApplicationForm from 'components/bids/ApplicationForm';

import { getUser } from 'shared/selectors/accountSelectors';

import { shortWeekdays } from 'shared/helpers/scheduleHelpers';

const ApplicationFormContainer = ({
  admin,
  bid,
  closePortal,
  jobOffer,
  schedule,
  submitBid,
}) => {
  const onSubmitBid = data => {
    const applied_shifts = [];

    Object.entries(schedule.shifts).forEach(([week, weekSelection]) => {
      Object.entries(weekSelection)
        .sort(([dayA], [dayB]) =>
          shortWeekdays.indexOf(dayA) < shortWeekdays.indexOf(dayB) ? -1 : 1,
        )
        .forEach(([dayKey, shifts]) => {
            shifts.forEach(shift => {
              applied_shifts.push(shift.id)
            });
        });
    });

    const submission = {
      bidID: bid.id,
      description: data.description,
      applied_shifts,
    };

    if (admin) {
      submission.admin_description = data.admin_description;
      submission.state = 'proposed';
    }

    submitBid(submission);
    closePortal && closePortal();
  };

  if (!bid || !jobOffer || !schedule || !schedule.shifts) {
    return <LoadingPlaceholder />;
  }

  const initialValues = {
    description: bid.description || '',
  };

  return (
    <ApplicationForm
      admin={admin}
      initialValues={initialValues}
      submitBid={onSubmitBid}
    />
  );
};

function mapStateToProps(state, props) {
  return {
    user: getUser(state),
    schedule: props.schedule,
  };
}

function mapDispatchToProps(dispath) {
  return bindActionCreators(
    {
      getRateLimits,
      submitBid,
    },
    dispath,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationFormContainer);
