import CurrencyInput from 'components/forms/CurrencyInput';
import FormRow from 'components/forms/FormRow';
import Input from 'components/forms/Input';
import StyledLabel from 'components/forms/StyledLabel';
import StyledSelect from 'components/forms/StyledSelect';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import Button from 'components/buttons/Button';
import ShiftSelect from 'components/modules/ShiftSelect';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Container, Col, Row } from 'components/grid';

const REFUND_TYPES = {
  'Full refund': 'full_refund',
  'Give full commission to carer': 'refund_fee_to_carer',
  'Refund insurance payment to carer': 'refund_insurance_to_carer',
  'Refund full commission to customer': 'refund_fee_to_customer',
  'Refund expense': 'refund_customer_from_carer',
  'Refund shift': null, // not a preset
  'Refund customer from SuperCarers': 'refund_customer_from_application',
  'Refund carer from SuperCarers': 'refund_carer_from_application',
};

class AdminCreateRefundForm extends Component {
  static propTypes = {
    createRefund: PropTypes.func.isRequired,
  };

  state = {
    refund: {},
  };

  updateRefundType = e => {
    const refundType = e.target.value;
    this.setState({ refundType, refund: { preset: REFUND_TYPES[refundType] } });
  };

  updateValue = (name, value) => {
    const refund = this.state.refund;
    refund[name] = value;
    this.setState({ refund });
  };

  createRefund = () => {
    this.props.createRefund(this.state.refund);
    this.setState({ refund: {} });
  };

  render() {
    const { refund } = this.state;

    return (
      <Module>
        <ModuleHeader>Create Refund</ModuleHeader>
        <Container>
          <FormRow>
            <Col>
              <StyledLabel htmlFor="type">Type of refund</StyledLabel>
              <StyledSelect
                name="type"
                value={this.state.refundType}
                onChange={this.updateRefundType}
              >
                <option key="none" value="">
                  Please select
                </option>
                {Object.keys(REFUND_TYPES).map(refundType => (
                  <option key={refundType} value={refundType}>
                    {refundType}
                  </option>
                ))}
              </StyledSelect>
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <StyledLabel htmlFor="description">Description</StyledLabel>
              <Input
                name="description"
                defaultValue={refund.description}
                onChange={this.updateValue}
                value={refund.description}
              />
            </Col>
          </FormRow>
          {this.state.refundType === 'Refund shift' && (
            <div>
              <Row>
                <Col>
                  Please enter the shift type and hours. This is for reporting
                  purposes only, it has no effect on the money transferred.
                </Col>
              </Row>
              <FormRow>
                <Col xs={12} md={6}>
                  <StyledLabel htmlFor="shift_type">Shift type</StyledLabel>
                  <ShiftSelect
                    name="shift_type"
                    value={refund.shift_type}
                    shiftTypes={
                      this.props.taxonomiesByClassification.shift_type
                    }
                    onChange={e =>
                      this.updateValue('shift_type', e.target.value)
                    }
                  />
                </Col>
                {!['livein', 'half_livein'].includes(refund.shift_type) && (
                  <Col xs={12} md={6}>
                    <StyledLabel htmlFor="hours">Hours</StyledLabel>
                    <Input
                      type="number"
                      step="0.01"
                      name="hours"
                      onChange={this.updateValue}
                      value={refund.hours}
                    />
                  </Col>
                )}
              </FormRow>
              <Row>
                <Col>
                  This refund is in two parts. The customer is refunded the full
                  amount from the carer. The carer is then refunded from
                  SuperCarers any commission they may be due.
                </Col>
              </Row>
              <FormRow>
                <Col xs={12} md={6}>
                  <StyledLabel htmlFor="amount">Customer receives</StyledLabel>
                  <CurrencyInput
                    name="amount"
                    onChange={this.updateValue}
                    value={refund.amount}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <StyledLabel htmlFor="fee_amount">Carer receives</StyledLabel>
                  <CurrencyInput
                    name="fee_amount"
                    onChange={this.updateValue}
                    value={refund.fee_amount}
                  />
                </Col>
              </FormRow>
            </div>
          )}
          {[
            'Refund expense',
            'Refund customer from SuperCarers',
            'Refund carer from SuperCarers',
          ].includes(this.state.refundType) && (
            <FormRow>
              <Col xs={12} md={6}>
                <StyledLabel htmlFor="amount">Amount</StyledLabel>
                <CurrencyInput
                  name="amount"
                  onChange={this.updateValue}
                  value={refund.amount}
                />
              </Col>
            </FormRow>
          )}
          <FormRow>
            <Col>
              <Button onClick={this.createRefund}>Create Refund</Button>
            </Col>
          </FormRow>
        </Container>
      </Module>
    );
  }
}

export default AdminCreateRefundForm;
