import PropTypes from 'prop-types';
import React from 'react';

import StyledSelect from 'components/forms/StyledSelect';

const ShiftSelect = ({ name, value, onChange, shiftTypes }) => (
  <StyledSelect name={name} value={value} onChange={onChange}>
    <option value="">Select shift type&hellip;</option>
    {shiftTypes.map(({ taxon_id, value }) => (
      <option key={taxon_id} value={taxon_id}>
        {value}
      </option>
    ))}
    <option value="exceptional">Exceptional Shift</option>
  </StyledSelect>
);

ShiftSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ShiftSelect.defaultProps = {
  value: '',
};

export default ShiftSelect;
