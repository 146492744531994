import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Module, { BorderedHeader } from 'components/global/layout/Module';
import ReferenceFormContainer from 'containers/references/ReferenceFormContainer';

class ReferenceAdd extends Component {
  static propTypes = {
    carerID: PropTypes.string.isRequired,
    initialValues: PropTypes.shape(),
    isAdmin: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    ready: PropTypes.bool.isRequired,
    workHistory: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    initialValues: null,
  };

  render() {
    const { onSubmit, initialValues } = this.props;
    return (
      <section>
        <Module>
          <BorderedHeader>
            Add Reference
          </BorderedHeader>
          <ReferenceFormContainer
            carerID={this.props.carerID}
            initialValues={initialValues}
            isAdmin={this.props.isAdmin}
            onSubmit={onSubmit}
            ready={this.props.ready}
            workHistory={this.props.workHistory}
          />
        </Module>
      </section>
    );
  }
}

export default ReferenceAdd;
