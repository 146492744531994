import ApiConstants from 'shared/constants/ApiConstants';
import PaymentConstants from 'shared/constants/PaymentConstants';
import { refreshAccount } from '_actions/accountActions';

export function createPaymentMethod(paymentMethod, userId, token) {
  const data = {
    payment_type: paymentMethod,
    user_id: userId,
    stripe_token: token.id,
  };
  return {
    endpoint: '/payment/method/',
    method: 'post',
    onError: { type: PaymentConstants.PAYMENTS_CREATE_PAYMENT_METHOD_FAILURE },
    onStart: { type: PaymentConstants.PAYMENTS_CREATE_PAYMENT_METHOD },
    onSuccess: {
      type: PaymentConstants.PAYMENTS_CREATE_PAYMENT_METHOD_SUCCESS,
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function removePaymentMethod(paymentId) {
  return {
    endpoint: `/payment/method/stripe/${paymentId}/`,
    method: 'delete',
    onError: { type: PaymentConstants.PAYMENTS_DELETE_PAYMENT_METHOD_FAILURE },
    onStart: { type: PaymentConstants.PAYMENTS_DELETE_PAYMENT_METHOD },
    onSuccess: {
      type: PaymentConstants.PAYMENTS_DELETE_PAYMENT_METHOD_SUCCESS,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function getUserPaymentMethods(userId) {
  return {
    endpoint: `/payment/method/find/${userId}/`,
    method: 'get',
    onError: { type: PaymentConstants.PAYMENTS_GET_USER_METHODS_FAILURE },
    onStart: { type: PaymentConstants.PAYMENTS_GET_USER_METHODS },
    onSuccess: { type: PaymentConstants.PAYMENTS_GET_USER_METHODS_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function connectStripe(code) {
  return {
    endpoint: `/payment/provider/stripe/connected/${code}/`,
    method: 'post',
    onError: { type: PaymentConstants.PAYMENTS_CONNECT_STRIPE_FAILURE },
    onStart: { type: PaymentConstants.PAYMENTS_CONNECT_STRIPE },
    onSuccess: { type: PaymentConstants.PAYMENTS_CONNECT_STRIPE_SUCCESS },
    payload: {},
    type: ApiConstants.API_REQUEST,
  };
}

export function connectStripeAndRefresh(code, userId) {
  return {
    onUpdate: connectStripe(code),
    onRefresh: refreshAccount(userId, '/home/'),
    type: ApiConstants.API_REQUEST_AND_REFRESH,
  };
}

export function addPaymentMethod(clientSecret, cardElement, formData, stripe) {
  return {
    type: PaymentConstants.PAYMENTS_ADD_PAYMENT,
    clientSecret,
    cardElement,
    formData,
    stripe,
    redirect: '/home/',
    onSuccess: {
      type: PaymentConstants.PAYMENTS_ADD_PAYMENT_SUCCESS,
      toast: {
        type: 'success',
        public: true,
        title: 'Payment Method Added',
      },
    },
    onError: {
      type: PaymentConstants.PAYMENTS_ADD_PAYMENT_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title:
          'Failed to add card - please check details and try again or contact Home Instead Live-in Care',
      },
    },
    systemError: {
      type: PaymentConstants.PAYMENTS_ADD_PAYMENT_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title:
          'An unexpected error occurred. Please contact the Home Instead Live-in Care team.',
      },
    },
  };
}

export function getSetupIntent(user_id) {
  return {
    endpoint: '/setup/intent/',
    method: 'post',
    onError: { type: PaymentConstants.PAYMENTS_GET_SETUP_INTENT_FAILURE },
    onStart: { type: PaymentConstants.PAYMENTS_GET_SETUP_INTENT },
    onSuccess: {
      type: PaymentConstants.PAYMENTS_GET_SETUP_INTENT_SUCCESS,
    },
    payload: { user_id },
    type: ApiConstants.API_REQUEST,
  };
}

export function authenticatePaymentViaStripe(
  clientSecret,
  paymentMethodId,
  stripe,
) {
  return {
    type: PaymentConstants.PAYMENTS_AUTHENTICATE_PAYMENT,
    clientSecret,
    paymentMethodId,
    stripe,
    redirect: '/home/',
    onSuccess: {
      type: PaymentConstants.PAYMENTS_AUTHENTICATE_PAYMENT_SUCCESS,
      toast: {
        type: 'success',
        public: true,
        title: 'Authentication successful. Payment made.',
      },
    },
    onError: {
      type: PaymentConstants.PAYMENTS_AUTHENTICATE_PAYMENT_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title:
          'Failed to authenticate - please check details and try again or contact Home Instead Live-in Care',
      },
    },
    systemError: {
      type: PaymentConstants.PAYMENTS_AUTHENTICATE_PAYMENT_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title:
          'An unexpected error occurred. Please contact the Home Instead Live-in Care team.',
      },
    },
  };
}

export function getPaymentIntent(charge_id, timesheet_id) {
  return {
    endpoint: `/payment/intent/by/charge/${charge_id}/`,
    method: 'get',
    onError: { type: PaymentConstants.PAYMENTS_GET_PAYMENT_INTENT_FAILURE },
    onStart: { type: PaymentConstants.PAYMENTS_GET_PAYMENT_INTENT },
    onSuccess: { type: PaymentConstants.PAYMENTS_GET_PAYMENT_INTENT_SUCCESS },
    otherProps: {
      timesheet_id,
    },
    type: ApiConstants.API_REQUEST,
  };
}
