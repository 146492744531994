import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addWorkHistoryInstance } from '_actions/workHistoryActions';
import { getUser, isAdmin } from 'shared/selectors/accountSelectors';
import moment from 'moment';

import CarerWorkHistoryAdd from 'components/carers/CarerWorkHistoryAdd';

class CarerWorkHistoryAddContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      addWorkHistoryInstance: PropTypes.func.isRequired,
    }).isRequired,
    isAdmin:PropTypes.bool.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        userID: PropTypes.string,
      }),
    }),
  };

  initialValues = {
    employment_start_date: moment().format('YYYY-MM-DD'),
    employment_end_date: moment().format('YYYY-MM-DD'),
    organization_country: 'gbr',
  };

  handleSubmit = (data, addMore) => {
    let redirect;
    let userID;

    if (this.props.isAdmin) {
      userID = this.props.match.params.userID;
      redirect = (addMore
        ? response => `/admin/users/${userID}/work-history/${response.data.id}/`
        : `/admin/users/${userID}/#work-history`
      );
    } else {
      userID = this.props.user.id
      redirect = addMore
        ? response => `/work-history/${response.data.id}/`
        : '/work-history/summary/';
    }

    this.props.actions.addWorkHistoryInstance(
      { ...data, user_id: userID },
      redirect,
    );
  };

  render() {
    return (
      <CarerWorkHistoryAdd
        onSubmit={this.handleSubmit}
        initialValues={this.initialValues}
      />
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: isAdmin(state),
  user: getUser(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addWorkHistoryInstance,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarerWorkHistoryAddContainer);
