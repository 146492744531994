import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import StandardTable from 'components/global/layout/StandardTable';
import TimesheetTableRow from 'components/timesheets/TimesheetTableRow';

const NoResults = styled.div`
  text-align: center;
`;

class TimesheetTable extends Component {
  render() {
    const { timesheets } = this.props;

    if (!timesheets || (timesheets && timesheets.length === 0)) {
      return <NoResults>No timesheets</NoResults>;
    }

    return (
      <StandardTable className="timesheets">
        <thead>
          <tr>
            <th>Week Commencing</th>
            <th>Client</th>
            <th>Time</th>
            <th>Amount</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {timesheets.map(timesheet => (
            <TimesheetTableRow key={timesheet.id} timesheet={timesheet} />
          ))}
        </tbody>
      </StandardTable>
    );
  }
}

TimesheetTable.propTypes = {
  timesheets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default TimesheetTable;
