import BidConstants from 'shared/constants/BidConstants';
import JobOfferConstants from 'shared/constants/JobOfferConstants';
import {
  addObjects,
  setLoadingState,
  addObjectsSetLoadingState,
  updateObject,
} from 'shared/reducers/reducerHelpers';
import { normaliseAdminFilters } from 'shared/reducers/jobOffersHelpers';

const initalState = {
  loading: {
    jobOffers: false,
  },
};

const updateJobOffer = (state, action) => {
  const newJobOfferData = normaliseAdminFilters(action.data);
  const newJob = updateObject(state[newJobOfferData.id], newJobOfferData);
  return updateObject(state, {
    [newJobOfferData.id]: newJob,
  });
};

function jobOffers(state = initalState, action) {
  switch (action.type) {
    case JobOfferConstants.JOB_OFFER_GET_BY_ID:
    case JobOfferConstants.JOB_OFFER_SEARCH:
      return setLoadingState(state, 'jobOffers', true);

    case JobOfferConstants.JOB_OFFER_CREATE_SUCCESS:
    case JobOfferConstants.JOB_OFFER_UPDATE_SUCCESS:
    case JobOfferConstants.JOB_OFFER_GET_BY_REFERENCE_SUCCESS:
    case JobOfferConstants.JOB_OFFER_GET_BY_ID_SUCCESS:
      return setLoadingState(updateJobOffer(state, action), 'jobOffers', false);

    case JobOfferConstants.JOB_OFFER_SEARCH_SUCCESS:
      const newJobOffersData = action.data.results.map(jobOffer =>
        normaliseAdminFilters(jobOffer),
      );
      const newJobOffersState = {
        ...action,
        data: { results: newJobOffersData },
      };
      return addObjectsSetLoadingState(
        state,
        newJobOffersState,
        'jobOffers',
        false,
      );

    case JobOfferConstants.JOB_OFFER_GET_BY_ID_FAILURE:
    case JobOfferConstants.JOB_OFFER_FAILURE:
      return setLoadingState(state, 'jobOffers', false);

    case BidConstants.SEARCH_BIDS_WITH_JOB_OFFERS_AND_SCHEDULES_AND_SHIFTS_SUCCESS:
      return addObjects(state, action);

    case JobOfferConstants.JOB_OFFER_GET_MATCH_TOTAL_SUCCESS:
      const job = updateObject(state[action.jobOfferID], {
        matchTotal: action.data.total,
      });
      return updateObject(state, {
        [action.jobOfferID]: job,
      });

    case JobOfferConstants.JOB_OFFER_GET_BID_SUMMARY_SUCCESS:
      const newerJob = updateObject(state[action.jobOfferID], {
        bidSummary: action.data,
      });
      return updateObject(state, {
        [action.jobOfferID]: newerJob,
      });

    default:
      return state;
  }
}

export default jobOffers;
