// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import TabbedComponents from 'components/global/layout/TabbedComponents';
import AdminUserReferenceSummaryContainer from 'containers/references/AdminUserReferenceSummaryContainer';
import AdminUserPersonalContainer from 'containers/users/AdminUserPersonalContainer';
import AdminUserAccountContainer from 'containers/users/AdminUserAccountContainer';
import AdminUserComplianceContainer from 'containers/users/AdminUserComplianceContainer';
import AdminUserOnboardingContainer from 'containers/users/AdminUserOnboardingContainer';
import AdminUserWorkHistorySummaryContainer from 'containers/users/AdminUserWorkHistorySummaryContainer';
import AdminUserDiscountsContainer from 'containers/users/AdminUserDiscountsContainer';
import AdminUserTeamsContainer from 'containers/users/AdminUserTeamsContainer';
import AdminUserRatesContainer from 'containers/users/AdminUserRatesContainer';
import AdminUserShiftsContainer from 'containers/users/AdminUserShiftsContainer';
import AdminUserTestimonialsContainer from 'containers/users/AdminUserTestimonialsContainer';
import NotesContainer from 'containers/notes/NotesContainer';

import { getUserById } from '_actions/userActions';
import { getLiveProfileByCarerID } from 'shared/selectors/carerSelectors';
import { getCarerProfileByCarerId } from '_actions/carerActions';
import { selectUserByID } from 'shared/selectors/userSelectors';
import {
  getImpersonatorLoadingState,
  isAgent,
  isRecruiter,
} from 'shared/selectors/accountSelectors';

import type { MatchType, ProfileType, UserType } from 'shared/types';

type Props = {
  getCarerProfileByCarerId: (string, {}) => {},
  getUserById: string => {},
  isAgent: boolean,
  isRecruiter: boolean,
  loadingImpersonator: boolean,
  match: MatchType,
  profile: ProfileType,
  user: UserType,
};

function AdminUserContainer({
  getCarerProfileByCarerId,
  getUserById,
  isAgent,
  isRecruiter,
  loadingImpersonator,
  match,
  profile = null,
  user = null,
}: Props) {
  const getProfile = user => {
    if (user && user.roles.includes('Carer')) {
      getCarerProfileByCarerId(user.id, {
        live_status: 'live',
      });
    }
  };

  useEffect(() => {
    getUserById(match.params.userID);
  }, [match.params.userID]);

  useEffect(() => {
    getProfile(user);
  }, [user && user.id]);

  if (!user || (user.roles.includes('Carer') && !profile)) {
    return <LoadingPlaceholder />;
  }

  if (isAgent) {
    const newNeeds = user.care_needs.filter(need => need.state === 'new');
    if (!newNeeds.length) {
      return <Redirect to="/unauthorised/" />;
    }
  }

  return (
    <TabbedComponents>
      <AdminUserPersonalContainer
        name="Personal"
        default
        user={user}
        profile={profile}
      />
      {!isAgent && (
        <AdminUserAccountContainer
          loadingImpersonator={loadingImpersonator}
          name="Account"
          user={user}
        />
      )}
      {user.roles.includes('Carer') && !isAgent && (
        <AdminUserOnboardingContainer
          name="Onboarding"
          user={user}
          profile={profile}
        />
      )}
      {user.roles.includes('Carer') && !isAgent && (
        <AdminUserComplianceContainer
          name="Compliance"
          profile={profile}
        />
      )}
      {user.roles.includes('Carer') && !isAgent && (
        <AdminUserReferenceSummaryContainer
          name="References"
          user={user}
          profile={profile}
        />
      )}
      {user.roles.includes('Carer') && !isAgent && (
        <AdminUserWorkHistorySummaryContainer
          name="Work History"
          user={user}
          profile={profile}
        />
      )}
      {user.roles.includes('Customer') && !isAgent && (
        <AdminUserDiscountsContainer name="Discounts" user={user} />
      )}
      {user.roles.includes('Carer') && !isAgent && !isRecruiter && (
        <AdminUserTeamsContainer name="Teams" user={user} />
      )}
      {user.roles.includes('Carer') && !isAgent && !isRecruiter && (
        <AdminUserRatesContainer name="Rates" user={user} />
      )}
      {user.roles.includes('Carer') && !isAgent && !isRecruiter && (
        <AdminUserShiftsContainer name="Shifts" user={user} />
      )}
      {user.roles.includes('Carer') && !isAgent && !isRecruiter && (
        <AdminUserTestimonialsContainer name="Testimonials" user={user} />
      )}
      {user.roles.includes('Carer') && !isAgent && !isRecruiter && (
        <NotesContainer
          name="Notes"
          objectID={user.id}
          noteType="User"
          placeholder="e.g. Any positive feedback about the carer"
        />
      )}
    </TabbedComponents>
  );
}

const mapStateToProps = (state, props) => ({
  isAgent: isAgent(state),
  isRecruiter: isRecruiter(state),
  loadingImpersonator: getImpersonatorLoadingState(state),
  profile: getLiveProfileByCarerID(state, props.match.params.userID),
  user: selectUserByID(state, props.match.params.userID),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCarerProfileByCarerId,
      getUserById,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserContainer);
