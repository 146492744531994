// @flow
import { required, validPhoneNumber } from 'validators';

import type { TaxonomiesClassificationType } from 'shared/types';


const fieldsConfig = (
  currentValues?: Object,
  extraDisplayCriteria: { isAdmin: boolean },
  taxonomiesByClassification: TaxonomiesClassificationType,
) => [
  {
    sectionId: 'information',
    sectionHeader: 'Franchise Details',
    displayCriteria: true,
    rows: [

      {
        displayCriteria: true,
        rowId: 'name',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'name',
            component: 'StandardInput',
            fieldConfiguration: {
              title: 'Franchise Name',
              greyTitle: true,
              titleHelp: `${
                !!extraDisplayCriteria.isAdmin ?
                "Please note this name must match the name in Smart Recruiters" :
                null
              }`,
              name: 'name',
              validate: [required],
              type: 'text',
              disabled: !extraDisplayCriteria.isAdmin,
            },
            size: {
              xs: 24,
              md: 12,
            },
          },
        ],
      },

      {
        displayCriteria: true,
        rowId: 'location',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'town',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'town',
              label: 'Town',
              type: 'text',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'county',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'county',
              label: 'County',
              type: 'text',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
      {
        displayCriteria: true,
        rowId: 'location-2',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'country',
            component: 'StandardSelect',
            fieldConfiguration: {
              name: 'country',
              label: 'Country',
              validate: [required],
              disabled: true,
            },
            options: taxonomiesByClassification.franchise_country,
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'postcode',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'postcode',
              label: 'Postcode',
              type: 'text',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
      {
        displayCriteria: true,
        rowId: 'contact',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'phone-number',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'phone_number',
              label: "Phone Number",
              type: 'text',
              validate: [validPhoneNumber],
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
    ],
  },
  {
    sectionId: 'management',
    displayCriteria: !!extraDisplayCriteria.isAdmin,
    sectionHeader: 'Admin Details',
    rows: [
      {
        rowId: 'admin-details',
        displayCriteria: true,
        fields: [
          {
            displayCriteria: true,
            fieldId: 'state',
            component: 'StandardSelect',
            fieldConfiguration: {
              name: 'state',
              label: 'State',
              parse: (value: string) => (value === '' ? null : value)
            },
            options: taxonomiesByClassification.franchise_state,
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'account_manager',
            component: 'StandardSelect',
            fieldConfiguration: {
              name: 'account_manager',
              label: 'Account Manager',
              parse: (value: string) => (value === '' ? null : value)
            },
            options: taxonomiesByClassification.account_manager,
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },

    ],
  },

]

export default fieldsConfig;
