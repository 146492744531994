import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import toTitleCase from 'components/helpers/toTitleCase';
import PaymentButton from 'components/payments/PaymentButton';

class ChargeDetails extends Component {
  static propTypes = {
    carer: PropTypes.shape().isRequired,
    charge: PropTypes.shape().isRequired,
    customer: PropTypes.shape().isRequired,
    paymentMethods: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    createPaymentMethod: PropTypes.func.isRequired,
    payCharge: PropTypes.func.isRequired,
  };

  render() {
    const created = moment(this.props.charge.created_at).format(
      'dddd, MMMM Do YYYY',
    );
    const payee = this.props.carer
      ? this.props.carer.display_name
      : 'SuperCarers';

    return (
      <section className="component-wrapper">
        <h1>Charge Details</h1>
        <div key={this.props.charge.id} className="module boxed">
          <span className="right-info">
            <div className="charges">
              <strong>Status: </strong>
              <span className={`status ${this.props.charge.state}`}>
                {toTitleCase(this.props.charge.state)}
              </span>
            </div>
          </span>
          <div className="old-row">
            <p>
              <strong>Created:</strong> {created}
            </p>
            <p>
              <strong>Amount:</strong> {this.props.charge.nice_amount}
            </p>
            <p>
              <strong>Payee:</strong> {payee}
            </p>
          </div>
          <div className="old-row">
            <div className="old-col">
              <p>{this.props.charge.description || 'No description'}</p>
            </div>
          </div>
          {this.props.charge.state !== 'paid' && (
            <div className="old-row">
              <div className="old-col">
                <PaymentButton
                  paymentMethods={this.props.paymentMethods}
                  user={this.props.customer}
                  createPaymentMethod={this.props.createPaymentMethod}
                  pay={this.props.payCharge}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default ChargeDetails;
