import { searchBids, updateBid } from '_actions/bidActions';
import { getJobOfferByReferenceForCarer } from '_actions/jobOfferActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import JobOffer from 'components/jobOffers/JobOffer';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { getUser, isAdmin, isCarer } from 'shared/selectors/accountSelectors';
import { selectCarerBidByJobOfferID } from 'shared/selectors/bidSelectors';
import { selectJobOfferByReference } from 'shared/selectors/jobOfferSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { selectScheduleWithShiftsByScheduleID } from 'shared/selectors/scheduleShiftsSelectors';

class JobOfferContainer extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    bid: PropTypes.shape(),
    jobOffer: PropTypes.shape(),
    isCarer: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape().isRequired,
    }).isRequired,
    searchBids: PropTypes.func.isRequired,
    getJobOfferByReferenceForCarer: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
    updateBid: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.shape(),
    schedule: PropTypes.shape(),
  };

  static defaultProps = {
    bid: null,
    need: null,
    jobOffer: null,
    schedule: null,
    search: null,
    taxonomiesByClassification: null,
  };

  //TODO LP potentially split these up into another container - most are needed further down in the need bit
  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'bid_carer_rejection',
      'care_type',
      'condition',
      'condition_experience',
      'equipment',
      'gender_preference',
      'hobbies',
      'likes_animals',
      'pets',
      'qualifications',
      'services',
      'symptoms',
      'smoker_preference',
    ];
  }

  componentDidMount() {
    this.props.getJobOfferByReferenceForCarer(
      this.props.match.params.jobOfferReference,
    );
  }

  render() {
    const { bid, jobOffer, taxonomiesByClassification, schedule } = this.props;
    if (
      !jobOffer ||
      !schedule ||
      Object.entries(schedule).length === 0 ||
      !taxonomiesByClassification
    ) {
      return <LoadingPlaceholder />;
    }

    return (
      <JobOffer
        bid={bid}
        jobOffer={jobOffer}
        updateBid={this.props.updateBid}
        taxonomiesByClassification={taxonomiesByClassification}
        schedule={schedule}
        isAdmin={this.props.isAdmin}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const jobOffer = selectJobOfferByReference(
    state,
    props.match.params.jobOfferReference,
  );
  const newProps = {
    jobOffer,
    isCarer: isCarer(state),
    user: getUser(state),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      JobOfferContainer.TAXONOMY_CLASSIFICATIONS,
    ),
    isAdmin: isAdmin(state),
  };
  if (jobOffer) {
    newProps.bid = selectCarerBidByJobOfferID(state, jobOffer.id);
    newProps.schedule = selectScheduleWithShiftsByScheduleID(
      state,
      jobOffer.schedule_id,
    );
  }
  return newProps;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getJobOfferByReferenceForCarer,
      searchBids,
      updateBid,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobOfferContainer);
