import PropTypes from 'prop-types';
import React from 'react';

const InvoicesIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 30"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="team-icon"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path d="M5.05263158,1.26315789 C4.70463158,1.26315789 4.42105263,1.54673684 4.42105263,1.89473684 L4.42105263,24 C4.42105263,24.3486316 4.70463158,24.6315789 5.05263158,24.6315789 L22.1052632,24.6315789 C22.4532632,24.6315789 22.7368421,24.3486316 22.7368421,24 L22.7368421,8.47263158 L15.528,1.26315789 L5.05263158,1.26315789 Z M22.1052632,25.8947368 L5.05263158,25.8947368 C4.008,25.8947368 3.15789474,25.0452632 3.15789474,24 L3.15789474,1.89473684 C3.15789474,0.850105263 4.008,0 5.05263158,0 L15.7894737,0 C15.9568421,0 16.1178947,0.0669473684 16.236,0.185052632 L23.8149474,7.764 C23.9336842,7.88273684 24,8.04315789 24,8.21052632 L24,24 C24,25.0452632 23.1498947,25.8947368 22.1052632,25.8947368 L22.1052632,25.8947368 Z" />
        <path d="M23.3684211,8.84210526 L15.7894737,8.84210526 C15.4408421,8.84210526 15.1578947,8.55978947 15.1578947,8.21052632 L15.1578947,1.26315789 L16.4210526,1.26315789 L16.4210526,7.57894737 L23.3684211,7.57894737 L23.3684211,8.84210526" />
        <path d="M18.9473684,29.0526316 L1.89473684,29.0526316 C0.850105263,29.0526316 0,28.2031579 0,27.1578947 L0,5.05263158 C0,4.008 0.850105263,3.15789474 1.89473684,3.15789474 L3.78947368,3.15789474 L3.78947368,4.42105263 L1.89473684,4.42105263 C1.54673684,4.42105263 1.26315789,4.70463158 1.26315789,5.05263158 L1.26315789,27.1578947 C1.26315789,27.5065263 1.54673684,27.7894737 1.89473684,27.7894737 L18.9473684,27.7894737 C19.2953684,27.7894737 19.5789474,27.5065263 19.5789474,27.1578947 L19.5789474,25.2631579 L20.8421053,25.2631579 L20.8421053,27.1578947 C20.8421053,28.2031579 19.992,29.0526316 18.9473684,29.0526316" />
        <polygon points="8.21052632 11.3684211 12.6315789 11.3684211 12.6315789 10.1052632 8.21052632 10.1052632" />
        <polygon points="8.21052632 14.5263158 18.9473684 14.5263158 18.9473684 13.2631579 8.21052632 13.2631579" />
        <polygon points="8.21052632 17.6842105 18.9473684 17.6842105 18.9473684 16.4210526 8.21052632 16.4210526" />
        <polygon points="8.21052632 20.8421053 18.9473684 20.8421053 18.9473684 19.5789474 8.21052632 19.5789474" />
      </g>
    </svg>
  </div>
);

InvoicesIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

InvoicesIcon.defaultProps = {
  className: '',
  height: '30px',
  width: '26px',
};

export default InvoicesIcon;
