import { createSelector } from 'reselect';

export const getDiscounts = state => state.discounts;
export const getDiscountSearch = state => {
  const { pagination, searchOrder } = state.search.discounts || {};
  if (pagination && searchOrder) {
    return { pagination, searchOrder };
  }
  return null;
};
export const getDiscountSearchFull = state => state.search.discounts;

export const getDiscountResults = createSelector(
  [getDiscounts, getDiscountSearch],
  (discounts, search) => {
    if (!search || !search.searchOrder) {
      return [];
    }
    return search.searchOrder.map(id => discounts[id]);
  },
);
