// @flow
import React, { Component } from 'react';
import { Container, Col, Row } from 'react-grid-system';

import CarerSearch from 'components/carers/carersearch/CarerSearch';
import CarerSearchResults from 'components/carers/carersearch/CarerSearchResults';
import LoadMore from 'components/global/LoadMore';

import type {
  CarerType,
  CarerSearchType,
  LastCarerSearchType,
  TaxonomiesClassificationType,
} from 'shared/types';

type Props = {
  carerResults: Array<?CarerType>,
  carerSearch: CarerSearchType,
  lastCarerSearch: LastCarerSearchType,
  searchCarers: Object => void,
  searchMoreCarers: Object => void,
  taxonomiesByClassification: TaxonomiesClassificationType,
};

class AdminCarerSearch extends Component<Props> {
  render() {
    const {
      carerResults,
      carerSearch,
      lastCarerSearch,
      searchCarers,
      searchMoreCarers,
      taxonomiesByClassification,
    } = this.props;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <CarerSearch
              name={'carerSearch-admin'}
              onSubmit={searchCarers}
              taxonomiesForClassification={taxonomiesByClassification}
            />
            <CarerSearchResults carers={carerResults} search={carerSearch} />
            {carerSearch &&
              carerSearch.pagination &&
              !!carerSearch.pagination.total && (
                <LoadMore
                  search={carerSearch}
                  params={lastCarerSearch}
                  onClick={searchMoreCarers}
                />
              )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AdminCarerSearch;
