import React from 'react';
import styled from 'styled-components';

export const IconLabel = styled.span`
  margin-bottom: 5px;
  position: relative;
  padding-left: 25px;
`;

export const IconLabelContainer = styled.div`
  position: relative;
`;

const InlineStyledIcon = ({ Icon, label, style, className }) => {
  const StyledIcon = styled(Icon)`
    display: inline-block;
    left: 0;
    position: absolute;
    top: 3px;
  `;
  return (
    <IconLabelContainer style={style} className={className}>
      <StyledIcon />
      <IconLabel>{label}</IconLabel>
    </IconLabelContainer>
  );
};

export default InlineStyledIcon;
