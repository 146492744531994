import ApiConstants from 'shared/constants/ApiConstants';
import TimesheetConstants from 'shared/constants/TimesheetConstants';

export function getTimesheetById(timesheetId) {
  return {
    endpoint: `/timesheet/${timesheetId}/`,
    method: 'get',
    onError: {
      type: TimesheetConstants.TIMESHEET_GET_BY_ID_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to get timesheets',
      },
    },
    onStart: { type: TimesheetConstants.TIMESHEET_GET_BY_ID },
    onSuccess: { type: TimesheetConstants.TIMESHEET_GET_BY_ID_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function getTimesheetSummary(isoweek, cumulative) {
  return {
    endpoint: `/timesheet/summary/${isoweek}/`,
    method: 'get',
    onError: { type: TimesheetConstants.TIMESHEET_SUMMARY_FAILURE },
    onStart: { type: TimesheetConstants.TIMESHEET_SUMMARY },
    onSuccess: { type: TimesheetConstants.TIMESHEET_SUMMARY_SUCCESS, isoweek },
    payload: cumulative ? { cumulative } : {},
    type: ApiConstants.API_REQUEST,
  };
}

export function deleteTimesheetById(timesheetId) {
  return {
    endpoint: `/timesheet/${timesheetId}/`,
    method: 'delete',
    onError: { type: TimesheetConstants.TIMESHEET_DELETE_BY_ID_FAILURE },
    onStart: {
      type: TimesheetConstants.TIMESHEET_DELETE_BY_ID,
      toast: {
        type: 'error',
        public: false,
        title: 'Timesheet deleted',
      },
    },
    onSuccess: { type: TimesheetConstants.TIMESHEET_DELETE_BY_ID_SUCCESS },
    redirect: '/admin/timesheets/',
    type: ApiConstants.API_REQUEST,
  };
}

export function timesheetRecalculate(timesheetId) {
  return {
    endpoint: `/timesheet/${timesheetId}/recalculate/`,
    method: 'put',
    onError: { type: TimesheetConstants.TIMESHEET_RECALCULATE_TOTALS_FAILURE },
    onStart: {
      type: TimesheetConstants.TIMESHEET_RECALCULATE_TOTALS,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to use the latest rate',
      },
    },
    onSuccess: {
      type: TimesheetConstants.TIMESHEET_RECALCULATE_TOTALS_SUCCESS,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function getTimesheetTotalById(timesheetId) {
  return {
    endpoint: `/timesheet/${timesheetId}/total/`,
    method: 'get',
    onError: { type: TimesheetConstants.TIMESHEET_GET_TOTAL_BY_ID_FAILURE },
    onStart: { type: TimesheetConstants.TIMESHEET_GET_TOTAL_BY_ID },
    onSuccess: {
      timesheet_id: timesheetId,
      type: TimesheetConstants.TIMESHEET_GET_TOTAL_BY_ID_SUCCESS,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function searchTimesheets(params, page) {
  const query = Object.assign({ page: page || 1 }, params);
  return {
    endpoint: '/timesheet/find/',
    method: 'get',
    onError: { type: TimesheetConstants.TIMESHEET_SEARCH_FAILURE },
    onStart: { type: TimesheetConstants.TIMESHEET_SEARCH },
    onSuccess: { type: TimesheetConstants.TIMESHEET_SEARCH_SUCCESS },
    payload: query,
    type: ApiConstants.API_REQUEST,
  };
}
