// @flow
import TestimonialConstants from 'shared/constants/TestimonialConstants';
import type { TestimonialType } from 'shared/types';

const removeNullOrString = testimonial => {
  const newTestimonial = {};
  Object.entries(testimonial).forEach(([key, value]) => {
    if (value !== null && value !== '') {
      newTestimonial[key] = value;
    }
  });

  return newTestimonial;
};

function testimonials(state: TestimonialType = {}, action: Object) {
  switch (action.type) {
    case TestimonialConstants.TESTIMONIALS_GET_FOR_CARER_BY_ID_SUCCESS: {
      const { carer_id } = action;
      const newState = {};
      newState[carer_id] = [];
      action.data.results.forEach(testimonial => {
        newState[carer_id].push(removeNullOrString(testimonial));
      });
      return { ...state, ...newState };
    }

    case TestimonialConstants.TESTIMONIAL_DELETE_SUCCESS: {
      // May need to be updated when the backend work for deleting a testimonial is complete
      // Currently we get an empty response from the back end, which means user_id is undefined
      const newState = {};
      const userID = action.payload.userID;
      newState[userID] = state[userID].filter(
        testimonial => testimonial.id !== action.payload.testimonialID,
      );
      return { ...state, ...newState };
    }

    default:
      return state;
  }
}

export default testimonials;
