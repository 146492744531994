import { createSelector } from 'reselect';
import { getCarers } from 'shared/selectors/carerSelectors';
import { getChargeByID } from 'shared/selectors/chargeSelectors';
import { getInvoiceByID } from 'shared/selectors/invoiceSelectors';
import {
  getTeams,
  getCarerTeams,
  getTeamResults,
} from 'shared/selectors/teamSelectors';
import {
  getTimesheetResults,
  getTimesheetByID,
} from 'shared/selectors/timesheetSelectors';
import { selectSchedulesForShifts } from 'shared/selectors/scheduleSelectors';

export const getNeeds = state => {
  if (!state.needs) {
    return null;
  }

  const needs = { ...state.needs };
  Object.entries(needs).forEach(([id, need]) => {
    if (id !== need.id) {
      delete needs[id];
    }
  });

  return needs;
};

export const getNeedSearch = state => state.search.needs;
export const getNeedByID = (state, id) => {
  return state.needs[id];
};

export const getNeedsByUser = (state, props) =>
  state.needs
    ? Object.values(state.needs).filter(need => need.user_id === props.user.id)
    : undefined;

export const getNeedsforUserByID = (state, id) => {
  return Object.values(state.needs).filter(need => need.user_id === id);
};

export const getSingleNeedForUserByID = createSelector(
  [getNeedsforUserByID],
  needs => {
    if (!needs || !needs.length) {
      return null;
    }
    return needs[0];
  },
);

export const getNeedResults = createSelector(
  [getNeeds, getNeedSearch],
  (needs, search) => {
    if (!search || !search.searchOrder) {
      return [];
    }
    return search.searchOrder.map(id => needs[id]);
  },
);

export const getNeedforInvoiceByID = createSelector(
  [getNeeds, getInvoiceByID],
  (needs, invoice) => {
    if (!needs || !invoice) {
      return null;
    }
    return Object.values(needs).find(need => need.id === invoice.need_id);
  },
);

export const getNeedforTimesheetByID = createSelector(
  [getNeeds, getTimesheetByID],
  (needs, timesheet) => {
    if (!needs || !timesheet) {
      return null;
    }
    return Object.values(needs).find(need => need.id === timesheet.need_id);
  },
);

export const getNeedsforConsumerByID = createSelector(
  [getNeeds, getChargeByID],
  (needs, charge) => {
    if (!needs || !charge) {
      return null;
    }
    return Object.values(needs).filter(
      need => need.user_id === charge.consumer_id,
    );
  },
);

// TODO: These are here to avoid a circular import, have a think about that
export const getTeamForNeedByID = (state, needId) => {
  if (!state.careTeams) {
    return null;
  }

  const { loading, ...careTeams } = state.careTeams;
  return Object.values(careTeams).find(team => team.need_id === needId);
};

export const getCarersForTeamOfIDs = createSelector(
  [getCarers, getTeamForNeedByID],
  (carers, team) => {
    if (!carers || !team) {
      return null;
    }

    return team.team.map(userID => carers[userID]);
  },
);

// returns carer objects against their team status
export const selectTeamMembersWithCarers = createSelector(
  [getCarers, getTeamForNeedByID],
  (carers, team) => {
    if (!carers || !team || !team.members) {
      return null;
    }

    //team members have the structure of [status]: [carerData]
    return Object.entries(team.members).reduce(
      (accumulator, [status, carerData]) => {
        accumulator[status] = carerData.map(member => {
          return {
            ...carers[member.carer_id],
            ...member,
          };
        });
        return accumulator;
      },
      {},
    );
  },
);

export const getTeamsForUserByID = createSelector(
  [getTeams, getNeedsforUserByID],
  (teams, needs) => {
    if (!teams || !needs) {
      return null;
    }
    const needTeams = [];
    needs.forEach(need => {
      const match = Object.values(teams).find(team => team.need_id === need.id);
      if (match) {
        needTeams.push(match);
      }
    });
    return needTeams;
  },
);

export const getTeamsByChargeID = createSelector(
  [getTeams, getNeedsforConsumerByID],
  (teams, needs) => {
    if (!teams || !needs) {
      return null;
    }
    const needTeams = [];
    needs.forEach(need => {
      const match = Object.values(teams).find(team => team.need_id === need.id);
      if (match) {
        needTeams.push(match);
      }
    });
    return needTeams;
  },
);

export const getNeedsTeamsByUser = createSelector(
  [getTeams, getNeedsByUser],
  (teams, needs) => {
    if (!teams || !needs) {
      return null;
    }
    const needsTeams = {};
    needs.forEach(need => {
      const match = Object.values(teams).find(team => team.need_id === need.id);
      if (match) {
        needsTeams[need.id] = match;
      }
    });
    return needsTeams;
  },
);

export const getNeedsforCarerByID = createSelector(
  [getNeeds, getCarerTeams],
  (needs, teams) => {
    if (!needs || !teams) {
      return null;
    }
    const matches = [];
    teams.forEach(team => {
      matches.forEach(need => {
        if (need.id === team.need_id) {
          matches.push(need);
        }
      });
    });
    return matches;
  },
);

export const getTimesheetResultNeeds = createSelector(
  [getNeeds, getTimesheetResults],
  (needs, timesheets) => {
    if (!needs || !timesheets) {
      return {};
    }
    const timesheetNeeds = {};
    timesheets.forEach(timesheet => {
      const match = Object.values(needs).find(
        need => need.id === timesheet.need_id,
      );
      if (match) {
        timesheetNeeds[timesheet.id] = match;
      }
    });
    return timesheetNeeds;
  },
);

export const getTeamResultNeeds = createSelector(
  [getNeeds, getTeamResults],
  (needs, teams) => {
    if (!needs || !teams) {
      return {};
    }
    const teamNeeds = {};
    teams.forEach(team => {
      const match = Object.values(needs).find(need => need.id === team.need_id);
      if (match) {
        teamNeeds[team.id] = match;
      }
    });
    return teamNeeds;
  },
);

export const selectNeedsForShifts = createSelector(
  [getNeeds, selectSchedulesForShifts],
  (needs, schedules) => {
    if (!needs) {
      return null;
    }
    let shiftNeeds = {};
    Object.values(schedules).forEach(schedule => {
      return (shiftNeeds[schedule.care_need_id] = needs[schedule.care_need_id]);
    });
    return shiftNeeds;
  },
);

export const selectAccountOwnerForNeedID = (state, needID) =>
  state.needs.accountOwners[needID];
