import React from 'react';
import Link from 'react-router-redux-dom-link';
import styled, { css } from 'styled-components';
import { Visible } from 'components/grid';

import Module from 'components/global/layout/Module';
import PriorityIndicator from 'components/global/PriorityIndicator';
import StatusIndicator from 'components/global/StatusIndicator';
import JobLocationDateDuration from 'components/jobOffers/JobLocationDateDuration';
import ShiftTypeHeadings from 'components/jobOffers/ShiftTypeHeadings';
import { Container } from 'components/grid';
import Button from 'components/buttons/Button';

import stateHelper from 'components/helpers/stateHelper';

import type { BidType, JobOfferType, ScheduleType } from 'shared/types';

const JobOfferRowModule = styled(Module)`
  display: block;
  position: relative;
  width: 100%;
  box-shadow: ${({ priority }) => priority && '0 0 15px 0 rgba(0, 0, 0, 0.20)'};
  background: ${({ priority, theme }) => priority && theme.colors.darkerBeige};
  padding: 20px 0 0;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 230px;
    display: flex;
    padding: 20px 0;
  }
`;

const StyledJobDetails = styled.div`
  @media (max-width: ${props => props.theme.screen.small}px) {
    margin-bottom: 15px;
  }
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const JobDetailColumn = styled.div`
  display: block;
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-basis: 50%;
    margin-right: 10px;
  }
`;

const JobTitleRef = styled(JobDetailColumn)`
  width: 100%;
  @media (min-width: 768px) {
    flex-basis: 20%;
  }
`;

const PriorityIndicatorPositioning = css`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const JobDetailRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 3px;
  position: relative;
  &.immediate {
    font-weight: bold;
  }
  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
`;

const ViewJobButton = styled.div`
  display: block;
  margin-top: 10px;
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    justify-content: flex-end;
    width: auto;
  }
`;

const FullWidthButton = styled(Button)`
  max-width: 100%;
  margin-bottom: 0;
  border-radius: 0 0 5px 5px;
`;

const StyledLink = styled(Link)`
  width: 100%;
`;

type Props = {
  bid: BidType,
  jobOffer: JobOfferType,
  newJob: boolean,
  schedule: ScheduleType,
};

const JobOfferRow = ({ bid, jobOffer, newJob, schedule }: Props) => {
  return (
    <JobOfferRowModule priority={jobOffer.priority}>
      <PriorityIndicator
        jobOffer={jobOffer}
        positioning={PriorityIndicatorPositioning}
      />
      <ShiftTypeHeadings jobOffer={jobOffer} showRefOnMobile />
      <Container>
        <StyledJobDetails>
          <JobLocationDateDuration
            bid={bid}
            jobOffer={jobOffer}
            schedule={schedule}
          />
          <JobTitleRef>
            {jobOffer.need.franchise_name !== '' && (
              <JobDetailRow>
                <strong>{jobOffer.need.franchise_name} Franchise</strong>
              </JobDetailRow>
            )}

            <JobDetailRow>
              <strong>{jobOffer.title || `Job in ${jobOffer.location}`}</strong>
            </JobDetailRow>

            <Visible md lg xl>
              <JobDetailRow>
                <strong>{jobOffer.reference}</strong>
              </JobDetailRow>
            </Visible>

            {!newJob && (
              <StatusIndicator
                status={stateHelper.bidDisplayState(jobOffer, bid)}
              />
            )}
          </JobTitleRef>
          <Visible md lg xl>
            <ViewJobButton>
              <Link to={`/jobs/${jobOffer.reference}/`}>
                <Button>View Job</Button>
              </Link>
            </ViewJobButton>
          </Visible>
        </StyledJobDetails>
      </Container>
      <Visible xs sm>
        <ViewJobButton>
          <StyledLink to={`/jobs/${jobOffer.reference}/`}>
            <FullWidthButton>View Job</FullWidthButton>
          </StyledLink>
        </ViewJobButton>
      </Visible>
    </JobOfferRowModule>
  );
};

export default JobOfferRow;
