// @flow
import React from 'react';

import Module from 'components/global/layout/Module';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import FormTemplate from 'components/forms/FormTemplate';
import { Container, Col } from 'components/grid';
import Button from 'components/buttons/Button';
import ButtonGroup from 'components/buttons/ButtonGroup';
import { asyncValidPostcode } from 'validators';

import fieldsConfig from 'components/admin/needs/AdminNeedsFormFields';

import type { TaxonomiesClassificationType } from 'shared/types';

type Props = {
  archiveNeed: () => void,
  taxonomiesByClassification: TaxonomiesClassificationType,
  editMode: boolean,
  formName: string,
  initialValues: Object,
  onSubmit: () => void,
  unarchiveNeed: () => void,
};

const AdminNeedsForm = ({
  archiveNeed,
  taxonomiesByClassification,
  formName,
  initialValues = {},
  editMode = false,
  onSubmit,
  unarchiveNeed,
}: Props) => {
  return (
    <Form
      name={formName}
      asyncValidate={asyncValidPostcode}
      asyncBlurFields={['postcode']}
      shouldAsyncValidate={syncValidationPasses => {
        return syncValidationPasses;
      }}
      destroyOnUnmount
      fields={Object.keys(initialValues)}
      values={initialValues}
      onSubmit={onSubmit}
      selectFields={['state', 'franchise_id']}
      component={({ currentValues, initialValues, handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit} id="admin-needs-form">
          <Module>
            <FormTemplate
              currentValues={currentValues}
              extraDisplayCriteria={{ editMode }}
              fieldsConfig={fieldsConfig}
              taxonomiesByClassification={taxonomiesByClassification}
            />
            <Container>
              <FormRow>
                <Col>
                  <ButtonGroup>
                    <Button disabled={pristine} type="submit">
                      {editMode ? 'Save changes' : 'Submit your request'}
                    </Button>
                    {editMode && currentValues.state !== 'ARCHIVED' && (
                      <Button
                        type="button"
                        name="archive"
                        className="danger"
                        onClick={archiveNeed}
                      >
                        Archive care need
                      </Button>
                    )}
                    {editMode && currentValues.state === 'ARCHIVED' && (
                      <Button
                        type="button"
                        name="unarchive"
                        className="danger"
                        onClick={unarchiveNeed}
                      >
                        Un-archive care need
                      </Button>
                    )}
                  </ButtonGroup>
                </Col>
              </FormRow>
            </Container>
          </Module>
        </form>
      )}
    />
  );
};

export default AdminNeedsForm;
