/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import { payInvoice, setInvoiceVoid } from '_actions/invoiceActions';
import { addNoteForObject } from '_actions/noteActions';

import { populateInvoiceWithId } from '_actions/invoiceActions';
import { authenticatePaymentViaStripe } from '_actions/paymentActions';
import TimesheetActions from 'actions/TimesheetActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import stateHelper from 'components/helpers/stateHelper';
import InvoiceDetails from 'components/invoices/InvoiceDetails';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUser, isAdmin } from 'shared/selectors/accountSelectors';
import { getCarers } from 'shared/selectors/carerSelectors';
import { getInvoiceByID } from 'shared/selectors/invoiceSelectors';

import { getNeedforInvoiceByID } from 'shared/selectors/needSelectors';
import { getPaymentsByInvoiceID } from 'shared/selectors/paymentSelectors';
import { getRefundsForInvoiceTimesheetsByID } from 'shared/selectors/refundSelectors';

import { getTimesheetsForInvoiceByID } from 'shared/selectors/timesheetSelectors';

import { getTimesheetTotals } from 'shared/selectors/totalSelectors';
import { getUserForInvoiceByID } from 'shared/selectors/userSelectors';

const InvoiceDetailsContainer = ({
  addNoteForObject,
  authenticatePaymentViaStripe,
  carers,
  customer,
  populateInvoiceWithId,
  invoice,
  isAdmin,
  match,
  need,
  payInvoice,
  paymentMethods,
  refunds,
  setInvoiceVoid,
  timesheets,
  totals,
  user,
}) => {
  useEffect(() => {
    populateInvoiceWithId(match.params.invoiceID);
  }, []);

  const getInvoiceTotal = () => {
    const invoiceTotal = {
      carer_total: 0,
      customer_total: 0,
      minutes_total: 0,
      livein_days: 0,
      application_total: 0,
    };

    timesheets.forEach(timesheet => {
      const timesheetTotal =
        timesheet.totals || (totals && totals[timesheet.id]);
      if (timesheetTotal) {
        [
          'carer_total',
          'customer_total',
          'minutes_total',
          'livein_days',
        ].forEach(key => {
          invoiceTotal[key] += timesheetTotal.total[key];
        });
        // Application total = supercarers commission (excl. insurance_payment)
        invoiceTotal.application_total +=
          timesheetTotal.total.customer_total -
          (timesheetTotal.total.carer_total + timesheetTotal.insurance_payment);

        const userRefunds = refunds[timesheet.id] || [];
        userRefunds.forEach(refund => {
          ['carer_total', 'application_total'].forEach(key => {
            invoiceTotal[key] += refund.totals[key];
          });
          invoiceTotal.customer_total -= refund.totals.customer_total;
        });
      }
    });

    return invoiceTotal;
  };

  const payInvoiceLocal = () => {
    payInvoice(invoice.id, { payment_type: 'stripe' });
  };

  const reassignTimesheet = timesheetId => {
    const confirmed = confirm(
      'Are you sure you want to detach this timesheet?',
    );
    if (confirmed) {
      TimesheetActions.reassignTimesheet(timesheetId);
    }
  };

  const voidInvoice = () => {
    const confirmation = confirm('Are you sure you want to void this invoice?');
    if (confirmation) {
      setInvoiceVoid(invoice.id, invoice.state !== 'void');
    }
  };

  const authenticateViaStripe = (clientSecret, paymentMethodId) => {
    const stripe = window.Stripe(window.global.STRIPE_KEY);
    authenticatePaymentViaStripe(clientSecret, paymentMethodId, stripe);
  };

  // check that all data is loaded
  let loaded = false;
  if (invoice && need && customer && paymentMethods !== null && timesheets) {
    if (timesheets.length) {
      loaded = timesheets.every(timesheet => carers[timesheet.carer_id]);
    } else if (invoice.void) {
      loaded = true;
    }
  }

  if (!loaded) {
    return <LoadingPlaceholder />;
  }

  const invoiceTotal = getInvoiceTotal();
  const invoiceState = stateHelper.invoiceDisplayState(invoice);

  return (
    <InvoiceDetails
      addNoteForObject={addNoteForObject}
      authenticateViaStripe={authenticateViaStripe}
      carers={carers}
      customer={customer}
      invoice={invoice}
      invoiceState={invoiceState}
      invoiceTotal={invoiceTotal}
      isAdmin={isAdmin}
      need={need}
      refunds={refunds}
      timesheets={timesheets}
      totals={totals}
      user={user}
      payInvoice={payInvoiceLocal}
      paymentMethods={paymentMethods}
      reassignTimesheet={reassignTimesheet}
      voidInvoice={voidInvoice}
    />
  );
};

const mapStateToProps = (state, props) => ({
  carers: getCarers(state),
  customer: getUserForInvoiceByID(state, props.match.params.invoiceID),
  invoice: getInvoiceByID(state, props.match.params.invoiceID),
  isAdmin: isAdmin(state),
  need: getNeedforInvoiceByID(state, props.match.params.invoiceID),
  paymentMethods: getPaymentsByInvoiceID(state, props.match.params.invoiceID),
  refunds: getRefundsForInvoiceTimesheetsByID(
    state,
    props.match.params.invoiceID,
  ),
  timesheets: getTimesheetsForInvoiceByID(state, props.match.params.invoiceID),
  totals: getTimesheetTotals(state),
  user: getUser(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addNoteForObject,
      authenticatePaymentViaStripe,
      populateInvoiceWithId,
      payInvoice,
      setInvoiceVoid,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoiceDetailsContainer);
