import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AdminUserWorkHistorySummary from 'components/users/AdminUserWorkHistorySummary';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import {
  deleteWorkHistoryInstance,
  getWorkHistoryByCarerID,
  updateWorkHistoryInstance,
} from '_actions/workHistoryActions';
import { updateCarerProfile } from '_actions/carerActions';

import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import {
  selectWorkHistoryByCarerID,
  selectWorkHistorySearch,
} from 'shared/selectors/workHistorySelectors';

class AdminUserWorkHistorySummaryContainer extends Component {
  static propTypes = {
    user: PropTypes.shape().isRequired,
    profile: PropTypes.shape().isRequired,
    workHistory: PropTypes.arrayOf(PropTypes.shape()),
    deleteWorkHistoryInstance: PropTypes.func.isRequired,
    getWorkHistoryByCarerID: PropTypes.func.isRequired,
    updateCarerProfile: PropTypes.func.isRequired,
    updateWorkHistoryInstance: PropTypes.func.isRequired,
    search: PropTypes.shape(),
    taxonomiesByClassification: PropTypes.shape(),
  };

  static defaultProps = {
    workHistory: null,
    search: null,
    taxonomiesByClassification: {},
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['work_history_employment_type'];
  }

  componentDidMount() {
    this.props.getWorkHistoryByCarerID(this.props.user.id);
  }

  markWorkHistoryCompliant = value => {
    this.props.updateCarerProfile(this.props.user.id, {
      hi_work_history_mark_compliant: value,
    });
  };

  render() {
    const { workHistory, taxonomiesByClassification } = this.props;

    if (!workHistory || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminUserWorkHistorySummary
        carer={this.props.user}
        deleteWorkHistoryInstance={this.props.deleteWorkHistoryInstance}
        markWorkHistoryCompliant={this.markWorkHistoryCompliant}
        taxonomiesByClassification={taxonomiesByClassification}
        updateWorkHistoryInstance={this.props.updateWorkHistoryInstance}
        workHistory={workHistory}
        workHistoryCompliant={(this.props.profile.hi_compliance_fields || {}).work_history}
        workHistoryMarkCompliant={this.props.profile.hi_work_history_mark_compliant}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  workHistory: selectWorkHistoryByCarerID(state, props.user.id),
  search: selectWorkHistorySearch(state),
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    AdminUserWorkHistorySummaryContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteWorkHistoryInstance,
      getWorkHistoryByCarerID,
      updateCarerProfile,
      updateWorkHistoryInstance,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUserWorkHistorySummaryContainer);
