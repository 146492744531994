import { Route } from 'react-router';

import ErrorTest from 'components/admin/ErrorTest';
import UserHomeContainer from 'containers/UserHomeContainer';
import AccountChangePasswordContainer from 'containers/account/AccountChangePasswordContainer';
import AccountConfirmContainer from 'containers/account/AccountConfirmContainer';
import AccountEditContainer from 'containers/account/AccountEditContainer';
import AccountLoginContainer from 'containers/account/AccountLoginContainer';
import AccountPaymentMethodsContainer from 'containers/account/AccountPaymentMethodsContainer';
import AccountRecoverPasswordContainer from 'containers/account/AccountRecoverPasswordContainer';
import AccountResetPasswordContainer from 'containers/account/AccountResetPasswordContainer';
import AccountTermsContainer from 'containers/account/AccountTermsContainer';
import AdminApplicantInviteFormContainer from 'containers/admin/carers/AdminApplicantInviteFormContainer';
import AdminChargesOverviewContainer from 'containers/admin/AdminChargesOverviewContainer';
import AdminCreateChargeContainer from 'containers/admin/AdminCreateChargeContainer';
import AdminCreateStaffContainer from 'containers/admin/AdminCreateStaffContainer';
import AdminEditChargeContainer from 'containers/admin/AdminEditChargeContainer';
import AdminHomeContainer from 'containers/admin/AdminHomeContainer';
import AdminInvoicesOverviewContainer from 'containers/admin/AdminInvoicesOverviewContainer';
import AdminNeedTabbedComponentsContainer from 'containers/admin/needs/AdminNeedTabbedComponentsContainer';
import AdminUserContainer from 'containers/users/AdminUserContainer';
import AdminUserReferenceAddContainer from 'containers/references/AdminUserReferenceAddContainer';
import AdminUserReferenceEditContainer from 'containers/references/AdminUserReferenceEditContainer';
import AdminNeedsOverviewContainer from 'containers/admin/AdminNeedsOverviewContainer';
import AdminTeamsOverviewContainer from 'containers/admin/AdminTeamsOverviewContainer';
import AdminTimesheetsOverviewContainer from 'containers/admin/AdminTimesheetsOverviewContainer';
import AdminCarerProfileChangeOverviewContainer from 'containers/admin/carers/AdminCarerProfileChangeOverviewContainer';
import AdminCarerSearchContainer from 'containers/admin/carers/AdminCarerSearchContainer';
import AdminJobOffersContainer from 'containers/jobOffers/AdminJobOffersContainer';
import AdminDiscountsContainer from 'containers/admin/discounts/AdminDiscountsContainer';
import DefaultRatesContainer from 'containers/rates/DefaultRatesContainer';
import AdminBidsContainer from 'containers/bids/AdminBidsContainer';
import AdminNeedsFormContainer from 'containers/admin/needs/AdminNeedsFormContainer';
import AdminNeedsEditFormContainer from 'containers/admin/needs/AdminNeedsEditFormContainer';
import AdminFranchisesOverviewContainer from 'containers/admin/franchise/AdminFranchisesOverviewContainer';
import AdminFranchiseSummaryContainer from 'containers/admin/franchise/AdminFranchiseSummaryContainer';
import AdminFranchiseUserFormContainer from 'containers/admin/franchise/AdminFranchiseUserFormContainer';
import UserSchedulesContainer from 'containers/schedules/UserSchedulesContainer';
import CustomerTeamsContainer from 'containers/customers/CustomerTeamsContainer';
import AdminHolidaySettingsContainer from 'containers/admin/settings/AdminHolidaySettingsContainer';
import CareNeedManageContainer from 'containers/careNeeds/CareNeedManageContainer';
import CarerEditProfileContainer from 'containers/carers/profile/CarerEditProfileContainer';
import JobOfferContainer from 'containers/jobOffers/JobOfferContainer';
import CarerJobOffersOverviewContainer from 'containers/jobOffers/CarerJobOffersOverviewContainer';
import CarerOnboardingContainer from 'containers/carers/CarerOnboardingContainer';
import CarerProfileContainer from 'containers/carers/profile/CarerProfileContainer';
import CarerReferenceAddContainer from 'containers/references/CarerReferenceAddContainer';
import CarerReferenceEditContainer from 'containers/references/CarerReferenceEditContainer';
import CarerReferenceSummaryContainer from 'containers/references/CarerReferenceSummaryContainer';
import CarerWorkHistoryAddContainer from 'containers/carers/CarerWorkHistoryAddContainer';
import CarerWorkHistoryEditContainer from 'containers/carers/CarerWorkHistoryEditContainer';
import CarerWorkHistorySummaryContainer from 'containers/carers/CarerWorkHistorySummaryContainer';
import CareSolvedContainer from 'care-solved/CareSolvedContainer';
import CarerTestimonialsFormContainer from 'containers/carers/testimonials/CarerTestimonialsFormContainer';
import CarerTimesheetsOverviewContainer from 'containers/timesheets/CarerTimesheetsOverviewContainer';
import ChargeDetailsContainer from 'containers/charges/ChargeDetailsContainer';
import CustomerChargesOverviewContainer from 'containers/charges/CustomerChargesOverviewContainer';
import CustomerInvoicesOverviewContainer from 'containers/invoices/CustomerInvoicesOverviewContainer';
import InvoiceDetailsContainer from 'containers/invoices/InvoiceDetailsContainer';
import MagicTokenLoginContainer from 'containers/MagicTokenLoginContainer';
import NotAuthorisedContainer from 'containers/NotAuthorisedContainer';
import SitePrivacyPolicyContainer from 'containers/site/PrivacyPolicyContainer';
import SiteTermsAndConditionsContainer from 'containers/site/TermsAndConditionsContainer';
import StaffLoginContainer from 'containers/admin/StaffLoginContainer';
import StripeAuthContainer from 'containers/payments/StripeAuthContainer';
import TimesheetDetailsConfirmContainer from 'containers/timesheets/TimesheetDetailsConfirmContainer';
import TimesheetDetailsContainer from 'containers/timesheets/TimesheetDetailsContainer';

import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import LogoutRoute from 'routes/LogoutRoute';
import composedLoginWrapper from 'containers/login/composedLoginWrapper';
import SiteRouteWrapper from 'containers/site/RouteWrapper';

/*
 * Define all routes here, giving name, component and route to be rendered.
 *
 * The names are used for breadcrumbs and page titles, in a cascade pattern. For example,
 *
 *   Path               | Name   | Breadcrumb / Title
 *   -------------------+--------+------------------------
 *   /admin/            | Admin  | Admin
 *   /admin/carers/     | Carers | Admin > Carers
 *   /admin/carers/new/ | Create | Admin > Carers > Create
 */
const routes = [
  {
    path: '/',
    name: 'Login',
    component: AccountLoginContainer,
    route: composedLoginWrapper(Route),
  },
  {
    path: '/recover-password/',
    name: 'Recover Password',
    component: AccountRecoverPasswordContainer,
    route: composedLoginWrapper(Route),
  },
  {
    path: '/reset/:reset_token/',
    name: 'Reset Password',
    component: AccountResetPasswordContainer,
    route: composedLoginWrapper(Route),
  },
  {
    path: '/verify/:verification_token/',
    name: 'Verify Account',
    component: AccountConfirmContainer,
    route: composedLoginWrapper(Route),
    extraProps: { verifying: true },
  },
  {
    path: '/magic_token/:magic_token/',
    name: 'Verify token',
    component: MagicTokenLoginContainer,
    route: composedLoginWrapper(Route),
  },
  {
    path: '/staff-login/',
    name: 'Login',
    component: StaffLoginContainer,
    route: composedLoginWrapper(Route),
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/account/',
    name: 'Account',
    component: AccountEditContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/account/payment-methods/',
    name: 'Payment Methods',
    component: AccountPaymentMethodsContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/admin/',
    name: 'Admin',
    component: AdminHomeContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin', 'Franchisee', 'Recruiter'] },
  },
  {
    path: '/admin/carers/changes/',
    name: 'Carer Profile Review',
    component: AdminCarerProfileChangeOverviewContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin', 'Recruiter'] },
  },
  {
    path: '/admin/carers/profile/:carerID/',
    name: 'Profile',
    component: CarerEditProfileContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin', 'Recruiter', 'Franchisee'] },
  },
  {
    path: '/admin/carers/',
    name: 'Carers',
    component: AdminCarerSearchContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin', 'Recruiter', 'Franchisee'] },
  },
  {
    path: '/admin/charges/new/',
    name: 'Create',
    component: AdminCreateChargeContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/charges/:chargeID/',
    name: 'Edit',
    component: AdminEditChargeContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/charges/',
    name: 'Charges',
    component: AdminChargesOverviewContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/discounts/',
    name: 'Discounts',
    component: AdminDiscountsContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/error-test/',
    name: 'Error Test',
    component: ErrorTest,
    route: composedLoginWrapper(Route),
    extraProps: { availableLoggedIn: true, roles: ['Admin'] },
  },
  {
    path: '/admin/holidays/',
    name: 'Holidays',
    component: AdminHolidaySettingsContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/invoices/',
    name: 'Invoices',
    component: AdminInvoicesOverviewContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/job-offers/',
    name: 'Job Offers',
    component: AdminJobOffersContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/needs/new/',
    name: 'New Need',
    component: AdminNeedsFormContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin', 'Agent', 'Franchisee'] },
  },
  {
    path: '/admin/needs/:needID/edit',
    name: 'Edit Need',
    component: AdminNeedsEditFormContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin', 'Agent', 'Franchisee'] },
  },
  {
    path: '/admin/needs/:needID/',
    name: 'Summary',
    component: AdminNeedTabbedComponentsContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin', 'Agent', 'Franchisee'] },
  },
  {
    path: '/admin/needs/:needID/:jobOfferID/',
    name: 'Manage Applicants',
    component: AdminBidsContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin', 'Franchisee'] },
  },
  {
    path: '/admin/needs/',
    name: 'Needs',
    component: AdminNeedsOverviewContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin', 'Franchisee'] },
  },
  {
    path: '/admin/default-rates/',
    name: 'Default Rates',
    component: DefaultRatesContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/users/:userID/references/',
    name: 'References',
    component: AdminUserContainer,
    route: AuthenticatedRoute,
    roles: ['Admin', 'Recruiter'],
  },
  {
    path: '/admin/users/:userID/references/add/',
    name: 'Add Reference',
    component: AdminUserReferenceAddContainer,
    route: AuthenticatedRoute,
    roles: ['Admin', 'Recruiter'],
  },
  {
    path: '/admin/users/:userID/references/:referenceID/',
    name: 'Edit Reference',
    component: AdminUserReferenceEditContainer,
    route: AuthenticatedRoute,
    roles: ['Admin', 'Recruiter'],
  },
  {
    path: '/admin/users/:userID/work-history/',
    name: 'Work History',
    component: AdminUserContainer,
    route: AuthenticatedRoute,
    roles: ['Admin', 'Recruiter'],
  },
  {
    path: '/admin/users/:userID/work-history/add/',
    name: 'Add Work History',
    component: CarerWorkHistoryAddContainer,
    route: AuthenticatedRoute,
    roles: ['Admin', 'Recruiter'],
  },
  {
    path: '/admin/users/:userID/work-history/:workHistoryInstanceID/',
    name: 'Edit Work History',
    component: CarerWorkHistoryEditContainer,
    route: AuthenticatedRoute,
    roles: ['Admin', 'Recruiter'],
  },
  {
    path: '/admin/staff/new/',
    name: 'Create Staff',
    component: AdminCreateStaffContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/franchises/',
    name: 'Franchises',
    component: AdminFranchisesOverviewContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin', 'Franchisee'] },
  },
  {
    path: '/admin/franchises/:franchiseID/',
    name: 'Summary',
    component: AdminFranchiseSummaryContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/franchises/:franchiseID/user/',
    name: 'Create Franchise User',
    component: AdminFranchiseUserFormContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin', 'Franchisee'] },
  },
  {
    path: '/admin/applicant/new/',
    name: 'Invite Applicant',
    component: AdminApplicantInviteFormContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/teams/',
    name: 'Teams',
    component: AdminTeamsOverviewContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/users/:userID/addTestimonial',
    name: 'Add Testimonial',
    component: CarerTestimonialsFormContainer,
    route: AuthenticatedRoute,
    roles: ['Admin', 'Recruiter'],
  },
  {
    path: '/admin/users/:userID/:testimonialID/',
    name: 'Edit Testimonial',
    component: CarerTestimonialsFormContainer,
    route: AuthenticatedRoute,
    roles: ['Admin', 'Recruiter'],
  },
  {
    path: '/admin/timesheets/',
    name: 'Timesheets',
    component: AdminTimesheetsOverviewContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin'] },
  },
  {
    path: '/admin/users/:userID/',
    name: 'User',
    component: AdminUserContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Admin', 'Agent', 'Recruiter'] },
  },
  {
    path: '/carers/profile/',
    name: 'Edit Profile',
    component: CarerEditProfileContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/schedule/',
    name: 'Schedule',
    component: UserSchedulesContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/care-team/',
    name: 'Care Team',
    component: CustomerTeamsContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/care-details/',
    name: 'Care Details',
    component: CareNeedManageContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/references/',
    name: 'References',
    component: CarerReferenceSummaryContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/references/add/',
    name: 'Add',
    component: CarerReferenceAddContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/references/summary/',
    name: 'Summary',
    component: CarerReferenceSummaryContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/references/:referenceID/',
    name: 'Edit Reference',
    component: CarerReferenceEditContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/work-history/',
    name: 'Work History',
    component: CarerWorkHistorySummaryContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/work-history/summary/',
    name: 'Summary',
    component: CarerWorkHistorySummaryContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/work-history/add/',
    name: 'Add',
    component: CarerWorkHistoryAddContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/work-history/:workHistoryInstanceID/',
    name: 'Edit',
    component: CarerWorkHistoryEditContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/care-solved/',
    name: 'Care Solved',
    component: CareSolvedContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/carers/:carerID/',
    name: 'Carer Profile',
    component: CarerProfileContainer,
    route: composedLoginWrapper(Route),
    extraProps: { availableLoggedIn: true },
  },
  {
    path: '/change-password/',
    name: 'Change Password',
    component: AccountChangePasswordContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/charges/:chargeID',
    name: 'Edit',
    component: ChargeDetailsContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/charges/',
    name: 'Charges',
    component: CustomerChargesOverviewContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/home/',
    name: 'Home',
    component: UserHomeContainer,
    route: AuthenticatedRoute,
    extraProps: { roles: ['Carer', 'Customer', 'Recruiter', 'Agent', 'Franchisee'] },
  },
  {
    path: '/invoices/:invoiceID/',
    name: 'View',
    component: InvoiceDetailsContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/invoices/',
    name: 'Invoices',
    component: CustomerInvoicesOverviewContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/jobs/:jobOfferReference/',
    name: 'Job Details',
    component: JobOfferContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/jobs/',
    name: 'Jobs',
    component: CarerJobOffersOverviewContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/logout/',
    name: 'Logout',
    component: AccountLoginContainer,
    route: LogoutRoute,
  },
  {
    path: '/needs/:needID/',
    name: 'Manage',
    component: CareNeedManageContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/onboarding/',
    name: 'Onboarding',
    component: CarerOnboardingContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/stripe/connected/',
    name: 'Stripe Connected',
    component: StripeAuthContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/terms/',
    name: 'Terms & Conditions',
    component: AccountTermsContainer,
    route: SiteRouteWrapper(Route),
  },
  {
    path: '/privacy-policy/',
    name: 'Privacy Policy',
    component: SitePrivacyPolicyContainer,
    route: SiteRouteWrapper(Route),
  },
  {
    path: '/terms-and-conditions/',
    name: 'Terms & Conditions',
    component: SiteTermsAndConditionsContainer,
    route: SiteRouteWrapper(Route),
  },
  {
    path: '/timesheets/:timesheetID/',
    name: 'Edit',
    component: TimesheetDetailsContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/timesheets/:timesheetID/confirm/',
    name: 'Confirm',
    component: TimesheetDetailsConfirmContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/timesheets/',
    name: 'Timesheets',
    component: CarerTimesheetsOverviewContainer,
    route: AuthenticatedRoute,
  },
  {
    path: '/unauthorised/',
    name: 'Not Authorised',
    component: NotAuthorisedContainer,
    route: Route,
  },
];

export default routes;
