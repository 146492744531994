import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Module from 'components/global/layout/Module';
import CarerWorkHistoryFormContainer from 'containers/carers/CarerWorkHistoryFormContainer';

class CarerWorkHistoryAdd extends Component {
  static propTypes = {
    initialValues: PropTypes.shape(),
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialValues: null,
  };

  render() {
    return (
      <section>
        <Module>
          <CarerWorkHistoryFormContainer
            onSubmit={this.props.onSubmit}
            initialValues={this.props.initialValues}
          />
        </Module>
      </section>
    );
  }
}

export default CarerWorkHistoryAdd;