import React from 'react';
import styled from 'styled-components';

import BasicCheckbox from 'components/global/form/inputs/BasicCheckbox';
import BasicInput from 'components/global/form/inputs/BasicInput';
import CurrencyInput from 'components/global/form/inputs/CurrencyInput';
import DateInput from 'components/global/form/inputs/DateInput';
import PercentageInput from 'components/global/form/inputs/PercentageInput';

const inputTypes = {
  BasicCheckbox,
  BasicInput,
  CurrencyInput,
  DateInput,
  PercentageInput,
};

const InputWrapper = styled.div`
  align-items: center;
  background: ${({ background }) => background};
  border: ${({ border }) => border};
  display: flex;
  height: ${({ height }) => height};
  justify-content: center;
  margin: ${({ margin }) => margin};
  max-width: 100%;
  width: ${({ width }) => width};

  & > input {
    background: transparent;
    border: none;
    color: ${({ color }) => color};
    font-size: ${({ fontSize }) => fontSize};
    height: 100%;
    padding: ${({ padding }) => padding};
    text-align: ${({ textAlign }) => textAlign};
    width: ${({ inputWidth }) => inputWidth};
  }
`;

const InputPicker = ({
  background,
  border = '1px solid #dbdbdb',
  color = '#484848',
  fontSize = '18px',
  height = '100%',
  id,
  inputType = 'text',
  inputWidth = '100%',
  margin = 'auto',
  name,
  onBlur,
  onChange,
  padding = '5px',
  placeholder,
  textAlign = 'center',
  type = 'BasicInput',
  value,
  width,
  ...otherProps
}) => {
  const Input = inputTypes[inputType];

  return (
    <InputWrapper
      background={background}
      border={border}
      color={color}
      key={name}
      fontSize={fontSize}
      height={height}
      inputWidth={inputWidth}
      margin={margin}
      padding={padding}
      textAlign={textAlign}
      width={width}
    >
      <Input
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        type={type}
        {...otherProps}
      />
    </InputWrapper>
  );
};

export default InputPicker;
