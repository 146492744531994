// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';

// $FlowFixMe - Needs a fix for styled components
const StyledFlexTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: white;

  ${props =>
    props.moduleStyling &&
    css`
      border-radius: 7px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    `}
`;

type Props = {
  children: React.Node,
  className?: string,
  moduleStyling?: boolean,
};

const FlexTable = ({ children, className, moduleStyling }: Props) => {
  return (
    <StyledFlexTable className={className} moduleStyling={moduleStyling}>
      {children}
    </StyledFlexTable>
  );
};

export default FlexTable;
