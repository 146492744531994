import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import CareTeamConstants from 'shared/constants/CareTeamConstants';
import SuperCarersClient from 'clients/SuperCarersClient';

export function* sendConfirmationEmail(action) {
  try {
    const response = yield call(
      SuperCarersClient[action.method],
      action.endpoint,
    );
    yield put({ ...action.onSuccess, data: response.data });
  } catch (e) {
    yield put(
      toastrActions.add({
        type: 'error',
        public: false,
        title: e.response.data.message,
        options: {
          showCloseButton: true,
        },
      }),
    );
  }
}

export function* sendConfirmationEmailListener() {
  yield takeLatest(
    CareTeamConstants.SEND_CARER_CONFIRMATION,
    sendConfirmationEmail,
  );
}
