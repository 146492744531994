import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { goBack } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';

import App from 'components/App';

import { getTaxonomies } from 'shared/selectors/taxonomySelectors';
import {
  getUser,
  getImpersonator,
  getFeatures,
  isImpersonating,
  isAdmin,
  isAgent,
} from 'shared/selectors/accountSelectors.js';

import { identifyUser } from 'logRocketHelper';
import store from 'store';

class MainAppContainer extends Component {
  componentDidMount() {
    const { impersonator, isImpersonating } = this.props;
    let user = this.props.user;
    if (isImpersonating) {
      user = impersonator;
    }
    if (!window.global.DEVELOPMENT) {
      Sentry.configureScope(scope => {
        scope.setUser({
          id: user.id ? user.id : 'Unknown',
          roles: user.roles ? user.roles : 'Unknown',
          email: user.email ? user.email : 'Unknown',
        });
      });

      identifyUser(user);
    }
  }

  render() {
    const {
      user,
      impersonator,
      features,
      isImpersonating,
      isAdmin,
      isAgent,
      goBack,
      taxonomies,
    } = this.props;

    const location = store.getState().router.location;

    return (
      <App
        user={user}
        impersonator={impersonator}
        features={features}
        impersonating={isImpersonating}
        isAdmin={isAdmin}
        isAgent={isAgent}
        location={location}
        goBack={goBack}
        taxonomies={taxonomies}
      >
        {this.props.children}
      </App>
    );
  }
}

MainAppContainer.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.shape(),
  impersonator: PropTypes.shape(),
  features: PropTypes.shape(),
  isImpersonating: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isAgent: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  taxonomies: PropTypes.shape(),
};

MainAppContainer.defaultProps = {
  user: null,
  impersonator: null,
  features: {},
  taxonomies: null,
};

const mapStateToProps = state => ({
  user: getUser(state),
  impersonator: getImpersonator(state),
  features: getFeatures(state),
  isImpersonating: isImpersonating(state),
  isAdmin: isAdmin(state),
  isAgent: isAgent(state),
  taxonomies: getTaxonomies(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goBack,
    },
    dispatch,
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MainAppContainer),
);
