import styled from 'styled-components';

export default styled.ul`
  list-style: none;

  > li:before {
    content: '✔ ';
    color: ${props => props.theme.colors.hiAccentBlue};
    font-size: 18px;
    position: relative;
    top: 2px;
    margin-right: 5px;
    left: 0;
  }
`;
