import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import DropdownHelperMenu from 'components/global/DropdownHelperMenu';

const StyledDiv = styled.div`
  background-color: ${props => props.theme.colors.lightGrey};
  display: none;
  position: relative;

  @media (min-width: 768px) {
    display: block;
  }
`;

class AppHelpers extends Component {
  static propTypes = {
    impersonating: PropTypes.bool.isRequired,
    user: PropTypes.shape(),
  };

  static defaultProps = {
    user: null,
  };

  render() {
    const { user, impersonating } = this.props;
    return (
      <StyledDiv>
        <DropdownHelperMenu user={user} impersonating={impersonating} />
      </StyledDiv>
    );
  }
}

export default AppHelpers;
