import { takeLatest, take, put, all } from 'redux-saga/effects';

import { getNeedByID, createNeed, updateNeed } from '_actions/needActions';
import { getUserById, createCustomer } from '_actions/userActions';
import CareNeedConstants from 'shared/constants/CareNeedConstants';
import UserConstants from 'shared/constants/UserConstants';
import AccountConstants from 'shared/constants/AccountConstants';
import { updateAccount } from '_actions/accountActions';
import { push } from 'react-router-redux';
import { actions as toastrActions } from 'react-redux-toastr';

export function* getNeedByIdWithUser(action) {
  try {
    yield put(getNeedByID(action.needId));
    const needResult = yield take([
      CareNeedConstants.CARE_NEED_GET_BY_ID_SUCCESS,
      CareNeedConstants.CARE_NEED_GET_BY_ID_FAILURE,
    ]);
    if (needResult.type === CareNeedConstants.CARE_NEED_GET_BY_ID_SUCCESS) {
      yield put(getUserById(needResult.data.user_id));
      const userResult = yield take([
        UserConstants.USER_GET_BY_ID_SUCCESS,
        UserConstants.USER_GET_BY_ID_FAILURE,
      ]);
      if (userResult.type === UserConstants.USER_GET_BY_ID_SUCCESS) {
        yield put({
          type: CareNeedConstants.CARE_NEED_GET_BY_ID_WITH_USER_SUCCESS,
        });
      } else {
        yield put({
          type: CareNeedConstants.CARE_NEED_GET_BY_ID_WITH_USER_FAILURE,
        });
      }
    } else {
      yield put({
        type: CareNeedConstants.CARE_NEED_GET_BY_ID_WITH_USER_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: CareNeedConstants.CARE_NEED_GET_BY_ID_WITH_USER_FAILURE,
    });
  }
}

export function* getNeedByIdWithUserListener() {
  yield takeLatest(
    CareNeedConstants.CARE_NEED_GET_BY_ID_WITH_USER,
    getNeedByIdWithUser,
  );
}

function* adminCreateNeed({ customerData, needData }) {
  try {
    yield put(createCustomer(customerData));
    const customerResult = yield take([
      UserConstants.USER_CREATE_CUSTOMER_SUCCESS,
      UserConstants.USER_CREATE_CUSTOMER_FAILURE,
    ]);
    if (customerResult.type === UserConstants.USER_CREATE_CUSTOMER_SUCCESS) {
      yield put(
        createNeed({
          user_id: customerResult.data.id,
          ...needData,
        }),
      );
    } else {
      return yield put({ type: CareNeedConstants.ADMIN_CREATE_NEED_FAILURE });
    }
    const needResult = yield take([
      CareNeedConstants.CARE_NEED_CREATE_SUCCESS,
      CareNeedConstants.CARE_NEED_CREATE_FAILURE,
    ]);

    if (needResult.type === CareNeedConstants.CARE_NEED_CREATE_FAILURE) {
      throw CareNeedConstants.CARE_NEED_CREATE_FAILURE;
    }

    yield put(push(`/admin/needs/${needResult.data.id}/`));
    yield put(
      toastrActions.add({
        type: 'success',
        public: false,
        title: 'Successfully created new care need',
        options: {
          showCloseButton: true,
        },
      }),
    );
    return yield put({ type: CareNeedConstants.ADMIN_CREATE_NEED_SUCCESS });
  } catch (error) {
    return yield put({ type: CareNeedConstants.ADMIN_CREATE_NEED_FAILURE });
  }
}

export function* createNeedListener() {
  yield takeLatest(CareNeedConstants.ADMIN_CREATE_NEED, adminCreateNeed);
}

function* updateNeedAndCustomer({ customerData, needData }) {
  const successes = [
    CareNeedConstants.CARE_NEED_UPDATE_SUCCESS,
    AccountConstants.ACCOUNT_UPDATE_SUCCESS,
  ];
  const failures = [
    CareNeedConstants.CARE_NEED_UPDATE_FAILURE,
    AccountConstants.ACCOUNT_UPDATE_FAILURE,
  ];
  const updatesPending = new Set(successes);

  yield all([
    put(updateAccount({ id: customerData.id, ...customerData })),
    put(updateNeed({ id: needData.id, ...needData })),
  ]);

  while (updatesPending.size) {
    const { type } = yield take([...successes, ...failures]);
    if (failures.includes(type)) {
      return yield put({
        type: CareNeedConstants.NEED_AND_CUSTOMER_UPDATE_FAILURE,
      });
    }
    updatesPending.delete(type);
  }

  yield put(push(`/admin/needs/${needData.id}/#care-need`));
  yield put(
    toastrActions.add({
      type: 'success',
      public: false,
      title: 'Successfully updated care need',
      options: {
        showCloseButton: true,
      },
    }),
  );
  return yield put({
    type: CareNeedConstants.NEED_AND_CUSTOMER_UPDATE_SUCCESS,
  });
}

export function* updateNeedAndCustomerListener() {
  yield takeLatest(
    CareNeedConstants.NEED_AND_CUSTOMER_UPDATE,
    updateNeedAndCustomer,
  );
}
