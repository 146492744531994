// @flow
import React, { useState } from 'react';
import styled from 'styled-components';

import InlineValidationError from 'components/forms/InlineValidationError';
import StyledLabelCheckbox from 'components/forms/StyledLabelCheckbox';
import StyledTitleWithLabel from 'components/forms/StyledTitleWithLabel';

import type { InputType, MetaType } from 'shared/types';

const StyledLabelCheckboxWrapper = styled.div`
  display: block;
  position: relative;
  @media (min-width: 768px) {
    display: inline-block;
    width: 47%;
    &:nth-child(2n) {
      margin-right: 4%;
    }
  }
`;

const CustomStyledLabelCheckbox = styled(StyledLabelCheckbox)`
  width: 100%;

  &.added {
    > input:checked + label {
      border-color: ${props => props.theme.colors.changeGreen};
      box-shadow: none;
      color: ${props => props.theme.colors.changeGreen};
    }
  }

  &.removed {
    > label {
      box-shadow: 0 0 6px 0 ${props => props.theme.colors.shadowOrange};
    }
  }
`;

const StyledPicker = styled.label`
  border-left: 1px solid ${props => props.theme.colors.uiGrey};
  position: absolute;
  bottom: 9px;
  right: 0;
  top: 2px;
  padding: 5px;

  &:hover {
    cursor: pointer;
  }

  > input {
    position: relative;
    top: 30%;
  }
`;

const StyledOptionsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

type Props = {|
  changes: {
    added: Array<string>,
    removed: Array<string>,
  },
  extraPadding: boolean,
  greyTitle: boolean,
  help: string,
  higherContrast?: boolean,
  input: InputType,
  label: string,
  meta: MetaType,
  options: [
    {
      label: string,
      key: string,
    },
  ],
  pick: (string, boolean) => void,
  tag: string,
  title: string,
  selectAll?: string,
|};

const StandardMultiCheckboxGroup = ({
  changes = { added: [], removed: [] },
  extraPadding,
  greyTitle,
  help = '',
  higherContrast,
  input,
  label,
  options,
  pick,
  tag,
  title,
  selectAll,
  meta: { touched, error, warning },
}: Props) => {
  const checkIfAllSelected = inputValue => {
    return options.every(option => inputValue.includes(option.key));
  };

  const [allSelected, toggleAllSelected] = useState(
    checkIfAllSelected(input.value),
  );

  const onChange = e => {
    e.persist();
    e.stopPropagation();
    const newValue = [...input.value];
    if (e.target.checked) {
      newValue.push(e.target.value);
    } else {
      newValue.splice(newValue.indexOf(e.target.value), 1);
    }
    input.onChange(newValue);
    toggleAllSelected(checkIfAllSelected(newValue));
  };

  const selectAllOptions = e => {
    const addAllToInput = options.map(option => option.key);
    toggleAllSelected(true);
    return input.onChange(addAllToInput);
  };

  return (
    <div id={`${input.name}`}>
      <StyledTitleWithLabel
        title={title}
        label={label}
        extraPadding={extraPadding}
        greyTitle={greyTitle}
        tag={tag}
        help={help}
        error={touched && error}
      />
      <StyledOptionsList>
        {selectAll && !allSelected && (
          <StyledLabelCheckboxWrapper key={'select_all'}>
            <CustomStyledLabelCheckbox
              name={'select_all'}
              value={'select_all'}
              label={selectAll}
              onChange={selectAllOptions}
              higherContrast={higherContrast}
            />
          </StyledLabelCheckboxWrapper>
        )}
        {options.map(option => (
          <StyledLabelCheckboxWrapper key={option.key}>
            <CustomStyledLabelCheckbox
              name={option.key}
              value={option.key}
              checked={input.value.includes(option.key)}
              label={option.label}
              onChange={onChange}
              higherContrast={higherContrast}
              className={`
              ${changes.added.includes(option.key) ? 'added' : ''}
              ${changes.removed.includes(option.key) ? 'removed' : ''}
            `}
            />
            {pick &&
              !!input.value.includes(option.key) &&
              changes.added.includes(option.key) && (
                <StyledPicker
                  htmlFor={`${option.key}-picker-input`}
                  key={`${option.key}-picker`}
                >
                  <input
                    id={`${option.key}-picker-input`}
                    onChange={e => {
                      e.persist();
                      pick(option.key, e.target.checked);
                    }}
                    type="checkbox"
                  />
                </StyledPicker>
              )}
          </StyledLabelCheckboxWrapper>
        ))}
      </StyledOptionsList>
      {touched &&
        ((error && <InlineValidationError>{error}</InlineValidationError>) ||
          (warning && (
            <InlineValidationError>{warning}</InlineValidationError>
          )))}
    </div>
  );
};

export default StandardMultiCheckboxGroup;
