import React from 'react';
import styled from 'styled-components';

const StyledDefaultEventContainer = styled.div``;

const StyledMonthEventContainer = styled(StyledDefaultEventContainer)`
  display: flex;
  align-items: left;
  flex-direction: row;
`;

const StyledEventDetail = styled.div`
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledNameDetail = styled(StyledEventDetail)`
  font-weight: bold;
  margin-bottom: 5px;
  white-space: nowrap;
  width: 100%;
  overflow-wrap: break-word;
  overflow: hidden;
`;

const AmendBadge = styled.div`
  background: ${props => props.theme.colors.calendarAmend};
  color: white;
  display: inline-block;
  font-size: 14px;
  border-radius: 20px;
  margin-bottom: 4px;
  padding: 5px 10px;
  text-align: center;
`;

const AdhocBadge = styled(AmendBadge)`
  background: ${props => props.theme.colors.calendarAdhoc};
`;

export function CustomMonthEvent({ event }) {
  const nameDetail = event.name ? event.name : 'Not yet assigned';
  return (
    <StyledMonthEventContainer>
      {event.onHoldBadge && <AmendBadge name="onHold">On Hold</AmendBadge>}
      {event.amendedBadge && !event.onHoldBadge && !event.adhocBadge && (
        <AmendBadge name="amended">Amended</AmendBadge>
      )}
      {event.adhocBadge && <AdhocBadge name="adHoc">Adhoc</AdhocBadge>}
      <StyledNameDetail>{nameDetail}</StyledNameDetail>
      <StyledEventDetail>
        {event.shiftTypes.find(({ taxon_id }) => taxon_id === event.type).value}
      </StyledEventDetail>
    </StyledMonthEventContainer>
  );
}

export function CustomEvent({ event }) {
  const nameDetail = event.name ? event.name : 'Not yet assigned';
  return (
    <StyledDefaultEventContainer>
      {event.onHoldBadge && <AmendBadge name="onHold">On Hold</AmendBadge>}
      {event.amendedBadge && !event.onHoldBadge && !event.adhocBadge && (
        <AmendBadge name="amended">Amended</AmendBadge>
      )}
      {event.adhocBadge && <AdhocBadge name="adHoc">Adhoc</AdhocBadge>}
      <StyledNameDetail>{nameDetail}</StyledNameDetail>
      <StyledEventDetail>
        {event.shiftTypes.find(({ taxon_id }) => taxon_id === event.type).value}
      </StyledEventDetail>
    </StyledDefaultEventContainer>
  );
}
