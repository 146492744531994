import PropTypes from 'prop-types';
import React from 'react';

const NonSmokerIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g id="nonsmoker-icon" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-159.000000, -204.000000)">
          <g transform="translate(158.500000, 202.000000)">
            <g transform="translate(0.195143, 2.500000)">
              <path
                d="M14.5930409,8.87339015 L14.5930409,10.9160723"
                stroke="currentColor"
                strokeWidth="0.6"
                strokeLinecap="square"
              />
              <g transform="translate(3.572979, 4.000000)">
                <path
                  d="M10.112103,0.94184766 C10.3065129,0.751302252 10.5633983,0.646419226 10.8355044,0.646419226 C11.4054456,0.646419226 11.8691479,1.11015542 11.8691479,1.6802323 C11.8691479,1.84030265 11.8323338,1.99525427 11.7597903,2.14064641 C11.7293152,2.20166433 11.7293152,2.27346209 11.7599259,2.3346156 C11.790367,2.39566742 11.8479612,2.43871896 11.9151148,2.45102424 C12.4061056,2.54041549 12.7625859,2.96757483 12.7625859,3.46693872 C12.7625859,3.58663554 12.8596383,3.68382353 12.9794707,3.68382353 C13.0993031,3.68382353 13.196525,3.58663554 13.196525,3.46693872 C13.196525,2.84601358 12.8082476,2.30349646 12.2434929,2.09295074 C12.2830867,1.95935539 12.3030871,1.82138709 12.3030871,1.6802323 C12.3030871,0.870999074 11.6447037,0.212649606 10.8355044,0.212649606 C10.4491253,0.212649606 10.0844416,0.361465536 9.80847106,0.631876622 C9.72280868,0.715674566 9.72148662,0.852998787 9.80528456,0.938695067 C9.88911641,1.02422185 10.0265762,1.0256795 10.112103,0.94184766 Z"
                  fill="currentColor"
                />
                <path
                  d="M1.01298241,4.55066351 L1.01298241,7.23879893 L12.6017042,7.23879893 C12.9883036,7.23879893 13.3017042,6.92539826 13.3017042,6.53879893 L13.3017042,5.25066351 C13.3017042,4.86406418 12.9883036,4.55066351 12.6017042,4.55066351 L1.01298241,4.55066351 Z"
                  stroke="currentColor"
                  strokeWidth="0.6"
                  fill="none"
                />
              </g>
              <g>
                <path
                  d="M4.76205597,3.75325706 L16.9277519,15.5067814"
                  stroke="currentColor"
                  strokeLinecap="square"
                />
                <path
                  d="M10.5729476,0.124357336 C5.28177981,0.124357336 0.992978801,4.41309614 0.992978801,9.70432615 C0.992978801,14.9955562 5.28177981,19.2843572 10.5729476,19.2843572 C15.8634311,19.2843572 20.1529786,14.9948096 20.1529786,9.70432615 C20.1529786,4.41309614 15.8634311,0.124357336 10.5729476,0.124357336 Z M10.5729476,18.0867678 C5.9434713,18.0867678 2.19056822,14.3338025 2.19056822,9.70432615 C2.19056822,5.07484983 5.9434713,1.32194676 10.5729476,1.32194676 C15.2024239,1.32194676 18.9553892,5.07484983 18.9553892,9.70432615 C18.9553892,14.3338025 15.2024239,18.0867678 10.5729476,18.0867678 Z"
                  fill="currentColor"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

NonSmokerIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

NonSmokerIcon.defaultProps = {
  className: '',
  height: '18px',
  width: '18px',
};

export default NonSmokerIcon;
