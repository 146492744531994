// @flow
import React, { type Node } from 'react';

import StandardSelect from 'components/forms/StandardSelect';

import { type TaxonomyType } from 'shared/types';

type SelectProps = {
  help: Node,
  input: Object,
  label: string,
  children: Node,
  prefix: string,
  suffix: string,
  meta: Object,
  disabled: boolean,
};

export const TrueFalseSelect = (props: SelectProps) => (
  <StandardSelect {...props}>
    <option value="">Please select&hellip;</option>
    <option value={true}>Yes</option>
    <option value={false}>No</option>
  </StandardSelect>
);

type TaxonomySelectProps = {
  ...SelectProps,
  taxonomy: TaxonomyType,
};

export const TaxonomySelect = (props: TaxonomySelectProps) => (
  <StandardSelect {...props}>
    <option value="">Select option</option>
    {props.taxonomy.map(taxon => (
      <option key={taxon.taxon_id} value={taxon.taxon_id}>
        {taxon.value}
      </option>
    ))}
  </StandardSelect>
);
