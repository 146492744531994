import PropTypes from 'prop-types';
import React from 'react';

const RatesIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 24"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="rates-icon"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path d="M22.1500284,23.2295067 L1.62073378,23.2295067 C0.727169225,23.2295067 0,22.5028777 0,21.6087729 L0,5.16967014 C0,4.409546 0.540244595,3.74234392 1.28470165,3.58405225 L18.0506524,0.0308635547 C18.5374128,-0.0636792494 19.0252537,0.0616574966 19.3996432,0.37013716 C19.7740327,0.678616824 19.98905,1.13458326 19.98905,1.62026315 L19.98905,4.36200447 C19.98905,4.66021949 19.7470204,4.90224907 19.4488054,4.90224907 C19.1505904,4.90224907 18.9085608,4.66021949 18.9085608,4.36200447 L18.9085608,1.62026315 C18.9085608,1.39876287 18.7853851,1.26370172 18.7119118,1.20373457 C18.6389788,1.14376742 18.4850091,1.05138559 18.2656698,1.08974296 L1.50890315,4.64131093 C1.26039064,4.6942549 1.08048919,4.91629543 1.08048919,5.16967014 L1.08048919,21.6087729 C1.08048919,21.9069879 1.32305901,22.1490175 1.62073378,22.1490175 L22.1500284,22.1490175 C22.4477032,22.1490175 22.690273,21.9069879 22.690273,21.6087729 L22.690273,16.206327 C22.690273,15.908112 22.9323026,15.6660824 23.2305176,15.6660824 C23.5287326,15.6660824 23.7707622,15.908112 23.7707622,16.206327 L23.7707622,21.6087729 C23.7707622,22.5028777 23.0435929,23.2295067 22.1500284,23.2295067" />
        <path d="M23.2305176,11.3441256 C22.9323026,11.3441256 22.690273,11.1026363 22.690273,10.803881 L22.690273,5.98273826 C22.690273,5.68452324 22.4477032,5.44249366 22.1500284,5.44249366 L1.08048919,5.44249366 C0.782274173,5.44249366 0.540244595,5.20046408 0.540244595,4.90224907 C0.540244595,4.60349381 0.782274173,4.36200447 1.08048919,4.36200447 L22.1500284,4.36200447 C23.0435929,4.36200447 23.7707622,5.08863345 23.7707622,5.98273826 L23.7707622,10.803881 C23.7707622,11.1026363 23.5287326,11.3441256 23.2305176,11.3441256" />
        <path d="M16.7475824,11.3441256 C16.4499077,11.3441256 16.2073378,11.5866954 16.2073378,11.8843702 L16.2073378,15.1258378 C16.2073378,15.4240528 16.4499077,15.6660824 16.7475824,15.6660824 L23.2305176,15.6660824 C23.5281923,15.6660824 23.7707622,15.4240528 23.7707622,15.1258378 L23.7707622,11.8843702 C23.7707622,11.5866954 23.5281923,11.3441256 23.2305176,11.3441256 L16.7475824,11.3441256 Z M23.2305176,16.7465716 L16.7475824,16.7465716 C15.8540179,16.7465716 15.1268487,16.0199426 15.1268487,15.1258378 L15.1268487,11.8843702 C15.1268487,10.9908057 15.8540179,10.2636364 16.7475824,10.2636364 L23.2305176,10.2636364 C24.1240821,10.2636364 24.8512514,10.9908057 24.8512514,11.8843702 L24.8512514,15.1258378 C24.8512514,16.0199426 24.1240821,16.7465716 23.2305176,16.7465716 L23.2305176,16.7465716 Z" />
        <path d="M18.9085608,12.9648594 C18.610886,12.9648594 18.3683162,13.2074292 18.3683162,13.505104 C18.3683162,13.803319 18.610886,14.0453486 18.9085608,14.0453486 C19.2062356,14.0453486 19.4488054,13.803319 19.4488054,13.505104 C19.4488054,13.2074292 19.2062356,12.9648594 18.9085608,12.9648594 Z M18.9085608,15.1258378 C18.0149963,15.1258378 17.287827,14.3992088 17.287827,13.505104 C17.287827,12.6115394 18.0149963,11.8843702 18.9085608,11.8843702 C19.8021254,11.8843702 20.5292946,12.6115394 20.5292946,13.505104 C20.5292946,14.3992088 19.8021254,15.1258378 18.9085608,15.1258378 L18.9085608,15.1258378 Z" />
      </g>
    </svg>
  </div>
);

RatesIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

RatesIcon.defaultProps = {
  className: '',
  height: '26px',
  width: '26px',
};

export default RatesIcon;
