import PropTypes from 'prop-types';
import React from 'react';
import Link from 'react-router-redux-dom-link';

import StatusIndicator from 'components/global/StatusIndicator';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import { Container, Col, Row } from 'react-grid-system';
import StandardTable from 'components/global/layout/StandardTable';
import StyledInput from 'components/forms/StyledInput';
import Button from 'components/buttons/Button';

const AdminUserSearch = ({ users, impersonateUser, updateSearch }) => (
  <Module>
    <ModuleHeader>User Search</ModuleHeader>
    <Container>
      <Row>
        <Col>
          <StyledInput
            placeholder="Search by name or email"
            onChange={updateSearch}
          />
        </Col>
      </Row>
    </Container>
    {!!users.length && <br />}
    {!!users.length && (
      <StandardTable>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>
                <Link to={`/admin/users/${user.id}/`}>{user.name}</Link>
              </td>
              <td>
                <StatusIndicator
                  status={user.roles ? user.roles.join(', ') : null}
                />
              </td>
              <td>
                {user.roles && user.roles.includes('Franchisee') ? (
                  <Link
                    key={user.franchise_id}
                    to={`/admin/franchises/${user.franchise_id}/`}
                  >
                    View franchise
                  </Link>
                ) : (
                  user.care_needs &&
                  user.care_needs.map(need => (
                    <Link key={need.id} to={`/admin/needs/${need.id}/`}>
                      View need for {need.name}
                    </Link>
                  ))
                )}
                {user.roles && user.roles.includes('Carer') && (
                  <Link to={`/carers/${user.slug}/`}>View profile</Link>
                )}
              </td>
              <td>
                <Button
                  className="alt"
                  onClick={() => impersonateUser(user.id)}
                >
                  Impersonate
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </StandardTable>
    )}
  </Module>
);

AdminUserSearch.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  impersonateUser: PropTypes.func.isRequired,
  updateSearch: PropTypes.func.isRequired,
};

AdminUserSearch.defaultProps = {
  status: null,
};

export default AdminUserSearch;
