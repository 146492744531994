import PropTypes from 'prop-types';
import React from 'react';

const DownTriangleIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 10"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <polygon
        stroke="currentColor"
        fill="currentColor"
        points="1 1 15.4799995 1 8.23999977 9.20310503"
      />
    </svg>
  </div>
);

DownTriangleIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

DownTriangleIcon.defaultProps = {
  className: '',
  height: '10px',
  width: '16px',
};

export default DownTriangleIcon;
