// NOTE
// Remaining actions to be ported during app rebuild
// Nothing more to be done here for now
import store from 'store';

import InvoiceConstants from 'shared/constants/InvoiceConstants';

import SuperCarersClient from 'clients/SuperCarersClient';

const InvoiceActions = {
  // TODO: Create relevant saga, see InvoiceDetailsContainer.jsx for worse case
  getInvoiceById(invoiceId) {
    store.dispatch({
      type: InvoiceConstants.INVOICE_GET_BY_ID,
    });

    return SuperCarersClient.get(`/invoice/${invoiceId}/`)
      .then(body => {
        const data = body.data;

        store.dispatch({
          type: InvoiceConstants.INVOICE_GET_BY_ID_SUCCESS,
          data,
        });

        return body;
      })
      .catch(error => {
        store.dispatch({
          type: InvoiceConstants.INVOICE_GET_BY_ID_FAILURE,
          error: SuperCarersClient.errorDescription(error),
        });
      });
  },
};

export default InvoiceActions;
