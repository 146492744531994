import PropTypes from 'prop-types';
import React from 'react';
import { Container, Col } from 'components/grid';
import Link from 'react-router-redux-dom-link';
import { Field } from 'redux-form';

import Module from 'components/global/layout/Module';
import { requiredEmail, requiredPassword, validEmail } from 'validators';
import { trim } from 'normalizers';
import FormRow from 'components/forms/FormRow';
import StandardInput from 'components/forms/StandardInput';
import ValidationError from 'components/forms/ValidationError';

import Button from 'components/buttons/Button';

const AccountLogin = props => {
  const { handleSubmit, pristine, error } = props;
  return (
    <section>
      {error && <ValidationError>{error}</ValidationError>}
      <Module>
        <form onSubmit={handleSubmit}>
          <Container>
            <FormRow>
              <Col xs={12} md={8} offset={{ md: 2 }}>
                <Field
                  name="email"
                  label="Email (Staff Login)"
                  component={StandardInput}
                  type="text"
                  validate={[requiredEmail, validEmail]}
                  normalize={trim}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col xs={12} md={8} offset={{ md: 2 }}>
                <Field
                  name="password"
                  label="Password"
                  component={StandardInput}
                  type="password"
                  validate={[requiredPassword]}
                  normalize={trim}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col xs={12} md={8} offset={{ md: 2 }}>
                <Button disabled={pristine} type="submit">
                  Log in
                </Button>
              </Col>
            </FormRow>
            <FormRow>
              <Col xs={12} md={8} offset={{ md: 2 }}>
                <p>
                  Forgot your password or need a new one?{' '}
                  <Link to="/recover-password/">Click here</Link>.
                </p>
              </Col>
            </FormRow>
          </Container>
        </form>
      </Module>
    </section>
  );
};

AccountLogin.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

AccountLogin.defaultProps = {
  error: null,
  handleSubmit: () => {},
};

export default AccountLogin;
