import styled from 'styled-components';
import Editor from 'react-medium-editor';
// $FlowFixMe - waiting on flow work
import 'medium-editor/dist/css/themes/default.css';
// $FlowFixMe - waiting on flow work
import 'medium-editor/dist/css/medium-editor.css';

const WysiwygTextArea = styled(Editor)`
  font-family: inherit;
  &.isChanged {
    border-color: ${props => props.theme.colors.changeGreen};
    border-width: 2px;
  }
  background-color: ${props => props.theme.colors.lightestGrey};
  border: 1px solid;
  border-color: ${props => props.theme.colors.uiGrey};
  border-radius: 6px;
  color: ${props => props.theme.colors.formText};
  font-size: 18px;
  line-height: 24px;
  min-height: 200px;
  padding: 15px 10px;
  width: 100%;

  &.error {
    border-color: ${props => props.theme.colors.warningRed};
  }

  &:focus {
    background-color: white;
  }
`;

export default WysiwygTextArea;
