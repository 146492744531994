import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row, Col } from 'components/grid';

import CarerCard from 'components/carers/CarerCard';
import ButtonLink from 'components/buttons/ButtonLink';
import Module, {
  ModuleHeader,
  ModuleSeparator,
} from 'components/global/layout/Module';
import filterCarerSchedules from 'components/helpers/filterCarerSchedules';
import FireWithReasonButton from 'components/teams/FireWithReasonButton';
import InfoPane from 'components/careNeeds/panes/InfoPane';
import ShiftsPane from 'components/careNeeds/panes/ShiftsPane';

function CustomerApprovedMembers({
  team,
  carers,
  schedules,
  fireCareTeamMember,
  admin,
  taxonomiesByClassification,
}) {
  const members = team.members.approved;
  if (!members.length) {
    return null;
  }
  return (
    <Module>
      <ModuleHeader>Your Care Team Members</ModuleHeader>
      <Container>
        <Row>
          <Col xs={12}>
            <p>
              The following carers are currently assigned to shifts with you.
            </p>
          </Col>
        </Row>
        <ModuleSeparator />
        <Row>
          {members.map((member, index) => {
            const carer = carers[index];
            const panes = {};
            if (!carer) {
              return null;
            }

            let displayedSchedules = schedules
              ? filterCarerSchedules(schedules, member.carer_id)
              : null;

            if (!displayedSchedules || !displayedSchedules.length) {
              return null;
            }

            panes.Shifts = <ShiftsPane schedules={displayedSchedules} />;
            panes.Info = (
              <InfoPane
                admin={admin}
                member={member}
                taxonomiesByClassification={taxonomiesByClassification}
                carer={carers[index]}
                notes={member.state.notes}
                team={team}
              />
            );

            const actions = [];
            actions.push(
              <ButtonLink key="profile" to={`/carers/${carer.id}`}>
                View Profile
              </ButtonLink>,
            );
            actions.push(
              <FireWithReasonButton
                carerID={carer.id}
                taxonomiesByClassification={taxonomiesByClassification}
                teamID={team.id}
                fireCareTeamMember={fireCareTeamMember}
              />,
            );
            return (
              <Col key={carer.id} xs={12} md={12} lg={6}>
                <CarerCard carer={carer} panes={panes} actions={actions} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </Module>
  );
}

CustomerApprovedMembers.propTypes = {
  team: PropTypes.shape().isRequired,
  carers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  schedules: PropTypes.arrayOf(PropTypes.shape()),
  updateCareTeamMemberState: PropTypes.func.isRequired,
  taxonomiesByClassification: PropTypes.shape().isRequired,
  admin: PropTypes.bool,
};

CustomerApprovedMembers.defaultProps = {
  schedules: null,
  admin: false,
};

export default CustomerApprovedMembers;
