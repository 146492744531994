import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';

import { stopImpersonatingUser } from '_actions/accountActions';

import Impersonation from 'components/admin/Impersonation';

import {
  isAdmin,
  isCarer,
  isCustomer,
} from 'shared/selectors/accountSelectors';

class ImpersonationContainer extends Component {
  stopImpersonation = () => {
    this.props.stopImpersonatingUser();
    push('/');
  };

  render() {
    const { user, impersonator } = this.props;

    let role;
    if (this.props.isAdmin) {
      role = 'admin';
    } else if (this.props.isCarer) {
      role = 'carer';
    } else if (this.props.isCustomer) {
      role = 'customer';
    } else {
      role = 'user';
    }

    return (
      <Impersonation
        role={role}
        name={user.name}
        email={user.email}
        firstName={impersonator.firstname}
        stopImpersonatingUser={this.stopImpersonation}
      />
    );
  }
}

ImpersonationContainer.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  impersonator: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isCarer: PropTypes.bool.isRequired,
  isCustomer: PropTypes.bool.isRequired,
  stopImpersonatingUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAdmin: isAdmin(state),
    isCarer: isCarer(state),
    isCustomer: isCustomer(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ stopImpersonatingUser }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImpersonationContainer);
