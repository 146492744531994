import PropTypes from 'prop-types';
import React from 'react';

const CloseIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="close-icon"
        stroke="none"
        strokeWidth="2"
        fill="currentColor"
        fillRule="evenodd"
      >
        <g
          transform="translate(19.890873, 19.890873) rotate(-45.000000) translate(-19.890873, -19.890873) translate(-7.609127, -7.609127)"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M0.5,28 L54.7309875,28" />
          <path d="M27.5,55 L27.5,0.769012548" />
        </g>
      </g>
    </svg>
  </div>
);

CloseIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

CloseIcon.defaultProps = {
  className: '',
  height: '41px',
  width: '41px',
};

export default CloseIcon;
