// @flow
import React from 'react';
import Link from 'react-router-redux-dom-link';
// $FlowFixMe
import { Field } from 'redux-form';
import styled from 'styled-components';

// $FlowFixMe
import { requiredEmail, requiredPassword, validEmail } from 'validators';
// $FlowFixMe
import { trim } from 'normalizers';

import FormRow from 'components/forms/FormRow';
import StandardInput from 'components/forms/StandardInput';
import { Container, Col, Row } from 'components/grid';
import Button from 'components/buttons/Button';
import StyledLinkLogo from 'components/global/StyledLinkLogo';
import ValidationError from 'components/forms/ValidationError';

const ValidationErrorContainer = styled(ValidationError)`
  position: relative;
  top: -50px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.small}px) {
    position: absolute;
    top: -86px;
  }
`;

type Props = {
  handleSubmit: () => void,
  pristine: () => void,
  error: ?string,
};

function AccountLoginForm({ handleSubmit, pristine, error }: Props) {
  return (
    <>
      {error && <ValidationErrorContainer>{error}</ValidationErrorContainer>}
      <form onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <StyledLinkLogo />
            </Col>
          </Row>
          <FormRow>
            <Col xs={12} md={12}>
              <Field
                name="email"
                label="Email"
                component={StandardInput}
                type="text"
                validate={[requiredEmail, validEmail]}
                normalize={trim}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col xs={12} md={12}>
              <Field
                name="password"
                label="Password"
                component={StandardInput}
                type="password"
                validate={[requiredPassword]}
                normalize={trim}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col xs={12} md={12}>
              <Button disabled={pristine} type="submit" fullWidth>
                Log in
              </Button>
            </Col>
          </FormRow>
          <FormRow>
            <Col xs={12} md={12}>
              <p>
                <Link id="forgot-password-link" to="/recover-password/">
                  Reset Password
                </Link>
              </p>
            </Col>
          </FormRow>
        </Container>
      </form>
    </>
  );
}

export default AccountLoginForm;
