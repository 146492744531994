import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';

import { searchNeeds } from '_actions/needActions';

import AdminNeedSearch from 'components/admin/AdminNeedSearch';

import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { getNeedResults, getNeedSearch } from 'shared/selectors/needSelectors';

import store from 'store';

class AdminNeedSearchContainer extends Component {
  static propTypes = {
    location: PropTypes.shape().isRequired,
    needs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    search: PropTypes.shape(),
    searchNeeds: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    needs: [],
    search: null,
    taxonomiesByClassification: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'account_manager',
    ];
  }

  state = {
    settings: {
      filters: {},
    },
  };

  componentDidMount() {
    const settings = this.parseLocation(this.props);
    this.search(settings);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      const settings = this.parseLocation(nextProps);
      this.search(settings);
    }
  }

  parseLocation = props => {
    const query = props.location.query;
    const page = query.page ? parseInt(query.page, 10) : 1;
    delete query.page;
    const filters = query;
    return {
      pagination: { page, pages: 1 },
      filters,
    };
  };

  search = settings => {
    this.setState({ settings });
    this.props.searchNeeds(settings.filters, settings.pagination.page || 1);
  };

  updateFilters = e => {
    const target = e.currentTarget;
    const params = this.props.location.query;

    if (target.value) {
      params[target.name] = target.value;
    } else {
      delete params[target.name];
    }

    // reset pagination
    delete params.page;

    store.dispatch(
      push(
        `${this.props.location.pathname}?${qs.stringify(params, {
          arrayFormat: 'repeat',
        })}`,
      ),
    );
  };

  render() {
    return (
      <AdminNeedSearch
        filters={this.state.settings.filters}
        location={this.props.location}
        needs={this.props.needs}
        search={this.props.search}
        taxonomiesByClassification={this.props.taxonomiesByClassification}
        updateFilters={this.updateFilters}
      />
    );
  }
}

const mapStateToProps = state => ({
  needs: getNeedResults(state),
  search: getNeedSearch(state),
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    AdminNeedSearchContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

const mapDispatchToProps = dispatch => ({
  searchNeeds: bindActionCreators(searchNeeds, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminNeedSearchContainer);
