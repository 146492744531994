import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'components/buttons/Button';
import styled from 'styled-components';

const StyledFileUploadButton = styled.span`
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type='file'] {
    cursor: pointer;
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;

class FileUploadButton extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    return (
      <StyledFileUploadButton>
        <Button>{this.props.children}</Button>
        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          name="image"
          onChange={this.props.onChange}
        />
      </StyledFileUploadButton>
    );
  }
}

export default FileUploadButton;
