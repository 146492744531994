import { arrayOf, bool, func, object as obj, string } from 'prop-types';
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';
import BigCalendar from 'react-big-calendar';
import { PortalWithState } from 'react-portal';
import styled from 'styled-components';

import ChangeRequestContainer from 'containers/customers/ChangeRequestContainer';
import MultiSelect from 'components/forms/MultiSelect';
import StyledCheckbox from 'components/forms/StyledCheckbox';

import { Container, Row, Col } from 'components/grid';
import Module, {
  ModuleHeader,
  ModuleHero,
  ModuleSeparator,
} from 'components/global/layout/Module';

const CustomBigCalendar = styled(BigCalendar)`
  .rbc-toolbar {
    > span button {
      text-transform: capitalize;
    }
  }
  @media (max-width: 768px) {
    font-size: 11px;
    .rbc-toolbar {
      display: block;
      font-size: 11px;
      text-align: center;
      > span {
        display: block;
      }
    }
  }
`;

const CustomStyledCheckbox = styled(StyledCheckbox)`
  display: inline-block;
`;

const CustomerCheckbox = styled.div`
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 15px;
  label {
    cursor: pointer;
    margin-left: 5px;
  }
`;

class CalendarShifts extends Component {
  static propTypes = {
    calendarHeader: string.isRequired,
    checkboxSelector: bool,
    formats: obj,
    initialTime: obj,
    noShiftsMessage: string.isRequired,
    shifts: arrayOf(obj).isRequired,
    scheduleSelection: obj,
    shiftInformationModal: func.isRequired,
    shiftInformationProps: obj,
    shiftStyles: func.isRequired,
    shiftTitle: func,
    onChangeScheduleSelection: func.isRequired,
    onNavigate: func,
    taxonomiesByClassification: obj.isRequired,
  };

  static defaultProps = {
    checkboxSelector: false,
    initialTime: new Date(new Date().setHours(8, 0)),
    onNavigate: () => {},
    shiftInformationProps: {},
    scheduleSelection: null,
    formats: {},
  };

  constructor(props) {
    super(props);

    BigCalendar.momentLocalizer(moment); // or globalizeLocalizer
    this.state = {
      initialTime: props.initialTime,
    };
  }

  endAccessor = shift =>
    moment(shift.start)
      .add(shift.duration, 'm')
      .toDate();

  startAccessor = shift => moment(shift.start).toDate();

  onSelectEvent = shift => this.setState({ selectedShift: shift });

  render() {
    const {
      calendarHeader,
      checkboxSelector,
      components,
      noShiftsMessage,
      formats,
      shifts,
      scheduleSelection,
      shiftInformationModal,
      shiftInformationProps,
      shiftStyles,
      shiftTitle,
      onNavigate,
      onChangeScheduleSelection,
      taxonomiesByClassification,
      need,
    } = this.props;
    const { selectedShift } = this.state;

    if (shifts.length === 0) {
      return (
        <Module>
          <ModuleHero>{noShiftsMessage}</ModuleHero>
        </Module>
      );
    }

    const ShiftInformationModal = shiftInformationModal;
    const shiftInformationModalProps = {
      selectedShift,
      shiftTypes: taxonomiesByClassification.shift_type,
      ...shiftInformationProps,
    };

    return (
      <Fragment>
        <Module>
          <Container
            style={{ paddingBottom: '0px' }}
            colGutter={2}
            rowGutter={5}
          >
            <Row>
              <Col xs={12} md={8} lg={9}>
                <ModuleHeader>{calendarHeader}</ModuleHeader>
              </Col>
              <Col xs={12} md={4} lg={3}>
                <ChangeRequestContainer
                  title="Schedule change request"
                  buttonLabel="Schedule change request"
                  need={need}
                  prefix="Change in Care Request"
                />
                <ModuleSeparator />
              </Col>
              {scheduleSelection &&
                onChangeScheduleSelection &&
                !checkboxSelector && (
                  <Col offset={{ md: 3, lg: 4 }} xs={12} md={6} lg={4}>
                    <MultiSelect
                      customPlaceholder="Schedule"
                      name="carer-calendar-schedule-select"
                      onChange={onChangeScheduleSelection}
                      {...scheduleSelection}
                    />
                  </Col>
                )}
            </Row>
            {scheduleSelection &&
              onChangeScheduleSelection &&
              checkboxSelector && (
                <Row>
                  <Col style={{ paddingLeft: '20px' }}>
                    {Object.entries(scheduleSelection.options).map(
                      ([id, name]) => (
                        <CustomerCheckbox key={id}>
                          <CustomStyledCheckbox
                            type="checkbox"
                            id={`checkbox-${id}`}
                            value={id}
                            checked={scheduleSelection.value.includes(id)}
                            name={name}
                            onChange={onChangeScheduleSelection}
                          />
                          <label htmlFor={`checkbox-${id}`}>{name}</label>
                        </CustomerCheckbox>
                      ),
                    )}
                  </Col>
                </Row>
              )}
          </Container>
          <Container style={{ height: '100vh' }}>
            <CustomBigCalendar
              defaultView={BigCalendar.Views.WEEK}
              endAccessor={this.endAccessor}
              eventPropGetter={shiftStyles}
              events={shifts}
              onSelectEvent={this.onSelectEvent}
              scrollToTime={this.state.initialTime}
              showMultiDayTimes
              startAccessor={this.startAccessor}
              step={15}
              timeslots={4}
              titleAccessor={shiftTitle}
              onNavigate={onNavigate}
              views={['month', 'week', 'day']}
              components={components}
              formats={formats}
            />
          </Container>
        </Module>
        {selectedShift && (
          <PortalWithState
            closeOnEsc
            onClose={() => this.setState({ selectedShift: null })}
            defaultOpen
          >
            {({ openPortal, closePortal, isOpen, portal }) =>
              portal(
                <ShiftInformationModal
                  closePortal={closePortal}
                  {...shiftInformationModalProps}
                />,
              )
            }
          </PortalWithState>
        )}
      </Fragment>
    );
  }
}

export default CalendarShifts;
