// Ported App 2.0? In Progress
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import StyledSelect from 'components/forms/StyledSelect';
import Button from 'components/buttons/Button';

const StyledButtonWithReason = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 240px;
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
`;

// TODO: Standard textarea component?
const ReasonPanel = styled.div`
  background: white;
  border-radius: 7px;
  box-shadow: 0 1px 4px 0 ${props => props.theme.colors.shadowGrey};
  left: 0;
  @media (min-width: 768px) {
    left: auto;
    right: 0;
  }
  padding: 10px;
  position: absolute;
  bottom: 100%;
  width: 350px;
  z-index: 9999;

  textarea {
    font-size: 18px;
    padding: 10px;
    width: 100%;
  }
`;

class ButtonWithReason extends Component {
  static propTypes = {
    action: PropTypes.func,
    allowOther: PropTypes.bool,
    className: PropTypes.string,
    cta: PropTypes.string.isRequired,
    margin: PropTypes.string,
    reasons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    otherReasoning: PropTypes.string,
    removeMargins: PropTypes.bool,
    width: PropTypes.string,
  };

  static defaultProps = {
    action: () => {},
    allowOther: false,
    className: '',
    otherReasoning: null,
  };

  constructor() {
    super();
    this.state = {
      reason: null,
      pending: false,
    };
  }

  selectReason = e => {
    e.preventDefault();
    if (e.target.value === 'other') {
      this.setState({ reason: null, other: true });
    } else if (e.target.value === '') {
      this.setState({ reason: null, other: false, pending: false });
    } else if (!e.target.value) {
      this.setState({ reason: null, other: false });
    } else {
      this.setState({ reason: e.target.value, other: false });
    }
  };

  updateReason = e => {
    this.setState({
      reason: e.target.value,
    });
  };

  render() {
    let other;
    let reasons;
    let clickAction;
    let isDisabled;

    if (this.state.pending) {
      if (this.props.reasons) {
        reasons = (
          <StyledSelect onChange={this.selectReason}>
            <option key="title" value={''}>
              Choose a reason
            </option>
            {this.props.reasons.map(r => (
              <option key={r.taxon_id} value={r.taxon_id}>
                {r.value}
              </option>
            ))}
            {this.props.allowOther && (
              <option key="other" value={'other'}>
                Other reason (please give details)
              </option>
            )}
          </StyledSelect>
        );
      }
      isDisabled = !this.state.reason;
      clickAction = () => this.props.action(this.state.reason);
    } else {
      clickAction = () => this.setState({ pending: true });
    }

    if (this.state.other === true) {
      other = (
        <textarea
          placeholder={this.props.otherReasoning}
          onChange={this.updateReason}
        />
      );
    }

    return (
      <StyledButtonWithReason
        margin={this.props.margin}
        width={this.props.width}
      >
        {this.state.pending && (
          <ReasonPanel>
            {reasons}
            {other}
          </ReasonPanel>
        )}
        <Button
          className={this.props.className}
          onClick={clickAction}
          disabled={isDisabled}
          removeMargins={this.props.removeMargins}
        >
          {this.state.reason ? 'Confirm Reason' : this.props.cta}
        </Button>
      </StyledButtonWithReason>
    );
  }
}

export default ButtonWithReason;
