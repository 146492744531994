import ScheduleConstants from 'shared/constants/ScheduleConstants';
import {
  addObjects,
  updateObject,
  setLoadingState,
} from 'shared/reducers/reducerHelpers';
import ShiftConstants from 'shared/constants/ShiftConstants';

const initalState = {
  loading: {
    shifts: false,
  },
};

function shifts(state = initalState, action) {
  switch (action.type) {
    case ShiftConstants.SHIFT_CREATE_FAILURE:
    case ShiftConstants.SHIFT_MODIFY_FAILURE:
    case ShiftConstants.SHIFT_DELETE_FAILURE:
    case ShiftConstants.SHIFT_GET_BY_ID_FAILURE:
    case ShiftConstants.SHIFT_GET_BY_PARAMS_FAILURE:
      return state;

    case ShiftConstants.SHIFT_GET_ALL_FOR_CUSTOMER_USER_ID:
      return setLoadingState(state, 'shifts', true);

    case ShiftConstants.SHIFT_GET_ALL_FOR_CUSTOMER_USER_ID_FAILURE:
    case ShiftConstants.SHIFT_GET_ALL_FOR_CUSTOMER_USER_ID_SUCCESS:
      return setLoadingState(state, 'shifts', false);

    case ShiftConstants.SHIFT_GET_BY_PARAMS_SUCCESS:
      return addObjects(state, action);

    case ShiftConstants.SHIFT_CREATE_SUCCESS:
    case ShiftConstants.SHIFT_MODIFY_SUCCESS:
    case ShiftConstants.SHIFT_GET_BY_ID_SUCCESS:
      return updateObject(state, { [action.data.id]: action.data });

    case ShiftConstants.SHIFT_DELETE_SUCCESS: {
      const newState = updateObject(state, {});
      delete newState[action.shiftID];
      return newState;
    }

    case ScheduleConstants.SCHEDULE_MODIFY_SUCCESS:
    case ScheduleConstants.SCHEDULE_DELETE_SUCCESS: {
      const newState = { ...state };
      Object.values(newState).forEach(shift => {
        if (
          shift.id &&
          newState[shift.id] &&
          shift.schedule_id === action.scheduleID
        ) {
          delete newState[shift.id];
        }
      });
      return newState;
    }

    case ScheduleConstants.SCHEDULE_SHIFT_UPDATE_SUCCESS:
    case ScheduleConstants.SCHEDULE_SHIFT_DELETE_SUCCESS: {
      const newState = { ...state };
      Object.values(newState).forEach(shift => {
        if (
          shift.id &&
          newState[shift.id] &&
          shift.schedule_shift_id === action.scheduleShiftID
        ) {
          delete newState[shift.id];
        }
      });
      return newState;
    }

    default:
      return state;
  }
}

export default shifts;
