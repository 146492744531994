import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCarerById } from '_actions/carerActions';
import { payCharge } from '_actions/chargeActions';
import {
  createPaymentMethod,
  getUserPaymentMethods,
} from '_actions/paymentActions';
import { getUserById } from '_actions/userActions';

import ChargeActions from 'actions/ChargeActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import ChargeDetails from 'components/charges/ChargeDetails';
import { getCarerByChargeID } from 'shared/selectors/carerSelectors';

import { getChargeByID } from 'shared/selectors/chargeSelectors';
import { getPaymentMethodsByChargeID } from 'shared/selectors/paymentSelectors';
import { getUserByChargeID } from 'shared/selectors/userSelectors';

class ChargeDetailsContainer extends Component {
  static propTypes = {
    carer: PropTypes.shape(),
    charge: PropTypes.shape(),
    customer: PropTypes.shape(),
    getCarerById: PropTypes.func.isRequired,
    getUserById: PropTypes.func.isRequired,
    getUserPaymentMethods: PropTypes.func.isRequired,
    paymentMethods: PropTypes.arrayOf(PropTypes.shape()),
    match: PropTypes.shape({
      params: PropTypes.shape({
        chargeID: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    createPaymentMethod: PropTypes.func.isRequired,
    payCharge: PropTypes.func.isRequired,
  };

  static defaultProps = {
    carer: null,
    charge: null,
    customer: null,
    paymentMethods: null,
  };

  UNSAFE_componentWillMount() {
    ChargeActions.getChargeById(this.props.match.params.chargeID).then(body => {
      const customerId = body.data.consumer_id;
      const carerId = body.data.carer_id;
      this.props.getUserById(customerId);
      this.props.getUserPaymentMethods(customerId);
      this.props.getCarerById(carerId);
    });
  }

  payCharge = () => {
    this.props.payCharge(this.state.charge.id, { payment_method: 'stripe' });
  };

  render() {
    const { charge, carer, customer } = this.props;

    if (!charge || !carer || !customer) {
      return <LoadingPlaceholder />;
    }

    return (
      <ChargeDetails
        charge={charge}
        carer={carer}
        customer={customer}
        paymentMethods={this.props.paymentMethods}
        createPaymentMethod={this.props.createPaymentMethod}
        payCharge={this.payCharge}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  carer: getCarerByChargeID(state, props.match.params.chargeID),
  charge: getChargeByID(state, props.match.params.chargeID),
  customer: getUserByChargeID(state, props.match.params.chargeID),
  paymentMethods: getPaymentMethodsByChargeID(
    state,
    props.match.params.chargeID,
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createPaymentMethod,
      getCarerById,
      getUserById,
      getUserPaymentMethods,
      payCharge,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChargeDetailsContainer);
