// @flow
import React from 'react';
import styled from 'styled-components';

import NotesForm from 'components/notes/NotesForm';
import NotesHistory from 'components/notes/NotesHistory';

import Module, { ModuleHeader } from 'components/global/layout/Module';
import { Container, Row, Col } from 'components/grid';

import type { NoteType } from 'shared/types';

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

type Props = {
  category?: string,
  notes: NoteType,
  placeholder?: string,
  addNote: ({ message: string, category?: string }) => void,
  updateNote: (string, string) => void,
};

const Notes = (props: Props) => {
  const { category, notes, placeholder, addNote, updateNote } = props;

  const saveNote = (values: { message: string, category?: string }) => {
    addNote(values);
  };

  return (
    <Module>
      <Container>
        <Row>
          <Col xs={12} md={4}>
            <StyledHeader>Notes</StyledHeader>
          </Col>
        </Row>
      </Container>
      <NotesForm
        category={category}
        saveNote={saveNote}
        placeholder={placeholder}
      />
      {!!notes.length && (
        <NotesHistory
          category={category}
          notes={notes}
          updateNote={updateNote}
        />
      )}
    </Module>
  );
};

export default Notes;
