import { createSelector } from 'reselect';

export const getCharges = state => state.charges;
export const getChargeByID = (state, id) => state.charges[id];

export const getChargeSearch = state => state.search.charges;

export const getChargeResults = createSelector(
  [getCharges, getChargeSearch],
  (charges, search) => {
    if (!search || !search.searchOrder) {
      return [];
    }
    return search.searchOrder.map(id => charges[id]);
  },
);
