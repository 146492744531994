import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { connectStripeAndRefresh } from '_actions/paymentActions';
import StripeAuth from 'components/payments/StripeAuth';
import { getUser } from 'shared/selectors/accountSelectors';

class StripeAuthContainer extends Component {
  static propTypes = {
    location: PropTypes.shape().isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    connectStripeAndRefresh: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.location.query.code) {
      this.props.connectStripeAndRefresh(
        this.props.location.query.code,
        this.props.user.id,
      );
    }
  }

  render() {
    return <StripeAuth location={this.props.location} />;
  }
}

function mapStateToProps(state) {
  return {
    user: getUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    connectStripeAndRefresh: bindActionCreators(
      connectStripeAndRefresh,
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StripeAuthContainer);
