import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import toTitleCase from 'components/helpers/toTitleCase';

const StyledDiv = styled.div`
  text-align: center;
  flex: 1;
  padding: 10px;
  white-space: nowrap;
`;

const StyledLink = styled(Link)`
  display: block;
  text-align: center;
  text-decoration: none;
`;

const StyledNumber = styled.span`
  color: ${props => props.theme.colors.formText};
  display: block;
  margin-bottom: 5px;
  font-size: 32px;
`;

const StyledState = styled.span`
  display: block;
  line-height: 18px;
`;

class SummaryState extends Component {
  render() {
    const { name, count, link } = this.props;

    let details = (
      <>
        <StyledNumber className="display number">{count}</StyledNumber>
        <StyledState className="state">{toTitleCase(name)}</StyledState>
      </>
    );

    if (link) {
      details = (
        <StyledLink className="panel" to={link}>
          {details}
        </StyledLink>
      );
    }

    return <StyledDiv>{details}</StyledDiv>;
  }
}

SummaryState.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
};

export default SummaryState;
