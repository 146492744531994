import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AdminInvoicesOverview from 'components/admin/AdminInvoicesOverview';

class AdminInvoicesOverviewContainer extends Component {
  render() {
    return <AdminInvoicesOverview location={this.props.location} />;
  }
}

AdminInvoicesOverviewContainer.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default AdminInvoicesOverviewContainer;
