import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';

class NeedResultRow extends Component {
  render() {
    const need = this.props.need;
    const created = moment(need.created_at).format('DD-MM-YYYY HH:mm');
    let age = moment(need.created_at).fromNow();

    let date_start = ''
    if (need.start_date) {
      date_start = 'Start: ' + moment(need.start_date).format('DD-MM-YYYY');
    }

    let date_end = ''
    if (need.end_date) {
      date_end = 'End: ' + moment(need.end_date).format('DD-MM-YYYY');
    }

    return (
      <tr key={this.props.id}>
        <td>{created}<br/>({age})</td>
        <td>{date_start}<br/>{date_end}</td>
        <td>{`${need.name}`}<br/>{`${need.postcode}`}</td>
        <td>
          <Link
            to={`/admin/needs/${this.props.id}/`}
            name={`${need.name}-manage`}
          >
            Manage
          </Link>
        </td>
      </tr>
    );
  }
}

NeedResultRow.propTypes = {
  need: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  taxonomiesByClassification: PropTypes.shape().isRequired,
};

export default NeedResultRow;
