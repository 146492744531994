// @flow
import React from 'react';
import { taxonomyToMultiselect } from 'components/helpers/taxonomyHelpers';
import { required, validEmail } from 'validators';

import type { TaxonomiesClassificationType } from 'shared/types';

const fieldsConfig = (
  currentValues: Object,
  extraDisplayCriteria: { editMode: boolean },
  taxonomiesByClassification: TaxonomiesClassificationType,
) => [
  {
    displayCriteria: true,
    sectionHeader: 'Source',
    sectionId: 'franchise',
    rows: [
      {
        displayCriteria: true,
        rowId: 'franchise-info',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'franchise-id',
            component: 'StandardSelect',
            fieldConfiguration: {
              name: "franchise_id",
              label: "Franchise",
            },
            options: taxonomiesByClassification.franchises,
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
    ]
  },

  {
    displayCriteria: true,
    sectionHeader: 'Contact Details',
    sectionId: 'contact-details',
    rows: [

      {
        displayCriteria: (
          currentValues.franchise_id && !extraDisplayCriteria.editMode
        ),
        rowId: 'franchise-contact-create',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'franchise-contact-create-text',
            component: 'StandardTextSpan',
            fieldConfiguration: {
              text: 'On franchise need creation, the Franchise Lead Contact will be added as the customer.',
            },
            size: {
              xs: 24,
              md: 12,
            },
          },
        ],
      },

      {
        displayCriteria: currentValues.franchise_id,
        rowId: 'franchise-contact',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'franchise-contact-text',
            component: 'StandardTextSpan',
            fieldConfiguration: {
              text: 'Please edit the selected franchise contact on the CareNeed #franchise tab.',
            },
            size: {
              xs: 24,
              md: 12,
            },
          },
        ],
      },

      {
        displayCriteria: true,
        rowId: 'contact-name',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'contact-firstname',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'contact_firstname',
              label: 'Customer name',
              type: 'text',
              disabled: currentValues.franchise_id,
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'contact-lastname',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'contact_lastname',
              label: 'Customer surname',
              type: 'text',
              disabled: currentValues.franchise_id,
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
      {
        displayCriteria: true,
        rowId: 'contact-information',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'contact-email',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'contact_email',
              label: 'Customer email',
              validate: [validEmail],
              type: 'text',
              disabled: currentValues.franchise_id,
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'contact-phone',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'contact_phone_number',
              label: 'Customer phone number',
              type: 'text',
              disabled: currentValues.franchise_id,
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
    ],
  },

  {
    displayCriteria: true,
    sectionHeader: 'Care recipient',
    sectionId: 'care-recipient',
    rows: [
      {
        displayCriteria: true,
        rowId: 'name',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'firstname',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'firstname',
              label: "Care recipient's name",
              type: 'text',
              validate: [required],
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'lastname',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'lastname',
              label: "Care recipient's surname",
              type: 'text',
              validate: [required],
            },
            size: {
              xs: 12,
              md: 6,
            },
          },

        ],
      },
      {
        displayCriteria: true,
        rowId: 'age-postcode',
        fields: [{
            displayCriteria: true,
            fieldId: 'age',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'age',
              label: "Care recipient's age",
              type: 'text',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'postcode',
            component: 'StandardInput',
            fieldConfiguration: {
              name: 'postcode',
              label: 'Postcode',
              type: 'text',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
      {
        displayCriteria: true,
        rowId: 'car-and-pets',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'needs_license',
            component: 'TrueFalseSelect',
            fieldConfiguration: {
              name: 'needs_license',
              label: 'Driver needed',
            },
            size: {
              xs: 12,
              md: 3,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'needs_car',
            component: 'TrueFalseSelect',
            fieldConfiguration: {
              name: 'needs_car',
              label: 'Car needed',
            },
            size: {
              xs: 12,
              md: 3,
            },
          },
          {
            displayCriteria: true,
            fieldId: 'pets',
            component: 'StandardMultiSelect',
            fieldConfiguration: {
              name: 'pets',
              label: 'Any pets',
              options: taxonomyToMultiselect(taxonomiesByClassification.pets),
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
    ],
  },

  {
    displayCriteria: true,
    sectionHeader: 'Job Details',
    sectionId: 'care',
    rows: [
      {
        displayCriteria: true,
        rowId: 'schedule_dates',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'schedule_start_date',
            component: 'StandardDateInput',
            wysiwyg: true,
            fieldConfiguration: {
              name: 'schedule_start_date',
              label: 'Start Date',
              type: 'date',
              placeholder: '',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },{
            displayCriteria: true,
            fieldId: 'schedule_end_date',
            component: 'StandardDateInput',
            wysiwyg: true,
            fieldConfiguration: {
              name: 'schedule_end_date',
              label: 'End Date',
              type: 'date',
              placeholder: '',
            },
            size: {
              xs: 12,
              md: 6,
            },
          },
        ],
      },
      {
        displayCriteria: true,
        rowId: 'story',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'story',
            component: 'StandardTextArea',
            fieldConfiguration: {
              name: 'story',
              label: 'Job Description',
              type: 'text',
              validate: [required],
              placeholder:
                '- (£) Rate of pay for the job\n' +
                '- (£) Travel & Food – information about any other relevant allowances for the job\n' +
                '- (£) Special rates – bank holiday etc.\n' +
                '- Who is being cared for – whether a single client or a couple.\n' +
                '- Client needs – details of the services the CP will need to provide. An accurate description of client conditions and needs which includes the activities of daily living the CP will be doing.\n' +
                '- Other relevant information – if there are pets present for example please provide additional information. If a driver is required please explain why and what driving will be needed.\n' +
                '- Any specialist training required for the job.',
            },
            size: {
              xs: 12,
              md: 12,
            },
          },
        ],
      },
      {
        displayCriteria: true,
        rowId: 'schedule_description',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'schedule_description',
            component: 'StandardTextArea',
            wysiwyg: true,
            fieldConfiguration: {
              name: 'schedule_description',
              label: 'Care Times',
              type: 'text',
              placeholder: '- Schedule and working pattern for the job, for example 1 week on and 1 week off.\n' +
                           '- Include the time and day the handover will typically take place.\n' +
                           '- If training is required, provide information on the date the CP will be expected to attend the office and make this clear to the CP.\n' +
                           '- Note when providing an end date above, if the job is ongoing just put a date next year.',
            },
            size: {
              xs: 12,
              md: 12,
            },
          },
        ],
      },
    ],
  },
  {
    displayCriteria: true,
    sectionHeader: 'Carer',
    sectionId: 'carer',
    rows: [
      {
        displayCriteria: true,
        rowId: 'gender-and-smoker',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'carer-gender',
            component: 'TaxonomySelect',
            fieldConfiguration: {
              name: 'carer_gender',
              label: 'Gender',
              type: 'text',
              taxonomy: taxonomiesByClassification.gender_preference,
            },
            size: {
              xs: 12,
              md: 12,
            },
          },
        ],
      },
    ],
  },

  {
    displayCriteria: true,
    sectionHeader: 'Admin',
    sectionId: 'admin',
    rows: [
      {
        displayCriteria: true,
        rowId: 'admin-notes',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'admin_notes',
            component: 'StandardTextArea',
            fieldConfiguration: {
              name: 'admin_notes',
              type: 'text',
              placeholder: 'Any admin notes for the need...',
            },
            size: {
              xs: 12,
              md: 12,
            },
          },
        ],
      },
    ],
  },

  {
    displayCriteria: !extraDisplayCriteria.editMode,
    sectionHeader: 'Terms of Service',
    sectionId: 'terms-of-service',
    rows: [
      {
        displayCriteria: true,
        rowId: 'confirmed-tos',
        fields: [
          {
            displayCriteria: true,
            fieldId: 'confirmed-tos',
            component: 'StandardCheckbox',
            fieldConfiguration: {
              inlineLabel: true,
              smallLabel: true,
              name: 'confirmed_tos',
              label: (
                <div>
                  I agree to the Home Instead{' '}
                  <a
                    href="https://www.supercarers.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{' '}
                  and to the{' '}
                  <a
                    href="https://www.supercarers.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms and conditions
                  </a>{' '}
                  of use for the website and platform.
                </div>
              ),
              validate: [required],
            },
            size: {
              xs: 12,
              md: 12,
            },
          },
        ],
      },
    ],
  },

];

export default fieldsConfig;
