import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { searchProfiles } from '_actions/carerActions';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AdminCarerProfileChangeOverview from 'components/admin/carers/AdminCarerProfileChangeOverview';
import { getCarerProfileSearch } from 'shared/selectors/carerSelectors';

import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

class AdminCarerProfileChangeOverviewContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      searchProfiles: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape().isRequired,
    search: PropTypes.shape(),
    taxonomiesByClassification: PropTypes.shape(),
  };

  static defaultProps = {
    search: null,
    taxonomiesByClassification: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['profile_needs_approval'];
  }

  state = {
    profileFilter: '',
    profileState: 'approved',
    includeQueried: false,
  };

  componentDidMount() {
    this.profileSearch();
  }

  UNSAFE_componentWillReceiveProps(props) {
    const page = props.location.query.page;
    if (page && page !== this.props.location.query.page) {
      this.profileSearch(page);
    }
  }

  profileSearch = page => {
    const { profileFilter, profileState, queried } = this.state;
    const payload = {
      live_status: 'draft',
      state: profileState,
      order_by: 'desc',
      latest_only: true,
      page: page || this.props.location.query.page || 1,
      fields_changed: profileFilter === '' ? undefined : profileFilter,
    };
    if (queried !== undefined) {
      payload.queried = queried;
    }
    this.props.actions.searchProfiles(payload);
  };

  filterProfiles = e => {
    e.persist();
    e.preventDefault();
    this.setState(
      () => ({ profileFilter: e.target.value }),
      () => this.profileSearch(1),
    );
  };

  filterQueried = e => {
    e.preventDefault();
    let queried;
    if (e.target.value === 'true') {
      queried = true;
    } else if (e.target.value === 'false') {
      queried = false;
    }
    this.setState(() => ({ queried }), () => this.profileSearch(1));
  };

  searchProfilesWithState = profileState => {
    this.setState(() => ({ profileState }), () => this.profileSearch(1));
  };

  render() {
    const { location, search, taxonomiesByClassification } = this.props;
    const { profileFilter, queried } = this.state;

    if (!taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    let changedProfiles = [];
    let areCarersLoaded = false;
    if (search) {
      changedProfiles = search.results;
      if (changedProfiles.length) {
        areCarersLoaded = changedProfiles.every(carer => carer !== undefined);
      }
    }

    // TODO: Test this properly
    const noPendingChanges = !areCarersLoaded || !taxonomiesByClassification;

    return (
      <AdminCarerProfileChangeOverview
        noPendingChanges={noPendingChanges}
        onSearchProfileStateChange={this.searchProfilesWithState}
        profiles={changedProfiles}
        taxonomiesByClassification={taxonomiesByClassification}
        search={search}
        location={location}
        profileFilter={profileFilter}
        filterProfiles={this.filterProfiles}
        queried={queried}
        filterQueried={this.filterQueried}
      />
    );
  }
}

const mapStateToProps = state => ({
  search: getCarerProfileSearch(state),
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    AdminCarerProfileChangeOverviewContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        searchProfiles,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminCarerProfileChangeOverviewContainer);
