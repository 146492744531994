import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AdminFranchiseSearchContainer from 'containers/admin/franchise/AdminFranchiseSearchContainer';

class AdminFranchisesOverview extends Component {
  render() {
    return (
      <div className="component-wrapper wide">
        <h1>Franchise Offices</h1>
        <AdminFranchiseSearchContainer location={this.props.location} />
      </div>
    );
  }
}

AdminFranchisesOverview.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default AdminFranchisesOverview;
