import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AdhocTimesheetCreatorContainer from 'containers/timesheets/AdhocTimesheetCreatorContainer';
import Module, { ModuleHeader } from 'components/global/layout/Module';
import Paginator from 'components/global/Paginator';
import TimesheetTable from 'components/timesheets/TimesheetTable';

class CarerTimesheetsOverview extends Component {
  render() {
    return (
      <section>
        <Module>
          <ModuleHeader>Your Timesheets</ModuleHeader>
          <TimesheetTable
            timesheets={this.props.timesheets}
            needs={this.props.needs}
          />
          {!!this.props.timesheets && this.props.timesheets.length > 0 && (
            <Paginator
              location={this.props.location}
              currentPage={parseInt(this.props.currentPage, 10)}
              totalPages={this.props.search.pagination.pages || 1}
              path="/timesheets/"
              perPage={this.props.resultsPerPage}
            />
          )}
        </Module>
        {this.props.needs && Object.keys(this.props.needs).length > 0 && (
          <AdhocTimesheetCreatorContainer
            carerID={this.props.user.id}
            needs={this.props.needs}
          />
        )}
      </section>
    );
  }
}

CarerTimesheetsOverview.propTypes = {
  location: PropTypes.shape().isRequired,
  search: PropTypes.shape(),
  timesheets: PropTypes.arrayOf(PropTypes.shape()),
  needs: PropTypes.shape().isRequired,
  currentPage: PropTypes.number.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

CarerTimesheetsOverview.defaultProps = {
  search: null,
  timesheets: null,
};

export default CarerTimesheetsOverview;
