import { all, takeLatest, take, put, select } from 'redux-saga/effects';

import BidConstants from 'shared/constants/BidConstants';
import JobOfferConstants from 'shared/constants/JobOfferConstants';
// import ScheduleConstants from 'shared/constants/ScheduleConstants';
import { searchBids } from '_actions/bidActions';
import {
  getJobOfferByIDForMultiple,
  getJobOfferByReference,
} from '_actions/jobOfferActions';
import { trackEvent } from '_actions/trackingActions';
import { getSchedulesWithScheduleShiftsByScheduleIDs } from '_actions/scheduleActions';
import { getUser } from 'shared/selectors/accountSelectors';

export function* getBidsWithJobOffersAndSchedulesAndShifts(action) {
  try {
    const { params, page, searchName } = action.payload;
    yield put(searchBids(params, page, searchName, false));
    const bidSearchResult = yield take([
      BidConstants.BID_SEARCH_SUCCESS_FOR_SAGA,
      BidConstants.BID_SEARCH_FAILURE,
    ]);
    if (bidSearchResult.type === BidConstants.BID_SEARCH_SUCCESS_FOR_SAGA) {
      const bids = bidSearchResult.data.results;
      yield all(
        bids.map(bid => put(getJobOfferByIDForMultiple(bid.job_offer_id))),
      );

      const [...jobOffersForBids] = yield all(
        Array(bids.length).fill(
          take(JobOfferConstants.JOB_OFFER_GET_BY_ID_FOR_MULTIPLE_SUCCESS),
        ),
      );

      yield put(
        getSchedulesWithScheduleShiftsByScheduleIDs(
          jobOffersForBids.map(jobOffer => jobOffer.data.schedule_id),
        ),
      );

      // TODO this is taking far too long to complete, bring it back when our api involves fewer
      // requests and responses
      /*yield take(
        ScheduleConstants.GET_SCHEDULES_WITH_SCHEDULE_SHIFTS_BY_SCHEDULE_IDS_SUCCESS,
      );*/

      yield put({
        type: BidConstants.BID_SEARCH_SUCCESS,
        data: bidSearchResult.data,
        payload: searchName,
      });

      yield put({
        type:
          BidConstants.SEARCH_BIDS_WITH_JOB_OFFERS_AND_SCHEDULES_AND_SHIFTS_SUCCESS,
        data: {
          results: jobOffersForBids.map(jobOfferForBid => jobOfferForBid.data),
        },
      });
    } else {
      yield put({
        type:
          BidConstants.SEARCH_BIDS_WITH_JOB_OFFERS_AND_SCHEDULES_AND_SHIFTS_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type:
        BidConstants.SEARCH_BIDS_WITH_JOB_OFFERS_AND_SCHEDULES_AND_SHIFTS_FAILURE,
    });
  }
}

export function* getBidsWithJobOffersAndSchedulesAndShiftsListener() {
  yield takeLatest(
    BidConstants.SEARCH_BIDS_WITH_JOB_OFFERS_AND_SCHEDULES_AND_SHIFTS,
    getBidsWithJobOffersAndSchedulesAndShifts,
  );
}

export function* getJobOfferAndBidByReferenceForCarer({ reference }) {
  yield put(getJobOfferByReference(reference));

  const jobOfferResult = yield take([
    JobOfferConstants.JOB_OFFER_GET_BY_REFERENCE_SUCCESS,
    JobOfferConstants.JOB_OFFER_GET_BY_REFERENCE_FAILURE,
  ]);

  if (
    jobOfferResult.type === JobOfferConstants.JOB_OFFER_GET_BY_REFERENCE_SUCCESS
  ) {
    yield put(searchBids({ job_offer_id: jobOfferResult.data.id }));

    const user = yield select(getUser);
    yield put(
      trackEvent('carer clicked on job', {
        email: user.email,
        customer_id: user.id,
        job_id: jobOfferResult.data.id,
      }),
    );
  }
}

export function* getJobOfferAndBidByReferenceForCarerListener() {
  yield takeLatest(
    JobOfferConstants.JOB_OFFER_GET_BY_REFERENCE_FOR_CARER,
    getJobOfferAndBidByReferenceForCarer,
  );
}
