import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCheckboxContainer = styled.div`
  display: block;
  margin: 0 auto;

  > input {
    background: white;
    border: 2px solid ${props => props.theme.colors.uiGrey};
    border-radius: 4px;
    cursor: pointer;
  }
`;

const StyledCheckbox = ({
  className,
  checked,
  disabled,
  onChange,
  style,
  name,
  id,
  value,
}) => (
  <StyledCheckboxContainer className={className} style={style}>
    <input
      type="checkbox"
      id={`${id || name}`}
      name={`${name}`}
      onChange={onChange}
      value={value}
      checked={checked}
      disabled={disabled}
    />
  </StyledCheckboxContainer>
);

export default StyledCheckbox;
