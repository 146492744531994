import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import Button from 'components/buttons/Button';

const StyledMessage = styled.div`
  textarea {
    border: 1px solid ${props => props.theme.colors.uiGrey};
    font-family: ${props => props.theme.typography.fontStack};
    font-size: ${props => props.theme.typography.defaultFontSize};
    line-height: ${props => props.theme.typography.defaultLineHeight};
    margin-bottom: 5px;
    min-height: 200px;
    padding: 10px;
    width: 100%;
  }
`;

class Message extends Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    closePortal: PropTypes.func,
    defaultValue: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    closePortal: null,
    defaultValue: '',
    placeholder: null,
  };

  state = {
    value: this.props.defaultValue,
  };

  onChange = event => {
    this.setState({ value: event.target.value });
  };

  onSubmit = () => {
    const { onSubmit, closePortal } = this.props;
    onSubmit(this.state.value);
    if (closePortal) {
      closePortal();
    }
  };

  render() {
    return (
      <StyledMessage>
        <textarea
          placeholder={this.props.placeholder}
          value={this.state.value}
          onChange={this.onChange}
        />
        <Button className="btn" onClick={this.onSubmit}>
          {this.props.buttonText}
        </Button>
      </StyledMessage>
    );
  }
}

export default Message;
