import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import PaymentConstants from 'shared/constants/PaymentConstants';

function* addPaymentMethodViaStripe(action) {
  try {
    const response = yield call(
      action.stripe.handleCardSetup,
      action.clientSecret,
      action.cardElement,
      {
        payment_method_data: {
          billing_details: {
            address: {
              line1: action.formData.street,
              city: action.formData.city,
              postal_code: action.formData.postcode,
              country: action.formData.country,
            },
            name: action.formData.name,
          },
        },
      },
    );
    if (response.error) {
      yield put({ ...action.onError });
    } else {
      yield put({ ...action.onSuccess, status: response.setupIntent.status });
      yield put(push(action.redirect));
    }
  } catch (e) {
    yield put({ ...action.systemError });
  }
}

export function* addPaymentMethodListener() {
  yield takeLatest(
    PaymentConstants.PAYMENTS_ADD_PAYMENT,
    addPaymentMethodViaStripe,
  );
}

export function* authenticatePaymentMethodViaStripe(action) {
  try {
    const response = yield call(
      action.stripe.handleCardPayment,
      action.clientSecret,
      {
        payment_method: action.paymentMethodId,
      },
    );
    if (response.error) {
      yield put({ ...action.onError });
    } else {
      yield put({ ...action.onSuccess, status: response.paymentIntent.status });
      yield put(push(action.redirect));
    }
  } catch (e) {
    yield put({ ...action.systemError });
  }
}

export function* authenticatePaymentListener() {
  yield takeLatest(
    PaymentConstants.PAYMENTS_AUTHENTICATE_PAYMENT,
    authenticatePaymentMethodViaStripe,
  );
}
