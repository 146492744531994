// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { getTestimonialsByCarerID } from '_actions/testimonialActions';

import { selectFeaturedTestimonialsByCarerID } from 'shared/selectors/testimonialSelectors';

import FeaturedTestimonials from 'components/carers/testimonials/FeaturedTestimonials';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import type {
  CarerType,
  TaxonomiesClassificationType,
  TestimonialType,
} from 'shared/types';

type Props = {|
  carer: CarerType,
  taxonomiesByClassification: ?TaxonomiesClassificationType,
  getTestimonialsByCarerID: string => void,
  featuredTestimonials: ?Array<TestimonialType>,
|};

export class FeaturedTestimonialsContainer extends Component<Props> {
  componentDidMount() {
    const { getTestimonialsByCarerID, featuredTestimonials } = this.props;
    if (!featuredTestimonials) {
      getTestimonialsByCarerID(this.props.carer.id);
    }
  }

  render() {
    const { featuredTestimonials, taxonomiesByClassification } = this.props;

    if (!featuredTestimonials || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    if (featuredTestimonials && !featuredTestimonials.length) {
      return null;
    }

    return (
      <FeaturedTestimonials
        featuredTestimonials={featuredTestimonials}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  featuredTestimonials: selectFeaturedTestimonialsByCarerID(
    state,
    props.carer.id,
  ),
  taxonomiesByClassification: getTaxonomiesByClassifications(state, [
    'testimonial_relationship',
  ]),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTestimonialsByCarerID,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeaturedTestimonialsContainer);
