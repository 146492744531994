import PropTypes from 'prop-types';
// App 2.0 component
import React, { Component } from 'react';
import StandardTable from 'components/global/layout/StandardTable';

import Button from 'components/buttons/Button';
import CardBrand from 'components/payments/CardBrand';

class RemovePaymentMethod extends Component {
  static propTypes = {
    paymentMethod: PropTypes.shape({
      id: PropTypes.string.isRequired,
      brand: PropTypes.string.isRequired,
      last4: PropTypes.string.isRequired,
      exp_month: PropTypes.number.isRequired,
      exp_year: PropTypes.number.isRequired,
    }).isRequired,
    removePaymentMethod: PropTypes.func.isRequired,
  };

  removePaymentMethod = () => {
    this.props.removePaymentMethod(this.props.paymentMethod.id);
  };

  render() {
    return (
      <StandardTable>
        <thead>
          <tr>
            <th>Card Type</th>
            <th>Card Number</th>
            <th>Card Expiry</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <CardBrand brand={this.props.paymentMethod.brand} width="80px" />
            </td>
            <td data-heading="Card #">
              ●●●● ●●●● ●●●● {this.props.paymentMethod.last4}
            </td>
            <td data-heading="Expires">
              {this.props.paymentMethod.exp_month}/
              {this.props.paymentMethod.exp_year}
            </td>
            <td>
              <Button className="danger" onClick={this.removePaymentMethod}>
                Remove Card
              </Button>
            </td>
          </tr>
        </tbody>
      </StandardTable>
    );
  }
}

export default RemovePaymentMethod;
