// @flow
import React, { type Node } from 'react';

import InputWrapper from 'components/forms/InputWrapper';
import StyledSelect from 'components/forms/StyledSelect';
import StyledTitleWithLabel from 'components/forms/StyledTitleWithLabel';

import type { MetaType } from 'shared/types';

type Props = {
  children: Node,
  disabled: boolean,
  extraPadding?: boolean,
  greyTitle?: boolean,
  help: Node,
  input: HTMLInputElement,
  label?: string,
  meta: MetaType,
  prefix: string,
  suffix: string,
  title?: ?string,
};

const StandardSelect = ({
  help,
  input,
  disabled,
  extraPadding,
  label,
  children,
  greyTitle,
  meta,
  prefix,
  suffix,
  title,
  ...otherProps
}: Props) => {
  const { touched, error } = meta;
  return (
    <InputWrapper {...{ help, meta, prefix, suffix }}>
      <StyledTitleWithLabel
        title={title}
        label={label}
        help={help}
        error={touched && error}
        extraPadding={extraPadding}
        greyTitle={greyTitle}
      />
      <StyledSelect
        disabled={disabled}
        id={`select-${input.name}`}
        className={touched && error ? 'error' : null}
        {...{ ...input, ...otherProps }}
      >
        {children}
      </StyledSelect>
    </InputWrapper>
  );
};

StandardSelect.defaultProps = {
  help: null,
  label: null,
  prefix: null,
  suffix: null,
};

export default StandardSelect;
