//@flow
import React from 'react';
import styled, { keyframes } from 'styled-components';

const imageLoad = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledAvatar = styled.img`
  position: absolute;
  left: -1px;
  top -1px;
  animation: ${imageLoad} 0.3s ease-in 1;
  color: ${({ theme }) => theme.colors.lightGrey};
  display: block;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  user-select: none;
`;

const StyledSpan = styled.span`
  position: relative;
  background-color: ${props => props.theme.colors.lightGrey};
  border-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.width / 2}px;
  border-style: solid;
  border-width: 3px;
  display: block;
  margin: 0 auto;
  overflow: hidden;

  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

type Props = {|
  alt: ?string,
  height: number,
  spanClassName: string,
  src: ?string,
  width: number,
|};

function AvatarImage({ alt, height, spanClassName, src, width }: Props) {
  if (!src || !alt) {
    return null;
  }

  return (
    <StyledSpan className={spanClassName} height={height} width={width}>
      <StyledAvatar src={src} alt={alt} />
    </StyledSpan>
  );
}

AvatarImage.defaultProps = {
  height: 98,
  spanClassName: '',
  width: 98,
};

export default AvatarImage;
