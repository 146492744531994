import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createUser } from '_actions/userActions';

import AdminCreateStaff from 'components/admin/AdminCreateStaff';

class AdminCreateStaffContainer extends Component {
  static propTypes = {
    createUser: PropTypes.func.isRequired,
  };

  render() {
    return <AdminCreateStaff createUser={this.props.createUser} />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createUser }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps,
)(AdminCreateStaffContainer);
