// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AdminFranchiseInfo from 'components/admin/franchise/AdminFranchiseInfo';

import {
  populateFranchiseWithID,
  updateFranchise,
} from '_actions/franchiseActions';

import { isAdmin } from 'shared/selectors/accountSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { getFranchiseByID } from 'shared/selectors/franchiseSelectors';
import { loadingSelectorFactory } from 'shared/selectors/loadingSelectors';
import { getUsersForFranchise } from 'shared/selectors/userSelectors';

import type { FranchiseType, TaxonomiesClassificationType } from 'shared/types';

type Props = {
  franchise: FranchiseType,
  franchiseID: string,
  franchiseUsers: Array,
  franchiseUsersLoadingState: boolean,
  isAdmin: boolean,
  populateFranchiseWithID: (value: string) => mixed,
  taxonomiesByClassification: TaxonomiesClassificationType,
  updateFranchise: (value: string, Object) => Object,
};

class AdminFranchiseInfoContainer extends Component<Props> {

  static defaultProps = {};

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'account_manager',
      'franchise_country',
      'franchise_state',
    ];
  }

  componentDidMount() {
    if (!!this.props.franchiseID) {
      this.props.populateFranchiseWithID(this.props.franchiseID);
    }
  }

  updateFranchise = (data) => {
    this.props.updateFranchise(this.props.franchiseID, data);
  };

  render() {
    const {
      franchise,
      franchiseUsersLoadingState,
      isAdmin,
      taxonomiesByClassification,
    } = this.props;

    if ( !franchise || !taxonomiesByClassification || franchiseUsersLoadingState) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminFranchiseInfo
        franchise={franchise}
        isAdmin={isAdmin}
        taxonomiesByClassification={taxonomiesByClassification}
        updateFranchise={this.updateFranchise}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    franchise: getFranchiseByID(state, props.franchiseID),
    franchiseUsers: getUsersForFranchise(state, props.franchiseID),
    franchiseUsersLoadingState: loadingSelectorFactory('franchises', 'franchiseUsers')(state),
    isAdmin: isAdmin(state),
    taxonomiesByClassification: getTaxonomiesByClassifications(
      state,
      AdminFranchiseInfoContainer.TAXONOMY_CLASSIFICATIONS,
    ),
  };
};

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      populateFranchiseWithID,
      updateFranchise,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminFranchiseInfoContainer);
