import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import styled from 'styled-components';

import { Container, Col, Row } from 'components/grid';
import { ModuleHeader } from 'components/global/layout/Module';
import FormRow from 'components/forms/FormRow';
import Button from 'components/buttons/Button';
import StandardInput from 'components/forms/StandardInput';

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

const StyledFormRow = styled(FormRow)`
  align-items: flex-end;
`;

class CarerSearchFormNoFilters extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <StyledHeader>Find carers</StyledHeader>
            </Col>
          </Row>
          <StyledFormRow>
            <Col xs={12} md={8}>
              <Field component={StandardInput} label="Carer name" name="name" />
            </Col>
            <Col xs={12} md={4}>
              <Button type="submit" disabled={pristine}>
                Search
              </Button>
            </Col>
          </StyledFormRow>
        </Container>
      </form>
    );
  }
}

export default CarerSearchFormNoFilters;
