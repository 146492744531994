import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Currency from 'components/modules/Currency';

class TimesheetTotalRefunds extends Component {
  static propTypes = {
    view: PropTypes.string.isRequired,
    tdWidth: PropTypes.string,
    refunds: PropTypes.arrayOf(
      PropTypes.shape({
        created_at: PropTypes.string.isRequired,
        description: PropTypes.string,
        totals: PropTypes.shape({
          carer_total: PropTypes.number,
          customer_total: PropTypes.number,
          application_total: PropTypes.number,
        }),
      }),
    ).isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    tdWidth: null,
  };

  render() {
    return (
      <tbody>
        {this.props.refunds.map((refund, index) => {
          const rowClass =
            index < this.props.refunds.length - 1 ? 'multiple' : '';
          if (this.props.view === 'carer' && refund.totals.carer_total === 0) {
            return null;
          }

          if (
            this.props.view === 'customer' &&
            refund.totals.customer_total === 0
          ) {
            return null;
          }

          const multiplier = this.props.view === 'carer' ? 1 : -1;

          return (
            <tr key={refund.id} className={rowClass}>
              <td rowSpan="1" colSpan="2">
                <strong>Refund: </strong>
                {refund.description}
              </td>
              <td className={`${this.props.tdWidth} number`}>
                {this.props.isAdmin && (
                  <div>
                    <strong>
                      {this.props.view === 'customer' ? 'Customer' : 'Carer'}
                    </strong>
                    <div className="supplementary-info">SuperCarers</div>
                  </div>
                )}
              </td>
              <td
                className={`${
                  this.props.tdWidth
                } td-right number number-negative`}
              >
                <strong>
                  <Currency
                    value={
                      multiplier * refund.totals[`${this.props.view}_total`]
                    }
                  />
                  {this.props.isAdmin && (
                    <div className="supplementary-info">
                      <Currency value={refund.totals.application_total} />
                    </div>
                  )}
                </strong>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export default TimesheetTotalRefunds;
