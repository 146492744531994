import _ from 'lodash';

//turns a taxon_id, or list of ids into a string
export const renderTaxon = (taxonomyClassification, ids) => {
  if (ids && ids.length) {
    if (ids[0].taxon_id) {
      ids = ids.map(({ taxon_id }) => taxon_id);
    }

    const classificationsForIDs = taxonomyClassification.filter(taxon => {
      if (typeof ids === 'string') {
        return ids === taxon.taxon_id;
      }
      return ids.includes(taxon.taxon_id);
    });

    if (classificationsForIDs.length) {
      return classificationsForIDs.map(taxon => taxon.value).join(', ');
    }

    return classificationsForIDs;
  }

  return '';
};

// Converts the need store object to a form friendly format
// Flattening any arrays with taxon_ids to just their values
// Removing any undefined, null or blank values
export const convertNeedToFormData = (need, formFields) => {
  return Object.keys(need)
    .filter(key => formFields.includes(key))
    .reduce((acc, key) => {
      const iterableObject = acc;
      const needItem = need[key];
      if (Array.isArray(needItem) && needItem.length > 0) {
        iterableObject[key] = needItem.some(item => item.taxon_id)
          ? needItem.map(item => item.taxon_id)
          : needItem;
      } else if (
        need[key] !== undefined &&
        need[key] !== null &&
        need[key] !== ''
      ) {
        iterableObject[key] = need[key];
      }
      return iterableObject;
    }, {});
};

// gets the values of any children the taxon obj might have for a specific child name
export const findChildren = (taxon, childName) => {
  if (taxon && taxon.length) {
    const children = taxon
      .filter(
        taxonObj => taxonObj.children && Object.keys(taxonObj.children).length,
      )
      .map(taxonObj => taxonObj.children);

    if (children.length) {
      const childTaxonomy = _.flatten(
        children
          .filter(child => child[childName] && child[childName].length)
          .map(taxonomy => taxonomy[childName]),
      );
      if (childTaxonomy.length) {
        return [...new Set(childTaxonomy)];
      }
    }
  }

  return null;
};

//takes an array of possible taxon objects and finds the name of the child in each, the returns a flat, unique array
export const findChildrenForMultipleTaxons = (multipleTaxons, childName) => {
  const children = [
    ...new Set(
      multipleTaxons
        .map(taxon => findChildren(taxon, childName))
        .filter(children => children)
        .flatten(),
    ),
  ];

  return children;
};

// takes an array of possible taxon objects and returns a unique arrays of children object key strings
export const childrenTaxonomyClassifications = options => {
  const optionsChildren = options
    .filter(item => Object.keys(item.children).length !== 0)
    .map(child => Object.keys(child.children));
  return [...new Set(_.flatten(optionsChildren))];
};

// Takes an array of taxonomy objects and returns them in a format that matches the MultiSelect component
export const taxonomyToMultiselect = taxonomy =>
  taxonomy.reduce((accumulator, taxon) => {
    // eslint-disable-next-line no-param-reassign
    accumulator[taxon.taxon_id] = taxon.value;
    return accumulator;
  }, {});

// Takes an array of taxonomy objects and returns them in a format that matches the StandardMultiCheckboxGroup component
export const taxonomyToMultiCheckboxGroup = options => {
  return options.map(option =>
    option.taxon_id ? { key: option.taxon_id, label: option.value } : option,
  );
};

// Takes an array created by a form components and converts it to taxon format to submit to backend
export const convertArrayToTaxon = inputArray => {
  return inputArray.map(input => ({ taxon_id: input }));
};

// Takes an array of taxonomy objects and returns them in a format that matches the StandardRadioGroup component
export const taxonomyToStandardRadioGroup = options => {
  return options.map(option =>
    option.taxon_id ? { value: option.taxon_id, label: option.value } : option,
  );
};

export const taxonomyValueFromKey = (taxonomy, key) => {
  return taxonomy.find(type => type.taxon_id === key).value;
};
