import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const ignoredMixinMethods = [
  'getDefaultProps',
  'getInitialState',
  'componentWillMount',
  'componentDidMount',
  'componentWillUnmount',
  'changeValue',
];

class Select extends Component {
  static contextTypes = {
    formsy: PropTypes.shape(),
  };

  constructor(props) {
    super(props);

    this.state = Object.assign(
      this.state || {},
      Formsy.Mixin.getInitialState.bind(this)(),
    );
    Object.getOwnPropertyNames(Formsy.Mixin).forEach(method => {
      if (typeof Formsy.Mixin[method] !== 'function') {
        return;
      }

      if (ignoredMixinMethods.indexOf(method) !== -1) {
        return;
      }
      this[method] = Formsy.Mixin[method].bind(this);
    });
  }

  UNSAFE_componentWillMount() {
    Formsy.Mixin.componentWillMount.bind(this)();
  }

  componentDidMount() {
    if (this.props.initialValue) {
      this.setValue(this.props.initialValue);
    }
  }

  componentWillUnmount() {
    Formsy.Mixin.componentWillUnmount.bind(this)();
  }

  changeValue = event => {
    this.setValue(event.currentTarget.value);
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  render() {
    const { name, title, initialValue } = this.props;

    const errorClass = this.showError() ? 'form-group has-error' : 'form-group';
    const className = this.showRequired() ? 'form-group' : errorClass;
    const errorMessage = this.getErrorMessage();

    const options = this.props.options.map(option => (
      <option key={option.title + option.value} value={option.value}>
        {option.title}
      </option>
    ));

    return (
      <div className={className}>
        <label htmlFor={name}>{title}</label>

        <select
          name={name}
          onChange={this.changeValue}
          defaultValue={initialValue}
        >
          {options}
        </select>

        <span className="validation-error">{errorMessage}</span>
      </div>
    );
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  initialValue: '',
  validationError: '',
  validationErrors: {},
  onChange: null,
};

export default Select;
