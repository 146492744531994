import ApiConstants from 'shared/constants/ApiConstants';
import TaxonomyConstants from 'shared/constants/TaxonomyConstants';

// getAllTaxonomies
export const getTaxonomiessRequest = taxonomies => {
  return {
    endpoint: `/taxonomy/`,
    method: 'get',
    onError: {
      type: TaxonomyConstants.TAXONOMY_GET_ALL_TAXONOMIES_FAILURE,
    },
    onStart: {
      type: TaxonomyConstants.TAXONOMY_GET_ALL_TAXONOMIES_REQUEST,
    },
    onSuccess: {
      type: TaxonomyConstants.TAXONOMY_GET_ALL_TAXONOMIES_SUCCESS,
      taxonomies,
    },
    type: ApiConstants.API_REQUEST,
  };
};
