import styled from 'styled-components';

const StyledTabs = styled.div`
  position: relative;
  background: white;
  border-radius: 7px;
  box-shadow: 0 1px 4px 0 ${props => props.theme.colors.shadowGrey};
  margin-bottom: 20px;
  padding: 0 20px;

  @media (min-width: ${props => props.theme.screen.medium}px) {
    > .floatRight {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  > a {
    border-bottom: 5px solid transparent;
    color: ${props => props.theme.colors.midGrey};
    display: inline-block;
    margin-right: 40px;
    padding: 15px 0 10px;
    text-decoration: none;

    &:hover {
      border-bottom-color: ${props => props.theme.colors.midGrey};
    }

    &.active {
      border-bottom-color: ${props => props.theme.colors.hiAccentBlue};
      color: ${props => props.theme.colors.hiAccentBlue};
    }
  }
`;

export default StyledTabs;
