import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import TimesheetResults from 'components/admin/TimesheetResults';
import TimesheetSearchFilters from 'components/admin/TimesheetSearchFilters';
import Module, {
  ModuleHeader,
  ModuleHero,
} from 'components/global/layout/Module';

class AdminTimesheetSearch extends Component {
  render() {
    let results;

    if (!this.props.timesheets) {
      results = <LoadingPlaceholder />;
    } else if (this.props.timesheets.length) {
      results = (
        <TimesheetResults
          search={this.props.search}
          timesheets={this.props.timesheets}
          location={this.props.location}
        />
      );
    } else {
      results = <ModuleHero>No timesheets found.</ModuleHero>;
    }

    return (
      <div>
        <TimesheetSearchFilters
          filters={this.props.filters}
          location={this.props.location}
          updateFilters={this.props.updateFilters}
        />
        <Module>
          <ModuleHeader>Timesheets</ModuleHeader>
          {results}
        </Module>
      </div>
    );
  }
}

AdminTimesheetSearch.propTypes = {
  search: PropTypes.shape(),
  timesheets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  location: PropTypes.shape().isRequired,
  updateFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
};

AdminTimesheetSearch.defaultProps = {
  search: {
    pagination: 1,
  },
};

export default AdminTimesheetSearch;
