import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AdminTeamSearchContainer from 'containers/admin/AdminTeamSearchContainer';

class AdminTeamsOverview extends Component {
  render() {
    return (
      <div className="component-wrapper wide">
        <h1>Teams Overview</h1>
        <AdminTeamSearchContainer location={this.props.location} />
      </div>
    );
  }
}

AdminTeamsOverview.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default AdminTeamsOverview;
