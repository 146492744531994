/* eslint-disable radix */
import moment from 'moment';

export const getClosestTimeInterval = inputTimeStr => {
  if (typeof inputTimeStr !== 'string') {
    return inputTimeStr;
  }
  let time;
  if (!inputTimeStr.includes(':')) {
    if (inputTimeStr.length < 3) {
      time = `${inputTimeStr}:00`;
    } else if (inputTimeStr.length === 3) {
      time = `${inputTimeStr.slice(0, 1)}:${inputTimeStr.slice(1)}`;
    } else {
      time = `${inputTimeStr.slice(0, 2)}:${inputTimeStr.slice(2)}`;
    }
  } else {
    const hour = inputTimeStr.split(':')[0];
    const mins = inputTimeStr.split(':')[1];
    if (hour.length > 2) {
      time = `${hour.slice(0, 2)}:${mins}`;
    } else {
      time = inputTimeStr;
    }
  }

  if (time.length > 5) {
    time = time.slice(0, 5);
  }

  const mins = parseInt(time.split(':')[1]);
  let hour = time.split(':')[0];

  if (isNaN(hour) || hour === '') {
    hour = '00';
  }

  if (isNaN(mins) || mins < 8) {
    return `${hour}:00`;
  } else if (mins < 23) {
    return `${hour}:15`;
  } else if (mins < 37) {
    return `${hour}:30`;
  } else if (mins < 53) {
    return `${hour}:45`;
  } else {
    const derivedHour = parseInt(hour);
    if (derivedHour < 9) {
      return `0${derivedHour + 1}:00`;
    } else if (derivedHour < 23) {
      return `${derivedHour + 1}:00`;
    } else {
      return '00:00';
    }
  }
};

export const getAssignedCarerIDs = scheduleShifts => {
  const carerIDs = new Set();
  scheduleShifts &&
    Object.values(scheduleShifts).forEach(week => {
      Object.values(week).forEach(day => {
        day.forEach(shift => {
          if (shift.user_id) {
            carerIDs.add(shift.user_id);
          }
        });
      });
    });

  return [...carerIDs.values()];
};

export const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const getIntValue = value => {
  const timeComponents = value.split(':');
  const hour = parseInt(timeComponents[0]);
  let minute;
  if (timeComponents[1] === '15') {
    minute = 0.25;
  } else if (timeComponents[1] === '30') {
    minute = 0.5;
  } else if (timeComponents[1] === '45') {
    minute = 0.75;
  } else {
    minute = 0;
  }
  return hour + minute;
};

export const shortWeekdays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export const prettifyWeekNumber = weekKey => {
  try {
    const weekNo = weekKey.split('_')[1];
    if (isNaN(weekNo)) {
      throw new Error();
    }
    return `Week ${parseInt(weekNo) + 1}`;
  } catch (e) {
    return null;
  }
};

export const scheduleShiftTimeString = shift => {
  const start = moment(shift.start, 'HH:mm');
  start.add(shift.iso_weekday - start.isoWeekday(), 'days');
  const end = start.clone().add(shift.duration, 'minutes');
  const startDay = start.clone().format('ddd');
  const endDay = end.clone().format('ddd');
  const shiftSpansMultipleDays = startDay !== endDay;

  return `${start.format('HH:mm')} - ${end.format('HH:mm')}
          ${shiftSpansMultipleDays ? endDay : ''}`;
};

export const doubleDigit = number =>
  number < 10 ? `0${number}` : `${number % 100}`;

export const getTimeValue = value => {
  const hour = doubleDigit(Math.floor(value));
  const minute = doubleDigit((value - hour) * 60);

  return `${hour}:${minute}`;
};

export const firstDayOfTimeSelection = exactTimeSelection => {
  for (let i = 0; i < shortWeekdays.length; ++i) {
    const day = shortWeekdays[i];
    if (day in exactTimeSelection) {
      return day;
    }
  }
};

export const shiftsSortFunction = (format = 'HH:mm') => (a, b) => {
  try {
    const momentA = moment(a.start, format);
    const momentB = moment(b.start, format);
    if (!momentA.isValid() || !momentB.isValid()) {
      return 0;
    }
    if (momentA.isAfter(momentB)) {
      return 1;
    }
    if (momentA.isBefore(momentB)) {
      return -1;
    }
    if (a.end && b.end) {
      if (moment(a.end, format).isAfter(moment(b.end, format))) {
        return 1;
      }
    } else if (a.duration && b.duration) {
      if (a.duration > b.duration) {
        return 1;
      }
    }
    return -1;
  } catch (e) {
    return 0;
  }
};

/**
 * Sorts past schedules to the end of the array
 */
export const sortSchedules = schedules => {
  return schedules.sort((a, b) => {
    if (!a.end || moment(a.end).isAfter(moment())) {
      return -1;
    }

    if (a.end && moment(a.end).isBefore(moment())) {
      return 1;
    }

    return 0;
  });
};

export const transformShifts = (schedule, weeksLength, scheduleId) => {
  const formattedShifts = {};

  shortWeekdays.forEach(shortWeekday => {
    formattedShifts[shortWeekday] = [];
  });

  Array.from(Array(weeksLength).keys()).forEach(weekIndex => {
    shortWeekdays.forEach(weekday => {
      const shiftsNew = formattedShifts[weekday];
      if (schedule && schedule[`week_${weekIndex}`][weekday]) {
        schedule[`week_${weekIndex}`][weekday].forEach(
          ({ start, end, type, user_id, ...otherProps }) => {
            if (start && end && type) {
              const duration = tranformScheduleToHour(start, end);

              shiftsNew.push({
                ...otherProps,
                schedule_id: scheduleId,
                start: `${start}:00`,
                duration,
                type,
                week_number: weekIndex,
                user_id: user_id ? user_id : null,
              });
            }
          },
        );
      }
      shiftsNew.sort(shiftsSortFunction());
    });
  });

  return formattedShifts;
};

export const tranformScheduleToHour = (start, end) => {
  let duration;
  const startIntVal = getIntValue(start);
  const endIntVal = getIntValue(end);

  if (startIntVal === 0 && endIntVal === 0) {
    duration = 1440;
  } else if (startIntVal >= endIntVal) {
    const derivedStart = startIntVal ? startIntVal : 24;
    duration = (24 - derivedStart + endIntVal) * 60;
  } else {
    const derivedEnd = endIntVal ? endIntVal : 24;
    duration = (derivedEnd - startIntVal) * 60;
  }

  return duration;
};

export const reverseTransformScheduleShift = ({
  start,
  duration,
  user_id,
  ...otherProps
}) => {
  const formattedStart = moment.utc(start, 'HH:mm:ss');
  return {
    start: formattedStart.format('HH:mm'),
    end: formattedStart
      .clone()
      .add(duration, 'minutes')
      .format('HH:mm'),
    user_id: user_id === 'None' ? undefined : user_id,
    duration,
    ...otherProps,
  };
};

export const cloneTimeSheet = timeSheet => {
  const newTimeSheet = JSON.parse(JSON.stringify(timeSheet));

  return newTimeSheet;
};

export const convertArrayOfDaysToArrayOfWeeks = (arrDays, weeksLength) => {
  const shifts = {};

  // Add week properties to object
  Array.from(Array(weeksLength).keys()).forEach(
    value => (shifts[`week_${value}`] = {}),
  );

  if (!Array.isArray(arrDays)) {
    arrDays = [arrDays];
  }

  for (let i = 0; i < weeksLength; i++) {
    const week = `week_${i}`;
    const weekDays = arrDays.filter(day => day.week_number === i);
    weekDays.forEach(day => {
      const weekDay = shortWeekdays[parseInt(day.iso_weekday) - 1];

      shifts[week][weekDay] = [];
    });
  }

  for (let i = 0; i < weeksLength; i++) {
    const week = `week_${i}`;
    const weekDays = arrDays.filter(day => day.week_number === i);
    weekDays.forEach(day => {
      const weekDay = shortWeekdays[parseInt(day.iso_weekday) - 1];

      const transformedShift = reverseTransformScheduleShift(day);

      shifts[week][weekDay].push(transformedShift);
      shifts[week][weekDay].sort(shiftsSortFunction());
    });
  }

  return shifts;
};

export const buildEmptyShiftsObj = weeksLength => {
  const shifts = {};

  Array.from(Array(weeksLength).keys()).forEach(
    value => (shifts[`week_${value}`] = {}),
  );

  return shifts;
};

/**
 * Helper function to retreieve a shift from our ridiculous schedule object
 * */
export const shiftInSchedule = (shiftID, schedule) => {
  const shifts = [];
  Object.values(schedule.shifts).forEach(week =>
    Object.values(week).forEach(days => {
      const shift = days.find(shift => shift.id === shiftID);
      if (shift) {
        shifts.push(shift);
      }
    }),
  );

  return shifts[0] || null;
};
