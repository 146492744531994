import ReferencesConstants from 'shared/constants/ReferencesConstants';

const initalState = {};

function references(state = initalState, action) {
  switch (action.type) {
    case ReferencesConstants.REFERENCE_GET_BY_ID_SUCCESS: {
      const ref = action.data;
      const newState = { ...state };
      const userID = action.data.user_id;
      if (newState[userID]) {
        // Remove any old instances of the reference
        newState[userID].splice(
          newState[userID].findIndex(existingRef => existingRef.id === ref.id),
          1,
        );
        newState[ref.user_id] = [...newState[ref.user_id]];
        newState[ref.user_id].push(ref);
      } else {
        newState[ref.user_id] = [ref];
      }
      return newState;
    }

    case ReferencesConstants.REFERENCES_SEARCH_SUCCESS:
    case ReferencesConstants.REFERENCES_GET_FOR_CARER_BY_ID_SUCCESS: {
      const newState = {};
      if (action.carer_id) {
        newState[action.carer_id] = [];
      }
      action.data.results.forEach(reference => {
        const userId = reference.user_id;
        if (!newState[userId]) {
          newState[userId] = [];
        }
        newState[userId].push(reference);
      });
      return { ...state, ...newState };
    }

    case ReferencesConstants.REFERENCE_UPDATE_SUCCESS: {
      const newState = { ...state };
      const ref = action.data;
      const userID = action.data.user_id;
      if (newState[userID]) {
        // Remove any old instances of the reference
        newState[userID].splice(
          newState[userID].findIndex(existingRef => existingRef.id === ref.id),
          1,
        );
        newState[ref.user_id] = [...newState[ref.user_id]];
        newState[ref.user_id].push(ref);
      } else {
        newState[ref.user_id] = [ref];
      }
      return newState;
    }

    case ReferencesConstants.REFERENCE_DELETE_SUCCESS: {
      const newState = { ...state };
      const userID = action.payload.userID;
      newState[userID] = (newState[userID] || []).filter(
        reference => reference.id !== action.payload.referenceID,
      );
      return newState;
    }

    default:
      return state;
  }
}

export default references;
