// @flow
import React, { type Node } from 'react';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import Logo from 'components/global/Logo';
import { Container, Row, Col } from 'components/grid';

// "Global styles"
const StyledContainer = styled(Container)`
  background: ${props => props.theme.colors.lightGrey};
  color: ${props => props.theme.colors.formText};
  font-family: ${props => props.theme.typography.fontStack};
  font-size: ${props => props.theme.typography.defaultFontSize};
  line-height: ${props => props.theme.typography.defaultLineHeight};

  margin-left: 0;

  strong {
    font-weight: bold;
  }

  h5 {
    margin-bottom: 10px;
  }

  div[style] > p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  main > section > div + h2 {
    margin-left: 20px;
    margin-top: 20px;
    @media (min-width: 768px) {
      margin-left: inherit;
      margin-top: 40px;
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
  margin: 15px auto 15px;
  width: 180px;

  @media (min-width: 768px) {
    margin: 25px auto 25px;
  }
`;

type Props = {
  children?: Node,
};

export const StaffLoginWrapper = ({ children }: Props) => (
  <StyledContainer fluid gutterWidth={20} outerGutter={0}>
    <Row>
      <Col sm={12} md={8} lg={8} offset={{ md: 2 }}>
        <StyledLink to="/">
          <Logo height={'52px'} width={500} />
        </StyledLink>
        <Container outerGutter={{ xs: 0, md: 20 }} rowGutter={0}>
          <Row>
            <Col md={12}>{children}</Col>
          </Row>
        </Container>
      </Col>
    </Row>
  </StyledContainer>
);

export default StaffLoginWrapper;
