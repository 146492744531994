import { all, takeLatest, takeEvery, put } from 'redux-saga/effects';

import { getCarerById } from '_actions/carerActions';
import { getInvoiceWithId } from '_actions/invoiceActions';
import { getNeedByID } from '_actions/needActions';
import { searchRefunds } from '_actions/refundActions';
import { getTimesheetTotalById } from '_actions/timesheetActions';
import { getUserById } from '_actions/userActions';
import {
  getPaymentIntent,
  getUserPaymentMethods,
} from '_actions/paymentActions';
import { getTimesheetById } from '_actions/timesheetActions';
import InvoiceConstants from 'shared/constants/InvoiceConstants';
import TimesheetConstants from 'shared/constants/TimesheetConstants';

import { checkIfDefined } from 'components/helpers/functionHelpers';

export function* getTimesheetDetails(action) {
  yield put(getCarerById(action.data.carer_id));
  if (!checkIfDefined(action.data.totals)) {
    yield put(getTimesheetTotalById(action.data.id));
  }

  if (action.data.charge_id && action.data.charge_id !== 'None') {
    yield put(
      searchRefunds({
        charge_id: action.data.charge_id,
      }),
    );
  }
  if (action.data.charge_status === 'requires_action') {
    yield put(getPaymentIntent(action.data.charge_id, action.data.id));
  }
}

export function* getInvoiceDetails(action) {
  yield all([
    put(getNeedByID(action.data.need_id)),
    put(getUserById(action.data.user_id)),
    put(getUserPaymentMethods(action.data.user_id)),
  ]);

  yield action.data.timesheet_ids.map(timesheetId =>
    put(getTimesheetById(timesheetId)),
  );

  yield takeEvery(
    [
      TimesheetConstants.TIMESHEET_GET_BY_ID_SUCCESS,
      TimesheetConstants.TIMESHEET_GET_BY_ID_FAILURE,
    ],
    getTimesheetDetails,
  );
}

export function* populateInvoice(action) {
  yield put(getInvoiceWithId(action.invoiceId));

  yield takeLatest(
    InvoiceConstants.INVOICE_GET_BY_ID_SUCCESS,
    getInvoiceDetails,
  );
}

export function* getInvoiceListener() {
  yield takeLatest(InvoiceConstants.POPULATE_INVOICE, populateInvoice);
}
