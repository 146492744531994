import AccountConstants from 'shared/constants/AccountConstants';
import ApiConstants from 'shared/constants/ApiConstants';
import { getUser, getRedirect } from 'shared/selectors/accountSelectors';
import store from 'store';

export function login(payload) {
  return {
    type: AccountConstants.LOGIN,
    payload,
  };
}

export function magicTokenLogin(token) {
  return {
    type: AccountConstants.MAGIC_TOKEN_LOGIN,
    token,
  };
}

export function staffLogin(payload) {
  return {
    type: AccountConstants.STAFF_LOGIN,
    payload,
  };
}

export function getLocalStorageLoginData() {
  return {
    type: AccountConstants.GET_LOCAL_STORAGE_LOGIN_DATA,
  };
}

export function logout() {
  return { type: AccountConstants.LOGOUT };
}

export function updateAccount(user) {
  return {
    endpoint: `/user/${user.id}/`,
    method: 'put',
    onError: {
      type: AccountConstants.ACCOUNT_UPDATE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to update account. Please contact Home Instead Live-in Care.',
        dataSent: user,
      },
    },
    onStart: { type: AccountConstants.ACCOUNT_UPDATE },
    onSuccess: {
      type: AccountConstants.ACCOUNT_UPDATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Account updated',
      },
    },
    payload: user,
    type: ApiConstants.FORM_API_REQUEST,
  };
}

export function updateUser(userID, payload) {
  return {
    endpoint: `/user/${userID}/`,
    method: 'put',
    onError: {
      type: AccountConstants.ACCOUNT_UPDATE_FAILURE,
      toast: {
        type: 'error',
        public: true,
        title: 'Unable to update account. Please contact Home Instead Live-in Care.',
      },
    },
    onStart: { type: AccountConstants.ACCOUNT_UPDATE },
    onSuccess: {
      type: AccountConstants.ACCOUNT_UPDATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Account updated',
      },
    },
    payload,
    type: ApiConstants.FORM_API_REQUEST,
  };
}

export function refreshAccount(userId, redirect = null) {
  return {
    endpoint: `/user/${userId}/`,
    method: 'get',
    onError: { type: AccountConstants.ACCOUNT_REFRESH_FAILURE },
    onStart: { type: AccountConstants.ACCOUNT_REFRESH },
    onSuccess: { type: AccountConstants.ACCOUNT_REFRESH_SUCCESS },
    redirect,
    type: ApiConstants.API_REQUEST,
  };
}

export function changePassword(data) {
  const payload = {
    password: data.current_password,
    new_password: data.password,
  };

  return {
    endpoint: `/user/${data.userId}/`,
    method: 'put',
    onError: {
      type: AccountConstants.CHANGE_PASSWORD_FAILURE,
      toast: {
        type: 'error',
        public: false,
      },
    },
    onStart: { type: AccountConstants.CHANGE_PASSWORD },
    onSuccess: {
      type: AccountConstants.CHANGE_PASSWORD_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Password updated successfully.',
      },
    },
    payload,
    type: ApiConstants.FORM_API_REQUEST,
  };
}

export function setPasswordAndConfirm(userId, password) {
  const payload = { password };
  return {
    endpoint: `/user/${userId}/password_and_confirm/`,
    method: 'post',
    onError: {
      type: AccountConstants.CHANGE_PASSWORD_FAILURE,
      toast: {
        type: 'error',
        public: false,
      },
    },
    onStart: { type: AccountConstants.CHANGE_PASSWORD },
    onSuccess: {
      type: AccountConstants.CHANGE_PASSWORD_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Password updated successfully.',
      },
    },
    payload,
    type: ApiConstants.FORM_API_REQUEST,
  };
}

export function recoverPassword({ email }) {
  return {
    endpoint: '/user/password/recovery/',
    method: 'post',
    onError: { type: AccountConstants.ACCOUNT_RECOVER_FAILURE },
    onStart: { type: AccountConstants.ACCOUNT_RECOVER },
    onSuccess: {
      type: AccountConstants.ACCOUNT_RECOVER_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Please check your email for a password reset link.',
      },
    },
    payload: { email },
    type: ApiConstants.FORM_API_REQUEST,
  };
}

export function resetPassword({ token, password }) {
  return {
    type: AccountConstants.RESET_PASSWORD,
    token,
    password,
  };
}

export function verifyAccount({ token, password }) {
  return {
    type: AccountConstants.ACCOUNT_VERIFY,
    token,
    password,
  };
}

export function impersonateUser(userId) {
  return {
    endpoint: `/user/impersonate/${userId}/`,
    method: 'post',
    onError: { type: AccountConstants.IMPERSONATE_FAILURE },
    onStart: { type: AccountConstants.IMPERSONATE },
    onSuccess: { type: AccountConstants.IMPERSONATE_SUCCESS },
    payload: {},
    redirect: '/home/',
    type: ApiConstants.API_REQUEST,
  };
}

export function stopImpersonatingUser() {
  return {
    type: AccountConstants.IMPERSONATE_STOP,
  };
}

export function confirmTerms() {
  const user = getUser(store.getState());
  const redirect = getRedirect(store.getState());
  return {
    endpoint: `/user/${user.id}/`,
    method: 'put',
    onError: {
      type: AccountConstants.ACCOUNT_CONFIRM_TERMS_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title:
          'Unable to accept Terms & Privacy Policy. Please contact Home Instead Live-in Care.',
      },
    },
    onStart: { type: AccountConstants.ACCOUNT_CONFIRM_TERMS },
    onSuccess: { type: AccountConstants.ACCOUNT_CONFIRM_TERMS_SUCCESS },
    payload: {
      confirmed_tos: true,
    },
    redirect: redirect || '/home/',
    type: ApiConstants.API_REQUEST,
  };
}

export function checkIfVerified(token) {
  return {
    type: AccountConstants.CHECK_IF_VERIFIED,
    token,
  };
}

export function toggleFeature(name, active) {
  return {
    type: AccountConstants.TOGGLE_FEATURE,
    name,
    active,
  };
}

export function sendEmailVerification(userId) {
  return {
    endpoint: `/user/${userId}/confirmation/email/`,
    method: 'post',
    onError: { type: AccountConstants.SEND_EMAIL_VERIFICATION_FAILURE },
    onStart: { type: AccountConstants.SEND_EMAIL_VERIFICATION },
    onSuccess: {
      type: AccountConstants.SEND_EMAIL_VERIFICATION_SUCCESS,
      toast: {
        type: 'success',
        public: true,
        title: 'Verification sent successfully. Please check your email.',
      },
    },
    payload: {},
    type: ApiConstants.API_REQUEST,
  };
}
