import React from 'react';
import moment from 'moment';

import { ModuleHero } from 'components/global/layout/Module';

export default class NeedHistory extends React.PureComponent {
  render() {
    const { need } = this.props;
    const { notes } = need;

    if (!notes || !notes.length) {
      return (
        <ModuleHero>No actions have yet been taken on this need</ModuleHero>
      );
    }

    const updateNotes = notes.filter(note => note.category === 'updatehistory');

    return (
      updateNotes &&
      updateNotes.length > 0 && (
        <div className="module boxed">
          <h3>History</h3>
          {updateNotes.map(note => {
            const date = moment(note.created_at).format(
              'dddd, MMMM Do YYYY, h:mm:ssa',
            );
            return (
              <p key={note.id}>
                <strong>{date}</strong>
                <br />
                {note.message} ({note.from_user_id})
              </p>
            );
          })}
        </div>
      )
    );
  }
}
