import { reset } from 'redux-form';
import ReferencesConstants from 'shared/constants/ReferencesConstants';
import AccountConstants from 'shared/constants/AccountConstants';
import { refreshAccount } from '_actions/accountActions';
import { getCarerProfileByCarerId } from '_actions/carerActions';
import { takeLatest, take, put, all, select } from 'redux-saga/effects';
import {
  updateReference,
  getReferencesByCarerID,
} from '_actions/referencesActions';
import { actions as toastrActions } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { isAdmin } from 'shared/selectors/accountSelectors';

function* resetForm() {
  yield put(getReferencesByCarerID());
  yield put(reset('ReferenceAdd'));
}

export function* referencesAddSuccessListener() {
  yield takeLatest(ReferencesConstants.REFERENCE_ADD_SUCCESS, resetForm);
}

function* submitReferences({ references }) {
  const updatesPending = new Set(references.map(({ id }) => id));

  if (updatesPending.size < 2) {
    yield put(
      toastrActions.add({
        type: 'error',
        public: false,
        title: 'Please submit at least two references',
      }),
    );
    return yield put({ type: ReferencesConstants.REFERENCES_SUBMIT_FAILURE });
  }

  yield all(
    references
      .filter(reference => reference.state === 'new')
      .map(reference =>
        put(updateReference(reference.id, { state: 'submitted' })),
      ),
  );

  const userID = references[0].user_id;
  while (updatesPending.size) {
    const { data, type } = yield take([
      ReferencesConstants.REFERENCE_UPDATE_SUCCESS,
      ReferencesConstants.REFERENCE_UPDATE_FAILURE,
    ]);
    updatesPending.delete(data.id);

    if (
      !data ||
      !data.id ||
      type === ReferencesConstants.REFERENCE_UPDATE_FAILURE
    ) {
      yield put(
        toastrActions.add({
          type: 'error',
          public: false,
          title: 'Error submitting references',
        }),
      );
      return yield put({ type: ReferencesConstants.REFERENCES_SUBMIT_FAILURE });
    }
  }

  // Must refresh the account to pull in the new submitted references property
  yield put(refreshAccount(userID));
  yield take(AccountConstants.ACCOUNT_REFRESH_SUCCESS);
  yield put(push('/home/'));
  yield put(
    toastrActions.add({
      type: 'success',
      public: false,
      title: 'References submitted',
      options: {
        showCloseButton: true,
      },
    }),
  );
  return yield put({ type: ReferencesConstants.REFERENCES_SUBMIT_SUCCESS });
}

export function* submitReferencesListener() {
  yield takeLatest(ReferencesConstants.REFERENCES_SUBMIT, submitReferences);
}

function* updatedReference(action) {
  const admin = yield select(isAdmin);
  if (admin) {
    const carerID = action.data.user_id;
    yield put(getCarerProfileByCarerId(carerID));
  }
}

export function* updatedReferenceListener() {
  yield takeLatest(
    ReferencesConstants.REFERENCE_UPDATE_SUCCESS,
    updatedReference,
  );
}
