import styled from 'styled-components';

const ButtonGroup = styled.span`
  width: 100%;
  display: block;
  text-align: center;
  margin: 20px 0;

  @media (min-width: 768px) {
    display: inline-block;
    margin: 10px 0 0;
    text-align: ${props => (props.rightAlign ? 'right' : 'left')};
  }

  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

export default ButtonGroup;
