// @flow
import PropTypes from 'prop-types';
import React from 'react';

export type ExternalIconProps = {|
  className: string,
  width: string,
  height: string,
|};

const ExternalIcon = ({ className, width, height }: ExternalIconProps) => (
  <div className={`icon ${className}`}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
      />
    </svg>
  </div>
);

ExternalIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

ExternalIcon.defaultProps = {
  className: '',
  height: '18px',
  width: '18px',
};

export default ExternalIcon;
