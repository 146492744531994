import PropTypes from 'prop-types';
import React from 'react';

const TimesheetsIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 29"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="timesheets-icon"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path d="M14.049259,27.6129989 L2.1266569,27.6129989 C1.1406577,27.6129989 0.338266578,26.8112039 0.338266578,25.8246085 L0.338266578,1.97940426 C0.338266578,0.99340506 1.1406577,0.191013937 2.1266569,0.191013937 L15.2415192,0.191013937 C15.4000899,0.191013937 15.5515069,0.254203728 15.6629832,0.365680058 L22.8165445,7.51924134 C22.928617,7.6313138 22.9912106,7.78273085 22.9912106,7.94070533 L22.9912106,15.6903967 L21.7989504,15.6903967 L21.7989504,8.18809932 L14.9947214,1.38327415 L2.1266569,1.38327415 C1.79759308,1.38327415 1.53052679,1.65093657 1.53052679,1.97940426 L1.53052679,25.8246085 C1.53052679,26.1536724 1.79759308,26.4207386 2.1266569,26.4207386 L14.049259,26.4207386 L14.049259,27.6129989" />
        <path d="M11.6647386,10.3252258 L4.51117733,10.3252258 C4.18151738,10.3252258 3.91504722,10.0587556 3.91504722,9.72909565 C3.91504722,9.40003183 4.18151738,9.13296554 4.51117733,9.13296554 L11.6647386,9.13296554 C11.9943986,9.13296554 12.2608687,9.40003183 12.2608687,9.72909565 C12.2608687,10.0587556 11.9943986,10.3252258 11.6647386,10.3252258" />
        <path d="M18.8182999,13.9020064 L4.51117733,13.9020064 C4.18151738,13.9020064 3.91504722,13.6355362 3.91504722,13.3058763 C3.91504722,12.9768125 4.18151738,12.7097462 4.51117733,12.7097462 L18.8182999,12.7097462 C19.1479598,12.7097462 19.41443,12.9768125 19.41443,13.3058763 C19.41443,13.6355362 19.1479598,13.9020064 18.8182999,13.9020064" />
        <path d="M12.8569988,17.478787 L4.51117733,17.478787 C4.18151738,17.478787 3.91504722,17.2123169 3.91504722,16.8826569 C3.91504722,16.5535931 4.18151738,16.2865268 4.51117733,16.2865268 L12.8569988,16.2865268 C13.1866588,16.2865268 13.4531289,16.5535931 13.4531289,16.8826569 C13.4531289,17.2123169 13.1866588,17.478787 12.8569988,17.478787" />
        <path d="M12.8569988,21.0555677 L4.51117733,21.0555677 C4.18151738,21.0555677 3.91504722,20.7890975 3.91504722,20.4594376 C3.91504722,20.1303738 4.18151738,19.8633075 4.51117733,19.8633075 L12.8569988,19.8633075 C13.1866588,19.8633075 13.4531289,20.1303738 13.4531289,20.4594376 C13.4531289,20.7890975 13.1866588,21.0555677 12.8569988,21.0555677" />
        <path d="M19.7423016,18.5434754 C18.6662867,18.5434754 17.6552501,18.9625549 16.894588,19.7232169 C16.133926,20.4838789 15.7154427,21.4949156 15.7154427,22.5703343 C15.7154427,23.645753 16.133926,24.6567897 16.894588,25.4174517 C17.6558462,26.1781137 18.6662867,26.5971932 19.7423016,26.5971932 C20.8177203,26.5971932 21.8287569,26.1781137 22.5894189,25.4174517 C24.1596256,23.8478411 24.1596256,21.2928275 22.5894189,19.7232169 C21.8287569,18.9625549 20.8177203,18.5434754 19.7423016,18.5434754 Z M19.7423016,27.7894534 C18.3479532,27.7894534 17.0376593,27.2463788 16.0516601,26.2603796 C15.0656609,25.2749766 14.5225863,23.9646826 14.5231825,22.5703343 C14.5231825,21.1765821 15.0656609,19.865692 16.0516601,18.8802889 C17.0376593,17.8942897 18.3479532,17.3512152 19.7423016,17.3512152 C21.1360537,17.3512152 22.4463477,17.8942897 23.4323469,18.8802889 C25.466939,20.914881 25.466939,24.2257876 23.4323469,26.2603796 C22.4463477,27.2463788 21.1360537,27.7894534 19.7423016,27.7894534 L19.7423016,27.7894534 Z" />
        <path d="M22.3950805,8.53683543 L15.2415192,8.53683543 C14.9118593,8.53683543 14.6453891,8.27036527 14.6453891,7.94070533 L14.6453891,1.38327415 L15.8376494,1.38327415 L15.8376494,7.34457522 L22.3950805,7.34457522 L22.3950805,8.53683543" />
        <path d="M20.0054544,22.2867392 L20.0054544,20.3023349 C20.0054544,20.0599918 19.8087701,19.8633075 19.5664269,19.8633075 C19.3240838,19.8633075 19.1273994,20.0599918 19.1273994,20.3023349 L19.1273994,22.4974724 C19.1273994,22.6309367 19.1879852,22.7569376 19.2920348,22.8407919 L21.4876112,24.5969018 C21.5683923,24.6614388 21.6649783,24.6926098 21.7615643,24.6926098 C21.8901994,24.6926098 22.0175174,24.6364143 22.1044448,24.5279745 C22.2559093,24.3387536 22.2251774,24.0626053 22.0355175,23.9107018 L20.0054544,22.2867392" />
      </g>
    </svg>
  </div>
);

TimesheetsIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

TimesheetsIcon.defaultProps = {
  className: '',
  height: '29px',
  width: '26px',
};

export default TimesheetsIcon;
