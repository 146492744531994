import PropTypes from 'prop-types';
import React from 'react';

const Chevron = ({ className, width, height }) => (
  <span className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 16"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="chevron"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="Interface"
          transform="translate(-227.000000, -933.000000)"
          stroke="#4A4A4A"
          strokeWidth="1.5"
        >
          <g id="Back" transform="translate(220.679688, 929.000000)">
            <g
              id="icon_back"
              transform="translate(8.131728, 12.131728) scale(-1, 1) rotate(-315.000000) translate(-8.131728, -12.131728) translate(2.631728, 6.131728)"
            >
              <path d="M0.75,0.6 L0.75,10.2" id="Line" />
              <path d="M10.35,10.2496938 L0.75,10.2496938" id="Line" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
);

Chevron.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Chevron.defaultProps = {
  className: '',
  height: '16px',
  width: '8px',
};

export default Chevron;
