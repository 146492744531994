import { takeLatest, put } from 'redux-saga/effects';

import { getFranchiseById } from '_actions/franchiseActions';
import { getUserById } from '_actions/userActions';

import FranchiseConstants from 'shared/constants/FranchiseConstants';

export function* getUsers(action) {
  yield action.data.users.map(userId =>
    put(getUserById(userId)),
  );
  yield put({ type: FranchiseConstants.POPULATE_FRANCHISE_COMPLETE });
}

export function* populateFranchise(action) {
  yield put(getFranchiseById(action.franchiseID));

  yield takeLatest(
    FranchiseConstants.FRANCHISE_GET_BY_ID_SUCCESS,
    getUsers,
  );
}

export function* getFranchiseUsersListener() {
  yield takeLatest(FranchiseConstants.POPULATE_FRANCHISE, populateFranchise);
}