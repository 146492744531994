import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 100%;
  & + & {
    margin-top: 40px;
  }
`;

const StyledHeader = styled.h3`
  color: ${props => props.theme.colors.midGrey};
  margin-bottom: 10px;
  margin-left: 20px;
`;

const StyledTable = styled.table`
  margin-bottom: 20px;
  width: 100%;
  tr {
    border-bottom: 1px solid ${props => props.theme.colors.uiGrey};
    &.total {
      td,
      th {
        font-weight: bold;
      }
    }
  }
  > thead {
    > tr {
      > th {
        color: ${props => props.theme.colors.midGrey};
        font-weight: 600;
        text-align: left;
        padding: 10px;
        &.numeric {
          text-align: right;
        }
        &:first-child {
          padding-left: 20px;
        }
        &:last-child {
          padding-right: 20px;
        }
      }
    }
  }
  > tbody {
    &:first-child {
      border-top: 1px solid ${props => props.theme.colors.uiGrey};
    }
    > tr {
      > td {
        padding: 20px 10px;
        vertical-align: middle;
        &:first-child {
          padding-left: 20px;
        }
        &:last-child {
          padding-right: 20px;
        }
        &.number {
          text-align: right;
        }
      }
      &:nth-child(even) {
        background-color: ${props => props.theme.colors.lightestGrey};
      }
      &.total {
        td,
        th {
          font-weight: bold;
        }
        &:only-child {
          td {
            padding-bottom: 2px;
            padding-top: 2px;
          }
        }
      }
    }
    &:only-child {
      >tr.total:only-child {
        td {
          padding-bottom: 30px;
          padding-top: 30px;
        }
      }
    }
  }
  .supplementary-info {
    color: ${props => props.theme.colors.midGrey};
    font-weight: 500;
  }
  @media (max-width: 767px) {
    &:not(.fixed) {
      display: block;
      margin-bottom: 20px;
      thead {
        display: none;
      }
      tbody {
        border-top: 1px solid ${props => props.theme.colors.uiGrey};
        display: block;
        > tr {
          display: block;
          >th, td {
            display: block;
            padding: 2px 20px;
            &:first-child {
              padding-top: 20px;
            }
            &:last-child {
              padding-bottom: 20px;
            }
            &.number {
              text-align: left;
            }
            &:empty {
              display: none;
            }
          }
        }
      }
      td[data-heading] {
        > div {
          display: inline-block;
          white-space: nowrap;
          width: 50%;
        }
        &:before {
          content: attr(data-heading)": ";
          display: inline-block;
          font-weight: 600;
          margin-right: 10px;
          vertical-align: top;
          width: 40%;
        }
      }
    }
  }
}
`;

class StandardTable extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    header: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    header: null,
  };

  render() {
    return (
      <StyledDiv className={this.props.className}>
        {this.props.header && <StyledHeader>{this.props.header}</StyledHeader>}
        <StyledTable className={this.props.className}>
          {this.props.children}
        </StyledTable>
      </StyledDiv>
    );
  }
}

export default StandardTable;
