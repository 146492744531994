import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import InputWrapper from 'components/forms/InputWrapper';
import StyledCheckbox from 'components/forms/StyledCheckbox';
import StyledLabel from 'components/forms/StyledLabel';

const StyledInputWrapper = styled(InputWrapper)`
  ${props =>
    props.inlineLabel &&
    `
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    > label {
      margin-left: 10px;
    }
  `}
`;

const StandardCheckbox = ({
  input,
  label,
  smallLabel,
  inlineLabel,
  meta,
  style,
  name,
  ...otherProps
}) => {
  const onValueChange = e => {
    e.persist();
    e.stopPropagation();

    // Force the value to be true or false
    input.onChange(e.target.checked);
  };

  const { touched, error } = meta;
  const checkboxStyle = {};
  if (inlineLabel) {
    checkboxStyle.margin = 0;
  }
  return (
    <StyledInputWrapper {...{ meta, inlineLabel, style }}>
      {label && (
        <StyledLabel error={touched && error} htmlFor={`${input.name}`}>
          {smallLabel ? <em>{label}</em> : label}
        </StyledLabel>
      )}
      <StyledCheckbox
        className={touched && error ? 'error' : null}
        {...{ ...input, ...otherProps }}
        type="checkbox"
        style={checkboxStyle}
        checked={input.value}
        onChange={onValueChange}
      />
    </StyledInputWrapper>
  );
};

StandardCheckbox.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape().isRequired,
};

StandardCheckbox.defaultProps = {
  label: null,
};

export default StandardCheckbox;
