import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminUserAccount from 'components/users/AdminUserAccount';

import {
  impersonateUser,
  updateAccount,
  sendEmailVerification,
} from '_actions/accountActions';
import { archiveUser } from '_actions/userActions';

class AdminUserAccountContainer extends Component {
  static propTypes = {
    archiveUser: PropTypes.func.isRequired,
    impersonateUser: PropTypes.func.isRequired,
    loadingImpersonator: PropTypes.bool,
    updateAccount: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
  };

  archiveUser = (userID: string, archive: boolean) => {
      this.props.archiveUser(userID, archive);
  };

  updateAccount = data => {
    const id = this.props.user.id;
    this.props.updateAccount({ id, ...data });
  };

  impersonateUser = () => this.props.impersonateUser(this.props.user.id);

  render() {
    return (
      <AdminUserAccount
        archiveUser={this.archiveUser}
        impersonateUser={this.impersonateUser}
        loadingImpersonator={this.props.loadingImpersonator}
        sendEmailVerification={this.props.sendEmailVerification}
        updateAccount={this.updateAccount}
        user={this.props.user}
      />
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      archiveUser,
      impersonateUser,
      sendEmailVerification,
      updateAccount,
    },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(AdminUserAccountContainer);
