import { select, takeEvery } from 'redux-saga/effects';

import AccountConstants from 'shared/constants/AccountConstants';
import TrackingConstants from 'shared/constants/TrackingConstants';
import { getUser, isAdmin } from 'shared/selectors/accountSelectors';

import { trackEventCall } from 'components/helpers/trackingHelpers';

export function* trackPageView({ payload: { pathname } }) {
  const admin = yield select(isAdmin);
  if (window.analytics && !admin) {
    window.analytics.page(pathname);
  }
}

export function* trackPageViewListener() {
  yield takeEvery('@@router/LOCATION_CHANGE', trackPageView);
}

export function* trackUser() {
  const admin = yield select(isAdmin);
  if (window.analytics && !admin) {
    const user = yield select(getUser);
    window.analytics.identify(user.id, {
      email: user.email,
      firstname: user.firstname,
      name: user.name,
      phone_number: user.phone_number,
      roles: user.roles,
    });
  }
}

export function* trackUserListener() {
  yield takeEvery(
    [
      AccountConstants.LOGIN_SUCCESS,
      AccountConstants.STAFF_LOGIN_SUCCESS,
      AccountConstants.ACCOUNT_VERIFY_SUCCESS,
    ],
    trackUser,
  );
}

export function* trackEvent({ eventLabel, eventInfo }) {
  const admin = yield select(isAdmin);
  if (window.analytics && !admin) {
    trackEventCall(eventLabel, eventInfo);
  }
}

export function* trackEventListener() {
  yield takeEvery(TrackingConstants.TRACK_EVENT, trackEvent);
}
