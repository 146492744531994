import createHistory from 'history/createBrowserHistory';
import * as Sentry from '@sentry/browser';
import qs from 'qs';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as reducers from 'shared/reducers';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';
import { formSubmitSaga } from 'redux-form-submit-saga';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import {
  addLogRocketToSentry,
  initLogRocket,
  logRocketMiddleware,
} from 'logRocketHelper';
import {
  isAdminInLocalStorage,
  isCarerInLocalStorage,
  isimpersonatorInLocalStorage,
} from 'localStorageHelper';

import { all, fork } from 'redux-saga/effects';
import {
  getLocalStorageLoginDataListener,
  loginFlowListener,
  logoutFlowListener,
  resetPasswordFlowListener,
  verifyAccountFlowListener,
  stopImpersonatingUser,
  checkIfVerifiedListener,
  magicTokenLoginListener,
} from 'sagas/accountSagas';
import {
  apiRequestListener,
  apiRequestAndRefreshListener,
  sendNotificationListener,
} from 'sagas/apiSagas';
import {
  adminUpdateCarerProfileListener,
  carerProfilePartialUpdateListener,
  carerAdhocTimesheetCreationListener,
  conflictsWithBidsListener,
} from 'sagas/carerSagas';
import { sendConfirmationEmailListener } from 'sagas/careTeamSagas';
import { getFranchiseUsersListener } from 'sagas/franchiseSagas';
import {
  getBidsWithJobOffersAndSchedulesAndShiftsListener,
  getJobOfferAndBidByReferenceForCarerListener,
} from 'sagas/jobOfferSagas';
import { getInvoiceListener } from 'sagas/invoiceSagas';
import { checkForUpdateListener } from 'sagas/metaSagas';
import {
  getNeedByIdWithUserListener,
  createNeedListener,
  updateNeedAndCustomerListener,
} from 'sagas/needSagas';
import { clearFormListener } from 'sagas/formSagas';
import {
  getSchedulesWithScheduleShiftsByNeedIDListener,
  getSchedulesWithScheduleShiftsByScheduleIDsListener,
  createScheduleWithShiftsListener,
  updateScheduleListener,
  updateShiftsListener,
  fetchShiftsForNeedIDListener,
  fetchShiftsForCarerUserIDListener,
  fetchShiftsForCustomerUserIDListener,
  fetchMoreShiftsForCustomerUserIDListener,
  fetchSchedulesForJobOffersListener,
  deleteMultipleShiftsListener,
} from 'sagas/scheduleSagas';
import {
  referencesAddSuccessListener,
  submitReferencesListener,
  updatedReferenceListener,
} from 'sagas/referencesSagas';
import {
  createTeamAndUpdateMemberListener,
  getCareTeamByNeedWithCarersListener,
  getTeamMembersForTeamListener,
  removeTeamMemberListener,
  updateTeamAndMemberListener,
} from 'sagas/teamSagas';
import {
  trackPageViewListener,
  trackUserListener,
  trackEventListener,
} from 'sagas/trackingSagas.js';
import { searchAndFetchUsersListener } from 'sagas/userSagas';
import { fetchCareNeedRatesListener } from 'sagas/rateSagas';
import {
  approveBidListener,
  createMultipleBidsListener,
  getBidsWithCarersListener,
} from 'sagas/bidSagas';
import {
  addPaymentMethodListener,
  authenticatePaymentListener,
} from 'sagas/paymentSagas';
import AccountConstants from 'shared/constants/AccountConstants';

export const history = createHistory();
const middleware = routerMiddleware(history);

const appReducer = combineReducers({
  ...reducers,
  router: routerReducer,
  form: formReducer,
  toastr: toastrReducer,
});

const rootReducer = (state, action) => {
  if (action.type === AccountConstants.IMPERSONATE_SUCCESS) {
    return combineReducers({
      accounts: reducers.accounts,
      users: reducers.users,
      router: routerReducer,
      ui: reducers.ui,
      toastr: toastrReducer,
    })(
      {
        accounts: state.accounts,
        users: state.users,
        router: state.router,
        ui: state.ui,
        toastr: state.toastr,
      },
      action,
    );
  }
  return appReducer(state, action);
};

const parseQueryMiddleware = () => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const temp = action;
    temp.payload.query = action.payload.search
      ? qs.parse(temp.payload.search.substring(1))
      : {};
    return next(temp);
  }
  return next(action);
};

const sagaMiddleware = createSagaMiddleware();
function* rootSaga() {
  yield all([
    fork(adminUpdateCarerProfileListener),
    fork(apiRequestAndRefreshListener),
    fork(apiRequestListener),
    fork(approveBidListener),
    fork(carerAdhocTimesheetCreationListener),
    fork(conflictsWithBidsListener),
    fork(carerProfilePartialUpdateListener),
    fork(checkForUpdateListener),
    fork(createMultipleBidsListener),
    fork(createScheduleWithShiftsListener),
    fork(createTeamAndUpdateMemberListener),
    fork(getCareTeamByNeedWithCarersListener),
    fork(deleteMultipleShiftsListener),
    fork(fetchCareNeedRatesListener),
    fork(fetchShiftsForCarerUserIDListener),
    fork(fetchShiftsForCustomerUserIDListener),
    fork(fetchMoreShiftsForCustomerUserIDListener),
    fork(fetchSchedulesForJobOffersListener),
    fork(formSubmitSaga),
    fork(getBidsWithCarersListener),
    fork(getBidsWithJobOffersAndSchedulesAndShiftsListener),
    fork(getFranchiseUsersListener),
    fork(getJobOfferAndBidByReferenceForCarerListener),
    fork(getNeedByIdWithUserListener),
    fork(getLocalStorageLoginDataListener),
    fork(createNeedListener),
    fork(updateNeedAndCustomerListener),
    fork(getSchedulesWithScheduleShiftsByNeedIDListener),
    fork(getSchedulesWithScheduleShiftsByScheduleIDsListener),
    fork(updateScheduleListener),
    fork(updateShiftsListener),
    fork(getTeamMembersForTeamListener),
    fork(loginFlowListener),
    fork(magicTokenLoginListener),
    fork(logoutFlowListener),
    fork(referencesAddSuccessListener),
    fork(removeTeamMemberListener),
    fork(resetPasswordFlowListener),
    fork(fetchShiftsForNeedIDListener),
    fork(searchAndFetchUsersListener),
    fork(sendConfirmationEmailListener),
    fork(sendNotificationListener),
    fork(stopImpersonatingUser),
    fork(submitReferencesListener),
    fork(trackPageViewListener),
    fork(trackUserListener),
    fork(trackEventListener),
    fork(updatedReferenceListener),
    fork(updateTeamAndMemberListener),
    fork(verifyAccountFlowListener),
    fork(checkIfVerifiedListener),
    fork(clearFormListener),
    fork(addPaymentMethodListener),
    fork(authenticatePaymentListener),
    fork(getInvoiceListener),
  ]);
}

let myStore;
const middlewareArray = [sagaMiddleware, parseQueryMiddleware, middleware];

if (window.global.DEVELOPMENT) {
  middlewareArray.push(
    createLogger({
      collapsed: true,
      diff: true,
      duration: true,
      timestamp: false,
    }),
  );
  myStore = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewareArray)),
  );
} else {
  const notBot =
    navigator &&
    navigator.userAgent &&
    !navigator.userAgent.includes('Ghost Inspector');

  if (notBot && window.global.ENV === 'production') {
    // Logrocket limits our sessions, so we're trying to filter as much as possible
    const admin = isAdminInLocalStorage();
    const carer = isCarerInLocalStorage();
    const impersonator = isimpersonatorInLocalStorage();
    const initiateLogRocket = !admin && !carer && !impersonator;
    if (initiateLogRocket) {
      initLogRocket();
    }

    Sentry.init({
      dsn: 'https://26defa5525fa464c8d59d3138bbdec02@sentry.io/1313237',
      release: `app@${process.env.REACT_APP_VERSION}`,
    });

    if (initiateLogRocket) {
      addLogRocketToSentry();
      middlewareArray.push(logRocketMiddleware);
    }
  }
  myStore = createStore(rootReducer, applyMiddleware(...middlewareArray));
}

sagaMiddleware.run(rootSaga);

const store = myStore;

export default store;
