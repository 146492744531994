//@flow
import React from 'react';
import styled, { type ReactComponentStyled } from 'styled-components';

import AvatarImage from 'components/global/AvatarImage';

type Props = {|
  image:  {|
    created_at: ?null,
    id: ?null,
    profile: ?null,
    s3_path: ?null,
    temp_path: ?null,
  |},
  carer_display_name: string,
  className: string,
  height?: number,
  width?: number,
|};

function BidCarerImage({ image, carer_display_name, className, height, width }: Props) {
  const noCarerProfileImage: boolean =
    !image || !image.profile;

  const spanClassName: string = noCarerProfileImage
    ? `no-image ${className}`
    : `carer-image ${className}`;

  const imageSrc: ?string = noCarerProfileImage ? null : image.profile;
  const imageAlt: ?string = carer_display_name;

  return (
    <AvatarImage
      src={imageSrc}
      alt={imageAlt}
      spanClassName={spanClassName}
      height={height}
      width={width}
    />
  );
}

BidCarerImage.defaultProps = {
  className: '',
};

export default BidCarerImage;

export const TableBidCarerImage: ReactComponentStyled<Props> = styled(BidCarerImage)`
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;
