// @flow
const toTitleCase = (str: string) => {
  if (!str) {
    return str;
  }
  const newStr = str.replace(/_/g, ' ');
  return newStr.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
};

export default toTitleCase;
