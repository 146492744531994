/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';
import StandardTable from 'components/global/layout/StandardTable';
import StyledSelect from 'components/forms/StyledSelect';
import { Container, Row, Col } from 'components/grid';
import Module, { ModuleHero } from 'components/global/layout/Module';

class AdminCarerProfileChangeTab extends Component {
  static propTypes = {
    profiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    filterProfiles: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    neededFields: PropTypes.shape().isRequired,
    profileFilter: PropTypes.string.isRequired,
    profileState: PropTypes.string.isRequired,
    noPendingChanges: PropTypes.bool,
  };

  componentDidMount() {
    this.props.onMount(this.props.profileState);
  }

  render() {
    return (
      <Module>
        <Container>
          <Row style={{ paddingBottom: '30px' }}>
            <Col lg={4} md={6} xs={12}>
              <label htmlFor="profileFilter">
                <StyledSelect
                  id="profileFilter"
                  name="profileFilter"
                  onChange={this.props.filterProfiles}
                  value={this.props.profileFilter}
                >
                  <option value="">All Profiles</option>
                  {Object.entries(this.props.neededFields).map(
                    ([taxon_id, value]) => (
                      <option key={taxon_id} value={taxon_id}>
                        {value}
                      </option>
                    ),
                  )}
                </StyledSelect>
              </label>
            </Col>
            <Col lg={4} md={6} xs={12}>
              <label htmlFor="queryFilter">
                <StyledSelect
                  id="queryFilter"
                  name="queryFilter"
                  onChange={this.props.filterQueried}
                  value={this.props.queried}
                >
                  <option value="">Queried & Unqueried</option>
                  <option value="true">Queried only</option>
                  <option value="false">Unqueried only</option>
                </StyledSelect>
              </label>
            </Col>
          </Row>
        </Container>
        {this.props.noPendingChanges ? (
          <ModuleHero>No pending changes.</ModuleHero>
        ) : (
          <CarerProfileChangesTable
            profiles={this.props.profiles}
            neededFields={this.props.neededFields}
            applied={this.props.profileState === 'applied'}
          />
        )}
      </Module>
    );
  }
}

const CarerProfileChangesTable = ({ profiles, neededFields, applied }) => (
  <StandardTable>
    <thead>
      <tr>
        <th>Carer</th>
        <th>Updated Field(s)</th>
        {applied && <th>Fountain state</th>}
        <th />
        <th />
      </tr>
    </thead>
    <tbody>
      {profiles.map(profile => (
        <tr key={profile.id}>
          <td className="name">
            <Link to={`/carers/${profile.user_id}/`}>{profile.user_name}</Link>
          </td>
          <td className="changes">
            {profile.fields_changed
              .map(field => neededFields[field])
              .filter(field => field)
              .join(', ')}
          </td>
          {applied && (
            <td className="fountain-state">{profile.fountain_state}</td>
          )}
          <td className="queried">{!!profile.queried_at && 'Queried'}</td>
          <td>
            <Link
              to={`/admin/carers/profile/${profile.user_id}/`}
              className="btn btn-small"
            >
              Review
            </Link>
          </td>
        </tr>
      ))}
    </tbody>
  </StandardTable>
);

CarerProfileChangesTable.propTypes = {
  applied: PropTypes.bool.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  neededFields: PropTypes.shape().isRequired,
};

export default AdminCarerProfileChangeTab;
