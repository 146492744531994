import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';

import { searchCharges } from '_actions/chargeActions';

import AdminChargeSearch from 'components/admin/AdminChargeSearch';

import {
  getChargeSearch,
  getChargeResults,
} from 'shared/selectors/chargeSelectors';

import store from 'store';

class AdminChargeSearchContainer extends Component {
  static propTypes = {
    charges: PropTypes.arrayOf(PropTypes.shape()),
    location: PropTypes.shape().isRequired,
    search: PropTypes.shape(),
    searchCharges: PropTypes.func.isRequired,
  };

  static defaultProps = {
    charges: null,
    search: null,
    users: null,
  };

  state = {
    settings: {
      filters: {},
    },
  };

  componentDidMount() {
    const settings = this.parseLocation(this.props);
    this.search(settings);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      const settings = this.parseLocation(nextProps);
      this.search(settings);
    }
  }

  parseLocation = props => {
    const query = props.location.query;
    const page = query.page ? parseInt(query.page, 10) : 1;
    delete query.page;
    const filters = query;
    return {
      pagination: { page, pages: 1 },
      filters,
    };
  };

  search = settings => {
    this.setState({ settings });
    this.props.searchCharges(
      settings.filters,
      settings.pagination.page || 1,
      false,
    );
  };

  updateFilters = e => {
    const target = e.currentTarget;
    const params = this.props.location.query;

    if (target.value) {
      params[target.name] = target.value;
    } else {
      delete params[target.name];
    }

    // reset pagination
    delete params.page;

    store.dispatch(
      push(
        `${this.props.location.pathname}?${qs.stringify(params, {
          arrayFormat: 'repeat',
        })}`,
      ),
    );
  };

  render() {
    return (
      <AdminChargeSearch
        search={this.props.search}
        charges={this.props.charges}
        location={this.props.location}
        filters={this.state.settings.filters}
        updateFilters={this.updateFilters}
      />
    );
  }
}

const mapStateToProps = state => ({
  charges: getChargeResults(state),
  search: getChargeSearch(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ searchCharges }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminChargeSearchContainer);
