import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FeatureContainer from 'containers/admin/FeatureContainer';

class Features extends Component {
  render() {
    const { features } = this.props;
    return (
      <div className="module">
        <strong>Features: </strong>
        {Object.entries(features).map(feature => (
          <FeatureContainer
            key={feature[0]}
            name={feature[0]}
            active={feature[1]}
          />
        ))}
      </div>
    );
  }
}

Features.propTypes = {
  features: PropTypes.shape().isRequired,
};

export default Features;
