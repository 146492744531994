import React from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';
import Link from 'react-router-redux-dom-link';
import { func, shape } from 'prop-types';

import { Container, Row, Col } from 'components/grid';
import { BorderedHeader } from 'components/global/layout/Module';
import Definition from 'components/global/informationdisplay/Definition';
import Modal from 'components/modules/Modal';

function CarerShiftInformation({
  closePortal,
  selectedShift,
  showAdminLinks = true,
  shiftTypes,
}) {
  const need = selectedShift.need;
  const address = `${need.address1}${
    need.address2 ? ', ' + need.address2 : ''
  }${need.address3 ? ', ' + need.address3 : ''}, ${need.postcode}`;
  return (
    <Modal closePortal={closePortal} small key="shift-details-modal">
      <BorderedHeader key="modal-header">Shift Details</BorderedHeader>
      <Container key="shift-details-container">
        <Row>
          <Col>
            <Definition label="Care recipient">
              {showAdminLinks ? (
                <Link to={`/admin/needs/${need.id}/`}>{need.name}</Link>
              ) : (
                need.name
              )}
            </Definition>
            <Definition label="Address">{address}</Definition>
            <Definition label="Customer">
              {showAdminLinks ? (
                <Link to={`/admin/users/${need.user_id}/`}>
                  {need.user_name}
                </Link>
              ) : (
                need.user_name
              )}
            </Definition>
            <Definition label="Contact number">
              <a href={`tel:${need.contact_number}`}>{need.contact_number}</a>
            </Definition>
            <Definition label="Start">
              {moment(selectedShift.start).format('DD-MM-YYYY HH:mm')}
            </Definition>
            <Definition label="End">
              {moment(selectedShift.end).format('DD-MM-YYYY HH:mm')}
            </Definition>
            <Definition label="Shift type">
              {
                shiftTypes.find(
                  ({ taxon_id }) => taxon_id === selectedShift.type,
                ).value
              }
            </Definition>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

CarerShiftInformation.propTypes = {
  closePortal: func.isRequired,
  selectedShift: shape(),
};

export default CarerShiftInformation;
