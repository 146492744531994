import React, { Component } from 'react';
import { arrayOf, func, object as obj } from 'prop-types';
import { withTheme } from 'styled-components';

import CalendarShifts from 'components/shifts/CalendarShifts';
import CustomCalendarToolbar from 'components/schedules/CustomCalendarToolbar';
import CustomerShiftInformationContainer from 'containers/customers/CustomerShiftInformationContainer';
import {
  CustomEvent,
  CustomMonthEvent,
} from 'components/admin/needs/calendar/CustomEvents';

class CustomerScheduleShifts extends Component {
  static propTypes = {
    shifts: arrayOf(obj).isRequired,
    onNavigate: func.isRequired,
    initialTime: obj.isRequired,
    taxonomiesByClassification: obj.isRequired,
  };

  eventPropGetter = (event, start, end, isSelected) => {
    let backgroundColor = this.props.theme.colors.calendar[0];

    return {
      style: {
        backgroundColor,
        fontSize: '14px',
        borderColor: 'transparent',
      },
    };
  };

  dayRangeHeaderFormat = ({ start, end }, culture, localizer) => {
    return localizer.format(start, 'MMMM YYYY', culture);
  };

  render() {
    const {
      need,
      shifts,
      onNavigate,
      initialTime,
      taxonomiesByClassification,
    } = this.props;

    let updatedShifts = shifts.map(shift => {
      const updatedShift = { ...shift };
      updatedShift.onHoldBadge = shift.on_hold ? true : false;
      updatedShift.name = shift.carer_name
        ? shift.carer_name
        : 'Carer not yet assigned';
      return updatedShift;
    });

    return (
      <CalendarShifts
        calendarHeader={'Schedule'}
        components={{
          event: CustomEvent,
          toolbar: CustomCalendarToolbar,
          month: {
            event: CustomMonthEvent,
          },
        }}
        formats={{
          dayRangeHeaderFormat: this.dayRangeHeaderFormat,
        }}
        noShiftsMessage={'We are working on creating your schedule'}
        shifts={updatedShifts}
        shiftInformationModal={CustomerShiftInformationContainer}
        shiftStyles={this.eventPropGetter}
        shiftTitle={this.titleAccessor}
        onNavigate={onNavigate}
        initialTime={initialTime}
        taxonomiesByClassification={taxonomiesByClassification}
        need={need}
      />
    );
  }
}

export default withTheme(CustomerScheduleShifts);
