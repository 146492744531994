import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AdminChargeFormContainer from 'containers/admin/AdminChargeFormContainer';

class AdminCreateCharge extends Component {
  render() {
    return (
      <AdminChargeFormContainer
        onSubmit={this.props.onSubmit}
        consumer={this.props.consumer}
        carers={this.props.carers}
      />
    );
  }
}

AdminCreateCharge.propTypes = {
  consumer: PropTypes.shape().isRequired,
  carers: PropTypes.arrayOf(PropTypes.shape()),
  onSubmit: PropTypes.func.isRequired,
};

AdminCreateCharge.defaultProps = {
  carers: null,
};

export default AdminCreateCharge;
