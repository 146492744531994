import styled from 'styled-components';

const BasicFlexItem = styled.div`
  flex-basis: ${({ basis }) => basis};
  min-width: ${({ basis }) => basis};
  padding: ${({ padding }) => padding};
  text-align: center;
`;

export default BasicFlexItem;
