import React from 'react';
import ReduxToastr from 'react-redux-toastr';

function ReduxToastrMessage() {
  return (
    <ReduxToastr
      timeout={3000}
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      position="top-center"
      showCloseButton
      preventDuplicates
    />
  );
}

export default ReduxToastrMessage;
