import moment from 'moment';
import { takeEvery, call, put, select } from 'redux-saga/effects';

import MetaClient from 'clients/MetaClient';
import ApiConstants from 'shared/constants/ApiConstants';
import MetaConstants from 'shared/constants/MetaConstants';
import { getLastCheckedForUpdate } from 'shared/selectors/metaSelectors';

const UPDATE_INTERVAL_MINUTES = 5;

export function* checkForUpdate() {
  const lastCheckedForUpdate = yield select(getLastCheckedForUpdate);

  if (
    !lastCheckedForUpdate ||
    moment().diff(lastCheckedForUpdate, 'minutes') > UPDATE_INTERVAL_MINUTES
  ) {
    // checked for update more than interval minutes ago

    try {
      const currentEtag = yield call(MetaClient.getCurrentEtag);
      yield put({
        type: MetaConstants.META_CHECK_FOR_UPDATE_SUCCESS,
        etag: currentEtag,
      });
    } catch (error) {
      yield put({ type: MetaConstants.META_CHECK_FOR_UPDATE_FAILURE });
    }
  }
}

export function* checkForUpdateListener() {
  yield takeEvery(ApiConstants.API_REQUEST, checkForUpdate);
}
