import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Definition from 'components/global/informationdisplay/Definition';
import {
  OneLineList,
} from 'components/global/informationdisplay/DisplayLists';
import { ModuleSubheader } from 'components/global/layout/Module';
import toTitleCase from 'components/helpers/toTitleCase';
export default class SummaryCustomerModule extends React.Component {
  static propTypes = {
    need: PropTypes.shape().isRequired,
    taxonomiesByClassification: PropTypes.shape().isRequired,
  };

  render() {
    const { need, taxonomiesByClassification } = this.props;
    return (
      <>
        <ModuleSubheader noMargin>Care need</ModuleSubheader>
        <Definition label="Created">
          {moment(need.created_at).format('DD-MM-YYYY HH:mm')}
        </Definition>

        { need.start_date && (
          <Definition label="Start Date">
            {moment(need.start_date).format('DD-MM-YYYY')}
          </Definition>
        )}

        { need.end_date && (
          <Definition label="End Date">
            {moment(need.end_date).format('DD-MM-YYYY')}
          </Definition>
        )}

        { need.schedule_description && (

          <Definition label="Care Times">
            <div
              id="scheduleDescription"
              dangerouslySetInnerHTML={{ __html: need.schedule_description }}
            />
          </Definition>
        )}

        { need.admin_notes && (
          <Definition label="Admin notes">
            <div
              id="scheduleDescription"
              dangerouslySetInnerHTML={{ __html: need.admin_notes }}
            />
          </Definition>
        )}

        {need.carer_gender && (
          <OneLineList
            alwaysDisplay
            list={need.carer_gender}
            taxonomies={taxonomiesByClassification['gender_preference']}
            label="Carer gender"
          />
        )}

        {need.needs_license !== null && (
          <Definition label="Driver needed">
            {need.needs_license ? 'Yes' : 'No'}
          </Definition>
        )}
        {need.needs_car !== null && (
          <Definition label="Car needed">
            {need.needs_car ? 'Yes' : 'No'}
          </Definition>
        )}
        {need.pets.length !== 0 && (
          <Definition label="Pets">
            {need.pets && need.pets.map(toTitleCase).join(', ')}
          </Definition>
        )}
      </>
    );
  }
}
