/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Col, Row } from 'react-grid-system';
import Link from 'react-router-redux-dom-link';
import moment from 'moment';
import styled from 'styled-components';
import { PortalWithState } from 'react-portal';

import StandardTable from 'components/global/layout/StandardTable';
import Module, {
  ModuleHeader,
  ModuleHero,
  ModuleMeta,
} from 'components/global/layout/Module';
import Button from 'components/buttons/Button';
import { DeleteIcon, EditIcon } from 'components/global/icons';
import StatusIndicator from 'components/global/StatusIndicator';
import stateHelper from 'components/helpers/stateHelper';
import { renderTaxon } from 'components/helpers/taxonomyHelpers';
import Modal from 'components/modules/Modal';
import AdminReferenceResponseForm from 'components/references/AdminReferenceResponseForm';
import AdminUserReferenceSendForm from 'components/references/AdminUserReferenceSendForm';
import ConfirmationDialog from 'components/global/ConfirmationDialog';
import NotesContainer from 'containers/notes/NotesContainer';
import MarkCompliant from 'components/modules/MarkCompliant';

const DeleteLink = styled.a`
  color: ${props => props.theme.colors.midGrey};
`;

const StyledHeader = styled(ModuleHeader)`
  margin-left: 0;
`;

const Meta = styled.div`
  color: ${props => props.theme.colors.midGrey};
`;

const ReferenceField = styled.span`
  font-weight: 600;
`;

class AdminUserReferenceSummary extends Component {
  static propTypes = {
    addReferenceResponse: PropTypes.func.isRequired,
    carer: PropTypes.shape().isRequired,
    deleteReference: PropTypes.func.isRequired,
    inviteReference: PropTypes.func.isRequired,
    markReferencesHICompliant: PropTypes.func.isRequired,
    references: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    referencesHICompliant: PropTypes.bool.isRequired,
    referencesMarkedHICompliant: PropTypes.bool.isRequired,
    sendReference: PropTypes.func.isRequired,
    submitReference: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.shape(),
  };

  state = {
    deletingReference: null,
    referenceResponse: null,
  };

  enterResponse = reference => {
    this.setState({
      responseReference: reference,
    });
  };

  confirmDeletion = referenceID => {
    this.setState({
      deletingReference: referenceID,
    });
  };

  render() {
    const {
      carer,
      referencesHICompliant,
      markReferencesHICompliant,
      referencesMarkedHICompliant,
      references,
      taxonomiesByClassification,
    } = this.props;
    return (
      <section>
        <Module>
          <Container>
            <Row>
              <Col xs={18} md={9}>
                <StyledHeader>Reference Summary</StyledHeader>
              </Col>
              <Col xs={6} md={3}>
                <ModuleMeta>
                  {referencesHICompliant !== null && (
                    <StatusIndicator
                      status={
                        `home_instead_${
                          referencesMarkedHICompliant ? 'compliant_override' :
                          referencesHICompliant ? 'compliant' :'non-compliant'
                        }`
                      }
                    />
                  )}
                  {(!referencesHICompliant || referencesMarkedHICompliant) && (
                    <MarkCompliant
                      markCompliant={markReferencesHICompliant}
                      compliant={referencesMarkedHICompliant}
                      message="references compliant"
                    />
                  )}
                </ModuleMeta>
              </Col>
            </Row>
          </Container>
          {references.length === 0 && (
            <ModuleHero>This carer has not entered any references</ModuleHero>
          )}
          {references.length > 0 && (
            <StandardTable className="user-references">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Referee</th>
                  <th>Edit</th>
                  <th>Status</th>
                  <th>Assessed</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {references.map(reference => (
                  <tr key={reference.id}>

                    <td>
                      <ReferenceField>
                        {reference.professional_reference ? 'Professional' : 'Personal'}
                      </ReferenceField>
                    </td>

                    <td>
                      <div>
                        {reference.firstname} {reference.lastname}
                      </div>
                      <Meta>
                        {reference.professional_reference
                          ? renderTaxon(
                            taxonomiesByClassification['reference_roles'],
                            reference.reference_role,
                          )
                          : renderTaxon(
                            taxonomiesByClassification['reference_relationships'],
                            reference.reference_relationship,
                          )
                        }
                      </Meta>
                    </td>

                    <td>
                      <Link to={`/admin/users/${carer.id}/references/${reference.id}/`}>
                        <EditIcon />
                      </Link>
                    </td>

                    <td>
                      <StatusIndicator
                        status={
                          stateHelper.REFERENCE_DISPLAY_STATES[reference.state]
                        }
                      />
                      <Meta>
                        {
                          reference.state === 'invited' &&
                          reference.invited_at &&
                          `on ${moment(reference.invited_at).format('DD.MM.YYYY')}`
                        }
                      </Meta>
                    </td>

                    <td>
                      <ReferenceField>
                        {
                          renderTaxon(
                            taxonomiesByClassification['reference_responses'],
                            reference.response,
                          )
                        }
                      </ReferenceField>
                    </td>

                    <td>
                      {reference.state === 'new' && (
                        <Button
                          onClick={() => this.props.submitReference(reference.id)}
                        >
                          Submit
                        </Button>
                      )}
                      {reference.state === 'submitted' && (
                        <Button
                          className="altGreen"
                          onClick={() => this.props.inviteReference(reference.id)}
                        >
                          Send request
                        </Button>
                      )}
                      {['invited', 'non_responsive'].includes(
                        reference.state,
                      ) && (
                        <Button
                          className="altRed"
                          onClick={e => this.enterResponse(reference)}
                        >
                          Add response
                        </Button>
                      )}
                      {reference.state === 'responded' && (
                        <Button
                          className="alt"
                          onClick={e => this.enterResponse(reference)}
                        >
                          Edit response
                        </Button>
                      )}
                    </td>

                    <td>
                      <DeleteLink
                        onClick={() => this.confirmDeletion(reference.id)}
                        role="button"
                      >
                        <DeleteIcon />
                      </DeleteLink>
                    </td>

                  </tr>
                ))}
              </tbody>
            </StandardTable>
          )}

          <Container>
            <Row>
              <Col>
                <Link to={`/admin/users/${carer.id}/references/add/`}>
                  Add a reference
                </Link>
              </Col>
            </Row>
          </Container>

          {this.state.responseReference && (
            <PortalWithState
              closeOnOutsideClick
              closeOnEsc
              onClose={() => this.setState({ responseReference: null })}
              defaultOpen
            >
              {({ openPortal, closePortal, isOpen, portal }) =>
                portal(
                  <Modal closePortal={closePortal} key="response-modal">
                    <AdminReferenceResponseForm
                      initialValues={this.state.responseReference}
                      onSubmit={data =>
                        this.props.addReferenceResponse(
                          data,
                          this.state.responseReference,
                          closePortal,
                        )
                      }
                      taxonomiesByClassification={taxonomiesByClassification}
                    />
                  </Modal>,
                )
              }
            </PortalWithState>
          )}

          {this.state.deletingReference && (
            <ConfirmationDialog
              title="Delete this reference?"
              message="Caution. This cannot be undone."
              confirmation="Yes, delete it"
              onConfirm={() => {
                this.props.deleteReference(this.state.deletingReference);
                this.setState({ deletingReference: null });
              }}
              onCancel={() => {
                this.setState({ deletingReference: null });
              }}
              danger
            />
          )}

        </Module>

        <NotesContainer
          objectID={carer.id}
          category="workhistory"
          placeholder="e.g. Gaps in references and other anomalies"
        />

        <AdminUserReferenceSendForm onSubmit={this.props.sendReference} />

      </section>
    );
  }
}

export default AdminUserReferenceSummary;
