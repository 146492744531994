import styled from 'styled-components';

const StandardList = styled.ul`
  margin: 20px 0;
  width: 100%;
  li {
    padding: 20px 10px;
    border-bottom: 1px solid ${props => props.theme.colors.uiGrey};
    &:first-child {
      border-top: 1px solid ${props => props.theme.colors.uiGrey};
    }
  }
`;

export default StandardList;
