// @flow
import React from 'react';
import { Container, Col } from 'react-grid-system';

import FormRow from 'components/forms/FormRow';
import FormTemplate from 'components/forms/FormTemplate';
import Form from 'components/forms/Form';

import Button from 'components/buttons/Button';

import fieldsConfig from 'components/account/PersonalDetailsFormFields';

import type { TaxonomiesClassificationType, UserType } from 'shared/types';

type Props = {
  isCarer: boolean,
  taxonomiesByClassification: TaxonomiesClassificationType,
  updateAccount: Object => Object,
  user: UserType,
};

const PersonalDetailsForm = ({
  isCarer,
  taxonomiesByClassification,
  updateAccount,
  user,
}: Props) => (
  <Form
    name="personalDetails"
    fields={[
      'firstname',
      'lastname',
      'preferred_name',
      'title',
      'email',
      'emails_non_essential_enabled',
      'phone_number',
      'gender',
      'address1',
      'address2',
      'address3',
      'town',
      'postcode',
      'next_of_kin_name',
      'next_of_kin_number',
      'next_of_kin_relationship',
    ]}
    values={user}
    onSubmit={updateAccount}
    component={({ handleSubmit, pristine }) => (
      <form onSubmit={handleSubmit}>
        <FormTemplate
          extraDisplayCriteria={{ emailVerified: user.confirmed_at, isCarer:isCarer }}
          fieldsConfig={fieldsConfig}
          taxonomiesByClassification={taxonomiesByClassification}
        />
        <Container>
          <FormRow>
            <Col xs={12} md={6}>
              <Button disabled={pristine} type="submit">
                Save Changes
              </Button>
            </Col>
          </FormRow>
        </Container>
      </form>
    )}
  />
);

export default PersonalDetailsForm;
