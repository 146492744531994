import BidConstants from 'shared/constants/BidConstants';
import JobOfferConstants from 'shared/constants/JobOfferConstants';
import {
  addObject,
  addObjects,
  setLoadingState,
} from 'shared/reducers/reducerHelpers';

const initalState = {
  loading: {
    bids: false,
    bidsWithCarers: false,
  },
};

function bids(state = initalState, action) {
  switch (action.type) {
    case BidConstants.BID_SEARCH:
      return setLoadingState(state, 'bids', true);

    case BidConstants.BID_SEARCH_SUCCESS:
      return setLoadingState(addObjects(state, action), 'bids', false);

    case BidConstants.SEARCH_BIDS_WITH_CARERS:
      return setLoadingState(state, 'bidsWithCarers', true);

    case BidConstants.SEARCH_BIDS_WITH_CARERS_SUCCESS:
    case BidConstants.SEARCH_BIDS_WITH_CARERS_FAILURE:
      return setLoadingState(state, 'bidsWithCarers', false);

    case BidConstants.BID_CREATE_SUCCESS:
    case BidConstants.BID_UPDATE_SUCCESS:
    case BidConstants.BID_NOTIFY_SUCCESS:
    case BidConstants.BID_GET_SUCCESS:
      return addObject(state, action);

    case JobOfferConstants.JOB_OFFER_NOTIFY_OF_BIDS_SUCCESS: {
      const newState = { ...state };
      action.data.forEach(bidID => {
        if (newState[bidID]) {
          newState[bidID].proposed_at = {
            ...newState[bidID],
            proposed_at: new Date().toString(),
          };
        }
      });
      return newState;
    }

    default:
      return state;
  }
}

export default bids;
