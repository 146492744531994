import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.colors.lightGrey};
  padding: 15px 5px;

  @media (min-width: 768px) {
    padding: 15px 20px;
  }
`;

class AppFooter extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    ).isRequired,
  };

  render() {
    return <StyledFooter>{this.props.children}</StyledFooter>;
  }
}

export default AppFooter;
