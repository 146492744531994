import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';

import toTitleCase from 'components/helpers/toTitleCase';

class TeamResultRow extends Component {
  render() {
    const { team } = this.props;
    const needId = team.need_id || '';
    const customerName = team.customer_name || '';
    let age = moment().fromNow();
    if (team.state.entered_at) {
      age = moment(team.state.entered_at).fromNow();
    }
    return (
      <tr>
        <td>{customerName}</td>
        <td>
          <span className={`status ${team.state.state}`}>
            {toTitleCase(team.state.state || 'none')}
          </span>
        </td>
        <td>{age}</td>
        <td>
          <Link to={`/admin/needs/${needId}/`}>Manage</Link>
        </td>
      </tr>
    );
  }
}

TeamResultRow.propTypes = {
  team: PropTypes.shape().isRequired,
};

export default TeamResultRow;
