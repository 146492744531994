import HolidayConstants from 'shared/constants/HolidayConstants';

const initalState = [];

function holidays(state = initalState, action, returnDefaultState = true) {
  let days;
  switch (action.type) {
    case HolidayConstants.HOLIDAY_GET:
      return state;

    case HolidayConstants.HOLIDAY_GET_SUCCESS:
      return action.data['results'] || action.data;

    case HolidayConstants.HOLIDAY_GET_FAILURE:
      return state;

    case HolidayConstants.HOLIDAY_CREATE:
      return state;

    case HolidayConstants.HOLIDAY_CREATE_SUCCESS:
      days = state.slice();
      days.unshift(action.data);
      return days;

    case HolidayConstants.HOLIDAY_CREATE_FAILURE:
      return state;

    case HolidayConstants.HOLIDAY_UPDATE:
      return state;

    case HolidayConstants.HOLIDAY_UPDATE_SUCCESS:
      return state;

    case HolidayConstants.HOLIDAY_UPDATE_FAILURE:
      return state;

    case HolidayConstants.HOLIDAY_DELETE:
      return state;

    case HolidayConstants.HOLIDAY_DELETE_SUCCESS:
      days = state.filter(holiday => !(action.data.date === holiday.date));
      return days;

    case HolidayConstants.HOLIDAY_DELETE_FAILURE:
      return state;

    default:
      return returnDefaultState ? state : false;
  }
}

export default holidays;
