import PropTypes from 'prop-types';
import React from 'react';

const ProfileIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 27"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="profile-icon"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <g transform="translate(3.714286, 3.714286)">
          <path d="M5.70355655,7.1940878 L5.7146994,7.20151637 C5.91279464,7.32656399 6.10036607,7.44480208 6.17279464,7.70046875 C6.35788988,8.35480208 7.59660417,10.7870402 9.24822321,10.7870402 C10.8998423,10.7870402 12.1391756,8.35480208 12.3236518,7.70170685 C12.3948423,7.44604018 12.5916994,7.32161161 12.781747,7.20151637 L12.7928899,7.19470685 C13.2132232,6.39551637 13.1277946,6.08351637 13.1048899,6.02718304 L13.0256518,5.94980208 C12.7204613,5.8928497 12.5328899,5.62418304 12.5489851,5.31465923 C12.5496042,5.30475446 12.5966518,4.32232589 12.3638899,3.42718304 L12.3453185,3.31327827 C12.3279851,3.12261161 12.0871756,1.39361161 9.24822321,1.39361161 C6.32941369,1.39361161 6.15731845,3.23899256 6.15112798,3.31761161 L6.13255655,3.42656399 C5.89855655,4.32913542 5.94746131,5.30413542 5.94808036,5.31404018 C5.96479464,5.62418304 5.74936607,5.89842113 5.4441756,5.95599256 L5.39155655,6.02718304 C5.36927083,6.08351637 5.28322321,6.39551637 5.70355655,7.1940878 Z M9.24822321,12.0251354 C6.86550893,12.0251354 5.4156994,9.21218304 5.05231845,8.2470878 C4.90312798,8.15237351 4.77374702,8.07065923 4.68522321,7.91280208 C4.12808036,6.91056399 3.99127083,6.09589732 4.27541369,5.49046875 C4.38250893,5.26204018 4.53912798,5.09056399 4.70627083,4.96799256 C4.71184226,4.53961161 4.75146131,3.83699256 4.92603274,3.14799256 C4.99846131,2.67318304 5.55808036,0.155516369 9.24822321,0.155516369 C12.9519851,0.155516369 13.4998423,2.6688497 13.5710327,3.1498497 C13.7456042,3.83823065 13.7852232,4.53961161 13.7907946,4.96799256 C13.9573185,5.09056399 14.1139375,5.26204018 14.2210327,5.49046875 C14.5057946,6.09589732 14.3683661,6.90994494 13.8130804,7.91032589 C13.7245565,8.07004018 13.5945565,8.15237351 13.444747,8.2470878 C13.0795089,9.21527827 11.6303185,12.0251354 9.24822321,12.0251354 L9.24822321,12.0251354 Z" />
          <path d="M0.884889881,17.9141354 C0.543175595,17.9141354 0.265842262,17.6368021 0.265842262,17.2950878 C0.265842262,14.4629449 1.84750893,12.4578497 4.83998512,11.4970878 C6.17155655,11.0687068 6.89027083,10.368564 6.8976994,10.3617545 C7.14036607,10.121564 7.53160417,10.1228021 7.77241369,10.3636116 C8.01322321,10.6044211 8.01508036,10.9938021 7.77488988,11.2352307 C7.73836607,11.2723735 6.85498512,12.149564 5.21822321,12.6757545 C2.71850893,13.4780402 1.5039375,14.9891354 1.5039375,17.2950878 C1.5039375,17.6368021 1.22660417,17.9141354 0.884889881,17.9141354" />
          <path d="M17.604747,18.4168021 C17.2760327,18.4168021 17.0024137,18.0038973 16.9869375,17.6720878 C16.8346518,14.4059926 15.0270327,13.3146116 13.2788423,12.7531354 C11.6414613,12.2269449 10.7586994,11.3113735 10.7221756,11.2742307 C10.4813661,11.031564 10.4832232,10.6198973 10.7258899,10.3797068 C10.9679375,10.1395164 11.3585565,10.1302307 11.5993661,10.3716592 C11.6185565,10.3902307 12.3366518,11.0779926 13.6570804,11.5020402 C15.5259851,12.1018973 18.033128,13.5250878 18.2237946,17.6169926 C18.2398899,17.9587068 17.9755565,17.7977545 17.6344613,18.4168021 L17.604747,18.4168021" />
        </g>
        <path d="M13,1.23809524 C6.5142381,1.23809524 1.23809524,6.51485714 1.23809524,13 C1.23809524,19.4857619 6.5142381,24.7619048 13,24.7619048 C19.4851429,24.7619048 24.7619048,19.4857619 24.7619048,13 C24.7619048,6.51485714 19.4851429,1.23809524 13,1.23809524 Z M13,26 C5.83142857,26 0,20.1685714 0,13 C0,5.83204762 5.83142857,0 13,0 C20.1679524,0 26,5.83204762 26,13 C26,20.1685714 20.1679524,26 13,26 L13,26 Z" />
      </g>
    </svg>
  </div>
);

ProfileIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

ProfileIcon.defaultProps = {
  className: '',
  height: '27px',
  width: '26px',
};

export default ProfileIcon;
