import Input from 'components/forms/Input';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class CurrencyInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    help: PropTypes.string,
  };

  static defaultProps = {
    value: 0,
    help: null,
  };

  onChange = (name, value) => {
    if (this.props.onChange && !this.props.readOnly) {
      const convertedValue = Math.round(parseInt(value * 1000, 10) / 10);
      this.props.onChange(name, convertedValue);
    }
  };

  render() {
    return (
      <Input
        type="number"
        step="0.01"
        prefix="£"
        name={this.props.name}
        onChange={this.onChange}
        value={this.props.value / 100}
        help={this.props.help}
      />
    );
  }
}

export default CurrencyInput;
