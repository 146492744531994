import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 20%;
`;

class SummaryTitle extends Component {
  render() {
    const { title, subtitle } = this.props;

    return (
      <StyledDiv>
        <h3>
          <strong>{title}</strong>
          {subtitle && <div className="subtitle">{subtitle}</div>}
        </h3>
      </StyledDiv>
    );
  }
}

SummaryTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.element.isRequired,
};

export default SummaryTitle;
