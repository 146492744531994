import PropTypes from 'prop-types';
import React from 'react';

const ReferencesIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Office-39"
          transform="translate(1.000000, 1.000000)"
          stroke="#D0DCE6"
        >
          <g id="Group">
            <path
              d="M2.36363636,13.5909091 L2.36363636,5.90894667 L7.09088486,0 L22.4545455,0 C23.107245,0 23.6363636,0.529118082 23.6363636,1.18181818 L23.6363636,13"
              id="Shape"
            />
            <path
              d="M7.68181818,7.68181818 L18.3181818,7.68181818"
              id="Shape"
            />
            <path
              d="M7.68181818,10.6363636 L18.3181818,10.6363636"
              id="Shape"
            />
            <path
              d="M24.2272727,26 L1.77272727,26 C0.793677064,26 0,25.2063221 0,24.2272727 L0,13 L26,24.2272727 C26,25.2063221 25.2063221,26 24.2272727,26 Z"
              id="Shape"
            />
            <polyline
              id="Shape"
              points="26 24.2272727 26 12.4090909 13 18.3181818"
            />
            <path d="M0,13 L2.36363636,10.0454545" id="Shape" />
            <path d="M26,12.4090909 L23.6363636,10.0454545" id="Shape" />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

ReferencesIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

ReferencesIcon.defaultProps = {
  className: '',
  height: '27px',
  width: '27px',
};

export default ReferencesIcon;
