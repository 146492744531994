import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { renderTaxon } from 'components/helpers/taxonomyHelpers';

const StyledInfo = styled.div`
  padding: 20px;
`;

function InfoPane({ carer, notes, taxonomiesByClassification }) {
  let contact;
  const data = carer.profile_details.profile_data;
  const conditionExperienceToList = data.condition_experience
    .filter(condExp => condExp.experience !== 'lessthan1month')
    .map(condExp => condExp.condition);

  return (
    <StyledInfo>
      {notes.length > 0 && (
        <p>
          <em>“{notes[0].message}”</em>
        </p>
      )}
      {contact}
      {data.care_type && data.care_type.length > 0 && (
        <p>
          <strong>Care Types:</strong>{' '}
          {renderTaxon(taxonomiesByClassification.care_type, data.care_type)}
        </p>
      )}
      {!!data.care_experience_years && (
        <p>
          <strong>Care Experience:</strong>{' '}
          {renderTaxon(
            taxonomiesByClassification.care_experience_years,
            data.care_experience_years,
          )}
        </p>
      )}
      {conditionExperienceToList.length > 0 && (
        <p>
          <strong>Condition Experience:</strong>{' '}
          {renderTaxon(
            taxonomiesByClassification.condition,
            conditionExperienceToList,
          )}
        </p>
      )}
      {!!data.qualifications && data.qualifications.length > 0 && (
        <p>
          <strong>Qualifications:</strong>{' '}
          {renderTaxon(
            taxonomiesByClassification.qualifications,
            data.qualifications,
          )}
        </p>
      )}
    </StyledInfo>
  );
}

InfoPane.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  carer: PropTypes.shape().isRequired,
  taxonomiesByClassification: PropTypes.shape().isRequired,
};

InfoPane.defaultProps = {
  requestContact: null,
};

export default InfoPane;
