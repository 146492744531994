import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AdminNeedTabbedComponents from 'components/admin/needs/AdminNeedTabbedComponents';

import { getCareTeamByNeedWithCarers } from '_actions/careTeamActions';
import { getNeedByIdWithUser } from '_actions/needActions';
import { getSchedulesWithScheduleShiftsByNeedID } from '_actions/scheduleActions';

import { getTeamForNeedByID } from 'shared/selectors/needSelectors';
import { getNeedByID } from 'shared/selectors/needSelectors';
import { selectSchedulesWithShiftsByNeedID } from 'shared/selectors/scheduleShiftsSelectors';

const mapStateToProps = (state, props) => ({
  need: getNeedByID(state, props.match.params.needID),
  schedules: selectSchedulesWithShiftsByNeedID(
    state,
    props.match.params.needID,
  ),
  match: props.match,
  team: getTeamForNeedByID(state, props.match.params.needID),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCareTeamByNeedWithCarers,
      getNeedByIdWithUser,
      getSchedulesWithScheduleShiftsByNeedID,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminNeedTabbedComponents);
