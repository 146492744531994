// @flow
import ApiConstants from 'shared/constants/ApiConstants';
import BidConstants from 'shared/constants/BidConstants';

import type { APIAction, BidType } from 'shared/types';

export function getBid(bidID: string): APIAction {
  return {
    endpoint: `/bid/${bidID}/`,
    method: 'get',
    onError: { type: BidConstants.BID_GET_FAILURE },
    onStart: { type: BidConstants.BID_GET },
    onSuccess: { type: BidConstants.BID_GET_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function searchBids(
  params: Object,
  page: number,
  searchName: string,
  updateStore: boolean = true,
): APIAction {
  const query = Object.assign({ page: page || 1 }, params);
  return {
    endpoint: '/bid/find/',
    method: 'get',
    onError: {
      type: BidConstants.BID_SEARCH_FAILURE,
    },
    onStart: {
      type: BidConstants.BID_SEARCH,
    },
    onSuccess: {
      type: updateStore
        ? BidConstants.BID_SEARCH_SUCCESS
        : BidConstants.BID_SEARCH_SUCCESS_FOR_SAGA,
      payload: searchName,
    },
    payload: query,
    type: ApiConstants.API_REQUEST,
  };
}

export type SearchParamsType = {
  carer_id: string,
  job_states?: Array<string>,
  page: ?Number,
  states?: Array<string>,
  order_by_field: ?string,
};

export function searchBidsWithJobOffers(
  params: SearchParamsType,
  page: number,
  searchName: string,
) {
  return {
    payload: {
      params,
      page,
      searchName,
    },
    type: BidConstants.SEARCH_BIDS_WITH_JOB_OFFERS_AND_SCHEDULES_AND_SHIFTS,
  };
}

export function searchBidsWithCarers(params: SearchParamsType, page: number) {
  return {
    payload: {
      params,
      page,
    },
    type: BidConstants.SEARCH_BIDS_WITH_CARERS,
  };
}

export function createBid(data: BidType): APIAction {
  return {
    endpoint: '/bid/',
    method: 'post',
    onError: { type: BidConstants.BID_CREATE_FAILURE },
    onStart: { type: BidConstants.BID_CREATE },
    onSuccess: { type: BidConstants.BID_CREATE_SUCCESS },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function createMultipleBids(
  carerIDs: Array<string>,
  jobOfferID: string,
  searchParams: SearchParamsType,
) {
  return {
    payload: { carerIDs, jobOfferID, searchParams },
    type: BidConstants.BID_CREATE_MULTIPLE,
  };
}

export type SubmitBidType = {
  bidID: string,
  applied_shifts: Array<string>,
  description: string,
  admin_description: string,
  message: string,
  state: string,
};

export function submitBid({
  bidID,
  applied_shifts,
  description,
  admin_description,
  message,
  state = 'applied',
}: SubmitBidType): APIAction {
  const payload = {
    available_schedule_shift_ids: applied_shifts || {},
    admin_description,
    description,
    message,
    state,
  };
  return {
    endpoint: `/bid/${bidID}/`,
    method: 'put',
    onError: {
      type: BidConstants.BID_UPDATE_FAILURE,
    },
    onStart: {
      type: BidConstants.BID_UPDATE,
    },
    onSuccess: {
      type: BidConstants.BID_UPDATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title:
          state === 'applied'
            ? 'Application has been submitted.'
            : `Application state set to ${state}`,
      },
    },
    payload,
    type: ApiConstants.API_REQUEST,
  };
}

export function updateBid(bidID: string, payload: BidType): APIAction {
  return {
    endpoint: `/bid/${bidID}/`,
    method: 'put',
    onError: {
      type: BidConstants.BID_UPDATE_FAILURE,
    },
    onStart: {
      type: BidConstants.BID_UPDATE,
    },
    onSuccess: {
      type: BidConstants.BID_UPDATE_SUCCESS,
    },
    payload,
    type: ApiConstants.API_REQUEST,
  };
}

export function approveBid(bid: BidType, needID: string) {
  return {
    payload: { bid, needID },
    type: BidConstants.BID_APPROVE,
  };
}

export function notifyBid(bidID: string): APIAction {
  return {
    endpoint: `/notification/bid/${bidID}/`,
    method: 'post',
    onError: {
      type: BidConstants.BID_NOTIFY_FAILURE,
    },
    onStart: {
      type: BidConstants.BID_NOTIFY,
    },
    onSuccess: {
      type: BidConstants.BID_NOTIFY_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'SMS sent',
      },
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function renotifyBids(jobOfferID: string): APIAction {
  return {
    endpoint: `/notification/job/${jobOfferID}/bids/new/`,
    method: 'post',
    onError: {
      type: BidConstants.BID_RENOTIFY_FAILURE,
    },
    onStart: {
      type: BidConstants.BID_RENOTIFY,
    },
    onSuccess: {
      type: BidConstants.BID_RENOTIFY_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Carers have been notified',
      },
    },
    type: ApiConstants.API_REQUEST,
  };
}
