import keyMirror from 'keymirror';

export default keyMirror({
  HOLIDAY_GET: null,
  HOLIDAY_GET_SUCCESS: null,
  HOLIDAY_GET_FAILURE: null,

  HOLIDAY_CREATE: null,
  HOLIDAY_CREATE_SUCCESS: null,
  HOLIDAY_CREATE_FAILURE: null,

  HOLIDAY_UPDATE: null,
  HOLIDAY_UPDATE_SUCCESS: null,
  HOLIDAY_UPDATE_FAILURE: null,

  HOLIDAY_DELETE: null,
  HOLIDAY_DELETE_SUCCESS: null,
  HOLIDAY_DELETE_FAILURE: null,
});
