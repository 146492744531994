// @flow
import * as React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 100%;
`;

const StyledHeader = styled.h3`
  color: ${props => props.theme.colors.midGrey};
  margin-bottom: 10px;
  margin-left: 20px;
`;

const StyledFlexContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
`;

const StyledFlexRow = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.uiGrey};
  font-weight: bold;
`;

type Props = {
  className?: string,
  header?: string,
  children: React.ChildrenArray<any>,
};

const StandardFlexTable = ({ className, children, header }: Props) => (
  <StyledDiv className={className}>
    {header && <StyledHeader>{header}</StyledHeader>}
    <StyledFlexContainer className={className}>
      {children.map((child, index) => (
        <StyledFlexRow key={index}>{child}</StyledFlexRow>
      ))}
    </StyledFlexContainer>
  </StyledDiv>
);

export default StandardFlexTable;
