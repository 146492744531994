import { delay } from 'redux-saga';
import { all, call, takeLatest, take, put } from 'redux-saga/effects';

import { getUserById, searchUsers } from '_actions/userActions';
import UserConstants from 'shared/constants/UserConstants';

export function* searchAndFetchUsers(action) {
  yield call(delay, 500); // debounce 500ms
  try {
    yield put(searchUsers(action.params));
    const results = yield take(UserConstants.USER_SEARCH_SUCCESS);
    yield all(results.data.ids.map(id => put(getUserById(id))));
    yield put({ type: UserConstants.USER_SEARCH_AND_FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: UserConstants.USER_SEARCH_AND_FETCH_FAILURE });
  }
}

export function* searchAndFetchUsersListener() {
  yield takeLatest(UserConstants.USER_SEARCH_AND_FETCH, searchAndFetchUsers);
}
