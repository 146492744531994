import { createSelector } from 'reselect';
import {
  getTimesheetByID,
  getTimesheetsForInvoiceByID,
} from 'shared/selectors/timesheetSelectors';

export const getRefunds = state => state.refunds;
export const getRefundSearch = state => state.search.refunds;
export const getRefundsByChargeID = (state, id) =>
  Object.values(state.refunds).filter(refund => refund.charge_id === id);

export const getRefundsForTimesheetByID = createSelector(
  [getRefunds, getTimesheetByID],
  (refunds, timesheet) => {
    if (!refunds || !timesheet) {
      return [];
    }
    return Object.values(refunds).filter(
      refund => refund.charge_id === timesheet.charge_id,
    );
  },
);

export const getRefundsForInvoiceTimesheetsByID = createSelector(
  [getRefunds, getTimesheetsForInvoiceByID],
  (refunds, timesheets) => {
    if (!refunds || !timesheets) {
      return {};
    }
    const timesheetRefunds = {};
    timesheets.forEach(timesheet => {
      const chargeRefunds = Object.values(refunds).filter(
        refund => refund.charge_id === timesheet.charge_id,
      );
      if (chargeRefunds.length) {
        timesheetRefunds[timesheet.id] = chargeRefunds;
      }
    });
    return timesheetRefunds;
  },
);
