import keyMirror from 'keymirror';

export default keyMirror({
  WORK_HISTORY_GET_BY_ID: null,
  WORK_HISTORY_GET_BY_ID_SUCCESS: null,
  WORK_HISTORY_GET_BY_ID_FAILURE: null,

  WORK_HISTORY_GET_FOR_CARER_BY_ID: null,
  WORK_HISTORY_GET_FOR_CARER_BY_ID_SUCCESS: null,
  WORK_HISTORY_GET_FOR_CARER_BY_ID_FAILURE: null,

  WORK_HISTORY_SEARCH: null,
  WORK_HISTORY_SEARCH_SUCCESS: null,
  WORK_HISTORY_SEARCH_FAILURE: null,

  WORK_HISTORY_ADD: null,
  WORK_HISTORY_ADD_SUCCESS: null,
  WORK_HISTORY_ADD_FAILURE: null,

  WORK_HISTORY_UPDATE: null,
  WORK_HISTORY_UPDATE_SUCCESS: null,
  WORK_HISTORY_UPDATE_FAILURE: null,

  WORK_HISTORY_DELETE: null,
  WORK_HISTORY_DELETE_SUCCESS: null,
  WORK_HISTORY_DELETE_FAILURE: null,

  WORK_HISTORY_SUBMIT: null,
  WORK_HISTORY_SUBMIT_SUCCESS: null,
  WORK_HISTORY_SUBMIT_FAILURE: null,
});