import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import InlineValidationError from 'components/forms/InlineValidationError';

const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputAffix = styled.div`
  pointer-events: none;
  position: absolute;
  top: 15px;
  ${props => props.prefix && 'left: 10px;'} ${props =>
    props.suffix && 'right: 10px;'} font-size: 18px;
`;

const InputHelp = styled.span`
  color: ${props => props.theme.colors.midGrey};
  display: inline-block;
`;

class InputWrapper extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    help: PropTypes.string,
    meta: PropTypes.shape().isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    help: null,
    prefix: null,
    suffix: null,
  };

  render() {
    const { className, help, children, meta, prefix, suffix } = this.props;
    const { error, touched, warning } = meta;

    return (
      <StyledInputWrapper className={className}>
        {prefix && <InputAffix prefix={prefix}>{prefix}</InputAffix>}
        {children}
        {help && <InputHelp>{help}</InputHelp>}
        {suffix && <InputAffix suffix={suffix}>{suffix}</InputAffix>}
        {touched &&
          ((error && <InlineValidationError>{error}</InlineValidationError>) ||
            (warning && (
              <InlineValidationError>{warning}</InlineValidationError>
            )))}
      </StyledInputWrapper>
    );
  }
}

export default InputWrapper;
