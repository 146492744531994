import styled from 'styled-components';

const StyledLabelTag = styled.span`
  background: ${props => props.theme.colors.red};
  color: white;
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  line-height: 16px;
  margin-left: 20px;
  padding: 5px 30px 5px 25px;
  position: relative;
  top: -2px;

  &:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 0, 0, 0);
    border-right-color: ${props => props.theme.colors.red};
    border-width: 13px;
    margin-top: -13px;
  }
`;

export default StyledLabelTag;
