import keyMirror from 'keymirror';

export default keyMirror({
  SCHEDULE_HOLD_CREATE: null,
  SCHEDULE_HOLD_CREATE_SUCCESS: null,
  SCHEDULE_HOLD_CREATE_FAILURE: null,

  SCHEDULE_HOLD_MODIFY: null,
  SCHEDULE_HOLD_MODIFY_SUCCESS: null,
  SCHEDULE_HOLD_MODIFY_FAILURE: null,

  SCHEDULE_HOLD_DELETE: null,
  SCHEDULE_HOLD_DELETE_SUCCESS: null,
  SCHEDULE_HOLD_DELETE_FAILURE: null,

  SCHEDULE_HOLD_GET_BY_ID: null,
  SCHEDULE_HOLD_GET_BY_ID_SUCCESS: null,
  SCHEDULE_HOLD_GET_BY_ID_FAILURE: null,

  SCHEDULE_HOLD_GET_BY_PARAMS: null,
  SCHEDULE_HOLD_GET_BY_PARAMS_SUCCESS: null,
  SCHEDULE_HOLD_GET_BY_PARAMS_FAILURE: null,
});
