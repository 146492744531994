import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import {
  isCarer,
  isCustomer,
  isAdmin,
  isAgent,
  isFranchiseMember,
  isRecruiter,
  getUser,
} from 'shared/selectors/accountSelectors';
import CarerHomeContainer from 'containers/carers/CarerHomeContainer';
import CustomerHomeContainer from 'containers/customers/CustomerHomeContainer';

class UserHomeContainer extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    isCarer: PropTypes.bool.isRequired,
    isCustomer: PropTypes.bool.isRequired,
    isFranchiseMember: PropTypes.bool.isRequired,
    isRecruiter: PropTypes.bool.isRequired,
    user: PropTypes.shape().isRequired,
  };

  render() {
    if (this.props.isCarer) {
      return <CarerHomeContainer />;
    }

    if (this.props.isCustomer) {
      return <CustomerHomeContainer />;
    }

    if (this.props.isAdmin || this.props.isRecruiter) {
      return <Redirect to="/admin/" />;
    }

    if (this.props.isAgent) {
      return <Redirect to="/admin/needs/new/" />;
    }

    if (this.props.isFranchiseMember) {
      return <Redirect to="/admin/needs/" />;
    }

    return <span />;
  }
}

function mapStateToProps(state) {
  return {
    isAdmin: isAdmin(state),
    isAgent: isAgent(state),
    isCarer: isCarer(state),
    isCustomer: isCustomer(state),
    isFranchiseMember: isFranchiseMember(state),
    isRecruiter: isRecruiter(state),
    user: getUser(state),
  };
}

export default connect(mapStateToProps)(UserHomeContainer);
