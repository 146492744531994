import CarerConstants from 'shared/constants/CarerConstants';
import { updateObject } from 'shared/reducers/reducerHelpers';

const initalState = {};

function updateCarer(state, action) {
  const data = action.data;
  const carer = data.user;
  carer.profile_details = data.profile;
  return updateObject(state, { [carer.id]: carer });
}

function updateCarers(state, action) {
  const carerObjs = action.data && action.data.objects;
  const updatedCarers = {};

  if (Array.isArray(carerObjs)) {
    carerObjs.forEach(carer => {
      if (!state[carer.user.id]) {
        updatedCarers[carer.user.id] = {
          profile_details: carer.profile,
          ...carer.user,
        };
      } else {
        updatedCarers[carer.user.id] = {
          profile_details: carer.profile,
          induction_slot: state[carer.user.id].induction_slot,
          ...carer.user,
        };
      }
    });
    return updateObject(state, updatedCarers);
  }

  return state;
}

function updateInfoInCarer(state, carerID, data) {
  return {
    ...state,
    [carerID]: {
      ...state[carerID],
      ...data,
    },
  };
}

function removeEmptyConflicts(conflicts) {
  const newConflicts = { ...conflicts };
  if (!newConflicts.conflicting_schedules.length) {
    delete newConflicts.conflicting_schedules;
  }

  Object.entries(newConflicts.conflicting_bids).forEach(([state, bidIDs]) => {
    if (!bidIDs.length) {
      delete newConflicts.conflicting_bids[state];
    }
  });

  if (!Object.values(newConflicts.conflicting_bids).length) {
    delete newConflicts.conflicting_bids;
  }

  return newConflicts;
}

function carers(state = initalState, action) {
  switch (action.type) {
    case CarerConstants.CARER_GET_BY_ID_SUCCESS:
      return updateCarer(state, action);

    case CarerConstants.CARER_SEARCH_SUCCESS:
    case CarerConstants.CARER_SEARCH_MORE_SUCCESS:
      return updateCarers(state, action);

    case CarerConstants.CARER_FIND_SUCCESS:
      return updateCarers(state, action);

    case CarerConstants.CARER_GET_INDUCTION_SLOT_SUCCESS:
      return updateInfoInCarer(state, action.carerID, {
        induction_slot: action.data,
      });

    case CarerConstants.CARER_GET_CONFLICTS_SUCCESS:
      const conflicts = removeEmptyConflicts({ ...action.data });
      return updateInfoInCarer(state, action.carerID, { conflicts });

    default:
      return state;
  }
}

export default carers;
