export const parseQueryString = rawQueryString => {
  if (!rawQueryString) {
    return null;
  }
  const queryString = rawQueryString.match(/\?(.*)/)[1];
  const queriesArray = queryString.split('&');
  const queryObject = {};
  queriesArray.forEach(query => {
    const queryArray = query.split('=');
    queryObject[queryArray[0]] = queryArray[1];
  });
  return queryObject;
};
