import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Field } from 'redux-form';
import Link from 'react-router-redux-dom-link';

import Module, {
  BorderedModuleSeparator,
  ModuleSeparator,
  ModuleSubheader,
} from 'components/global/layout/Module';
import { Container, Col } from 'react-grid-system';
import Button from 'components/buttons/Button';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import StandardInput from 'components/forms/StandardInput';
import StandardSelect from 'components/forms/StandardSelect';
import SmartRecruiterButton from 'components/global/SmartRecruiterButton';
import Definition from 'components/global/informationdisplay/Definition';
import { required, validGUID } from 'validators';

class AdminFranchise extends Component {
  static propTypes = {
    franchise: PropTypes.shape().isRequired,
    franchiseUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    initialNeedValues: PropTypes.shape(),
    onNeedSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialNeedValues: {},
  };

  render() {
    const {
      franchise,
      franchiseUsers,
      initialNeedValues,
    } = this.props;

    const location = `${franchise.town}, ${franchise.county}`

  	return (
      <Module>

        <Container>
            <ModuleSubheader noMargin>Franchise Information</ModuleSubheader>
            <Definition label="Franchise">
              <Link to={`/admin/franchises/${franchise.id}/`}>
                {franchise.name}
              </Link>
            </Definition>
            <Definition label="Number">{franchise.phone_number}</Definition>
            <Definition label="Location">{location}</Definition>
            <Definition label="Postcode">{franchise.postcode}</Definition>
        </Container>

        <BorderedModuleSeparator />
        <ModuleSeparator />

        <Form
          name="franchiseNeedForm"
          fields={['smart_recruiter_id', 'user_id', 'smart_recruiter_state']}
          selectFields={['smart_recruiter_id',]}
          values={initialNeedValues}
          component={({ currentValues, handleSubmit, pristine }) => (
            <form id="franchiseNeedForm">
              <Container>

                <ModuleSubheader noMargin>Franchise Fields</ModuleSubheader>

                <Fragment>
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardInput}
                        label="Smart Recruiter Job ID"
                        name="smart_recruiter_id"
                        type="text"
                        validate={[validGUID]}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      {currentValues.smart_recruiter_id && (
                        <SmartRecruiterButton
                          id={currentValues.smart_recruiter_id}
                          resource="job"
                        />
                      )}
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardInput}
                        label="Smart Recruiter Job Status"
                        name="smart_recruiter_state"
                        type="text"
                        disabled
                      />
                    </Col>
                  </FormRow>
                </Fragment>
                <Fragment>
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardSelect}
                        label="Franchise Contact"
                        name="user_id"
                        type="text"
                        validate={[required]}
                      >
                        <option value="">
                          Please Select
                        </option>
                        {franchiseUsers
                          .map(instance => (
                            <option key={instance.id} value={instance.id}>
                              {instance.email}
                            </option>
                          ))}
                        </Field>
                    </Col>
                  </FormRow>
                </Fragment>
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Button
                        onClick={handleSubmit(data =>
                          this.props.onNeedSubmit({ data }),
                        )}
                        disabled={pristine}
                        type="button"
                      >
                        Save
                      </Button>
                    </Col>
                  </FormRow>
              </Container>
            </form>
          )}
        />

      </Module>
  	);
  }

 }

export default AdminFranchise;