import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';

import { Container, Col } from 'components/grid';
import { required, validDate } from 'validators';
import { ModuleHeader } from 'components/global/layout/Module';
import { TaxonomySelect } from 'components/forms/ConvenienceSelects';
import FormRow from 'components/forms/FormRow';
import StandardInput from 'components/forms/StandardInput';
import StandardDateInput from 'components/forms/StandardDateInput';
import StandardSelect from 'components/forms/StandardSelect';
import StandardCheckbox from 'components/forms/StandardCheckbox';
import StandardTextarea from 'components/forms/StandardTextarea';
import Form from 'components/forms/Form';
import _ from 'lodash';

import Button from 'components/buttons/Button';

class CarerWorkHistoryForm extends Component {
  static propTypes = {
    editMode: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape(),
    onSubmit: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.shape({
      organization_country: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      work_history_employment_type: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    initialValues: null,
  };

  validateEndDate = (employmentEndDate, { employment_start_date }) => {
    const endDate = new Date(employmentEndDate);
    const startDate = new Date(employment_start_date);
    try {
      return endDate < startDate
        ? 'End date must be after start date'
        : undefined;
    } catch (e) {
      return 'Date error';
    }
  };

  render() {
    const { initialValues, taxonomiesByClassification } = this.props;
    // destroyOnUnmount must be set to false until we can find out why it is
    // unmounting when initialized under some circumstances

    return (
      <Form
        name="carerWorkHistoryForm"
        values={initialValues}
        enableReinitialize={false}
        fields={[
          'organization',
          'organization_town',
          'organization_country',
          'user_role',
          'employment_start_date',
          'employment_end_date',
          'duty_description',
          'reason_for_leaving',
          'salary',
          'current_employer',
          'employment_type',
        ]}
        selectFields={['employment_type', 'current_employer']}
        component={({ handleSubmit, pristine, currentValues }) => (
          <form id="carerWorkHistoryForm">

            <Container>
              <FormRow>
                <Col xs={12} md={6}>
                  <Field
                    component={TaxonomySelect}
                    name="employment_type"
                    type="text"
                    label="Work History Type"
                    taxonomy={taxonomiesByClassification.work_history_employment_type}
                    validate={[required]}
                  />
                </Col>
              </FormRow>
            </Container>

            {currentValues.employment_type && (
              <div>
                {currentValues.employment_type !== 'gapinemployment' &&
                 (
                  <div>
                    <ModuleHeader>
                      {
                        currentValues.employment_type === 'furthereducation'
                        ? 'Place of Education'
                        : 'Organisation Details'
                      }
                    </ModuleHeader>
                    <Container>
                      <FormRow>
                        <Col xs={12} md={6}>
                          <Field
                            component={StandardInput}
                            name="organization"
                            type="text"
                            label="Name"
                          />
                        </Col>
                        <Col xs={12} md={3}>
                          <Field
                            component={StandardInput}
                            name="organization_town"
                            type="text"
                            label="Town / City"
                            validate={[required]}
                          />
                        </Col>
                        <Col xs={12} md={3}>
                          <Field
                            component={StandardSelect}
                            name="organization_country"
                            type="text"
                            label="Country"
                            validate={[required]}
                          >
                            <option key="gbr" value="gbr">
                              United Kingdom
                            </option>
                            {_.sortBy(
                              taxonomiesByClassification.organization_country,
                              'value',
                            )
                              .filter(taxon => taxon.value !== 'gbr')
                              .map(taxon => (
                                <option key={taxon.taxon_id} value={taxon.taxon_id}>
                                  {taxon.value}
                                </option>
                              ))}
                          </Field>
                        </Col>
                      </FormRow>
                    </Container>
                  </div>
                )}

                <br/>

                <ModuleHeader>Details</ModuleHeader>
                <Container>
                  {
                    currentValues.employment_type !== 'gapinemployment' &&
                    currentValues.employment_type !== 'furthereducation' &&
                    (
                      <FormRow>
                        <Col xs={12} md={6}>
                          <Field
                            component={StandardInput}
                            name="user_role"
                            type="text"
                            label="Your Job Title"
                            validate={[required]}
                          />
                        </Col>
                        {!currentValues.employment_type.includes('unpaid') && (
                          <Col xs={12} md={6}>
                            <Field
                              component={StandardInput}
                              name="salary"
                              type="text"
                              label="Salary (e.g. £11/hour, £70/day, £25000/year)"
                            />
                          </Col>
                        )}
                      </FormRow>
                  )}
                  <FormRow>
                    <Col xs={12} sm={6} lg={3}>
                      <Field
                        component={StandardDateInput}
                        past
                        name="employment_start_date"
                        granularity="month"
                        type="text"
                        label="Start date (Month/Year)"
                        validate={[required, validDate]}
                      />
                    </Col>
                    {!currentValues.current_employer && (
                      <Col xs={12} sm={6} lg={3}>
                        <Field
                          component={StandardDateInput}
                          past
                          name="employment_end_date"
                          granularity="month"
                          type="text"
                          label="End date (Month/Year)"
                          validate={[required, validDate, this.validateEndDate]}
                        />
                      </Col>
                    )}
                    <Col xs={12} sm={6} lg={3}>
                      <Field
                        component={StandardCheckbox}
                        name="current_employer"
                        label="I Am Currently Doing This"
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Field
                        component={StandardTextarea}
                        name="duty_description"
                        type="text"
                        label={
                          `Description${
                            (
                              currentValues.employment_type === 'gapinemployment' &&
                              ''
                            ) ||
                            (
                              currentValues.employment_type === 'furthereducation' &&
                              ' (include details of course type and results)'
                            ) ||
                            ' (e.g. job duties)'
                          }`
                        }
                        validate={
                          currentValues.employment_type !== 'gapinemployment'
                          ? []
                          : [required]
                        }
                      />
                    </Col>
                    {
                      !currentValues.current_employer &&
                      currentValues.employment_type !== 'gapinemployment' &&
                      currentValues.employment_type !== 'furthereducation' && (
                        <Col xs={12} md={6}>
                          <Field
                            component={StandardTextarea}
                            name="reason_for_leaving"
                            type="text"
                            label="Reasons for Leaving"
                            validate={[required]}
                          />
                        </Col>
                    )}
                  </FormRow>
                </Container>

                <br/>

                <Container>
                  <FormRow>
                    <Col xs={12} md={6}>
                      <Button
                        onClick={handleSubmit(data =>
                          this.props.onSubmit({ data }),
                        )}
                        disabled={pristine}
                        type="button"
                      >
                        Submit
                      </Button>
                    </Col>
                  </FormRow>
                </Container>
              </div>
            )}
          </form>
        )}
      />
    );
  }
}

export default CarerWorkHistoryForm;
