// @flow
import React, { useState } from 'react';
import styled from 'styled-components';

import JobActionButtons from 'components/admin/needs/jobs/jobofferpanel/JobActionButtons';
import JobPreviewPortal from 'components/admin/needs/jobs/JobPreviewPortal';
import JobStatusView from 'components/admin/needs/jobs/jobofferpanel/JobStatusView';
import JobTimestampRefTitle from 'components/admin/needs/jobs/jobofferpanel/JobTimestampRefTitle';

import type { JobOfferType, ScheduleType } from 'shared/types';

const StyledPanelLayout = styled.li`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  border-right: 1px solid ${props => props.theme.colors.uiGrey};
  border-left: 1px solid ${props => props.theme.colors.uiGrey};

  &: nth-child(2n+1) {
    background: white;
  }

  &:first-child {
    border-radius: 7px 7px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 0px 7px 7px;
  }

  &:only-child {
    border-radius: 7px;
  }
`;

type Props = {
  jobOffer: JobOfferType,
  needID: string,
  schedule: ScheduleType,
  updateJobOffer: (string, Object) => void,
};

function JobOfferPanel({ jobOffer, needID, schedule, updateJobOffer }: Props) {
  const [displayJobPreview, toggleJobPreview] = useState(false);

  if (!schedule) {
    return null;
  }

  const panel =
    jobOffer.state === 'draft' ? (
      <StyledPanelLayout>
        <JobTimestampRefTitle jobOffer={jobOffer} />
        <JobActionButtons
          showJobPreview={() => toggleJobPreview(true)}
          updateJobOffer={updateJobOffer}
          jobOfferID={jobOffer.id}
        />
      </StyledPanelLayout>
    ) : (
      <StyledPanelLayout>
        <JobTimestampRefTitle jobOffer={jobOffer} />
        <JobStatusView jobOffer={jobOffer} needID={needID} />
      </StyledPanelLayout>
    );

  return (
    <>
      {panel}
      {displayJobPreview && (
        <JobPreviewPortal
          handlePreviewClose={() => toggleJobPreview(false)}
          jobOffer={jobOffer}
          schedule={schedule}
        />
      )}
    </>
  );
}

export default JobOfferPanel;
