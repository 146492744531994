import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Currency from 'components/modules/Currency';
import TimesheetTotalDiscount from 'components/timesheets/TimesheetTotalDiscount';

class TimesheetTotalCommission extends Component {
  static propTypes = {
    discounts: PropTypes.shape({
      carer: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        percent: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
      }),
      customer: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        percent: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
      }),
    }),
    isAdmin: PropTypes.bool,
    timesheetTotal: PropTypes.shape({
      application_total: PropTypes.number.isRequired,
      application_total_gross: PropTypes.number,
      application_total_net: PropTypes.number.isRequired,
      carer_total: PropTypes.number,
      carer_total_gross: PropTypes.number,
      carer_total_net: PropTypes.number,
      customer_total: PropTypes.number,
      customer_total_net: PropTypes.number,
      insurance_payment: PropTypes.number.isRequired,
      livein_days: PropTypes.number,
      minutes_total: PropTypes.number,
    }).isRequired,
    view: PropTypes.string.isRequired,
  };

  render() {
    return (
      <tbody>
        <tr>
          <td rowSpan="1">
            <strong>SuperCarers’ Commission</strong>
          </td>
          <td />
          <td className="number">
            {this.props.view !== 'customer' && 'Commission Fee'}
          </td>
          <td className="number">
            <strong>
              <Currency
                value={
                  this.props.view === 'customer'
                    ? this.props.timesheetTotal.application_total
                    : this.props.timesheetTotal.application_total_net
                }
              />
            </strong>
          </td>
        </tr>
        {this.props.discounts && this.props.discounts.customer && (
          <TimesheetTotalDiscount
            isAdmin={this.props.isAdmin}
            discount={this.props.discounts.customer}
          />
        )}
        {this.props.view !== 'customer' && (
          <tr>
            <td />
            <td />
            <td className="number">Insurance</td>
            <td className="number">
              <strong>
                <Currency value={this.props.timesheetTotal.insurance_payment} />
              </strong>
            </td>
          </tr>
        )}
      </tbody>
    );
  }
}

export default TimesheetTotalCommission;
