import BidConstants from 'shared/constants/BidConstants';
import CarerConstants from 'shared/constants/CarerConstants';
import CareNeedConstants from 'shared/constants/CareNeedConstants';
import { getBid } from '_actions/bidActions';
import { getJobOfferByID } from '_actions/jobOfferActions';
import {
  getCarerConflicts,
  getCarerProfileByCarerId,
  updateCarerProfile,
} from '_actions/carerActions';
import { searchTimesheets } from '_actions/timesheetActions';
import { takeLatest, put, take, select, all } from 'redux-saga/effects';
import { getUser, isCarer } from 'shared/selectors/accountSelectors';

export function* carerProfilePartialUpdate(action) {
  const { carerID, liveData, draftData } = action.payload;
  yield put(updateCarerProfile(carerID, liveData, false, false, true));
  const response = yield take([
    CarerConstants.CARER_UPDATE_PROFILE_SUCCESS,
    CarerConstants.CARER_UPDATE_PROFILE_FAILURE,
  ]);
  if (response.type === CarerConstants.CARER_UPDATE_PROFILE_SUCCESS) {
    yield put(updateCarerProfile(carerID, draftData, true, true, false));
  }
}

export function* carerProfilePartialUpdateListener() {
  yield takeLatest(
    CarerConstants.CARER_PARTIAL_UPDATE_PROFILE,
    carerProfilePartialUpdate,
  );
}

export function* adminUpdateCarerProfile(action) {
  const { carerID, data } = action.payload;
  yield put(
    updateCarerProfile(carerID, data, !action.approve, true, action.approve),
  );
  const updateResponse = yield take([
    CarerConstants.CARER_UPDATE_PROFILE_SUCCESS,
    CarerConstants.CARER_UPDATE_PROFILE_FAILURE,
  ]);
  if (updateResponse.type === CarerConstants.CARER_UPDATE_PROFILE_SUCCESS) {
    yield put({ type: CarerConstants.ADMIN_CARER_UPDATE_PROFILE_SUCCESS });
    yield put(getCarerProfileByCarerId(carerID, { live_status: 'draft' }));
  }
  const draftResponse = yield take([
    CarerConstants.CARER_GET_PROFILE_SUCCESS,
    CarerConstants.CARER_GET_PROFILE_FAILURE,
  ]);
  if (draftResponse.type === CarerConstants.CARER_GET_PROFILE_FAILURE) {
    yield put({
      type: CarerConstants.CLEAR_DRAFT_PROFILE,
      payload: { carerID },
    });
  }
}

export function* adminUpdateCarerProfileListener() {
  yield takeLatest(
    CarerConstants.ADMIN_CARER_UPDATE_PROFILE,
    adminUpdateCarerProfile,
  );
}

export function* carerAdhocTimesheetCreation() {
  const searchRequired = yield select(isCarer);
  if (searchRequired) {
    const user = yield select(getUser);
    yield put(
      searchTimesheets({ carer_id: user.id, per_page: 5, order_by: 'desc' }),
    );
  }
}

export function* carerAdhocTimesheetCreationListener() {
  yield takeLatest(
    CareNeedConstants.CARE_NEED_TIMESHEET_GENERATE_SUCCESS,
    carerAdhocTimesheetCreation,
  );
}

export function* conflictsWithBids(action) {
  yield put(getCarerConflicts(action.payload.carerID));
  const result = yield take([
    CarerConstants.CARER_GET_CONFLICTS_SUCCESS,
    CarerConstants.CARER_UPDATE_PROFILE_FAILURE,
  ]);

  if (result.type === CarerConstants.CARER_GET_CONFLICTS_SUCCESS) {
    const conflictingBids = Object.values(
      result.data.conflicting_bids,
    ).flatten();
    yield all(conflictingBids.map(bidID => put(getBid(bidID))));

    const results = yield all(
      Array(conflictingBids.length).fill(take(BidConstants.BID_GET_SUCCESS)),
    );

    yield all(results.map(bid => put(getJobOfferByID(bid.data.job_offer_id))));

    yield put({
      type: CarerConstants.CARER_GET_CONFLICTS_WITH_BIDS_SUCCESS,
    });
  } else {
    yield put({
      type: CarerConstants.CARER_GET_CONFLICTS_WITH_BIDS_FAILURE,
    });
  }
}

export function* conflictsWithBidsListener() {
  yield takeLatest(
    CarerConstants.CARER_GET_CONFLICTS_WITH_BIDS,
    conflictsWithBids,
  );
}
