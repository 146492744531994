// @flow
import React, { Component } from 'react';
// $FlowFixMe - cannot resolve the module - needs fixing
import { Field } from 'redux-form';
import styled from 'styled-components';

import { Container, Col } from 'components/grid';
import {
  maxChars,
  required,
  validEmail,
  validDate,
  requiredBoolean,
} from 'validators';
import { ModuleHeader } from 'components/global/layout/Module';
import FormRow from 'components/forms/FormRow';
import StandardInput from 'components/forms/StandardInput';
import StandardDateInput from 'components/forms/StandardDateInput';
import StandardSelect from 'components/forms/StandardSelect';
import StandardTextarea from 'components/forms/StandardTextarea';
import Form from 'components/forms/Form';

import Button from 'components/buttons/Button';
import StandardRadioGroup from 'components/forms/StandardRadioGroup';

import type {
  DefaultTestimonialType,
  TaxonomiesClassificationType,
  TestimonialType,
} from 'shared/types';

type Props = {
  initialValues: TestimonialType | DefaultTestimonialType,
  onSubmit: (data: Object, edit: boolean) => void,
  taxonomiesByClassification: TaxonomiesClassificationType,
  user_id: string,
};

// $FlowFixMe - styled components issue with Flow
const StyledTitle = styled.span`
  color: #979797;
`;

const TESTIMONIAL_CHAR_LIMIT = 250;

class CarerTestimonialsForm extends Component<Props> {
  render() {
    const {
      initialValues,
      onSubmit,
      taxonomiesByClassification,
      user_id,
    } = this.props;

    const testimonialMaxChars = maxChars(TESTIMONIAL_CHAR_LIMIT);

    return (
      <Form
        name="carerTestimonialsForm"
        enableReinitialize={false}
        fields={[
          'relationship',
          'start_date',
          'end_date',
          'firstname',
          'lastname',
          'featured',
          'email',
          'testimonial',
        ]}
        values={initialValues}
        component={({ handleSubmit, pristine, currentValues }) => (
          <form id="carerTestimonialsForm">
            <ModuleHeader>Testimonial</ModuleHeader>
            <Container>
              <FormRow>
                <Col xs={12} md={6}>
                  <Field
                    component={StandardSelect}
                    name="relationship"
                    label="Reviewer Type"
                    validate={[required]}
                  >
                    <option>Please select one...</option>
                    {taxonomiesByClassification.testimonial_relationship.map(
                      type => (
                        <option key={type.taxon_id} value={type.taxon_id}>
                          {type.value}
                        </option>
                      ),
                    )}
                  </Field>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Field
                    component={StandardDateInput}
                    past
                    name="start_date"
                    granularity="month"
                    type="text"
                    label="Start date (Month/Year)"
                    validate={[validDate]}
                  />
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Field
                    component={StandardDateInput}
                    past
                    name="end_date"
                    granularity="month"
                    type="text"
                    label="End date (Month/Year)"
                    validate={[validDate]}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col xs={12} md={6}>
                  <Field
                    component={StandardInput}
                    name="firstname"
                    type="text"
                    label="Reviewer first name"
                    validate={[required]}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Field
                    component={StandardInput}
                    name="lastname"
                    type="text"
                    label="Reviewer last name"
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col xs={12} md={6}>
                  <Field
                    component={StandardRadioGroup}
                    name="featured"
                    title={<StyledTitle>Featured on profile</StyledTitle>}
                    options={[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ]}
                    validate={[requiredBoolean]}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Field
                    component={StandardInput}
                    name="email"
                    type="text"
                    label="Reviewer email"
                    validate={[validEmail]}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col xs={12}>
                  <Field
                    component={StandardTextarea}
                    name="testimonial"
                    validate={[required, testimonialMaxChars]}
                    charCountLimit={TESTIMONIAL_CHAR_LIMIT}
                    label="Reviewer Testimonial"
                    placeholder="Enter the testimonial here..."
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col xs={12}>
                  <Button
                    onClick={handleSubmit(data =>
                      onSubmit({ ...data, user_id }, !!initialValues.id),
                    )}
                    disabled={pristine}
                    type="button"
                    className="alt"
                  >
                    Save
                  </Button>
                </Col>
              </FormRow>
            </Container>
          </form>
        )}
      />
    );
  }
}

export default CarerTestimonialsForm;
