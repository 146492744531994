import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

const StyledTimestampRefTitle = styled.div`
  width: 35%;
  min-width: 235px;
  margin: auto;
`;

const StyledJobRef = styled.div`
  font-weight: bold;
  display: inline;
`;

const StyledJobTitle = styled.div`
  display: inline;
`;

const StyledTimestamp = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.midGrey};
`;

const JobTimestampRefTitle = ({ jobOffer }) => (
  <StyledTimestampRefTitle>
    <StyledTimestamp>
      {jobOffer.posted_at
        ? `Posted: ${moment(jobOffer.posted_at).format(
            'D MMM YYYY [at] HH:MM',
          )}`
        : 'Not posted yet'}
    </StyledTimestamp>
    <div>
      <StyledJobRef>{`${jobOffer.reference}: `}</StyledJobRef>
      <StyledJobTitle>{jobOffer.title}</StyledJobTitle>
    </div>
  </StyledTimestampRefTitle>
);

export default JobTimestampRefTitle;
