// @flow
/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import React, { Component } from 'react';
import styled from 'styled-components';
import Link from 'react-router-redux-dom-link';

import FlexTable from 'components/global/layout/FlexTable';
import FlexTableRow from 'components/global/layout/FlexTableRow';
import FlexTableItem from 'components/global/layout/FlexTableItem';
import Module, {
  ModuleHeader,
  ModuleText,
} from 'components/global/layout/Module';
import StatusIndicator from 'components/global/StatusIndicator';

import { renderTaxon } from 'components/helpers/taxonomyHelpers';
import MarkCompliant from 'components/modules/MarkCompliant';

import type {
  CarerProfileType,
  TaxonomiesClassificationType,
} from 'shared/types';

type Props = {
  profile: CarerProfileType,
  taxonomiesByClassification: TaxonomiesClassificationType,
  updateCarerProfile: Function,
};

// $FlowFixMe - Needs a fix for styled components
const StyledHeaderRow = styled(FlexTableRow)`
  font-weight: bold;
`;

class AdminUserCompliance extends Component<Props> {

  overrideReferencesCompliant = value => {
    this.props.updateCarerProfile({ hi_references_mark_compliant: value });
  };

  overrideWorkHistoryCompliant = value => {
    this.props.updateCarerProfile({ hi_work_history_mark_compliant: value });
  };

  overrideCompliant = value => {
    this.props.updateCarerProfile({ hi_compliant_override: value });
  };

  render() {
    const { profile, taxonomiesByClassification } = this.props;

    const profile_data = profile.profile_data || {};

    return (
      <section>

        <Module>

          <ModuleHeader>Compliance</ModuleHeader>
          <ModuleText>
            <Link to={`/admin/carers/profile/${profile.user_id}/`}>
              Edit Profile
            </Link>
          </ModuleText>

          <FlexTable moduleStyling>

            <StyledHeaderRow>
              <FlexTableItem xs={6} md={4}>Country</FlexTableItem>
              <FlexTableItem xs={6} md={4}>Status</FlexTableItem>
              <FlexTableItem xs={0} md={4}>Interest?</FlexTableItem>
            </StyledHeaderRow>

            {taxonomiesByClassification.franchise_country.map((field, index) => (
              <FlexTableRow key={`country-${field.taxon_id}`}>
                <FlexTableItem xs={6} md={4}>{field.value}</FlexTableItem>
                <FlexTableItem xs={6} md={4}>
                  <StatusIndicator
                    key={`country-${field.taxon_id}`}
                    status={
                      profile.compliant_countries &&
                        profile.compliant_countries[field.taxon_id] === true ? 'compliant'
                      : profile.hi_compliant_override === true ? 'override'
                      : 'non-compliant'
                    }
                  />
                </FlexTableItem>
                <FlexTableItem xs={0} md={4}>
                  {profile.country_preference.includes(field.taxon_id) ? 'Yes' : 'No'}
                </FlexTableItem>
              </FlexTableRow>
            ))}

          </FlexTable>

          <FlexTableRow>
            <FlexTableItem xs={2} md={4}>
              <MarkCompliant
                markCompliant={this.overrideCompliant}
                compliant={profile.hi_compliant_override}
                message="Compliant"
              />
            </FlexTableItem>
            <FlexTableItem xs={0} md={4}/>
            <FlexTableItem xs={0} md={4}/>
          </FlexTableRow>

        </Module>

        <Module>
          <FlexTable moduleStyling>
            <StyledHeaderRow>
              <FlexTableItem xs={2} md={4}>Field</FlexTableItem>
              <FlexTableItem xs={2} md={4}>Status</FlexTableItem>
              <FlexTableItem xs={0} md={4}></FlexTableItem>
            </StyledHeaderRow>

            {taxonomiesByClassification.compliance_fields.map((field, index) => {

              let field_id = field.taxon_id;
              let status = "non-compliant";
              let action = "-";

              if (profile.hi_compliance_fields[field_id] === true) {
                status = "compliant";
              }

              switch (field_id) {
                case "right_to_work":
                  action = renderTaxon(
                    taxonomiesByClassification['right_to_work'],
                    (profile_data.right_to_work || {}).validation,
                  )
                  break;

                case "work_history":
                  if (profile.hi_work_history_mark_compliant) {
                      status = "override";
                  }
                  action = (
                    <MarkCompliant
                      markCompliant={this.overrideWorkHistoryCompliant}
                      compliant={profile.hi_work_history_mark_compliant}
                      message="Compliant"
                    />
                  );
                  break;

                case "references":
                  if (profile.hi_references_mark_compliant) {
                      status = "override";
                  }
                  action = (
                    <MarkCompliant
                      markCompliant={this.overrideReferencesCompliant}
                      compliant={profile.hi_references_mark_compliant}
                      message="Compliant"
                    />
                  );
                  break;

                default:
              }

              return (

                <FlexTableRow key={`compliance-${field_id}`}>

                  <FlexTableItem xs={2} md={4}>{field.value}</FlexTableItem>

                  <FlexTableItem xs={2} md={4}>
                    <StatusIndicator key={`indicator-${field_id}`} status={status}/>
                  </FlexTableItem>

                  <FlexTableItem xs={0} md={4}>{action}</FlexTableItem>

                </FlexTableRow>
              );
            })}
          </FlexTable>
        </Module>
      </section>
    );
  }

}

export default AdminUserCompliance;