import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { bindActionCreators } from 'redux';

import { logout } from '_actions/accountActions';
import { isLoggedIn } from 'shared/selectors/accountSelectors';

import LoginWrapper from 'components/login/LoginWrapper';

class LogoutRoute extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    if (this.props.isLoggedIn) {
      this.props.logout();
    }
  }

  render() {
    return (
      <LoginWrapper>
        <Route {...this.props} />
      </LoginWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: isLoggedIn(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutRoute);
