// @flow
import type { Action, State } from 'shared/types';

export function createReducer(
  initialState: {},
  handlers: ?{ [string]: ({}, Action) => {} },
) {
  return function reducer(state: {} = initialState, action: Action) {
    if (handlers && handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}

export function updateObject(currentObject: {}, newObject: {} = {}) {
  return Object.assign({}, currentObject, newObject);
}

export function addObject(state: {}, action: Object) {
  return {
    ...state,
    [action.data.id]: action.data,
  };
}

export function addObjects(state: {}, action: Object) {
  const resultsObject = action.data.results.reduce((accumulator, result) => {
    // this is much more performant than using spread operator
    // eslint-disable-next-line no-param-reassign
    accumulator[result.id] = result;
    return accumulator;
  }, {});
  return {
    ...state,
    ...resultsObject,
  };
}

export function deleteObject(state: {}, action: Object) {
  const newState = { ...state };
  delete newState[action.data.id];
  return newState;
}

export function deleteId(state: {}, action: Object) {
  const newState = { ...state };
  delete newState[action.id];
  return newState;
}

export function addNote(state: {}, action: Object) {
  const id = action.data.object_id;
  if (!state[id]) {
    return state;
  }

  const newState = { ...state };
  state[id].notes.unshift(action.data);

  return newState;
}

export function setLoadingState(
  state: State,
  type: string,
  bool: boolean,
): State {
  return {
    ...state,
    loading: {
      ...state.loading,
      [type]: bool,
    },
  };
}

export function addObjectSetLoadingState(
  state: State,
  action: Action,
  type: string,
  bool: boolean,
) {
  return addObject(setLoadingState(state, type, bool), action);
}

export function addObjectsSetLoadingState(
  state: State,
  action: Action,
  type: string,
  bool: boolean,
) {
  return addObjects(setLoadingState(state, type, bool), action);
}
