// @flow
import React from 'react';
import styled, { css } from 'styled-components';

import BasicFlexContainer from 'components/global/layout/BasicFlexContainer';
import Button from 'components/buttons/Button';
import ButtonWithSubscript from 'components/buttons/ButtonWithSubscript';

const StyledJobActionButtonsContainer = styled(BasicFlexContainer)`
  min-width: 235px;
  margin: 10px 0;
  width: ${({ width }) => width};
`;

const ButtonStyling = css`
  flex: 1 0 calc(33% - 10px);
  margin 0 5px;
`;

const StyledJobActionButton = styled(Button)`
  ${ButtonStyling}

  height: 57px;
`;

const StyledJobActionButtonWithSubscript = styled(ButtonWithSubscript)`
  ${ButtonStyling}
`;

type Props = {
  jobOfferID: string,
  updateJobOffer: (string, Object) => void,
  showCloseOption?: boolean,
  showJobPreview?: ?(boolean) => void,
  showSkipMatch?: boolean,
  width?: string,
};

function JobActionButtons({
  jobOfferID,
  updateJobOffer,
  showCloseOption,
  showJobPreview,
  showSkipMatch,
  width = '35%',
}: Props) {
  const updateJob = state => {
    updateJobOffer(jobOfferID, { state });
  };

  return (
    <StyledJobActionButtonsContainer width={width} justify="space-between">
      {showJobPreview && (
        <StyledJobActionButton
          type="button"
          name="preview"
          className="alt"
          onClick={showJobPreview}
          id="previewJobButton"
        >
          Preview job
        </StyledJobActionButton>
      )}
      <StyledJobActionButton
        type="button"
        name="postJob"
        id="postJobButton"
        onClick={() => updateJob('pending')}
      >
        Post job
      </StyledJobActionButton>
      {showSkipMatch && (
        <StyledJobActionButtonWithSubscript
          onClick={() => updateJob('open')}
          id="skipSendJob"
          className="alt"
          subscript="This will skip carer matching"
        >
          Open job
        </StyledJobActionButtonWithSubscript>
      )}
      {showCloseOption && (
        <StyledJobActionButton
          type="button"
          name="closeJob"
          id="closeJobButton"
          onClick={() => updateJob('closed')}
          className="danger"
        >
          Close Job
        </StyledJobActionButton>
      )}
    </StyledJobActionButtonsContainer>
  );
}

export default JobActionButtons;
