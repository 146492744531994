import PropTypes from 'prop-types';
import React from 'react';

const VerifiedIcon = ({ className, width, height }) => (
  <div className={`icon ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <g
        id="verified-icon"
        stroke="none"
        strokeWidth="1"
        fill="transparent"
        fillRule="evenodd"
      >
        <g transform="translate(-25.000000, -247.000000)">
          <g transform="translate(25.000000, 245.000000)">
            <g transform="translate(0.695143, 2.000000)">
              <circle stroke="currentColor" cx="9" cy="9.49227773" r="8.5" />
              <path
                d="M13.09725,6.03129568 L7.31060065,11.7661074 L4.90263312,9.40612035 C4.68119805,9.18626321 4.32119805,9.18626321 4.09952922,9.40612035 C3.87739286,9.62615282 3.87739286,9.98206191 4.09952922,10.2014515 L6.91132792,12.9568541 C7.14193831,13.1600554 7.48662662,13.1824385 7.71449026,12.9568541 L13.9008214,6.82668529 C14.1224903,6.60729568 14.1224903,6.25068529 13.9008214,6.03129568 C13.6786851,5.81196451 13.3193864,5.81196451 13.09725,6.03129568 Z"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

VerifiedIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

VerifiedIcon.defaultProps = {
  className: '',
  height: '18px',
  width: '18px',
};

export default VerifiedIcon;
