import PropTypes from 'prop-types';
import React, { Component } from 'react';

import InputWrapper from 'components/forms/InputWrapper';

class StandardTextSpan extends Component {
  static propTypes = {
    text: PropTypes.string,
    help: PropTypes.node,
    input: PropTypes.shape().isRequired,
    meta: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    text: '',
  };

  render() {
    const { help, input, meta, text } = this.props;
    const { touched, error } = meta;

    return (
      <InputWrapper {...{ help, meta }}>
        <span
          className={touched && error ? 'error' : ''}
          {...input}
        >
          {text}
        </span>
      </InputWrapper>
    );
  }
}

export default StandardTextSpan;