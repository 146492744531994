import InvoiceConstants from 'shared/constants/InvoiceConstants';
import TimesheetConstants from 'shared/constants/TimesheetConstants';
import { updateObject, setLoadingState } from 'shared/reducers/reducerHelpers';

const initalState = {
  loading: {
    invoices: false,
  },
};

function addInvoice(state, action) {
  const invoice = updateObject(state, { [action.data.id]: action.data });
  return invoice;
}

function invoices(state = initalState, action) {
  const updatedInvoices = {};

  switch (action.type) {
    case InvoiceConstants.INVOICE_SEARCH:
      return setLoadingState(state, 'invoices', true);

    case InvoiceConstants.INVOICE_GET_BY_ID_SUCCESS:
      return addInvoice(state, action);

    case InvoiceConstants.INVOICE_PAY_SUCCESS:
      return addInvoice(state, action);

    case InvoiceConstants.INVOICE_SEARCH_SUCCESS:
      action.data.results.forEach(invoice => {
        updatedInvoices[invoice.id] = invoice;
      });
      return setLoadingState(
        updateObject(state, updatedInvoices),
        'invoices',
        false,
      );

    case InvoiceConstants.INVOICE_VOID_SUCCESS:
      return addInvoice(state, action);

    case TimesheetConstants.TIMESHEET_REASSIGN_SUCCESS:
      return updateObject(state, {
        [action.data.invoice.id]: action.data.invoice,
      });

    case InvoiceConstants.INVOICE_SEARCH_FAILURE:
      return setLoadingState(state, 'invoices', false);

    default:
      return state;
  }
}

export default invoices;
