import React, { PureComponent } from 'react';
import { PortalWithState } from 'react-portal';

import Modal from 'components/modules/Modal';
import JobSummaryContainer from 'containers/admin/needs/jobs/JobSummaryContainer';

class JobPreviewPortal extends PureComponent {
  render() {
    const { bid, handlePreviewClose, jobOffer, schedule } = this.props;

    return (
      // closeOnOutsideClick cause conditional compoenent render to fail
      // <PortalWithState closeOnOutsideClick closeOnEsc defaultOpen>
      <PortalWithState closeOnEsc defaultOpen onClose={handlePreviewClose}>
        {({ closePortal, portal }) =>
          portal(
            <Modal
              closePortal={closePortal}
              key="confirmation-modal"
              id="jobPreview"
            >
              <JobSummaryContainer
                bid={bid}
                jobOffer={jobOffer}
                schedule={schedule}
                preview
                closePortal={closePortal}
              />
            </Modal>,
          )
        }
      </PortalWithState>
    );
  }
}

export default JobPreviewPortal;
