import NoteConstants from 'shared/constants/NoteConstants';

const initalState = {};

function notes(state = initalState, action) {
  const newState = { ...state };
  switch (action.type) {
    case NoteConstants.NOTE_ADD_FOR_OBJECT_SUCCESS:
      if (newState[action.data.object_id]) {
        newState[action.data.object_id] = [
          action.data,
          ...newState[action.data.object_id],
        ];
      } else {
        newState[action.data.object_id] = [action.data];
      }
      return newState;
    case NoteConstants.NOTE_UPDATE_SUCCESS:
      if (newState[action.data.object_id]) {
        const newList = [...newState[action.data.object_id]];
        const existingIndex = newList.findIndex(
          note => note.id === action.data.id,
        );
        if (existingIndex === -1) {
          newList.unshift(action.data);
        } else {
          newList[existingIndex] = action.data;
        }
        newState[action.data.object_id] = newList;
      } else {
        newState[action.data.object_id] = [action.data];
      }
      return newState;

    case NoteConstants.NOTE_DELETE_SUCCESS:
      if (newState[action.data.object_id]) {
        newState[action.data.object_id] = newState[
          action.data.object_id
        ].filter(note => note.id !== action.data.id);
      }
      return newState;

    case NoteConstants.NOTES_GET_FOR_OBJECT_SUCCESS:
      action.data.results.forEach(note => {
        const userNotesInState = newState[note.object_id];
        if (
          userNotesInState &&
          userNotesInState.find(noteInState => noteInState.id === note.id)
        ) {
          return null;
        }
        if (userNotesInState) {
          newState[note.object_id].push(note);
        } else {
          newState[note.object_id] = [note];
        }
      });
      return newState;

    default:
      return state;
  }
}

export default notes;
