// @flow
/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import React from 'react';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import StatusIndicator from 'components/global/StatusIndicator';
import BasicFlexContainer from 'components/global/layout/BasicFlexContainer';
import StyledSelect from 'components/forms/StyledSelect';
import { TableBidCarerImage } from 'components/bids/BidCarerImage';
import ProfilePDFLink from 'components/carers/profile/ProfilePDFLink';

import {
  activeBid,
  selectBidStates,
} from 'components/helpers/bidHelpers';

import type {
  BidType,
  JobOfferType,
  ScheduleType,
  TaxonomyType,
} from 'shared/types';
import Definition from '../global/informationdisplay/Definition';

const StyledDetail = styled.div`
  margin-right: 10px;
`;

const StyledDescription = styled.div`
  font-weight: normal;
`;

const StyledSelectWithMinWidth = styled(StyledSelect)`
  width: 190px;
  @media (max-width: ${props => props.theme.screen.small}px) {
    margin-top: 10px;
  }
`;

const StyledName = styled.span`
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
`;

type Props = {
  addCarerToTeam: (string, BidType) => void,
  bid: BidType,
  jobOffer: JobOfferType,
  notifyBid: string => Object,
  proposeCarerBid: BidType => void,
  rejectionReasons: TaxonomyType,
  schedule: ScheduleType,
  updateBid: (string, Object) => Object,
  isAdmin: bool,
};

const AdminBidRow = ({
  bid,
  jobOffer,
  updateBid,
  isAdmin,
}: Props) => {
  const updateBidState = (bid, state, updateBid) => {
    const confirmation = confirm('Are you sure you want to update this bid?');
    const newBid = { state };
    if (confirmation) {
      updateBid(bid.id, newBid);
      return true;
    }
    return false;
  };

  return (
    <>
      <BasicFlexContainer
        wrap="wrap"
        align="center"
        padding="10px"
        margin="10px 0 0 0"
        justify="space-between"
      >
        <Link to={`/carers/${bid.carer_id}`} target="_blank">
          <TableBidCarerImage image={bid.carer_image} carer_display_name={bid.carer_display_name} className="avatar" />
          <StyledName>{bid.carer_name}</StyledName>
        </Link>

        {bid.carer_id
          && (isAdmin
            || !['new', 'carer_withdrawn', 'carer_declined'].includes(bid.state)
          ) &&
        (
          <StyledSelectWithMinWidth
            onChange={e => updateBidState(bid, e.target.value, updateBid)}
            defaultValue={bid.state}
          >
            {Object.entries(selectBidStates()).map(
              ([key, value]: [string, any]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ),
            )}
          </StyledSelectWithMinWidth>
        )}

        {bid.carer_id
          && (isAdmin
            || ['applied', 'contacted', 'proposed', 'accepted'].includes(bid.state)
          ) &&
        (
            <ProfilePDFLink carerID={bid.carer_id} />
        )}
      </BasicFlexContainer>
      <BasicFlexContainer wrap="wrap" align="center" padding="10px">
          <StatusIndicator
            status='Unable to confirm compliance.'
          />
      </BasicFlexContainer>

      {activeBid(bid.state) && (
        <>
          <BasicFlexContainer wrap="wrap" align="center" padding="10px">
            {bid.distance && (
              <StyledDetail>
                Distance: {`${bid.distance.toFixed(0).toString()} miles`}
              </StyledDetail>
            )}
            {bid.carer_id && bid.state !== 'new' && (
              <StyledDetail>
                Email:{' '}
                  <a href={`mailto:${bid.carer_email}`}>{bid.carer_email}</a>
              </StyledDetail>
            )}
            {bid.carer_id && bid.state !== 'new' && (
              <StyledDetail>
                Phone:{' '}
                  <a href={`tel:${bid.carer_phone_number}`}>{bid.carer_phone_number}</a>
              </StyledDetail>
            )}
          </BasicFlexContainer>

          {bid.description && (
            <BasicFlexContainer wrap="wrap" align="center" padding="10px">
              <Definition label="Message From CP">
                <StyledDescription>
                  {bid.description}
                </StyledDescription>
              </Definition>
            </BasicFlexContainer>
          )}
        </>
      )}
    </>
  );
};

export default AdminBidRow;
