import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import styled from 'styled-components';
import ReactPaginator from 'react-js-pagination';
import { withRouter } from 'react-router';
import { Visible, Hidden } from 'components/grid';

const borderRadius = 5;

const StyledDiv = styled.div`
  text-align: center;
  > .paginator-container {
    @media (max-width: ${props => props.theme.screen.small - 1}px) {
      display: flex;
      justify-content: center;
      padding: 0 6px 0;
    }
  }

  .item-container {
    display: none;
    background-color: white;
    border: 1px solid ${props => props.theme.colors.midLightGrey};

    &.disabled {
      .item-link-text {
        color: ${props => props.theme.colors.midGrey};
      }
    }

    &:last-child {
      margin-left: 5px;
      border-radius: ${borderRadius}px;
    }
    &:first-child {
      margin-right: 5px;
      border-radius: ${borderRadius}px;
    }
    &:nth-child(2) {
      border-radius: ${borderRadius}px 0 0 ${borderRadius}px;
    }
    &:nth-last-child(2) {
      border-radius: 0 ${borderRadius}px ${borderRadius}px 0;
    }

    @media (max-width: ${props => props.theme.screen.small - 1}px) {
      &:first-child,
      &:last-child,
      &:nth-child(2),
      &:nth-last-child(2) {
        display: inline-block;
      }
      &:nth-child(2) {
        margin-right: 5px;
        position: relative;
        &:after {
          position: absolute;
          top: 5px;
          left: 6px;
          content: '❮';
          color: ${props => props.theme.colors.hiAccentBlue};
        }
      }
      &:nth-last-child(2) {
        position: relative;
        &:after {
          position: absolute;
          top: 5px;
          right: 6px;
          content: '❯';
          color: ${props => props.theme.colors.hiAccentBlue};
        }
      }
      &:nth-last-child(2) {
        flex: 1;
        text-align: center;
        &:after {
          right: calc(50% - 6px);
        }
      }
      &:nth-child(2) {
        flex: 1;
        text-align: center;
        &:after {
          left: calc(50% - 6px);
        }
      }
      &:last-child,
      &:first-child {
        flex-basis: 100px;
      }
    }

    @media (min-width: ${props => props.theme.screen.small}px) {
      display: inline-block;
      &:not(:first-child):not(:last-child):nth-child(n + 3) {
        border-left: none;
      }
      &.active {
        background-color: ${props => props.theme.colors.hiAccentBlue};
        > a {
          color: white;
        }
      }
    }
  }

  .item-link-text {
    text-decoration: none;
    color: ${props => props.theme.colors.hiAccentBlue};
    padding: 5px 0;

    @media (min-width: ${props => props.theme.screen.small}px) {
      padding: 5px 15px;
    }
  }
`;

class Paginator extends Component {
  handlePageChange = pageNumber => {
    let path;
    let query = {};

    if (this.props.path) {
      path = this.props.path;
    } else if (this.props.location) {
      query = Object.assign({}, this.props.location.query);
      path = this.props.location.pathname;
    } else {
      path = '/';
    }
    const url = `${path}?${qs.stringify({ ...query, page: pageNumber })}`;
    this.props.history.push(url);
  };

  render() {
    const { currentPage, totalPages, perPage } = this.props;

    if (totalPages > 1) {
      return (
        <StyledDiv>
          Page {currentPage} of {totalPages}
          <Hidden xs sm>
            <ReactPaginator
              firstPageText="First"
              lastPageText="Last"
              itemClass="item-container"
              innerClass="paginator-container"
              linkClass="item-link-text"
              activePage={currentPage}
              itemsCountPerPage={perPage}
              totalItemsCount={perPage * totalPages}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </Hidden>
          <Visible xs sm>
            <ReactPaginator
              firstPageText="First"
              lastPageText="Last"
              nextPageText=""
              prevPageText=""
              itemClass="item-container"
              innerClass="paginator-container"
              linkClass="item-link-text"
              activePage={currentPage}
              itemsCountPerPage={perPage}
              totalItemsCount={perPage * totalPages}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </Visible>
        </StyledDiv>
      );
    } else {
      return '';
    }
  }
}

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  perPage: PropTypes.number,
  path: PropTypes.string,
  location: PropTypes.shape().isRequired,
};

Paginator.defaultProps = {
  perPage: 20,
  path: '',
};

export default withRouter(Paginator);
