import ApiConstants from 'shared/constants/ApiConstants';
import DiscountConstants from 'shared/constants/DiscountConstants';

export function getDiscountById(discountId) {
  return {
    endpoint: `/discount/${discountId}/`,
    method: 'get',
    onError: { type: DiscountConstants.DISCOUNT_GET_BY_ID_FAILURE },
    onStart: { type: DiscountConstants.DISCOUNT_GET_BY_ID },
    onSuccess: { type: DiscountConstants.DISCOUNT_GET_BY_ID_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function searchDiscounts(params, page) {
  const query = Object.assign({ page: page || 1, order_by: 'desc' }, params);
  return {
    endpoint: '/discount/find/',
    method: 'get',
    onError: { type: DiscountConstants.DISCOUNT_SEARCH_FAILURE },
    onStart: { type: DiscountConstants.DISCOUNT_SEARCH },
    onSuccess: { type: DiscountConstants.DISCOUNT_SEARCH_SUCCESS },
    payload: query,
    type: ApiConstants.API_REQUEST,
  };
}

export function createDiscount(data) {
  return {
    endpoint: '/discount/',
    method: 'post',
    onError: {
      type: DiscountConstants.DISCOUNT_CREATE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Failed to create discount',
        dataSent: data,
      },
    },
    onStart: { type: DiscountConstants.DISCOUNT_CREATE },
    onSuccess: {
      type: DiscountConstants.DISCOUNT_CREATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Discount created successfully',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function createDiscountAndRefresh(data, params) {
  return {
    onUpdate: createDiscount(data),
    onRefresh: searchDiscounts(params),
    type: ApiConstants.API_REQUEST_AND_REFRESH,
  };
}

export function updateDiscount(discountId, data) {
  return {
    endpoint: `/discount/${discountId}/`,
    method: 'put',
    onError: {
      type: DiscountConstants.DISCOUNT_UPDATE_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Failed to update discount',
        dataSent: data,
      },
    },
    onStart: { type: DiscountConstants.DISCOUNT_UPDATE },
    onSuccess: {
      type: DiscountConstants.DISCOUNT_UPDATE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Discount updated successfully',
      },
    },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function updateDiscountAndRefresh(discountId, data, params) {
  return {
    onUpdate: updateDiscount(discountId, data),
    onRefresh: searchDiscounts(params),
    type: ApiConstants.API_REQUEST_AND_REFRESH,
  };
}

export function assignDiscount(userId, discountIdentifier) {
  return {
    endpoint: `/discount/${discountIdentifier}/assign/${userId}/`,
    method: 'put',
    onError: {
      type: DiscountConstants.DISCOUNT_ASSIGN_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to assign discount',
      },
    },
    onStart: { type: DiscountConstants.DISCOUNT_ASSIGN },
    onSuccess: { type: DiscountConstants.DISCOUNT_ASSIGN_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function assignDiscountAndRefresh(userId, discountIdentifier, params) {
  return {
    onUpdate: assignDiscount(userId, discountIdentifier),
    onRefresh: searchDiscounts(params),
    type: ApiConstants.API_REQUEST_AND_REFRESH,
  };
}

export function unassignDiscount(userId, discountIdentifier) {
  return {
    endpoint: `/discount/${discountIdentifier}/unassign/${userId}/`,
    method: 'put',
    onError: {
      type: DiscountConstants.DISCOUNT_UNASSIGN_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to unassign discount',
      },
    },
    onStart: { type: DiscountConstants.DISCOUNT_UNASSIGN },
    onSuccess: { type: DiscountConstants.DISCOUNT_UNASSIGN_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function unassignDiscountAndRefresh(userId, discountIdentifier, params) {
  return {
    onUpdate: unassignDiscount(userId, discountIdentifier),
    onRefresh: searchDiscounts(params),
    type: ApiConstants.API_REQUEST_AND_REFRESH,
  };
}
