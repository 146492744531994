/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  notifyCustomerOfCharge,
  payCharge,
  updateCharge,
} from '_actions/chargeActions';
import {
  createPaymentMethod,
  getUserPaymentMethods,
} from '_actions/paymentActions';
import { createRefund, searchRefunds } from '_actions/refundActions';
import { getUserById } from '_actions/userActions';
import CareNeedActions from 'actions/CareNeedActions';
import CareTeamActions from 'actions/CareTeamActions';

import ChargeActions from 'actions/ChargeActions';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import AdminEditCharge from 'components/admin/AdminEditCharge';
import { getCarers } from 'shared/selectors/carerSelectors';

import { getChargeByID } from 'shared/selectors/chargeSelectors';
import { getTeamsByChargeID } from 'shared/selectors/needSelectors';
import { getPaymentMethodsByChargeID } from 'shared/selectors/paymentSelectors';
import { getRefundsByChargeID } from 'shared/selectors/refundSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';
import { getUserByChargeID } from 'shared/selectors/userSelectors';

class AdminEditChargeContainer extends Component {
  static propTypes = {
    carers: PropTypes.shape(),
    charge: PropTypes.shape(),
    consumer: PropTypes.shape(),
    teams: PropTypes.arrayOf(PropTypes.shape()),
    match: PropTypes.shape({
      params: PropTypes.shape({
        chargeID: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    paymentMethods: PropTypes.arrayOf(PropTypes.shape()),
    refunds: PropTypes.arrayOf(PropTypes.shape()),
    createPaymentMethod: PropTypes.func.isRequired,
    getUserById: PropTypes.func.isRequired,
    getUserPaymentMethods: PropTypes.func.isRequired,
    payCharge: PropTypes.func.isRequired,
    createRefund: PropTypes.func.isRequired,
    searchRefunds: PropTypes.func.isRequired,
    updateCharge: PropTypes.func.isRequired,
    taxonomiesByClassification: PropTypes.object,
  };

  static defaultProps = {
    carers: null,
    charge: null,
    consumer: null,
    teams: [],
    needs: null,
    paymentMethods: null,
    refunds: {},
    taxonomiesByClassification: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['shift_type'];
  }

  state = {};

  UNSAFE_componentWillMount() {
    if (this.props.match.params.chargeID) {
      ChargeActions.getChargeById(this.props.match.params.chargeID).then(
        body => {
          this.props.getUserById(body.data.consumer_id);
          // TODO: Create saga for needs with team/schedule
          CareNeedActions.getNeedsByUserId(body.data.consumer_id).then(
            body2 => {
              const needs = body2.data || [];
              needs.forEach(need => {
                CareTeamActions.getTeamByNeed(need.id);
              });
            },
          );
          this.props.getUserPaymentMethods(body.data.consumer_id);
        },
      );
    }
    this.props.searchRefunds({
      order_by: 'desc',
      charge_id: this.props.match.params.chargeID,
    });
  }

  createRefund = ({ amount, fee_amount, description, preset }) => {
    this.props.createRefund(
      this.props.match.params.chargeID,
      amount,
      fee_amount,
      description,
      preset,
    );
  };

  payCharge = data => {
    this.props.payCharge(this.props.charge.id, data);
  };

  updateCharge = charge => {
    this.props.updateCharge(this.props.charge.id, charge);
  };

  voidCharge = () => {
    let confirmed = false;
    let newVoidValue = this.props.charge.void;

    if (this.props.charge.void === true) {
      confirmed = confirm('Are you sure you want to un-void this charge?');
      newVoidValue = false;
    } else if (this.props.charge.void === false) {
      confirmed = confirm('Are you sure you want to void this charge?');
      newVoidValue = true;
    }

    if (confirmed) {
      this.props.updateCharge(this.props.charge.id, { void: newVoidValue });
    }
  };

  render() {
    let areCarersLoaded = true;
    let teamCarers = [];
    let carerIDs = [];

    if (this.props.teams && this.props.teams.length) {
      areCarersLoaded = false;
      carerIDs = this.props.teams.map(team => team.team);
      carerIDs = [].concat(...carerIDs);
      teamCarers = carerIDs.map(carerID => this.props.carers[carerID]);
      areCarersLoaded = teamCarers.every(carer => carer);
    }

    if (
      !this.props.charge ||
      !this.props.consumer ||
      !areCarersLoaded ||
      !Object.keys(this.props.consumer).length ||
      !this.props.taxonomiesByClassification
    ) {
      return <LoadingPlaceholder />;
    }
    return (
      <AdminEditCharge
        carers={teamCarers}
        charge={this.props.charge}
        consumer={this.props.consumer}
        notified={this.state.notified}
        paymentMethods={this.props.paymentMethods}
        refunds={this.props.refunds}
        createPaymentMethod={this.props.createPaymentMethod}
        notifyCustomer={this.notifyCustomer}
        payCharge={this.payCharge}
        updateCharge={this.updateCharge}
        createRefund={this.createRefund}
        taxonomiesByClassification={this.props.taxonomiesByClassification}
        voidCharge={this.voidCharge}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  carers: getCarers(state),
  charge: getChargeByID(state, props.match.params.chargeID),
  consumer: getUserByChargeID(state, props.match.params.chargeID),
  paymentMethods: getPaymentMethodsByChargeID(
    state,
    props.match.params.chargeID,
  ),
  refunds: getRefundsByChargeID(state, props.match.params.chargeID),
  teams: getTeamsByChargeID(state, props.match.params.chargeID),
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    AdminEditChargeContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createPaymentMethod,
      getUserById,
      getUserPaymentMethods,
      notifyCustomerOfCharge,
      payCharge,
      searchRefunds,
      updateCharge,
      createRefund,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminEditChargeContainer);
