import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Module from 'components/global/layout/Module';
import CarerWorkHistoryFormContainer from 'containers/carers/CarerWorkHistoryFormContainer';

class CarerWorkHistoryEdit extends Component {
  static propTypes = {
    initialValues: PropTypes.shape(),
    onSubmit: PropTypes.func.isRequired,
    ready: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    initialValues: null,
  };

  render() {
    return (
      <Module>
        <CarerWorkHistoryFormContainer
          onSubmit={this.props.onSubmit}
          initialValues={this.props.initialValues}
          ready={this.props.ready}
          editMode
        />
      </Module>
    );
  }
}

export default CarerWorkHistoryEdit;
