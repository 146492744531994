import currency from 'currency.js';

export const currencyFormat = value =>
  currency(value, { formatWithSymbol: true, symbol: '£' })
    .divide(100) // convert from pence
    .format();

// Round customer rate to the nearest 5 pence
export const calculateCustomerRate = (carerRate, commission) =>
  Math.round((carerRate * (1 + commission)) / 5) * 5; // eslint-disable-line no-mixed-operators

export const convertCurrencyStringToPence = string => {
  const removeSymbolAndFixDecimals = parseFloat(
    string.replace(/[^0-9.-]+/g, ''),
    10,
  ).toFixed(2);
  const removeDecimalPoint = removeSymbolAndFixDecimals.replace(/[^0-9]+/g, '');
  return parseInt(removeDecimalPoint, 10);
};

export const convertObjectWithPenceValuesToCurrencyString = (
  valuesObject,
  criteriaArray = false,
) => {
  if (!valuesObject) {
    return null;
  }
  const currencyObject = {};
  Object.entries(valuesObject).forEach(([key, value]) => {
    if (!criteriaArray || criteriaArray.includes(key)) {
      currencyObject[key] = currencyFormat(value);
    }
  });
  return currencyObject;
};

export const convertObjectCurrencyStringsToPenceInt = (
  currencyObject,
  criteriaArray = false,
) => {
  if (!currencyObject) {
    return null;
  }
  const valuesObject = {};
  Object.entries(currencyObject).forEach(([key, value]) => {
    if (!criteriaArray || criteriaArray.includes(key)) {
      valuesObject[key] = convertCurrencyStringToPence(value);
    }
  });
  return valuesObject;
};
