import PropTypes from 'prop-types';

const DiscountPropType = PropTypes.shape({
  id: PropTypes.string,
  created_at: PropTypes.string,
  weeks_valid: PropTypes.number,
  end_date: PropTypes.string,
  percent: PropTypes.number,
  slug: PropTypes.string,
  start_date: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.string),
  void: PropTypes.bool,
});

export default DiscountPropType;
