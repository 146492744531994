import ApiConstants from 'shared/constants/ApiConstants';
import UserConstants from 'shared/constants/UserConstants';

export function createUser(data, redirect = null) {
  return {
    endpoint: '/user/account/',
    method: 'post',
    onError: { type: UserConstants.USER_CREATE_USER_FAILURE },
    onStart: { type: UserConstants.USER_CREATE_USER },
    onSuccess: { type: UserConstants.USER_CREATE_USER_SUCCESS },
    payload: data,
    redirect: !!redirect ? redirect : response => `/admin/users/${response.data.id}/`,
    type: ApiConstants.API_REQUEST,
  };
}

export function getUserById(userId) {
  return {
    endpoint: `/user/${userId}/`,
    method: 'get',
    onError: { type: UserConstants.USER_GET_BY_ID_FAILURE },
    onStart: { type: UserConstants.USER_GET_BY_ID },
    onSuccess: { type: UserConstants.USER_GET_BY_ID_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function searchUsers(params) {
  return {
    endpoint: '/search/people/',
    method: 'get',
    onError: { type: UserConstants.USER_SEARCH_FAILURE },
    onStart: { type: UserConstants.USER_SEARCH },
    onSuccess: { type: UserConstants.USER_SEARCH_SUCCESS },
    payload: params,
    type: ApiConstants.API_REQUEST,
  };
}

export function createCustomer(data) {
  return {
    endpoint: '/user/customer/',
    method: 'post',
    onError: { type: UserConstants.USER_CREATE_CUSTOMER_FAILURE },
    onStart: { type: UserConstants.USER_CREATE_CUSTOMER },
    onSuccess: { type: UserConstants.USER_CREATE_CUSTOMER_SUCCESS },
    payload: data,
    type: ApiConstants.API_REQUEST,
  };
}

export function archiveUser(userID, archive) {
  const status = archive ? 'archived' : 'unarchived';
  return {
    endpoint: `/user/${userID}/archive/`,
    method: 'put',
    onError: { type: UserConstants.USER_ARCHIVE_FAILURE },
    onStart: { type: UserConstants.USER_ARCHIVE },
    onSuccess: {
      type: UserConstants.USER_ARCHIVE_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: `User ${status}`,
      },
    },
    payload: { archive: archive },
    redirect: `/home/`,
    type: ApiConstants.API_REQUEST,
  };
}

export function searchAndFetchUsers(params) {
  return {
    params,
    type: UserConstants.USER_SEARCH_AND_FETCH,
  };
}
