import keyMirror from 'keymirror';

export default keyMirror({
  REFUND_CREATE: null,
  REFUND_CREATE_SUCCESS: null,
  REFUND_CREATE_FAILURE: null,

  REFUND_SEARCH: null,
  REFUND_SEARCH_SUCCESS: null,
  REFUND_SEARCH_FAILURE: null,
});
