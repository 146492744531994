import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

const DateInput = ({ name, onChange, value }) => {
  const [focused, toggleFocus] = useState(false);
  return (
    <SingleDatePicker
      date={moment(value)}
      onDateChange={date => onChange(date.format('YYYY-MM-DD'))}
      focused={focused}
      id={name}
      onFocusChange={({ focused }) => toggleFocus(focused)}
      displayFormat={'DD/MM/YYYY'}
      numberOfMonths={1}
    />
  );
};

export default DateInput;
