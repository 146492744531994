import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { inviteApplicant } from '_actions/carerActions';

import AdminApplicantInviteForm from 'components/admin/carers/AdminApplicantInviteForm';

class AdminApplicantInviteFormContainer extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      inviteApplicant: PropTypes.func.isRequired,
    }).isRequired,
    initialValues: PropTypes.shape(),
  };

  static defaultProps = {
    initialValues: {},
  };

  onSubmit = formData => {
    this.props.actions.inviteApplicant(formData)
  };

  render() {
    const { initialValues } = this.props;

    return (
      <AdminApplicantInviteForm
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      inviteApplicant,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminApplicantInviteFormContainer);