import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import CalendarShifts from 'components/shifts/CalendarShifts';
import CarerShiftInformation from 'components/shifts/CarerShiftInformation';
import {
  CustomEvent,
  CustomMonthEvent,
} from 'components/admin/needs/calendar/CustomEvents';

class AdminUserShifts extends Component {
  static propTypes = {
    onNavigate: PropTypes.func.isRequired,
    shifts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    user: PropTypes.shape().isRequired,
  };

  eventPropGetter = (event, start, end, isSelected) => {
    let opacity = '1';
    let backgroundColor = this.props.theme.colors.calendar[
      event.needIndex % this.props.theme.colors.calendar.length
    ];
    if (!event.active) {
      opacity = '0.5';
    }
    return {
      style: {
        backgroundColor,
        borderColor: 'transparent',
        opacity,
      },
    };
  };

  render() {
    const { onNavigate, shifts, user, taxonomiesByClassification } = this.props;

    let updatedShifts = shifts.map(shift => {
      const updatedShift = { ...shift };
      updatedShift.amendedBadge = shift.amended ? true : false;
      updatedShift.adhocBadge = shift.schedule_shift_id ? false : true;
      updatedShift.onHoldBadge = shift.on_hold ? true : false;
      updatedShift.name = shift.need ? shift.need.name : '';
      return updatedShift;
    });

    return (
      <CalendarShifts
        calendarHeader={`${user.name}’s Shifts`}
        components={{
          event: CustomEvent,
          month: {
            event: CustomMonthEvent,
          },
        }}
        noShiftsMessage={'No shifts for this carer'}
        onNavigate={onNavigate}
        shifts={updatedShifts}
        shiftInformationModal={CarerShiftInformation}
        shiftStyles={this.eventPropGetter}
        taxonomiesByClassification={taxonomiesByClassification}
      />
    );
  }
}

export default withTheme(AdminUserShifts);
