// @flow
import React from 'react';

import AdminDiscountTable from 'components/admin/discounts/AdminDiscountTable';
import Paginator from 'components/global/Paginator';
import Module, {
  ModuleHeader,
  ModuleSubheader,
} from 'components/global/layout/Module';

import DiscountPropType from 'proptypes/DiscountPropType';

type Props = {
  createDiscount: DiscountPropType => void,
  discounts: Array<DiscountPropType>,
  location: Object,
  search: Object,
  updateDiscount: DiscountPropType => void,
};

const AdminDiscounts = ({
  createDiscount,
  discounts,
  updateDiscount,
  search,
  location,
}: Props) => {
  return (
    <>
      <ModuleHeader noIndent>Discounts</ModuleHeader>

      <Module>
        <ModuleSubheader>Create New Discount</ModuleSubheader>
        <AdminDiscountTable
          discounts={[{}]}
          buttonText="Create"
          onSubmit={createDiscount}
        />
      </Module>

      {discounts && (
        <Module>
          <ModuleSubheader>Existing Discounts</ModuleSubheader>
          <AdminDiscountTable
            discounts={discounts}
            buttonClassName="altRed"
            buttonText="Update"
            onSubmit={updateDiscount}
          />
        </Module>
      )}

      <Paginator
        currentPage={search.pagination.page}
        totalPages={search.pagination.pages}
        location={location}
      />
    </>
  );
};

export default AdminDiscounts;
