// @flow
import React from 'react';

import Module from 'components/global/layout/Module';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import FormTemplate from 'components/forms/FormTemplate';
import { Container, Col } from 'components/grid';
import Button from 'components/buttons/Button';
import ButtonGroup from 'components/buttons/ButtonGroup';

import franchiseUserFieldsConfig from 'components/admin/needs/AdminFranchiseUserFormFields';

import type { TaxonomiesClassificationType } from 'shared/types';

type Props = {
  editMode: boolean,
  formName: string,
  initialValues: Object,
  onSubmit: () => void,
  taxonomiesByClassification: TaxonomiesClassificationType,
};

const AdminFranchiseUserForm = ({
  editMode = false,
  formName,
  initialValues = {},
  onSubmit,
  taxonomiesByClassification,
}: Props) => {
  return (
    <Form
      name={formName}
      shouldAsyncValidate={syncValidationPasses => {
        return syncValidationPasses;
      }}
      destroyOnUnmount
      fields={Object.keys(initialValues)}
      values={initialValues}
      onSubmit={onSubmit}
      selectFields={['franchise_id']}
      component={({ currentValues, initialValues, handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit} id="admin-franchise-user-form">
          <Module>
            <FormTemplate
              currentValues={currentValues}
              extraDisplayCriteria={{ editMode }}
              fieldsConfig={franchiseUserFieldsConfig}
              taxonomiesByClassification={taxonomiesByClassification}
            />
            <Container>
              <FormRow>
                <Col>
                  <ButtonGroup>
                    <Button disabled={pristine} type="submit">
                      {editMode ? 'Save changes' : 'Create User'}
                    </Button>
                  </ButtonGroup>
                </Col>
              </FormRow>
            </Container>
          </Module>
        </form>
      )}
    />
  );
};

export default AdminFranchiseUserForm;
