// @flow
import React from 'react';
import styled from 'styled-components';

import InlineStyledIcon from 'components/global/icons/InlineStyledIcon';
import {
  CarIcon,
  ExperienceIcon,
  LanguageIcon,
  LocationIcon,
  NonSmokerIcon,
  PetsIcon,
} from 'components/global/icons/small';

import { renderTaxon } from 'components/helpers/taxonomyHelpers';
import toTitleCase from 'components/helpers/toTitleCase';

import type {
  CarerType,
  ProfileDataType,
  TaxonomiesClassificationType,
} from 'shared/types';

const StyledSummary = styled.div``;

export const getLanguagesAndLevels = (
  data: ProfileDataType,
  taxonomiesByClassification: TaxonomiesClassificationType,
): ?Array<string> => {
  if (data.languages) {
    return data.languages.map(spoken => {
      const language = renderTaxon(
        taxonomiesByClassification['languages'],
        spoken.language,
      );
      const level = renderTaxon(
        taxonomiesByClassification['language_level'],
        spoken.level,
      );
      return `${language} (${level})`;
    });
  }

  return null;
};

export const getDriverStatus = (data: ProfileDataType): ?string => {
  if (data.driving_license && data.car) {
    return 'Has a driving license and car';
  } else if (data.driving_license && !data.car) {
    return 'Has a driving license but no car';
  }

  return null;
};

type Props = {|
  carer: CarerType,
  taxonomiesByClassification: TaxonomiesClassificationType,
|};

const ProfileSummary = ({ carer, taxonomiesByClassification }: Props) => {
  const data: ProfileDataType = carer.profile_details.profile_data;
  if (!data) {
    return null;
  }

  const town: string = carer.town;
  const years: string = renderTaxon(
    taxonomiesByClassification['care_experience_years'],
    data.care_experience_years,
  );

  const driverStatus: ?string = getDriverStatus(data);
  const languages: ?Array<string> = getLanguagesAndLevels(
    data,
    taxonomiesByClassification,
  );

  return (
    <StyledSummary>
      {town && (
        <InlineStyledIcon Icon={LocationIcon} label={toTitleCase(town)} />
      )}
      {years && (
        <InlineStyledIcon Icon={ExperienceIcon} label={`${years} experience`} />
      )}
      {languages && languages.length && (
        <InlineStyledIcon
          Icon={LanguageIcon}
          label={`Speaks ${languages.join(', ')}`}
        />
      )}
      {driverStatus && <InlineStyledIcon Icon={CarIcon} label={driverStatus} />}
      {data.likes_animals && (
        <InlineStyledIcon
          Icon={PetsIcon}
          label={renderTaxon(
            taxonomiesByClassification['likes_animals'],
            data.likes_animals,
          )}
        />
      )}
      {!data.smoker && (
        <InlineStyledIcon Icon={NonSmokerIcon} label="Non smoker" />
      )}
    </StyledSummary>
  );
};

export default ProfileSummary;
