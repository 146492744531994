import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Container, Col } from 'components/grid';
import FormRow from 'components/forms/FormRow';
import StyledInput from 'components/forms/StyledInput';
import Button from 'components/buttons/Button';

class ExpenseRow extends Component {
  static propTypes = {
    index: PropTypes.number,
    expense: PropTypes.shape(),
    addExpense: PropTypes.func,
    deleteExpense: PropTypes.func,
    onUpdateExpense: PropTypes.func.isRequired,
  };

  static defaultProps = {
    index: 0,
    expense: {
      description: '',
      value: 0,
    },
    addExpense: null,
    deleteExpense: null,
  };

  render() {
    const index = this.props.index || 0;
    const expense = this.props.expense || {
      description: '',
      value: 0,
    };

    return (
      <Container>
        <FormRow>
          <Col xs={12} md={6}>
            <StyledInput
              type="text"
              name="description"
              onChange={e => this.props.onUpdateExpense(index, e)}
              value={expense.description || ''}
              placeholder="Expense Description"
            />
          </Col>
          <Col xs={6} md={3}>
            <StyledInput
              type="number"
              name="value"
              step="0.01"
              defaultValue={(expense.value / 100).toFixed(2)}
              onChange={e => this.props.onUpdateExpense(index, e)}
              placeholder="Expense Amount"
            />
          </Col>
          {this.props.addExpense && (
            <Col xs={6} md={3}>
              <Button onClick={e => this.props.addExpense(e)}>Add</Button>
            </Col>
          )}
          {this.props.deleteExpense && (
            <Col xs={6} md={3}>
              <Button
                className="danger"
                onClick={() => this.props.deleteExpense(index)}
              >
                Remove
              </Button>
            </Col>
          )}
        </FormRow>
      </Container>
    );
  }
}

export default ExpenseRow;
