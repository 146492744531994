import React from 'react';
import styled from 'styled-components';
import { arrayOf, bool, object as obj, oneOfType, string } from 'prop-types';

import Definition from 'components/global/informationdisplay/Definition';
import { ModuleHeader } from 'components/global/layout/Module';

import { renderTaxon } from 'components/helpers/taxonomyHelpers';

export const StyledBulletedList = styled.ul`
  list-style: circle;
  margin-left: 22px;

  ${props => props.listStyling}
`;

const StyledListItem = styled.li`
  color: ${props =>
    props.deselected
      ? props.theme.colors.midGrey
      : props.theme.colors.formText};
`;

export const BulletedList = ({
  list,
  taxonomies,
  definitionLabel,
  alwaysDisplay,
  className,
  moduleLabel,
  listStyling,
}) => {
  if ((!list || !list.length) && !alwaysDisplay) {
    return null;
  }
  const bulletList = (
    <StyledBulletedList listStyling={listStyling}>
      {list.map(id => {
        return (
          <li key={id}>
            {taxonomies.find(({ taxon_id }) => taxon_id === id).value}
          </li>
        );
      })}
    </StyledBulletedList>
  );

  if (definitionLabel) {
    return (
      <Definition label={definitionLabel} className={className}>
        {bulletList}
      </Definition>
    );
  }

  return (
    <>
      {moduleLabel && <ModuleHeader noIndent>{moduleLabel}</ModuleHeader>}
      {bulletList}
    </>
  );
};

BulletedList.propTypes = {
  list: arrayOf(string),
  taxonomies: arrayOf(obj).isRequired,
  definitionLabel: string,
  moduleLabel: string,
  alwaysDisplay: bool,
  className: string,
};

BulletedList.defaultProps = {
  alwaysDisplay: false,
  className: '',
  list: null,
  definitionLabel: null,
  moduleLabel: null,
};

export const OneLineList = ({
  list,
  taxonomies,
  label,
  alwaysDisplay,
  className,
}) => {
  const display = list && (Array.isArray(list) ? list.length : true);
  return display || alwaysDisplay ? (
    <Definition className={className} label={label}>
      {renderTaxon(taxonomies, list)}
    </Definition>
  ) : null;
};

OneLineList.propTypes = {
  list: oneOfType([arrayOf(string), string, bool]),
  taxonomies: arrayOf(obj).isRequired,
  label: string.isRequired,
  alwaysDisplay: bool,
  className: string,
};

OneLineList.defaultProps = {
  alwaysDisplay: false,
  className: '',
  list: null,
};

// Returns a bulleted list that shows items selected or deselcted
// This is based on checking the initial list against a second passed in matchList
export const MatchedList = ({
  className,
  definitionLabel,
  list,
  listStyling,
  matchList,
  moduleLabel,
  selectedByDefault,
  taxonomies,
}) => {
  if (!list || !list.length) {
    return null;
  }

  const matchedList = (
    <StyledBulletedList listStyling={listStyling}>
      {list.map(id => {
        let deselected =
          (!matchList && !selectedByDefault) ||
          (matchList && !matchList.length);

        if (!deselected) {
          deselected = matchList && !matchList.includes(id);
        }

        return (
          <StyledListItem key={id} deselected={deselected}>
            {taxonomies.find(({ taxon_id }) => taxon_id === id).value}
          </StyledListItem>
        );
      })}
    </StyledBulletedList>
  );

  if (definitionLabel) {
    return (
      <Definition label={definitionLabel} className={className}>
        {matchedList}
      </Definition>
    );
  }

  return (
    <>
      {moduleLabel && <ModuleHeader noIndent>{moduleLabel}</ModuleHeader>}
      {matchedList}
    </>
  );
};
