// NOTE
// Remaining actions to be ported during app rebuild
// Nothing more to be done here for now
import store from 'store';

import ChargeConstants from 'shared/constants/ChargeConstants';
import SuperCarersClient from 'clients/SuperCarersClient';

const ChargeActions = {
  // TODO: Create use case saga where relevant
  getChargeById(chargeId) {
    const uri = `/charge/${chargeId}/`;

    const onSuccess = body => {
      store.dispatch({
        type: ChargeConstants.CHARGE_GET_BY_ID_SUCCESS,
        data: body.data,
      });

      return body;
    };

    const onError = error => {
      store.dispatch({
        type: ChargeConstants.CHARGE_GET_BY_ID_FAILURE,
        error: SuperCarersClient.errorDescription(error),
      });
    };

    store.dispatch({
      type: ChargeConstants.CHARGE_GET_BY_ID,
    });

    return SuperCarersClient.get(uri)
      .then(onSuccess)
      .catch(onError);
  },
};

export default ChargeActions;
