import ApiConstants from 'shared/constants/ApiConstants';
import RatesConstants from 'shared/constants/RatesConstants';
import { selectFields } from 'shared/selectors/ratesSelectors';
import store from 'store';

function maskRates(rates) {
  const fields = selectFields(store.getState());
  const maskedFields = fields.settings
    .concat(fields.shiftTypes)
    .concat(fields.shiftTypes.map(shiftTypes => `${shiftTypes}_commission`));

  return maskedFields.reduce((masked, key) => {
    masked[key] = rates[key];
    return masked;
  }, {});
}

export function getDefaultRates() {
  return {
    endpoint: '/settings/rates/default/',
    method: 'get',
    onError: {
      type: RatesConstants.RATES_GET_DEFAULT_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to get default rates.',
      },
    },
    onStart: { type: RatesConstants.RATES_GET_DEFAULT },
    onSuccess: { type: RatesConstants.RATES_GET_DEFAULT_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function setDefaultRates(rates) {
  return {
    endpoint: '/settings/rates/default/',
    method: 'put',
    onError: {
      type: RatesConstants.RATES_SET_DEFAULT_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to set default rates.',
      },
    },
    onStart: { type: RatesConstants.RATES_SET_DEFAULT },
    onSuccess: { type: RatesConstants.RATES_SET_DEFAULT_SUCCESS },
    payload: maskRates(rates),
    type: ApiConstants.API_REQUEST,
  };
}

export function getRateLimits() {
  return {
    endpoint: '/settings/rates/limit/find/',
    method: 'get',
    onError: {
      type: RatesConstants.RATES_GET_LIMITS_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to get rate limits.',
      },
    },
    onStart: { type: RatesConstants.RATES_GET_LIMITS },
    onSuccess: { type: RatesConstants.RATES_GET_LIMITS_SUCCESS },
    type: ApiConstants.API_REQUEST,
  };
}

export function getCarerRates(carerId) {
  return {
    endpoint: `/settings/rates/carer/${carerId}/`,
    method: 'get',
    onError: {
      type: RatesConstants.RATES_GET_CARER_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to get carer rates.',
      },
    },
    onStart: { type: RatesConstants.RATES_GET_CARER },
    onSuccess: { type: RatesConstants.RATES_GET_CARER_SUCCESS, id: carerId },
    type: ApiConstants.API_REQUEST,
  };
}

export function setCarerRates(carerId, rates) {
  return {
    endpoint: `/settings/rates/carer/${carerId}/`,
    method: 'put',
    onError: {
      type: RatesConstants.RATES_SET_CARER_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to set carer rates.',
      },
    },
    onStart: { type: RatesConstants.RATES_SET_CARER },
    onSuccess: {
      type: RatesConstants.RATES_SET_CARER_SUCCESS,
      id: carerId,
      toast: {
        type: 'success',
        public: false,
        title: 'Rates successfully updated.',
      },
    },
    payload: maskRates(rates),
    type: ApiConstants.API_REQUEST,
  };
}

export function resetCarerRates(carerId) {
  return {
    endpoint: `/settings/rates/carer/${carerId}/reset/`,
    method: 'post',
    onError: {
      type: RatesConstants.RATES_SET_CARER_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to reset carer rates.',
      },
    },
    onStart: { type: RatesConstants.RATES_SET_CARER },
    onSuccess: {
      type: RatesConstants.RATES_SET_CARER_SUCCESS,
      id: carerId,
      toast: {
        type: 'success',
        public: false,
        title: 'Rates successfully reset.',
      },
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function getCareNeedRates(careNeedId) {
  return {
    endpoint: `/settings/rates/care/need/${careNeedId}/`,
    method: 'get',
    otherProps: { needId: careNeedId },
    onError: {
      type: RatesConstants.RATES_GET_CARE_NEED_FAILURE,
    },
    onStart: { type: RatesConstants.RATES_GET_CARE_NEED },
    onSuccess: {
      type: RatesConstants.RATES_GET_CARE_NEED_SUCCESS,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function setCareNeedRates(careNeedId, rates) {
  return {
    endpoint: `/settings/rates/care/need/${careNeedId}/`,
    method: 'put',
    otherProps: { needId: careNeedId },
    onError: {
      type: RatesConstants.RATES_SET_CARE_NEED_FAILURE,
    },
    onStart: { type: RatesConstants.RATES_SET_CARE_NEED },
    onSuccess: {
      type: RatesConstants.RATES_SET_CARE_NEED_SUCCESS,
      toast: {
        type: 'success',
        public: false,
        title: 'Set care need rates successfully',
      },
    },
    payload: rates,
    type: ApiConstants.API_REQUEST,
  };
}

export function resetCareNeedRates(careNeedId, carerId) {
  return {
    endpoint: `/settings/rates/care/need/${careNeedId}/reset/`,
    method: 'post',
    otherProps: { needId: careNeedId },
    onError: {
      type: RatesConstants.RATES_RESET_CARE_NEED_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to reset care need rates.',
      },
    },
    onStart: { type: RatesConstants.RATES_RESET_CARE_NEED },
    onSuccess: {
      type: RatesConstants.RATES_RESET_CARE_NEED_SUCCESS,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function getCareNeedCarerRates(careNeedId, carerId) {
  return {
    endpoint: `/settings/rates/carer/${carerId}/${careNeedId}/`,
    method: 'get',
    onError: {
      type: RatesConstants.RATES_GET_CARE_NEED_CARER_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to get carer care need rates.',
      },
    },
    onStart: { type: RatesConstants.RATES_GET_CARE_NEED_CARER },
    onSuccess: {
      type: RatesConstants.RATES_GET_CARE_NEED_CARER_SUCCESS,
      id: `${careNeedId}:${carerId}`,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function setCareNeedCarerRates(careNeedId, carerId, rates) {
  return {
    endpoint: `/settings/rates/carer/${carerId}/${careNeedId}/`,
    method: 'put',
    onError: {
      type: RatesConstants.RATES_SET_CARE_NEED_CARER_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to set carer care need rates.',
      },
    },
    onStart: { type: RatesConstants.RATES_SET_CARE_NEED_CARER },
    onSuccess: {
      type: RatesConstants.RATES_SET_CARE_NEED_CARER_SUCCESS,
      id: `${careNeedId}:${carerId}`,
      toast: {
        type: 'success',
        public: false,
        title: 'Carer care need rates updated.',
      },
    },
    payload: maskRates(rates),
    type: ApiConstants.API_REQUEST,
  };
}

export function resetCareNeedCarerRates(careNeedId, carerId) {
  return {
    endpoint: `/settings/rates/carer/${carerId}/${careNeedId}/reset/`,
    method: 'post',
    onError: {
      type: RatesConstants.RATES_SET_CARE_NEED_CARER_FAILURE,
      toast: {
        type: 'error',
        public: false,
        title: 'Unable to reset carer care need rates.',
      },
    },
    onStart: { type: RatesConstants.RATES_SET_CARE_NEED_CARER },
    onSuccess: {
      type: RatesConstants.RATES_SET_CARE_NEED_CARER_SUCCESS,
      id: `${careNeedId}:${carerId}`,
    },
    type: ApiConstants.API_REQUEST,
  };
}

export function fetchCareNeedRates(careNeedId) {
  return { type: RatesConstants.RATES_FETCH_CARE_NEED_RATES, careNeedId };
}

export function setBidRates(bidId, rates) {
  return {
    endpoint: `/bid/${bidId}/`,
    method: 'put',
    onStart: { type: RatesConstants.RATES_SET_BID },
    onSuccess: {
      type: RatesConstants.RATES_SET_BID_SUCCESS,
      toast: {
        type: 'success',
        public: true,
        title: 'Rate updated successfully.',
      },
    },
    onError: {
      type: RatesConstants.RATES_SET_BID_FAILURE,
      toast: {
        type: 'error',
        public: true,
        title: 'Rate updated failed.',
      },
    },
    payload: { rates },
    type: ApiConstants.API_REQUEST,
  };
}
