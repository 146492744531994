import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { timesheetRecalculate } from '_actions/timesheetActions';
import TimesheetActions from 'actions/TimesheetActions';
import stateHelper from 'components/helpers/stateHelper';
import TimesheetControls from 'components/timesheets/TimesheetControls';

class TimesheetControlsContainer extends Component {
  onTimesheetRecalculate = () => {
    this.props.timesheetRecalculate(this.props.id);
  };

  onUpdateState = (e, newState) => {
    e.preventDefault();
    const data = { state: newState };
    TimesheetActions.update(this.props.id, data, false);
  };

  inState = ({ carer, admin }) =>
    stateHelper.inState(this.props.state, { carer, admin });

  render() {
    const stripeEnabled = true; // this.props.stripeEnabled;
    return (
      <TimesheetControls
        id={this.props.id}
        inState={this.inState}
        updateState={this.onUpdateState}
        stripeEnabled={stripeEnabled}
        timesheetRecalculate={this.onTimesheetRecalculate}
      />
    );
  }
}

TimesheetControlsContainer.propTypes = {
  id: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  stripeEnabled: PropTypes.bool.isRequired,
  timesheetRecalculate: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      timesheetRecalculate,
    },
    dispatch,
  );
}

export default connect(
  null,
  mapDispatchToProps,
)(TimesheetControlsContainer);
