import FranchiseConstants from 'shared/constants/FranchiseConstants';
import {
  addObject,
  addObjects,
  addObjectsSetLoadingState,
  deleteId,
  setLoadingState,
} from 'shared/reducers/reducerHelpers';

const initalState = {
  loading: {
    franchises: false,
    franchiseUsers: false,
  },
};

function franchises(state = initalState, action) {
  switch (action.type) {
    case FranchiseConstants.POPULATE_FRANCHISE:
      return setLoadingState(state, 'franchiseUsers', true);

    case FranchiseConstants.POPULATE_FRANCHISE_COMPLETE:
      return setLoadingState(state, 'franchiseUsers', false);

    case FranchiseConstants.FRANCHISE_GET_ALL:
      return setLoadingState(state, 'franchises', true);

    case FranchiseConstants.FRANCHISE_GET_ALL_SUCCESS:
      return setLoadingState(addObjects(state, action), 'franchises', false);

    case FranchiseConstants.FRANCHISE_GET_ALL_FAILURE:
      return addObjectsSetLoadingState(state, action, 'franchises', false);

    case FranchiseConstants.FRANCHISE_SEARCH_SUCCESS:
      return addObjects(state, action)

    case FranchiseConstants.FRANCHISE_CREATE_SUCCESS:
    case FranchiseConstants.FRANCHISE_GET_BY_ID_SUCCESS:
    case FranchiseConstants.FRANCHISE_UPDATE_SUCCESS:
      return addObject(state, action);

    case FranchiseConstants.FRANCHISE_DELETE_SUCCESS:
      return deleteId(state, action);

    default:
      return state;
  }
}

export default franchises;
