import keyMirror from 'keymirror';

export default keyMirror({
  INVOICE_GET_BY_ID: null,
  INVOICE_GET_BY_ID_SUCCESS: null,
  INVOICE_GET_BY_ID_FAILURE: null,

  INVOICE_PAY: null,
  INVOICE_PAY_SUCCESS: null,
  INVOICE_PAY_FAILURE: null,

  INVOICE_SEARCH: null,
  INVOICE_SEARCH_SUCCESS: null,
  INVOICE_SEARCH_FAILURE: null,

  INVOICE_VOID: null,
  INVOICE_VOID_SUCCESS: null,
  INVOICE_VOID_FAILURE: null,

  INVOICE_SUMMARY: null,
  INVOICE_SUMMARY_SUCCESS: null,
  INVOICE_SUMMARY_FAILURE: null,

  POPULATE_INVOICE: null,
});
