import CareNeedConstants from 'shared/constants/CareNeedConstants';
import NoteConstants from 'shared/constants/NoteConstants';
import TimesheetConstants from 'shared/constants/TimesheetConstants';
import {
  addObject,
  addObjects,
  deleteId,
  addNote,
  updateObject,
} from 'shared/reducers/reducerHelpers';
import PaymentConstants from '../constants/PaymentConstants';

const initalState = {};

function timesheets(state = initalState, action) {
  switch (action.type) {
    case NoteConstants.TIMESHEET_GET_BY_ID_SUCCESS:
      return addNote(state, action);

    case TimesheetConstants.TIMESHEET_GET_BY_ID_SUCCESS:
      return addObject(state, action);

    case TimesheetConstants.TIMESHEET_GET_BY_CARER_ID_SUCCESS:
      return addObjects(state, action);

    case TimesheetConstants.TIMESHEET_UPDATE_SUCCESS:
      return addObject(state, action);

    case TimesheetConstants.TIMESHEET_SEARCH_SUCCESS:
      return addObjects(state, action);

    case TimesheetConstants.TIMESHEET_DELETE_SUCCESS:
      return deleteId(state, action);

    case TimesheetConstants.TIMESHEET_REASSIGN_SUCCESS:
      return updateObject(state, {
        [action.data.timesheet.id]: action.data.timesheet,
      });

    case TimesheetConstants.TIMESHEET_RECALCULATE_TOTALS_SUCCESS:
      return addObject(state, action);

    case CareNeedConstants.CARE_NEED_TIMESHEET_GENERATE_SUCCESS:
      return addObject(state, action);

    case PaymentConstants.PAYMENTS_GET_PAYMENT_INTENT_SUCCESS:
      return updateObject(state, {
        [action.otherProps.timesheet_id]: {
          paymentIntent: action.data,
          ...state[action.otherProps.timesheet_id],
        },
      });

    default:
      return state;
  }
}

export default timesheets;
