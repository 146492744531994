import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';

class ShiftTypeCell extends Component {
  static propTypes = {
    shift: PropTypes.object.isRequired,
    liveIn: PropTypes.bool.isRequired,
    view: PropTypes.string.isRequired,
    shiftTypes: PropTypes.array.isRequired,
  };

  SHIFT_TYPES = [
    { taxon_id: 'exceptional', value: 'Exceptional Shift' },
    ...this.props.shiftTypes,
  ];

  render() {
    const startDate = moment(this.props.shift.start).format('HH:mm');
    const endDate = moment(this.props.shift.start)
      .add(this.props.shift.duration, 'minutes')
      .format('HH:mm');
    const shiftType = this.SHIFT_TYPES.find(
      ({ taxon_id }) => taxon_id === this.props.shift.type,
    );

    const shiftTypeText = shiftType ? shiftType.value : '';
    let text;
    let supplementaryText;

    if (this.props.view === 'customer' || this.props.liveIn) {
      text = shiftTypeText;
      supplementaryText = '';
    } else if (!!shiftTypeText) {
      text = `${startDate} - ${endDate}`;
      supplementaryText = shiftTypeText;
    } else {
      text = `${startDate} - ${endDate}`;
      supplementaryText = '';
    }

    if (this.props.shift.total.holiday_multiplier > 1) {
      text += ' (Holiday)';
    }

    return (
      <td data-heading="Shift" className="supplementary-info">
        <div>
          <div>{text}</div>
          {!!supplementaryText && <div>{supplementaryText}</div>}
        </div>
      </td>
    );
  }
}

export default ShiftTypeCell;
