import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import HolidayRow from 'components/admin/settings/HolidayRow';

class AdminHolidaySettings extends Component {
  render() {
    const dates = this.props.dates;
    return (
      <section className="component-wrapper">
        <div className="module boxed">
          <h2>Holiday Days</h2>
          <HolidayRow
            date={this.props.newHoliday.date || ''}
            name={this.props.newHoliday.name || ''}
            addHoliday={this.props.addHoliday}
            onSetDate={this.props.onSetDate}
            onChangeName={this.props.onSetName}
          />
          {dates.map((holiday, index) => (
            <HolidayRow
              key={moment(holiday.date).format('YYYY-MM-DD')}
              date={moment(holiday.date).format('YYYY-MM-DD')}
              name={holiday.name}
              index={index}
              onChangeName={this.props.onChangeName}
              updateHoliday={this.props.updateHoliday}
              deleteHoliday={this.props.deleteHoliday}
            />
          ))}
        </div>
      </section>
    );
  }
}

AdminHolidaySettings.propTypes = {
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  newHoliday: PropTypes.shape({
    date: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,

  addHoliday: PropTypes.func,
  updateHoliday: PropTypes.func,
  deleteHoliday: PropTypes.func,
  onSetDate: PropTypes.func.isRequired,
  onSetName: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
};

AdminHolidaySettings.defaultProps = {
  updateHoliday: null,
  addHoliday: null,
  deleteHoliday: null,
};

export default AdminHolidaySettings;
