import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import StandardTable from 'components/global/layout/StandardTable';
import InvoiceTableRow from 'components/invoices/InvoiceTableRow';

const NoResults = styled.div`
  text-align: center;
`;

class InvoiceTable extends Component {
  render() {
    if (!this.props.invoices || this.props.invoices.length <= 0) {
      return <NoResults>No invoices</NoResults>;
    }

    return (
      <StandardTable className="invoices">
        <thead>
          <tr>
            <th>Week Commencing</th>
            <th>Invoice ID</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.invoices.map(invoice => (
            <InvoiceTableRow key={invoice.id} invoice={invoice} />
          ))}
        </tbody>
      </StandardTable>
    );
  }
}

InvoiceTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default InvoiceTable;
