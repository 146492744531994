import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleFeature } from '_actions/accountActions';

import Feature from 'components/admin/Feature';

class FeatureContainer extends Component {
  toggleFeature = () => {
    this.props.toggleFeature(this.props.name, !this.props.active);
  };

  render() {
    const { active, name } = this.props;

    return (
      <Feature active={active} name={name} toggleFeature={this.toggleFeature} />
    );
  }
}

FeatureContainer.propTypes = {
  active: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  toggleFeature: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ toggleFeature }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps,
)(FeatureContainer);
