// Redux-Form compatible table with Read-only mode
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { rateLimitValidators } from 'validators';

import StandardInput from 'components/forms/StandardInput';
import CurrencyField from 'components/forms/CurrencyField';
import StandardTable from 'components/global/layout/StandardTable';
import Currency from 'components/modules/Currency';
import { calculateCustomerRate } from 'components/helpers/currency';

export const SHIFT_DESCRIPTIONS = {
  short_duty: {
    name: 'Day shift',
    description: '',
  },
  long_duty: {
    name: 'Day shift',
    description: '',
  },
  working_night: {
    name: 'Working night',
    description: 'Carer is up and working through the night.',
  },
  sleeping_night: {
    name: 'Sleeping night',
    description:
      'Carer will be up a couple of times in the night, but will be able to get a good sleep.',
  },
  livein: {
    name: 'Live-in (full day)',
    description:
      'Daily rate for full day live-in duties, not including break days.',
  },
  half_livein: {
    name: 'Live-in (half day)',
    description:
      'Daily rate for half day live-in duties, not including break days. Always half the rate of Live-in (full day).',
  },
};

class CarerRatesTable extends Component {
  static propTypes = {
    currentValues: PropTypes.shape(),
    editable: PropTypes.bool,
    fieldName: PropTypes.func,
    rate: PropTypes.shape().isRequired,
    rateLimits: PropTypes.shape(),
    shiftTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    withDescription: PropTypes.bool,
  };

  static defaultProps = {
    currentValues: {},
    editable: false,
    fieldName: field => field,
    rateLimits: {},
    withDescription: false,
  };

  constructor(props) {
    super(props);
    // validators must be created once to avoid fields re-registering
    this.validators = rateLimitValidators(this.props.rateLimits);
  }

  render() {
    const {
      shiftTypes,
      rate,
      currentValues,
      withDescription,
      editable,
      fieldName,
    } = this.props;

    return (
      <StandardTable>
        <thead>
          <tr>
            <th>Shift Type</th>
            {withDescription && <th>Description</th>}
            <th>Your Pay</th>
            <th className="numeric">Commission</th>
            <th className="numeric">Customer Total</th>
          </tr>
        </thead>
        <tbody>
          {shiftTypes &&
            shiftTypes.map(shiftType => {
              const { name, description } = SHIFT_DESCRIPTIONS[shiftType];
              const shiftTypeCommission = `${shiftType}_commission`;
              const carerRate = currentValues[shiftType] || rate[shiftType];
              const customerRate = calculateCustomerRate(
                carerRate,
                rate[shiftTypeCommission],
              );
              const commission = customerRate - carerRate;
              const frequency = shiftType === 'livein' ? 'per day' : 'per hour';
              return (
                <tr key={shiftType}>
                  <td data-heading="Shift Type">
                    <span style={{ whiteSpace: 'nowrap' }}>{name}</span>
                  </td>
                  {withDescription && (
                    <td data-heading="Description">{description}</td>
                  )}
                  <td data-heading="Your Pay">
                    {editable && (
                      <CurrencyField
                        component={StandardInput}
                        help={frequency}
                        name={fieldName(shiftType)}
                        validate={this.validators[shiftType]}
                      />
                    )}
                    {!editable && (
                      <Currency value={carerRate} frequency={frequency} />
                    )}
                  </td>
                  <td data-heading="Commission" className="number">
                    <Currency value={commission} />
                  </td>
                  <td data-heading="Customer Total" className="number">
                    <Currency value={customerRate} />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </StandardTable>
    );
  }
}

export default CarerRatesTable;
