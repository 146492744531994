// @flow
import React, { Component, type Node } from 'react';
import styled, { css } from 'styled-components';

const StyledExapndableText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTextContainer = styled.div`
  max-height: ${props => (props.expanded ? '' : props.maxHeight + 'px')};
  overflow: hidden;
  position: relative;

  ${props =>
    !props.expanded &&
    css`
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 50%,
          white
        );
      }
    `}
`;

const StyledExpandCollapseText = styled.div`
  color: ${props => props.theme.colors.statusBlue};
  margin: 0 auto 5px auto;
`;

type Props = {
  children: Node,
  maxHeight: number,
};

type State = {
  expanded: boolean,
  noOverflow: boolean,
};

export default class ExpandableText extends Component<Props, State> {
  // class variables have to have their type declared as so
  textContainerRef: { current: null | HTMLDivElement };

  static defaultProps = {
    maxHeight: 300,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      expanded: false,
      noOverflow: false,
    };

    this.textContainerRef = React.createRef();
  }

  componentDidMount() {
    if (this.textContainerRef.current) {
      const noOverflow =
        this.textContainerRef.current.clientHeight < this.props.maxHeight;
      if (noOverflow) {
        this.setState({ noOverflow: true });
      }
    }
  }

  expandCollapse = () => this.setState({ expanded: !this.state.expanded });

  render() {
    const { children, maxHeight } = this.props;
    const { expanded, noOverflow } = this.state;

    if (noOverflow) {
      return children;
    }

    return (
      <StyledExapndableText>
        <StyledTextContainer expanded={expanded} maxHeight={maxHeight}>
          <div ref={this.textContainerRef}>{children}</div>
        </StyledTextContainer>
        <StyledExpandCollapseText role="button" onClick={this.expandCollapse}>
          {expanded ? 'Hide' : 'Read more'}
        </StyledExpandCollapseText>
      </StyledExapndableText>
    );
  }
}
