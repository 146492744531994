// @flow
import React, { useEffect, useState, type Node } from 'react';
import styled from 'styled-components';

import { ModuleHeader, ModuleSubheader } from 'components/global/layout/Module';
import { DownTriangleIcon } from 'components/global/icons/small';

const CollapsibleHeaderWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

let CollapsibleSectionWrapper = styled.div`
  max-height: ${props => props.maxHeight};
  opacity: 1;
  transition: max-height 0.5s, opacity 0.5s 0.3s;

  > * {
    visibility: visible;
  }

  &.closed {
    max-height: 0;
    opacity: 0;
    transition: opacity 0.5s, max-height 0.5s 0.25s;

    > * {
      visibility: hidden;
    }
  }
`;

const StyledDownTriangleIcon = styled(DownTriangleIcon)`
  transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(-90deg)')};
  position: absolute;
  right: 20px;
  top: 0;
`;

type Props = {
  children: Node,
  header?: string,
  id: string,
  maxHeight?: string,
  openByDefault?: boolean,
  onExpand?: () => void,
  subheader?: string,
  setHeight?: number,
};

const CollapsibleSection = ({
  children,
  header,
  id,
  maxHeight = '2000px',
  setHeight,
  openByDefault = true,
  onExpand,
  subheader,
}: Props) => {
  const [sectionOpen, toggleSectionDisplay] = useState(openByDefault);
  const [sectionHeight, updateSectionHeight] = useState(maxHeight);
  const sectionRef = React.createRef();

  useEffect(() => {
    if (sectionOpen) {
      const waitForSectionToOpen = 500;
      setTimeout(() => {
        if (sectionRef.current) {
          updateSectionHeight(
            setHeight || `${sectionRef.current.clientHeight}px`,
          );
        }
      }, waitForSectionToOpen);
    }
  });

  const toggleSection = () => {
    if (!sectionOpen && onExpand) {
      onExpand();
    }

    toggleSectionDisplay(!sectionOpen);
  };

  return (
    <>
      <CollapsibleHeaderWrapper onClick={toggleSection}>
        {header && <ModuleHeader>{header}</ModuleHeader>}
        {subheader && <ModuleSubheader>{subheader}</ModuleSubheader>}
        <StyledDownTriangleIcon open={sectionOpen} />
      </CollapsibleHeaderWrapper>
      <CollapsibleSectionWrapper
        className={!sectionOpen && 'closed'}
        innerRef={sectionRef}
        maxHeight={sectionHeight}
      >
        {children}
      </CollapsibleSectionWrapper>
    </>
  );
};

export default CollapsibleSection;
