import moment from 'moment';
import { sortSchedules } from 'shared/helpers/scheduleHelpers';

export const selectSchedules = state => state.schedules;
export const selectScheduleByID = (state, id) => state.schedules[id];

export const selectSchedulesByNeedID = (
  state,
  needID,
  isCurrent = false,
  sorted = false,
  archived = false,
) => {
  const schedules = selectSchedules(state);
  if (!schedules) {
    return null;
  }

  const schedulesForNeed = Object.values(schedules).filter(schedule => {
    const currentCheck = isCurrent
      ? !schedule.end || moment(schedule.end).isAfter(moment())
      : true;
    const archivedCheck = schedule.archived === archived;
    return schedule.care_need_id === needID && currentCheck && archivedCheck;
  });

  // sort past schedules to the end of the list
  if (sorted) {
    return sortSchedules(schedulesForNeed);
  }

  return schedulesForNeed;
};

export const selectSchedulesForShifts = (state, shifts) => {
  const schedules = {};
  shifts.forEach(shift => {
    if (!schedules[shift.schedule_id]) {
      if (state.schedules[shift.schedule_id]) {
        schedules[shift.schedule_id] = state.schedules[shift.schedule_id];
      }
    }
  });
  return schedules;
};

export const selectAllSchedulesForUserByNeed = (state, id) => {
  const needs = Object.values(state.needs).filter(need => need.user_id === id);
  const schedules = selectSchedules(state);
  if (!schedules || !needs) {
    return null;
  }

  const schedulesByNeed = {};
  needs.forEach(need => {
    schedulesByNeed[need.id] = Object.values(schedules).filter(
      schedule => schedule.care_need_id === need.id,
    );
  });

  return schedulesByNeed;
};

export const selectAllSchedulesForUser = (state, id) => {
  const needIDs = Object.keys(state.needs).filter(
    needID => state.needs[needID].user_id === id,
  );
  const schedules = selectSchedules(state);
  if (!schedules || !needIDs) {
    return null;
  }

  return Object.values(schedules).filter(schedule =>
    needIDs.includes(schedule.care_need_id),
  );
};
