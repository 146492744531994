import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

import ReferenceForm from 'components/references/ReferenceForm';

class ReferenceFormContainer extends Component {
  static propTypes = {
    carerID: PropTypes.string.isRequired,
    editMode: PropTypes.bool,
    initialValues: PropTypes.shape().isRequired,
    isAdmin: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    ready: PropTypes.bool,
    taxonomiesByClassification: PropTypes.shape().isRequired,
    workHistory: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    editMode: false,
    ready: true,
    taxonomiesByClassification: null,
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return ['reference_roles', 'reference_relationships', 'reference_time_known'];
  }

  handleSubmit = ({ data, addMore }) => {
    const newData = { ...data };
    this.props.onSubmit(newData, addMore);
  };

  render() {
    const { taxonomiesByClassification } = this.props;

    if (!this.props.ready || !taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    return (
      <ReferenceForm
        carerID={this.props.carerID}
        editMode={this.props.editMode}
        initialValues={this.props.initialValues}
        isAdmin={this.props.isAdmin}
        onSubmit={this.handleSubmit}
        taxonomiesByClassification={this.props.taxonomiesByClassification}
        workHistory={this.props.workHistory}
      />
    );
  }
}

const mapStateToProps = state => ({
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    ReferenceFormContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

export default connect(mapStateToProps)(ReferenceFormContainer);
