/* global confirm */
/* eslint no-restricted-globals: ["off", "confirm"] */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-redux-dom-link';
import moment from 'moment';
import styled from 'styled-components';

import { Container, Col, Row } from 'components/grid';
import Module, {
  ModuleHeader,
  ModuleHero,
} from 'components/global/layout/Module';
import StandardTable from 'components/global/layout/StandardTable';
import ConfirmationDialog from 'components/global/ConfirmationDialog';
import { renderTaxon } from 'components/helpers/taxonomyHelpers';
import toTitleCase from 'components/helpers/toTitleCase';
import { DeleteIcon, EditIcon } from 'components/global/icons';
import StyledTickList from 'components/global/informationdisplay/StyledTickList';
import StyledTickNestedList from 'components/global/informationdisplay/StyledTickNestedList';

const DeleteLink = styled.a`
  color: ${props => props.theme.colors.midGrey};
`;

class CarerWorkHistorySummary extends Component {
  static propTypes = {
    carer: PropTypes.shape().isRequired,
    deleteWorkHistoryInstance: PropTypes.func.isRequired,
    isApproved: PropTypes.bool.isRequired,
    taxonomiesByClassification: PropTypes.shape(),
    workHistory: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  state = {
    deletingWorkHistoryInstance: null,
  };

  confirmDeletion = workHistoryInstanceID => {
    this.setState({
      deletingWorkHistoryInstance: workHistoryInstanceID,
    });
  };

  deleteWorkHistoryInstance = workHistoryInstanceID => {
    this.props.deleteWorkHistoryInstance(
      workHistoryInstanceID,
      this.props.carer.id,
    );
  };

  render() {
    const { isApproved, taxonomiesByClassification, workHistory } = this.props;

    return (
      <section>
        <Module>
          <ModuleHeader>
            Please detail your full employment history since leaving secondary
            education
          </ModuleHeader>
          <Container>
            <Row>
              <Col xs={12} md={10}>
                <StyledTickList>
                  <li>
                    Provide details of all <strong>full and part time</strong>{' '}
                    work, as well as details of all{' '}
                    <strong>paid and unpaid</strong> work experience since
                    secondary education e.g. commercial experience, youth work
                    or voluntary work.
                  </li>
                  <li>
                    Please ensure you add any{' '}
                    <strong>gaps in employment which exceed one month</strong>{' '}
                    as additional entries e.g. raising a family, travelling and
                    periods when you were not working.
                    <StyledTickNestedList>
                      <li>
                        Select <strong>Gap in Employment</strong> as the "Work
                        History Type" and provide an explanation using the
                        "Description" box.
                      </li>
                    </StyledTickNestedList>
                  </li>
                  <li>
                    Please <strong>keep your work history up to date</strong> by
                    returning to this page and adding any new assignments or
                    employers.
                  </li>
                </StyledTickList>
              </Col>
            </Row>
          </Container>
        </Module>

        {isApproved && !!workHistory.length && (
          <Module>
            <Container>
              <Row>
                <Col>
                  Your existing work history has been approved. If you need to
                  change your work history, please contact us. Feel free to add
                  any new assignments or employers.
                </Col>
              </Row>
            </Container>
          </Module>
        )}

        <Module className="CarerWorkHistorySummary">
          <ModuleHeader>Work History Summary</ModuleHeader>
          {!workHistory.length && (
            <ModuleHero>No work history entered yet.</ModuleHero>
          )}
          {!!workHistory.length && (
            <StandardTable className="carerWorkHistory">
              <thead>
                <tr>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Type</th>
                  <th>Role</th>
                  <th>Organisation</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {workHistory.map(workHistoryInstance => (
                  <tr key={workHistoryInstance.id}>
                    <td>
                      {workHistoryInstance.employment_start_date
                        ? moment(
                            workHistoryInstance.employment_start_date,
                          ).format('YYYY-MM')
                        : 'No start date'}
                    </td>

                    <td>
                      {workHistoryInstance.employment_end_date
                        ? moment(
                            workHistoryInstance.employment_end_date,
                          ).format('YYYY-MM')
                        : 'Present'}
                    </td>

                    <td>
                      {renderTaxon(
                        taxonomiesByClassification[
                          'work_history_employment_type'
                        ],
                        workHistoryInstance.employment_type,
                      )}
                    </td>

                    <td>{toTitleCase(workHistoryInstance.user_role)}</td>

                    <td>{toTitleCase(workHistoryInstance.organization)}</td>

                    <td>
                      {(!isApproved ||
                        !workHistoryInstance.employment_end_date) && (
                        <Link to={`/work-history/${workHistoryInstance.id}/`}>
                          <EditIcon />
                        </Link>
                      )}
                      {isApproved &&
                        workHistoryInstance.employment_end_date &&
                        '-'}
                    </td>

                    <td>
                      {!isApproved && (
                        <DeleteLink
                          onClick={() =>
                            this.confirmDeletion(workHistoryInstance.id)
                          }
                          role="button"
                        >
                          <DeleteIcon />
                        </DeleteLink>
                      )}
                      {isApproved && '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </StandardTable>
          )}

          <Container>
            <Row>
              <Col>
                <Link to="/work-history/add/">
                  Add period of employment or otherwise
                </Link>
                <br />
                <br />
              </Col>
            </Row>
          </Container>

          {this.state.deletingWorkHistoryInstance && (
            <ConfirmationDialog
              title="Delete this employment?"
              message="Caution. This cannot be undone."
              confirmation="Yes, delete it"
              onConfirm={() => {
                this.props.deleteWorkHistoryInstance(
                  this.state.deletingWorkHistoryInstance,
                );
                this.setState({ deletingWorkHistoryInstance: null });
              }}
              onCancel={() => {
                this.setState({ deletingWorkHistoryInstance: null });
              }}
              danger
            />
          )}
        </Module>
      </section>
    );
  }
}

export default CarerWorkHistorySummary;
