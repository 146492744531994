import styled from 'styled-components';

import React from 'react';
import PropTypes from 'prop-types';

const StyledTick = styled.span`
  display: inline-block;
  margin: 0 5px;

  color: ${props => props.theme.colors.statusGreen};
  &:after {
    content: '✓';
  }
`;

const StyledCross = styled.span`
  display: inline-block;
  margin: 0 5px;

  color: ${props => props.theme.colors.statusRed};
  &:after {
    content: '✗';
  }
`;

const StyledBoolean = ({ value }) => (value ? <StyledTick /> : <StyledCross />);

StyledBoolean.propTypes = {
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

StyledBoolean.defaultProps = {
  value: false, // eslint-disable-line react/forbid-prop-types
};

export default StyledBoolean;
