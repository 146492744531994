import { checkIfDefined } from 'components/helpers/functionHelpers';

export const sortObjectStrings = (objectArray, sortByValue) => {
  const localObjectArray = objectArray;
  return localObjectArray.sort((a, b) =>
    a[sortByValue] > b[sortByValue] ? 1 : -1,
  );
};

export const moveObjectToLastInArray = (
  objectArray,
  keyOfItemToMove,
  valueOfItemToMove,
) => {
  const itemToMove = objectArray.find(
    object => object[keyOfItemToMove] === valueOfItemToMove,
  );
  const filteredArray = objectArray.filter(
    object => object[keyOfItemToMove] !== itemToMove[keyOfItemToMove],
  );
  filteredArray.push(itemToMove);
  return filteredArray;
};

export const checkIfKeyHasValueInObject = (item, object) => {
  return object.hasOwnProperty(item) && checkIfDefined(object[item]);
};
