import PropTypes from 'prop-types';
import React, { Component } from 'react';

import StandardTable from 'components/global/layout/StandardTable';
import Currency from 'components/modules/Currency';
import Days from 'components/modules/Days';
import Hours from 'components/modules/Hours';

class InvoiceTotal extends Component {
  static propTypes = {
    total: PropTypes.shape({
      carer_total: PropTypes.number.isRequired,
      customer_total: PropTypes.number.isRequired,
      minutes_total: PropTypes.number.isRequired,
      livein_days: PropTypes.number.isRequired,
      application_total: PropTypes.number.isRequired,
    }).isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };
  render() {
    return (
      <StandardTable className="fixed">
        <tbody>
          <tr className="total">
            <td>
              <strong>Total</strong>
            </td>
            <td>
              {!!this.props.total.livein_days && (
                <Days context={'Live-in'} days={this.props.total.livein_days} />
              )}
              {!!this.props.total.minutes_total && (
                <Hours minutes={this.props.total.minutes_total} />
              )}
            </td>
            <td className="number">
              <strong>
                <Currency value={this.props.total.customer_total} />
              </strong>
              {this.props.isAdmin && (
                <div
                  title="SuperCarers commission."
                  className="supplementary-info"
                >
                  <Currency value={this.props.total.application_total} />
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </StandardTable>
    );
  }
}

export default InvoiceTotal;
