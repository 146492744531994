import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Col } from 'components/grid';
import FormRow from 'components/forms/FormRow';
import StyledInput from 'components/forms/StyledInput';

class ExceptionalDetails extends Component {
  static propTypes = {
    total: PropTypes.number,
    reason: PropTypes.string,
    updateTotal: PropTypes.func.isRequired,
    updateReason: PropTypes.func.isRequired,
  };

  static defaultProps = {
    total: 0,
    reason: '',
  };

  render() {
    const total = (this.props.total || 0) / 100;
    const reason = this.props.reason;
    return (
      <FormRow style={{ justifyContent: 'space-between' }}>
        <Col xs={12} md={6}>
          <StyledInput
            type="text"
            value={reason}
            name="reason"
            onChange={e => this.props.updateReason(e.currentTarget.value)}
            placeholder="Reason for exceptional shift"
          />
        </Col>
        <Col xs={6} md={3}>
          <StyledInput
            type="number"
            step="0.01"
            name="total"
            defaultValue={total.toFixed(2)}
            onChange={e => this.props.updateTotal(e.currentTarget.value)}
            placeholder="Total Cost"
          />
        </Col>
      </FormRow>
    );
  }
}

export default ExceptionalDetails;
