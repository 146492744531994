// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { searchCarers, searchMoreCarers } from '_actions/carerActions';

import AdminCarerSearch from 'components/admin/carers/AdminCarerSearch';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import {
  getCarerResults,
  getCarerSearch,
} from 'shared/selectors/carerSelectors';
import { selectLastSearchParamsByCategory } from 'shared/selectors/searchSelectors';
import { getTaxonomiesByClassifications } from 'shared/selectors/taxonomySelectors';

import type {
  CarerType,
  CarerSearchType,
  LastCarerSearchType,
  TaxonomiesClassificationType,
} from 'shared/types';

const SEARCH_AMOUNT = 100;

type Props = {
  carerResults: Array<?CarerType>,
  carerSearch: CarerSearchType,
  lastCarerSearch: LastCarerSearchType,
  searchCarers: Object => void,
  searchMoreCarers: Object => void,
  taxonomiesByClassification: TaxonomiesClassificationType,
};

class AdminCarerSearchContainer extends Component<Props> {
  static defaultProps = {
    carerResults: null,
    carerSearch: {},
    lastCarerSearch: null,
    taxonomiesByClassification: [],
  };

  static get TAXONOMY_CLASSIFICATIONS() {
    return [
      'care_type',
      'carer_profile_care_team_states',
      'carer_profile_states',
      'carer_profile_work_preferences',
      'compliance_fields',
      'condition',
      'equipment',
      'franchise_country',
      'languages',
      'likes_animals',
      'qualifications',
      'services',
      'training_courses',
    ];
  }

  searchCarers = data => {

    const searchData = {
      per_page: SEARCH_AMOUNT,
      ...data,
    };

    if (searchData.work_state === '') {
      delete searchData.work_state;
    }

    if (searchData.is_compliant === '') {
      delete searchData.is_compliant;
    }

    this.props.searchCarers(searchData);
  };

  render() {
    const {
      carerResults,
      carerSearch,
      lastCarerSearch,
      taxonomiesByClassification,
    } = this.props;

    if (!taxonomiesByClassification) {
      return <LoadingPlaceholder />;
    }

    return (
      <AdminCarerSearch
        carerResults={carerResults}
        carerSearch={carerSearch}
        lastCarerSearch={lastCarerSearch}
        taxonomiesByClassification={taxonomiesByClassification}
        searchCarers={this.searchCarers}
        searchMoreCarers={this.props.searchMoreCarers}
      />
    );
  }
}

const mapStateToProps = state => ({
  carerResults: getCarerResults(state),
  carerSearch: getCarerSearch(state),
  lastCarerSearch: selectLastSearchParamsByCategory(state, 'carers'),
  taxonomiesByClassification: getTaxonomiesByClassifications(
    state,
    AdminCarerSearchContainer.TAXONOMY_CLASSIFICATIONS,
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchCarers,
      searchMoreCarers,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminCarerSearchContainer);
