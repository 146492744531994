import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  @media (min-width: 768px) {
    white-space: nowrap;
  }
`;

class DateCell extends Component {
  render() {
    return (
      <td data-heading="Day" rowSpan={this.props.shifts.length}>
        <StyledSpan>
          {moment(this.props.date).format('dddd, MMMM Do')}
        </StyledSpan>
      </td>
    );
  }
}

DateCell.propTypes = {
  date: PropTypes.string.isRequired,
  shifts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      total: PropTypes.shape({
        minutes_total: PropTypes.number,
        carer_total: PropTypes.number,
        customer_total: PropTypes.number,
      }),
    }),
  ),
};

DateCell.defaultProps = {
  shifts: [],
};

export default DateCell;
