// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import AvatarImage from 'components/global/AvatarImage';
import Button from 'components/buttons/Button';
import HITulip from 'images/HITulip.png';
import { SC_NUMBER, openContactNumber } from 'components/global/ContactInfo';

import type { AccountOwnerType } from 'shared/types';

const StyledAccountOwnerElement = styled.div`
  max-width: 250px;
  text-align: center;
`;

const StyledHeading = styled.div`
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  margin-left: 6px;
`;

const StyledCallUsText = styled.div`
  display: inline;
`;

const StyledPhoneNumber = styled.div`
  display: inline;
`;

type Props = {
  accountOwner: AccountOwnerType,
};

class AccountOwnerComponent extends Component<Props> {
  static defaultProps = {
    accountOwner: {
      firstname: ' the team',
      gravatar: null,
      phone: null,
    },
  };

  render() {
    const { accountOwner } = this.props;
    const { firstname, gravatar, phone } = accountOwner;

    return (
      <>
        <StyledAccountOwnerElement>
          <AvatarImage src={gravatar ? gravatar : HITulip} alt={firstname} />
        </StyledAccountOwnerElement>
        <StyledAccountOwnerElement>
          <StyledHeading>{`Meet ${firstname}`}</StyledHeading>
          <div>We are available to support you every step of the way.</div>
        </StyledAccountOwnerElement>
        <StyledAccountOwnerElement>
          <StyledButton multiclick onClick={() => openContactNumber(phone)}>
            <StyledCallUsText>Call us: </StyledCallUsText>
            <StyledPhoneNumber>{phone ? phone : SC_NUMBER}</StyledPhoneNumber>
          </StyledButton>
        </StyledAccountOwnerElement>
      </>
    );
  }
}

export default AccountOwnerComponent;
