import PropTypes from 'prop-types';
import React from 'react';
import ShiftsTable from 'components/careNeeds/ShiftsTable';

// Shifts card panes
function ShiftsPane({ schedules }) {
  const tables = schedules.map(schedule => (
    <ShiftsTable
      key={schedule.id}
      shifts={schedule.shifts}
      title={schedule.name}
    />
  ));
  return <div>{tables}</div>;
}

ShiftsPane.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ShiftsPane;
