let roles = [];
let impersonator = null;

if (window.localStorage) {
  if (window.localStorage.user) {
    roles = JSON.parse(window.localStorage.user).roles;
  }
  if (window.localStorage.impersonator) {
    impersonator = JSON.parse(window.localStorage.impersonator);
  }
}

export const isAdminInLocalStorage = () => {
  return roles.includes('Admin');
};

export const isCustomerInLocalStorage = () => {
  return roles.includes('Customer');
};

export const isCarerInLocalStorage = () => {
  return roles.includes('Carer');
};

export const isimpersonatorInLocalStorage = () => {
  return !!impersonator;
};
