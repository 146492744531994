// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledPath = styled.path`
  animation: fade-in-out 1s linear alternate infinite;

  @keyframes fade-in-out {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

type Props = {
  className: string,
};

class LoadingPlaceholder extends Component<Props> {
  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <div className={`${this.props.className} loading-placeholder`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 158.9 161"
          fill="#681d49"
          width="51px"
          height="71px"
        >
          <g>
            <StyledPath
              d="M79.5,10.7C41,10.7,9.7,42,9.7,80.5c0,38.5,31.3,69.8,69.8,69.8c38.5,0,69.8-31.3,69.8-69.8
    C149.2,42,117.9,10.7,79.5,10.7z M86.7,57.9c-2.7,2.6-5.1,5.7-6.9,9c-1.9-3.4-4.3-6.5-7.1-9.2l7.1-7L86.7,57.9z M84.3,83.4
    C85.2,70,95.2,59,108.1,56.9V85c0,14.1-10.3,25.8-23.8,28.1V85C84.4,84.4,84.4,83.9,84.3,83.4z M75.1,83.3c0,0.5,0,1.1,0,1.6V113
    C61.6,110.8,51.3,99,51.3,85V56.8C64.3,59,74.3,70,75.1,83.3z M84.1,140.7v-18.3c18.7-2.2,33.3-18.2,33.3-37.5V52.3
    c0-2.8-2.2-5.1-5.1-5c-6.6,0.1-12.8,1.9-18.2,5L83.3,41.2c-1-1-2.2-1.5-3.6-1.5s-2.6,0.5-3.5,1.5L65.1,52.1
    c-5.3-3-11.5-4.8-18-4.9c0,0,0,0-0.1,0c-2.8,0-5,2.3-5,5V85c0,19,14.3,34.9,32.8,37.4v18.3C43.7,138.3,19,112.2,19,80.5
    C19,47.1,46.1,20,79.5,20c33.3,0,60.4,27.1,60.4,60.4C139.9,112.2,115.2,138.3,84.1,140.7z"
            />
          </g>
        </svg>
      </div>
    );
  }
}

export default LoadingPlaceholder;
