// @flow
import React, { Component } from 'react';
import Link from 'react-router-redux-dom-link';
import { PortalWithState } from 'react-portal';

import AdminBidsOverviewContainer from 'containers/bids/AdminBidsOverviewContainer';
import { selectBidStates } from 'components/helpers/bidHelpers';
import Modal from 'components/modules/Modal';
import Module from 'components/global/layout/Module';
import ApplicationFormContainer from 'containers/bids/ApplicationFormContainer';
import TabbedComponents from 'components/global/layout/TabbedComponents';
import { Container, Col, Row } from 'components/grid';

import type {
  BidType,
  JobOfferType,
  ScheduleType,
  TaxonomiesClassificationType,
  TeamType,
} from 'shared/types';
import { type RemoveTeamMemberType } from 'containers/bids/AdminBidsContainer';

type Props = {
  teamMembersWithCarers: Object,
  jobOffer: JobOfferType,
  needID: string,
  removeTeamMember: RemoveTeamMemberType => Object,
  schedule: ScheduleType,
  taxonomiesByClassification: TaxonomiesClassificationType,
  team: TeamType,
  isAdmin: bool,
};

type State = {
  proposedBid: ?BidType,
};

class AdminBids extends Component<Props, State> {
  state = { proposedBid: null };

  proposeCarerBid = (bid: BidType): void => {
    this.setState({ proposedBid: bid });
  };

  render() {
    const {
      jobOffer,
      needID,
      schedule,
      isAdmin,
    } = this.props;

    return (
      <Container outerGutter={0}>
        <Row>
          <Col md={12} xl={8}>
            {isAdmin && (
              <TabbedComponents>
                <AdminBidsOverviewContainer
                  default
                  name="Applicants"
                  jobOffer={jobOffer}
                  proposeCarerBid={this.proposeCarerBid}
                  schedule={schedule}
                  needID={needID}
                  bidStateLabelMappings={selectBidStates(['applied', 'contacted', 'proposed'])}
                />
                <AdminBidsOverviewContainer
                  name="Accepted"
                  jobOffer={jobOffer}
                  proposeCarerBid={this.proposeCarerBid}
                  schedule={schedule}
                  needID={needID}
                  bidStateLabelMappings={selectBidStates(['accepted'])}
                />
                <AdminBidsOverviewContainer
                  name="Rejected"
                  jobOffer={jobOffer}
                  proposeCarerBid={this.proposeCarerBid}
                  schedule={schedule}
                  needID={needID}
                  bidStateLabelMappings={selectBidStates(['customer_rejected', 'admin_rejected'])}
                />
                <AdminBidsOverviewContainer
                  name="Carer Declined"
                  jobOffer={jobOffer}
                  proposeCarerBid={this.proposeCarerBid}
                  schedule={schedule}
                  needID={needID}
                  bidStateLabelMappings={selectBidStates(['carer_withdrawn', 'carer_declined'])}
                />
                <AdminBidsOverviewContainer
                  name="CPs Contacted"
                  jobOffer={jobOffer}
                  proposeCarerBid={this.proposeCarerBid}
                  schedule={schedule}
                  needID={needID}
                  bidStateLabelMappings={selectBidStates(['new'])}
                />
              </TabbedComponents>
            )}

            {!isAdmin && (
              <TabbedComponents>
                <AdminBidsOverviewContainer
                  default
                  name="Applicants"
                  jobOffer={jobOffer}
                  proposeCarerBid={this.proposeCarerBid}
                  schedule={schedule}
                  needID={needID}
                  bidStateLabelMappings={selectBidStates(['applied'])}
                />
                <AdminBidsOverviewContainer
                  default
                  name="Contacted"
                  jobOffer={jobOffer}
                  proposeCarerBid={this.proposeCarerBid}
                  schedule={schedule}
                  needID={needID}
                  bidStateLabelMappings={selectBidStates(['contacted'])}
                />
                <AdminBidsOverviewContainer
                  default
                  name="Proposed"
                  jobOffer={jobOffer}
                  proposeCarerBid={this.proposeCarerBid}
                  schedule={schedule}
                  needID={needID}
                  bidStateLabelMappings={selectBidStates(['proposed'])}
                />
                <AdminBidsOverviewContainer
                  name="Accepted"
                  jobOffer={jobOffer}
                  proposeCarerBid={this.proposeCarerBid}
                  schedule={schedule}
                  needID={needID}
                  bidStateLabelMappings={selectBidStates(['accepted'])}
                />
                <AdminBidsOverviewContainer
                  name="Rejected"
                  jobOffer={jobOffer}
                  proposeCarerBid={this.proposeCarerBid}
                  schedule={schedule}
                  needID={needID}
                  bidStateLabelMappings={selectBidStates(['customer_rejected'])}
                />
                <AdminBidsOverviewContainer
                  name="Withdrawn"
                  jobOffer={jobOffer}
                  proposeCarerBid={this.proposeCarerBid}
                  schedule={schedule}
                  needID={needID}
                  bidStateLabelMappings={selectBidStates(['carer_withdrawn'])}
                />
              </TabbedComponents>
            )}
          </Col>
          <Col md={12} xl={4}>
            <Module>
              <Container>
                <Row>
                  <Col>
                    <Link
                      className="btn"
                      to={`/admin/needs/${needID}/?jobID=${
                        jobOffer.id
                      }#job-details`}
                    >
                      Return to job offer
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Module>
          </Col>
        </Row>
        {this.state.proposedBid && (
          <PortalWithState
            closeOnOutsideClick
            closeOnEsc
            onClose={() => this.setState({ proposedBid: null })}
            defaultOpen
          >
            {({ closePortal, portal }) =>
              portal(
                <Modal closePortal={closePortal} key="propose-modal">
                  <ApplicationFormContainer
                    admin
                    jobOffer={jobOffer}
                    bid={this.state.proposedBid}
                    closePortal={closePortal}
                    schedule={schedule}
                  />
                </Modal>,
              )
            }
          </PortalWithState>
        )}
      </Container>
    );
  }
}

export default AdminBids;
