import React from 'react';
import styled from 'styled-components';

import AccountLoginMessageBoard from 'components/account/AccountLoginMessageBoard';
import { Container, Row, Col } from 'components/grid';

const FormCol = styled(Col)`
  background: #ffffff;
  padding: 40px 0;
`;

const MessageCol = styled(Col)`
  background: ${props => props.theme.colors.hiAccentBlue};
  padding: 40px 0;
`;

function AccountLoginModule({
  children,
  title,
  message,
  secondaryMessage,
  button,
  rules,
}) {
  return (
    <Container outerGutter={0} colGutter={0}>
      <Row>
        <FormCol md={message ? 8 : 12} sm={12}>
          {children}
        </FormCol>
        {message && (
          <MessageCol md={4} sm={12}>
            <AccountLoginMessageBoard
              title={title}
              message={message}
              secondaryMessage={secondaryMessage}
              button={button}
              rules={rules}
            />
          </MessageCol>
        )}
      </Row>
    </Container>
  );
}

export default AccountLoginModule;
