import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import InlineValidationError from 'components/forms/InlineValidationError';
import StyledTitleWithLabel from 'components/forms/StyledTitleWithLabel';
import StyledSelect from 'components/forms/StyledSelect';

const StyledTupleContainer = styled.div`
  vertical-align: top;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    display: inline-block;
    width: 48%;

    &:nth-child(2n) {
      margin-right: 4%;
    }
  }
`;

const CustomStyledLabel = styled.div`
  background: white;
  border-radius: 4px;
  border: 2px solid ${props => props.theme.colors.hiAccentBlue};
  box-shadow: 0 1px 6px 0 ${props => props.theme.colors.shadowBlue};
  color: ${props => props.theme.colors.hiAccentBlue};
  cursor: pointer;
  display: inline-block;
  margin: 0 auto;
  overflow: hidden;
  padding: 14px 40px 14px 20px;
  position: relative;
  user-select: none;
  width: 100%;
`;

const Dropdown = styled.div`
  background: white;
  border: 1px solid ${props => props.theme.colors.uiGrey};
  border-top: 0;
  margin-top: -7px;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: ${props => props.theme.zIndexes.menu};

  > span {
    display: block;
    padding: 10px;
    &:hover {
      background: ${props => props.theme.colors.lightestGrey};
      cursor: pointer;
    }
  }
`;

const AddButton = styled.button`
  appearance: none;
  background: white;
  background-image: url('/static/images/inputs/add_button.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 29px 29px;
  border: none;
  color: ${props => props.theme.colors.midGrey};
  display: block;
  font-family: ${props => props.theme.typography.fontStack};
  font-size: ${props => props.theme.typography.defaultFontSize};
  line-height: 38px;
  margin-top: 10px;
  padding-left: 38px;

  &:hover {
    cursor: pointer;
  }
`;

const CustomStyledSelect = styled(StyledSelect)`
  vertical-align: top;
  position: relative;
  width: 100%;
  @media (min-width: 768px) {
    display: inline-block;
    width: 48%;

    &:nth-child(2n) {
      margin-right: 4%;
    }
  }
`;

const DeleteButton = styled.button`
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  color: white;
  line-height: 10px;
  position: absolute;
  right: 25px;
  text-align: left;
  text-indent: -9999em;
  bottom: 0;
  top: 0;
  width: 20px;

  &:after {
    -webkit-appearance: none;
    appearance: none;
    content: '×';
    color: ${props => props.theme.colors.hiAccentBlue};
    cursor: pointer;
    display: block;
    font-size: 30px;
    line-height: 50%;
    height: 30px;
    text-align: center;
    text-indent: 0;
    width: 30px;
  }

  &:focus {
    outline: none;
  }
`;

class StandardTupleCheckboxAdder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: null,
      open: null,
    };
  }

  onAdd = e => {
    e.persist();
    e.preventDefault();
    if (!this.state.adding) {
      this.setState({
        adding: true,
      });
    }
  };

  onSelectAddition = e => {
    e.persist();
    e.preventDefault();
    const { input, transform } = this.props;
    if (e.target.value && e.target.value !== '') {
      const newValue = [...input.value];
      newValue.push(transform(e.target.value, null));
      this.setState(
        {
          adding: false,
          open: e.target.value,
        },
        input.onChange(newValue),
      );
    }
  };

  onRemove = (e, value) => {
    e.persist();
    e.preventDefault();
    const { input, keyMap } = this.props;
    const newValue = [...input.value];
    newValue.splice(newValue.findIndex(items => items[keyMap[0]] === value), 1);
    input.onChange(newValue);
  };

  onSelectDetail = (value, dropdown) => {
    const { input, transform, keyMap } = this.props;
    const newValue = [...input.value];
    const toUpdate = newValue.findIndex(item => item[keyMap[0]] === value);
    if (toUpdate > -1) {
      newValue[toUpdate] = transform(value, dropdown);
    } else {
      newValue.push(transform(value, dropdown));
    }
    this.setState(
      {
        open: null,
      },
      input.onChange(newValue),
    );
  };

  toggle = field => {
    if (this.state.open === field) {
      this.setState({ open: null });
    } else {
      this.setState({ open: field });
    }
  };

  render() {
    const {
      additionType,
      dropdownOptions,
      input,
      keyMap,
      label,
      options,
      help,
      title,
      meta: { touched, error, warning },
    } = this.props;

    return (
      <div id={input.name}>
        <StyledTitleWithLabel
          title={title}
          label={label}
          help={help}
          error={touched && error}
        />
        {(input.value || []).map(item => {
          const key = item[keyMap[0]];
          const taxon = options.find(option => option.key === key);
          const detail = dropdownOptions.find(
            option => option.taxon_id === item[keyMap[1]],
          );
          return (
            <StyledTupleContainer key={key}>
              <CustomStyledLabel
                key={key}
                name={key}
                value={key}
                onClick={() => this.toggle(key)}
              >
                {taxon.value} {detail && `(${detail.value})`}
                <DeleteButton onClick={e => this.onRemove(e, key)}>
                  Delete
                </DeleteButton>
              </CustomStyledLabel>
              {this.state.open === key && (
                <Dropdown>
                  {dropdownOptions.map(({ taxon_id, value }) => (
                    <span
                      role="button"
                      onClick={() => this.onSelectDetail(key, taxon_id)}
                      key={taxon_id}
                    >
                      {value}
                    </span>
                  ))}
                </Dropdown>
              )}
            </StyledTupleContainer>
          );
        })}
        {this.state.adding && (
          <CustomStyledSelect onChange={this.onSelectAddition}>
            <option value="">Please select</option>
            {options.map(option => (
              <option key={option.key} value={option.key}>
                {option.value}
              </option>
            ))}
          </CustomStyledSelect>
        )}
        <AddButton id={`add-${input.name}`} onClick={this.onAdd}>
          Add new {additionType}
        </AddButton>
        {touched &&
          ((error && <InlineValidationError>{error}</InlineValidationError>) ||
            (warning && (
              <InlineValidationError>{warning}</InlineValidationError>
            )))}
      </div>
    );
  }
}

StandardTupleCheckboxAdder.propTypes = {
  additionType: PropTypes.string,
  dropdownOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  input: PropTypes.shape().isRequired,
  keyMap: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  help: PropTypes.string,
  transform: PropTypes.func.isRequired,
  title: PropTypes.string,
};

StandardTupleCheckboxAdder.defaultProps = {
  additionType: 'item',
  advice: null,
  label: null,
  help: '',
  title: null,
};

export default StandardTupleCheckboxAdder;
