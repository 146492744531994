export default function niceShiftTimes(shift) {
  if (shift.start === '00:30' && shift.end === '23:30') {
    return 'Live-in (all day)';
  }
  if (
    (shift.start === '00:30' && shift.end === '11:30') ||
    (shift.start === '12:30' && shift.end === '23:30')
  ) {
    return 'Live-in (half day)';
  }
  return `${shift.start} - ${shift.end}`;
}
