import TimesheetConstants from 'shared/constants/TimesheetConstants';

const initalState = [];

function amends(state = initalState, action) {
  let updatedAmends;

  switch (action.type) {
    case TimesheetConstants.TIMESHEET_GET_AMENDS_BY_NEED_ID_SUCCESS:
      return action.data.results;

    case TimesheetConstants.TIMESHEET_CREATE_AMENDS_SUCCESS:
      updatedAmends = state.slice();
      action.data.forEach(response => {
        updatedAmends.push(response.body.data);
      });
      return updatedAmends;

    case TimesheetConstants.TIMESHEET_DELETE_AMENDS_SUCCESS:
      updatedAmends = state.slice();

      action.data.forEach(response => {
        const index = updatedAmends.reduce((current, amend, amendIndex) => {
          const data = response.body.data;
          if (
            amend.date === data.date &&
            amend.need_id === data.need_id &&
            amend.carer_id === data.carer_id
          ) {
            return amendIndex;
          }
          return current;
        }, -1);

        if (index > -1) {
          updatedAmends.splice(index, 1);
        }
      });

      return updatedAmends;

    default:
      return state;
  }
}

export default amends;
