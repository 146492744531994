// @flow
import React from 'react';
import { Container, Col } from 'components/grid';
//$FlowFixMe
import { Field } from 'redux-form';

import Button from 'components/buttons/Button';
import Form from 'components/forms/Form';
import FormRow from 'components/forms/FormRow';
import StandardInput from 'components/forms/StandardInput';
import { trim } from 'normalizers';
import { matchesPassword, required } from 'validators';

import type { APIAction } from 'shared/types';

type Props = {
  className: string,
  setConfirmPassword: ({ password: string }) => (string, string) => APIAction,
};

function SetConfirmPassword({ setConfirmPassword, className }: Props) {
  return (
    <Form
      name="updatePassword"
      onSubmit={setConfirmPassword}
      component={({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <FormRow className={className}>
              <Col>
                <Field
                  component={StandardInput}
                  name="password"
                  type="password"
                  validate={[required]}
                  label="New password"
                  normalize={trim}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="confirm_password"
                  label="Confirm Password"
                  component={StandardInput}
                  type="password"
                  validate={[matchesPassword]}
                  normalize={trim}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Button disabled={pristine} type="submit">
                  Update Password
                </Button>
              </Col>
            </FormRow>
          </Container>
        </form>
      )}
    />
  );
}

export default SetConfirmPassword;
