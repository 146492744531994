import { actions as toastrActions } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { all, take, takeEvery, call, put } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { logout } from '_actions/accountActions';

import SuperCarersClient from 'clients/SuperCarersClient';
import ApiConstants from 'shared/constants/ApiConstants';

export function* apiRequest(action) {
  const {
    endpoint,
    method,
    onStart,
    onSuccess,
    onError,
    payload,
    redirect,
    otherProps,
  } = action;
  yield put({ ...onStart, payload });
  try {
    const response = yield call(SuperCarersClient[method], endpoint, payload);
    yield put({ ...onSuccess, data: response.data, otherProps });
    if (redirect) {
      let path;
      if (typeof redirect === 'function') {
        path = redirect(response);
      } else {
        path = redirect;
      }
      yield put(push(path));
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Unauthorised, logout
      yield put(logout());
      yield put(
        toastrActions.add({
          type: 'error',
          public: false,
          message: 'Your session has expired. Please log in again to continue.',
          id: 'session-expired',
          options: {
            preventDuplicates: true,
          },
        }),
      );
      return;
    } else if (error.response && error.response.status === 403) {
      yield put(
        toastrActions.add({
          type: 'error',
          public: true,
          message: 'You do not have permission to view this page.',
          id: 'permission-denied',
          options: {
            showCloseButton: true,
            preventDuplicates: true,
          },
        }),
      );
      return;
    }
    const errorAction = { ...onError };
    if (action.type === ApiConstants.FORM_API_REQUEST) {
      let msg;
      if (error.response && error.response.data) {
        msg =
          typeof error.response.data === 'string'
            ? error.response.data
            : error.response.data.message;
      } else {
        msg =
          'An unexpected error occurred. Please contact the Home Instead Live-in Care team.';
      }
      errorAction.payload = { _error: msg };
    } else {
      errorAction.error = typeof error === 'string' ? error : { ...error };
    }
    if (errorAction.toast && !errorAction.toast.title) {
      errorAction.toast.title = errorAction.payload._error || errorAction.error;
    }
    yield put(errorAction);
  }
}

export function* apiRequestListener() {
  yield all([
    takeEvery(ApiConstants.API_REQUEST, apiRequest),
    takeEvery(ApiConstants.FORM_API_REQUEST, apiRequest),
  ]);
}

export function* sendNotification(action) {
  //TODO ADD ACTION TYPE TO SENTRY EVENT
  let toast;
  if (action.toast) {
    toast = Object.assign(action.toast, {
      options: { showCloseButton: true, preventDuplicates: true },
    });

    yield put(toastrActions.add(toast));
  } else if (action.error) {
    let text;
    if (action.error.response && action.error.response.status === 404) {
      // 404s are fine.
      return;
    }
    if (action.error.response && action.error.response.data) {
      const message = action.error.response.data.message || 'No message';
      if (message.explanation && message.resolution) {
        text = `${message.explanation}\n${message.resolution}`;
      } else {
        text = message;
      }
    } else {
      text = `${action.type}`;
    }

    yield call(Sentry.withScope, scope => {
      scope.setTag('error_title', text);

      Sentry.captureEvent({
        message: 'Error toast displayed',
        level: 'warning',
        extra: {
          toast_displayed: text,
          error: { ...action.error },
          dataSent: { ...action.dataSent },
        },
      });
    });

    if (text && text.length) {
      // Don't show empty errors
      toast = {
        type: 'error',
        private: true,
        message: text,
        options: {
          showCloseButton: true,
          preventDuplicates: true,
        },
      };
      yield put(toastrActions.add(toast));
    }
  }
}

export function* sendNotificationListener() {
  yield all([
    takeEvery(action => /_SUCCESS$/.test(action.type), sendNotification),
    takeEvery(action => /_FAILURE$/.test(action.type), sendNotification),
  ]);
}

export function* apiRequestAndRefresh(action) {
  yield put(action.onUpdate);
  const nextAction = yield take([
    action.onUpdate.onSuccess.type,
    action.onUpdate.onError.type,
  ]);
  if (nextAction.type === action.onUpdate.onSuccess.type) {
    yield put(action.onRefresh);
  }
}

export function* apiRequestAndRefreshListener() {
  yield takeEvery(ApiConstants.API_REQUEST_AND_REFRESH, apiRequestAndRefresh);
}
