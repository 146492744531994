import React from 'react';
import styled from 'styled-components';

import CareDetailsCompleteImage from 'images/customers/OurCarersColourised.png';
import CareTeamPlaceholderImage from 'images/customers/GroupOfCarers.png';
import InvoicesPlaceholderImage from 'images/customers/CarerLookingUp.png';
import SchedulePlaceholderImage from 'images/customers/DogCatMouseChicken.png';

import { ThickUnderline } from 'components/global/StyledUnderlines';
import { SCContactNumber } from 'components/global/ContactInfo';

import {
  emptyScheduleTitle,
  emptyCareTeamTitle,
  emptyHomePageTitle,
  emptyInvoicesTitle,
  emptyInvoicesSummary,
  scheduleAdviceLink,
  careTeamFaqLink,
  completedCareDetailsTitle,
  verificationMessages,
} from 'components/customers/config';

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 100%;
  grid-template-areas:
    'summary'
    'image';
  justify-items: center;

  @media (min-width: ${props => props.theme.screen.medium}px) {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'summary image';
    justify-items: normal;
  }
`;

const StyledSummary = styled.p`
  grid-area: summary;
  padding-top: 30px;
  vertical-align: top;

  > :first-child {
    font-size: 22px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  grid-area: image;
  padding-top: 60px;

  @media (min-width: ${props => props.theme.screen.medium}px) {
    padding: 0;
  }
`;

const InvoicesImg = styled(StyledImage)`
  padding-top: 60px;
  width: auto;
  height: 400px;

  @media (min-width: ${props => props.theme.screen.medium}px) {
    padding-top: 30px;
    justify-self: center;
  }
`;

const InlineLink = styled.a`
  display: inline;
`;

const CustomerEmptyStateLayout = ({ title, summary, img, underlineColour }) => (
  <GridContainer>
    <StyledSummary>
      <div>{title}</div>
      <ThickUnderline themeColour={underlineColour} />
      <div>{summary}</div>
    </StyledSummary>
    {img}
  </GridContainer>
);

export const CustomerEmptySchedule = props => (
  <CustomerEmptyStateLayout
    img={<StyledImage src={SchedulePlaceholderImage} />}
    title={emptyScheduleTitle}
    summary={
      <>
        Not sure how much care you need?&nbsp;
        <InlineLink href={scheduleAdviceLink.link} target="_blank">
          {scheduleAdviceLink.text}
        </InlineLink>
        , or give the team a call on&nbsp;
        <SCContactNumber />
      </>
    }
  />
);

export const CustomerEmptyInvoices = props => (
  <CustomerEmptyStateLayout
    img={<InvoicesImg src={InvoicesPlaceholderImage} />}
    imgWidth={260}
    title={emptyInvoicesTitle}
    summary={emptyInvoicesSummary}
  />
);

export const CustomerEmptyCareTeam = props => (
  <CustomerEmptyStateLayout
    img={<StyledImage src={CareTeamPlaceholderImage} />}
    title={emptyCareTeamTitle}
    summary={
      <>
        Questions about how to choose the right carer?&nbsp;
        <InlineLink href={careTeamFaqLink.link} target="_blank">
          {careTeamFaqLink.text}
        </InlineLink>
        .
      </>
    }
  />
);

export const CustomerCompletedCareDetails = props => (
  <CustomerEmptyStateLayout
    img={<StyledImage src={CareDetailsCompleteImage} />}
    title={completedCareDetailsTitle}
    summary={
      <>
        Please contact us at SuperCarers if you wish to change your details -{' '}
        <SCContactNumber />
      </>
    }
    underlineColour={'hiAccentBlue'}
  />
);

export const CustomerEmptyHome = props => (
  <CustomerEmptyStateLayout
    img={<StyledImage src={SchedulePlaceholderImage} />}
    title={emptyHomePageTitle}
    summary={
      <>
        Please contact SuperCarers on <SCContactNumber /> and we'll sort it
        right away.
      </>
    }
    underlineColour={'hiAccentBlue'}
  />
);

export const CustomerVerifyEmailPlaceholder = ({
  sendEmailVerification,
  image,
  updateUser,
  user,
  type,
}) => (
  <CustomerEmptyStateLayout
    img={
      type === 'invoice' ? (
        <InvoicesImg src={image} />
      ) : (
        <StyledImage src={image} />
      )
    }
    title={verificationMessages[type].title}
    summary={verificationMessages[type].summary}
    underlineColour={'hiAccentBlue'}
  />
);
