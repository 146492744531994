import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ButtonLink from 'components/buttons/ButtonLink';

import { getUser } from 'shared/selectors/accountSelectors';

function ProfilePDFLink({ carerID, user }) {
  return (
    <ButtonLink
      to={`${
        window.global.SUPERCARERS_API_URL
      }/carer/${carerID}/view/pdf/?token=${user.access_token}`}
      target="_blank"
      rel="noopener noreferrer"
      className="altGreen"
    >
      Download Carer CV
    </ButtonLink>
  );
}

function mapStateToProps(state) {
  return {
    user: getUser(state),
  };
}

ProfilePDFLink.propTypes = {
  carerID: PropTypes.string.isRequired,
  user: PropTypes.shape(),
};

ProfilePDFLink.defaultProps = {
  user: null,
};

export default connect(mapStateToProps)(ProfilePDFLink);
