import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';

import Summary from 'components/modules/Summary';

class WeeklySummary extends Component {
  render() {
    const summaries = this.props.weeks.map(week => ({
      title: week.title,
      subtitle: (
        <div>
          <div>{week.isoweek}</div>
          <div>{week.start.format('DD-MM-YYYY')}</div>
        </div>
      ),

      states: this.props.states.map(state => {
        const params = {
          state,
          end: week.start.format('YYYY-MM-DD'),
        };
        if (!week.cumulative) {
          params.begin = params.end;
        }
        const summary = this.props.summaries[week.isoweek] || {};
        return {
          name: state,
          count: (summary || {})[state] || 0,
          link: `${this.props.filterLink}?${qs.stringify(params)}`,
        };
      }),
    }));

    return <Summary title={this.props.title} summaries={summaries} />;
  }
}

WeeklySummary.propTypes = {
  title: PropTypes.string.isRequired,
  states: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterLink: PropTypes.string,
  weeks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  summaries: PropTypes.shape().isRequired,
};

WeeklySummary.defaultProps = {
  filterLink: '',
};

export default WeeklySummary;
