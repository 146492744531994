import React from 'react';
import { reduxForm } from 'redux-form';
import { onSubmitActions } from 'redux-form-submit-saga';

import { recoverPassword } from '_actions/accountActions';
import AccountConstants from 'shared/constants/AccountConstants';

import AccountRecoverPasswordForm from 'components/account/AccountRecoverPasswordForm';
import AccountLoginModule from 'components/account/AccountLoginModule';

import { scrollToTop } from 'components/helpers/domHelpers';

const ReduxAccountRecoverPassword = reduxForm({
  form: 'recoverPassword',
  onSubmit: onSubmitActions({
    submit: recoverPassword,
    success: AccountConstants.ACCOUNT_RECOVER_SUCCESS,
    failure: AccountConstants.ACCOUNT_RECOVER_FAILURE,
  }),
  onSubmitFail: () => {
    scrollToTop();
  },
})(AccountRecoverPasswordForm);

const message =
  'If you have forgotten your password or would like to create a new one, please enter your email address and we will send you a link.';

function AccountRecoverPasswordContainer() {
  return (
    <AccountLoginModule title={'Reset Password'} message={message}>
      <ReduxAccountRecoverPassword />
    </AccountLoginModule>
  );
}

export default AccountRecoverPasswordContainer;
