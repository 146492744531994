import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { onSubmitActions } from 'redux-form-submit-saga';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { checkIfVerified, verifyAccount } from '_actions/accountActions';
import { isLoggedIn } from 'shared/selectors/accountSelectors.js';

import AccountConstants from 'shared/constants/AccountConstants';

import AccountConfirmForm from 'components/account/AccountConfirmForm';
import AccountLoginModule from 'components/account/AccountLoginModule';
import { DEFAULT_MAIL } from 'components/global/ContactInfo';

const ReduxAccountConfirmForm = reduxForm({
  form: 'accountConfirm',
  onSubmit: onSubmitActions({
    submit: verifyAccount,
    success: AccountConstants.ACCOUNT_VERIFY_SUCCESS,
    failure: AccountConstants.ACCOUNT_VERIFY_FAILURE,
  }),
})(AccountConfirmForm);

const AccountConfirmContainer = ({ checkIfVerified, isLoggedIn, match }) => {
  useEffect(() => {
    checkIfVerified(match.params.verification_token);
  }, []);

  const title = 'You are almost there';
  const message = isLoggedIn
    ? 'Create a password to gain full access to your account'
    : 'Create your password to log into the platform.';
  const secondaryMessage = `If you are having difficulties setting your password, please contact us at ${DEFAULT_MAIL}`;

  return (
    <AccountLoginModule
      title={title}
      message={message}
      secondaryMessage={secondaryMessage}
      rules={true}
    >
      <ReduxAccountConfirmForm
        initialValues={{
          token: match.params.verification_token,
        }}
      />
    </AccountLoginModule>
  );
};

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedIn(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ checkIfVerified }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountConfirmContainer);
