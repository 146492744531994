// @flow
import React from 'react';
import Link from 'react-router-redux-dom-link';
import styled from 'styled-components';

import AccountOwnerContainer from 'containers/customers/AccountOwnerContainer';
import CollapsibleSection from 'components/global/dynamic/CollapsibleSection';
import StandardFlexTable from 'components/global/layout/StandardFlexTable';
import { ModuleHero } from 'components/global/layout/Module';

import toTitleCase from 'components/helpers/toTitleCase';

import { type BidType, type JobOfferType } from 'shared/types';

const StyledConflictRow = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 85px;
  align-items: center;
  padding: 5px 20px;
  font-weight: normal;
  flex-wrap: wrap;
`;

const StyledConflictElement = styled.div`
  flex: 1;
  min-width: 150px;
`;

type BidConflictProps = {
  state: string,
  bids: Array<BidType>,
  bidIDs: mixed,
  jobOffers: Array<JobOfferType>,
};

export const BidConflict = ({
  state,
  bids,
  bidIDs,
  jobOffers,
}: BidConflictProps) => {
  if (!Array.isArray(bidIDs)) {
    bidIDs = [bidIDs];
  }

  return (
    <StandardFlexTable header={toTitleCase(state)}>
      {bidIDs.map(bidID => {
        const bid = bids.find(bid => bid.id === bidID);
        const jobOffer =
          bid && jobOffers.find(jobOffer => jobOffer.id === bid.job_offer_id);
        if (!jobOffer) {
          return null;
        }
        return (
          <StyledConflictRow key={jobOffer.id}>
            <StyledConflictElement>
              {jobOffer.full_user_name}
            </StyledConflictElement>
            <StyledConflictElement>
              <Link to={`/admin/needs/${jobOffer.need_id}/`}>
                {jobOffer.need_name}
              </Link>
            </StyledConflictElement>
            <StyledConflictElement>
              <Link
                to={`/admin/needs/${jobOffer.need_id}/?jobID=${
                  jobOffer.id
                }#job-details`}
              >
                {jobOffer.reference}
              </Link>
            </StyledConflictElement>
            <StyledConflictElement>
              <AccountOwnerContainer nameOnly needID={jobOffer.need_id} />
            </StyledConflictElement>
          </StyledConflictRow>
        );
      })}
    </StandardFlexTable>
  );
};

type Props = {
  bids: Array<BidType>,
  conflictingBids: { [string]: Array<string> },
  jobOffers: Array<JobOfferType>,
};

const AdminUserConflicts = ({ bids, conflictingBids, jobOffers }: Props) => {
  return (
    <CollapsibleSection header="Bid Conflicts" id="bid-conflicts">
      {!conflictingBids && (
        <ModuleHero>This carer does not have any active bids</ModuleHero>
      )}
      {conflictingBids &&
        Object.entries(conflictingBids).map(([state, bidIDs]) => (
          <BidConflict
            key={state}
            state={state}
            bidIDs={bidIDs}
            bids={bids}
            jobOffers={jobOffers}
          />
        ))}
    </CollapsibleSection>
  );
};

export default AdminUserConflicts;
